/* eslint-disable eqeqeq */
import AuthErrorTooltip from "./AuthErrorTooltip";
import { useState, useRef, useEffect } from 'react';
import logo from '../../img/logo.svg';
import back_white from '../../img/back_white.svg';
import autosize from 'autosize';
import { IMaskInput } from 'react-imask';
import PromocodeView from "./custom_views/PromocodeView";
import Loader from "../../custom_views/Loader";
import { useHistory } from "react-router-dom";
import { Pages } from "../../utils/Utils";
var canGoNext = false
var pastedData = ""
function PhoneView(props) {
    const ref = useRef(null);
    const inputRef = useRef(null);
    const [promoCode, setPromoCode] = useState("")
    const history = useHistory()
    // const registerUser = () => {
    //     props.toggleIsLoading()
    //     var params = getRequestParams()
    //     if (promoCode != undefined && promoCode != ""){
    //         params.promo_code = promoCode
    //     }
    //     params.phone_number = props.phoneNumber.includes("+7") ? props.phoneNumber.replaceAll("+7", "7") : props.phoneNumber
    //     console.log("registerUser params =" + JSON.stringify(params))
    //     fetch(getRequestUrl() + "registerUser", getHttpParams(params)).then((response) => response.json())
    //     .then((responseData) => {
    //         console.log("registerUser = " + JSON.stringify(responseData))
    //         if (responseData.response == 1 || responseData.response == 2){
    //             props.toggleCodeView()
    //             props.setCodeType(responseData.channelType)
    //         }
    //     })
    // }
    useEffect(() => {
        inputRef.current.focus();
        autosize(inputRef.current);
    }, [])
    useEffect(() => {
        if (props.phoneNumber.length == 11 && canGoNext){
            props.registerUser(promoCode)
            canGoNext = false
        } else {
            canGoNext = true
        }
    }, [props.phoneNumber])

    var isPhoneEntered = props.phoneNumber.length == 11
    var isPhoneError = false
    const setPhone = (phone) => {
        if (phone == undefined || phone == ""){
            props.setPhone("")
            props.setPhone("7")
        } else {
            if (phone.length < 3){
                props.setPhone("7")
            } else {
                props.setPhone(phone)
                //maskPhone(phone)
            }
        }
    }

    const back = () => {
        history.goBack()
    }
    return (
        <div>
            <div onClick={back} className="back_code_container hover"><img alt="" className="back_white_code" src={back_white}/></div>

            <img alt="" className="auth_logo" src={logo}/>
            <p className="auth_title">Авторизация</p>
            <p className="auth_description">Вам придет смс с кодом</p>
            <div className="auth_phone_bg">
                <p className="auth_phone_title">Телефон</p>
                <IMaskInput type="tel" className="auth_phone_input" mask={'+{7} (000) 000-00-00'} radix="." value={props.phoneNumber} unmask={true} // true|false|'typed'
                ref={ref}
                inputRef={inputRef}
                onAccept={
                    (value, mask) => {
                        setPhone(value)
                        console.log("value = " + value)
                    }
                } placeholder='+7 (000) 000-00-00' />
            </div>

            {isPhoneError && <AuthErrorTooltip text="Неверный номер телефона"/>}
            <PromocodeView setPromoCode={setPromoCode}/>
            <div className="bottom_auth_container">
                <div className="auth_policy_text">
                    <div className="auth_policy_gradient"/>
                    <div className="auth_policy_bg">Нажимая кнопку “Далее”, я подтверждаю, что мне есть 18 лет, и я соглашаюсь с 
                    <a rel="noreferrer" style={{color : 'white'}} href={Pages.privacy_policy} target="_blank"><b className="hover"> Политикой конфиденциальности </b></a>
                    и 
                    <a rel="noreferrer" style={{color : 'white'}} href={Pages.agreement} target="_blank"><b className="hover"> Пользовательским соглашением </b></a>
                 </div>
                </div>
                <button style={!isPhoneEntered ? {pointerEvents : 'none', opacity : 0.2} : {}} onClick={() => props.registerUser(promoCode)} className="button bottom_button hover">{props.isLoading ? <Loader isWhite={true}/> : "Далее"}</button>
            </div>
        </div>
    );
}

export default PhoneView;
