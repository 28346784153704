import { Pages } from "./Utils";

/* eslint-disable eqeqeq */
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = window.document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  };
export const setCookie = (name,value) => {
    var expires = "";
    var days = 9999
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString() + "";
    }
    // window.document.cookie = name + "=" + (value || "")  + expires + "; path=/" + (window.location.hostname == "localhost" ? "" : ";domain=sozvon.pro");
    const domain_name = getDomainNameWithoutHttps()
    window.document.cookie = name + "=" + (value || "")  + expires + "; path=/" + (window.location.hostname == "localhost" ? "" : ";domain=" + domain_name);
}
export const setCookieOld = (name,value) => {
  var expires = "";
  var days = 9999
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = "; expires=" + date.toUTCString() + "";
  }
  window.document.cookie = name + "=" + (value || "")  + expires + "; path=/"
}
// export const deleteCookie = (name) => {
//     window.document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT' + "; path=/" + (window.location.hostname == "localhost" ? "" : ";domain=sozvon.pro");
// }
export const getDomainNameWithoutHttps = () => {
  var domain_name = window.location.origin
  if (domain_name.includes("https://")){
      domain_name = domain_name.split("https://")[1]
  }
  return domain_name
}
export const deleteCookie = (name) => {
  const domain_name = getDomainNameWithoutHttps()
  window.document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT' + "; path=/" + (window.location.hostname == "localhost" ? "" : ";domain=" + domain_name);
  window.document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT' + '; path=/'
}

export const getUserUrl = (user) => {
    if (user == undefined || user._id == undefined){
      return ""
    }
    if (user.domain_name){
      return Pages.users + "/" + user.domain_name
    }
    return Pages.users + "/id" + user._id
}
export const getRequestParams = () => {
     
    var requestParams = {
        //"access_token" : getCookie("token")
    }  
    return requestParams;
};
export const isUserAuthed = () => {
    const token = getCookie("token")
    const isTokenEmpty = token == null || token == undefined || token == ""
    return !isTokenEmpty;
};