/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, getUserAvatar } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { DialogsApi } from '../../../api/DialogsApi';
import { BoardsApi } from '../../../api/BoardsApi';

function WorkBoardsSendDialog(props) {
    const [dialogs, setDialogs] = useState([])
   
    const isDesktop = getIsDesktop()
    useEffect(() => {
        if (props.isShow){
            getDialogs()
        }
    }, [props.isShow])
    const getDialogs = () => {
        DialogsApi.getDialogs((responseData) => {
            const loadedDialogs = responseData.dialogs.filter((d) => d.dialog != undefined)
            setDialogs(loadedDialogs)
            console.log("getDialogs loadedDialogs = ", loadedDialogs)
        }, () => {})
    }
    const onChatClick = (chat) => {
        const params = {
            "board_id": props.workDialogData._id,
            "text": props.workDialogData.name + "\n",
            "to_id": chat.user.vk_caller_id
        }
        
        BoardsApi.sendDeskToStudent(params, (responseData) => {
            setShow()
        }, () => {})
        
    }
    const setShow = () => {
        props.setShow({})
    }
    const getInnerHtml = () => {
        return(
            <div>            
                <div className="call_info_container blog_theme_picker">
                    <p style={{color : 'black'}} className="create_ads_close_title">Выберите диалог</p>
                    {dialogs.length > 0 && dialogs.map((item, index) => (
                        <div onClick={() => onChatClick(item)} className="chat_item_container hover" index={index} key={index}>
                            <div className="flex chat_item_p">
                                <div className="chat_item_avatar_img_cont">
                                    <img alt='' src={getUserAvatar(item.user)} className="chat_item_avatar"/>
                                    {item.user.online && <div className="round_msg_chat_item"></div>}
                                </div>
                                
                                <div className="chat_item_text">
                                    <p className="chat_item_title">{item.user.name} {item.user.surname}</p>
                                    {/* <p className="chat_item_description">{text}</p> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    {dialogs.length == 0 && <div className="blog_Filer_no_theme">Диалоги не найдены</div>}
                </div>
                <div style={{margin : '16px 0 16px 0'}} className="flex">
                    <div onClick={setShow} style={{margin : '0 24px'}} className="call_price_continue hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={props.isShow} setShow={setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={setShow} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default WorkBoardsSendDialog;
