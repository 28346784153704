import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../../api/UsersApi';


function ProdeactivateDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const user = props.user

    const deactivate = () => {
        setLoading(true)
        UsersApi.cancelPro((responseData) => {
            props.getMyInfo()
            cancel()
        }, () => {props.cancelPro()})
    }
    const cancel = () => {
        props.setShow(false)
    }
   
    const getInnerHtml = () => {
        return(
            <div className="pro_dialog_container">
                <div className="pro_dialog_title">Отключение статуса PRO</div>
                <div className="pro_dialog_text">Автоматическое продление статуса PRO будет отключено</div>
                <div>
                    <div onClick={deactivate} style={{background : '#FF5353', color : 'white'}} className="pro_dialog_btn hover">{isLoading ? <Loader isWhite/> : "Потерять все преимущества"}</div>

                    <div onClick={cancel} className="pro_dialog_btn_cancel hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return null
        }
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ProdeactivateDialog;
