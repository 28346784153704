import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import { AuthApi } from '../../../../api/AuthApi';
import Loader from '../../../../custom_views/Loader';


function TgNotificationsDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const [tgCode, setTGCode] = useState("")
    const isDesktop = getIsDesktop()
    const isEnableButton = tgCode.length > 1
    const onNext = () => {
        setLoading(true)
        AuthApi.addTelegramNotify(tgCode, (responseData) => {
            setLoading(false)
            props.getMyInfo()
            if (props.onFinish){
                props.onFinish()
            }
            cancel()
        }, () => {
            setLoading(false)
        })
        
    }
    const cancel = () => {
        setTGCode("")
        props.setShow(false)
    }
   
    const getTgCodeHtml = () => {
        return(
            <div>
                <div className="referal_dialog_title">Введите код, который вы получили от<br/>Telegram-бота</div>
                <div style={{height : '140px'}} >
                    <div className="referal_dialog_input_bg">
                        <p className="auth_phone_title">Код</p>
                        <input style={{background: 'none'}} className="auth_phone_input" type="text" placeholder="Например, 645asd3hdjh46764dkfjsh5" value={tgCode} onChange={(e) => setTGCode(e.target.value)}/>
                    </div>
                </div>
            </div>
        )
    }
    const getInnerHtml = () => {
        return(
            <div className="other_settings_dialog">
                {getTgCodeHtml()}
                <div style={{marginTop : '36px'}} className="flex">
                    <div onClick={cancel} className="call_prices_edit hover">Отмена</div>
                    <div style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={onNext} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : "Далее"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default TgNotificationsDialog;
