/* eslint-disable eqeqeq */
import search_icon from '../../../../img/experts/search.svg'
import filter from '../../../../img/blogs/filter.svg'
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from "react-router-dom";
import filter_selected from '../../../../img/create_ads/filter_selected.svg'


function AdsSearchBar(props) {
    const params = props.searchParams
    const history = useHistory()
    
    const openLink = (link) => {
        history.push(link)
    }
    const isFiltersApplied = params.price != 0 || params.specializations.length > 0 || params.is_all == 1 || params.without_respond == 1 || params.without_pro == 1
    return (
        <div className="ads_search_bar">
            <div className="experts_search_container ads_search_input_bar">
                <img alt="" className="search_experts_icon" src={search_icon}/>
                <DebounceInput debounceTimeout={500} placeholder="Поиск" className="experts_search_input ads_search_input_text" type="text" value={params.text} onChange={(e) => props.setText(e.target.value)}/>
            </div>   
            <img style={{marginTop : '12px'}}  alt="" onClick={() => openLink("/ads_board/filter")} className="like_experts_icon hover" src={isFiltersApplied ? filter_selected : filter}/> 
        </div>
    )
}

export default AdsSearchBar;
