/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */


import * as SDK from '@vkontakte/calls-sdk'
import React, { useState, useEffect, useRef } from 'react';
import { getApiKey, getIsDesktop, getUrlParameter, getOS, Pages } from '../../utils/Utils';
import '../../styles/call.css'
import CallNavbar from './custom_views/CallNavbar';
import CallBottomBar from './custom_views/CallBottomBar';
import stop_screen_share from '../../img/call/stop_screen_share.svg'
import { getCookie } from '../../utils/CookieHelper';
import video_logo from '../../img/call/video_logo.svg'
import { DialogsApi } from '../../api/DialogsApi';
import SafariScreenShareScreen from './dialogs/SafariScreenShareSheet';
import CloseCallBottomSheet from './dialogs/CloseCallBottomSheet';
import SafariIncomingScreenShare from './dialogs/SafariIncomingScreenShare';
import CantPauseDialog from './dialogs/CantPauseDialog';
// import { isSupported, VideoEffects } from '@vkontakte/calls-video-effects';
import ScreenShareErrorDialog from './dialogs/ScreenShareErrorDialog';
import CallCameraErrorDialog from './dialogs/CallCameraErrorDialog';
import CallMicErrorDialog from './dialogs/CallMicErrorDialog';
import GroupVideoStreams from './custom_views/GroupVideoStream';
import { PlannerApi } from '../../api/PlannerApi';
import { UsersApi } from '../../api/UsersApi';
import Chat from '../chat/Chat';
import moment from 'moment';
import GroupCallTimer from './custom_views/GroupCallTimer';
import { CallApi } from '../../api/CallApi';
import DisableMicDialog from './dialogs/DisableMicDialog';
import HandUpDialog from './dialogs/HandUpDialog';
import { GroupCallController } from './controllers/GroupCallController';
var testCallUsers = []
for (let index = 0; index < 6; index++) {
    const element = {user : {id : index, name : 'Test', surname : 'Testov'}, stream : {}};
    testCallUsers.push(element)
}
var streams = []
var localStream = undefined
var startCallTime = 0
var isAllMutedPermanent = false
function GroupCall(props) {
    const urlParamIsVideo = getUrlParameter("is_video")
    const isVideoOnFromUrl = urlParamIsVideo != null && urlParamIsVideo != undefined && urlParamIsVideo != ""
    const [conversationToken, setConversationToken] = useState("")
    const bigVideoRef = useRef(null);
  
    const [isCallAccepted, setCallAccepted] = useState(false)
    const [isCallPaused, setCallPaused] = useState(false)
    const [hasUnreadedMsgs, setHasUnreadedMsgs] = useState(false)

    const [isShowCallInfo, setShowCallInfo] = useState(false)
    const [isScreenShareEnabled, setScreenShareEnabled] = useState(false)
    const [isMyMicPermanentMuted, setMyMicPermanentMuted] = useState(false)
    const [isHandUp, setHandUp] = useState(false)
    const [speakingUserId, setSpeakingUserId] = useState(-1)
    const [cameras, setCameras] = useState([])
    
    const [isVideoOn, setVideoOn] = useState(isVideoOnFromUrl)
    const [isShowSafariScreenShare, setShowSafariScreenShare] = useState(false)
    const [isShowCloseCall, setShowCloseCall] = useState(false)
    const [isShowParticipants, setShowParticipants] = useState(true)
    const [isShowSafariIncomingScreenShare, setShowSafariIncomingScreenShare] = useState(false)
    const [isShowCallChat, setShowCallChat] = useState(false)
    const [currentChat, setCurrentChat] = useState(undefined)

    const [aspectRatio, setAspectRatio] = useState(1)
    const [isShowCantPause, setShowCantPause] = useState(false)
    const [isShowDisableMic, setShowDisableMic] = useState(false)
    const [conversation, setConversation] = useState({})
    const [videoStreams, setVideoStreams] = useState([])
    const [handUpStream, setHandUpStream] = useState({})
    const [planner, setPlanner] = useState({})
    const [toastText, setToastText] = useState("")
    var plannerInfo = {}
    

    const [isShowScreenShareError, setShowScreenShareError] = useState(false)
    const [isShowMicError, setShowMicError] = useState(false)
    const [isShowCameraError, setShowCameraError] = useState(false)
    const [isMicOn, setMicOn] = useState(false)
    const [checkRepetitorResult, setCheckRepetitor] = useState(props.checkRepetitorResult ? props.checkRepetitorResult : {})

    const callOptions = ["AUDIO", "VIDEO", "SCREEN_SHARING"]
    const isDesktop = getIsDesktop()
    const config = props.config
    const socketMsg = props.socketMessage

    async function standartVkInit (){
        console.log("props.user.vk_call_token = ", props.user.vk_call_token)
        const id = getUrlParameter("id")
         window.onpopstate = function(event) {
            window.location.href = Pages.group_call_info + "/" + id
        }
        await SDK.init({
            apiKey: getApiKey(),
            authToken: props.user.vk_call_token,
            onLocalStream: (stream, mediaSettings) => GroupCallController.onLocalStream(stream, mediaSettings, plannerInfo, streams, setVideoStreams, addStream, isVideoOn, (stream) => {
                localStream = stream
            }), 
            onHangup : (type) => { declineCall() },
            onScreenStream : (stream, mediaSettings) => GroupCallController.onScreenStream(stream, mediaSettings, plannerInfo, streams, setVideoStreams, setScreenShareEnabled),
            onLocalStatus : (status) => GroupCallController.onLocalStatus(status),
            onDeviceSwitched: (mediaOptions, enabled) => GroupCallController.onDeviceSwitched(mediaOptions, enabled, streams, setMicOn, setVideoOn, setVideoStreams),
            onRemoteMediaSettings: (userId, mediaSettings) => GroupCallController.onRemoteMediaSettings(userId, mediaSettings, streams, plannerInfo, setVideoStreams),
            onRemoteScreenStream: (user, stream) => {
                //remoteScreenStream = stream
                console.log("onRemoteScreenStream userId = ", user)
                console.log("onRemoteScreenStream stream = ", stream)
                console.log("onRemoteScreenStream plannerInfo.creator.vk_caller_id = ", plannerInfo.creator.vk_caller_id)
                const isUserCreator = (plannerInfo.creator.vk_caller_id == user.id)
                if (isUserCreator){
                    console.log("onRemoteScreenStream play")
                    const foundIndex = streams.findIndex((obj => obj.user.id == user.id))
                    streams[foundIndex].user.is_video_on = false
                    streams[foundIndex].user.is_screen_sharing = true
                    streams[foundIndex].screen_stream = stream
                    setVideoStreams([...streams])
                }
            },
            onSpeakerChanged: (userId) => {  
                console.log('onSpeakerChanged = ', userId);
                setSpeakingUserId(userId.id)
            },           
            onRemoteStream: (useriId, stream) => {                
                // remoteStream = stream
                // if (screenStream == undefined){
                //     playVideo(smallVideoRef, localStream)
                // } 
                // if (stream){
                //     playVideo(bigVideoRef, stream)
                // }
                console.log('onRemoteStream = ', useriId);
                console.log('onRemoteStream stream = ', stream);

                setCallAccepted(true)
                // setTimeout(function() {
                //     addStream(useriId, stream, false)
                // }, 1000)
                addStream(useriId, stream, false)
               
            },
            onStatistics: (stats) => {
                console.log('onStatistics', stats);
            },
            onParticipantStatus: (userId, status) => {
                console.log("onParticipantStatus = ", userId)
                console.log("onParticipantStatus status = ", status)
            },
            onParticipantJoined: async (userId) => {
                console.log("onParticipantJoined = ", userId)
                
                // if (userId.id + "" == getCookie("id")){
                //     declineCall()
                //     return
                // }
                if (userId.id + "" != getCookie("id")){
                    addStream(userId, undefined, false)
                }
                const is_creator = (plannerInfo.creator.vk_caller_id == getCookie("id"))
                console.log("onParticipantJoined is_creator = ", is_creator)
                if (is_creator){
                    await SDK.muteParticipant(userId.id, {"AUDIO" : isAllMutedPermanent ? SDK.MuteState.MUTE_PERMANENT : SDK.MuteState.MUTE})
                    console.log("onParticipantJoined isAllMutedPermanent = ", isAllMutedPermanent)

                }
            },
            onRemoteRemoved: (userId) => {
                const foundIndex = streams.findIndex((obj => obj.user.id == userId.id))
                if (foundIndex != -1){
                    streams.splice(foundIndex, 1)
                }   
                setVideoStreams([...streams])
            },
            onConversation: (userId, mediaModifiers, muteStates, participants) => {
                console.log('onConversation userId = ', userId);
                console.log('participants = ', participants);
                const isUserCreator = (plannerInfo.creator.vk_caller_id == userId.id)

                if (!isUserCreator && muteStates.AUDIO != undefined){
                    setMyMicPermanentMuted(muteStates.AUDIO == SDK.MuteState.MUTE_PERMANENT)
                }
                participants.forEach(element => {
                    addStream(element.uid, undefined, false)
                    if (isUserCreator){
                        SDK.muteParticipant(element.uid.id, {"AUDIO" : isAllMutedPermanent ? SDK.MuteState.MUTE_PERMANENT : SDK.MuteState.MUTE})
                    }
                    // if (element.uid.id == getCookie("id")){
                    //     declineCall()
                    //     return
                    // }
                })
                const layouts = participants
                    .map(({uid, mediaSettings}) => ({
                        uid,
                        mediaType: mediaSettings.isScreenSharingEnabled ? SDK.MediaType.SCREEN : SDK.MediaType.CAMERA
                    }));

                SDK.updateDisplayLayout(layouts)
                
                callStartedServerEvent()
            },
            onCallState: (isCallActive, canAddParticipants, conversation) => {
                console.log('onCallState conversation = ', conversation);
                console.log('onCallState isCallActive = ', isCallActive);
                setConversation(conversation)
                setCallAccepted(isCallActive)

                const is_i_creator = plannerInfo.creator ? plannerInfo.creator.vk_caller_id == getCookie("id") : false

                if (isCallActive && is_i_creator){
                    PlannerApi.repetitorReadyToSozvonByLink(plannerInfo._id, (responseData) => {})
                }
            },
            onMuteStates: (muteStates) => {
                const isUserCreator = (plannerInfo.creator.vk_caller_id == getCookie("id"))
                if (muteStates.AUDIO != undefined && !isUserCreator){
                    const isPermaDisabled = muteStates.AUDIO == SDK.MuteState.MUTE_PERMANENT
                    setMyMicPermanentMuted(isPermaDisabled)
                    if (isPermaDisabled){
                        showToast("Организатор отключил ваш микрофон")
                    }
                    
                    const isMicrophoneOn = muteStates.AUDIO != SDK.MuteState.MUTE_PERMANENT && muteStates.AUDIO != SDK.MuteState.MUTE
                    if (!isMicrophoneOn){
                        setMicOn(isMicrophoneOn)
                    } else {
                        setHandUp(false)
                    }
                }
            },
            onRemoteParticipantState : (user, participantState, markers) => {
                const is_creator = (plannerInfo.creator.vk_caller_id == getCookie("id"))
                if (is_creator){
                    const foundIndex = streams.findIndex((obj => obj.user.id == user.id))
                    if (foundIndex != -1 && streams[foundIndex].user != undefined && participantState["up"] != undefined){
                        streams[foundIndex].user.is_hand_up = participantState["up"].state == "1"
                        setVideoStreams([...streams])
                    }
                }
            },
            onCustomData : (data, user) => {
                if (data.up == "0"){
                    toggleHangUp(false)
                }
                if (data.can_speak == "0"){
                    showToast("Организатор опустил вашу руку")
                } else if (data.can_speak == "1"){
                    showToast("Вам разрешили включить микрофон")
                }
                // console.log("onCustomData data = ", data)
                // console.log("onCustomData user = ", user)
            }
            
          
        })
        await SDK.authorize()
        const convTokenFromUrl = getUrlParameter("conv_token")
        if (convTokenFromUrl != undefined && convTokenFromUrl != null && convTokenFromUrl != ""){
            await SDK.joinCallByLink(convTokenFromUrl, ["AUDIO"])
            setConversationToken(convTokenFromUrl)
        } else {
            const convToken = await SDK.startConversation()
            console.log("startConversation link = ", convToken)
            setConversationToken(convToken)
            try {
                await SDK.joinCallByLink(convToken, ["AUDIO"])
            } catch (error) {
                console.log("joinCallByLink error = ", error)
            }
            
        }
        setCameras(SDK.browser.getCameras())
        await SDK.toggleLocalVideo(isVideoOn)
        setSavedMic()
        startCallTime = moment().unix()
       
    }
    const swapStream = () => {

    }
    const addStream = (user, stream, is_video_turned_on) => {
        const foundIndex = streams.findIndex((obj => obj.user.id == user.id))
        console.log("foundIndex = ", foundIndex)
        console.log("addStream = ", stream)
        if (foundIndex != -1){
            const isUserCreator = (plannerInfo.creator.vk_caller_id == streams[foundIndex].user.id)
            streams[foundIndex].stream = stream
            streams[foundIndex].user.is_creator = isUserCreator
            streams[foundIndex].user.is_video_on = (is_video_turned_on != undefined && !is_video_turned_on) ? false : stream != undefined
            // if (stream != undefined && isUserCreator){
            //     playVideo(bigVideoRef, stream)
            // }
        } else {
            const isUserCreator = (plannerInfo.creator.vk_caller_id == user.id)
            var user = {...user}
            user.is_creator = isUserCreator
            user.is_video_on = (is_video_turned_on != undefined && !is_video_turned_on) ? false : stream != undefined
            streams.push({user : user, stream : stream})
            // if (stream != undefined && isUserCreator){
            //     playVideo(bigVideoRef, stream)
            // }
            loadUserInfo(user.id)

        }
        
        setVideoStreams([...streams])
        // streams.push({user : user, stream : stream})
        
        console.log("addStream streams = ", streams)
    }
    const loadUserInfo = (id) => {
        UsersApi.getUserByVkCallerID(id, (mUser) => {
            const foundIndex = streams.findIndex((obj => obj.user.id == id))
            if (foundIndex != -1){
                streams[foundIndex].user_info = mUser
                setVideoStreams([...streams])
                getDialogInfo(id)
            }
        }, () => {}, 1)
    }
    const callStartedServerEvent = () => {
        CallApi.newCallSozvonByLink(getUrlParameter("id"), (response) => {})
    }
    const getDialogInfo = (id) => {
        var is_i_creator = plannerInfo.creator_vk_caller_id == getCookie("id")
        
        if (is_i_creator){
            const foundIndex = streams.findIndex((obj => obj.user.id == id))
            const to_id = streams[foundIndex].user.id
            DialogsApi.getDialogInfo(to_id, (responseData) => {
                var chat = {user : streams[foundIndex].user_info}
                chat.dialog = responseData.dialogInfo
                setCurrentChat(chat)
            })
        } else {
            DialogsApi.getDialogInfo(plannerInfo.creator_vk_caller_id, (responseData) => {
                var chat = {user : plannerInfo.creator}
                chat.dialog = responseData.dialogInfo
                setCurrentChat(chat)
            })
        }
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 4000)
    }
    useEffect(() => {            
            PlannerApi.getSozvonByLinkById({"_id" : getUrlParameter("id")}, (responseData) => {
                const planner = responseData.planner
                if (planner.creator.vk_caller_id)
                setPlanner(planner)
                const is_i_creator = planner.creator ? planner.creator.vk_caller_id == getCookie("id") : false
                if (!is_i_creator){
                    streams.push({user : {id : getCookie("id")}, stream : localStream, user_info : props.user})
                    setVideoStreams([...streams])
                }
                plannerInfo = planner
                standartVkInit()
            }, (responseData) => {})
        
    }, [])
    useEffect(() => {
        const foundIndex = streams.findIndex((obj => obj.user.id == getCookie("id")))
        if (foundIndex != -1){
            streams[foundIndex].user.is_video_on = isVideoOn
            setVideoStreams([...streams])
        }
    }, [isVideoOn])
   
    const openChat = () => {
        if (isShowCallChat){
            setShowCallChat(!isShowCallChat)
        } else if (currentChat != undefined && props.user != undefined && streams.length > 0){
            setShowCallChat(!isShowCallChat)
            setHasUnreadedMsgs(false)
        }
        
    }
  
   
    const closeCall = () => {
        props.getMyInfo()
    }
   
     useEffect(() => {
        const isOpenedChatMessage = currentChat != undefined && (streams.filter(s => s.user.id + "_" + getCookie("id") == socketMsg.dialog_id || getCookie("id") + "_" + s.user.id == socketMsg.dialog_id).length > 0)
        if (socketMsg != undefined && socketMsg.event === "message" && isOpenedChatMessage){
            if (socketMsg.to_id == getCookie("id") && !isShowCallChat){
                setHasUnreadedMsgs(true)
            }
         
            
        }
      
    }, [socketMsg])
    
  
    
    const stopVideo = (ref) => {
        let video = ref.current
        if (video == undefined){
            return
        }
        try {
            video.srcObject = undefined
            video.pause()    
        } catch {
            console.log("play video error")
        }
    }
    const playVideo = (ref, stream) => {
        let video = ref.current
        if (video == undefined){
            return
        }
        console.log("play video ref = ", ref)
        // video.addEventListener("loadedmetadata", function () {
        //     const height = this.videoHeight
        //     const width = this.videoWidth
        //     setAspectRatio(height/width)
        //     console.log("playVideo playVideo = ", (height/width))
        // }, false)
        if (ref == bigVideoRef){
            video.addEventListener("resize", function () {
                const height = this.videoHeight
                const width = this.videoWidth
                setAspectRatio(height/width)
                console.log("setAspectRatio height/width = ", (height/width))
            }, false)
        }
        if (stream != undefined && stream != null){
            console.log("playVideo stream = ", stream)
            try {
                video.srcObject = stream
                video.play()    
            } catch {
                console.log("play video error")
            }
              
        }  
    }
 
    const setSavedMic = async () => {
        const mic = SDK.browser.getSavedMicrophone() != null ? SDK.browser.getSavedMicrophone() : SDK.browser.getMicrophones().length > 0 ? SDK.browser.getMicrophones()[0] : null
        if (mic != null){
            try {
                await SDK.changeDevice(mic.kind, mic.deviceId)
            } catch (error) {
                console.log(error)
            }
            toggleMic(false)
            // if (isMyMicPermanentMuted){
            //     return
            // }
            // try {
            //     await SDK.toggleLocalAudio(true)
            //     setMicOn(true)
            //     const foundIndex = streams.findIndex((obj => obj.user.id == getCookie("id")))
            //     if (foundIndex != -1){
            //         streams[foundIndex].user.is_mic_on = true
            //     }
            // } catch (error) {
            //     setMicOn(false)
            //     if (getOS() == "macos"){
            //         setShowMicError(true)
            //     }
            // }
           
            console.log("setSavedMic = ", mic)
        }
    }
   
    const declineCall = () => {
        console.log("declineCall start")
        if (isShowCallChat){
            setShowCallChat(!isShowCallChat)
        }
        // try {
        //     var writeReviewInfo = {}
        //     if (!isCreator){
        //         writeReviewInfo = checkNeedWriteReview(planner.creator_vk_caller_id)
        //     }
           
        //     SDK.hangup()
        // } catch (error) {
        //     console.log("declineCall error = ", error)
        // }
        // const redirectUrl = window.location.origin + Pages.group_call_info + "/" + getUrlParameter("id") + (writeReviewInfo.isShow ? "?is_write_review_time=" + writeReviewInfo.time : "")
        var writeReviewInfo = {}
        if (!isCreator){
            writeReviewInfo = checkNeedWriteReview(planner.creator_vk_caller_id)
        }
        const redirectUrl = window.location.origin + Pages.group_call_info + "/" + getUrlParameter("id") + (writeReviewInfo.isShow ? ("?is_write_review_time=" + writeReviewInfo.time) : "")
        window.location.href = redirectUrl
        console.log("declineCall redirectUrl = ", redirectUrl)
        console.log("declineCall end")
    }
    
    const changeScreenShare = async (isEnabled) => {
  
        console.log("captureScreen = " + isEnabled)
        
        try {
            await SDK.captureScreen(isEnabled)
        } catch (error) {
            setScreenShareEnabled(false)
            if (getOS() == "macos"){
                setShowScreenShareError(true)
            }
        }
        if (!isEnabled){
            playVideo(bigVideoRef, localStream)
        }
      
        
    }
    const toggleMic = (isEnabled) => {
        setMicOn(isEnabled)
        const foundIndex = streams.findIndex((obj => obj.user.id == getCookie("id")))
        if (foundIndex != -1){
            streams[foundIndex].user.is_mic_on = isEnabled
            setVideoStreams([...streams])
        }
        
    }
    const isCreator = planner.creator ? planner.creator.vk_caller_id == getCookie("id") : false

    const disableAllMic = async (isPermanent) => {
        isAllMutedPermanent = isPermanent
        streams.forEach(async s => {
            if (isCreator){
                try {
                    await SDK.muteParticipant(s.user.id, {"AUDIO" : isPermanent ? SDK.MuteState.MUTE_PERMANENT : SDK.MuteState.MUTE})
                } catch (error) {
                    
                }
            }
        })
        
    }
    const toggleHangUp = async (value) => {
        setHandUp(value)
        try {
            await SDK.changeParticipantState({"up" : value ? "1" : "0"})
            console.log("toggleHangUp = ", value)
        } catch (error) {
            console.log("handUp error = ", error)
        }
    }
    const toggleVolume = (isEnabled) => {
        let video = bigVideoRef.current
        if (isEnabled){
            video.volume = 1
        } else {
            video.volume = 0
        }
    }

    const checkNeedWriteReview = (id) => {
        const timePlus3min = (startCallTime / 1 + (3 * 60))
        const thisTime = moment().unix() / 1
        const isReadyToShow = timePlus3min <= thisTime
        // const isReadyToShow = timePlus3min <= thisTime
        // console.log("checkNeedWriteReview timePlus3min = ", timePlus3min)
        // console.log("checkNeedWriteReview thisTime = ", thisTime)
        // console.log("checkNeedWriteReview startCallTime = ", startCallTime / 1)
        // console.log("checkNeedWriteReview isReadyToShow 1 = ", (thisTime - (startCallTime / 1)) / 60)
        // console.log("checkNeedWriteReview isReadyToShow 2 = ", isReadyToShow)
        if (isReadyToShow && startCallTime > 0){ 
            return {isShow : true, price : plannerInfo.price, time : (thisTime - (startCallTime / 1)) / 60 , to_id : id}
        }
        return {}
    }
    
    const boardUrl = planner.creator ? (Pages.call_board + planner.creator.vk_caller_id + "_" + getUrlParameter("id")) : ""
    const isDesktopChat = isDesktop && isShowCallChat
    return (
        <div className="call_screen_container flex">
            <div style={isCallAccepted ? {background : '#2D2535'} : {background: '#2C1444'}} className={isDesktopChat ? "call_screen_container_with_chat" : "call_screen_container"}>
                <CallNavbar isGroupCall setShowCloseCall={setShowCloseCall} isCallAccepted={isCallAccepted} declineCall={declineCall} isShowCallInfo={isShowCallInfo} toggleVolume={toggleVolume} cameras={cameras} setShowCallInfo={setShowCallInfo} setCallerUser={props.setCallerUser}/>
                
                <GroupVideoStreams onHandClicked={(s) => setHandUpStream(s)} toggleHangUp={toggleHangUp} isHandUp={isHandUp} setShowDisableMic={setShowDisableMic} planner={planner} speakingUserId={speakingUserId} is_i_creator={isCreator} playVideo={playVideo} stopVideo={stopVideo} isShowParticipants={isShowParticipants} conversationToken={conversationToken} showToast={showToast} streams={videoStreams} isScreenShareEnabled={isScreenShareEnabled} user={props.user} isCallAccepted={isCallAccepted} bigVideoRef={bigVideoRef} aspectRatio={aspectRatio}/>
                {planner.creator != undefined && <GroupCallTimer speakingUserId={speakingUserId} streams={videoStreams} isShowParticipants={isShowParticipants} planner={planner}/>}
                <CallBottomBar isHandUp={isHandUp} toggleHangUp={toggleHangUp} toastText={toastText} showToast={showToast} isMyMicPermanentMuted={isMyMicPermanentMuted} disableAllMic={() => setShowDisableMic(true)} hasUnreadedMsgs={hasUnreadedMsgs} boardUrl={boardUrl} isGroupCall is_i_creator={isCreator} videoStreams={videoStreams} isShowParticipants={isShowParticipants} setShowParticipants={setShowParticipants} isMicOn={isMicOn} setMicOn={toggleMic} setShowMicError={setShowMicError} setShowCameraError={setShowCameraError} conversation={conversation} checkRepetitorResult={checkRepetitorResult} setShowCantPause={setShowCantPause} setShowCloseCall={setShowCloseCall} isVideoOn={isVideoOn} setVideoOn={setVideoOn} isScreenShareEnabled={isScreenShareEnabled} changeScreenShare={changeScreenShare} isShowCallChat={isShowCallChat} isCallPaused={isCallPaused} setCallPaused={setCallPaused} isIRepetitor={false} openChat={openChat} declineCall={declineCall} callToUser={{}}/>
                <SafariScreenShareScreen isShow={isShowSafariScreenShare} setShow={setShowSafariScreenShare}/>
                <SafariIncomingScreenShare isShow={isShowSafariIncomingScreenShare} setShow={setShowSafariIncomingScreenShare}/>
                <CloseCallBottomSheet isShow={isShowCloseCall} setShow={setShowCloseCall} declineCall={declineCall} />
                <CantPauseDialog isShow={isShowCantPause} setShow={setShowCantPause}/>
                <ScreenShareErrorDialog isShow={isShowScreenShareError} setShow={setShowScreenShareError}/>
                <CallCameraErrorDialog isShow={isShowCameraError} setShow={setShowCameraError}/>
                <CallMicErrorDialog isShow={isShowMicError} setShow={setShowMicError}/>
                <HandUpDialog stream={handUpStream} isShow={handUpStream.user != undefined} setShow={() => {setHandUpStream({})}}/>
                <DisableMicDialog disableAllMic={disableAllMic} isShow={isShowDisableMic} setShow={setShowDisableMic}/>
                {(isDesktop && isScreenShareEnabled) && <div onClick={() => changeScreenShare(false)} className="stop_screen_share hover"><p className="stop_screen_share_text">Идёт демонстрация экрана</p><img className="stop_screen_share_icon" src={stop_screen_share} alt=""/></div>}
                
            </div>
            {(isShowCallChat) && <Chat setHasUnreadedMsgs={setHasUnreadedMsgs} is_i_creator={isCreator} videoStreams={isCreator ? videoStreams : [streams.filter(s => s.user.is_creator)[0]]} planner={planner} setShowCallChat={setShowCallChat} isGroupCall fromCall setCurrentChat={setCurrentChat} currentChat={currentChat} isRestoredRecconnect={false} dialogs={[]} removeUnreadedMsgs={props.removeUnreadedMsgs} updateBalance={props.updateBalance} config={config} isShowCallChat={isShowCallChat} getMyInfo={props.getMyInfo} setBalance={() => {}} clearBadge={() => {}} socketMessage={props.socketMessage} user={props.user}/>}
            <img alt='' className="video_logo_img_group_call" src={video_logo}/>
        </div>
    );
}

export default GroupCall;
