import { getIsDesktop } from "../../../utils/Utils";
function ProfileBlogsWhyTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="best_exp_recommended_tooltip">
                <div style={{bottom : '6px', position : 'absolute'}} className="green_rectangle"></div>
                <div style={{width : '256px'}} className="green_profile_tooltip">
                Написание постов поможет показать вашу экспертность и заинтересовать новых клиентов.
                <br/><br/>
                Посты будут публиковаться в разделе “Лента публикаций” и в вашем профиле.
                </div>
            </div>
        </div>
     );
 }
 
 export default ProfileBlogsWhyTooltip;
 