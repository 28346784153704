/* eslint-disable eqeqeq */
import moment from 'moment';
import { Pages } from '../../../utils/Utils';

function CourseCallItem({ item, index, isShowAll }) {
    if (index > 4 && !isShowAll){
        return null
    }
    return(
        <a rel="noreferrer" href={Pages.group_call_info + "/" + item._id} className='hover'  target="_blank">
            <div className='flex'>
                <div className='number_item'>{index + 1}.</div>
                <div>
                    <div className='title_item'>{item.sozvon_name}</div>
                    <div className='descr_item'>{moment(item.sozvon_date).utc().local().format("DD MMM, dd  ·  HH:mm")}</div>
                </div>
            </div>
        </a>
    )
}
export default CourseCallItem
