import new_icon from '../../../../img/create_ads/new.svg'
import { useHistory, Link } from "react-router-dom";
import { getAdsPrices, getSexes, getHowLong, linkify, getAges, getEducationTargets } from '../../CreateAds/helpers/CreateAdsHelper';
import AdsItemProfile from '../custom_views/AdsItemProfile';
import { Pages } from '../../../../utils/Utils';

function AdsItem(props) {
    const history = useHistory()
    const item = props.item
    const extraData = item.extra_data == undefined ? "" : item.extra_data.includes("\n") ? ("<div>" +  item.extra_data.replaceAll("\n", "<br/>") + "</div>") : item.extra_data
    const onClick = () => {
        props.setPickedAds(item)
        // history.push()
    }
    return (
        <Link to={Pages.ads_board + "/ads?id=" + item._id}>
            <div onClick={onClick} className="ads_item_container hover">
                <div className="ads_item_spec">{item.specialization}</div>
                <div className="ads_item_header">
                    <div className="ads_item_header_title ads_item_title_mb">{item.name}</div>
                    {item.views == 0 && <img className="ads_mew_icon" src={new_icon}/>}
                </div>
                <div className="ads_item_header_title ads_item_price_mb">{item.level_repetitor == 0 ? "Цена не имеет значения" : getAdsPrices()[item.level_repetitor]}</div>
                
                <div className="ads_item_info_container">
                    {/* <div className="ads_item_info_text">{item.target_education} · {(item.sex_repetitor == 1 || item.sex_repetitor == 2) ? "Эксперт-" + getSexes()[item.sex_repetitor].toLocaleLowerCase() : "Пол не важен"} · {getHowLong()[item.training_period]}</div> */}
                    <div style={{marginRight : '4px'}} className="ads_item_info_text">{getEducationTargets()[item.target_education]} ·</div>
                    <div style={{marginRight : '4px'}} className="ads_item_info_text">{(item.sex_repetitor == 1 || item.sex_repetitor == 2) ? "Эксперт-" + getSexes()[item.sex_repetitor].toLocaleLowerCase() : "Пол не важен"} · </div>
                    <div className="ads_item_info_text">{item.training_period == 1 ? "Много занятий" : getHowLong()[item.training_period]}</div>
                    {item.extra_data && <div className="ads_item_extra_data ads_item_extra_data_max" dangerouslySetInnerHTML={{__html: linkify(extraData)}}></div>}
                    <AdsItemProfile item={item}/>
                </div>
            </div>
        </Link>
    )
}

export default AdsItem;
