import crown from '../../../../img/chat/crown.svg'

function ProIcon(props) {
    const isShow = props.isShow
    if (!isShow){
        return null
    }
    return(
        <div className="pro_icon_container">
            <img alt="" src={crown}/>
            <div>PRO</div>
        </div>
    )
}
export default ProIcon;