import { useState} from 'react';
import ProfileSkills from './custom_views/ProfileSkills';

function Step7(props) {

    const [specializations, setSpecializations] = useState(props.profileInfo.tags ? props.profileInfo.tags : [])
    const isEnableButton = specializations.length > 0
    
    const removeTag = (tag, index) => {
        var specs = [...specializations]
        specs.splice(index, 1)
        setSpecializations(specs)
    }
    const addTag = (tag) => {
        var specs = [...specializations]
        if (!specs.includes(tag)){
            specs.push(tag)
            setSpecializations(specs)
        }
    }
    

    return (
        <div>
           <p className="profile_fill_start_title title_step">Ваши навыки (теги)</p>
           <p style={{marginBottom : '62px'}} className="profile_fill_start_desc">По указанным навыкам вас смогут найти новые клиенты. Набор навыков можно отредактировать в профиле.</p>
           <ProfileSkills addTag={addTag} removeTag={removeTag} skills={specializations}/>
           <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep7(specializations)} className="fill_button hover profile_bottom_button">Далее</button>
        </div>
    );
}

export default Step7;
