import '../../styles/profile_filling.css'
import ProfileFillNavbar from './custom_views/ProfileFillNavbar';
import ProfileFillStart from './ProfileFillStart';
import { useState, useEffect, useCallback } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import { getUrlParameter, checkAfterAuth, Pages } from '../../utils/Utils';
import moment from 'moment'
import { Route, useHistory } from "react-router-dom";
import auth_bg_img from '../../img/auth_bg_img.svg'
import { UsersApi } from '../../api/UsersApi';
import VkGroupDialog from './dialogs/VkGroupDialog';

function ProfileFilling(props) {
    const [step, setSt] = useState(0)
    const [profileInfo, setProfileInfo] = useState({})
    const [isShowVkDialog, setShowVkDialog] = useState(false)
    const history = useHistory()

    const config = props.config
    
    const setStep = useCallback((s) => {
        setSt(s)
        history.push("/profile_filling/step_" + s)
    }, [history])
    const onBack = useCallback((s) => {
        setSt(s)
        history.goBack()
    }, [history])
    const changeStep = (step) => { 
        setStep(step)
    }
    const saveStep1 = (name, surname, bdate, sex, email, city, country) => {
        setStep(2)
        const info = {...profileInfo}
        info.name = name
        info.surname = surname
        info.birthday = bdate
        info.sex = sex
        info.email = email
        if (city){
            info.city = city
        }
        if (country){
            info.country = country
        }
        
        setProfileInfo(info)
    }
    const saveStep2 = (avatar) => {
        const info = {...profileInfo}
        info.avatar = avatar
        setProfileInfo(info)
    }
    const saveStep3 = (type, token) => {
        setStep(4)
        const info = {...profileInfo}
        info.type = type
        if (token){
            info.push_token = token
        }
        setProfileInfo(info)
    }
    const saveStep4 = (rub_in_hour, free_time) => {
        setStep(5)
        const info = {...profileInfo}
        info.rub_in_hour = rub_in_hour
        info.free_time = free_time
        setProfileInfo(info)
    }
    const saveStep5 = (rub_per_message) => {
        setStep(6)
        const info = {...profileInfo}
        info.rub_per_message = rub_per_message
        setProfileInfo(info)
    }
    const saveStep6 = (specializations) => {
        setStep(7)
        const info = {...profileInfo}
        info.specs = specializations
        setProfileInfo(info)
    }
    const saveStep7 = (skills) => {
        setStep(8)
        const info = {...profileInfo}
        info.tags = skills
        setProfileInfo(info)
    }
    const saveStep8 = (bio) => {
        const info = {...profileInfo}
        info.bio = bio
        setProfileInfo(info)
        setMyInfo(info)
    }
    const iAmClient = (token) => {
        const info = {...profileInfo}
        if (token){
            info.push_token = token
        }
        info.type = 0
        info.is_repetitor = false
        setMyInfo(info)
    }
    // const setPushToken = (token) => {
    //     const info = {...profileInfo}
    //     info.push_token = token
    //     setProfileInfo(info)
    //     console.log("setPushToken info = ", info)
    // }
    const setMyInfo = (info) => {
        var params = info
        var bdate = params.birthday
        if (params.surname == undefined || info.name == undefined){
            setStep(0)
            return
        }
        if (params.birthday.length > 10){
            bdate = params.birthday.slice(0, 10)
            params.bdate = moment(bdate + ".24:00", "DD.MM.YYYY.HH:mm").unix() * 1000
        } else if (bdate.length == 10) {
            params.bdate = moment(bdate + ".24:00", "DD.MM.YYYY.HH:mm").unix() * 1000
        }
        if (params.specs != undefined && params.specs.length > 0){
            params.specializations = params.specs.join(',')
        }
        if (params.tags != undefined && params.tags.length > 0){
            params.skills = params.tags.join(',')
        }
        if (info.type == 1){
            params.is_repetitor = true
        }
        UsersApi.setMyInfo(params, (responseData) => {
            if (params.is_repetitor){
                //window.location.href = "/profile?show_tips=1"
                window.location.href = Pages.ads_board
            } else {
                if (checkAfterAuth()){
                    return
                }
                window.location.href = Pages.experts
            }
        })

    }
    

    useEffect(() => {
        // if (props.user.name != undefined){
        //     setProfileInfo(formatUser(props.user))
        // }
        if (step == 0 && !window.location.pathname.includes("/step_0")){
            history.push('/profile_filling/step_0')
        }
        getMyInfo()
        
    }, [])
    const getMyInfo = () => {
        UsersApi.getMyInfo((mUser) => {
            if (mUser.name != undefined){
                setProfileInfo(formatUser(mUser))
                const is_vk = getUrlParameter("is_vk")
                if (mUser.phone_number.includes("vk") && is_vk != undefined && is_vk != ""){
                    setShowVkDialog(true)
                }
            }
        })
    }
    const formatUser = (mUser) => {
        var user = mUser
        if (user.specializations != undefined){
            user.specs = user.specializations
        }

        if (user.skills != undefined){
            user.tags = user.skills
        }
            
        if (user.bdate){
            user.birthday = moment.unix(moment(user.bdate, "YYYY-MM-DD").unix()).format("DD.MM.YYYY")
        }
        return user
    }
    const profileHref = "/profile_filling"
    return (
        <div className="profile_fill_container">
            <ProfileFillNavbar step={step} onBack={onBack}/>
            <div className="flex_profile_filling">
                <img className="auth_bg_img profile_fill_bg_img" src={auth_bg_img}/>
                <div style={window.location.pathname.includes("/step_8") ? {width : 'calc(100vw - 40px)'} : {}} className="profile_fill_section">
                    <Route path={profileHref + "/step_0"}><ProfileFillStart step={step} setStep={setStep}/></Route>
                    <Route path={profileHref + "/step_1"}><Step1 profileInfo={profileInfo} saveStep1={saveStep1}/></Route>
                    <Route path={profileHref + "/step_2"}><Step2 profileInfo={profileInfo} step={step} setStep={setStep} saveStep2={saveStep2}/></Route>
                    <Route path={profileHref + "/step_3"}><Step3 getMyInfo={getMyInfo} iAmClient={iAmClient} saveStep3={saveStep3} profileInfo={profileInfo} step={step} setStep={setStep}/></Route>
                    <Route path={profileHref + "/step_4"}><Step4 config={config} saveStep4={saveStep4} profileInfo={profileInfo}/></Route>
                    <Route path={profileHref + "/step_5"}><Step5 config={config} saveStep5={saveStep5} profileInfo={profileInfo}/></Route>
                    <Route path={profileHref + "/step_6"}><Step6 saveStep6={saveStep6} profileInfo={profileInfo}/></Route>
                    <Route path={profileHref + "/step_7"}><Step7 saveStep7={saveStep7} profileInfo={profileInfo}/></Route>
                    <Route path={profileHref + "/step_8"}><Step8 saveStep8={saveStep8} profileInfo={profileInfo}/></Route>
                </div>
                <img className="auth_bg_img profile_fill_bg_img" src={auth_bg_img}/>
            </div>
           <VkGroupDialog isShow={isShowVkDialog} setShow={setShowVkDialog}/>
        </div>
    );
}
export default ProfileFilling;
