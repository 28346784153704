/* eslint-disable eqeqeq */
import { useState, useRef, useEffect } from 'react';
import { debounce } from '../../../utils/Utils';
import { linkify } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';

function PlannedGroupInfoDescription(props) {
    const [showButton, setShowButton] = useState(true);
    const containerRef = useRef(null);
    const text = linkify(props.text)
    useEffect(() => {
      const hasClamping = (el) => {
        const { clientHeight, scrollHeight } = el;
        return clientHeight !== scrollHeight;
      };
  
      const checkButtonAvailability = () => {
        if (containerRef.current) {
          // Save current state to reapply later if necessary.
          const hadClampClass = containerRef.current.classList.contains("clamp_planned");
          // Make sure that CSS clamping is applied if aplicable.
          if (!hadClampClass) containerRef.current.classList.add("clamp_planned");
          // Check for clamping and show or hide button accordingly.
          setShowButton(hasClamping(containerRef.current));
          // Sync clamping with local state.
          if (!hadClampClass) containerRef.current.classList.remove("clamp_planned");
        }
      };
  
      const debouncedCheck = debounce(checkButtonAvailability, 50) 
  
      checkButtonAvailability();
      window.addEventListener("resize", debouncedCheck);
  
      return () => {
        window.removeEventListener("resize", debouncedCheck);
      };
    }, [containerRef]);
    const onClampClick = () => {
        props.onOpen()
    }
    
    const formattedText = text == undefined ? "" : text.includes("\n") ? ("<div>" +  text.replaceAll("\n", "<br/>") + "</div>") : text

    return (
      <>
        <div ref={containerRef} className={"experts__item_text clamp_planned"} dangerouslySetInnerHTML={{__html: formattedText}}></div>
        {showButton && <div style={{minHeight : '20px'}}>
            <div style={{color: 'var(--0)'}} className="experts_item_expand hover" onClick={onClampClick}>Читать описание</div>
        </div>}
      </>
    );
  };
export default PlannedGroupInfoDescription;