import { useState, useEffect, useRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { getIsDesktop } from '../../../utils/Utils';
import autosize from 'autosize';
import { UsersApi } from '../../../api/UsersApi';

function UploadYoutubeVideoDialog(props) {
    const [link, setLink] = useState("")
    const refTextarea = useRef(null)
    const isDesktop = getIsDesktop()
    useEffect(() => {
        if (refTextarea != undefined && refTextarea.current != undefined){
            refTextarea.current.focus()
            autosize(refTextarea.current);
        }
    }, [refTextarea])
    const onNext = () => {
        const params = {video_url_youtube : link}
        UsersApi.setMyInfo(params, (responseData) => {
            // const user = {...props.user}
            // user.video_url_youtube = link
            // props.updateUserInfo(user)
            if (props.getMyInfo){
                props.getMyInfo()
            }
            setLink("")
            props.setShow(false)
        })
        // setLink("")
        // props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="profile_upload_youtube_title">Вставьте ссылку на ролик из ВК</p>
                <p className="lk_youtube_desc">Ролик должен быть размещён на vk.com</p>
                <div className="flex">
                    <textarea ref={refTextarea} placeholder="Ссылка" className="lk_youtube_input profile_upload_youtube_input"  value={link} onChange={(e) => setLink(e.target.value)}/>
                </div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px', color : 'white'}} onClick={onNext} className="call_price_continue hover">Сохранить</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default UploadYoutubeVideoDialog;
