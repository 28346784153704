import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { log, onResponse, setApiMetadata } from "./ApiUtils";
export class AdsApi {
    
    static createRequestRepetitor(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createRequestRepetitor", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createRequestRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static editRequestRepetitor(params, onSuccess, onError) {
        log("editRequestRepetitor params = ", params)
        fetch(getRequestUrl() + "editRequestRepetitor", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("editRequestRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static searchRequestsRepetitor(params, onSuccess, onError) {
        var p = {...params}
        if ( p.specializations){
            p.specializations = p.specializations.join(",")
        }
        
        console.log("searchRequestsRepetitor params = ", p)
        fetch(getRequestUrl() + "searchRequestsRepetitor", getHttpParams(p)).then((response) => response.json())
        .then((responseData) => {
            log("searchRequestsRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
    static respondRequestRepetitor(repetitor_request_id, message, onSuccess, onError) {
        const params = {repetitor_request_id : repetitor_request_id, message : message}
        fetch(getRequestUrl() + "respondRequestRepetitor", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("respondRequestRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getRequestRepetitorById(owner_vk_caller_id, repetitor_request_id, onSuccess, onError) {
        const params = {owner_vk_caller_id : owner_vk_caller_id, repetitor_request_id : repetitor_request_id}
        fetch(getRequestUrl() + "getRequestRepetitorById", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getRequestRepetitorById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
            setApiMetadata(responseData.meta_data)

        })
    }
    static getRecRequestsRepetitor(onSuccess, onError) {
        fetch(getRequestUrl() + "getRecRequestsRepetitor", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getRecRequestsRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyRequestsRepetitor(onSuccess, onError) {
        fetch(getRequestUrl() + "getMyRequestsRepetitor", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getMyRequestsRepetitor response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static removeRequestRepetitorById(repetitor_request_id, onSuccess, onError) {
        fetch(getRequestUrl() + "removeRequestRepetitorById", getHttpParams({repetitor_request_id : repetitor_request_id})).then((response) => response.json())
        .then((responseData) => {
            log("removeRequestRepetitorById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getSocialPosts(searchParams, onSuccess, onError) {
        const params = {
            "query": searchParams.text,
            "specializations": searchParams.is_all ? "" : searchParams.specializations.join(",") 
          }
        fetch(getRequestUrl() + "getSocialPosts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getSocialPosts response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getSocialPostById(id, onSuccess, onError) {
        fetch(getRequestUrl() + "getSocialPostById", getHttpParams({_id : id})).then((response) => response.json())
        .then((responseData) => {
            log("getSocialPostById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
            setApiMetadata(responseData.meta_data)
        })
    }
    static getSocialPostLink(id, onSuccess, onError) {
        fetch(getRequestUrl() + "getSocialPostLink", getHttpParams({_id : id})).then((response) => response.json())
        .then((responseData) => {
            log("getSocialPostLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static openContact(user_id, onSuccess, onError) {
        fetch(getRequestUrl() + "openContact", getHttpParams({user_id : user_id})).then((response) => response.json())
        .then((responseData) => {
            log("openContact response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static checkContact(user_id, onSuccess, onError) {
        fetch(getRequestUrl() + "checkContact", getHttpParams({user_id : user_id})).then((response) => response.json())
        .then((responseData) => {
            log("checkContact response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
   
}