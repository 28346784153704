/* eslint-disable eqeqeq */
import '../../../styles/ads_desk.css';
import { useState} from 'react';
import { Route } from "react-router-dom";
import { getCreateAdsProgress, getSavedAds } from './helpers/CreateAdsHelper';
import CreateAdsStep1 from './CreateAdsStep1';
import CreateAdsStep2 from './CreateAdsStep2';
import CreateAdsStep3 from './CreateAdsStep3';
import CreateAdsStep4 from './CreateAdsStep4';
import CreateAdsStep5 from './CreateAdsStep5';
import CreateAdsStep6 from './CreateAdsStep6';
import CreateAdsStep7 from './CreateAdsStep7';
import CreateAdsStep8 from './CreateAdsStep8';
import { getIsDesktop, getUrlParameter } from '../../../utils/Utils';
import { getDesktopStyle } from '../../../utils/Styles';
import CreateAdsStep9 from './CreateAdsStep9';
import CreateAdsStep10 from './CreateAdsStep10';
import CreateAdsStep11 from './CreateAdsStep11';
import CreateAdsSmsCode from './CreateAdsSmsCode';

function CreateAds(props) {
    //const savedInfo = getSavedAds()
    //console.log("savedInfo = ", savedInfo)
    const nameFromUrl = getUrlParameter("name")
    const specFromUrl = getUrlParameter("specialization")

    const [savedInfo, setSavedInfo] = useState(getSavedAds())
    const [name, setName] = useState((nameFromUrl != null && nameFromUrl != undefined && nameFromUrl != "") ? nameFromUrl : savedInfo.name)
    // const [userQueries, setUserQueries] = useState([])
    const [pickedSpecs, setPickedSpecs] = useState((specFromUrl != null && specFromUrl != undefined && specFromUrl != "") ? specFromUrl : savedInfo.specialization)
    const [targetEducation, setTargetEducation] = useState(savedInfo.target_education)
    const [myLevel, setMyLevel] = useState(savedInfo.level_my)
    const [age, setAge] = useState(savedInfo.age)
    const [price, setPrice] = useState(savedInfo.level_repetitor)
    const [trainingPeriod, setTrainingPeriod] = useState(savedInfo.training_period)
    const [time, setTime] = useState(savedInfo.desired_class_time == "" ? [] : savedInfo.desired_class_time.includes(",") ? savedInfo.desired_class_time.split(",") : savedInfo.desired_class_time)
    const [sex, setSex] = useState(savedInfo.sex_repetitor)
    const [extraData, setExtraData] = useState(savedInfo.extra_data)
    // useEffect(() => {
    //     const id = getUrlParameter("id")
    //     if (id != null && id != undefined && id != ""){
    //         AdsApi.getRequestRepetitorById("", id, (response) => {
    //             if (response.response > 0){
    //                 console.log("getRequestRepetitorById result = ", response)
    //                 setSavedInfo(response.result)
    //             }
    //         })  
    //     } 
    // }, [])
    const createAdsHref = "/create_ads"
    const urlIsEdit = getUrlParameter("is_edit")
    const isEdit = urlIsEdit != null && urlIsEdit != undefined && urlIsEdit != ""
    const isDesktop = getIsDesktop()

   
    const adsInfo = {
        "desired_class_time": time.join(","),
        "specialization": pickedSpecs,
        "name": name,
        "target_education": targetEducation,
        "age": age,
        "sex_repetitor": sex,
        "training_period": trainingPeriod,
        "level_my": myLevel,
        "level_repetitor": price,
        "extra_data": extraData
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="create_ads_container">
                <Route path={createAdsHref + "/step_1"}><CreateAdsStep1 isEdit={isEdit} name={name} setName={setName} progress={getCreateAdsProgress(1)}/></Route>
                <Route path={createAdsHref + "/step_2"}><CreateAdsStep2 isEdit={isEdit} specializations={props.specializations} pickedSpecs={pickedSpecs} setPickedSpecs={setPickedSpecs} progress={getCreateAdsProgress(2)}/></Route>
                <Route path={createAdsHref + "/step_3"}><CreateAdsStep3 isEdit={isEdit} pickedSpecs={pickedSpecs} value={targetEducation} setValue={setTargetEducation} progress={getCreateAdsProgress(3)}/></Route>
                <Route path={createAdsHref + "/step_4"}><CreateAdsStep4 isEdit={isEdit} pickedSpecs={pickedSpecs} value={myLevel} setValue={setMyLevel} progress={getCreateAdsProgress(4)}/></Route>
                <Route path={createAdsHref + "/step_5"}><CreateAdsStep5 isEdit={isEdit} pickedSpecs={pickedSpecs} value={age} setValue={setAge} progress={getCreateAdsProgress(5)}/></Route>
                <Route path={createAdsHref + "/step_6"}><CreateAdsStep6 isEdit={isEdit} pickedSpecs={pickedSpecs} value={price} setValue={setPrice} progress={getCreateAdsProgress(6)}/></Route>
                <Route path={createAdsHref + "/step_7"}><CreateAdsStep7 isEdit={isEdit} pickedSpecs={pickedSpecs} value={trainingPeriod} setValue={setTrainingPeriod} progress={getCreateAdsProgress(7)}/></Route>
                <Route path={createAdsHref + "/step_8"}><CreateAdsStep8 isEdit={isEdit} pickedSpecs={pickedSpecs} time={time} setTime={setTime} progress={getCreateAdsProgress(8)}/></Route>
                <Route path={createAdsHref + "/step_9"}><CreateAdsStep9 isEdit={isEdit} pickedSpecs={pickedSpecs} value={sex} setValue={setSex} progress={getCreateAdsProgress(9)}/></Route>
                <Route path={createAdsHref + "/step_10"}><CreateAdsStep10 isEdit={isEdit} adsInfo={adsInfo} pickedSpecs={pickedSpecs} value={extraData} setValue={setExtraData} progress={getCreateAdsProgress(10)}/></Route>
                <Route path={createAdsHref + "/step_11"}><CreateAdsStep11 getMyInfo={props.getMyInfo} isEdit={isEdit} adsInfo={adsInfo} user={props.user} pickedSpecs={pickedSpecs} progress={getCreateAdsProgress(11)}/></Route>
                <Route path={createAdsHref + "/sms_code"}><CreateAdsSmsCode getMyInfo={props.getMyInfo} isEdit={isEdit} adsInfo={adsInfo} user={props.user} pickedSpecs={pickedSpecs} progress={getCreateAdsProgress(11)}/></Route>
            </div>
        </div>
    );
}

export default CreateAds;
