import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { CallHandType, getIsDesktop, Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import * as SDK from '@vkontakte/calls-sdk'
import disable_all_mic from '../../../img/call/disable_all_mic.svg'
function CallMoreDialog(props) {
    const isDesktop = getIsDesktop()
    const isGroupCall = props.isGroupCall
    const isBoardEnabled = props.isBoardEnabled
    const setBoardEnabled = props.setBoardEnabled
    const openChat = () => {
        props.setShow(false)
        props.openChat()
    }
    const showDesc = () => {
        window.open(isGroupCall ? props.boardUrl : Pages.call_board + props.checkRepetitorResult.dialogModel.dialog_id, '_blank')
        props.setShow(false)
    }
    const toggleDesc = () => {
        setBoardEnabled(!isBoardEnabled)
        props.setShow(false)
    }
    const turnOnDemonstration = () => {
        props.changeScreenShare(!props.isScreenShareEnabled)
        props.setShow(false)
    }
    // const changeCallBG = () => {
    //     props.changeCallBG(true)
    //     props.setShow(false)
    // }
    const openMicSettings = () => {
        props.setShowMicDialog(true)
        props.setShow(false)
    }
  
    const handUp = () => {       
        props.setShow(false)
        if (props.toggleHangUp){
            props.toggleHangUp(!props.isHandUp)
        }
    }
    const disableAllMic = () => {
        props.disableAllMic()
        props.setShow(false)
    }
  
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <div className="call_more_title_dialog">Ещё</div>
                {!isDesktop &&
                <div>
                   
                    <div onClick={openChat} className="call_more_btn_dialog hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15ZM7 8C7 7.44772 7.44772 7 8 7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H8C7.44772 9 7 8.55228 7 8ZM8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8Z" fill="#AC92ED"/>
                        </svg>
                        <div className='flex'>
                            <div className="call_more_btn_text">Перейти в чат</div>
                            {props.hasUnreadedMsgs && <div className='chat_new_msgs_dot_mobile'/>}
                        </div>
                    </div>

                    <div onClick={setBoardEnabled ? toggleDesc : showDesc} className="call_more_btn_dialog hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H4ZM15.1142 8.8572L13.1428 6.88574L8.42493 11.6079C7.99518 12.0377 7.67761 12.5665 7.50016 13.1477L7.00784 14.7605C6.96596 14.8977 7.0989 15.0349 7.23934 14.992L8.85217 14.4996C9.43332 14.3222 9.96198 14.0047 10.3917 13.575L15.1142 8.8572ZM16.7558 6.03225L15.9678 5.24412C15.6422 4.91864 15.1145 4.91862 14.789 5.2441L13.9499 6.08573L15.9143 8.05004L16.8131 7.14774C17.0801 6.82034 17.061 6.33741 16.7558 6.03225Z" fill="#AC92ED"/>
                            <path d="M6.5 22C5.94772 22 5.5 21.5523 5.5 21L5.5 19L7.5 19L7.5 21C7.5 21.5523 7.05228 22 6.5 22Z" fill="#AC92ED"/>
                            <path d="M18 22C17.4477 22 17 21.5523 17 21L17 19L19 19L19 21C19 21.5523 18.5523 22 18 22Z" fill="#AC92ED"/>
                            <rect x="7" y="19" width="10" height="1" fill="#AC92ED"/>
                        </svg>
                        <div className="call_more_btn_text">{isBoardEnabled ? "Закрыть доску" :"Доска для совместной работы"}</div>
                    </div>

                    {(props.is_i_creator && SDK.browser.isScreenCapturingSupported()) && <div onClick={turnOnDemonstration} className="call_more_btn_dialog hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 9C2 6.23858 4.23858 4 7 4H17C19.7614 4 22 6.23858 22 9V15C22 17.7614 19.7614 20 17 20H7C4.23858 20 2 17.7614 2 15V9ZM13 10.4142L14.4166 11.8308C14.8071 12.2213 15.4403 12.2213 15.8308 11.8308C16.2213 11.4403 16.2213 10.8071 15.8308 10.4166L12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L8.1692 10.4166C7.77867 10.8071 7.77867 11.4403 8.1692 11.8308C8.55972 12.2213 9.19289 12.2213 9.58341 11.8308L11 10.4142V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V10.4142Z" fill="#AC92ED"/>
                        </svg>
                        <div className="call_more_btn_text">{props.isScreenShareEnabled ? "Выключить" : "Включить"} демонстрацию экрана</div>
                    </div>}
                </div>}
                {/* {(isSupported() && !isGroupCall) && <div onClick={changeCallBG} className="call_more_btn_dialog hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 3C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H18ZM14.7248 12.322C14.5612 12.195 14.3256 12.2247 14.1987 12.3883L11 16.51L8.79807 13.8589C8.78075 13.838 8.76122 13.8191 8.73982 13.8025C8.57634 13.6753 8.34074 13.7048 8.21359 13.8682L5.47073 17.3948C5.41954 17.4606 5.39174 17.5416 5.39174 17.625C5.39174 17.8321 5.55963 18 5.76674 18H18.25C18.3311 18 18.4101 17.9737 18.475 17.925C18.6407 17.8007 18.6743 17.5657 18.55 17.4L14.7949 12.3932C14.7748 12.3664 14.7513 12.3425 14.7248 12.322Z" fill="#AC92ED"/>
                    </svg>
                    <div className="call_more_btn_text">Изменить фон видео</div>
                </div>} */}
                 {(isGroupCall && !props.is_i_creator) && <div onClick={handUp} className="call_more_btn_dialog hover">
                        <span style={{marginLeft : '6px'}}>✋</span>
                        <div className="call_more_btn_text">{props.isHandUp ? "Опустить руку" : "Поднять руку"}</div> 
                    </div>}
                {(isGroupCall && props.disableAllMic && props.is_i_creator) && <div onClick={disableAllMic} className="call_more_btn_dialog hover">
                    <img alt='' src={disable_all_mic}/>
                    <div className="call_more_btn_text">Выключить всем микрофоны</div> 
                </div>}
                
                <div onClick={openMicSettings} className="call_more_btn_dialog hover">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M12.0002 2.48438C9.79105 2.48438 8.00019 4.27524 8.00019 6.48437V11.4844C8.00019 13.6935 9.79105 15.4844 12.0002 15.4844C14.2093 15.4844 16.0002 13.6935 16.0002 11.4844V6.48438C16.0002 4.27524 14.2093 2.48438 12.0002 2.48438Z" fill="#AC92ED"/>
                        <path d="M5.1003 12.4844C4.54896 12.4844 4.11844 12.97 4.2572 13.5036C5.07879 16.6629 7.78287 19.0629 11.1002 19.4343V21.5844C11.1002 22.0814 11.5031 22.4844 12.0002 22.4844C12.4972 22.4844 12.9002 22.0814 12.9002 21.5844V19.4343C16.2175 19.0629 18.9216 16.6629 19.7432 13.5036C19.8819 12.97 19.4514 12.4844 18.9001 12.4844C18.4504 12.4844 18.0742 12.8134 17.9467 13.2447C17.1881 15.8114 14.8129 17.6844 12.0002 17.6844C9.1875 17.6844 6.81226 15.8114 6.05363 13.2447C5.92617 12.8134 5.54999 12.4844 5.1003 12.4844Z" fill="#AC92ED"/>
                    </svg>
                    <div className="call_more_btn_text">Настройки микрофона</div> 
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CallMoreDialog;
