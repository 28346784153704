import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function NotSavedDialog(props) {
    const isDesktop = getIsDesktop()
    const save = () => {
        props.save()
        props.closeDialog()
    }
    const cancel = () => {
        props.closeDialog()
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="call_edit_title">У вас есть не сохраненные изменения</p>
                <p className="call_edit_desc">Хотите их сохранить?</p>
                <div className="flex">
                    <div onClick={cancel} className="call_prices_edit hover">Отмена</div>
                    <div onClick={save} className="call_price_continue hover">Сохранить</div>
                </div>       
            </div>
        )
    }
    if (isDesktop){
        return(
            <BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>
        )
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default NotSavedDialog;
