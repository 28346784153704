import { getIsDesktop } from "../../../utils/Utils";

function BalanceTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div  onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div className="experts_balance_tooltip">
                <div style={isDesktop ? {left : '80%'} : {bottom : '6px', position : 'absolute'}} className="green_rectangle"></div>
                <div className="green_profile_tooltip">{"Здесь находится ваш баланс. Эти деньги вы можете потратить на занятия с репетиторами и экспертами. Для просмотра деталей нажмите сюда"}</div>
                
            </div>
        </div>
     );
 }
 
 export default BalanceTooltip;
 