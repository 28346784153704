import arrow_right from '../../../../img/create_ads/arrow_right.svg'
import back_btn from '../../../../img/create_ads/back_btn.svg'
import { useHistory } from "react-router-dom";
import { checkButtonDisabled } from '../helpers/CreateAdsHelper';
import { getUrlParameter } from '../../../../utils/Utils';
function CreateAdsButtons(props) {
    const history = useHistory()
    const isEdit = props.isEdit
    return (
        <div className="create_ads_buttons">
            {!isEdit && <img onClick={() =>  history.goBack()} className="create_ads_back_btn hover" src={back_btn}/>}
            {props.onNext != undefined && 
            <div style={isEdit ? {marginRight : 'auto'} : checkButtonDisabled(props.isDisabled)} onClick={props.onNext} className="create_ads_btn hover">
                {isEdit ? 
                <div>Сохранить</div>
                :
                <div className="flex">Далее <img className="create_ads_arrow_right" src={arrow_right}/></div>}
            </div>}
        </div>
    );
}

export default CreateAdsButtons;
