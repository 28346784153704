import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import { CSSTransition } from 'react-transition-group';

function CallInfoBottomSheet(props) {
    const pricesUserInfo = props.pricesUserInfo
    const isDesktop = getIsDesktop()
    const config = props.config
    const changeScreenShare = () => {
        props.setShowCallInfo(false)
        props.changeScreenShare(!props.isScreenShareEnabled)
    }
    const changeRole = () => {
        if (props.isIRepetitor){
            props.setShowCallInfo(false)
            props.setShowChangeRole(true)
        }
    }
    const interviewClick = () => {
        props.setShowCallInfo(false)
        props.setInterview(true)
    }
    if (isDesktop){
        return(
            <div>
                <CSSTransition in={props.isShowCallInfo} timeout={300} classNames="animation_in" unmountOnExit>
                    <div className="call_info_container_desktop">
                        <div style={{zIndex : 4}}  onClick={() => props.setShowCallInfo(false)} className="more_tooltip_profile_bg"></div>
                        <div className="more_tooltip_call_info">
                            <InnerDialog pricesUserInfo={pricesUserInfo} isIRepetitor={props.isIRepetitor} isScreenShareEnabled={props.isScreenShareEnabled} setShowCallInfo={props.setShowCallInfo} changeScreenShare={changeScreenShare} config={config} count_free_time={props.count_free_time} changeRole={changeRole} interviewClick={interviewClick}/>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        )
    }
    return (
        <BottomSheet onDismiss={() => props.setShowCallInfo(false)} style={{zIndex : 9999}} open={props.isShowCallInfo}>
            <InnerDialog pricesUserInfo={pricesUserInfo} isIRepetitor={props.isIRepetitor} isScreenShareEnabled={props.isScreenShareEnabled} setShowCallInfo={props.setShowCallInfo} changeScreenShare={changeScreenShare} config={config} count_free_time={props.count_free_time} changeRole={changeRole} interviewClick={interviewClick}/>
        </BottomSheet>
    );
}

export default CallInfoBottomSheet;
const InnerDialog = ({ pricesUserInfo, isIRepetitor, isScreenShareEnabled, setShowCallInfo, changeScreenShare, config, count_free_time, changeRole, interviewClick }) => {
    const isDesktop = getIsDesktop()
    return (
            <div className="call_info_container">
                <div className="call_info_text_cont hover">
                    <p className="call_info_text">Стоимость звонка</p>
                    <p className="call_info_title">{isIRepetitor ? parseFloat(pricesUserInfo.rub_in_hour * ( 1 - config.comission)).toFixed(1) : pricesUserInfo.rub_in_hour}/час</p>
                </div>
                <div className="call_info_text_cont hover">
                    <p className="call_info_text">Бесплатное время</p>
                    <p className="call_info_title">{parseInt(count_free_time)} мин.</p>
                </div>
                <div onClick={changeRole} className="call_info_text_cont hover">
                    <p className="call_info_text">Ваша роль в звонке</p>
                    <p className="call_info_title">{isIRepetitor ? "эксперт" : "клиент"}</p>
                </div>
                {isDesktop ? 
                    <div onClick={interviewClick} className="enable_screen_sharing hover">Включить режим интервью</div> : <div/>}
                     {/* <div onClick={changeScreenShare} className="enable_screen_sharing hover">{isScreenShareEnabled ? "Выключить" : "Включить"} демонстрацию экрана</div>} */}
                <div onClick={() => setShowCallInfo(false)} className="cancel_call_info hover">Отмена</div>
            </div>
    )
}