/* eslint-disable eqeqeq */
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import PlannedPlannerItem from '../../planner/list_items/PlannedPlannerItem';
import { getIsDesktop, Pages } from '../../../utils/Utils';
import AdsGroupCall from '../../planner/list_items/AdsGroupCall';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ProfileGroupCalls(props) {
    const sozvons = props.planners
    var planners = []
    if (sozvons != undefined){
        planners = sozvons.filter(s => props.isMyCalls || s.is_open)
    }
    const isDesktop = getIsDesktop()
    const fromSearch = props.fromSearch
    const isMyCalls = props.isMyCalls
    const isProfile = props.isProfile
    const getInnerCorousel = () => {
        return(
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {planners.map((item, index) => (
                    <Card slide={item} itemId={item.id} index={index} key={index} fromSearch={fromSearch} isProfile={isProfile}/>
                ))}
            </ScrollMenu>
        )
    }
    if (sozvons == undefined || sozvons.length == 0 || planners.length == 0){
        return null
    }
    return (
            <div style={props.style ? props.style : {}} className={fromSearch ? "planner_list_bg experts_search_group_call" : "planner_list_bg profile_planner_group_cont"}>
                {/* <p onClick={() => history.push(Pages.planned_planner)} style={isDesktop ? {} : {marginTop : '12px'}} className="experts_title_section planner_list_title_desktop hover">Запланированные созвоны</p> */}
                {isMyCalls ? 
                    <Link to={Pages.planned_planner}><div style={{marginTop : 0}} className="lk_planners_title hover">Групповые занятия <span style={{opacity : 0.5}}>{planners.length}</span></div></Link>
                :
                    <p style={{marginTop : 0}} className="lk_planners_title">{fromSearch ? "Групповые занятия" : isMyCalls ? "Запланированные видеовстречи" : "Организует публичные видеовстречи"} <span style={{opacity : 0.5}}>{planners.length}</span></p>
                }
                {/* {fromSearch && <div className='experts_search_group_desc'>Вы можете к ним присоединиться</div>} */}
                {planners.length > 0 && <div className="carousel_mobile">{getInnerCorousel()}</div>}
                {planners.length > 0 && <div style={{margin : '0 0 0 ' + (isDesktop ? '0' :  '-16px')}} className="popular_horizontal_cont planner_list_corousel">{getInnerCorousel()}</div>}
            </div>
    );
    
}
function Card({ slide, itemId, index, fromSearch, isProfile }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isDesktop = getIsDesktop()
    return (
        <div style={index == 0 ? {marginLeft : isDesktop ? '1px' : '16px'} : (index == 3 && isDesktop) ? {} : {}}>
            {(fromSearch || isProfile) ?
                <AdsGroupCall isFromMainScreen isAdsInProfile item={slide}/>
             :
                <PlannedPlannerItem isFromMainScreen isAdsInProfile item={slide}/>
            }
            
        </div>
    );
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img alt="" style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll planner_arrow_scroll_left hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img alt="" style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_right planner_arrow_scroll hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}
export default ProfileGroupCalls;
