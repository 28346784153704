/* eslint-disable eqeqeq */
import search_icon from '../../../img/experts/search.svg'
import bookmark from '../../../img/experts/bookmark.svg'
import { useRef, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useHistory } from "react-router-dom";
import { Pages } from '../../../utils/Utils';

function SearchExperts(props) {
    const inputRef = useRef(null)
    
    useEffect(() => {
       if (inputRef != undefined && inputRef.current != undefined && props.isFocused){
            inputRef.current.focus()
       }
    }, [])
    const history = useHistory();
    
    const openLink = (link) => {
        if (props.isDisabledOpen){
            return
        }
        history.push(link)
    }
    const onTextChanged = (text) => {
        var q = text == undefined ? "" : text
        window.history.replaceState(null, null, Pages.search + '?text=' + q)
        props.setText(q)
    }
    return (
        <div>
            <div className="flex">
                <div style={props.style ? props.style : {}} onClick={() => openLink(Pages.search)} className="experts_search_container">
                    <img alt="" className="search_experts_icon" src={search_icon}/>
                    <DebounceInput style={props.isDisabled ? {pointerEvents : 'none'} : {}} inputRef={inputRef} debounceTimeout={500} placeholder="Поиск" className="experts_search_input" type="text" value={props.text} onChange={(e) => onTextChanged(e.target.value)}/>
                </div>   
                <img alt="" onClick={() => openLink(Pages.bookmarks)} className="like_experts_icon hover" src={bookmark}/> 
            </div>
            {/* <div className="experts_seach_line"/> */}
        </div>
        
    );
}

export default SearchExperts;
