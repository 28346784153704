/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, setModelParam } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import experts_info_1 from '../../../img/experts/experts_info_1.png'
import experts_info_2 from '../../../img/experts/experts_info_2.png'
import experts_info_3 from '../../../img/experts/experts_info_3.png'
import experts_info_4 from '../../../img/experts/experts_info_4.png'
import popular_left from '../../../img/experts/popular_left.svg'
import popular_right from '../../../img/experts/popular_right.svg'
import { useState } from 'react';

function MobilePopularDialog(props) {
    const [index, setIndex] = useState(0)
    const items = [{id : 0, title : "Помощь эксперта<br/>начинающему специалисту", text : "Новички сталкиваются со сложностями на каждом шагу. Вместе с экспертом решить задачу получится в разы быстрее.", img : experts_info_1},
    {id : 1,title : "Подготовка к экзаменам", text : "Найди репетитора по нужному предмету и грызи гранит науки с удовольствием!", img : experts_info_2},
    {id : 2,title : "Платные совещания", text : "Уходит много времени на общение с клиентами? Чтобы монетизировать это время, звонки и переписку перенеси в сервис sozvon.pro. Установи стоимость переписки и звонков. Время — деньги.", img : experts_info_3},
    {id : 3,title : "Поговори со звездой", text : "Хочешь пообщаться вживую с блогером, которого давно смотришь? Найди его через поиск и позвони по видеосвязи. Сделай скриншот и хвастайся в соцсетях, что поговорил со знаменитостью!", img : experts_info_4}]
    const info = props.info
    const close = () => {
        setIndex(0)
        setModelParam(props.info, "isShow", false, props.setDialogInfo)
    }
    const isDesktop = getIsDesktop()
    const getBackground = (index) => {
        switch (index) {
            case 0:
                return {background : isDesktop ? "#FFDF7F" : "#C5AEFF"}
                case 1:
                return {background : isDesktop ? "#C5AEFF" : "#77EBCF"}
                case 2:
                return {background : "#56CCF2"}
                case 3:
                return {background : "#77EBCF"}
            
            default:
                return
        }
    }
    const bgColor = getBackground(index)
    const getInnerHtml = () =>{
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}} className="call_info_container">
                <div style={bgColor} className="popular_logo_bg">
                    <img alt='' className="popular_logo_img" src={items[index].img}/>
                </div>
                <div className="popular_dialog_title" dangerouslySetInnerHTML={{__html: items[index].title}}/>
                <p style={index == 0 ? {minHeight : '64px'} : {}} className="popular_dialog_text">{items[index].text}</p>
                <div className="mobile_popular_btns">
                    {/* <div onClick={close} className="call_price_continue hover">Понятно</div> */}
                    <img alt='' style={index == 0 ? {opacity : 0, pointerEvents : 'none'} : {}} onClick={() => setIndex(index - 1)} className="mobile_popular_btn" src={popular_left}/>
                    {index >= 3 ? <div onClick={close} style={{width : 'fit-content', padding : '14px 44px'}} className="call_price_continue hover">Понятно</div> :  <img onClick={() => setIndex(index + 1)} className="mobile_popular_btn" src={popular_right}/>}
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={info.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={info.isShow} onDismiss={close}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default MobilePopularDialog;
