import { useState, useRef, useEffect } from 'react';
import { debounce } from '../../../utils/Utils';

function ReadMoreText(props) {
    const [clamped, setClamped] = useState(true);
    const [showButton, setShowButton] = useState(true);
    const containerRef = useRef(null);
    const handleClick = () => setClamped(!clamped);
    const text = props.text
    useEffect(() => {
      const hasClamping = (el) => {
        const { clientHeight, scrollHeight } = el;
        return clientHeight !== scrollHeight;
      };
  
      const checkButtonAvailability = () => {
        if (containerRef.current) {
          // Save current state to reapply later if necessary.
          const hadClampClass = containerRef.current.classList.contains("clamp");
          // Make sure that CSS clamping is applied if aplicable.
          if (!hadClampClass) containerRef.current.classList.add("clamp");
          // Check for clamping and show or hide button accordingly.
          setShowButton(hasClamping(containerRef.current));
          // Sync clamping with local state.
          if (!hadClampClass) containerRef.current.classList.remove("clamp");
        }
      };
  
      const debouncedCheck = debounce(checkButtonAvailability, 50) 
  
      checkButtonAvailability();
      window.addEventListener("resize", debouncedCheck);
  
      return () => {
        window.removeEventListener("resize", debouncedCheck);
      };
    }, [containerRef]);
  
    return (
      <>
        <div ref={containerRef} className={clamped ? "experts__item_text clamp" : "experts__item_text"} dangerouslySetInnerHTML={{__html: text}}></div>
        {showButton && <div style={{minHeight : '20px'}}>
            <div className="experts_item_expand hover" onClick={handleClick}>{clamped ? "Развернуть" : "Свернуть"}</div>
        </div>}
      </>
    );
  };
export default ReadMoreText;