import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import vk_logo_big from '../../../img/profile/vk_logo_big.svg'

function VkGroupDialog(props) {
    const close = () => {
        props.setShow(false)
    }
    const joinVK = () => {
        window.open( "https://vk.com/sozvonpro", '_blank')
        close()
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}} className="call_info_container">
                <img className="vk_logo_big" src={vk_logo_big}/>
                <div className="vk_dialog_title">Подписывайтесь, чтобы<br/>узнавать о важном!</div>
                <p className="vk_dialog_text">Подписывайтесь, чтобы узнавать о важном! Делимся полезной информацией и новостями сервиса в нашей группе Вконтакте.</p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div onClick={close} className="call_prices_edit hover">Пропустить</div>
                    <div onClick={joinVK} className="call_price_continue hover">Подписаться</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet onDismiss={close} style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default VkGroupDialog;
