/* eslint-disable eqeqeq */
import '../../../styles/ads_desk.css';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Loader from '../../../custom_views/Loader';
import { getAuthUrlWithPrev, getIsDesktop, getUrlParameter, Pages } from '../../../utils/Utils';
import back_arrow from '../../../img/experts/back_arrow.svg'
import share_ads from '../../../img/create_ads/share_ads.svg'
import autosize from 'autosize';
import { AdsApi } from '../../../api/AdsApi';
import { isUserAuthed} from '../../../utils/CookieHelper';
import { CSSTransition } from 'react-transition-group';
import Toast from '../../profile/custom_views/Toast';
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/blogs/blogs_back.svg'
import ShikariAdsProfile from './custom_views/ShikariAdsProfile';
import ProIcon from './custom_views/ProIcon';
import ProAvailable from './custom_views/ProAvailable';

function PickedShikariAds(props) {
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const inputRef = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const [toastText, setToastText] = useState("")
    const [ads, setAds] = useState(props.pickedAds ? props.pickedAds : undefined)
    const [isFromLink, setFromLink] = useState(false)
    const user = props.user
    useEffect(() => {
        autosize(inputRef.current)
        
    }, [])
  
    useEffect(() => {
        if (props.pickedAds != undefined){
            return
        }
        const id = getUrlParameter("id")
        setLoading(true)
        AdsApi.getSocialPostById(id, (response) => {
            if (response.response > 0){
                setAds(response.item)
            } else {
                window.location.href = Pages.ads_board
            }
            setLoading(false)
        }, () => {
            window.location.href = Pages.ads_board
        })
    }, [])
    useEffect(() => {
        if (props.pickedAds){
            setAds(props.pickedAds)
        }
    }, [props.pickedAds])
    const onBack = () => {
        if (isFromLink){
            window.location.href = Pages.ads_board
        } else {
            history.goBack()
            // props.setPickedAds(undefined)
        }
        
    }
 
    

    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const share = () => {
        var textField = document.createElement('textarea')
        textField.innerHTML = "Здесь ищут эксперта:\n" + window.location.href
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        showToast("Ссылка на объявление скопирована")
    }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header">
                <div className="flex">
                {isDesktop ? <img alt='' onClick={onBack} className="ads_back_desktop hover" src={other_back}/> 
                :
                <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                </div>
                {!isDesktop && <img alt='' onClick={share} className="ads_share_img hover" src={share_ads}/>}
            </div>
        )
    }
 
   
  
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="ads_flex_cont ads_archive_desktop">
                {(ads == undefined || isLoading) ? 
                <Loader />
                : <div className="ads_picked_desktop">
                    <div className="picked_ads_top_container">
                            {getToolbar()}
                            <div className="ads_picked_info_container ads_picked_info_container_p">

                                <div className="ads_picked_shikari_pro_icon"><div className="ads_shikari_badge">из соцсетей</div></div>
                                <h1 className='ads_picked_info_title h1_title'>{""}</h1>

                                <div style={{opacity : '0.5', marginBottom : '2px'}} className="ads_picked_shikari_t">Пожелания к заявке:</div>
                                <div className="ads_picked_shikari_t">{ads.description}</div>
                                <div className="ads_line"/>
                                <ShikariAdsProfile item={ads}/>
                            </div>
                           
                    </div>
                  
                    <div style={{marginBottom : '48px'}} className="ads_picked_comment_container">
                            <div className="ads_picked_comment_title">Мой отклик</div>
                            {isUserAuthed() ?
                            (!user.is_pro && user.is_repetitor) ? 
                                <div>
                                    <div className='ads_pro_available'>Этот запрос из Социальной сети. Откликаться на такие объявления могут только эксперты со статусом PRO</div>
                                    <ProAvailable config={props.config}/>
                                </div>
                            :
                            <div className="ads_picked_shikari_text">
                                <div style={{margin : '16px 0'}}>Откликаться на такие объявления могут только эксперты со статусом PRO</div>
                                {props.user.is_pro ?
                                    <div onClick={() => props.setShowShareDialog(true)} className="ads_picked_shikari_btn hover">
                                        Связаться с клиентом
                                    </div>
                                : <ProAvailable />}
                            </div> : <NotAuthedUser />}
                    </div>

                </div>}
                {(isDesktop && !isLoading) && 
                    <div className="ads_desktop_share_cont ads_share_cont">
                        <div onClick={share} className="flex hover">
                            <img alt='' className="ads_share_img hover" src={share_ads}/>
                            <div className="ads_desktop_share_text">Поделиться</div>
                        </div>
                    </div>
                }

           </div>
           <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>

        </div>
    )
}

export default PickedShikariAds;


const NotAuthedUser = ({  }) => {
    return(
        <div>
            <div style={{margin : '16px 0'}} className="ads_my_answer">Отклики могут оставлять только авторизованные эксперты сервиса</div>
            <button onClick={() => window.location.href = (getAuthUrlWithPrev() + "?id=" + getUrlParameter("id"))} style={{position : 'relative', marginBottom : '8px'}} className="fill_button hover">Авторизоваться 👋</button>
        </div>
    )
}