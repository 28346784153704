/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import message from '../../../img/experts/message.svg'
import star from '../../../img/experts/star.svg'
import bookmark from '../../../img/experts/bookmark.svg'
import bookmark_selected from '../../../img/experts/bookmark_selected.svg'
import { getRating, getIsDesktop, Pages, youtube_parser, getUserAvatar, getReviewsCount, getVideoType, VideoType, changeVkVideoUrl } from '../../../utils/Utils';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../api/UsersApi';
import YouTube from "react-youtube"
import badge_seleb from '../../../img/profile/badge_seleb.svg'
import badge from '../../../img/profile/badge.svg'
import ReadMoreText from '../custom_views/ReadMoreText';
import Loader from '../../../custom_views/Loader';
import { getExpertsYoutubeParams } from '../utils/ExpertsUtils';
import { getCookie, getUserUrl } from '../../../utils/CookieHelper';
import ProIcon from '../../ads_desk/AdsBoard/custom_views/ProIcon';
import OnlineInfo from '../../profile_filling/custom_views/OnlineInfo';
import { Link } from 'react-router-dom/cjs/react-router-dom';

function ExpertItem(props) {
    const user = props.user
    const videoRef = useRef(null)
    const history = useHistory()
    const [isInBookmarks, setInBookmarks] = useState(false)
    const [isVideoLoading, setVideoLoading] = useState(true)
    const isMyItem = props.user.vk_caller_id == getCookie("id")
    const usersUrl = getUserUrl(user) + "?from_search=1"
    const seoTags = (props.seoTags && props.seoTags.length > 0) ? props.seoTags : []
    
   
    const handleOnClick = () => {
        if (isDesktop){
            window.open(usersUrl, '_blank')
        } else {
            saveScrollTop()
            history.push(usersUrl)
        }
    }
    const saveScrollTop = () => {
        if (props.scrollTop){
            localStorage.setItem("search_scroll_top", props.scrollTop)
        }
    }
    const isDesktop = getIsDesktop()
    const setHovered = (isHovered) => {
        let video = videoRef.current
        if (videoRef != null && isHovered && video != undefined){
            video.play()
        } else if (videoRef != null && video != undefined) {
            video.pause()
        }
    }
    useEffect(() => {
        if (props.myUser != undefined && props.myUser.favorite_users){
            getFavorites()
        }
    }, [])
    const getFavorites = () => {
        const favorites = props.myUser.favorite_users
        if (favorites){
            favorites.forEach(profile => {
                if (user.vk_caller_id == profile){
                    setInBookmarks(true)
                }
            })
        }
    }
    const toggleBookmarks = () => {
        UsersApi.toggleBookmarks(user.vk_caller_id, isInBookmarks, (responseData) => {})
        setInBookmarks(!isInBookmarks)
    }
    const onVideoReady = (event) => {
        setVideoLoading(false)
        event.target.playVideo()
    }
    var youtubeVideoUrl = user.video_url_youtube
    const hasVideo = isDesktop && youtubeVideoUrl && youtubeVideoUrl != ""

   
    const videoType = getVideoType(user)
    if (videoType == VideoType.vk){
        youtubeVideoUrl = changeVkVideoUrl(youtubeVideoUrl)
    }
    const getTagsHtml = () => {
        return(
            <div className="profile_tags_contaner experts_item_tags">
                {seoTags.length > 0 && seoTags.map((tag, index) => (
                    <div style={(tag != "" && tag != " " && tag != "  ") ? {} : {display : 'none'}} key={index}>
                        <p className="profile_tag">{tag}</p>
                    </div>
                ))}
                {(user.specializations && user.specializations.length > 0) && user.specializations.map((tag, index) => (
                    <div style={(tag != "" && tag != " " && tag != "  ") ? {} : {display : 'none'}} key={index}>
                        <p className="profile_tag">{tag}</p>
                    </div>
                ))}
            </div>
        )
    }
    const getStatsHtml = () => {
        return(
            // <div className="flex">
            //     <div className="experts_item_price">{user.rub_in_hour} ₽/час</div>
            //     <img alt='' className="experts_info_icon" src={star}/>
            //     <p className="experts__item_info_text">{getRating(user)}</p>

            //     <img alt='' style={{marginLeft : '24px'}} className="experts_info_icon" src={message}/>
            //     <p className="experts__item_info_text">{countReviews}</p>

            //     <p style={user.online ? {color : "#22DCB0", margin : '9px 0 0 16px'} : {margin : '9px 0 0 16px'}} className="experts_online_text">{user.online ? "сейчас онлайн" : "на связи"}</p>
            // </div>
            <div className="experts_item_online_inf">
                
                {(user.free_time != undefined && user.free_time >= 30) ?
                    <div className='user_info_free_time'>🎁 {user.free_time} мин. бесплатно</div>
                : (user.free_time != undefined && user.free_time < 30) ?
                    <p style={{marginRight : '6px'}} className="desktop_profile_desk">{user.free_time} мин. бесплатно </p>
                :
                    <div/>
                }
                {(user.rub_in_hour > 0 || isDesktop) && <p className="profile_hour_price" style={{margin : '-5px 6px 0 0', fontSize : '12px'}}>{user.rub_in_hour > 0 ? user.rub_in_hour + "₽/час" : "цена договорная"}</p>}
                
                <OnlineInfo disableAge disableViews user={user}/>
                <div style={{margin: '-8px 0 0 0'}} className="flex">
                    <img alt='' className="review_info_icon" src={star}/>
                    <p className="review_info_text">{getRating(user)}</p>

                    <img alt='' className="review_info_icon" src={message}/>
                    <p className="review_info_text">{getReviewsCount(user)}</p>
                </div>
            </div>
        )
    }
     const getVideoHTML = () => {
        if (videoType == VideoType.none){
            return(
                null
            )
        }
        return(
            <div className="relative experts_item_video">
                {videoType == VideoType.youtube ?
                    <YouTube onReady={onVideoReady} videoId={youtube_parser(youtubeVideoUrl)} className="experts_item_full_center" iframeClassName="experts_item_iframe_video" opts={getExpertsYoutubeParams(isDesktop)} id="video"/>
                :
                    <iframe src={youtubeVideoUrl} style={isDesktop ? {marginRight : '24px'} : {}} className='experts_item_full_center experts_item_iframe_video' allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="true" allowFullScreen></iframe>
                }
                {isVideoLoading && <div className="experts_item_full_center" style={{maxHeight : '24px'}}><Loader style={{marginTop : 0}} isWhite/></div>}

            </div>
        )
    }
    // const getVideoHtml = () => {
    //     return(
    //         <div className="relative experts_item_video">
    //             {getVideoHTML()}
    //             {/* <YouTube onReady={onVideoReady} videoId={youtube_parser(youtubeVideoUrl)} className="experts_item_full_center" iframeClassName="experts_item_iframe_video" opts={getExpertsYoutubeParams(isDesktop)} id="video"/> */}
    //             {/* <video onLoadedData={() => setVideoLoading(false)} playsInline autoPlay={false} controls={false} className="experts_item_video" src={user.video_url} ref={videoRef} muted={true}></video> */}
    //         </div>
    //     )
    // }
    return (
        <div className="experts_item_cont_mobile">
            {isDesktop ? 
                <a rel="noreferrer" className="experts_item_clickable" href={usersUrl} target="_blank"></a>
            :
                <Link onClick={saveScrollTop} className="experts_item_clickable" to={usersUrl}></Link>
            }
            {(!isDesktop && hasVideo) && getVideoHTML()}
            <div style={(!isDesktop && hasVideo) ? {marginTop : '-34px', position : 'relative'} : (isDesktop && hasVideo) ? {minHeight : '276px'} : {}} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className="experts_item_cont">
                <div onClick={handleOnClick}  className="profile_mobile_pro_cont">
                    <img alt='' className="experts_avatar_redisign" src={getUserAvatar(user)}/>

                    <div className="experts_item_pro_icon"><ProIcon isShow={user.is_pro}/> </div>
                </div>
                <div className="experts_item_name_cont">
                    <div style={{width : '100%'}}>
                        <div style={(!isDesktop && hasVideo) ? {marginTop : '8px'} : {}} className="experts_item_flex_just">
                            <div onClick={(!isDesktop && hasVideo) ? handleOnClick : () => {}} className="flex ">
                                <p className="expert__item_title_name">{user.name} {user.surname}</p>
                                {(user.is_repetitor || user.verified) && <img alt='' className="experts_item_profile_badge" src={user.verified ? badge_seleb : badge}/>}
                            </div>
                            {(!isDesktop  && !isMyItem) && <img alt='' style={{position : 'absolute', zIndex : "1", top : hasVideo ? '22px' : '14px'}} onClick={toggleBookmarks} className="experts_item_bookmark hover bookmark_expert_item" src={isInBookmarks ? bookmark_selected : bookmark}/>}

                        </div>
                        
                        {isDesktop && <div>
                            <ReadMoreText text={user.bio}/>
                            {getTagsHtml()}
                            {getStatsHtml()}
                        </div>}

                       
                        {/* {isDesktop ? <p className="experts_online_text" dangerouslySetInnerHTML={{__html: user.bio}}></p> : <p style={user.online ? {color : "#22DCB0"} : {}} className="experts_online_text">{user.online ? "сейчас онлайн" : "на связи"}</p>} */}

                    </div>
                    
                    {(isDesktop && hasVideo) && getVideoHTML()}
                </div>
                
            </div>
            {!isDesktop && <div className="experts_item_mobile_cont">
                {getStatsHtml()}
                <ReadMoreText text={user.bio}/>
                {getTagsHtml()}
            </div>}
            {(isDesktop && !isMyItem) && <img alt='' onClick={toggleBookmarks} className="experts_item_bookmark hover bookmark_expert_item" src={isInBookmarks ? bookmark_selected : bookmark}/>}
        </div>
        
    )
}

export default ExpertItem;

  