/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { MyImage, Pages, getIsDesktop, getUserAvatar } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import x from '../../../img/x.svg'
import messages from '../../../img/planner/messages.svg'

function GroupCalParticipantsDialog(props) {
    const isDesktop = getIsDesktop()
    const participants = props.participants ? props.participants : []

    const onClick = (user) => {
        window.open(window.location.origin + "/chats/chat?id=" + user.vk_caller_id, '_blank')
    }
    const close = () => {
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <div className='planned_fl'>
                    <p className="pay_type_dialog_title">Примут участие <span style={{opacity : '0.5'}}>{participants.length}</span></p>
                    <img alt='' onClick={close} className='planned_x hover' src={x}/>
                </div>
                <div style={isDesktop ? {marginTop : '24px'} : {marginTop : '24px', minHeight : 'auto'}} className='pay_bank_dialog'>
                    {participants.map((user, index) => (
                            <div  index={index} key={index} className='planned_participant'>
                            
                                <div onClick={() =>  window.open(Pages.users + "/id" + user._id, '_blank')} className='flex hover'>
                                    <img className='planned_participant_img' alt='' src={getUserAvatar(user)}/>
                                    <div className='planned_participant_name'>{user.name ? user.name : ""} {user.surname ? user.surname : ""}</div>
                                </div>
                                <div  onClick={() => onClick(user)} >
                                    <MyImage className="planned_participant_messages hover" src={messages}/>
                                </div>
                            </div>
                        
                    ))}
                  
                </div>
               
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default GroupCalParticipantsDialog;
