/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Pages, getIsDesktop } from '../../../../utils/Utils';
import back_arrow from '../../../../img/experts/back_arrow.svg'
import check_black from '../../../../img/create_ads/check_black.svg'
import { getAdsPricesFilter } from '../../CreateAds/helpers/CreateAdsHelper';
import PricePickerDialog from '../dialogs/PricePickerDialog';
import search_icon from '../../../../img/experts/search.svg'

function AdsFilter(props) {
    const params = props.searchParams
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const scrollTop = props.scrollTop

    const [isShowPrices, setShowPrices] = useState(false)
    const [selectedSpecs, setSelectedSpecs] = useState(params.specializations)
    const [isAll, setAll] = useState(params.is_all)
    const [withoutRespond, setWithoutRespond] = useState(params.without_respond)
    const [withoutPro, setWithoutPro] = useState(params.without_pro)
    const [price, setPrice] = useState(params.price)
    const [filterSearchText, setFilterSearchText] = useState("")
    const isBlogFilter = props.isBlogFilter
    const isDesktopBlogs = getIsDesktop() && props.isBlogFilter
    const onBack = () => {
        history.goBack()
    }
    const getHeader = () => {
        if (isDesktopBlogs){
            return <div style={{marginTop : '-100px'}}></div>
        }
        return(
            <div style={isBlogFilter ? {borderBottom : 'solid 0.5px #B2C2E0'} : {}} className="picked_ads_top_header">
                {isDesktop ? 
                    <div className="ads_desktop_filters_title">Настройки выдачи</div>
                    : <div className="flex">
                    <img alt="" onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>
                    <p className="theme_title">Фильтры</p>
                </div>}
            </div>
        )
    }
    const onWithoutRespond = (event) => {
        if (event){
            var value = event.target.checked
            setWithoutRespond(value ? 1 : 0)
        }
    }
    const onWithoutPro = (event) => {
        if (event){
            var value = event.target.checked
            setWithoutPro(value ? 1 : 0)
        }
    }
    const onAll = (event) => {
        if (event){
            var value = event.target.checked
            setAll(value ? 1 : 0)
            setSelectedSpecs(value ? props.specializations : [])
        }
    }
    const onSpecClicked = (item) => {
        const specs = [...selectedSpecs]
        if (specs.includes(item)){
            specs.splice(specs.indexOf(item), 1)
        } else {
            specs.push(item)
        }
        setAll(specs.length == props.specializations.length ? 1 : 0)
        window.history.replaceState(null, null, Pages.ads_board + '?filter=' + specs.join(","))

        setSelectedSpecs(specs)
    }

    const applyFilters = () => {
        props.setSelectedSpecs(selectedSpecs)
        props.setAll(isAll)
        props.setWithoutRespond(withoutRespond)
        props.setPrice(price)
        if (props.setWithoutPro){
            props.setWithoutPro(withoutPro)
        }
        
        if (!isDesktop){
            onBack()
        }
    }
    const scrolledToTop = scrollTop >= 88
    const blogFilterItems = () => {
        return(
            <div>
                {isDesktopBlogs && <div className="blogs_filters_name">Фильтры блогов</div>}
                <div className="blogs_filter_title">Выбрано тем {selectedSpecs.length > 0 && <span className="blogs_filter_count">{selectedSpecs.length}</span>}</div>
                <div>
                    <div className="flex">
                        <div className="blog_filter_search">
                            <img alt="" className="search_experts_icon" src={search_icon}/>
                            <input placeholder="Поиск по темам" className="experts_search_input blogs_search_input" type="text" value={filterSearchText} onChange={(e) => setFilterSearchText(e.target.value)}/>
                        </div>   
                    </div>
                </div>
            </div>
        )
    }
    const filteredSpecs = props.specializations.filter((spec) => filterSearchText == "" ? true : spec.toLowerCase().includes(filterSearchText.toLowerCase()))
    return (
        <div style={scrolledToTop ? {paddingTop : isBlogFilter ? '88px' : 0} : {paddingTop : isDesktop ? '88px' : '0'}} className={isDesktop ? "search_experts_desktop ads_desktop_filters transition_ads_all" : "selected_theme"}>
            {getHeader()}
            {!isDesktop && <div className="ads_filter_line"/>}
            
            {!isBlogFilter && <div className="ads_top_filter_container">
                <div className="ads_filter_title">Стоимость услуг</div>
                <div onClick={() => setShowPrices(true)} className="ads_filter_bold hover">{price == 0 ? "от 0 ₽/час." : getAdsPricesFilter()[price]}</div>
                <div className="ads_filter_line"/>
                {/* <div  className="ads_filter_switch_row">
                    <div className="ads_filter_bold hover">Без статуса PRO</div>
                    <Slider value={withoutPro} setValue={onWithoutPro}/>
                </div> */}
                <div style={{marginTop : '24px'}} className="ads_filter_switch_row">
                    <div className="ads_filter_bold hover">Без отклика</div>
                    <Slider value={withoutRespond} setValue={onWithoutRespond}/>
                </div>
            </div>}
            <div style={isBlogFilter ? {paddingBottom : isDesktop ? '0' : '80px', borderRadius : isDesktop ? '16px 16px 0 0' : '16px', height : scrolledToTop ? 'calc(100vh - 310px)' : ''} : scrolledToTop ? {height : 'calc(100vh - 312px)'} : {}} className="ads_filter_container transition_ads_all">
                {!isBlogFilter ? <div className="flex">
                    <div className="ads_filter_header">Специализации</div>
                    {selectedSpecs.length > 0 && <div className="ads_selected_specs_count">{selectedSpecs.length}</div>}
                </div> : 
                blogFilterItems()
                }
                {filterSearchText == "" && <div className="ads_select_all_specs">
                    <div className="ads_filter_bold hover">Все</div>
                    <Slider value={isAll} setValue={onAll}/>
                </div>}
                {filteredSpecs.length > 0 && filteredSpecs.map((item, index) => (
                    <SpecializationItem item={item} isSelected={selectedSpecs.filter(s => s.toLowerCase() == item.toLowerCase()).length > 0} onSpecClicked={onSpecClicked} index={index} key={index}/>
                ))}
                {filteredSpecs.length == 0 && <div className="blog_Filer_no_theme">Тема не найдена</div>}
                <div style={{height : '64px'}}/>
                {isDesktop && <div style={isDesktopBlogs ? {bottom : scrolledToTop ? '294px' : '404px'} : {bottom : '60px'}} className="filters_gradient transition_ads_all"/>}
                {isDesktopBlogs ? 
                    <div style={(scrolledToTop && isDesktop) ? {bottom : '246px'} : {}} onClick={applyFilters} className="blogs_filter_desktop_btn transition_ads_all">
                        Применить
                    </div>
                    : 
                    <div onClick={applyFilters} style={isDesktop ? {position : 'absolute', left : '0', width : 'calc(100% - 16px)', borderRadius : '0 0 16px 16px'} : {position : 'fixed', bottom : isBlogFilter ? '80px' : '16px'}} className="fill_button profile_bottom_button ads_filter_btn_board">Применить</div>
                }
            </div>

            <PricePickerDialog price={price} setPrice={setPrice} isShow={isShowPrices} setShow={setShowPrices}/>
        </div>
    )
}

export default AdsFilter;
const SpecializationItem = ({ item, isSelected, onSpecClicked }) => {
    return(
        <div className="ads_spec_item">
            <div onClick={() => onSpecClicked(item)} className="ads_spec_item_flex hover">
                {item}
                <img alt="" style={isSelected ? {opacity : 1} : {opacity : 0}} className="ads_spec_item_icon" src={check_black}/>
            </div>
            <div style={{marginTop : '12px'}} className="ads_filter_line"/>
        </div>
    )
}
const Slider = ({ value, setValue }) => {
    return(
        <label style={{marginTop : '4px'}} className="switch">
            <input type="checkbox" onChange={setValue} checked={value}/>
            <div className="slider round"></div>
        </label>
    )
}