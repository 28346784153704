import { useState} from 'react';
import share from '../../../img/blogs/share.svg'
import { getIsDesktop } from '../../../utils/Utils';

function BlogsShare(props) {
    const [isShowTooltip, setShowTooltip] = useState(false)
    const isShowTooltips = props.isShowTooltips
    const isDesktop = getIsDesktop()
    const showTooltip = () => {
        if (isShowTooltips && isDesktop){
            setShowTooltip(true)
        }
    }
    return (
        <div className="blog_post_share">
            <img onClick={props.sharePost} onMouseEnter={showTooltip} onMouseLeave={() => setShowTooltip(false)} className="blog_item_bookmark hover" src={share}/>
            <div style={isShowTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseLeave={() => setShowTooltip(false)} className="blog_tooltip_bookmark blog_tooltip_share">
                <p className="blog_tooltip_text blog_share_text">Поделиться</p>
            </div>
        </div>)
}

export default BlogsShare;