import { useRef, useEffect } from 'react';

function PlannerSpecsPicked(props) {
    const refInput = useRef(null)

     useEffect(() => {
        refInput.current.focus();
    }, [])
    const pickedSpec = props.pickedSpec
    // const [specializations, setSpecializations] = useState([])
    // useEffect(() => {
    //     getSpecializations()
    // }, [])
    // const getSpecializations = () => {
    //     ConfigApi.getSpecializations((responseData) => {
    //         setSpecializations(responseData.specializations)
    //     })
    // }
   
    return(
        <div>
            <div className="create_planner_theme">Тема созвона</div>
            {/* <div onClick={() => setShowSpecs(!isShowSpecs)} className="create_planner_border hover">
                {pickedSpec}
            </div> */}
            <input ref={refInput} className="create_planner_border" style={{padding : '12px 16px'}} placeholder="Например, урок по математике" type="text" value={pickedSpec} onChange={(e) => props.setPickedSpec(e.target.value)}/>
            <div style={{marginTop : '10px'}} className='create_planner_theme'>В видеовстрече может принять участие один или более человек.</div>
            {/* {isShowSpecs && <FilterDropdown onCloseFilters={() => setShowSpecs(false)} style={{margin : '0', width : 'calc(100% - 32px)'}} filters={specializations} onFilterPicked={onFilterPicked}/>} */}
        </div>
    )
}
export default PlannerSpecsPicked;
