/* eslint-disable eqeqeq */

import message from '../../../img/experts/message.svg'
import star from '../../../img/experts/star.svg'
import { getIsDesktop } from '../../../utils/Utils'
const SeoRatings = ({isSeo, experts, aggregateRating}) => {
    const isDesktop = getIsDesktop()
    const lowestPrice = (isSeo && experts.length > 0) ? experts.sort((a, b) => a.rub_in_hour - b.rub_in_hour)[0].rub_in_hour : 0
    const hightestPrice = (isSeo && experts.length > 0) ? experts.sort((a, b) => b.rub_in_hour - a.rub_in_hour)[0].rub_in_hour : 0
    console.log("SeoRatings aggregateRating = ", aggregateRating)
    if (isSeo && experts.length > 0 && aggregateRating.ratingValue != undefined){
        return (
            <div style={isDesktop ? {marginRight : '12px'} : {marginBottom : '12px'}} className='experts_seo_ratings experts_item_cont'>
                <div>от 👉 {lowestPrice == undefined ? 0 : lowestPrice} ₽/час до {hightestPrice} ₽/час </div>
                <div style={{margin: '16px auto', width : 'fit-content', justifyContent : 'center'}} className="flex">
                    <img alt='' className="experts_seo_icon" src={star}/>
                    <div className='experts_seo_reviews'>{aggregateRating.ratingValue.toFixed(2)}</div>

                    <img alt='' className="experts_seo_icon" src={message}/>
                    <div className='experts_seo_reviews'>{aggregateRating.ratingCount}</div>
                </div>
                <div className='opa'>На основе данных {parseInt(aggregateRating.ratingCount * 1.5)} анкет репетиторов</div>
            </div>
        )
    } else {
        return null
    }
  
}

export default SeoRatings;
