/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, isUrlWithImage, MyImage } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import answer_msg from '../../../img/chat/answer_msg.svg'
import edit_msg from '../../../img/chat/edit_msg.svg'
import copy_msg from '../../../img/chat/copy_msg.svg'
import to_ads from '../../../img/create_ads/to_ads.svg'
import { getCookie } from '../../../utils/CookieHelper';
function MsgContextMenu(props) {
    const msg = props.contextMenuMsg
    const isPlanner = msg.planner != undefined && msg.planner._id != undefined
    const isDesktop = getIsDesktop()
    const isShow = msg.text != undefined
    const isMyMessage = msg.from_id == getCookie("id")
    const isDocument = msg.attachments_urls && msg.attachments_urls.length > 0 && !isUrlWithImage(msg.attachments_urls[0])

    const onClose = () => {
        props.setContextMenuMsg({})
    }
    const onAnswer = () => {
        props.setReplyMsg(msg)
        onClose()
    }
    const onCopy = () => {
        var textField = document.createElement('textarea')
        const text = msg.text == undefined ? "" : msg.text.includes("\n") ? msg.text.replace(/<br>/g, "\n") : msg.text
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        textField.setSelectionRange(0, 99999)
        navigator.clipboard.writeText(textField.value)
        //document.execCommand('copy')

        textField.remove()

       
        props.showToast("Сообщение скопировано")
        onClose()
       
    }
    const onEdit = () => {
        props.setEditableMsg(msg)
        onClose()
    }
    const toAds = () => {
        window.location.href = "http://localhost:3000/ads_board/ads?id=" + msg.repetitor_request
        //history.push("/ads_board/ads?id=" + msg.repetitor_request)
    }
    const isFromCallScreen = props.isShowCallChat != undefined && props.isShowCallChat
    const getInnerHtml = () => {
        return(
            <div style={{paddingTop : '14px'}} className="rules_container">
                {msg.repetitor_request && <div onClick={toAds} className="context_msg_menu_item hover">
                    <MyImage className="context_msg_menu_icon" src={to_ads}/>
                    <p className="context_msg_menu_text">Перейти к объявлению</p>
                </div>}
                <div onClick={onAnswer} className="context_msg_menu_item hover">
                    <MyImage className="context_msg_menu_icon" src={answer_msg}/>
                    <p className="context_msg_menu_text">Ответить</p>
                </div>
                {!isDocument && <div onClick={onCopy} className="context_msg_menu_item hover">
                    <MyImage className="context_msg_menu_icon" src={copy_msg}/>
                    <p className="context_msg_menu_text">Копировать</p>
                </div>}
                {!isDocument && !isPlanner && isMyMessage && <div onClick={onEdit} className="context_msg_menu_item hover">
                    <MyImage className="context_msg_menu_icon" src={edit_msg}/>
                    <p className="context_msg_menu_text">Редактировать</p>
                </div>}
            </div>
        )
    }
    if (isDesktop){
        if (!isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isFromCallScreen={isFromCallScreen} isShow={isShow} setShow={onClose} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={onClose} open={isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default MsgContextMenu;
