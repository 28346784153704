import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function ErrorCreationDialog(props) {
    const isDesktop = getIsDesktop()
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="call_edit_title">Ошибка</p>
                <p className="call_edit_desc">{props.error}</p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px 0 24px'}} onClick={() => props.setShow(false)} className="call_price_continue hover">Понятно</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ErrorCreationDialog;
