/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { getUserUrl, isUserAuthed } from '../../../utils/CookieHelper';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../api/UsersApi';
import { exitProfile, getDomainUrl } from '../../../utils/Utils';
import { setSavedAds } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';

function ProfileTooltip(props) {
    const user = props.user
    const is_repetitor = props.user != undefined ? props.user.is_repetitor : false
    const history = useHistory()
    const isAuthed = isUserAuthed()

    // const deleteProfile = () => {
    //     props.setShowMoreTooltip(false)
    // }

    const verifyProfile = () => {
        window.location.href = '/verification'
        props.setShowMoreTooltip(false)
    }
    const exit = () => {
        exitProfile()
    }

    const onMyPrices = () => {
        history.push("/profile/prices")
        props.setShowMoreTooltip(false)
    }
 
    const openEdit = useCallback(() => {
        props.setShowMoreTooltip(false)
        if (user.is_repetitor){
            history.push("/edit_profile")
        } else {
            setSavedAds(undefined)
            history.push("/profile_filling/step_0")
        }
        
    }, [history])
    const changeVideo = () => {
        if (props.setUploadVideoChanged){
            props.setUploadVideoChanged(Math.floor(Math.random() * 9999999))
        }
        if (props.setShowUploadYoutube){
            props.setShowUploadYoutube(true)
        }
        props.setShowMoreTooltip(false)
    }
 
    const block = () => {
        props.toggleBlocked()
        props.setShowMoreTooltip(false)
    }
    const appeal = () => {
        props.showToast("Жалоба успешно отправлена")
        props.setShowMoreTooltip(false)
    }
    const removeVideo = () => {
        // UploadApi.removeVideo((response) => {
        //     props.getMyInfo()
        // })
        const params = {video_url_youtube : ""}
        UsersApi.setMyInfo(params, (responseData) => {
            props.getMyInfo()
        })
        props.setShowMoreTooltip(false)
    }
    const share = () => {
        var textField = document.createElement('textarea')
        textField.innerText = (getDomainUrl() + getUserUrl(user))
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        props.showToast("Ссылка на профиль скопирована")
        props.setShowMoreTooltip(false)
        //navigator.share({url : "https://sozvon.pro/users/id" + user._id})
    }
    const hasVideo = (user.video_url_youtube != undefined && user.video_url_youtube != "" && user.is_repetitor)
    return (
        <div className="more_tooltip_profile_bg">
            <div onClick={() => props.setShowMoreTooltip(false)} className="more_tooltip_profile_bg"></div>
            {props.fromMobileNavbar ? 
                <div style={props.style ? props.style : {}} className="more_tooltip_profile">
                    <p onClick={openEdit} className="more_tooltip_profile_text hover">Редактировать профиль</p>
                    <p onClick={share} className="more_tooltip_profile_text hover">Поделиться ссылкой<br/>на профиль</p>
                    <p onClick={exit} className="more_tooltip_profile_text hover">Выйти</p>
                </div>
            :
                props.isMyProfile ? <div style={props.style ? props.style : {}} className="more_tooltip_profile">
                <p onClick={openEdit} className="more_tooltip_profile_text hover">Редактировать профиль</p>
                {is_repetitor && <p onClick={share} className="more_tooltip_profile_text hover">Поделиться профилем</p>}
                {hasVideo && <p onClick={changeVideo} className="more_tooltip_profile_text hover">Заменить видео</p>}
                {hasVideo && <p onClick={removeVideo} className="more_tooltip_profile_text hover">Удалить видео</p>}
                {is_repetitor && <p onClick={verifyProfile} className="more_tooltip_profile_text hover">Верификация знаменитости</p>}
                {is_repetitor && <p onClick={onMyPrices} className="more_tooltip_profile_text hover">Мои расценки</p>}
                <p onClick={exit} className="more_tooltip_profile_text hover">Выйти</p>
                {/* <p onClick={deleteProfile}  className="more_tooltip_profile_text more_tooltip_profile_text_r hover">Удалить профиль</p> */}
            </div> :
            <div style={props.style ? props.style : {}} className="more_tooltip_profile">
                <p onClick={share} className="more_tooltip_profile_text hover">Поделиться профилем</p>
                {isAuthed && <p onClick={block} className="more_tooltip_profile_text hover">{props.isBlocked ? "Разблокировать" : "Заблокировать"}<br/>пользователя</p>}
                {isAuthed && <p onClick={appeal} className="more_tooltip_profile_text hover">Пожаловаться</p>}
            </div>}
        </div>
    );
}

export default ProfileTooltip;
