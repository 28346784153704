import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import Loader from '../../../custom_views/Loader';
import { AuthApi } from '../../../api/AuthApi';
import { setCookie, getCookie } from '../../../utils/CookieHelper';
import { UsersApi } from '../../../api/UsersApi';

function PromoCodeDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const [promoCode, setPromoCode] = useState("")
    const [tgCode, setTGCode] = useState("")
    const [isError, setError] = useState("")
    const [promocodeError, setPromocodeError] = useState("")
    const [isPromoCodeCorrect, setPromoCodeCorrect] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const isDesktop = getIsDesktop()
    const isTGAuth = props.isTGAuth
    const isEnableButton = isTGAuth ? tgCode.length > 1 : isPromoCodeCorrect == 1
    const isOnlyPromocode = props.isOnlyPromocode == undefined ? false : props.isOnlyPromocode
    const onNext = () => {
        if (isOnlyPromocode){
            props.setShow(false)
            return
        }
        const enteredPromocode = isPromoCodeCorrect == 1 ? promoCode : ""
        setError("")
        if (isTGAuth && currentPage == 0){
            //setCurrentPage(1)
            registerUserByTelegram(enteredPromocode)
        } else if (isTGAuth){
            registerUserByTelegram(enteredPromocode)
        } else {
            props.setPromoCode(enteredPromocode)
            props.setShow(false)
        }
    }
    const registerUserByTelegram = (enteredPromocode) => {
        setLoading(true)
        const savedPromoCode = getCookie("promocode")
        AuthApi.registerUserByTelegram(tgCode, ((savedPromoCode != undefined && savedPromoCode != null && savedPromoCode != "") ? savedPromoCode : enteredPromocode), (responseData) => {
            setCookie("token", responseData.access_token)
            props.getMyInfo()
        }, (responseData) => {
            setLoading(false)
            setError(responseData.desc)
        })
    }
    // const cancel = () => {
    //     setError("")
    //     if (isTGAuth && currentPage == 0){
    //         setTGCode("")
    //         props.setPromoCode("")
    //         props.setShow(false)
    //     } else if (isTGAuth){
    //         setCurrentPage(0)
    //         props.setPromoCode("")
    //     } else {
    //         props.setPromoCode("")
    //         props.setShow(false)
    //     }
    // }
    const onPromoChanged = (value) => {
        setPromoCode(value)
        if (isPromoCodeCorrect != 0){
            setPromoCodeCorrect(0)
        }
    }
    const checkPromocode = () => {
        
        if (promoCode == undefined || promoCode == ""){
            return
        }
        setPromocodeError("")
        setLoading(true)
        console.log("checkPromocode promoCode = ", promoCode)
        AuthApi.checkPromocode(promoCode, (responseData) => {
            console.log("checkPromocode = ", responseData)
            setPromoCodeCorrect(1)
            //props.setPromoCode(promoCode)
            setLoading(false)
            if (isOnlyPromocode){
                activatePromocode(promoCode)
            }
        }, (responseData) => {
            setPromocodeError(responseData.desc)
            setLoading(false)
            setPromoCodeCorrect(-1)
        })
    }
    const activatePromocode = (promoCode) => {
        UsersApi.activatePromocode(promoCode, (responseData) => {
            if (props.onPromocodeActivated && responseData.response > 0){
                props.onPromocodeActivated()
                props.setShow(false)
            } 
        }, () => {
            setPromocodeError("Ошибка активации")
            setPromoCodeCorrect(-1)
        })
    }
    const closeTgAuth = () => {
        props.setShow(false)
        setError("")
    }
    const isPromoBtnDisabled = promoCode == undefined || promoCode == "" || promoCode.length < 3
    const getTgCodeHtml = () => {
        return(
            <div>
                <div className="referal_dialog_title">Введите код, который вы получили от<br/>Telegram-бота</div>
                <div style={{height : '140px'}} >
                    <div className="referal_dialog_input_bg">
                        <p className="auth_phone_title">Код</p>
                        <input style={{background: 'none'}} className="auth_phone_input" type="text" placeholder="Например, 645asd3hdjh46764dkfjsh5" value={tgCode} onChange={(e) => setTGCode(e.target.value)}/>
                    </div>
                </div>
                
            </div>
        )
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                {(isTGAuth && currentPage == 0) ? 
                    getTgCodeHtml()
                    : 
                    <div>
                        <div className="promocode_dialog_title">Реферальный промокод</div>
                        <div className="promocode_dialog_title promocode_dialog_desc">Это необязательный шаг</div>
                        <div style={{height : '140px'}} >
                            <div className="referal_dialog_input_bg">
                                <p className="auth_phone_title">Промокод</p>
                                <input style={{background: 'none'}} placeholder="Примерно такой 1677584730347" className="auth_phone_input" type="number" value={promoCode} onChange={(e) => onPromoChanged(e.target.value)}/>
                            </div>
                            {isLoading ? <div style={{position : 'relative', height : '48px'}}><Loader style={{marginTop : "8px"}}/></div> : 
                            isPromoCodeCorrect == 0 ? <div onClick={checkPromocode} style={isPromoBtnDisabled ? {pointerEvents : 'none', opacity : '0.2'} : {}} className="auth_promo_text promo_btn_title hover">ПРИМЕНИТЬ</div> : isPromoCodeCorrect == -1 ? 
                            <div className="promo_result_text wrong_promo">😞   {promocodeError}</div> :
                            <div className="promo_result_text correct_promo">👍  Промокод успешно применён</div>}
                        </div>
                    </div>}
                {isError != "" && <div style={{margin : '0 0 16px 0'}} className="promo_result_text wrong_promo">{isError}</div>}
                <div className="flex">
                    {/* <div onClick={cancel} className="call_prices_edit hover">Отмена</div> */}
                    {/* <div style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={onNext} className="call_price_continue hover">Далее</div> */}
                    <div style={!isLoading ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={onNext} className="call_price_continue hover">{isOnlyPromocode ? "Закрыть" : isLoading ? <Loader isWhite/> : "Далее"}</div>
                </div>
                {isTGAuth && <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={closeTgAuth} className="cancel_call_info hover">Отмена</div>
                </div>}
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default PromoCodeDialog;
