import { getIsDesktop } from "../../../utils/Utils";

function BlogsTooltips(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    const getTooltipClass = () => {
        switch (props.tooltipIndex) {
            case 0:
                return "blogs_tooltip_0"
            case 1:
                return "blogs_tooltip_1" + (props.isRepetitor ? "" : " blogs_tooltip_1_1")
            default:
                return "blogs_tooltip_2"
        }
    }
    const getText = () => {
        switch (props.tooltipIndex) {
            case 0:
                return "Написание постов поможет показать вашу экспертность и заинтересовать новых клиентов.<br/><br/>Посты будут публиковаться в разделе “Лента публикаций” и в вашем профиле."
            case 1:
                return "Отсюда вы можете добавить эксперта в Избранное, чтобы следить за его блогом и быть на связи."
            case 2:
                return "Здесь вы можете выбрать темы блогов, которые хотите видеть в своей ленте"
            default:
                return ""
        }
    }
  
    const getSquareStyle = () => {
        if (props.tooltipIndex == 0 || (isDesktop && props.tooltipIndex == 2)){
            return {left : '50%'}
        } else {
            return {left : isDesktop ? '80%' : '87%'}
        }
    }
     return (
        <div className="profile_published_tooltip">
            <div  onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div className={getTooltipClass()}>
                <div style={getSquareStyle()} className="green_rectangle"></div>
                <div className="green_profile_tooltip" dangerouslySetInnerHTML={{__html: getText()}}></div>
            </div>
        </div>
     );
 }
 
 export default BlogsTooltips;
 