import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import CreateExpressSearchHeader from './header/CreateExpressSearchHeader';
import Loader from '../../../custom_views/Loader';
import ExpressProfile from '../custom_views/ExpressProfile';
import accept from '../../../img/express_search/accept.svg'
import decline from '../../../img/express_search/decline.png'
import { ExpressSearchApi } from '../../../api/ExpressSearchApi';
import { getExpressSearchTypes } from '../helpers/ExpressSearchHelper';
import not_found_experts from '../../../img/express_search/express_not_found.svg'
import { Pages } from '../../../utils/Utils';

function ExpressSearchStep4(props) {
    const history = useHistory()

    const [seconds, setSeconds] = useState(0)
    const [isTimerActive, setIsActive] = useState(false)
    const [startTime, setStartTime] = useState(new Date()/1000)
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
                setStartTime(new Date()/1000)
            }
        }, [])

    useEffect(() => {
        let interval = null;
        if (isTimerActive) {
            interval = setInterval(() => {
                //const s = seconds + 1
                const s = Math.round(new Date()/1000 - startTime)
                setSeconds(seconds => s );      
                if (seconds >= 120){
                    setIsActive(false)
                }     
            }, 1000);
        } else if (!isTimerActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isTimerActive, seconds])

    const isLoading = props.isLoading
    const user = props.respondedUsers.length > 0 ? props.respondedUsers[0] : {}
    const [isAccepted, setAccepted] = useState(false)
    const declineExpert = () => {
        ExpressSearchApi.declineExpressSearch(user.vk_caller_id, (responseData) => {
            //props.createExpressSearch()
            var users = [...props.respondedUsers]
            if (users.length > 0){
                users.splice(0, 1)
            } else {
                props.setLoading(true)
            }
            props.setRespondedUsers(users)
            
        }, () => {})
    }
    const acceptExpert = () => {
        ExpressSearchApi.acceptExpressSearch(user.vk_caller_id, (responseData) => {
            setAccepted(true)
            props.startConnect(props.type, user)
        }, () => {})
    }
    const isShowLoader = isLoading || user.vk_caller_id == undefined
    const getLoader = () => {
        return(
            <div className="relative full_center">
                <div className="express_loader_cont">
                    <Loader style={{marginTop : 0, minHeight : 'auto', position : 'relative'}}/>
                </div>
                
                <div style={{height : '12px'}}></div>
                <div className="express_loading_title">Идёт поиск подходящего эксперта</div>
                <div className="express_loading_desc">Это может занять до 2 минут.<br/>Не уходите с данного экрана —<br/>это прервёт поиск</div>
            </div>
        )
    }
    //finished time and not found
    if (!isTimerActive && user.vk_caller_id == undefined && !isAccepted){
    //if (true){
        return(
            <div>
                <CreateExpressSearchHeader />
                <div className="create_ads_step">
                    <div className="create_ads_background express_ads_background">
                        <div className="express_expert_connection">
                            <img className="express_not_found_img" src={not_found_experts}/>
                            <div className="express_not_found_title">Нет доступных экспертов<br/>в данный момент</div>
                            <div className="express_not_found_desc">Создайте заявку на поиск эксперта и специалисты откликнутся сами</div>
                            <div onClick={() => history.push(Pages.create_ads)} style={{width : 'fit-content', margin : '30px auto 80px auto', padding : '14px 44px'}}  className="call_price_continue hover">Создать объявление</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (isAccepted){
        return(
            <div>
                <CreateExpressSearchHeader />
                <div className="create_ads_step">
                    <div className="create_ads_background express_ads_background">
                        <div className="express_client_picked express_expert_connection">
                            Соединяем вас<br/>с экспертом 🤓
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const userPrices = {rub_per_message : user.rub_per_message, free_time : user.free_time, rub_in_hour : user.rub_in_hour}
    return (
        <div>
            <CreateExpressSearchHeader />
            <div className="create_ads_step">
                <div className="create_ads_background express_ads_background">
                    {isShowLoader ? getLoader() : <ExpressProfile prices={userPrices} type={props.type} user={user}/>}
                </div>
                {!isShowLoader && <div className="express_search_buttons">
                    <div onClick={declineExpert} style={{marginRight : '40px'}} className="hover">
                        <img className="express_search_button" src={decline}/>
                        <div style={{color : '#FF5353'}} className="express_search_btn_text">Искать ещё</div>
                    </div>
                    <div onClick={acceptExpert} style={{marginLeft : '40px'}} className="hover">
                        <img className="express_search_button" src={accept}/>
                        <div style={{color : '#0DCDA0'}} className="express_search_btn_text">Соединить</div>
                    </div>
                </div>}
                {/* <CreateAdsButtons onNext={onNext}/> */}
            </div>
        </div>
    );
}

export default ExpressSearchStep4;
