import avatar from '../../../../img/avatar.svg'
import moment from 'moment'

function ShikariAdsProfile(props) {
    const item = props.item
    return(
        <div className="ads_item_profile">
            <div style={{width : '100%'}} className="flex">
                <img alt="" className="create_ads_avatar" src={item.image ? item.image : avatar}/>
                <div style={{width : '100%'}}>
                    <div className="ads_item_profile_text">{item.title}</div>
                    <div className="ads_without_comments_cont">
                        <div className="ads_item_profile_text">Опубликовано {moment.unix(moment(item.pubDate, "YYYY-MM-DD'T'HH:mm").unix()).format("DD MMMM")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShikariAdsProfile;
