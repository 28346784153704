import { useState} from 'react';
import liked from '../../../img/blogs/liked.svg'
import { getIsDesktop } from '../../../utils/Utils';

function BlogsLike(props) {
    const [isShowTooltip, setShowTooltip] = useState(false)
    const isShowTooltips = props.isShowTooltips
    const isDesktop = getIsDesktop()

    const showTooltip = () => {
        if (isShowTooltips && isDesktop){
            setShowTooltip(true)
        }
    }
    return (
        <div className="blog_item_like_absolute">
            <div onClick={props.toggleLike} onMouseEnter={showTooltip} onMouseLeave={() => setShowTooltip(false)} style={props.isLiked ? {background : '#FFE4E4', color: 'var(--red, #FF5353)'} : {}} className="blog_item_like hover">
                {props.isLiked ? 
                <img className="blogs_item_like_icon" src={liked}/>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                    <path d="M13.9605 6.32568C16.1915 6.32568 18 8.13155 18 10.3592C18 12.5708 17.1161 13.6753 13.48 16.4992L12.3814 17.3524C11.5689 17.9834 10.4311 17.9834 9.61863 17.3524L8.52 16.4992C4.8839 13.6753 4 12.5708 4 10.3592C4 8.13155 5.80853 6.32568 8.03947 6.32568C9.15104 6.32568 10.1434 6.80715 11 7.72762C11.8566 6.80715 12.849 6.32568 13.9605 6.32568ZM8.03947 7.82347C6.63696 7.82347 5.5 8.95875 5.5 10.3592C5.5 11.9473 6.15326 12.7636 9.44091 15.3169L10.5395 16.1701C10.8104 16.3805 11.1896 16.3805 11.4605 16.1701L12.5591 15.3169C15.8467 12.7636 16.5 11.9473 16.5 10.3592C16.5 8.95875 15.363 7.82347 13.9605 7.82347C13.1121 7.82347 12.3416 8.30522 11.6169 9.3517L11.4112 9.6486C11.2542 9.87539 10.9427 9.9321 10.7156 9.77526C10.666 9.74102 10.623 9.69811 10.5888 9.6486L10.3831 9.3517C9.65838 8.30522 8.88792 7.82347 8.03947 7.82347Z" fill="#99A2AD"/>
                </svg>}
                <div style={{margin: '3px 0 0 4px'}}>{props.likesCount}</div>
            </div>
            <div style={isShowTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseLeave={() => setShowTooltip(false)} className="blog_tooltip_bookmark blog_tooltip_like">
                <p className="blog_tooltip_text">{props.isLiked ? "Убрать лайк" : "Поставить лайк"}</p>
            </div>
        </div>)
}

export default BlogsLike;