import vk_icon from '../../../../img/auth/vk_icon.svg';
import tg_icon from '../../../../img/auth/tg_icon.svg';
import { Link } from "react-router-dom";
import Loader from '../../../../custom_views/Loader';
import { useState } from 'react';
import PromoCodeDialog from '../../../auth/custom_views/PromoCodeDialog';
import { Pages } from '../../../../utils/Utils';

function CreateAdsAuthButtons(props) {
    const [isLoading, setLoading] = useState(false)
    const [isShowPromocode, setShowPromocode] = useState(false)
    const [isTGAuth, setTGAuth] = useState(true)
    const authVK = () => {
        window.location.href = "https://oauth.vk.com/authorize?client_id=51494524&display=popup&redirect_uri=https://sozvon.pro/verification&scope=offline,groups&response_type=code&v=5.131"
    }
    const authTG = () => {
        setTGAuth(true)
        setShowPromocode(true)
        //window.open('https://t.me/notification_sozvon_bot', '_blank')
    }
    const promoCodeChanged = (promo_code) => {
        //empty 
    }
    return (
            <div>
                
                <button onClick={authVK} className="auth_vk_btn vk_ads_button hover">
                    {isLoading ? <Loader /> : <div className="flex">
                        <img alt='' className="vk_icon" src={vk_icon}/>
                        <p className="vk_auth_text">Войти через ВКонтакте</p>
                    </div>}
                </button>
                <a href={Pages.tg_bot} rel="noreferrer" target="_blank">
                    <button onClick={authTG} style={{marginTop : '16px'}} className="auth_vk_btn vk_ads_button hover">
                        <div className="flex">
                            <img alt='' className="vk_icon" src={tg_icon}/>
                            <p className="vk_auth_text">Войти через Telegram</p>
                        </div>
                    </button>
                </a>
                <Link to={Pages.auth_phone}>
                    <div className="use_phone_btn create_ads_phone_login hover">Войти по номеру телефона</div>
                </Link>
                <PromoCodeDialog getMyInfo={props.getMyInfo} isTGAuth={isTGAuth} setPromoCode={promoCodeChanged} isShow={isShowPromocode} setShow={setShowPromocode}/>

            </div>
    );
}

export default CreateAdsAuthButtons;
