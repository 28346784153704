import profile_fill_1 from '../../img/profile_fill_1.png'
import { Link } from "react-router-dom";
function ProfileFillStart(props) {
   
    return (
        <div>
            <div className="center_fill_cont">
                <img style={{marginLeft : 0}} className="profile_fill_start_img" src={profile_fill_1}/>
                <p className="profile_fill_start_title">Расскажите о себе</p>
                <p className="profile_fill_start_desc">Пару слов о себе, тип вашего профиля в приложении. На всё уйдёт примерно 3 минуты</p>
            </div>
            <div style={{height : '72px'}} className="fill_start_buttons">
                <button onClick={() => props.setStep(1)} className="fill_button profile_bottom_button hover">Начнём</button>
                {/* <Link to="/experts"><div className="fill_button_skip hover">Пропустить</div></Link> */}
            </div>
        </div>
    );
}

export default ProfileFillStart;
