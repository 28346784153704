import { useState } from 'react';
import plus from '../../../img/planner/plus.svg'
import AddNotifDialog from '../dialogs/AddNotifDialog';
import { getNotificationFormat } from '../../../utils/Utils';
function PlannerNotifications(props) {
    const pickedNotifications = props.pickedNotifications
    const [isShowAddNotif, setShowAddNotif] = useState(false)
    const removeNotif = (index) => {
        const array = [...pickedNotifications]
        array.splice(index, 1)
        props.setPickedNotifications(array)
    }
    const showAddNotif = () => {
        setShowAddNotif(!isShowAddNotif)
    }
    const addNotif = (milliseconds) => {
        setShowAddNotif(false)
        const array = [...pickedNotifications]
        array.push(milliseconds)
        props.setPickedNotifications(array)
    }
    return(
        <div>
            <div className="create_planner_not_flex">
                <div className="create_planner_theme">Напоминания</div>
                <img alt='' onClick={showAddNotif} className="create_planner_not_icon hover" src={plus}/>
            </div>
                    
            <div className="profile_tags_contaner">
                    {pickedNotifications.length > 0 && pickedNotifications.map((notif, index) => (
                        <div onClick={() => removeNotif(index)} style={{width : 'auto', marginRight : '16px', padding : '12px 16px'}} className="create_planner_border create_planner_border_mt hover" key={index}>
                            {getNotificationFormat(notif)}
                            <div style={{marginLeft : '10px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M11.2999 4.69922L4.70026 11.2989" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M4.70044 4.69922L11.3001 11.2989" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="create_planner_separator"/>
            <AddNotifDialog isShow={isShowAddNotif} setShow={setShowAddNotif} addNotif={addNotif}/>
        </div>
    )
}
export default PlannerNotifications;
const NotifRow = ({ user, isIRepetitor }) => {
   
    return (
        <div>
           
        </div>)
}