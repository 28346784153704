export const copyText = (text) => {
   //  var textField = document.createElement('textarea')
   //  textField.innerText = text
   //  document.body.appendChild(textField)
   //  textField.select()
   //  document.execCommand('copy')
   //  textField.remove()
   navigator.clipboard.writeText(text)
  
}

export const disabledBtn = () => {
   return {pointerEvents : 'none', background : '#D6D3E1'}
}
export const copyToClipboard = (string) => {
  navigator.clipboard.writeText(string)
}
export const canLoadByh1 = (pTags) => {
   var canLoad = true
   if (pTags.length > 0){
      Array.from(pTags).forEach(pTag => {
         if (pTag.innerText != "" || pTag.innerText.trim() != ""){
            if (canLoad){
               canLoad = pTag.innerText.trim() == "<br/>"
            }            
         }
     })
   }
   return canLoad
}
export const getQuillTooltip = () => {
    return [
        [
           "bold",
           "italic",
           "underline",
           "strike"
        ],[
           {
              "color":[]
           },{
              "background":[]
           }
        ],[
           {
              "header":1
           },
           "blockquote",
           "code-block"
        ],[
           {
              "list":"ordered"
           }, {
              "list":"bullet"
           },{
              "indent":"-1"
           },{
              "indent":"+1"
           }
        ],[
           {
              "direction":"rtl"
           },{
              "align":[]
           }
        ],[
           "link",
           "image",
           "video",
           "formula"
        ],[
           "clean"
        ]
    ]
}
export const showHideDetails = (qlEditor,hide) => {

	for (const child in Array.from(qlEditor.childNodes.values())) {
		try{
		    if (qlEditor.childNodes[child].style.display == 'block' && hide){
		    	qlEditor.childNodes[child].style.display = 'none';
		    }else if (qlEditor.childNodes[child].style.display == 'none' && hide == false){
		    	qlEditor.childNodes[child].style.display = 'block';
		    }else{

		    }
	    }catch(err){}
	}
}
export const postShorter = (postElement, fromBlogPost) => {
	var qlEditor = postElement.getElementsByClassName("ql-editor")[0]

	var i = 0;
	var max = 5;
	var canShowImg = true; 
	var needShowDetailsButton = false; 
	for (const child in Array.from(qlEditor.childNodes.values())) {
		try{
			var elem = qlEditor.childNodes[child];
			if(elem.tagName.toLowerCase() == "IFRAME".toLowerCase()){
				// elem = $(elem)
				var widthParrent = elem.parentElement.getBoundingClientRect().width
				if(widthParrent > 708){
					widthParrent = 708
				}
				var videoWidth = elem.getBoundingClientRect().width
				var videoHeight = elem.getBoundingClientRect().height
            
				var ratio = videoHeight/videoWidth

				var newWidth = widthParrent
				var newHeight = newWidth*ratio

				elem.width = newWidth;
				elem.height = newHeight;
			}
			if(canShowImg && (elem.tagName.toLowerCase() == "IFRAME".toLowerCase() || elem.getElementsByTagName("img").length > 0)){
				canShowImg = false;
			}else{
				if (i >= max && !fromBlogPost){
		    		qlEditor.childNodes[child].style.display = 'none';
		    		needShowDetailsButton = true
		    	}
		    	i++;
			}
			
		}catch(err){}
	    
    }
    const style = "color: #AC92ED; font-size: 14px; font-weight: 400; line-height: 20px; cursor: pointer; text-decoration: none"
    // if (fromBlogPost){
    if (fromBlogPost){
        var aTags = postElement.getElementsByTagName("a")
        Array.from(aTags).forEach(aTag => {
            aTag.style = style
        })
    }
    var pTags = postElement.getElementsByTagName("p")
    var imgTags = postElement.getElementsByTagName("img")
	if(needShowDetailsButton){
		qlEditor.innerHTML += '<p><a class="blogs_post_details">Читать далее</a></p>'
        var postDetails = qlEditor.getElementsByClassName("blogs_post_details")[0]
        if (postDetails != undefined){
            postDetails.style = style
            postDetails.addEventListener('click', (event) => {
                var currElement = event.target
                qlEditor = currElement.parentNode.parentNode

                if(currElement.innerHTML == "Читать далее"){
                    currElement.innerHTML = "Свернуть"
                    showHideDetails(qlEditor,false)
                }else{
                    currElement.innerHTML = "Читать далее"
                    showHideDetails(qlEditor,true)
                }
                
            })
        }
       
  
        var aTags = postElement.getElementsByTagName("a")
        Array.from(aTags).forEach(aTag => {
            aTag.style = style
        })
    
    }
   Array.from(pTags).forEach(pTag => {
      const selectors = pTag.querySelectorAll('span')
      Array.from(selectors).forEach(span => {
         span.setAttribute("style", 'background-color: white;')
      })
   })
   Array.from(imgTags).forEach(imgTag => {
      imgTag.setAttribute("style", 'border-radius : 12px')
   })
    // Array.from(aTags).forEach(aTag => {
    //     aTag.style = style
    // })
}