import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useState, useEffect } from 'react';
import check from '../../../img/express_search/check.svg'


function AcceptExpressBtn(props) {
   
    const [seconds, setSeconds] = useState(0)
    const [isActive, setIsActive] = useState(false)
    const [startTime, setStartTime] = useState(new Date()/1000)
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
                setStartTime(props.urlParams.create_time)
            }
        }, [])

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                //const s = seconds + 1
                const s = Math.round(new Date()/1000 - startTime)
                setSeconds(seconds => s );           
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds])
    const isError = props.error != ""
    const percents = (isError || seconds > 120) ? 0 : ((120 - seconds) / 120) * 100
    const isFinished = isError || seconds > 120
    return (
        <div>
            {isFinished && <div className="express_search_error">{isError ? props.error : "Запрос аннулирован — прошло больше 120 сек."}</div>}        
            <div onClick={props.onAccept} style={(isError != "" || isFinished) ? {marginLeft : '40px', opacity : 0.2, pointerEvents : 'none'} : {marginLeft : '40px'}} className="relative hover">
                <div className="circle_progress_container">
                    <CircularProgressbar value={percents}
                        styles={buildStyles({
                            strokeLinecap: 'butt',
                            width : '80px',
                            pathTransitionDuration: 0.5,
                            pathColor: '#31F8C9',
                            trailColor: 'var(--light_text)',
                        })}
                    />
                </div>
                <div className="express_search_accept">
                    <img className="express_search_check" src={check} alt=""/>
                </div>
                
                <div style={{color : '#0DCDA0', marginTop : isFinished ? "20px" : '14px'}} className="express_search_btn_text">Откликнуться<br/>{isFinished ? "" : (120 - seconds) + "сек."} </div>
            </div>
        </div>
    );
}

export default AcceptExpressBtn;
