import { getIsDesktop } from "../../../utils/Utils";

function FirstAvatarTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div onClick={() => props.setShow(false)} style={(!isDesktop && props.isShowTgBanner) ? {top : '64px'} : {}} className="experts_avatar_tooltip">
                <div style={isDesktop ? {left : '3px', top : '10%'} : {top : '0px', left : '24px'}} className="green_rectangle"></div>
                <div className="green_profile_tooltip">Здесь находится ваш профиль. Нажмите на фотографию, чтобы перейти в него.<br/><br/>Здесь вы сможете отредактировать информацию о вас и поделиться ссылкой на профиль.</div>
                
            </div>
        </div>
     );
 }
 
 export default FirstAvatarTooltip;
 