import back_arrow from '../../../img/experts/back_arrow.svg'
import { useState, useEffect,useCallback } from 'react';
import Loader from '../../../custom_views/Loader';
import { getRequestUrl, getHttpParams, getIsDesktop } from '../../../utils/Utils';
import { getRequestParams } from '../../../utils/CookieHelper';
import ExpertItem from '../list_items/ExpertItem';
import EmptyBookmarks from '../../../custom_views/EmptyBookmarks';
import { useHistory } from "react-router-dom";

function BookMarks(props) {
    const [experts, setExperts] = useState([])
    const [isLoading, setLoading] = useState(false)
    const loadByTheme = () => {
        setLoading(true)
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyFavorites", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("getMyFavorites = " + JSON.stringify(responseData))
            if (responseData.favorite_users){
                setExperts(responseData.favorite_users)
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        if (props.pickedTheme != undefined && props.pickedTheme != ""){
            loadByTheme()
        }
    }, [setExperts])

    const history = useHistory()
    const onBack = useCallback(() => {
        history.goBack()
    }, [history]);
    const isDesktop = getIsDesktop()
    return (
        <div className={isDesktop ? "search_experts_desktop" : "selected_theme"}>
            <div className="arrow_left_theme">
                <div className="flex">
                    {!isDesktop && <img onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>}
                    <p style={isDesktop ? {margin : "24px 0 24px 16px"} : {}} className="theme_title">{props.pickedTheme}</p>
                </div>
            </div>
            {/* <SearchExperts text={searchTheme} setText={setSearchTheme}/> */}
            {isLoading ? <Loader /> : 
            <div className="exprets_in_theme">
                {experts.length > 0 && experts.map((expert, index) => (
                    <ExpertItem myUser={props.user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} user={expert}/>
                ))}
                {experts.length == 0 && 
                <div style={{marginTop : '-64px'}}><EmptyBookmarks/></div>}
            </div>}
        </div>
    )
}

export default BookMarks;
