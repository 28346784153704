/* eslint-disable eqeqeq */
import payment_img from '../../../img/pay/payment_img.svg'
import call_no_avatar from '../../../img/pay/call_no_avatar.svg'
import chat_no_avatar from '../../../img/pay/chat_no_avatar.svg'
import withdrawl from '../../../img/pay/withdrawl.svg'
import payment_img_d from '../../../img/pay/payment_img_d.svg'
import call_no_avatar_d from '../../../img/pay/call_no_avatar_d.svg'
import chat_no_avatar_d from '../../../img/pay/chat_no_avatar_d.svg'
import withdrawl_d from '../../../img/pay/withdrawl_d.svg'
import profile_promotion from '../../../img/pay/profile_promotion.png'
import pro from '../../../img/pay/pro.png'

export const getPaymentDetalization = (transaction, isDetalized) => {
    const callImg = isDetalized ? call_no_avatar_d : call_no_avatar
    const chatImg = isDetalized ? chat_no_avatar_d : chat_no_avatar
    const withdrawlImg = isDetalized ? withdrawl_d : withdrawl
    const paymentImg = isDetalized ? payment_img_d : payment_img
    var info = ""
    if (transaction.type == 0){
        info = {text : "Платный звонок", img : (transaction.avatar != undefined && transaction.avatar != "") ? transaction.avatar : callImg}
    } else if (transaction.type == 1){
        info = {text : "Платный чат", img : (transaction.avatar != undefined && transaction.avatar != "") ? transaction.avatar : chatImg}
    } else if (transaction.type == 2){
        const isPayment = transaction.amount > 0
        info = {text : isPayment ? "Пополнение баланса" : "Вывод денег", img : isPayment ? paymentImg : withdrawlImg}
    } else if (transaction.type == 3){
        info = {text : "Возврат средств за паузу в разговоре", img : (transaction.avatar != undefined && transaction.avatar != "") ? transaction.avatar : callImg}
    } else if (transaction.type == 4){
        info = {text : "Пополнение за реферала", img : paymentImg}
    } else if (transaction.type == 5){  
        info = {text : "Консультация, " + transaction.extra_data + " мин.", img : call_no_avatar}
    } else if (transaction.type == 6){
        info = {text : "Возврат предоплаты", img : call_no_avatar}
    } else if (transaction.type == 7){
        info = {text : "Предоплата за созвон", img : call_no_avatar, isShowWaiting : true}
    } else if (transaction.type == 8){
        info = {text : "Оплата статуса PRO", img : pro}
    } else if (transaction.type == 9){
        info = {text : "Оплата продвижение", img : profile_promotion}
    } else if (transaction.type == 10){
        info = {text : "Видеовстреча", img : call_no_avatar}
    }
    return info
}
