import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { CompOS, getIsDesktop, getOS } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function CallCameraErrorDialog(props) {
    const isDesktop = getIsDesktop()
    var os = getOS()
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}} className="call_info_container call_screen_share_error">
                <p className="call_screen_share_error_title">Возникла ошибка</p>
                <div style={{margin : '24px 0'}}>Проверьте, что вы разрешили доступ вашему браузеру к камере.<br/>Для этого:</div>
                {os == CompOS.macos ?
                    <div style={{textAlign : 'left'}}>
                        1. Нажмите на логотип Apple<br/>
                        2. Зайдите в системные настройки<br/>
                        3. Зайдите в раздел “Конфиденциальность и безопасность”<br/>
                        4. Выберите раздел “Камера” и справа отметьте галочкой ваш браузер.
                    </div>
                    :
                    <div style={{textAlign : 'left'}}>
                        Перейдите в настройки вашего устройства и дайте разрешение использовать камеру для вашего Браузера.
                    </div>
                }   
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px 0 24px'}} onClick={() => props.setShow(false)} className="call_price_continue hover">Понятно</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CallCameraErrorDialog;
