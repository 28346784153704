/* eslint-disable eqeqeq */

import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getUrlByTags, SeoTagName } from '../utils/ExpertsUtils';

const SeoHeader = ({isSeo, seoTags, setCurrentSeoTagSelected}) => {
   
    if (isSeo && seoTags.size > 0) {
        return (
            <div className='experts_seo_links'>
                <Link onClick={() => setCurrentSeoTagSelected(SeoTagName.city)} className="hover" to={getUrlByTags(seoTags, SeoTagName.city)}>{seoTags.get(SeoTagName.city)}</Link>
                {(seoTags.get(SeoTagName.city) != "") && <div>{">"}</div>}
                <Link onClick={() => setCurrentSeoTagSelected(SeoTagName.metro)} className="hover" to={getUrlByTags(seoTags, SeoTagName.metro)}>{seoTags.get(SeoTagName.metro)}</Link>
                {(seoTags.get(SeoTagName.metro) != "" && seoTags.get(SeoTagName.specialization) != "") && <div>{">"}</div>}
                <Link onClick={() => setCurrentSeoTagSelected(SeoTagName.specialization)} className="hover" to={getUrlByTags(seoTags, SeoTagName.specialization)}>{seoTags.get(SeoTagName.specialization)}</Link>
                {(seoTags.get(SeoTagName.specialization) != "" && seoTags.get(SeoTagName.tag) != "") && <div>{">"}</div>}
                <Link onClick={() => setCurrentSeoTagSelected(SeoTagName.tag)} className="hover" to={getUrlByTags(seoTags, SeoTagName.tag)}>{seoTags.get(SeoTagName.tag)}</Link>
            </div>
        )
    } else {
        return null
    }
  
}

export default SeoHeader;
