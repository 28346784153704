import NotificationDialog from "./dialogs/NotificationDialog";
import { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { Route, useHistory } from "react-router-dom";
import TgNotifDialog from "./dialogs/TgNotifDialog";
import TgAuthInformation from "../other/custom_views/TgAuthInformation";
import { Pages } from "../../utils/Utils";
const firebaseConfig = {
    apiKey: "AIzaSyAJdDWQ4L6ZyqlnUl4dTehy2Uowqk7LaSA",
    authDomain: "vlearn-af7a8.firebaseapp.com",
    projectId: "vlearn-af7a8",
    storageBucket: "vlearn-af7a8.appspot.com",
    messagingSenderId: "42249686873",
    appId: "1:42249686873:web:a569191bb77f9714210648",
    measurementId: "G-WY2CJJG1ZZ"
}
function Step3(props) {
    const [isShowNotification, setShowNotification] = useState(false)
    const [isShowTgNotifDialog, setShowTgNotifDialog] = useState(false)
    const [isShowTgAuth, setShowTgAuth] = useState(false)
    const [firebaseApp, setFirebaseApp] = useState(undefined)
    const [type, setType] = useState(0)
    const isTgNotficitaionsAdded = props.profileInfo.telegram_username
    const history = useHistory()
    useEffect(() => {
        const app = initializeApp(firebaseConfig)
        setFirebaseApp(app)
    }, [])
    
    const onClick = (type) => {
        setType(type)
        //для уведомлений в телегу при регистрации
        // if (!isTgNotficitaionsAdded){
        //     setShowTgNotifDialog(true)
        // } else {
        //     setShowNotification(true)
        // }
        setShowNotification(true)
    }
    const onCloseNotification = (isShow, token) => {
        setShowNotification(false)
        if (type == 0){
            props.iAmClient(token)
        } else {
            props.saveStep3(1, token)
        }
    }
    const closeTgAuth = () => {
        setShowTgAuth(false)
        if (type == 0){
            props.iAmClient(undefined)
        } else {
            props.saveStep3(1, undefined)
        }
    }
    const showTgDialog = () => {
        setShowTgAuth(true)
    }
    return (
        <div>
           <p className="profile_fill_start_title title_step3">Тип вашего аккаунта</p>
           <div className="flex just_content_profile">
                <div onClick={() => onClick(0)} className="profile_type_item hover profile_type_item_r">
                    <p className="profile_type_title">Я клиент</p>
                    <p className="profile_type_desc">Это значит, что вы не планируете зарабатывать деньги в приложении как эксперт. Вы хотите здесь получать уроки или консультации.</p>
                </div>

                <div onClick={() => onClick(1)} className="profile_type_item hover profile_type_item_l">
                    <p className="profile_type_title">Я эксперт</p>
                    <p className="profile_type_desc">Это ваш вариант, если вы планируете зарабатывать в данном приложении на платных звонках или сообщениях: вы репетитор, звезда или эксперт в какой-то области. </p>
                </div>
           </div>
            <TgNotifDialog showTgDialog={showTgDialog} isShow={isShowTgNotifDialog} setShow={setShowTgNotifDialog}/>
            <NotificationDialog firebaseApp={firebaseApp} profileInfo={props.profileInfo} isShow={isShowNotification} setShow={onCloseNotification}/>
            {isShowTgAuth && <TgAuthInformation closeTgAuth={closeTgAuth} fromProfileFill user={props.profileInfo} getMyInfo={props.getMyInfo} />}
        </div>
    );
}

export default Step3;
