export const callRemoteChange = (mediaSettings, isBoardEnabled) => {
    if (mediaSettings.isVideoEnabled){
        window.document.getElementsByClassName("call_screen_video_small_bg_2")[0].setAttribute('style', 'display : none; visibility : collapse')
        window.document.getElementsByClassName("call_screen_video_small_opponent")[0].setAttribute('style', 'display : block')
    } else {
        window.document.getElementsByClassName("call_screen_video_small_opponent")[0].setAttribute('style', 'display : none; visibility : collapse')
        window.document.getElementsByClassName("call_screen_video_small_bg_2")[0].setAttribute('style', 'display : block')

    }
    if (!isBoardEnabled){
        if (!mediaSettings.isScreenSharingEnabled){
            window.document.getElementsByClassName("call_screen_mini_2_container")[0].setAttribute('style', 'display : none; visibility : collapse')
        } else {
            const rect = window.document.getElementsByClassName("call_screen_mini_1_container")[0].getBoundingClientRect()
            window.document.getElementsByClassName("call_screen_mini_2_container")[0].setAttribute('style', 'display : block; top : ' + (rect.bottom + 8) + 'px;')
        }
    }
};

