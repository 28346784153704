import { useState} from 'react';
import views from '../../../img/profile/views.svg'
import { getIsDesktop } from '../../../utils/Utils';

function BlogsViews(props) {
    const [isShowTooltip, setShowTooltip] = useState(false)
    const isShowTooltips = props.isShowTooltips
    const isDesktop = getIsDesktop()
    const showTooltip = () => {
        if (isShowTooltips && isDesktop){
            setShowTooltip(true)
        }
    }
    return (
        <div className="relative">
            <div onMouseEnter={showTooltip} onMouseLeave={() => setShowTooltip(false)} style={{marginBottom : '8px'}} className="flex">
                <img className="profile_views_img" src={views}/>
                <p className="profile_views_text">{props.views}</p>
            </div>
            <div style={isShowTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseLeave={() => setShowTooltip(false)} className="blog_tooltip_bookmark blog_tooltip_views">
                <p className="blog_tooltip_text blog_tooltip_views">Количество просмотров</p>
            </div>
        </div>)
}

export default BlogsViews;