import avatar from '../../../../img/avatar.svg'
import message from '../../../../img/experts/message.svg'
import star from '../../../../img/experts/star.svg'
import bookmark from '../../../../img/experts/bookmark.svg'
import bookmark_selected from '../../../../img/experts/bookmark_selected.svg'
import { getRating, getIsDesktop } from '../../../../utils/Utils';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../../api/UsersApi';
import Loader from '../../../../custom_views/Loader'
import moment from 'moment'

import { linkify } from '../../CreateAds/helpers/CreateAdsHelper';
import { getCookie } from '../../../../utils/CookieHelper';
function AdsExpertItem(props) {
    
    const videoRef = useRef(null)
    const history = useHistory()
    const comment = props.comment
    const user = comment.owner_user
    const [isInBookmarks, setInBookmarks] = useState(false)
    const [isVideoLoading, setVideoLoading] = useState(true)
    const handleOnClick = useCallback(() => {
        props.setSelectedProfile(user)
        history.push("/users/id" + user._id)
    }, [history]);
    const isDesktop = getIsDesktop()
    const setHovered = (isHovered) => {
        let video = videoRef.current
        if (videoRef != null && isHovered && video != undefined){
            video.play()
        } else if (videoRef != null && video != undefined) {
            video.pause()
        }
    }
    useEffect(() => {
        if (props.myUser != undefined && props.myUser.favorite_users){
            getFavorites()
        }
    }, [])
    const getFavorites = () => {
        const favorites = props.myUser.favorite_users
        if (favorites){
            favorites.forEach(profile => {
                if (user.vk_caller_id == profile){
                    setInBookmarks(true)
                }
            })
        }
    }
    const toggleBookmarks = () => {
        UsersApi.toggleBookmarks(user.vk_caller_id, isInBookmarks, (responseData) => {})
        setInBookmarks(!isInBookmarks)
    }
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade
    const messageText = comment.message == undefined ? "" : comment.message.includes("\n") ? ("<div>" +  comment.message.replaceAll("\n", "<br/>") + "</div>") : comment.message

    return (
        <div style={{paddingBottom : '24px'}} className="relative">
            <a href={"/users/id" + user._id} target="_blank">
                <div style={{width : '100%'}} className="flex hover">
                    <img className="create_ads_avatar" src={user.avatar ? user.avatar : avatar}/>
                    <div style={{width : '100%'}}>
                        <div>
                            <div className="ads_picked_profile_name">{user.name} {user.surname}</div>
                            <div className="flex">
                                <img className="experts_info_icon" src={star}/>
                                <p className="experts_info_text">{getRating(user)}</p>

                                <img style={{marginLeft : '24px'}} className="experts_info_icon" src={message}/>
                                <p className="experts_info_text">{countReviews}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </a>
            <div className="my_ads_item_comment_text" dangerouslySetInnerHTML={{__html: linkify(messageText)}}/>
            {user.vk_caller_id != getCookie("id") && <img onClick={toggleBookmarks} className="my_ads_item_bookmarks" src={isInBookmarks ? bookmark_selected : bookmark}/>}
            <div style={props.count == 1 ? {border : 'none'} : {}} className="my_ads_item_comment_time">Отклик от {moment.unix(moment(comment.create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD.MM.YYYY, HH:mm")}</div>
        </div>
        
    )
}

export default AdsExpertItem;
