import { getIsDesktop } from "../../../utils/Utils";

function LKBalanceTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div className="lk_balance_tooltip">
                <div style={isDesktop ? {left : '80%'} : {bottom : '6px', position : 'absolute'}} className="green_rectangle"></div>
                <div className="green_profile_tooltip">Здесь находится ваш баланс. С него вы оплачиваете общение с экспертами и репетиторами.</div>
                
            </div>
        </div>
     );
 }
 
 export default LKBalanceTooltip;
 