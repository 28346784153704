import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class AuthApi {
    
    static registerUser(params, onSuccess, onError) {
        fetch(getRequestUrl() + "registerUser", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("registerUser response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static confirmPhoneNumber(params, onSuccess, onError) {
        fetch(getRequestUrl() + "confirmPhoneNumber", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("confirmPhoneNumber response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static registerUserByVK(code, promo_vk_caller_id, onSuccess, onError) {
        const params = {code : code, promo_vk_caller_id : promo_vk_caller_id}
        fetch(getRequestUrl() + "registerUserByVK", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("registerUserByVK response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static checkPromocode(promo_code, onSuccess, onError) {
        const params = {promo_code : promo_code}
        fetch(getRequestUrl() + "checkPromocode", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("checkPromocode response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static registerUserByTelegram(telegram_code, promo_code, onSuccess, onError) {
        const params = {telegram_code : telegram_code, promo_vk_caller_id : promo_code, type_os : 'web'}
        fetch(getRequestUrl() + "registerUserByTelegram", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
            log("registerUserByTelegram response = ", responseData)
        })
    }
    static addTelegramNotify(telegram_code, onSuccess, onError) {
        const params = {telegram_code : telegram_code}
        fetch(getRequestUrl() + "addTelegramNotify", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
            log("addTelegramNotify response = ", responseData)
        })
    }
    static registerUserByTelegramNew(user, onSuccess) {
        fetch(getRequestUrl() + "registerUserByTelegramNew", {
                    method: "POST",
                    headers: {'Content-Type': 'application/json'}, 
                    body: JSON.stringify({telegram_data:JSON.stringify(user)})
        })
        .then((response) => response.json())
        .then(responseData => {
            onSuccess(responseData)
            log("addTelegramNotify response = ", responseData)
        })
    }
}