/* eslint-disable eqeqeq */
import sbp from '../../../img/pay/sbp.svg'
import usdt from '../../../img/pay/usdt.svg'
import bank_tin from '../../../img/pay/bank_tin.svg'
import bank_sber from '../../../img/pay/bank_sber.svg'
import bank_alfa from '../../../img/pay/bank_alfa.svg'
import bank_raif from '../../../img/pay/bank_raif.svg'
export const getPayTypes = () => {
   
    return [{img : sbp, text : "Система быстрых платежей", isSelected : true}, {img : usdt, text : "USDT на криптокошелёк"}]
}
export const sortSbpBanks = (banks) => {
    var mBanks = [...banks]
    const tinkoffIndex = mBanks.findIndex((bank => bank.member_id === "100000000004"))
    
   
    if (tinkoffIndex != -1){
        mBanks[tinkoffIndex].img = bank_tin
        swapElements(mBanks, tinkoffIndex, 0)
    }
    const alphaIndex = mBanks.findIndex((bank => bank.member_id === "100000000008"))
    if (alphaIndex != -1){
        mBanks[alphaIndex].img = bank_alfa
        swapElements(mBanks, alphaIndex, 1)
    }
    const raifIndex = mBanks.findIndex((bank => bank.member_id === "100000000007"))
    if (raifIndex != -1){
        mBanks[raifIndex].img = bank_raif
        swapElements(mBanks, raifIndex, 2)
    }
    const sberIndex = mBanks.findIndex((bank => bank.member_id === "100000000111"))
    if (sberIndex != -1){
        mBanks[sberIndex].img = bank_sber
        swapElements(mBanks, sberIndex, 3)
    }
    return mBanks
}
export const swapElements = (array, index1, index2) => {
    let temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
}