import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import head from '../../../img/profile/ads/head.svg'
import { Link } from "react-router-dom";

function ProfileAdsDialog(props) {
    const user = props.user
    const nextDialogInfo = props.nextDialogInfo
    const countRub = user.count_rub == undefined ? 0 : Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
    const notEnoughtMoney = countRub < nextDialogInfo.price
    const close = () => {
        props.setNextDialogInfo({isShow : false, price : 0, type : 0})
    }

    const isDesktop = getIsDesktop()
    
    const getInnerHtml = () => {
        return(
            <div className="call_info_container profile_published_cont">
                <div className="profile_published_img_container">
                    <img className="profile_ads_dialog_image" src={head}/>
                </div>
                <div className="profile_ads_dialog_title">{notEnoughtMoney ? <div>На вашем балансе<br/>недостаточно средств</div> : 'Что дальше?'}</div>
                {notEnoughtMoney ? 
                <div style={{textAlign : 'center'}} className="profile_published_text">
                    Пополните ваш баланс на {nextDialogInfo.price - parseInt(countRub)}₽.
                </div>
                :
                <div>
                    <div className="profile_published_text">Наш представитель настроит рекламную кампанию для вас и напишет вам внутри сервиса sozvon.pro, что кампания создана и запущена — после этого деньги спишутся с вашего баланса.
                    <br/><br/>
                    Команда продвижения работает 
                    пн-пт с 10 до 18:00. Если вы оформили заявку в иное время, то нужно будет немного подождать.</div>
                </div>} 
                {notEnoughtMoney ? 
                <div style={{marginTop : '36px'}} className="flex">
                    <div onClick={close} className="call_prices_edit hover">Отмена</div>
                    <Link style={{width : '100%'}} to={Pages.pay + "?pay=1&balance=" + (nextDialogInfo.price - parseInt(countRub))}><div className="call_price_continue hover">Пополнить</div></Link>
                </div>
                :
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px 0 24px'}} onClick={close} className="call_price_continue hover">Понятно</div>
                </div>}
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default ProfileAdsDialog;

