import big_head from '../../../img/experts/big_head.svg'
import { Pages } from '../../../utils/Utils';
function NotAuthed(props) {
    
    return (
        
        <div className="center_empty_profile">
            <div>
                <img alt="" className="expert_bighead_width empty_profile_img" src={big_head}/>
                <p className="empty_profile_description">Войдите или зарегистрируйтесь, чтобы общаться с экспертами и получать новые знания 😎 или чтобы зарабатывать на своих знаниях как эксперт 🧐.</p>
            </div>
            <button style={props.btnStyle ? props.btnStyle : {}} onClick={() => window.location.href = Pages.auth + "?prev_url=" +  window.location.pathname} className="fill_button hover profile_bottom_button empty_profile_btn">Авторизоваться</button>
        </div>
    );
}

export default NotAuthed;
