import { useState } from 'react';
import close_editable_msg from '../../../../img/chat/close_editable_msg.svg'
import CloseAdsDialog from '../dialogs/CloseAdsDialog';
import { useHistory } from "react-router-dom";

function CreateAdsHeader(props) {
    const history = useHistory()

    const [isShowRemoveDialog, setShowRemoveDialog] = useState(false);
    const  isEdit = props.isEdit
    const onCloseClick = () => {
        if (isEdit){
            history.goBack()
        } else {
            setShowRemoveDialog(!isShowRemoveDialog)
        }
    }
    return (
        <div className="relative">
            <p className="create_ads_title">Создание объявления на поиск онлайн-эксперта</p>
            <p className="create_ads_progress">Прогресс: {props.progress}%</p>
            <img alt="" onClick={onCloseClick} className="create_ads_close hover" src={close_editable_msg}/>
            <CloseAdsDialog isShow={isShowRemoveDialog} setShow={setShowRemoveDialog}/>
        </div>
    );
}

export default CreateAdsHeader;
