import vk_ads from '../../../img/profile/ads/vk_ads.svg'
import check from '../../../img/profile/ads/check.svg'
import { Pages } from '../../../utils/Utils';

function ProfileAdsDemoVideo(props) {
  
    return (
        <div className="profile_ads_item">
            <div className="flex">
                <div style={{width : '100%'}}>
                    <div className="flex">
                        <img className="profile_ads_item_target_icon" src={vk_ads}/>
                        <div>
                            <div className="profile_ads_item_title">Демонстрационное видео</div>
                            <div className="profile_ads_item_price">1 500 ₽</div>
                        </div>
                    </div>
                    Запишите пробный урок через сервис sozvon.pro. Наш представитель возьмёт у вас урок по теме вашего предмета.
                    <br/><br/>
                    Мы заинтересованы преподнести вас с лучшей стороны, поэтому общение на уроке будет максимально мягким и комфортным.
                    <div className="profile_ads_item_demo">Что вы получите:</div>
                    <div className="profile_ads_item_demo_flex">
                        <img className="profile_ads_item_demo_icon" src={check}/>
                        <div>
                            Мы бесплатно смонтируем записанный видеоурок
                        </div>
                    </div>
                    <div className="profile_ads_item_demo_flex">
                        <img className="profile_ads_item_demo_icon" src={check}/>
                        <div>
                            Видео будет прикреплено к вашему профилю
                        </div>
                    </div>
                    <div className="profile_ads_item_demo_flex">
                        <img className="profile_ads_item_demo_icon" src={check}/>
                        <div>
                            Ваш профиль будет чаще рекомендоваться внутри нашего сервиса
                        </div>
                    </div>
                    <div className="profile_ads_item_demo_flex">
                        <img className="profile_ads_item_demo_icon" src={check}/>
                        <div>
                            Повышение доверия со стороны потенциальных клиентов
                        </div>
                    </div>
                    <div onClick={() => props.buyDemoAds()} className="profile_ads_item_btn hover">Заказать <span style={{color : '#FFDF7F', marginLeft : '8px'}}>1 500 ₽</span></div>

                </div>
            </div>
        </div>
    );
}

export default ProfileAdsDemoVideo;
