import { getIsDesktop } from "../../../utils/Utils";

function ChatBalanceTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div  onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div className="chat_balance_tooltip">
                <div style={isDesktop ? {left : '50%', bottom : '6px', position : 'absolute'} : {bottom : '6px', position : 'absolute'}} className="green_rectangle"></div>
                <div className="green_profile_tooltip">{isDesktop ? "Здесь отображается стоимость сообщений в выбранном чате. Нажмите на них, чтобы увидеть детали" : "Здесь отображается стоимость сообщений в выбранном чате и состояние вашего баланса. Нажмите на них, чтобы увидеть детали"}</div>
                
            </div>
        </div>
     );
 }
 
 export default ChatBalanceTooltip;
 