/* eslint-disable eqeqeq */
import advantages_desk from '../../../img/planner/advantages_desk.svg'
import advantages_individual from '../../../img/planner/advantages_individual.svg'
import advantages_online from '../../../img/planner/advantages_online.svg'
import advantages_planner from '../../../img/planner/advantages_planner.svg'
import advantages_prepaid from '../../../img/planner/advantages_prepaid.svg'
import advantages_video from '../../../img/planner/advantages_video.svg'
import advantages_world_pays from '../../../img/planner/advantages_world_pays.svg'
import React from 'react';

function ExpertsAdvatages(props) {
    const advantages4 = [{title : "Занятия онлайн", icon : advantages_online}, {title : "Виртуальная доска", icon : advantages_desk}, {title : "Индивидуальные\nи групповые занятия", icon : advantages_individual}, {title : "Качественная\nвидеосвязь", icon : advantages_video}]
    const advantages5 = [{title : "Занятия онлайн", icon : advantages_online}, {title : "Виртуальная доска", icon : advantages_desk}, {title : "Предоплата\nи оплата за\nнеявку", icon : advantages_prepaid}, {title : "Планировщик занятий", icon : advantages_planner}, {title : "Международные платежи", icon : advantages_world_pays}]
    const advantagesExperts = [{title : "Занятия онлайн", icon : advantages_online}, {title : "Виртуальная доска", icon : advantages_desk}, {title : "Планировщик занятий", icon : advantages_planner}, {title : "Международные платежи", icon : advantages_world_pays}, {title : "Индивидуальные\nи групповые занятия", icon : advantages_individual}, {title : "Качественная\nвидеосвязь", icon : advantages_video}]
    const array = props.isExperts ? advantagesExperts : props.is5 ? advantages5 : advantages4
    return (
        <div style={props.style ? props.style : {}} className="experts_advatages">
            {props.isExperts == undefined && <div className='experts_advatages_title'>Преимущества</div>}
            {array.map((item, index) => (
                <div className='flex experts_advatages_item' index={index} key={index}>
                    <img alt='' className='advantages_icon' src={item.icon}/>
                    <div className='advantages_icon_title'>{item.title}</div>
                </div>
            ))}
        </div>
    )
}

export default ExpertsAdvatages;