import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { setModelParam, getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function YouEarnedBottomSheet(props) {
    const writeReview = props.writeReview
    const close = () => {
        setModelParam(props.writeReview, "isShowToRepetitor", false, props.setWriteReview)
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div className="call_info_container">
                <p className="you_earned_t_dialog">Вы заработали</p>
                <p className="you_earned_money_dialog">{writeReview.price}₽</p>
                <button style={isDesktop ? {position : 'relative', left : 0} : {position : 'relative'}} onClick={close} className="fill_button profile_bottom_button hover">Понятно</button>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={writeReview.isShowToRepetitor} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet onDismiss={close} style={{zIndex : 9999}} open={writeReview.isShowToRepetitor}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default YouEarnedBottomSheet;
