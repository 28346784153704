/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { Pages } from '../../../../utils/Utils';
import ProIcon from '../custom_views/ProIcon';
import ShikariAdsProfile from '../custom_views/ShikariAdsProfile';
import React, { useRef } from 'react';

const ShikariAdsItem = ({ item, index, setPickedAds, setSize, windowWidth }) => {
    const rowRef = useRef();
    // React.useEffect(() => {
    //   setSize(index, rowRef.current.getBoundingClientRect().height + 16);
    // }, [setSize, index, windowWidth]);
    const onClick = () => {
        setPickedAds(item)
    }
    return (
        <Link ref={rowRef} to={Pages.ads_board + "/shikari?id=" + item._id}>
            <div onClick={onClick} className="ads_item_container hover">
                {/* <div className="flex"><ProIcon isShow/></div> */}
                <div className="flex"><div className="ads_shikari_badge">из соцсетей</div></div>
                <div className="ads_shikari_text">
                   {item.description}
                </div>
                
                <ShikariAdsProfile item={item}/>
            </div>
        </Link>
    )
}

export default ShikariAdsItem;
