/* eslint-disable eqeqeq */
import '../../styles/menu.css'
import navbar_logo from '../../img/menu/navbar_logo.svg'
import { Link } from "react-router-dom";
import { isUserAuthed } from '../../utils/CookieHelper';
import { Pages, getAuthUrlWithPrev } from '../../utils/Utils';

function DesktopNavbar(props) {
    const user = props.user
    const isAuthed = isUserAuthed()

    const rubs = parseFloat(user.count_rub).toFixed(1)
    const countRub = rubs % 1 == 0 ? parseInt(rubs) : rubs
    return (
        <div className="desktop_navbar">
            <div className="flex">
                {/* <Link className="hover" to="/experts"> */}
                <img alt='' className="navbar_logo" src={navbar_logo}/>
                {/* </Link> */}
                <Link className="hover" to={Pages.referals}><p className="navbar_link hover">Реферальная система</p></Link>
                <a rel="noreferrer" className="hover" target="_blank" href={Pages.terms}><p className="navbar_link hover">Правила сервиса</p></a>
                <a rel="noreferrer" href={Pages.support} target="_blank" className="navbar_link hover">Помощь</a>
                <Link className="hover" to={Pages.other}><p className="navbar_link hover">Настройки</p></Link>

                {/* <Link className="navbar_link hover" to="/other/referals">Сотрудничество</Link> */}
            </div>
            {(isAuthed && user.count_rub != undefined) && <Link className="hover" to={Pages.pay}>
                <div className="navbar_balance hover">
                    <div style={{textDecoration : 'none', textAlign : 'right'}} className="">Баланс {countRub}₽</div>
                    <div className="navbar_up_balance_btn">Пополнить</div>
                </div>
            </Link>}
            {!isAuthed && <Link className="hover" to={getAuthUrlWithPrev()}>
                <div className="hover">
                    <div className="navbar_balance navbar_not_authed hover">Войти 👋</div>
                </div>
            </Link>}
        </div>

    );
}

export default DesktopNavbar;

