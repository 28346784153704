var isShowLogs = true

export const error = (onError, responseData) => {
    if(onError){
        onError(responseData)
    }
}
export const log = (text, object) => {
    if (isShowLogs){
        console.log(text, object)
    }
}
export const onResponse = (responseData, onSuccess, onError) => {
    if (responseData.response > 0){
        onSuccess(responseData)
    } else {
        error(onError, responseData)
    }
}
export const setApiMetadata = (meta_data) => {
    if (meta_data){
        window.document.querySelector('meta[property="og:description"]').setAttribute("content", meta_data.description)
        window.document.querySelector('meta[property="og:title"]').setAttribute("content", meta_data.title)
        window.document.querySelector('meta[name="description"]').setAttribute("content", meta_data.description)
        if (meta_data.keywords){
            window.document.querySelector('meta[property="keywords"]').setAttribute("content", meta_data.keywords)
        }
        window.document.title = meta_data.title
        setTimeout(function() {
            const h1Tags = window.document.getElementsByClassName("h1_title")
            console.log("h1Tags = ", h1Tags)
            if (h1Tags.length > 0 && meta_data.h1){
                h1Tags[0].innerHTML = meta_data.h1
            } else if (h1Tags.length > 0 && meta_data.title){
                h1Tags[0].innerHTML = meta_data.title
            }
        }, 500)
       
    }
   
}