import crown from '../../../../img/chat/crown.svg'
import { Pages } from '../../../../utils/Utils';

function ProAvailable(props) {
    const config = props.config ? props.config : {PRICE_PRO : 490} 
    return(
        <a rel="noreferrer" href={Pages.pro} target="_blank"> 
            <div className="ads_picked_shikari_pro hover">
                <div>Больше возможностей<br/><div className='flex'>со статусом PRO<span>от {config.PRICE_PRO}₽</span></div></div>

                <img alt='' src={crown}/>
            </div>
        </a>
    )
}
export default ProAvailable;