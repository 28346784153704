/* eslint-disable eqeqeq */
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import { UsersApi } from '../../../../api/UsersApi';


function RemoveTgDialog(props) {
    const [isLoading, setLoading] = useState(false)
   
    const isDesktop = getIsDesktop()
    const user = props.user
   
    const onNext = () => {
        setLoading(true)
        // AuthApi.addTelegramNotify(tgCode, (responseData) => {
        //     setLoading(false)
        //     props.getMyInfo()
        //     if (props.onFinish){
        //         props.onFinish()
        //     }
        //     cancel()
        // }, () => {
        //     setLoading(false)
        // })
        UsersApi.clearTelegramNotify((response) => {
            setLoading(false)
            props.getMyInfo()
            cancel()
        }, () => {
           
        })
        
    }
    const cancel = () => {
       
        props.setShow(false)
    }
   
    const getTgCodeHtml = () => {
        return(
            <div>
                <div className="other_settings_change_title">Отвязать Telegram</div>
                <div className='other_settings_change_url'>
                    <div>В этом случае вы не узнаете о том, что с вами кто-то пытался связаться через сервис.</div>
                    
                </div>
            </div>
        )
    }
    const getInnerHtml = () => {
        return(
            <div className="other_settings_dialog">
                {getTgCodeHtml()}
                <div style={{marginTop : '16px'}} className="flex">
                    <div onClick={cancel} className="call_prices_edit hover">Отмена</div>
                    <div onClick={onNext} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : "Отвязать"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default RemoveTgDialog;
