/* eslint-disable eqeqeq */
import '../../../styles/ads_desk.css';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Loader from '../../../custom_views/Loader';
import { getIsDesktop, getUrlParameter, Pages } from '../../../utils/Utils';
import back_arrow from '../../../img/experts/back_arrow.svg'
import share_ads from '../../../img/create_ads/share_ads.svg'
import { getEducationTargets, getHowLong, getAges, getSexes, getMyLevels, linkify, getAdsPrices, checkButtonDisabled } from '../CreateAds/helpers/CreateAdsHelper';
import moment from 'moment'
import CreateAdsDaysPicker from '../CreateAds/custom_views/CreateAdsDaysPicker';
import autosize from 'autosize';
import { AdsApi } from '../../../api/AdsApi';
import { isUserAuthed, getCookie, setCookie } from '../../../utils/CookieHelper';
import { CSSTransition } from 'react-transition-group';
import Toast from '../../profile/custom_views/Toast';
import MyAdsProfile from './custom_views/MyAdsProfile';
import AdsProfileCreator from './custom_views/AdsProfileCreator';
import MyPickedAds from './MyPickedAds';
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/blogs/blogs_back.svg'
import AdsAnswerTooltip from './tooltips/AdsAnswerTooltip';
import ContactsDialog from './dialogs/ContactsDialog';
import ProAvailable from './custom_views/ProAvailable';
import NeedProDialog from '../../other/custom_views/dialogs/NeedProDialog';

function PickedAds(props) {
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const inputRef = useRef(null)
    const ads = props.pickedAds
    const user = props.user
    const [answer, setAnswer] = useState("Здравствуйте, предлагаю свои услуги")
    const [isLoading, setLoading] = useState(false)
    const [comments, setComments] = useState([])
    const [toastText, setToastText] = useState("")
    const [isFromLink, setFromLink] = useState(false)
    const [isShowAdsAnswerTooltip, setShowAdsAnswerTooltip] = useState(false)
    const [isShowOpenContacts, setShowOpenContacts] = useState(false)
    const [isShowNeedPro, setShowNeedPro] = useState(false)
    const socketMessage = props.socketMessage
    useEffect(() => {
        autosize(inputRef.current)
        
    }, [])
    useEffect(() => {
        if (socketMessage && socketMessage.event == "buyPro"){
            console.log("buyPro pickedAds = ", socketMessage)
        }
       
    }, [socketMessage])
    useEffect(() => {
        if (getCookie("shown_picked_ads_tooltip") != getCookie("id") && isUserAuthed()){
            setCookie("shown_picked_ads_tooltip", getCookie("id"))
            setShowAdsAnswerTooltip(true)
        }
    }, [])
    useEffect(() => {
        const ads = props.pickedAds
        //if (ads != undefined && ads != undefined){
            const id = ads == undefined ? getUrlParameter("id") : ads._id
            console.log("getRequestRepetitorById start")
            AdsApi.getRequestRepetitorById(ads == undefined ? "" : ads.owner_user.vk_caller_id, id, (response) => {
                if (response.response > 0){
                    console.log("getRequestRepetitorById result = ", response)
                    setComments(response.result.comments)
                    setFromLink(ads == undefined)
                    props.setPickedAds(response.result)
                    
                }
            }, (response) => {
                    setFromLink(true)
                    props.setPickedAds({error : response.desc})
            })
        //}
    }, [])
    const onBack = () => {
        if (isFromLink){
            window.location.href = Pages.ads_board
        } else {
            history.goBack()
            props.setPickedAds(undefined)
        }
        
    }
    const share = () => {
        var textField = document.createElement('textarea')
        textField.innerHTML = "Здесь ищут эксперта, похожего на вас:\n" + window.location.href
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        showToast("Ссылка на объявление скопирована")
    }
    
    if (props.pickedAds == undefined){
        return(
            <div className="ads_board_container">
                <Loader/>
            </div>
        )
    }
    const getToolbar = () => {
        return(
            <div style={props.pickedAds.error ? {background : 'none'} : {}} className="picked_ads_top_header">
                <div className="flex">
                {isDesktop ? <img onClick={onBack} className="ads_back_desktop hover" src={other_back}/> 
                :
                <img onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                </div>
                {!isDesktop && <img onClick={share} className="ads_share_img hover" src={share_ads}/>}
            </div>
        )
    }
    if (props.pickedAds.error){
        return(
            <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
                <div className="ads_flex_cont ads_archive_desktop">
                    <div className="picked_ads_top_container">
                            {getToolbar()}
                    </div>
                    <div style={isDesktop ? {} : {paddingTop : '16px'}} className='blog_toolbar_title'>{props.pickedAds.error}</div>
                    
                </div>
            </div>
        )
    }
    if (ads.owner_user.vk_caller_id == getCookie("id")){
        return(<MyPickedAds setFromLink={setFromLink} isFromLink={isFromLink} user={props.user} comments={comments} ads={ads}/>)
    }

    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
   
   
    const getInfoLine = (title, text) => {
        return(
            <p className="ads_picked_info_line ads_picked_info_line_title">
                <span className="ads_opacity_05">{title} </span>
                {text}
            </p>
        )
    }
   
    const sendAnswer = () => {
        if (!user.is_pro){
            window.open(Pages.pro, '_blank')
            return
        }
        setLoading(true)
        AdsApi.respondRequestRepetitor(ads._id, answer, (response) => {
            if (response.response > 0){
                setComments([{message : answer, create_date : moment().format("DD.MM.yyyyTHH:mm:ss")}])
            }
            setLoading(false)
        }, () => {setLoading(false)})
    }
    const extraData = ads.extra_data == undefined ? "" : ads.extra_data.includes("\n") ? ("<div>" +  ads.extra_data.replaceAll("\n", "<br/>") + "</div>") : ads.extra_data
    const formattedComment = comments.length == 0 ? "" : comments[0].message.includes("\n") ? ("<div>" +  comments[0].message.replaceAll("\n", "<br/>") + "</div>") : comments[0].message
    console.log("ads = ", ads)
    
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="ads_flex_cont ads_archive_desktop">
                <div className="ads_picked_desktop">
                    <div className="picked_ads_top_container">
                            {getToolbar()}
                            <div className="ads_picked_info_container ads_picked_info_container_p">
                                <div className="ads_item_spec">{ads.specialization}</div>
                                <h1 className="ads_picked_info_title">{ads.name}</h1>
                                {getInfoLine("Цель:", getEducationTargets()[ads.target_education].toLocaleLowerCase())}
                                {getInfoLine("Как долго планируете заниматься:", getHowLong()[ads.training_period].toLocaleLowerCase())}
                                {getInfoLine("Возраст клиента:", getAges()[ads.age].toLocaleLowerCase())}
                                {getInfoLine("Желаемый пол эксперта:", getSexes()[ads.sex_repetitor].toLocaleLowerCase())}
                                {getInfoLine("Уровень знаний клиента в данной области:", getMyLevels()[ads.level_my].toLocaleLowerCase())}
                                {ads.extra_data && <div className="ads_picked_info_line ads_picked_info_line_title">
                                    <span className="ads_opacity_05">Пожелания к заявке: </span>
                                    <div style={{lineHeight : '20px'}} dangerouslySetInnerHTML={{__html: linkify(extraData)}}></div>
                                </div>}
                                <div className="ads_item_header_title ads_item_price_mb">{ads.level_repetitor == 0 ? "Цена не имеет значения" : getAdsPrices()[ads.level_repetitor]}</div>

                            </div>
                            <div className="ads_line"/>
                            <AdsProfileCreator setShowNeedPro={setShowNeedPro} setShowOpenContacts={setShowOpenContacts} ads={ads} user={user}/>
                    </div>
                    <div>
                        <p className="ads_picked_days_title">Желаемые дни и время занятий</p>
                        <div className="ads_picked_days_container">
                                <CreateAdsDaysPicker time={ads.desired_class_time}/>
                        </div>
                    </div>
                    {(ads.owner_user && ads.owner_user.ip_address != "::1") ? <div onClick={isShowAdsAnswerTooltip ? () => setShowAdsAnswerTooltip(false) : () => {}} style={{marginBottom : '48px'}} className="ads_picked_comment_container">
                            <div className="ads_picked_comment_title">Мой отклик</div>
                            {isUserAuthed() ?
                            (!user.is_pro && user.is_repetitor) ? 
                                <div>
                                    <div className='ads_pro_available'>Откликаться на такие объявления могут только эксперты со статусом PRO</div>
                                    <ProAvailable config={props.config}/>
                                </div>
                            :
                            <div>
                                <MyAdsProfile user={props.user}/>
                                {comments.length > 0 ? <div>
                                    <div className="ads_my_answer" dangerouslySetInnerHTML={{__html: linkify(formattedComment)}}></div>
                                    <div style={{margin : '16px 0'}} className="ads_item_profile_text">Отправлен {moment.unix(moment(comments[0].create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD MMMM yyyy в HH:mm")}</div>
                                </div>:
                                <div className="relative">
                                    <textarea ref={inputRef} style={{minHeight : '110px', height : '110px', marginTop : '0'}} placeholder="Напишите о том, чем вы можете быть полезны клиенту" className="create_ads_input profile_textare_bio"  value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                                    <div style={checkButtonDisabled(answer.length <= 0)} onClick={sendAnswer} className="create_ads_btn ads_send_answer hover">{isLoading ? <Loader isWhite/> : "Оставить отклик"}</div>
                                    {isShowAdsAnswerTooltip && <AdsAnswerTooltip setShow={setShowAdsAnswerTooltip}/>}
                                </div>}
                            </div> : <NotAuthedUser />}
                    </div> : <div style={{height : '80px'}}/>}
                    {/* {isShowAdsAnswerTooltip && <div onClick={() => setShowAdsAnswerTooltip(false)} className="more_tooltip_profile_bg"></div>} */}

                </div>
                {isDesktop && 
                <div className="ads_desktop_share_cont ads_share_cont">
                    <div onClick={share} className="flex hover">
                        <img alt='' className="ads_share_img hover" src={share_ads}/>
                        <div className="ads_desktop_share_text">Поделиться</div>
                    </div>
                </div>}

           </div>
           <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
            <ContactsDialog getMyInfo={props.getMyInfo} showToast={showToast} owner_user_id={ads.owner_user._id} config={props.config} user={user} isShow={isShowOpenContacts} setShow={setShowOpenContacts}/>
            <NeedProDialog config={props.config} user={user} isShow={isShowNeedPro} setShow={setShowNeedPro}/>
        </div>
    )
}

export default PickedAds;


const NotAuthedUser = ({  }) => {
    return(
        <div>
            <div style={{margin : '16px 0'}} className="ads_my_answer">Отклики могут оставлять только авторизованные эксперты сервиса</div>
            <button onClick={() => window.location.href = '/auth'} style={{position : 'relative', marginBottom : '8px'}} className="fill_button hover">авторизоваться 👋</button>
        </div>
    )
}