import {DebounceInput} from 'react-debounce-input';

function ProfileInputRaw(props) {
   
    return (
        <div className="relative input_raw_container">
            <div className="input_raw_container_p">
                <p style={props.titleStyle ? props.titleStyle : {}} className="profile_input_title">{props.title}</p>
                <div className="flex">
                    {props.debounceTimeout != undefined ? <DebounceInput inputRef={props.innerRef ? props.innerRef : undefined} onKeyDown={props.onKeyDown ? props.onKeyDown : () => {}} debounceTimeout={props.debounceTimeout != undefined ? props.debounceTimeout : 0} className="profile_input" placeholder={props.placeholder} type="text" value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
                    : <input ref={props.innerRef ? props.innerRef : undefined} onKeyDown={props.onKeyDown ? props.onKeyDown : () => {}} className="profile_input" placeholder={props.placeholder} type={props.type == undefined ? "text" : props.type} value={props.value} onChange={(e) => props.setValue(e.target.value)}/>}
                    {props.inputDesc && <p className="profile_input_desc">{props.inputDesc}</p>}
                </div>
            </div>
            <div className="fill_navbar_line"/>
        </div>
    );
}

export default ProfileInputRaw;
