import { useState, useEffect } from 'react';
import { getIsDesktop, Pages } from '../../../utils/Utils';
import { useHistory, Link } from "react-router-dom";
import add from '../../../img/blogs/add.svg'
import { getCookie } from '../../../utils/CookieHelper';
import question from '../../../img/blogs/question.svg';
import { BlogsApi } from '../../../api/BlogsApi';
import BlogItem from '../../blog/custom_views/BlogItem';
import empty from '../../../img/blogs/empty.svg'
import ProfileBlogsWhyTooltip from '../toolbars/ProfileBlogsWhyTooltip';

function ProfileBlogs(props) {
    const [posts, setPosts] = useState([])
    const [isShowTooltip, setShowTooltip] = useState(false)

    const user = props.user

    const isDesktop = getIsDesktop()
    const history = useHistory()
    useEffect(() => {
        if (props.user != undefined && props.user._id != undefined){
            getLastPosts()
        }
    }, [props.user])
    const getLastPosts = () => {
        BlogsApi.getPostsByUserID(100, 0, user._id, (responseData) => {
            setPosts(responseData.results)
        }, () => { })
    }
    const removePost = (index) => {
        var myPosts = [...posts]
        myPosts.splice(index, 1)
        setPosts(myPosts)
    }
    if (posts.length == 0){
        if (user.vk_caller_id != getCookie("id")){
            return null
        }
        return(
            <div className="profile_blogs_container">
                <div style={isDesktop ? {paddingLeft : '16px'} : {}} className="relative">
                    <div className="best_exp_recommended">Блог эксперта</div>
                    <div className="best_experts_container_empty">
                        <img className="best_exp_recommended_image" src={empty}/>
                        <div className="best_exp_recommended_title">У вас пока нет постов</div>
                        <Link to={Pages.create_blog_post}><button className="best_exp_recommended_btn hover">Создать пост</button></Link>
                        <div className="relative">
                            <div onClick={() => setShowTooltip(!isShowTooltip)} className="best_exp_recommended_btn_why hover">
                                Зачем это?
                            </div>
                            {isShowTooltip && <ProfileBlogsWhyTooltip isShow={isShowTooltip} setShow={setShowTooltip}/>}
                        </div>
                      
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="profile_blogs_container">
            <div className="profile_blogs_flex">
                <div className="best_exp_recommended">Блог эксперта</div>
                {props.user.vk_caller_id == getCookie("id") && <div className="profile_blogs_add_flex">
                    <div onClick={() => history.push(Pages.create_blog_post)} className="profile_blogs_add hover">
                        <img src={add}/>
                    </div>
                    <div onClick={() =>  window.open(Pages.blogs_faq, '_blank')} className="profile_blogs_add hover">
                        <img src={question}/>
                    </div>
                </div>}
            </div>
            <div style={{paddingBottom : '0px'}} className="blogs_list profile_blogs_list">
                {posts.length > 0 && posts.map((post, index) => (
                    <BlogItem removePost={removePost} isProfilePosts myUser={user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} post={post}/>
                ))}
            </div>
        </div>
    )
}

export default ProfileBlogs;