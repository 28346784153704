import { getMessaging, getToken } from "firebase/messaging"
import { UsersApi } from "../api/UsersApi";
import { setCookie } from "./CookieHelper";

export const getPushToken = (app, user, callback) => {
    
    const messaging = getMessaging(app)
    if (app == undefined){
        return
    }
    getToken(messaging, { vapidKey: 'BCu0TCVsV1FDXLexT4Ufy1YTJjrlp7u5mio7-Yn9231DrAFZGzjg-HauCPsdYeeY2C2r6cyxpiHRbJyFc0V9qIU' }).then((currentToken) => {
        if (currentToken) {
            console.log('Token = ' + currentToken);
            if (user != undefined && user.push_token != currentToken){
                var params = {push_token : currentToken}
                UsersApi.setMyInfo(params, (response) => {
                    console.log('Token sent = ' + currentToken);
                    setCookie("push_token", currentToken)
                })
            }
            
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
        callback(currentToken)
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        callback(undefined)
    })
}