import { useState, useEffect, useCallback, useRef } from 'react';
import CreateAdsHeader from './custom_views/CreateAdsHeader';
import CreateAdsButtons from './custom_views/CreateAdsButtons';
import { useHistory } from "react-router-dom";
import search_icon from '../../../img/experts/search.svg'
import FilterDropdown from '../../../custom_views/FilterDropdown';
function CreateAdsStep2(props) {
    const history = useHistory()
    const inputRef = useRef(null)
    const [isShowSpecs, setShowSpecs] = useState(false)
    const specializations = props.specializations
    useEffect(() => {
        inputRef.current.focus();
    }, [])
    const text = props.pickedSpecs
    // const addSpec = (tag) => {
    //     var specs = [...props.pickedSpecs]
    //     if (!specs.includes(tag)){
    //         specs.push(tag)
    //         props.setPickedSpecs(specs)
    //     }
    // }
    // const removeSpec = (tag, index) => {
    //     var specs = [...props.pickedSpecs]
    //     specs.splice(index, 1)
    //     props.setPickedSpecs(specs)
    // }
    // const addUserQuery= (tag) => {
    //     var specs = [...props.userQueries]
    //     if (!specs.includes(tag)){
    //         specs.push(tag)
    //         props.setUserQueries(specs)
    //     }
    // }
    // const removeUserQuery = (tag, index) => {
    //     var specs = [...props.userQueries]
    //     specs.splice(index, 1)
    //     props.setUserQueries(specs)
    // }
    var filteredServerSpecs = specializations.filter( tag => text.length > 0 ? (tag.toLowerCase().includes(text.toLowerCase()) && tag.toLowerCase() != text.toLowerCase()) : isShowSpecs)
    // if (enteredSpec.length > 0 && filteredServerSpecs.length == 0){
    //     filteredServerSpecs.push("Другое")
    // }
    const onFilterPicked = (tag) => {
        filteredServerSpecs = []
        setShowSpecs(false)
        //setEnteredSpec(tag)
        props.setPickedSpecs(tag)
        //addSpec(tag)
    }
    const onNext = () => {
        history.push("/create_ads/step_3")
    }

    const handleOnEnter = (e) => {
        // if (e.key === 'Enter') {
        //     addUserQuery(e.target.value)
        //     setTimeout(function() {
        //         filteredServerSpecs = []
        //         setShowSpecs(false)
        //         setEnteredSpec("")
        //     }, 300)
        // }
    }
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                <div className="create_ads_step_title">Эксперта какой области вы ищете?</div>
                    <div onClick={() => setShowSpecs(!isShowSpecs)} className="relative">
                        <input onKeyDown={handleOnEnter}  style={{paddingLeft : '36px'}} ref={inputRef} className="create_ads_input" placeholder="Поиск" type="text" value={text} onChange={(e) => props.setPickedSpecs(e.target.value)}/>
                        <img className="create_ads_search_icon" src={search_icon}/>
                    </div>
                    {(filteredServerSpecs.length > 0 || (isShowSpecs && filteredServerSpecs.length > 0)) && <FilterDropdown onCloseFilters={() => setShowSpecs(false)} style={{margin : '0', maxWidth : '568px', marginTop : '-24px'}} filters={filteredServerSpecs} onFilterPicked={onFilterPicked}/>}
                    {/* <div className="profile_tags_contaner">
                        {props.userQueries.length > 0 && props.userQueries.map((tag, index) => (
                            <div className="hover" style={tag == "" || tag == " " ? {display : 'none'} : {}} key={index}>
                                <p onClick={() => removeUserQuery(tag, index)} className="profile_tag">{tag}</p>
                            </div>
                        ))}
                        {props.pickedSpecs.length > 0 && props.pickedSpecs.map((tag, index) => (
                            <div className="hover" style={tag == "" || tag == " " ? {display : 'none'} : {}} key={index}>
                                <p onClick={() => removeSpec(tag, index)} className="profile_tag">{tag}</p>
                            </div>
                        ))}
                    </div> */}
                </div>
                <CreateAdsButtons isEdit={props.isEdit} isDisabled={props.pickedSpecs.length == 0} onNext={onNext}/>
            </div>
        </div>
    );
}

export default CreateAdsStep2;
