import { useState} from 'react';
import { getUserAvatar, getUserBadge, Pages, getIsDesktop } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import profile_more from '../../../img/profile/profile_more.svg';
import ProIcon from '../../ads_desk/AdsBoard/custom_views/ProIcon';
import { getCookie } from '../../../utils/CookieHelper';
import ProfileTooltip from '../../profile/tooltips/ProfileTooltip';


function LKHeader(props) {
    
    const user = props.user
    const [isShowMoreTooltip, setShowMoreTooltip] = useState(false)
    return (
        <div className="lk_header relative">
            <Link to={Pages.users + "/id" + getCookie("_id")}>
                <div className="flex hover">
                    <div className='relative'>
                        <img alt="" className="lk_icon" src={getUserAvatar(user)}/>
                        {(user.is_repetitor || user.verified) && <img alt="" className="lk_badge"  src={getUserBadge(user)}/>}
                    </div>
                    <div>
                        <div className="flex">
                            <p className="lk_name">{user.name} {user.surname} </p>
                            <div className="lk_pro_icon_name"><ProIcon isShow={user.is_pro}/></div>
                        </div>
                        <p className="lk_go_profile">просмотреть профиль</p>
                    </div>
                </div>
            </Link>
           
            <img alt="" style={{margin : 0}} onClick={() => setShowMoreTooltip(true)} className="mobile_profile_icon hover" src={profile_more}/>

            <CSSTransition in={isShowMoreTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                <ProfileTooltip setShowUploadYoutube={props.setShowUploadYoutube} isMyProfile showToast={props.setToastText} style={getIsDesktop() ? {left : 'calc(100vw/2 - 60px)', right : 'auto'} : {}} user={user} setShowMoreTooltip={setShowMoreTooltip}/>
            </CSSTransition>
        </div>)
}

export default LKHeader;