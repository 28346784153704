/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import back_fill from '../../../img/back_fill.svg'
import phone_call_icon from '../../../img/chat/phone_call_icon.svg'
import close_chat from '../../../img/chat/close_chat.svg'
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from 'react';
import { getIsDesktop, debounce, getUserAvatar } from '../../../utils/Utils';
import arrow_down from '../../../img/chat/arrow_down.svg'
import help_circle from '../../../img/profile/help_circle.svg'
import Typing from './Typing';
import { getCookie } from '../../../utils/CookieHelper';
import { DialogsApi } from '../../../api/DialogsApi';
import { getChatDateTitle } from './ChatUtils';
import { UsersApi } from '../../../api/UsersApi';

function ChatNavbar(props) {
    const [isTyping, setTyping] = useState(false)
    const [isLoaded, setLoaded] = useState(false)
    
    
    const checkRepetitorInfo = props.checkRepetitorInfo
    const [isPaidChat, setPaidChat] = useState(checkRepetitorInfo.dialogModel != undefined ? checkRepetitorInfo.dialogModel.paid_chat : false)
    
    const history = useHistory()
    const user = props.currentChat.user
    const isGroupCall = props.isGroupCall
    const fromCall = props.fromCall
    const isDesktop = getIsDesktop()
    const setServicesValueDebounced = useRef(debounce(() => {
        setTyping(false)
    }, 3000))
    const onBack = () => {
        if (props.setShowCallChat){
            props.setShowCallChat(false)
            return
        }
        if (props.isDesktopCallChat){
            props.setShowCallChat(false)
        } else {
            props.setCurrentChat(undefined)
            history.goBack()
        }
    }
    // useEffect(() => {
    //     document.addEventListener('backbutton', onBack, false)
    // }, [])
    useEffect(() => {
        setTimeout(function() {
            setLoaded(true)
        }, 1000)
        if (props.socketMessage != undefined && props.socketMessage.event === "typingEvent" && isLoaded){
            const id = props.currentChat.from_id == getCookie("id") ? props.currentChat.to_id : props.currentChat.from_id
            if (id == props.socketMessage.from_id){
                if (!isTyping){
                    setTyping(true)
                }
                setServicesValueDebounced.current()
            }
        }
    }, [props.socketMessage])
    useEffect(() => {
        if (props.isLoading && isTyping){
            setTyping(false)
        }
    }, [props.isLoading])
    useEffect(() => {
       if (user != undefined && user.vk_caller_id != undefined){
            checkCanReview(user.vk_caller_id)
       }
    }, [user])
    useEffect(() => {
       if (user != undefined && user.vk_caller_id != undefined){
            checkCanReview(user.vk_caller_id)
       }
    }, [])
    const checkCanReview = (id) => {
        UsersApi.checkWriteReviewAvailable(id, (responseData) => {
            if (responseData.writeReviewAvailable && responseData.response > 0){
                props.setCanWriteReview(true)
            }
        })
    }
    useEffect(() => {
        if (props.checkRepetitorInfo != undefined && props.checkRepetitorInfo.dialogModel != undefined && props.checkRepetitorInfo != {}){
            setPaidChat(props.checkRepetitorInfo.dialogModel.paid_chat)
        }
    }, [props.checkRepetitorInfo])

    const isShowTooltipArrow = props.callToUser == undefined && !props.isDesktopCallChat
    const openProfile = () => {
        if (isShowTooltipArrow){
            props.setShowToolbarTooltip(!props.isShowToolbarTooltip)
        }
    }
    const call = () => {
        if (props.isIBlocked){
            props.showToast("Звонок невозможен. Пользователь вас заблокировал.")
        } else {
            props.setShowCallBottomSheet(true)
        }
    }
    const onPaidChatClicked = (event) => {
        if (event){
            var value = event.target.checked
            
            const id = props.currentChat.from_id == getCookie("id") ? props.currentChat.to_id : props.currentChat.from_id
            DialogsApi.setPaidDialog(id, value, (response) => {
                setPaidChat(value)
                props.checkRepetitor()
            }, (errorResponse) => {
                props.showToast(errorResponse.desc)
            })
        }
    }
    return (
        <div style={{width : 'auto'}}>
            <div className="search_chat_navbar">
                <div className="flex">
                    {isGroupCall && <div onClick={onBack} className="back_icon_container hover chat_back_icon">
                        <img alt="" className="back_icon_img" src={close_chat}/>
                    </div>}
                    {(!isDesktop && !isGroupCall) && <div onClick={onBack} className="back_icon_container hover chat_back_icon">
                        <img alt="" className="back_icon_img" src={props.isDesktopCallChat ? close_chat : back_fill}/>
                    </div>}
                    {!isGroupCall && <div onClick={openProfile} className="flex hover">
                        <div className="chat_avatar_img_cont">
                            <img alt="" className="chat_avatar_img" src={getUserAvatar(user)}/>
                            {user.online && <div className="round_msg_profile_online"></div>}
                        </div>
                        <div className="chat_desktop_margin">
                            <div className="flex">
                                <p className="chat_title_navbar">{user.name ? user.name : "Имя неизвестно"}</p>
                                {isShowTooltipArrow && <img alt="" style={props.isShowToolbarTooltip ? {rotate : '180deg'} : {}} className="profile_tooltip_arrow" src={arrow_down}/>}
                            </div>
                            {isTyping ? <Typing /> : 
                            <p style={user.online ? {color : "#AC92ED"} : {}} className="msgs_profile_desc">{user.online ? "online" : user.last_visit_date != null ? "был в сети " + getChatDateTitle(user.last_visit_date) : ""}</p>}
                            
                        </div>
                        
                        
                    </div>}
                </div>
                
                <div className="flex">
                    {/* <div style={{marginRight : 0}} className="search_chat_cont hover">
                        <img className="chat_rigth_icon" src={video_call_icon}/>
                    </div> */}
                    {(props.callToUser == undefined && !props.isGroupCall) && <div onClick={call} className="call_chat_cont hover">
                        <img alt="" className="chat_rigth_icon" src={phone_call_icon}/>
                    </div>}
                </div>
                
            </div>
            <div className="chat_navbar_l"/>   
            {(props.isIRepetitor && !props.isGroupCall) && <div style={(fromCall && isDesktop) ? {width : '360px'} : {}} className="paid_chat_flex">
                <div className="flex">
                    <label className="switch">
                        <input type="checkbox" onChange={onPaidChatClicked} checked={isPaidChat}/>
                        <div className="slider round"></div>
                    </label>
                    <p className="paid_chat_title">{isPaidChat ? "Платный чат включен" : "Платный чат выключен"}</p>
                </div>
                <img alt="" onClick={() => props.setShowPaidChatDialog(true)} className="help_circle_profile hover" src={help_circle}/>
            </div>}      
        </div>
    );
}

export default ChatNavbar;
