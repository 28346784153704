
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { getIsDesktop } from '../../../utils/Utils';
import other_back from '../../../img/other/other_back.svg'
import { getDesktopStyle } from '../../../utils/Styles';
import TgNotificationsDialog from './dialogs/TgNotificationsDialog';
import tg_icon from '../../../img/auth/tg_icon.svg';

function TgAuthInformation(props) {
    const [isShowTgDialog, setTgShowDialog] = useState(false)
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const user = props.user
    const isTgNotficitaionsAdded = user.telegram_username
    var desktopStyle = getDesktopStyle()
    desktopStyle.zIndex = 3
    if (props.fromProfileFill){
        desktopStyle.top = 0
        desktopStyle.left = 0
        desktopStyle.maxWidth = '100vw'
        desktopStyle.minWidth = '100vw'
    }
    const openTG = () => {
        if (!isTgNotficitaionsAdded){
            window.open('https://t.me/notification_sozvon_bot', '_blank')
            setTgShowDialog(true)
        }
    }
    const onFinish = () => {
        if (props.closeTgAuth){
            props.closeTgAuth()
        } else {
            history.goBack()
        }
    }
    return (
        <div style={isDesktop ? desktopStyle : {zIndex : '2', background : 'white'}} className="selected_settings">
            <div style={props.fromProfileFill ? {padding : isDesktop ? '24px 16px 24px 16px' : '0 0 24px 0', borderRadius : isDesktop ? "16px" : 0} : {}} className="desktop_referals other_tg_auth_bg">
                {isDesktop && <img alt="" onClick={onFinish} className="other_back_desktop hover" src={other_back}/>}

                <div className="arrow_left_theme">
                    <div style={{width : '100%'}} className="flex">
                        {!isDesktop && 
                        <div className="other_tg_auth_back" onClick={onFinish}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711L8.414 13H22C22.5128 13 22.9355 13.386 22.9933 13.8834L23 14C23 14.5523 22.5523 15 22 15H8.414L13.7071 20.2929C14.0676 20.6534 14.0953 21.2206 13.7903 21.6129L13.7071 21.7071C13.3166 22.0976 12.6834 22.0976 12.2929 21.7071L5.29289 14.7071L5.21969 14.6254C5.21737 14.6226 5.21507 14.6197 5.21279 14.6167C5.20728 14.6098 5.20175 14.6026 5.19633 14.5953C5.17849 14.5711 5.16172 14.5463 5.14607 14.5207C5.13861 14.5086 5.1315 14.4964 5.12467 14.4841C5.11379 14.4643 5.10338 14.4439 5.09365 14.4231C5.08568 14.4061 5.0782 14.3888 5.07123 14.3713C5.06332 14.3516 5.05612 14.3318 5.04955 14.3117C5.04473 14.2969 5.0401 14.2815 5.03585 14.266C5.02983 14.2444 5.02464 14.2227 5.02016 14.2008C5.0167 14.1834 5.01363 14.1659 5.01102 14.1485C5.00376 14.1004 5 14.0506 5 14L5.00397 14.0893C5.00189 14.066 5.00063 14.0427 5.00019 14.0194L5 14C5 13.9936 5.00006 13.9872 5.00018 13.9808C5.0006 13.958 5.00187 13.9343 5.00397 13.9107C5.00577 13.8902 5.00811 13.8705 5.01102 13.851C5.01363 13.8341 5.0167 13.8166 5.02024 13.7993C5.02464 13.7773 5.02983 13.7556 5.03572 13.7342C5.0401 13.7185 5.04473 13.7031 5.04974 13.6879C5.05612 13.6682 5.06332 13.6484 5.0711 13.6289C5.0782 13.6112 5.08568 13.5939 5.09367 13.5768C5.10338 13.5561 5.11379 13.5357 5.12488 13.5157C5.1315 13.5036 5.13861 13.4914 5.146 13.4793C5.16172 13.4537 5.17849 13.4289 5.19631 13.4048C5.22531 13.3657 5.2575 13.3283 5.29289 13.2929L5.21279 13.3833C5.23767 13.3515 5.26443 13.3214 5.29289 13.2929L12.2929 6.29289Z" fill="black"/>
                            </svg>
                        </div>}
                        <p style={{lineHeight: '120%'}} className={isDesktop ? "theme_title" : 'other_tg_auth_title'}>Подключение Telegram-бота</p>
                    </div>
                </div>
                <div className="other_tg_auth_line"/>
                <img alt="" className="other_tg_auth_icon" src={tg_icon}/>
                <div className="other_tg_auth_desc">
                    Чтобы всегда оставаться на связи и не пропускать сообщения в сервисе Sozvon, подключите Telegram-бота к вашему аккаунту.
                    <br/><br/>
                     1. После нажатия на кнопку <b>Подключить бота</b> вас перекинет в Telegram.<br/>
                     2. Нажмите на кнопку <b>Start</b>. Если её не будет, то введите вручную команду <b>/start</b>.
                </div>
                <div onClick={openTG} className="other_tg_auth_btn hover">Подключить бота</div>
              
            </div>
            <TgNotificationsDialog onFinish={onFinish} getMyInfo={props.getMyInfo} isShow={isShowTgDialog} setShow={setTgShowDialog}/>
            
        </div>
    );
}

export default TgAuthInformation;
