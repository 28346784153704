/* eslint-disable eqeqeq */

import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Pages } from '../../../utils/Utils';

function SubjectsList(props) {
    const [selected, setSelected] = useState(0)
    const [isShowAllSpecs, setShowAllSpecs] = useState(false)
    const [specs, setSpecs] = useState(props.specializations)
   
    const history = useHistory();
  
    const handleOnClick = (title, index) => {
        if (index == 9 && !isShowAllSpecs){
            setShowAllSpecs(true)
        } else {
            history.push(Pages.theme + "?title=" + title)
        }
    }
    useEffect(() => {
        if (props.specializations != undefined && props.specializations.length > 0){
            setSpecs(props.specializations)
        }
    }, [props.specializations])
    const changeSelected = (index) => {
        if (index == 0){
            setSpecs(props.specializations)
        } else if (index == 1){
            setSpecs(props.consultants)
        } else {
            history.push(Pages.theme + "?title=Знаменитости")
        }
        setSelected(index)
    }
    return (
        <div className="subjects_container">
            <div className="flex">
                <SelectedItem title="Обучение" index={0} selected={selected} setSelected={changeSelected}/>
                <SelectedItem title="Консультации" index={1} selected={selected} setSelected={changeSelected}/>
                {/* <SelectedItem title="звезды" index={2} selected={selected} setSelected={changeSelected}/> */}
            </div>
            <div style={isShowAllSpecs ? {maxHeight : 'none'} : {}} className="subjects_list_container">
                {specs.length > 0 && specs.map((tag, index) => (
                    <div onClick={() => handleOnClick(tag, index)} className="hover" key={index}>
                        <p className="subject_list_item">{(index == 9 && !isShowAllSpecs) ? "Все " + specs.length + " предмета" : tag}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SubjectsList;

const SelectedItem = ({ title, index, selected, setSelected }) => {
    return (
        <div onClick={() => setSelected(index)} style={index == selected ? {background : 'var(--black)', color : 'white'} : {}} className="subjects_nav_title hover">
            {title}
        </div>
    )
}