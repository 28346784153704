import { Pages } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import referals from '../../../img/lk/referals.svg'
import bookmarks from '../../../img/lk/bookmarks.svg'
import answers from '../../../img/lk/answers.svg'
import desks from '../../../img/lk/desks.svg'
import promotion from '../../../img/lk/promotion.svg'
function LKSections(props) {

    const getItem = (icon, title, link) => {
        return(
            <Link to={link}>
                <div className="lk_section_item hover">
                    <img src={icon}/>
                    <div>{title}</div>
                </div>
            </Link>
        )
    }
    return (
        <div className="lk_other">
            <div className="lk_other_title">Разделы</div>
            {getItem(desks, "Мои доски", Pages.work_boards)}
            {getItem(bookmarks, "Избранное", Pages.bookmarks)}
            {getItem(answers, "Ответы", Pages.answers)}
            {props.user.is_repetitor && getItem(promotion, "Продвижение профиля", Pages.profile_ads)}
            {getItem(referals, "Реферальная система", Pages.referals)}
        </div>)
}

export default LKSections;