/* eslint-disable eqeqeq */
import { Pages, getIsDesktop } from "../../../utils/Utils";
import { copyText } from "../../blog/utils/BlogUtils";
import { BoardsApi } from "../../../api/BoardsApi";
import { useEffect } from 'react';
import { downloadFileFromUrl } from "../../chat/custom_views/ChatUtils";

function BoardTooltip(props) {
    const board = props.board
    const boardUrl = Pages.call_board + board._id
    
    useEffect(() => {
        const rect = window.document.getElementsByClassName("boards_more_tooltip")[0].getBoundingClientRect()
        const innerHeight = window.innerHeight
        console.log("BoardTooltip rect = ", rect)
        console.log(window.innerHeight)
        if (rect.y + rect.height + 60  > innerHeight){
            window.document.getElementsByClassName("boards_more_tooltip")[0].setAttribute("style", "top : auto; bottom : 0")
        }
    }, [])
    const copyUrl = () => {
        copyText(boardUrl)
        if (props.showToast){
            props.showToast("Ссылка скопирована")
        }
    }
    const duplicateBoard = () => {
        BoardsApi.duplicateBoard(board._id, (response) => {
            props.getMyBoards()
        }, () => {

        })
        
    }
    // const downloadFile = async (url) => {
    //     let response = await fetch(url);

    //     if (response.ok) { // если HTTP-статус в диапазоне 200-299
    //         let json = await response.text();
    //         console.log("download json = ", json)
    //     } 
    // }
    
      
    const removeBoard = () => {
        BoardsApi.deleteBoard(board._id, (response) => {
            props.getMyBoards()
        }, () => {

        })
        
    }
    
    return (
        <div style={props.style ? props.style : {}} className="boards_more_tooltip">
            <a rel="noreferrer" href={boardUrl} target="_blank"><p className="more_tooltip_profile_text hover">Редактировать доску</p></a>
            {/* <p className="more_tooltip_profile_text hover">Отправить доску клиенту</p> */}
            <p onClick={duplicateBoard} className="more_tooltip_profile_text hover">Дублировать доску</p>
            <a href={boardUrl}><p className="more_tooltip_profile_text hover">Переименовать доску</p></a>
            {getIsDesktop() &&  <p onClick={() => downloadFileFromUrl(board.board_url, "desk.szvn")} className="more_tooltip_profile_text hover">Скачать файл доски</p>}
            <p onClick={copyUrl} className="more_tooltip_profile_text hover">Скопировать ссылку на доску</p>
            {(board.board_id2 != undefined && board.board_id2 != "") && <p onClick={() => props.setWorkDialogData(board)} className="more_tooltip_profile_text hover">Отправить доску ученику</p>}
            <p onClick={removeBoard} style={{color : '#FF5353'}} className="more_tooltip_profile_text hover">Удалить</p>
        </div>
    );
}

export default BoardTooltip;
