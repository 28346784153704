/* eslint-disable eqeqeq */
import { useState } from 'react';
import { declension, Pages, getTimePaid, getIsDesktop } from '../../../utils/Utils';
import help_grey from '../../../img/profile/help_grey.svg'
import ProfileInfoMsgPriceDialog from '../dialogs/ProfileInfoMsgPriceDialog';
import ProfileInfoFreeTimeDialog from '../dialogs/ProfileInfoFreeTimeDialog';
import { linkify } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';
import { isUserAuthed } from '../../../utils/CookieHelper';
import ProfileGroupCalls from './ProfileGroupCalls';
function ProfileInfo(props) {
    const [isShowMsgPrice, setShowMsgPrice] = useState(false)
    const [isShowFreeTime, setShowFreeTime] = useState(false)
    const user = props.user
    const bio = user.bio == undefined ? "" : user.bio.includes("\n") ? ("<div>" +  user.bio.replaceAll("\n", "<br/>") + "</div>") : user.bio
    const isRepetitor = user.is_repetitor
    const config = props.config
    const isMyProfile = props.isMyProfile
    const priceWithComission = Math.round(user.rub_in_hour * (1 - config.comission))
    const myUser = props.myUser
    const isScrolledToTop = props.isScrolledToTop
    const setShowAddReview = props.setShowAddReview
    const setShowAddClientReviewDialog = props.setShowAddClientReviewDialog
    const isDesktop = getIsDesktop()
    const getFreeTimeInfo = () => {
        return(
            <div className="profile_info_raw_help profile_free_time_stroke">
                {("Первые " + user.free_time + " " + declension(user.free_time, "минута", "минуты", "минут") + " бесплатно")}
                <div style={{paddingTop : 0, marginTop : '-4px'}} onClick={() => setShowFreeTime(true)} className="help_circle_profile_cont hover">
                    <img alt="" className="help_circle_profile" src={help_grey}/>
                </div>
            </div>
        )
    }
    const addReviewHTML = () => {
        if (!isDesktop){
            return null
        }
        return(
            <div className="profile_my_review_full">
               
                <p className='profile_my_review_p'>Загрузите отзывы ваших клиентов из других сервисов</p>
                <div onClick={() => setShowAddReview(true)} className="hover">Загрузить</div>
            </div>
            
        )
    }
    const addReviewFromClientHTML = () => {
        if (!isDesktop){
            return null
        }
        return(
            <div className="profile_my_review_full">
                <p className='profile_my_review_p'>Пожалуйста, оставьте честный отзыв об эксперте. Это поможет потенциальным клиентом в выборе подходящего специалиста.</p>
                <div onClick={() => setShowAddClientReviewDialog(user.vk_caller_id)} className="hover">Оставить отзыв</div>
            </div>
        )
    }
    return (
        <div style={isScrolledToTop ? {marginTop : '108px'} : {}} className="profile_width_full">
            {isMyProfile && addReviewHTML()}
            {!isMyProfile && props.canWriteReview && addReviewFromClientHTML()}
            {/* {addReviewFromClientHTML()} */}
            <div>
                <ProfileGroupCalls isProfile planners={user.sozvon_by_links}/>
            </div>
            {isRepetitor && 
                <div>
                    {/* {(isMyProfile && isDesktop) && <ProfileInfoBalance user={user}/>} */}
                    <div  className="profile_achievements profile_achievements_container profile_width_full">
                        <p className="profile_info_title">О себе</p>
                        <p className="profile_info_text" dangerouslySetInnerHTML={{__html: linkify(bio)}}></p>
                        {/* <div className="profile_info_text">{linkify(bio)}</div> */}
                        <p className="profile_info_title">Стоимость звонка</p>
                        
                        
                        {isMyProfile ? 
                            <div className="profile_info_text">
                                <div className='flex'>
                                    <div className='profile_hour_price'>{priceWithComission} ₽/час</div>
                                    <span style={{marginLeft : '4px'}} className='profile_price_with_comm'>с учетом комиссии</span>
                                </div>
                                <div style={{opacity : '0.5'}}>{parseInt(priceWithComission / 60)} ₽/минута с учетом комиссии</div>
                                {user.free_time > 0 ? getFreeTimeInfo() : ""}
                            </div> 
                            :
                            user.rub_in_hour == 0 ? 
                            <div className="profile_info_text">Цена договорная</div>
                            :
                            <div className="profile_info_text">
                                <div style={{paddingTop : '4px'}} className='flex'>
                                    <div style={{marginRight : '0'}} className='profile_hour_price'>{user.rub_in_hour > 0 ? user.rub_in_hour + ' ₽/час' : 'Цена договорная'}</div>
                                    {user.rub_in_hour > 0  && <span style={{marginLeft : '12px'}} className='profile_price_with_comm'>{parseInt(user.rub_in_hour / 60)} ₽/минута</span>}
                                </div>
                                {user.free_time > 0 ? getFreeTimeInfo() : ""}
                            </div>
                                
                        }

                        {(!isMyProfile && isRepetitor && isUserAuthed() && user.rub_in_hour > 0) && <div className="profile_up_balance">
                        🤓 Средств на вашем балансе хватит на <b>{getTimePaid(myUser, user.rub_in_hour)}</b> разговора с экспертом. Рекомендуем сначала согласовать время созвона с экспертом в чате.
                            <br/><br/>
                            <div onClick={() => window.open(Pages.pay + "?pay=1", '_blank')} className='profile_up_balance_btn hover'>Пополнить баланс</div>
                            {/* <a rel="noreferrer" href={Pages.pay + "?pay=1"} target='_blank'><b className="hover">{"Пополнить баланс".toLocaleUpperCase()}</b></a> */}
                        </div>}
                        {/* <div className="profile_info_raw_help">
                            <p className="profile_info_title">Стоимость сообщения</p>
                            <div onClick={() => setShowMsgPrice(true)} className="help_circle_profile_cont hover">
                                <img className="help_circle_profile" src={help_circle}/>
                            </div>
                        </div>
                        <p className="profile_info_text">{user.rub_per_message > 0 ? isMyProfile ? parseFloat(user.rub_per_message * (1 - config.comission)).toFixed(1) + "₽" : user.rub_per_message + "₽" : "бесплатно"}</p>
                         */}
                        {(user.city && user.country) && 
                        <div style={{paddingTop : '8px'}}>
                            <p className="profile_info_title">Город</p>
                            <div className="profile_info_text">{user.city}, {user.country}</div>
                        </div>}
                        
                        <p className="profile_info_title">Специализация</p>
                        <div className="profile_tags_contaner">
                            {(user.specializations && user.specializations.length > 0) && user.specializations.map((tag, index) => (
                                <div style={(tag != "" && tag != " " && tag != "  ") ? {} : {display : 'none'}} key={index}>
                                    <p className="profile_tag">{tag}</p>
                                </div>
                            ))}
                        </div>

                        <p style={{paddingTop : '24px'}} className="profile_info_title">Основые навыки</p>
                        <div className="profile_tags_contaner">
                            {(user.skills && user.skills.length > 0) && user.skills.map((tag, index) => (
                                <div style={(tag != "" && tag != " " && tag != "  ") ? {} : {display : 'none'}} key={index}>
                                    <p className="profile_tag">{tag}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
                <ProfileInfoMsgPriceDialog user={user} isShow={isShowMsgPrice} setShow={setShowMsgPrice}/>
                <ProfileInfoFreeTimeDialog user={user} isShow={isShowFreeTime} setShow={setShowFreeTime}/>

        </div>
    );
}

export default ProfileInfo;

const ProfileInfoBalance = ({ user }) => {
    return (
        <div onClick={() => window.location.href = "/pay"} className="profile_achievements_container not_repetitor_achievments hover">
            <p className="balance_not_rep">{user.count_rub == undefined ? ' ' : parseInt(user.count_rub) + "₽"}</p>
            <p className="descr_not_rep">Ваш баланс</p>
        </div>)
}
