import back_arrow from '../../../img/experts/back_arrow.svg'
import { useState, useEffect, useCallback } from 'react';
import Loader from '../../../custom_views/Loader';
import { getUrlParameter, getIsDesktop } from '../../../utils/Utils';
import ExpertItem from '../list_items/ExpertItem';
import EmptyView from '../../../custom_views/EmptyView';
import { useHistory } from "react-router-dom";
import { ConfigApi } from '../../../api/ConfigApi';
import not_found_experts from '../../../img/experts/not_found_experts.svg'

function PickedTheme(props) {
    const [experts, setExperts] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isSelebreties, setSelebreties] = useState(false)
    const history = useHistory()

    const loadByTheme = (title) => {
        setLoading(true)
        if (title == "Знаменитости"){
            setSelebreties(true)
            ConfigApi.searchExpertsWithStar((responseData) => {
                setResponse(responseData)
            })
        } else {
            ConfigApi.searchExpertsBySpecialization(title, (responseData) => {
                setResponse(responseData)
            })
        }
    }
    const setResponse = (responseData) => {
        setExperts(responseData.results)
        setLoading(false)
    }
    var title = ""
    if (window.location.href.includes("/theme?title=")){
        title = getUrlParameter("title")
    }
    useEffect(() => {
        if (title != ""){
            loadByTheme(title)
        }
    }, [setExperts])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const onBack = useCallback(() => {
        history.goBack()
    }, [history]);
    const isDesktop = getIsDesktop()

    return (
         <div className={isDesktop ? "search_experts_desktop" : "selected_theme"}>
            {/* <div className="arrow_left_theme">
                <div className="flex">
                    <img onClick={() => onBack()}  className="back_arrow_theme hover" src={back_arrow}/>
                    <p className="theme_title">{title}</p>
                </div>
            </div> */}
            <div className="flex">
                <div className="arrow_left_theme">
                    <div className="flex">
                        <img onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>
                        <p className="theme_title"></p>
                    </div>
                </div>
                <p className="expeerts_search_title">{title}</p>
            </div>
            {isLoading ? <Loader /> : 
            <div style={experts.length == 0 ? {background : 'var(--light_text)'} : {}} className="exprets_in_theme">
                {experts.length > 0 && experts.map((expert, index) => (
                    <ExpertItem myUser={props.user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} user={expert}/>
                ))}
                {experts.length == 0 && <div className="not_found_experts_cont"><img className="not_found_experts" src={not_found_experts}/></div>}
            </div>}
        </div>
    )
}

export default PickedTheme;
