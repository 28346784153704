/* eslint-disable eqeqeq */
import '../../styles/work_boards.css'
import { useState, useEffect } from 'react';
import { getDesktopStyle } from '../../utils/Styles';
import { getIsDesktop, Pages } from '../../utils/Utils';
import other_back from '../../img/other/other_back.svg'
import back_arrow from '../../img/experts/back_arrow.svg'
import add from '../../img/blogs/add.svg'
import { useHistory } from "react-router-dom";
import BoardsSearch from './custom_views/BoardsSearch';
import { BoardsApi } from '../../api/BoardsApi';
import empty from '../../img/blogs/empty.svg'
import BoardsItem from './custom_views/BoardsItem';
import Loader from '../../custom_views/Loader';
import globe from '../../img/work_boards/globe.svg'
import { getCookie } from '../../utils/CookieHelper';
import { CSSTransition } from 'react-transition-group';
import Toast from '../profile/custom_views/Toast';
import WorkBoardsSendDialog from './dialogs/WorkBoardsSendDialog';

function WorkBoards(props) {
    const [searchText, setSearchText] = useState("")
    const [boards, setBoards] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isPublic, setPublic] = useState(false)
    const [workDialogData, setWorkDialogData] = useState({})
    const [toastText, setToastText] = useState("")
    
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const user = props.user

    useEffect(() => {
        getMyBoards()
    }, [])
    useEffect(() => {
        setLoading(true)
        if (isPublic){
            BoardsApi.searchPublicBoards(searchText, (responseData) => {
                setLoading(false)
                setBoards(responseData.items)
            }, () => {
                setLoading(false)
            })
        } else {
            getMyBoards()
        }
    }, [searchText, isPublic])
    const getMyBoards = () => {
        BoardsApi.getMyBoards((responseData) => {
            setLoading(false)
            setBoards(responseData.items)
        }, () => {
            setLoading(false)
        })
    }
    const getEmptyBoards = () => {
        return(
            <div className="boards_empty">
                <img alt='' className="blogs_empty_img" src={empty}/>
                Ничего не найдено
            </div>
        )
    }
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.lk
        } else {
            history.goBack()
        }
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const filteredBoards = isPublic ? boards : boards.filter((board) => searchText == "" ? true : (board.name.toLowerCase().includes(searchText.toLowerCase()) || board.theme.toLowerCase().includes(searchText.toLowerCase())))
    const getHelpBtn = () => {
        return(
            <div className="boards_help">
                Помощь в работе с интерактивными досками, мастер-классы по созданию досок
                <a rel="noreferrer" href={Pages.boads_faq} target="_blank"><div className="hover">Смотреть</div></a>
            </div>
        )
    }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header">
                <div className="flex">
                    {isDesktop ? <img alt='' onClick={onBack} className="ads_back_desktop blog_post_back hover" src={other_back}/> 
                    :
                    <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                    <div className="blog_toolbar_title">{isPublic ? "Публичные доски" : "Мои доски"}</div>
                </div>
                <a href={Pages.call_board + getCookie("id") + "_" + Math.floor(100000 + Math.random() * 900000)}><img alt='' className="boards_add_icon" src={add}/></a>
            </div>
        )
    }
    return (
        
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="lk_container_inner">
                <div className="boards_inner">
                    {getToolbar()}
                    <div className="boards_flex">
                        <div className="boards_container">
                            <BoardsSearch text={searchText} setSearchText={setSearchText}/>
                            {isLoading ? 
                                <Loader style={{marginTop : '56px'}}/>
                            :
                                filteredBoards.length > 0 ? <div className="boards_list">
                                    {filteredBoards.length > 0 && filteredBoards.map((board, index) => (
                                        <BoardsItem isPublic={isPublic} setWorkDialogData={setWorkDialogData} getMyBoards={getMyBoards} showToast={showToast} user={user} board={board} index={index} key={index} />
                                    ))}
                                </div> 
                            :
                                getEmptyBoards()
                            } 
                            <div onClick={() => setPublic(!isPublic)} className="boards_open_all">
                                <img alt='' src={globe}/>
                                <div>Перейти в {isPublic ? "мои доски" : "публичные доски"}</div>
                            </div>
                        </div>
                        <div>
                            {isDesktop && <a href={Pages.call_board + getCookie("id") + "_" + Math.floor(100000 + Math.random() * 900000)}>
                                <div className="boards_create_btn hover">
                                    <img alt='' src={add}></img>
                                    <div>Создать доску</div>
                                </div>
                            </a>}
                            {isDesktop && getHelpBtn()}
                        </div>
                    </div>
                </div>
            </div>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
            <WorkBoardsSendDialog workDialogData={workDialogData} isShow={workDialogData._id != undefined} setShow={setWorkDialogData}/>
        </div>
    );
}

export default WorkBoards;

