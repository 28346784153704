import { getIsDesktop } from "../../../utils/Utils";
import close from '../../../img/blogs/close.svg'
function GigachatTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="blogs_gigachat_tooltip_pos">
                <div style={isDesktop ? {left : '95%', bottom : '6px', position : 'absolute'} : {bottom : '6px', position : 'absolute'}} className="blogs_gigachat_rectangle"></div>
                <div className="blogs_gigachat_tooltip">Если вам сложно начать писать пост:
                    <ul>
                        <li>После создания заголовка H1 вам автоматически будет предложен текст поста с этой темой. Вы можете взять его (для этого после появления текста нажмите клавишу tab) и отредактировать на своё усмотрение.</li>
                        <li>Вы можете создать заголовок вида “План статьи о русском языке”. После этого вам будет предложен план, раскрывая который вы напишете собственную статью.</li>
                    </ul>
                </div>
                <img className="hover" src={close}/>
            </div>
        </div>
     );
 }
 
 export default GigachatTooltip;
 