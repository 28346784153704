import { useState, useEffect } from 'react';
import x_white from '../../../img/x_white.svg'
import back_white from '../../../img/back_white.svg'
import { useHistory } from "react-router-dom";
import { getIsDesktop } from '../../../utils/Utils';

function PayoutHeader(props) {
    const history = useHistory()
    const user = props.user
    const isDesktop = getIsDesktop()
    const onClose = () => {
        history.goBack()
        //window.location.href = '/profile'
    }
    if (isDesktop){
        return(
            <div>
                <p className="payouts_desktop_title">{props.pageTitle}</p>
            </div>
        )
    }
    return (
        <div style={{height : '120px', paddingTop : '24px'}} className="pay_header">
                <div className="pay_header">
                    <div className="flex">
                        <div style={{marginLeft : '0'}} onClick={() => props.setCurrentPage(0)} className="review_close_cont hover">
                            <img className="review_close_icon" src={back_white}/>
                        </div>
                        <div onClick={onClose} className="review_close_cont hover">
                            <img className="review_close_icon" src={x_white}/>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default PayoutHeader;
