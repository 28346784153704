/* eslint-disable eqeqeq */

import avatar from '../../../img/avatar.svg'
import { getIsDesktop, getUrlParameter, Pages } from '../../../utils/Utils';
import { useHistory } from "react-router-dom";
import React, {useCallback} from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import { getUserUrl } from '../../../utils/CookieHelper';

function BestExperts(props) {
    const setUser = (item) => {
       // window.history.replaceState("", "", "/users/id" + item._id)
        // const history = useHistory();
        // history.push("/users/id" + item._id)
        props.setSelectedProfile(item)
       
    }
    const isRecommended = props.isRecommended

    const history = useHistory();
    const handleOnClick = useCallback((item) => {
        const usersUrl = getUserUrl(item)
        // Pages.users + '/id' +  item._id
        window.open(usersUrl, '_blank')
        // if (isRecommended){
        //     window.open(usersUrl, '_blank')
        // } else {
        //     props.setSelectedProfile(item)
        //     history.push(usersUrl)
        // }
    }, [history]);
    const isDesktop = getIsDesktop()
    return (
        <div style={(isRecommended && isDesktop) ? {maxWidth : window.innerWidth >= 1280 ? '575px' : 'calc(100vw - 449px)', position : 'relative', marginLeft :  '-8px', background : '#F1F5FD'} : {}} className="best_exp_container">
            {isRecommended ? 
                <div style={window.innerWidth >= 1280 ? {marginLeft : '0'} : {}} className="best_exp_recommended">Рекомендуемые эксперты</div>
            : 
                <p style={isDesktop ? {paddingTop : '0'} : {marginTop : '12px'}} className="experts_title_section experts_online">Эксперты Онлайн</p>
            }
            {/* <div style={props.bestExperts.length == 0 ? {} : {opacity : 1}} className="experts_horizontal_contaner opacity_list_messages no_horizontal_scroll">
                {props.bestExperts.map((item, index) => (
                        <div onClick={() => handleOnClick(item)} to={"/users/id" + item._id} index={index} key={index} className="best_experts_item hover">
                            <img className="best_experts_avatar" src={item.avatar ? item.avatar : avatar}/>
                            <p className="best_expert_title">{item.name}</p>
                            <p className="best_expert_specialization">{item.specializations[0]}</p>
                        </div>
                ))}
            </div> */}
            {/* <div style={props.bestExperts.length == 0 ? {} : {opacity : 1}} className="experts_horizontal_contaner opacity_list_messages no_horizontal_scroll"> */}
               
            {/* </div>      */}
            <div style={props.bestExperts.length == 0 ? {} : {opacity : 1}} className="opacity_list_messages no_horizontal_scroll">
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {props.bestExperts.map((item, index) => (
                        <Card item={item} itemId={item._id} handleOnClick={handleOnClick} key={index} index={index} isRecommended={isRecommended}/>
                    ))}
                </ScrollMenu>
                {(isRecommended && isDesktop) && <div className="profile_best_exp_gradient"></div>}
            </div> 
            
        </div>
    );
}

function Card({ item, itemId, handleOnClick, index, isRecommended }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isDesktop = getIsDesktop()
    const from_search = getUrlParameter("from_search")
    var url = Pages.users + '/id' +  item._id
    if (from_search != null && from_search != undefined && from_search != ""){
        url += "?from_search=1"
    }
    return (
        <a rel="noreferrer" href={url} target="_blank"  index={index} key={index}>
            <div style={index == 0 ? {marginLeft : isDesktop ? (isRecommended ? '1px' : '52px') : '16px'} : {}} className={(isRecommended && isDesktop) ? "best_experts_item best_experts_item_recommend hover" : "best_experts_item hover"}>
                <img alt='' className="best_experts_avatar" src={item.avatar ? item.avatar : avatar}/>
                <p className="best_expert_title">{item.name}</p>
                <p className="best_expert_specialization">{item.specializations[0]}</p>
                <p className="best_expert_specialization best_expert_specialization_price">{item.rub_in_hour > 0 ? item.rub_in_hour + " ₽/час" : "Цена договорная"}</p>
            </div> 
        </a>
    );
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img alt='' style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img alt='' style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_right hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}

export default BestExperts;
