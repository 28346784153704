import '../../styles/other.css'
import Rules from './custom_views/Rules';
import Settings from './custom_views/Settings';
import Referals from './custom_views/Referals';
import { Route, Link } from "react-router-dom";
import { getIsDesktop, Pages } from '../../utils/Utils';
import back_arrow from '../../img/experts/back_arrow.svg'
import { useHistory } from "react-router-dom";
import { getDesktopStyle } from '../../utils/Styles';
import other_back from '../../img/other/other_back.svg'

function Other(props) {
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const openQuestions = () => {
        window.open(Pages.support, '_blank')
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : '2'}} className="selected_settings">
            <div className="desktop_referals">
                {isDesktop && <img alt="" onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}

                <div className="other_container">
                    <div className="other_top_cont">
                        {!isDesktop && <img alt="" onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>}
                        <p style={isDesktop ? {marginTop : '-8px'} : {}} className="theme_title">Другое</p>
                    </div>
                    {/* <p className="main_title">Другое</p> */}
                    <div className="other_item_container other_mt32">
                        <Link style={{color: 'var(--dark_purple)'}} to={Pages.referals}>
                            <p className="other_item_title hover">Реферальная система</p>
                        </Link>
                        
                    </div>
                    <div className="other_item_container">
                        <Link style={{color: 'var(--dark_purple)'}} to={Pages.settings}>
                            <p className="other_item_title hover">Настройки</p>
                        </Link>
                    </div>
                    <div className="other_item_container">
                        <p onClick={openQuestions} className="other_item_title hover">Часто задаваемые вопросы</p>
                        <p onClick={openQuestions} className="other_item_title hover">Техподдержка</p>
                        <Link style={{color: 'var(--dark_purple)'}} to={Pages.rules}>
                            <p className="other_item_title hover">Правила сервиса</p>
                        </Link>
                    </div>

                    <div className="other_item_container">
                        <p onClick={openQuestions} className="other_item_title hover">Написать разработчикам</p>
                        {/* <p className="other_item_title hover">Поделиться приложением</p> */}
                    </div>
                </div>

               
                <Route path={Pages.settings}>
                    <Settings user={props.user} getMyInfo={props.getMyInfo}/>
                </Route>
                <Route path={Pages.rules}>
                    <Rules />
                </Route>
                <Route path={Pages.referals}>
                    <Referals user={props.user} />
                </Route>
                
            </div>
        </div>
    );
}

export default Other;
