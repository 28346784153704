import { useState, useEffect } from 'react';
import back_arrow from '../../../img/experts/back_arrow.svg'
import ref_copy from '../../../img/other/ref_copy.svg'
import other_back from '../../../img/other/other_back.svg'
import { getCookie } from '../../../utils/CookieHelper';
import { getHttpParams, getRequestUrl, getIsDesktop, Pages } from '../../../utils/Utils';
import { CSSTransition } from 'react-transition-group';
import Toast from '../../profile/custom_views/Toast';
import { useHistory } from "react-router-dom";
import { getDesktopStyle } from '../../../utils/Styles';
import NotAuthed from '../../profile/custom_views/NotAuthed';
import { UsersApi } from '../../../api/UsersApi';

function Referals(props) {
    const [referrals, setReferrals] = useState(0)
    const [referralsPrice, setReferralsPrice] = useState(0)
    const [recommendedUser, setRecommendedUser] = useState(undefined)
    const [toastText, setToastText] = useState("")
    const history = useHistory()
    const user = props.user
    const isRepetitor = user.is_repetitor
    const getMyReferrals = () => {
        fetch(getRequestUrl() + "getMyReferrals", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response > 0){
                setReferrals(responseData.results)
            }
        })
    }
    useEffect(() => {
        getMyReferrals()
    }, [setReferrals])
    useEffect(() => {
        getUser()
    }, [user])
    const showRules = () => {
        window.open('https://sozvon.pro/ref', '_blank');

    }
    const share = () => {
        var textField = document.createElement('textarea')
        textField.innerText = ("Привет. Присоединяйся к сервису sozvon.pro для онлайн-уроков, экспертных консультаций и общения со знаменитостями с функцией автоматической оплаты звонков и чатов. Воспользуйся промокодом на 100 руб. Просто введи его в приложении после регистрации. Промокод: " + user.vk_caller_id + " https://sozvon.pro/")
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        showToast("Ссылка на профиль скопирована")
    }
    const copy = () => {
        var textField = document.createElement('textarea')
        textField.innerText = (Pages.promocode + user.vk_caller_id)
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        showToast("Промокод скопирован")
    }

    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const getUser = () => {
        if (user.promo_vk_caller_id){
            UsersApi.getUserByVkCallerID(user.promo_vk_caller_id, (mUser) => {
                setRecommendedUser(mUser)
            }, () => {})
        }
    }
    const isDesktop = getIsDesktop()
    const token = getCookie("token")
    const isTokenEmpty = token == null || token == undefined || token == ""
    
    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="selected_settings">
            
            <div className="desktop_referals">
                {isDesktop && <img onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}

                <div className="arrow_left_theme">
                    <div style={{width : '100%'}} className="flex">
                        {!isDesktop && <img onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>}
                        <p className="theme_title">Реферальная система</p>
                    </div>
                </div>
                {isTokenEmpty ? <NotAuthed btnStyle={isDesktop ? {left: 'calc((100vw)/2 - 210px)'} : {}}/> : <div style={isDesktop ? {maxWidth: '360px', margin : '0 auto', width : '360px'} : {}} className="other_rules_container">
                    <div className="other_ref_container">
                        <div className="flex">
                            <div className="referals_money">
                                <p className="referals_money_title">Вы заработали</p>
                                <p className="referals_money_price">{referralsPrice}₽</p>
                            </div>
                            <div className="referals_money">
                                <p className="referals_money_title">Рефералов</p>
                                <p className="referals_money_price">{user.count_referals}</p>
                            </div>
                        </div>
                    </div>

                    <div className="other_ref_container">
                        {isRepetitor ? "Рассказывайте о сервисе sozvon.pro, делитесь реферальной ссылкой и дарите вашим рефералам 300₽" : "Рассказывайте о сервисе sozvon.pro, делитесь реферальной ссылкой и дарите вашим клиентам 300₽"}
                        <p onClick={copy} className="ref_promo_url hover">{Pages.promocode + user.vk_caller_id}</p>
                        <div className="ref_copy_btns">
                            <img onClick={copy} className="ref_copy_icon hover" src={ref_copy}/>
                            {/* <img onClick={share} className="ref_copy_icon hover" src={ref_share}/> */}
                        </div>
                        <div>{isRepetitor ? <div>При регистрации в сервисе по данной ссылке ваши клиенты сразу <b>получат на баланс 300₽.</b> Этими деньгами клиент сможет расплатиться за ваши услуги.</div> : <div>При регистрации в сервисе по вашей ссылке <b>ваши рефералы сразу получат на баланс 300₽.</b> Этими деньгами они смогут расплатиться за онлайн-консультации на платформе.</div>}</div>
                        <br/>
                        <div>Если по вашей ссылке зарегестрировался эксперт, то <b>вы будете получать 5%</b> с его заработка на платформе в течение его первого месяца работы.</div>
                    </div>
                     {recommendedUser != undefined && 
                     <a href={Pages.users + "/id" + recommendedUser._id} target="_blank">
                         <div style={{paddingBottom : '21px'}} className="other_ref_container hover">
                            <div className="other_ref_recommend">Ваш рекомендатель</div>
                            <div className="other_ref_recommend other_ref_recommend_b">{recommendedUser.name} {recommendedUser.surname}</div>
                        </div>
                    </a>}
                   
                    <div onClick={showRules} className="ref_rules_btn hover">Условия и правила</div>
                   
                </div>}
            </div>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast style={isDesktop ? {} : {top : '100px'}} text={toastText}/>
            </CSSTransition>
        </div>
    );
}

export default Referals;
