import { useCallback } from 'react';
import { setCookie } from '../../../utils/CookieHelper';
import { useHistory } from "react-router-dom";
import { UploadApi } from '../../../api/UploadApi';

function SexTooltip(props) {
    
    return (
        <div style={{position : 'initial', minHeight : 'auto', minWidth : 'auto'}} className="more_tooltip_profile_bg">
            <div onClick={() => props.setShow(false)} style={{position : 'fixed'}} className="more_tooltip_profile_bg"></div>
            <div style={{position : 'absolute'}} className="more_tooltip_profile">
                <p onClick={() => props.setSex(0)} className="more_tooltip_profile_text hover">Мужской</p>
                <p onClick={() => props.setSex(1)} className="more_tooltip_profile_text hover">Женский</p>
            </div>
        </div>
    );
}

export default SexTooltip;
