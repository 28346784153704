/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Pages } from '../../../utils/Utils';
import { PlannerApi } from '../../../api/PlannerApi';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import PlannedPlannerItem from '../../planner/list_items/PlannedPlannerItem';
import { getIsDesktop } from '../../../utils/Utils';
import moment from 'moment'
import ProfileGroupCalls from '../../profile/custom_views/ProfileGroupCalls';

function PlannersList(props) {
    const [planners, setPlanners] = useState([])
    const history = useHistory()
    useEffect(() => {
        PlannerApi.getMyPlanner((responseData) => {
            console.log("getMyPlanner result = ", responseData.result)
            getGroupCalls(responseData.result)
        }, (responseData) => {
        })
    }, [])
    const getGroupCalls = (planners) => {
        PlannerApi.getMySozvonByLink((responseData) => {
            var result = [...planners]
            result.push(...responseData.result)
            result.sort(function(a,b){
                return moment(a.sozvon_date).utc().local().toDate() - moment(b.sozvon_date).utc().local().toDate();
            })
            console.log("getGroupCalls result = ", result)
            setPlanners(result)
        }, (responseData) => {

        })
        
    }
   
    return (
           <ProfileGroupCalls isMyCalls style={{maxWidth : 'none', margin : '12px 0 0 0'}} planners={planners}/>
    );
    
}

export default PlannersList;
