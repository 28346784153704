/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import { UsersApi } from '../../../../api/UsersApi';


function ChangeUserUrlDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const [url, setUrl] = useState("")
    const [errorText, setErrorText] = useState("")
    const isDesktop = getIsDesktop()
    const user = props.user
    const isEnableButton = url.length > 1 && errorText == ""
    useEffect(() => {
        if (user && user._id && props.isShow){
            setUrl(user.domain_name ? user.domain_name : "id" + user._id)
        }
    }, [user, props.isShow])
    const onNext = () => {
        setLoading(true)
        setErrorText("")
        // AuthApi.addTelegramNotify(tgCode, (responseData) => {
        //     setLoading(false)
        //     props.getMyInfo()
        //     if (props.onFinish){
        //         props.onFinish()
        //     }
        //     cancel()
        // }, () => {
        //     setLoading(false)
        // })
        const params = {domain_name : url.toLowerCase()}
        UsersApi.setMyInfo(params, (responseData) => {
            setLoading(false)
            props.getMyInfo()
            cancel()
        }, (responseData) => {
            setLoading(false)
            setErrorText(responseData.desc)
        })
        
    }
    const cancel = () => {
        props.setShow(false)
    }
    const checkSymbolsInUrl = (str) => {
         var urlRegex = '^[a-zA-Z0-9_]*$';
         var url = new RegExp(urlRegex, 'i');
         return url.test(str);
    }
     useEffect(() => {
        if (url){
            setErrorText(checkSymbolsInUrl(url) ? "" : "Недопустимые символы в адресе. Разрешено использовать только латинские буквы, цифры и _")

        } else {
            setErrorText("")
        }
    }, [url])
    const getChangeUrlHTML = () => {
        return(
            <div>
                <div className="other_settings_change_title">Адрес вашей страницы</div>
                <div className='other_settings_change_url'>
                    <div>Здесь вы можете изменить адрес вашей страницы</div>
                    
                    <div style={{marginTop : '16px', textAlign : 'left'}}>
                        <ul>
                            <li>Адрес не должен быть занят</li>
                            <li>Может содержать только латинские буквы, цифры, знак “_”.</li>
                        </ul>
        
                    </div>
                    <div style={errorText != "" ? {margin : '16px 0 8px 0', border: '1px solid var(--red)'} : {margin : '16px 0'}} className='other_settings_border'>
                        <input className="" type='text' value={url} onChange={(e) => setUrl(e.target.value)}/>
                    </div>
                    {errorText != "" && <div className='other_settings_error'>{errorText}</div>}
                </div>
            </div>
        )
    }
    const getInnerHtml = () => {
        return(
            <div className="other_settings_dialog">
                {getChangeUrlHTML()}
                <div style={{marginTop : '40px'}} className="flex">
                    <div onClick={cancel} className="call_prices_edit hover">Отмена</div>
                    <div style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={onNext} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : "Сохранить"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ChangeUserUrlDialog;
