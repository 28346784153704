/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect, useRef } from 'react';
import close from '../../../img/x.svg'
import star_selected from '../../../img/profile/star_selected.svg'
import star from '../../../img/profile/star.svg'
import { Pages, getIsDesktop, isValidUrl } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { disabledBtn } from '../../../pages/blog/utils/BlogUtils';
import Loader from '../../../custom_views/Loader';
import { UsersApi } from '../../../api/UsersApi';
import { getCookie } from '../../../utils/CookieHelper';

function AddMyReviewDialog(props) {
    const [stars, setStars] = useState([{img : star},{img : star},{img : star},{img : star},{img : star}])
    const [grade, setGrade] = useState(5)
    const [isLoading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [review, setReview] = useState("")
    const [url, setUrl] = useState("")
    const refTextarea = useRef(null)
    const inputText = useRef(null)
    const setShow = props.setShow
    const isShow = props.isShow

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (isShow && refTextarea && inputText){
            inputText.current.focus();
            // autosize(refTextarea.current);
        }
       
    }, [isShow])
    const onClose = () => {
       
        setShow(false)
    }
    const sendReview = () => {
        setLoading(true)
        var params = {}
        params.author_name = name
        params.text = review
        params.grade = grade
        params.link_to_proof = url

        console.log("writeReviewForYourself params = " + JSON.stringify(params))
        UsersApi.writeReviewForYourself(params, (responseData) => {
            setGrade(5)
            setName("")
            setUrl("")
            setReview("")
            setLoading(false)
            onClose()
            if (props.onLoaded){
                props.onLoaded((props.user == undefined || props.user.vk_caller_id == undefined) ? "" : "&&" + window.location.origin + Pages.reviews + '?id=' + props.user.vk_caller_id + "&user_id=" + props.user._id)
            }
        }, () => {
            setLoading(false)
        })
        
    }
    const isDesktop = getIsDesktop()
    const isBtnEnabled = name && name != "" && url && url != "" && isValidUrl(url) && review && review != "" && !isLoading
    if (!isShow){
        return null
    }
    const innerHtml = () => {
        return(
            <div>
                <div onClick={onClose} className="review_close_cont hover">
                    <img alt="" className="review_close_icon" src={close}/>
                </div>
                <div style={{padding : '0 8px'}}>
                    <div className='profile_review_dialog_title'>Загрузка отзыва</div>
                    <div className='profile_review_dialog_star'>Поставьте оценку</div>
                    <div className="write_review_stars">
                        {stars.map((review, index) => (
                            <img alt="" onClick={() => setGrade(index + 1)} className="write_review_star hover" index={index} key={index} src={grade - 1 >= index ? star_selected : star}/>
                        ))}
                    </div>
                    <div className='profile_review_dialog_star'>Имя автора отзыва</div>
                    <input ref={inputText} placeholder="Имя и фамилия" className="profile_input profile_textare_bio profile_review_dialog_input"  value={name} onChange={(e) => setName(e.target.value)}/>

                    
                    <div className='profile_review_dialog_star'>Напишите текст отзыва</div>
                    <textarea ref={refTextarea} placeholder="Ваш отзыв поможет эксперту и его потенциальным клиентам" className="profile_input profile_textare_bio profile_review_dialog_input profile_review_dialog_area"  value={review} onChange={(e) => setReview(e.target.value)}/>


                    <div className='profile_review_dialog_star'>Ссылка на отзыв</div>
                    <input placeholder="Вставьте ссылку" className="profile_input profile_textare_bio profile_review_dialog_input"  value={url} onChange={(e) => setUrl(e.target.value)}/>
                    <div className='profile_review_dialog_desc'>Нужна, чтобы потенциальный клиент, изучая отзыв, мог убедиться в его реальности. Это может быть ссылка на стороннюю соцсеть или скриншот отзыва</div>
                </div>
                <button style={isBtnEnabled ? {} : disabledBtn()} onClick={() => sendReview()} className="fill_button hover profile_bottom_button review_ready_btn profile_review_dialog_btn">{isLoading ? <Loader isWhite/> : "Готово"}</button>

            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={isShow} setShow={onClose} getInnerHtml={innerHtml}/>)
    } else {
        return (
            <div style={{background : 'white'}} className="selected_theme">
                {innerHtml()}
            </div>
        )
    }
}

export default AddMyReviewDialog;
