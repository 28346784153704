/* eslint-disable eqeqeq */
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import planner_copy from '../../../img/planner/planner_copy.svg'
import { copyText, copyToClipboard } from '../../blog/utils/BlogUtils';

function ShareGroupCallLinkDialog(props) {
    const [toastText, setToastText] = useState("")
    const isDesktop = getIsDesktop()
    const link =  props.shareLink
    const close = () => {
        props.setShow(false)
        window.location.href = Pages.lk
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const copyTextClick =() => {
        showToast(link)
        copyToClipboard(link)
    }
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '400px'} : {}} className="call_info_container relative">
                <p className="call_edit_title">Пригласить участников</p>
                <p className="planner_share_text">Чтобы пригласить клиентов, отправьте им<br/>ссылку на встречу</p>
                <div onClick={copyTextClick} className="planner_share_link hover">
                    <div>{link}</div>
                    <img alt="" src={planner_copy}/>
                </div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0'}} onClick={close} className="call_price_continue hover">Закрыть</div>
                </div>
                {toastText != "" && <div className="planner_copy_share">Ссылка на видеовстречу скопирована</div>}
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ShareGroupCallLinkDialog;
