/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useState } from 'react';
import search_icon from '../../../img/experts/search.svg'

function PayBankPickerDialog(props) {
    const isDesktop = getIsDesktop()
    const [text, setText] = useState("")

    const banks = props.banks
    const filteredBanks = banks.filter(b => (text == "" || text == undefined) ? true : b.name.toLowerCase().includes(text.toLowerCase()))

    const onBankPicked = (b) => {
        var mBanks = [...banks]
        const foundBank = mBanks.findIndex((bank => bank.member_id === b.member_id))
        mBanks.forEach((element, i) => {
            element.isSelected = foundBank === i
        })
        setText("")
        props.setBanks(mBanks)
        
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="pay_type_dialog_title">Выберите ваш банк</p>
                <div className="flex">
                    <div className="pay_bank_dialog_search">
                        <img alt="" className="search_experts_icon" src={search_icon}/>
                        <input placeholder="Поиск" className="experts_search_input pay_bank_dialog_input" type="text" value={text} onChange={(e) => setText(e.target.value)}/>
                    </div>   
                </div>
                <div className='pay_bank_dialog'>
                    {filteredBanks.map((bank, index) => (
                        <div onClick={() => onBankPicked(bank)} index={index} key={index} className='pay_type_dialog_flex hover'>
                            {bank.img ? <img alt="" className='pay_type_dialog_img' src={bank.img}/> : <div className='pay_type_dialog_img'/>}
                            <div>{bank.name}</div>
                        </div>
                    ))}
                    {filteredBanks.length == 0 && <div>Банк не найден</div>}
                </div>
               
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default PayBankPickerDialog;
