/* eslint-disable eqeqeq */
import { useState } from 'react';
import views from '../../../img/profile/views.svg'
import { declension, calculateAge, getIsDesktop } from '../../../utils/Utils';
function OnlineInfo(props) {
    const [isShowTooltip, setShowTooltip] = useState(false)
    const user = props.user
    const age = calculateAge(user.bdate)
    const disableViews = props.disableViews
    const disableAge = props.disableAge
    const isDesktop = getIsDesktop()
    
    return (
        <div className="flex relative">
            {(isDesktop || disableAge == undefined) && <div className='flex'>
                <p className="desktop_profile_desk">{age != "" ? (age + " " + declension(age, "год", "года", "лет") + ", ") : ""}{!user.online && "на связи"}</p>
                {user.online ? <div className="green_profile_circle"/> : <div/>}
                {user.online && <p className="green_profile_online">{isDesktop ? "онлайн" : "онлайн"}</p>}
            </div>}
            {(user.is_repetitor && !disableViews) && <div onMouseEnter={() => user.is_repetitor ? setShowTooltip(true) : {}} onMouseLeave={() => user.is_repetitor ? setShowTooltip(false) : {}}  className="flex">
                <img alt='' className="profile_views_img" src={views}/>
                <p className="profile_views_text">{user.views}</p>
            </div>}

            <div style={isShowTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseLeave={() => user.is_repetitor ? setShowTooltip(false) : {}} className="views_tooltip">
                <p className="views_tooltip_text">просмотров профиля</p>
            </div>
        </div>
    );
}

export default OnlineInfo;
