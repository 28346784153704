import { useRef,useState } from 'react';
import { useHistory } from "react-router-dom";
import { UploadApi } from '../../../api/UploadApi';
import { UsersApi } from '../../../api/UsersApi';
import { getIsDesktop } from '../../../utils/Utils';
import ImageCropper from '../../profile_filling/custom_views/ImageCropper';
import Loader from '../../../custom_views/Loader';
var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
function AvatarTooltip(props) {
    const user = props.user
    const [isLoading, setLoading] = useState(false)
    const [isShowCropper, setShowCropper] = useState(false)
    const [croppedImg, setCroppedImg] = useState(undefined)
    const fileUpload = useRef(null)
    const open = () => {
        window.open(user.avatar, '_blank');
    }
    const upload = () => {
        fileUpload.current.click()
    }
    const remove = () => {
        setMyAvatar(undefined)
        props.setShow(false)
    }
    const setMyAvatar = (avatar) => {
        var params = {avatar : avatar}
        UsersApi.setMyInfo(params, (response) => {
            updateUserInfo(avatar)
        })
    }
    
    const fileChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            uploadFile(event.target.files[0])
        }
    }
    const uploadFile = (file) => {
        setLoading(true)
        pickedFile = file
        fr.onload = onLoadedFileReader
        fr.readAsDataURL(file)
    }
    const onLoadedFileReader = () => {
        img.onload = onPhotoReady
        img.src = fr.result
    }
    const onPhotoReady = () => {
        setShowCropper(true)
    }
    const uploadPhoto = (img) => {
        UploadApi.uploadAvatar(img, (responseData) => {
            setMyAvatar(responseData.url)
            setLoading(false)
            props.setShow(false)
        }, () => {setLoading(false)})
    }
    const onCropped = (img) => {
        setLoading(true)
        setShowCropper(false)
        setCroppedImg(img)
        uploadPhoto(img)
    }
    const updateUserInfo = (avatar) => {
        const user = {... props.user}
        user.avatar = avatar
        props.updateUserInfo(user)
    }
    const cancelCrop = () => {
        setShowCropper(false)
        setLoading(false)
        props.setShow(false)
    }
    const hasAvatar = user.avatar != undefined && user.avatar != ""
    const isDesktop = getIsDesktop()
    if (isShowCropper){
        return(<ImageCropper style={{background : 'black', zIndex : '7', bottom : isDesktop ? 0 : '60px'}} file={pickedFile} onCancel={cancelCrop} onCropped={onCropped} img={img}/>)
    }
    return (
        <div className="more_tooltip_profile_bg">
            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div style={isLoading ? {maxHeight : '150px'} : {}} className="avatar_tooltip">
                {isLoading ? <Loader /> : 
                <div>
                    {hasAvatar && <p onClick={open} className="more_tooltip_profile_text hover">Открыть фото</p>}
                    <p onClick={upload} className="more_tooltip_profile_text hover">Загрузить фото</p>
                    {hasAvatar && <p onClick={remove} className="more_tooltip_profile_text more_tooltip_profile_text_r hover">Удалить фото</p>}
                </div>}
            </div>
            <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>

        </div>
    );
}

export default AvatarTooltip;
