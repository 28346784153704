import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class BoardsApi {
    
    static createBoard(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createBoard", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createBoard response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyBoards(onSuccess, onError) {
        fetch(getRequestUrl() + "getMyBoards", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getMyBoards response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static duplicateBoard(board_id, onSuccess, onError) {
        fetch(getRequestUrl() + "duplicateBoard", getHttpParams({board_id : board_id})).then((response) => response.json())
        .then((responseData) => {
            log("duplicateBoard response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
    static deleteBoard(board_id, onSuccess, onError) {
        fetch(getRequestUrl() + "deleteBoard", getHttpParams({board_id : board_id})).then((response) => response.json())
        .then((responseData) => {
            log("deleteBoard response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
    static sendDeskToStudent(params, onSuccess, onError) {
        fetch(getRequestUrl() + "sendDeskToStudent", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("sendDeskToStudent response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static searchPublicBoards(query, onSuccess, onError) {
        fetch(getRequestUrl() + "searchPublicBoards", getHttpParams({query : query})).then((response) => response.json())
        .then((responseData) => {
            log("searchPublicBoards response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
   
}