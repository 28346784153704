import image_finger from '../../../img/experts/image_finger.svg'
import image_glasses from '../../../img/experts/image_glasses.svg'
import { getIsDesktop } from '../../../utils/Utils';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import React, {useCallback} from 'react';

function ExpertsInfoMobile(props) {
    const itemsMobile = [
                            {id : 0, title : "Как это работает?", text : "", img : image_finger}, 
                            {id : 1, title : "Когда sozvon может пригодиться?", text : "", img : image_glasses}
                        ]
    const isDesktop = getIsDesktop()

    return (
        <div className="popular_horizontal_cont">
            <p style={isDesktop ? {paddingTop : '56px'} : {marginTop : '12px'}} className="experts_title_section">О сервисе</p>

            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {itemsMobile.map((item, index) => (
                    <ExpertsInfoItem item={item} index={index} setDialogInfo={props.setMobilePopularDiaog} setHowItWorksDialog={props.setHowItWorksDialog} key={index} itemId={item.id}/>
                ))}
            </ScrollMenu>
        </div>
    );
}

export default ExpertsInfoMobile;

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_scroll_popular hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_scroll_popular experts_arrow_right hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}
const ExpertsInfoItem = ({ item , index, setDialogInfo, setHowItWorksDialog, itemId}) => {
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);

    const isDesktop = getIsDesktop()
    const getBackground = (index) => {
        switch (index) {
            case 0:
                return {background : isDesktop ? "#FFDF7F" : "#C5AEFF", marginLeft : isDesktop ? '52px' : '16px'}
                case 1:
                return {background : isDesktop ? "#C5AEFF" : "#77EBCF"}
                case 2:
                return {background : "#56CCF2"}
                case 3:
                return {background : "#77EBCF"}
            
            default:
                return
        }
    }
    const bgColor = getBackground(index)
    const openInfo = () => {
        if (!isDesktop){
            var info = item
            info.bgColor = bgColor.background
            info.isShow = true
            if (index == 0){
                setHowItWorksDialog(info)
            } else {
                setDialogInfo(info)
            }
            
        }
    }
    return (
        <div onClick={openInfo} style={bgColor} className="popular_experts_item">
            <p className="experts_info_title">{item.title}</p>
            <div className="flex">
                <p className="experts_popular_info_text">{item.text}</p>
                <img className="experts_popular_info_icon" src={item.img}/>
            </div>
        </div>
    )
}
