import { useState } from 'react';
import ProfileInputRaw from './custom_views/ProfileInputRaw';

function Step5(props) {
    const [messagePrice, setMessagePrice] = useState(props.profileInfo.rub_per_message ? props.profileInfo.rub_per_message : 0)
    const isEnableButton = messagePrice >= 0
    return (
        <div>
           <p className="profile_fill_start_title title_step">Стоимость сообщения</p>
           <p className="profile_fill_start_desc text_with_bottom_input">Цена рассчитывается за парные сообщения. В правилах сервиса оговорено, что Клиент в одном сообщении может задать один вопрос</p>
           <ProfileInputRaw inputDesc="₽" title="Стоимость одного сообщения" value={messagePrice} setValue={setMessagePrice}/>
           <p className="profile_input_title profile_input_title_l">За вычетом комиссии сервиса получится {parseFloat(messagePrice*(1-props.config.comission)).toFixed(2)}₽</p>
           
           <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep5(messagePrice)} className="fill_button hover profile_bottom_button">Далее</button>
        </div>
    );
}

export default Step5;
