/* eslint-disable eqeqeq */
import { useState } from 'react';
import like_profile from '../../../img/profile/like_profile.svg'
import more_white from '../../../img/profile/more_white.svg'
import back_white from '../../../img/back_white.svg'
import { CSSTransition } from 'react-transition-group';
import { useHistory } from "react-router-dom";
import ProfileTooltip from '../tooltips/ProfileTooltip';
import { UsersApi } from '../../../api/UsersApi';
import Loader from '../../../custom_views/Loader';
import profile_more from '../../../img/profile/profile_more.svg'
import profile_bookmark from '../../../img/profile/profile_bookmark.svg'
import back_arrow from '../../../img/experts/back_arrow.svg'
import bookmark_selected from '../../../img/experts/bookmark_selected.svg'
import { isUserAuthed, getCookie } from '../../../utils/CookieHelper';
import NotAuthedDialog from '../dialogs/NotAuthedDialog';
import { Pages, getUrlParameter, getUserAvatar } from '../../../utils/Utils';

function ClientProfileNavbar(props) {
    const user = props.user
    const [isShowMoreTooltip, setShowMoreTooltip] = useState(false)
    const [isShowNotAuthedDialog, setShowNotAuthedDialog] = useState(false)
    const isMyProfile = (user.vk_caller_id == getCookie("id"))
    const history = useHistory()
    const isAuthed = isUserAuthed()
    const toggleBookmarks = () => {
        if (isAuthed){
            UsersApi.toggleBookmarks(user.vk_caller_id, props.isInBookmarks, (responseData) => {
                props.getMyInfo()
            })
            props.setInBookmarks(!props.isInBookmarks)
        } else {
            setShowNotAuthedDialog(true)
        }
    }

    const toggleBlocked = () => {
        UsersApi.toggleBlock(user.vk_caller_id, props.isBlocked, (responseData) => {
            props.getMyInfo()
        })
        props.setBlocked(!props.isBlocked)
    }

    
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.experts
        } else {
            if (props.setSelectedProfile != undefined){
                props.setSelectedProfile(undefined)
            }
            const scroll_top = getUrlParameter("scroll_top")
            if (scroll_top){
                localStorage.setItem("search_scroll_top", scroll_top)
            }
            history.goBack()
        }
    }
    const chat = () => {
        if (isAuthed){
            props.write()
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const call = () => {
        if (isAuthed){
            props.setShowCallBottomSheet(true)
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const isShowBack = props.isProfileFromID || props.isSelectedProfile || isMyProfile
    const isShowAvatar = props.isShowHeaderAvatar
    return (
        <div style={isShowAvatar ? {borderRadius: '0px 0px 24px 24px'} : {}} className="client_profile_navbar navbar_profile_cont">
            <div style={isShowAvatar ? {background: '#FFFFFF', borderRadius: '0px 0px 24px 24px', opacity : 1, height : '78px', filter: 'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.03)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.06))'} : {}} className="client_profile_bg"/>
            <div className="flex">
                <div onClick={onBack} style={isShowBack ? {} : {opacity : 0, pointerEvents : 'none'}} className="button_profile_img hover">
                    <img alt='' src={isShowAvatar ? back_arrow : back_white}/>
                </div>
                <div style={isShowAvatar ? {opacity : 1, marginLeft : isShowBack ? 0 : '-32px'} : {marginLeft : isShowBack ? 0 : '-32px'}} className="avatar_animation_cont">
                    <div className="chat_item_avatar_img_cont">
                        <img alt='' style={{height : '44px', width : '44px', marginLeft : '16px'}} className="chat_avatar_img" src={getUserAvatar(user)}/>

                        {user.online && <div style={{right : '-7px', top : '5px'}} className="round_msg_chat_item"></div>}
                    </div>
                    {/* {(isShowAvatar && !isShowBack) && <p style={{marginTop : '18px'}} className="chat_title_navbar">{user.name}</p>} */}
                </div>
                
            </div>
            
           <div className="profile_mobile_toolbar">
                
                {/* <div onClick={copy} className="button_profile_img hover">
                    <img src={share_profile}/>
                </div>
                {!props.isMyProfile ? 
                <div onClick={toggleBookmarks} className="button_profile_img hover">
                    <img src={props.isInBookmarks ? bookmark : like_profile}/>
                </div> : 
                <div onClick={() => setShowMoreTooltip(true)} className="button_profile_img hover">
                    <img src={more}/>
                </div>} */}
                {!props.isMyProfile &&
                    <div style={{position : 'relative', maxHeight : '48px'}} className="flex"> 
                        {!props.isLoading ? <div className="flex">
                            <img alt='' onClick={toggleBookmarks} style={props.isInBookmarks ? {height : '26px', width : '26px', marginTop : '11.5px'} : {}} className={isShowAvatar ? "mobile_profile_icon hover" : "mobile_profile_bookmark hover"} src={props.isInBookmarks ? bookmark_selected : isShowAvatar ? profile_bookmark : like_profile}/>
                            {/* <img onClick={chat} className="mobile_profile_icon hover" src={isShowAvatar ? profile_msg : chat_white}/>
                            <img onClick={call} className="mobile_profile_icon hover" src={isShowAvatar ? profile_call :call_white}/>  */}
                        </div> : <Loader isWhite={!isShowAvatar} style={{margin : '0 64px 0 0', minHeight : 'auto'}}/>}
                    </div> 
                }

                <img alt=''  onClick={() => setShowMoreTooltip(true)} className="mobile_profile_icon hover" src={isShowAvatar ? profile_more : more_white}/>


                <CSSTransition in={isShowMoreTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                    <ProfileTooltip getMyInfo={props.getMyInfo} toggleBlocked={toggleBlocked} isBlocked={props.isBlocked} showToast={props.showToast} isMyProfile={props.isMyProfile} setUploadVideoChanged={props.setUploadVideoChanged} user={user} setShowMoreTooltip={setShowMoreTooltip}/>
                </CSSTransition>
                
            </div>
            <NotAuthedDialog isShow={isShowNotAuthedDialog} setShow={setShowNotAuthedDialog}/>
        </div>
    );
}

export default ClientProfileNavbar;
