/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, getAuthUrlWithPrev, Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useHistory } from "react-router-dom";
import { useState, useRef } from 'react';
import { IMaskInput } from 'react-imask';
import free_lesson from '../../../img/profile/free_lesson.svg'
import { UsersApi } from '../../../api/UsersApi';

function FreeLessonDialog(props) {
    const [phoneNumber, setPhone] = useState("7")
    const [specialization, setSpecialization] = useState("")
    const ref = useRef(null)
    const inputRef = useRef(null)

    const isDesktop = getIsDesktop()
    const history = useHistory()
    const cancel = () => {
        props.setShow(false)
    }
    var isPhoneEntered = phoneNumber.length == 11
    var isPhoneError = false
    const onPhoneChange = (phone) => {
        if (phone == undefined || phone == ""){
            setPhone("")
            setPhone("7")
        } else {
            if (phone.length < 3){
                setPhone("7")
            } else {
                setPhone(phone)
            }
        }
    }
    const fastCreateRequestRepetitor = () => {
        UsersApi.fastCreateRequestRepetitor(specialization, phoneNumber, () => {

        }, () => {

        })
        cancel()
    }
    const getInnerHtml = () => {
        return(
            <div style={{padding : '32px 14px', maxWidth : isDesktop ? '370px' : ''}} className="call_info_container relative">
                <div className='profile_free_lesson_t'>Дарим бесплатное онлайн-занятие с репетитором</div>

                <div className="profile_free_lesson_input profile_free_lesson_bg">
                    <p className='profile_free_l_t'>Предмет</p>
                    <input className="auth_phone_input profile_free_lesson_bg" placeholder="Например, русский язык" type="text" value={specialization} onChange={(e) => setSpecialization(e.target.value)}/>
                </div>

                <div className="profile_free_lesson_input profile_free_lesson_bg">
                    <p className='profile_free_l_t'>Телефон</p>
                    <IMaskInput type="tel" className="auth_phone_input profile_free_lesson_bg" mask={'+{7} (000) 000-00-00'} radix="." value={phoneNumber} unmask={true} // true|false|'typed'
                    ref={ref}
                    inputRef={inputRef}
                    onAccept={
                        (value, mask) => {
                            onPhoneChange(value)
                            console.log("value = " + value)
                        }
                    } placeholder='+7 (000) 000-00-00' />
                </div>
                <div onClick={fastCreateRequestRepetitor} style={(isPhoneEntered && specialization != "" && specialization.length > 1) ? {} : {pointerEvents : 'none', opacity : '0.2'}} className='profle_free_lesson_btn hover'>Получить подарок</div>
                <div className='profle_free_lesson_privacy'>Нажимая кнопку “Получить подарок”<br/>я соглашаюсь с <a rel="noreferrer" target='_blank' href={Pages.privacy_policy} style={{fontWeight : '700'}} className='hover'>Политикой<br/>конфиденциальности</a></div>
                <img alt='' className='profle_free_lesson_img' src={free_lesson}/>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml} setShow={cancel}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow} onDismiss={cancel}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default FreeLessonDialog;
