import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function PayTypePickerDialog(props) {
    const isDesktop = getIsDesktop()
    const types = props.types
    const onTypePicked = (index) => {
        var mTypes = [...types]
        mTypes.forEach((element, i) => {
            element.isSelected = index === i
        })
        console.log("setTypes mTypes = ", mTypes)
        props.setTypes(mTypes)
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="pay_type_dialog_title">Способ вывода</p>
                {types.map((type, index) => (
                    <div onClick={() => onTypePicked(index)} index={index} key={index} className='pay_type_dialog_flex hover'>
                        <img alt="" className='pay_type_dialog_img' src={type.img}/>
                        <div>{type.text}</div>
                    </div>
                ))}
               
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default PayTypePickerDialog;
