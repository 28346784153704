import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, setModelParam } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import step_1 from '../../../img/experts/step_1.svg'
import step_2 from '../../../img/experts/step_2.svg'
import step_3 from '../../../img/experts/step_3.svg'
import step_4 from '../../../img/experts/step_4.svg'
import popular_left from '../../../img/experts/popular_left.svg'
import popular_right from '../../../img/experts/popular_right.svg'
import { useState } from 'react';

function HowItWorksDialog(props) {
    const [index, setIndex] = useState(0)
    const items = [
        {id : 0, text : "Найдите нужного эксперта в каталоге или оставьте заявку на поиск эксперта — получите отклики и выберите подходящего специалиста. Видеопрезентация и отзывы клиентов помогут сделать правильный выбор.", img : step_1},
        {id : 1, text : "Пополните баланс удобным способом.", img : step_2},
        {id : 2, text : "Спишитесь или созвонитесь с выбранным экспертом.", img : step_3},
        {id : 3, text : "Оплата спишется автоматически. Вы заплатите за то количество времени, которое прообщаетесь. С точностью до минуты.", img : step_4}]
    const info = props.info
    const close = () => {
        setIndex(0)
        setModelParam(props.info, "isShow", false, props.setDialogInfo)
    }
    const isDesktop = getIsDesktop()
    
    const getInnerHtml = () =>{
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}} className="call_info_container">
                <img alt='' className="how_it_works_slide_img" src={items[index].img}/>
                <p style={{minHeight : '102px'}} className="how_it_works_slide_text">{items[index].text}</p>
                <div className="mobile_popular_btns">
                    {/* <div onClick={close} className="call_price_continue hover">Понятно</div> */}
                    <img alt='' style={index == 0 ? {opacity : 0, pointerEvents : 'none'} : {}} onClick={() => setIndex(index - 1)} className="mobile_popular_btn" src={popular_left}/>
                    {index >= 3 ? <div onClick={close} style={{width : 'fit-content', padding : '14px 44px'}} className="call_price_continue hover">Понятно</div> :  <img onClick={() => setIndex(index + 1)} className="mobile_popular_btn" src={popular_right}/>}
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={info.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={info.isShow} onDismiss={close}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default HowItWorksDialog;
