/* eslint-disable eqeqeq */


import * as SDK from '@vkontakte/calls-sdk'
import React, { useState } from 'react';
import chevron_back from '../../../img/chevron_back.svg'
import chevron_down from '../../../img/chevron_down.svg'
import volume from '../../../img/call/volume.svg'
import camera from '../../../img/call/camera.svg'
var cameraIndex = 0

function    CallNavbar(props) {
    const [isVolumeEnabled, setVolumeEnabled] = useState(true)
    const callToUser = props.callToUser ? props.callToUser : {name : ''}
    const isGroupCall = props.isGroupCall
    
    async function changeCamera (){
        const cameras = SDK.browser.getCameras()
        if (cameras.length == 1){
            return
        }
        // if (SDK.browser.isMobile() && cameras.length > 1) {
        //     cameraIndex = (cameraIndex % 2 == 0) ? (cameraIndex + 1) : 0
        //     var camera = props.cameras[cameraIndex]
        //     await SDK.changeDevice(camera.kind, camera.deviceId);
            
        //     return
        // }
        if (SDK.browser.isMobile() && cameras.length > 1) {
            await SDK.changeDevice('videoinput', cameraIndex == 0 ? cameras[cameras.length - 1].deviceId : cameras[0].deviceId);
            cameraIndex += 1
            if (cameraIndex == 2){
                cameraIndex = 0
            }
            return
        }
        var cameraDesktop = undefined;
        cameraIndex += 1
        if (cameraIndex >= cameras.length){
            cameraIndex = 0
        } 
        cameraDesktop = cameras[cameraIndex]
        await SDK.changeDevice(cameraDesktop.kind, cameraDesktop.deviceId)
       
    }
    const toggleVolume = () => {
        console.log("toggleVolume = " + isVolumeEnabled)
        props.toggleVolume(!isVolumeEnabled)
        setVolumeEnabled(!isVolumeEnabled)
    }
    return (
        <div className="call_navbar">

            <div className="flex">
                <div onClick={() => props.setShowCloseCall(true)} className="back_icon_container hover chat_back_icon">
                    <img alt="" className="back_icon_img" src={chevron_back}/>
                </div>
                {!isGroupCall && <div>
                    <div onClick={() => props.setShowCallInfo(true)} className="flex hover">
                        <p className="call_title">{callToUser.name}</p>
                        <img alt="" style={props.isShowCallInfo ? {rotate : '180deg'} : {}} className="call_title_arrow" src={chevron_down}/>
                    </div>
                    {!props.isCallAccepted && <p className="call_title_small">запрос</p>}
                </div>}
            </div>
            <div className="flex">
                <div style={{display : 'none'}} onClick={toggleVolume} className="call_navbar_icon_cont hover">
                    <img alt="" className="call_navbar_icon" src={volume}/>
                </div>
                {props.cameras.length > 1 && <div onClick={changeCamera} className="call_navbar_icon_cont hover">
                    <img alt="" className="call_navbar_icon" src={camera}/>
                </div>}
            </div>
        </div>
    );
}

export default CallNavbar;
