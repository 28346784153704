/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState, useEffect } from 'react';
import { Pages, getIsDesktop, getUrlParameter, getUserAvatar } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import close_chat from '../../img/chat/close_chat.svg'
import { useHistory } from "react-router-dom";
import { PlannerApi } from '../../api/PlannerApi';
import Loader from '../../custom_views/Loader';
import planner_call from '../../img/planner/planner_call.svg'
import planner_notif from '../../img/planner/planner_notif.svg'
import calendar_today from '../../img/planner/calendar_today.svg'
import moment from 'moment';
import ReadyTimer from './custom_views/ReadyTime';
import { getCookie } from '../../utils/CookieHelper';
import CancelPlannerDialog from './dialogs/CancelPlannerDialog';
import { secondsToTime } from './utils/PlannerUtils';
import { UsersApi } from '../../api/UsersApi';
import planner_close from '../../img/planner/planner_close.svg'


function PlannerInfo(props) {
    const [isLoading, setLoading] = useState(true)
    const [isReadyLoading, setReadyLoading] = useState(false)
    const [planner, setPlanner] = useState({})
    const [isReady, setReady] = useState(false)
    const [isReadyClicked, setReadyClicked] = useState(false)
    const [secondsToStart, setSecondsToStart] = useState(9999)
    const [isShowCancel, setShowCancel] = useState(false)
    const [isCanceled, setCanceled] = useState(false)
    useEffect(() => {
        PlannerApi.getPlannerById({"planner_id" : getUrlParameter("id")},(responseData) => {
            setLoading(false)
            setPlanner(responseData.planner)
            setCanceled(responseData.planner.canceled)
        }, (responseData) => {
            setLoading(false)
        })
    }, [])

    const history = useHistory()
    const isDesktop = getIsDesktop()
    const onBack = () => {
        if (isCanceled || history.action == "POP"){
            window.location.href = Pages.lk
        } else {
            history.goBack()
        }
    }
    const onReady = () => {
        setReadyLoading(true)
        PlannerApi.repetitorReady({planner_id : planner._id}, (responseData) => {
            setReadyLoading(false)
            setReadyClicked(true)
        }, (responseData) => {
            setReadyLoading(false)
        })
    }
    const onCancel = () => {
        setShowCancel(!isShowCancel)
    }
   
    const onTimer = (seconds) => {
        if (!isReady && seconds <= 300){
            setReady(true)
        }
        setSecondsToStart(seconds)
    }
    const getUser = (user) => {
        UsersApi.getUsersByIDs(user._id, (mUser) => {
            props.setCallToUser(mUser)
        }, () => {setLoading(false)}, 1)
    }
    const onPlannerCanceled = () => {
        setCanceled(true)
    }
    var date = ""
    var datePlusHour = ""
    var time = ""
    const isHourFinished = secondsToStart < -3600
    const isIClient = planner.client != undefined ? planner.client.vk_caller_id == getCookie("id") : false
    if (planner.sozvon_date != undefined){
        date = moment(planner.sozvon_date).utc().local()
        datePlusHour = moment(planner.sozvon_date).utc().local().add(1, 'hours')
        time = date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM, dd")
    }
    const call = () => {
        getUser(!isIClient ? planner.client : planner.repetitor)
    }
    const getTimerHtml = () => {
        return(
            <div>
                <div className="planner_time_to_start_line"></div>
                <div className="planner_time_to_start_title">{'Время ' + (secondsToStart < 0 ? 'от' : 'до') + ' начала созвона'}</div>
                <div className="planner_time_to_start">{secondsToTime(secondsToStart)}</div>
            </div>
        )
    }
   
    return (
        <div style={isDesktop ? getDesktopStyle() : {position : 'block', zIndex : '2', background : '#F1F5FD'}} className="selected_settings create_planner_bg">
            <div className="create_ads_step">
                <div className="create_planner_toolbar">
                        <p className="theme_title planner_title">{isDesktop ? "Запланированный созвон" : ""}</p>
                        {!isDesktop ? 
                            <div onClick={onBack} className="planner_close_cont hover">
                                <img alt="" className="planner_close_img hover" src={close_chat}/>
                            </div>
                            : 
                            <img alt="" onClick={onBack}  className="planner_close_desktop hover" src={planner_close}/>
                        }

                </div>
                {/* <div className="planned_planner_header">
                    <div className="flex">
                        <div className="arrow_left_theme">
                            <div className="flex">
                                <div onClick={onBack} className="planner_close_cont hover">
                                    <img className="planner_close_img hover" src={close_chat}/>
                                </div>
                                <p className="theme_title"></p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {(isLoading || planner.client == undefined) ? <Loader /> : 
                <div>
                    <div className="planned_picked_planner_cont">
                        <div className="planned_info_title">{planner.sozvon_name}</div>
                        <a rel="noreferrer" href={!isIClient ? Pages.users + "/id" + planner.client._id :  Pages.users + "/id" + planner.repetitor._id} target="_blank"><div className="flex hover">
                            <img alt="" src={!isIClient ? (getUserAvatar(planner.client)) : (getUserAvatar(planner.repetitor))} className="planned_item_avatar planned_picked_planner_avat"/>
                            <div>
                                <div className="planned_picked_planner_name">{!isIClient ? planner.client.name : planner.repetitor.name}</div>
                                <div className="planned_picked_planner_client">{!isIClient ? "Клиент" : "Эксперт"}</div>
                            </div>
                        </div></a>
                        <div style={{opacity : '0.5'}}>
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img" src={calendar_today}/>
                                <div className="planned_picked_planner_time">{time}</div>
                            </div>
                            {planner.reminder.length > 0 && planner.reminder.map((item, index) => (
                                <div className="flex" index={index} key={index}>
                                    <img alt="" className="planned_picked_planner_img" src={planner_notif}/>
                                    <div className="planned_picked_planner_time">{moment.unix(item/1000).local().format("HH:mm · DD MMMM, dd")}</div>
                                </div>
                            ))}
                            
                        </div>
                        {(isHourFinished || isCanceled) ? 
                            <div className="planner_canceled">{isHourFinished ? "Занятие завершено" : "Занятие отменено"}</div>
                        :
                        isReadyClicked ? 
                        <div>
                            <div style={{textAlign : 'center', opacity : '0.5', marginBottom : '16px'}} className="planned_picked_planner_time">{isIClient ? "Вы можете подключиться в течение 30 мин. после запланированного времени созвона. Это время вами гарантированно оплачено, поэтому эксперт ожидает связи." : "Клиент может подключиться в течение 30 мин. после запланированного времени созвона. Это время вам гарантированно оплачено, поэтому оставайтесь на связи."}</div>

                            <div onClick={call} className="planner_call_btn hover">
                                <img alt="" className="planner_call_btn_icon" src={planner_call}/>
                                позвонить
                            </div>
                            
                        </div>

                        :
                        <div>
                            {secondsToStart <= 300 && getTimerHtml()}
                            <div style={isReady ? {} : {opacity : 0.3, pointerEvents : 'none'}} onClick={onReady} className="chat_planner_accept chat_lowercased hover">
                                {isReadyLoading ? <Loader isWhite/> : "Я готов"}
                            </div>
                            
                            {isReady && <div style={{textAlign : 'center', opacity : '0.5'}} className="planned_picked_planner_time">{isIClient ? "Вы можете подключиться в течение 30 мин. после запланированного времени созвона. Это время вами гарантированно оплачено, поэтому эксперт ожидает связи." : "Если вы не нажмёте кнопку “Я готов” в течение 5 минут после запланированного времени созвона, то клиенту будет возвращена предоплата."}</div>}
                            <div onClick={onCancel} className="planned_picked_planner_cancel hover">Отменить созвон</div>
                        </div>}
                    </div>
                    <div onClick={() => window.open(Pages.planner_rules, '_blank')} style={{textAlign : 'center', marginTop : '24px'}} className="create_planner_how_it_works hover">Как это работает?</div>
                    <ReadyTimer startTime={moment(planner.sozvon_date).utc().local().unix()} onTimer={onTimer}/>

                </div>}
            </div>
            <CancelPlannerDialog onPlannerCanceled={onPlannerCanceled} onSuccess={onPlannerCanceled} planner={planner} isShow={isShowCancel} setShow={setShowCancel}/>
        </div>
        
    );
}

export default PlannerInfo;
