/* eslint-disable eqeqeq */
import { useState, useRef,useEffect } from 'react';
import Loader from '../../../custom_views/Loader';
import { getIsDesktop, getUserBadge, getUserAvatar, getUrlParameter, youtube_parser, getVideoType, VideoType, changeVkVideoUrl } from '../../../utils/Utils';
import upload_video_icon from '../../../img/profile/upload_video_icon.svg'
import upload_video_mob from '../../../img/profile/upload_video_mob.svg'
import { CSSTransition } from 'react-transition-group';

import { UploadApi } from '../../../api/UploadApi';
import AvatarTooltip from '../tooltips/AvatarTooltip';
import OnlineInfo from '../../profile_filling/custom_views/OnlineInfo';
import CallWriteButtons from './CallWriteButtons';
import YouTube from "react-youtube"
import ProIcon from '../../ads_desk/AdsBoard/custom_views/ProIcon';
import { getCookie } from '../../../utils/CookieHelper';

var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
function UploadVideo(props) {
   
    const [isLoading, setLoading] = useState(false)
    const [isVideoLoading, setVideoLoading] = useState(true)
    const [isShowAvatarTooltip, setShowAvatarTooltip] = useState(false)
    
    const config = props.config
    const fileUpload = useRef(null)
    const user = props.user
    const is_repetitor = props.user != undefined ? props.user.is_repetitor : false

    useEffect(() => {
        if (props.uploadVideoChanged != 0){
            openFilePicker()
            
        }
    }, [props.uploadVideoChanged])
    const openFilePicker = () => {
        //fileUpload.current.click()
        props.setShowUploadYoutube(true)
    }
    const uploadVideo = () => {
        UploadApi.uploadVideo(pickedFile, (responseData) => {
            const user = {... props.user}
            user.video_url = responseData.url
            console.log("uploadVideo user = " + JSON.stringify(user))
            props.updateUserInfo(user)
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }
    const fileChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            uploadFile(event.target.files[0])
        }
    }
    const uploadFile = (file) => {
        setLoading(true)
        pickedFile = file
        fr.onload = onLoadedFileReader
        fr.readAsDataURL(file)
    }
    const onLoadedFileReader = () => {
        uploadVideo()
    }
    const openVideo = () => {
        window.open(user.video_url, '_blank');
    }
    if (user == undefined || user.name == undefined){
        return(<div/>)
    }

    const isRepetitor = user.is_repetitor
    const isDesktop = getIsDesktop()
    var youtubeVideoUrl = user.video_url_youtube
    // const hasVideo = !(user.video_url == undefined || user.video_url == "")
    const hasVideo = !(youtubeVideoUrl == undefined || youtubeVideoUrl == "")
    const videoType = getVideoType(user)
    if (videoType == VideoType.vk){
        youtubeVideoUrl = changeVkVideoUrl(youtubeVideoUrl)
    }
    // console.log("videoType = ", videoType)
    // console.log("youtubeVideoUrl = ", youtubeVideoUrl)
    const isShowUploadVideo = isLoading ? true : props.isMyProfile && !hasVideo
    var videoOptions = {
        width: '320',
        playerVars: {
            autoplay: 1,
            controls: 1,
            rel: 0,
            showinfo: 0,
            mute: 1,
            loop: 1
        },
    }
    const getVideoHTML = () => {
        if (videoType == VideoType.none){
            return(
                null
            )
        }
        return(
            <div>
                {videoType == VideoType.youtube ?
                    <YouTube videoId={youtube_parser(youtubeVideoUrl)} iframeClassName="profile_desktop_video" opts={videoOptions} id="video"/>
                :
                    <iframe src={youtubeVideoUrl} className='profile_desktop_video' allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameBorder="true" allowFullScreen></iframe>
                    // <video loop playsInline autoPlay controls className="profile_desktop_video" src={youtubeVideoUrl} muted={true}></video>
                }
            </div>
        )
    }
    const getAboutHtml = () => {
        return(
            <div>
                {(user.is_repetitor && user.specializations && user.specializations.length > 0) && <div className="profile_expert_by">Эксперт - {user.specializations[0].toLowerCase()}</div>}

                <div className="flex">
                    <p className="profile_mobile_header_title">{user.name} {user.surname}</p>
                    {(isRepetitor || user.verified) && <img alt="" style={{marginTop : '11px'}} className="desktop_profile_badge"  src={getUserBadge(user)}/>}
                </div>
                {isRepetitor && <div className="profile_ages">
                    <p className="desktop_profile_desk" style={{marginRight : '3px'}}>{user.vk_caller_id == getCookie("id") ?  Math.round(user.rub_in_hour * (1 - config.comission)) : user.rub_in_hour} ₽/час ·</p>
                    <OnlineInfo user={user}/>
                    
                </div>}
                
            </div>
        )
    }
    if (isDesktop){
         return (
            <div>
                {/* {renderedVideo != undefined && <video loop onClick={openVideo} playsInline autoPlay={true} controls={false} className="profile_mobile_video" src={URL.createObjectURL(renderedVideo)} muted={true}></video>} */}

                <div style={isShowUploadVideo ? {} : {display : 'none'}} onClick={openFilePicker} className="upload_video_container hover">
                    {isLoading ? <Loader style={{marginTop : 0}}/> : 
                    <div>
                        <img alt="" className="upload_video_icon" src={upload_video_icon}/>
                        <p className="upload_video_title">Загрузить видео</p>
                        <p className="upload_video_text">Это поможет привлечь клиентов</p>
                    </div>}
                    <input id="fileInput" className="custom-file-input hover" type="file" accept="video/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>

                </div>
                {getVideoHTML()}
                {/* {hasVideo && <YouTube videoId={youtube_parser(youtubeVideoUrl)} iframeClassName="profile_desktop_video" opts={videoOptions} id="video"/>} */}
                
                {/* {hasVideo && <video loop playsInline autoPlay controls className="profile_desktop_video" src={user.video_url} muted={true}></video>} */}
            </div>
        )
    } else {
        return(
            <div className="relative">
                {isShowUploadVideo && is_repetitor ? 
                    <div className="profile_mobile_header">
                        <div className="mobile_profile_image">
                            {isLoading ? <Loader isWhite={true} style={{marginTop : 0}}/> : 
                            <img alt="" onClick={openFilePicker} className="hover upload_video_mob" src={upload_video_mob}/>}
                        </div> 
                        <div className="flex">
                            <img alt="" onClick={props.isMyProfile ? () => setShowAvatarTooltip(true) : () => {}} className="profile_mobile_avatar" src={getUserAvatar(user)}/>
                            {getAboutHtml()}
                        </div>
                        {!props.isMyProfile && <div className="call_write_buttons_less_1160">
                            <CallWriteButtons onCall={props.onCall} onWrite={props.onWrite} />
                        </div>}
                    </div> 
                : 
                    <div>
                        {hasVideo ? 
                            <div className="profile_mobile_video">
                                {getVideoHTML()}

                            </div>
                            : 
                            
                            <img alt="" className="mobile_profile_image" src={getUserAvatar(user)}/>
                                
                        }
                        <div className="profile_mobile_header">
                            <div className="flex">
                                {hasVideo && 
                                    <div className="profile_mobile_pro_cont">
                                        <img alt="" onClick={props.isMyProfile ? () => setShowAvatarTooltip(true) : () => {}} className="profile_mobile_avatar" src={getUserAvatar(user)}/>
                                        <div className="profile_mobile_pro"><ProIcon isShow={user.is_pro}/> </div>
                                    </div>
                                }
                                {getAboutHtml()}
                            </div>
                            {!props.isMyProfile && <div className="call_write_buttons_less_1160">
                                <CallWriteButtons onCall={props.onCall} onWrite={props.onWrite} />
                            </div>}
                        </div>
                    </div>
                }
                <input id="fileInput" className="custom-file-input hover" type="file" accept="video/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>
                <CSSTransition in={isShowAvatarTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                    <AvatarTooltip updateUserInfo={props.updateUserInfo} user={user} setShow={setShowAvatarTooltip}/>
                </CSSTransition>
            </div>
        )
    }
}

export default UploadVideo;
