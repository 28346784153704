/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
import { useState } from 'react';
import back_arrow from '../../../img/experts/back_arrow.svg'
import { getCookie } from '../../../utils/CookieHelper';
import { getIsDesktop, Pages } from '../../../utils/Utils';
import { useHistory } from "react-router-dom";
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/blogs/blogs_back.svg'
import crown from '../../../img/chat/crown.svg'
import check from '../../../img/profile/ads/check.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ProActivateDialog from './dialogs/ProActivateDialog';
import moment from 'moment'
import ProdeactivateDialog from './dialogs/ProDeactivateDialog';
import { UsersApi } from '../../../api/UsersApi';
import Loader from '../../../custom_views/Loader';

function Pro(props) {
    // const [isShowActivate, setShowActivate] = useState(false)
    const [activateDialogInfo, setActivateDialogInfo] = useState({isShow : false, price : 0, type : 0})
    const [isShowDeActivate, setShowDeActivate] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const config = props.config
    // const token = getCookie("token")
    // const isTokenEmpty = token == null || token == undefined || token == ""
    const user = props.user
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.experts
        } else {
            history.goBack()
        }
    }
    const turnOnAutoPro = () => {
        setLoading(true)
        UsersApi.buyPro(user.is_pro_type, (responseData) => {
            props.getMyInfo()
            setTimeout(function() {
                setLoading(false)
            }, 600);
            
        }, () => {props.getMyInfo()})
    }

    const isProCanceled = user.is_pro_canceling
    const proCancellingAndWorking = user.is_pro && isProCanceled
    console.log("config pro = ", props.config)
    const proActivated = () => {
        return(
            <div style={isDesktop ? {margin : '12px 0 12px 16px'} : {marginTop : '90px'}} className="pro_questions_cont">
                <div className="pro_questions">Статус PRO действует</div>
                <div className="pro_questions_text">{proCancellingAndWorking ? <div>Статус PRO действует до {moment.parseZone(user.is_pro_create_date).local().add(user.is_pro_type == 1 ? 30 : 360,'days').format("DD.MM.YYYY")}.<br/>Автоматическое продление статуса PRO отключено.</div> : <div>Действует автоматическое продление статуса.<br/>Следующий автоплатёж (с вашего внутреннего баланса) будет осуществлён: {moment.parseZone(user.is_pro_create_date).local().add(user.is_pro_type == 1 ? 30 : 365,'days').format("DD.MM.YYYY")}.</div>}</div>
                {proCancellingAndWorking ? <div onClick={turnOnAutoPro} className="pro_support hover">{isLoading ? <Loader isWhite/> : "Включить автопродление"}</div> : <div onClick={() => setShowDeActivate(true)} className="pro_support hover">Отключить статус</div>}
                <Link to={Pages.pay}><div className="pro_history hover">История платежей</div></Link>
                <a href={Pages.terms} target="_blank"><div style={{marginTop : 0}} className="pro_history hover">Условия и правила</div></a>
            </div>
        )
    }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header pro_header">
                <div className="flex">
                    {isDesktop ? <img alt='' onClick={onBack} className="ads_back_desktop blog_post_back hover" src={other_back}/> 
                    :
                    <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}

                    <div className="blog_toolbar_title">Статус PRO</div>
                </div>
            </div>
        )
    }
    const setShowActivate = () => {
        setActivateDialogInfo({isShow : false})
    }
    const texts = ["Приоритетное расположение вашего профиля в поисковой выдаче", "Возможность откликаться на объявления и просматривать ссылки в объявлениях с пометкой “из соцсетей”", "Неограниченное время хранения картинок в облаке. У базовых аккаунтов картинки на досках и в сообщениях удаляются через 2 недели.", "Возможность проводить созвоны стоимостью 0 руб.","Дополнительная реклама в Яндекс.Директ, которая будет вести на ваш профиль и давать от 1 до 10 переходов новых клиентов на профиль в день."]
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className={isDesktop ? "pro_desktop_top" : "selected_settings"}>
                {getToolbar()}
                <div className="blog_post_mt blogs_post_desktop_flex">
                    {(user.is_pro && !isDesktop) && proActivated()}
                    <div style={user.is_pro ? {marginTop : '12px'} : {}} className="pro_container pro_container_margin_b">
                        <div className="pro_gradient_right">
                           
                        </div>
                        <img alt='' className="pro_crown" src={crown}/>
                        <div className="pro_title">Преимущества статуса PRO</div>

                        {texts.map((text, index) => (
                            <div className="profile_ads_item_demo_flex" index={index} key={index}>
                                <img alt='' className="profile_ads_item_demo_icon" src={check}/>
                                <div className="pro_check_text">
                                    {text}
                                </div>
                            </div>
                        ))}
                        {/* <div className="pro_week_price">
                            <p>149₽</p>
                            <div>49₽/неделя</div>
                        </div> */}
                        {(user.is_pro != undefined && !user.is_pro) && <div className='pro_activate_border'>
                            <div className='pro_activate_flex pro_activate_month'>
                                <div className='pro_activate_desktop_flex pro_activate_center_v'>
                                    <div style={{width : '140px'}} className='flex pro_activate_m_text'>
                                        <span className='pro_activate_bold'>1 месяц</span>статус PRO
                                    </div>
                                    <span className='pro_activate_bold pro_activate_ml'>{config.PRICE_PRO}₽</span>
                                </div>
                                <div onClick={() => setActivateDialogInfo({isShow : true, price : config.PRICE_PRO, type : 1})} className='pro_activate_btn hover'>Оплатить</div>
                            </div>

                            <div className='pro_activate_flex pro_activate_year'>
                                <div className='pro_activate_desktop_flex pro_activate_center_v'>
                                    <div style={{width : '140px'}} className='flex pro_activate_center_v pro_activate_m_text'>
                                        <span className='pro_activate_bold'>1 год</span>статус PRO
                                    </div>
                                    <div className='flex'>
                                        <div className='flex pro_activate_center_v'><span className='pro_line_throught pro_activate_ml pro_activate_center_v'>{config.PRICE_PRO*12}</span></div><span style={{marginLeft : '4px'}} className='pro_activate_bold pro_activate_center_v'>{config.PRICE_PRO_YEAR}₽</span>
                                        <div className='pro_activate_bonus'>Выгода {config.PRICE_PRO*12 - config.PRICE_PRO_YEAR}₽</div>
                                    </div>
                                </div>
                                <div onClick={() => setActivateDialogInfo({isShow : true, price : config.PRICE_PRO_YEAR, type : 2})} className='pro_activate_btn hover'>Оплатить</div>
                            </div>
                        </div>}
                        <div style={user.is_pro ? {marginTop : '16px'} : {}} className="pro_advice">
                            Для успешной работы на платформе рекомендуем ознакомиться с <a target="_blank" className='hover' href={Pages.advices_profile}><span style={{textDecorationLine : 'underline', fontWeight : '700'}}>советами по заполнению профиля.</span></a>
                        </div>
                        {/* {!user.is_pro && <div onClick={ () => setShowActivate(true)} className="pro_status_activate_btn hover">Активировать статус PRO</div>} */}
                    </div>
                    <div>
                        {(user.is_pro && isDesktop) && proActivated()}
                        <div className="pro_questions_cont">
                            <div className="pro_questions">Есть вопросы?</div>
                            <div className="pro_questions_text">Получите онлайн-консультацию у наших специалистов</div>
                            <a target="_blank" href={Pages.support_chat}><div className="pro_support hover">Чат с поддержкой</div></a>
                        </div>
                    </div>
                </div>
            </div>
            <ProActivateDialog getMyInfo={props.getMyInfo} user={props.user} activateDialogInfo={activateDialogInfo} isShow={activateDialogInfo.isShow} setShow={setShowActivate}/>
            <ProdeactivateDialog getMyInfo={props.getMyInfo} user={props.user} isShow={isShowDeActivate} setShow={setShowDeActivate}/>
        </div>
    );
}

export default Pro;
