import '../../styles/answers.css';
import { useState, useEffect, useCallback } from 'react';
import { getIsDesktop } from '../../utils/Utils';
import answers_empty from '../../img/answers/answers_empty.svg'

function Answers(props) {
    const user = props.user
    const isDesktop = getIsDesktop()
    
    return (
        <div className="answers_bg">
            <div className="answers_title">Ответы</div>
            <div className="answers_empty_cont center_horizontal">
                <div className="answers_empty_text">Скоро здесь будет раздел, в котором вы сможете задать вопрос и получить ответ на него от разных экспертов.</div>
                <img className="aswers_empty_icon" src={answers_empty}/>
            </div>
        </div>
    );
}

export default Answers;
