/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import autosize from 'autosize';
import ProfileInputRaw from './ProfileInputRaw';
import FilterDropdown from '../../../custom_views/FilterDropdown';
import { ConfigApi } from '../../../api/ConfigApi';

function ProfileSpecs(props) {
    const [text, setText] = useState("")
    const [serverSpecs, setServerSpecs] = useState([])
    const [isShowSpecs, setShowSpecs] = useState(false)
    const refTextarea = useRef(null)
    const specializations = props.specializations
    useEffect(() => {
        
        if (props.disabledAutoFocus){

        } else {
            refTextarea.current.focus();
        }
        autosize(refTextarea.current);
    }, [])
    const getSpecializations = () => {
        ConfigApi.getSpecializations((responseData) => {
            setServerSpecs(responseData.specializations)
        })
    }
    var filteredServerSpecs = serverSpecs.filter( tag => text.length > 0 ? tag.toLowerCase().includes(text.toLowerCase()) : isShowSpecs)
    if (text.length > 0 && filteredServerSpecs.length == 0){
        filteredServerSpecs.push("Другое")
    }

    const onFilterPicked = (tag) => {
        filteredServerSpecs = []
        setShowSpecs(false)
        setText("")
        props.addTag(tag)
    }
  
    const openGroup = () => {
        window.open('https://vk.com/sozvonpro', '_blank');

    }
    useEffect(() => {
        getSpecializations()
    }, []);
   
    return (
        <div>
           
           <div onClick={() => setShowSpecs(!isShowSpecs)} className="spec_top">
                <ProfileInputRaw innerRef={refTextarea} placeholder="Выберите из появляющихся подсказок" value={text} setValue={setText}/>
           </div>
           {(filteredServerSpecs.length > 0 || isShowSpecs) && <FilterDropdown isHideTooltipBg onCloseFilters={() => setShowSpecs(false)} style={{margin : '0px 0px 0px -2px'}} filters={filteredServerSpecs} onFilterPicked={onFilterPicked}/>}

           <div className="profile_tags_contaner">
                {specializations.length > 0 && specializations.map((tag, index) => (
                    <div className="hover" style={tag == "" || tag == " " ? {display : 'none'} : {}} key={index}>
                        <p onClick={() => props.removeTag(tag, index)} className="profile_tag">{tag}</p>
                    </div>
                ))}
           </div>
           {specializations.includes("Другое" || "другое") && 
                <div onClick={openGroup} className="no_my_spec hover">
                    Нет вашей специализации?<br/>
                    <b>Напишите об этом в сообщения</b><br/>
                    <b>нашей группы Вк</b>
                </div>
            }
        </div>
    );
}

export default ProfileSpecs;
