import { useState, useEffect } from 'react';
import ProfileSpecs from './custom_views/ProfileSpecs';

function Step6(props) {
    const [specializations, setSpecializations] = useState(props.profileInfo.specs ? props.profileInfo.specs : [])
    const isEnableButton = specializations.length > 0
    
    const addTag = (tag) => {
        var specs = [...specializations]
        if (!specs.includes(tag)){
            specs.push(tag)
            setSpecializations(specs)
        }
    }
    const removeTag = (tag, index) => {
        var specs = [...specializations]
        specs.splice(index, 1)
        setSpecializations(specs)
    }
   
   
   
    return (
        <div>
            <p style={{marginBottom : '16px'}} className="profile_fill_start_title title_step">Ваша специализация</p>
            <div style={{marginBottom : '16px'}}  className="profile_fill_start_desc">Начните вводить и выберите<br/>из появляющихся подсказок</div>
            <div style={{marginBottom : '62px'}} className="profile_fill_start_desc">По указанной специализации вас смогут найти новые клиенты. Специализацию можно отредактировать в профиле</div>
            <ProfileSpecs specializations={specializations} removeTag={removeTag} addTag={addTag}/>
            <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep6(specializations)} className="fill_button hover profile_bottom_button">Далее</button>
        </div>
    );
}

export default Step6;
