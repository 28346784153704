/* eslint-disable eqeqeq */
import quick_search from '../../../img/experts/quick_search.svg'
import create_video from '../../../img/experts/create_video.svg'
import create_ads from '../../../img/experts/create_ads.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { getIsDesktop, Pages } from '../../../utils/Utils'

const QuickSearchDesktop = ({}) => {
    const isDesktop = getIsDesktop()
    return (
        
        <div className='experts_under_search'>
            <div style={isDesktop ? {} : {justifyContent : 'end'}} className='flex'>
                <Link to={Pages.express_search}>
                    <div className='btn_quick_search hover'>
                        <div>Экспресс-поиск</div>
                        <img alt='' src={quick_search}/>
                    </div>
                </Link>
                <div className='quick_search_desk'>Быстро найдём эксперта, готового проконсультировать вас сейчас</div>
            </div>
            <div style={{marginTop : '24px'}} className='experts_quick_btns'>
                <Link to={Pages.create_video_meeting} style={{width : '100%', marginRight : '8px'}}>
                    <div className='experts_quick_btn hover'>
                        <img alt='' src={create_video}/>
                        <div>Создать видеовстречу</div>
                    </div>
                </Link>
                <Link to={Pages.create_ads} style={{width : '100%', margin : '0 0 0 8px'}}>
                    <div style={isDesktop ? {margin : '0'} : {margin : '-8px 0 12px 0'}} className='experts_quick_btn hover'>
                        <img alt='' src={create_ads}/>
                        <div>Создать объявление</div>
                    </div>
                </Link>
            </div>
            {isDesktop && <div className='flex'>
                <div className='experts_quick_btn_desc'>Для индивидуальных и групповых встреч</div>
                <div className='experts_quick_btn_desc'>А эксперты откликнутся сами</div>
            </div>}
        </div>
    )
}

export default QuickSearchDesktop;
