import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { UsersApi } from '../../../api/UsersApi';
import moment from 'moment'
import { getPaymentDetalization } from '../utils/DetalizationHelper';

function DetalizationDialog(props) {
    const [user, setUser] = useState(undefined)
    const transaction = props.transaction
    const info = getPaymentDetalization(transaction, true)
    useEffect(() => {
        setUser(undefined)
        if (props.isShow && transaction.vk_caller_id_other){
            UsersApi.getUserByVkCallerID(transaction.vk_caller_id_other, (mUser) => {
                setUser(mUser)
            })
        }
    }, [props.isShow])
    const close = () => {
        props.setShow(false)
    }
    const openVk = () => {
        window.open( "https://vk.com/sozvonpro", '_blank');       
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div className="call_info_container">
                {transaction.create_date && <p className="pay_detalization_date">{moment.unix(moment(transaction.create_date, "YYYY-MM-DDTHH:mm").unix()).format("DD MMMM YYYY, HH:mm")}</p>}
                <p style={transaction.amount > 0 ? {color: '#6FCF97'} :{}} className="pay_detalization_amount">{transaction.amount > 0 ? "+" : ""}{Math.round(transaction.amount)}₽</p>
                <div className="pay_detalization_details">{info.text}
                <br/>
                {user != undefined && user.name + " " + user.surname}
                </div>
                <div className="notification_sheet_flex">
                    <img className="pay_detalization_avatar" src={info.img}/>
                </div>
                {(user != undefined && transaction.amount < 0) && <div className="pay_detalization_prices">
                    {user.rub_in_hour} ₽ / час<br/>{parseInt(user.rub_in_hour / 60)} ₽ / минута
                </div>}
                <div onClick={openVk} className="pay_detalization_problem hover">Сообщить о проблеме с выплатой</div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet onDismiss={close} style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default DetalizationDialog;
