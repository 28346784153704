/* eslint-disable eqeqeq */
import '../../styles/experts.css'
import { useState, useEffect } from 'react';
import BestExperts from './custom_views/BestExperts';
import SubjectsList from './custom_views/SubjectsList';
import big_head from '../../img/experts/big_head.svg'
import HowItWorks from './custom_views/HowItWorks';
import { Link } from "react-router-dom";
import ExpertsInfo from './custom_views/ExpertsInfo';
import SubjectsDesktopList from './custom_views/SubjectsDesktopList';
import SearchExpertsDesktop from './custom_views/SearchExpertsDesktop';
import PopularDetalizationDialog from './dialogs/PopularDetalizationDialog';
import search_icon from '../../img/experts/search.svg'
import { DebounceInput } from 'react-debounce-input';
import ExpertsInfoMobile from './custom_views/ExpertsInfoMobile';
import MobilePopularDialog from './dialogs/MobilePopularDialog';
import HowItWorksDialog from './dialogs/HowItWorksDialog';
import { Pages, parseBalance, getIsDesktop, getUserAvatar } from '../../utils/Utils';
import BalanceTooltip from './tooltips/BalanceTooltip';
import { getCookie, setCookie, isUserAuthed } from '../../utils/CookieHelper';
import TgNotificationsBanner from './custom_views/TgNotificationsBanner';
import FirstAvatarTooltip from './tooltips/FirstAvatarTooltip';
import ProIcon from '../ads_desk/AdsBoard/custom_views/ProIcon';
import QuickSearchDesktop from './custom_views/QuickSearchDesktop';
import ExpertsAdvatages from './custom_views/ExpertsAdvantages';
import ExpertsReviews from './custom_views/ExpertsReviews';
import FooterRequesities from '../../custom_views/Footer';

function Experts(props) {
    const [searchExperts, setSearchExperts] = useState("")
    const [dialogInfo, setDialogInfo] = useState({isShow : false})
    const [mobilePopularDiaog, setMobilePopularDiaog] = useState({isShow : false})
    const [howItWorksDialog, setHowItWorksDialog] = useState({isShow : false})
    const [isShowBalanceTooltip, setShowBalanceTooltip] = useState(false)
    const [isShowAvatarTooltip, setShowAvatarTooltip] = useState(false)
    useEffect(() => {
        if (getCookie("shown_avatar_tooltip") != getCookie("id") && isUserAuthed()){
            setCookie("shown_avatar_tooltip", getCookie("id"))
            setShowAvatarTooltip(true)
        }
    }, [])
    const closeAvatarTooltip = () => {
        setShowAvatarTooltip(false)
        setShowBalanceTooltip(true)
    }
    const isDesktop = getIsDesktop()
    const user = props.user
    const isShowTgBanner = props.user == undefined ? false : ((props.user.telegram_username == undefined || props.user.telegram_username == "") && props.user.vk_caller_id != undefined)
    return (
        <div>
            <div className="experts_container">
                <div className="mobile_experts">
                    {isShowTgBanner && <TgNotificationsBanner />}
                    <div className="experts_top_mobile">
                        <div className="exprets_top_header">
                            <Link to={Pages.profile}>
                                <div className="flex">
                                    <img alt="" className="exprets_top_avatar" src={getUserAvatar(user)}/>
                                    <div className="flex">
                                        <h1 className="exprets_top_header_title">Репетиторы и эксперты онлайн</h1>
                                        <div className="experts_pro_icon_name"><ProIcon isShow={user.is_pro}/></div>
                                    </div>
                                </div>
                            </Link>
                
                        </div>
                        <div className='experts_main_searc_desc'>Онлайн-репетиторы, консультирующие специалисты разных областей</div>
                        <Link to={Pages.search}>
                            <div className="experts_top_search_container">
                                <img alt="" className="search_experts_icon" src={search_icon}/>
                                <DebounceInput style={{pointerEvents : 'none'}} debounceTimeout={500} placeholder="Поиск эксперта" className="experts_search_input" type="text" value={searchExperts} onChange={(e) => setSearchExperts(e.target.value)}/>
                            </div> 
                        </Link>
                        <QuickSearchDesktop />
                        {/* <Link to={Pages.create_ads}><img alt="" className="experts_top_banner" src={ads_mobile}/></Link>
                        <Link to={Pages.express_search}><img alt="" style={{marginTop : '8px'}} className="experts_top_banner" src={express_mobile}/></Link> */}
                    </div>
                    <p style={isDesktop ? {paddingTop : 0} : {}} className="experts_title_section experts_online">Преимущества платформы</p>
                    <div className='experts_advantages_main'>
                        <ExpertsAdvatages style={{height : '64px', width : 'fit-content'}} isExperts/>
                    </div>
                    <BestExperts setSelectedProfile={props.setSelectedProfile} bestExperts={props.bestExperts} />
                    <ExpertsReviews />
                    <ExpertsInfoMobile setHowItWorksDialog={setHowItWorksDialog} setMobilePopularDiaog={setMobilePopularDiaog}/>
                    <SubjectsList consultants={props.consultants} specializations={props.specializations}/>
                    
                </div>

                <div className="desktop_experts_container">
                    {isShowTgBanner && <TgNotificationsBanner />}
                    <div className="desktop_experts">
                        <div className="desctop_experts_half">
                            <h1 className="main_title experts_main_title">Репетиторы и эксперты онлайн</h1>
                            <p className="desktop_experts_text">Здесь можно найти онлайн-репетитора, профессионалов разных областей, а также известных блогеров и знаменитостей, чтобы взять онлайн-консультацию.</p>
                            <SearchExpertsDesktop specializations={props.specializations} text={searchExperts} setText={setSearchExperts}/>
                            {/* {(user.is_repetitor != undefined && !user.is_repetitor) && <Link to="/profile_filling/step_0"><div className="experts_start_btn hover">Стать экспертом</div></Link>} */}
                            {/* <div className="experts_create_ads_cont">
                                <Link to={Pages.create_ads}><img alt="" style={{marginRight : '16px'}} className="experts_create_ads hover" src={create_ads}/></Link>
                                <Link to={Pages.express_search}><img alt="" className="experts_create_ads hover" src={express_search}/></Link>
                            </div> */}
                            <QuickSearchDesktop />
                        </div>
                        <img alt="" className="expert_bighead_width" src={big_head}/>
                        {/* <img alt="" className="expert_right_width" src={auth_bg_img}/> */}
                    </div>
                    
                    <div className="experts_bg_white">
                        {/* <PlannersList /> */}
                        <p style={isDesktop ? {paddingTop : 0} : {}} className="experts_title_section experts_online">Преимущества платформы</p>
                        <div className='experts_advantages_main'>
                            <ExpertsAdvatages style={{height : '64px', width : 'fit-content', minWidth : '100%'}} isExperts/>
                        </div>
                        <ExpertsReviews />
                        <BestExperts setSelectedProfile={props.setSelectedProfile} bestExperts={props.bestExperts} />

                        <HowItWorks />
                        <div style={isDesktop ? {paddingTop : '40px'} : {}} className="experts_title_section">Когда sozvon пригодится</div>
                        <ExpertsInfo setDialogInfo={setDialogInfo}/>
                    </div>
                    <SubjectsDesktopList consultants={props.consultants} specializations={props.specializations}/>
                    {/* <iframe width='500' height='500' src={"https://desk.sozvon.pro/boards/6570b951e2b54d1c95e0464d" + "?token=" + getCookie("token")}/> */}
                </div>                
            </div>
            <PopularDetalizationDialog info={dialogInfo} setDialogInfo={setDialogInfo}/>
            <MobilePopularDialog info={mobilePopularDiaog} setDialogInfo={setMobilePopularDiaog}/>
            <HowItWorksDialog info={howItWorksDialog} setDialogInfo={setHowItWorksDialog}/>
            {(user.count_rub != undefined && !getIsDesktop()) &&  <div style={{position : 'fixed', bottom : '64px', background : '#2a2a2a'}} onClick={() => window.location.href = Pages.pay} className="chat_message_price_cont center_horizontal">
                <p className="chat_message_price_balance">Баланс {parseBalance(user)}₽</p>
            </div>}
            {isShowAvatarTooltip && <FirstAvatarTooltip isShowTgBanner={isShowTgBanner} setShow={closeAvatarTooltip}/>}

            {isShowBalanceTooltip && <BalanceTooltip setShow={setShowBalanceTooltip}/>}
            {/* <CallBGPickerDialog isShow setShow={() => {}}/> */}
            <FooterRequesities />
        </div>
        
    );
}

export default Experts;
