import React, { useState, useRef } from 'react';
import Loader from '../../custom_views/Loader';
import { UploadApi } from '../../api/UploadApi';
import 'react-image-crop/dist/ReactCrop.css'
import ImageCropper from './custom_views/ImageCropper';
import UploadAvatar from './custom_views/UploadAvatar';

function Step2(props) {
    const [avatarUrl, setAvatarUrl] = useState(props.profileInfo.avatar ? props.profileInfo.avatar : undefined)
    const next = () => {
        props.saveStep2(avatarUrl)
        props.setStep(3)
    }
    return (
        <div>
            <div className="profile_avatar_filling_margin">
                <UploadAvatar avatarUrl={avatarUrl} setAvatarUrl={setAvatarUrl}/>
            </div>
            
            <p className="profile_fill_start_desc">Профили с хорошей фотографией вызывают больше доверия. Фото можно добавить потом</p>
            <button onClick={next} className="fill_button hover profile_bottom_button">Далее</button>           
        </div>
    );
}

export default Step2;
