/* eslint-disable eqeqeq */
import { useEffect, useRef } from 'react';
import arrow_right from '../../../img/create_ads/arrow_right.svg'
import gift from '../../../img/create_ads/gift.svg'
import CreateAdsHeader from './custom_views/CreateAdsHeader';
import { useHistory } from "react-router-dom";
import { checkButtonDisabled } from './helpers/CreateAdsHelper';
function CreateAdsStep1(props) {
    const history = useHistory()
    const inputRef = useRef(null)

    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const onNext = () => {
        history.push("/create_ads/step_2")
    }
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_gift">
                        <img alt="" src={gift}/>
                        <div className="create_ads_gift_title">Создайте объявление на поиск онлайн-эксперта и получите <b>бонусные 500₽</b> на первое занятие.</div>
                    </div>
                    <div className="create_ads_step_title">Название вашего объявления</div>
                    <div style={{marginTop : '24px', color : 'black', opacity : 1}} className="create_ads_light_title">Занятия проводятся только онлайн, через нашу платформу</div>
                    
                    <input ref={inputRef} className="create_ads_input" placeholder="Введите название" type="text" value={props.name} onChange={(e) => props.setName(e.target.value)}/>
                    <p className="create_ads_step_desc">Например, подготовить к ЕГЭ по математике</p>
                </div>
                <div className="create_ads_buttons">
                    <div style={checkButtonDisabled(props.name.length == 0)} onClick={onNext} className="create_ads_btn hover">Далее <img alt="" className="create_ads_arrow_right" src={arrow_right}/></div>
                </div>
            </div>
        </div>
    );
}

export default CreateAdsStep1;
