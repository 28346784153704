/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import ProfileInputRaw from './custom_views/ProfileInputRaw';
import AuthErrorTooltip from '../auth/AuthErrorTooltip';
import moment from 'moment'
import autosize from 'autosize';
import { CSSTransition } from 'react-transition-group';
import arrow_down from '../../img/chat/arrow_down.svg'
import SexTooltip from './custom_views/SexTooltip';
import FilterDropdown from '../../custom_views/FilterDropdown';
import { Pages } from '../../utils/Utils';
import { ConfigApi } from '../../api/ConfigApi';

function Step1(props) {
    const [firstName, setFirstName] = useState(props.profileInfo.name ? props.profileInfo.name : "")
    const [lastName, setLastName] = useState(props.profileInfo.surname ? props.profileInfo.surname : "")
    const [bday, setBday] = useState(props.profileInfo.birthday ? props.profileInfo.birthday : "")

    const [city, setCity] = useState(props.profileInfo.city ? props.profileInfo.city : "")
    const [allCities, setAllCities] = useState([])

    const [country, setCountry] = useState(props.profileInfo.country ? props.profileInfo.country : "")
    const [allCountries, setAllCountries] = useState([])
    const [isCountryPicked, setCountryPicked] = useState(false)

    const [errorText, setErrorText] = useState("")
    const [isShowSex, setShowSex] = useState(false)
    const [sex, setSex] = useState(props.profileInfo.sex != undefined ? props.profileInfo.sex == 2 ? "Мужской" : "Женский" : "Мужской")
    const [email, setEmail] = useState(props.profileInfo.email ? props.profileInfo.email : "")

    const refTextarea = useRef(null)
    useEffect(() => {
        refTextarea.current.focus()
        autosize(refTextarea.current)
        getCountries()
    }, [])
    const getCountries = () => {
        ConfigApi.getAllCountries((responseData) => {
            var countries = []
            responseData.countries.forEach(element => {
                countries.push(element.title)
            })
            setAllCountries(countries)
        })
        ConfigApi.getAllRussianCities((responseData) => {
            var cities = []
            responseData.cities.forEach(element => {
                cities.push(element.city)
            })
            setAllCities(cities)
        })
    }
    const bdayChanged = (text) => {
        if (text.length == 2 || text.length == 5){
            if (bday.length > text.length){
                setBday(text)
            } else {
                setBday(text + ".")
            }
            
            setErrorText("")
        } else if (text.length > 10){
            const date = text.slice(0, 10)
            setBday(date)
            checkDate(date)
        } else {
            setErrorText("")
            setBday(text)
            if (text.length == 10){
                checkDate(text)
            }
        } 
    }
    const checkDate = (dateText) => {
        var ageDifMs = Date.now() - moment(dateText, "DD.MM.YYYY").unix()
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        const years = Math.abs(ageDate.getUTCFullYear() - new Date(moment(dateText, "DD.MM.YYYY")).getUTCFullYear())
        if (years > 99){
            setErrorText("Вам больше 100 лет?")
        } else if (years < 18){
            setErrorText("Пользоваться сервисом можно только совершеннолетним")
        } else {
            setErrorText("")
        }
    }
    var filteredCities = allCities.filter(c => (city.length > 0 && (country == "RU" || country == "" || country.toLowerCase() == "россия")) ? (c != city && c.toLowerCase().includes(city.toLowerCase())) : false)
    var filteredCountries = allCountries.filter(c => isCountryPicked ? false : country.length > 0 ? (c != country && c.toLowerCase().includes(country.toLowerCase())) : false)
    const onCountryTextChange = (c) => {
        setCountry(c)
        setTimeout(function() {
            setCountryPicked(false)
        }, 100)
        
    }
    const onCountryPicked = (picked) => {
        filteredCountries = []
        setCountry(picked)
        setCountryPicked(true)
    }
    const onCityPicked = (picked) => {
        filteredCities = []
        setCity(picked)
    }
    const setPickedSex = (type) => {
        setShowSex(false)
        setSex(type == 0 ? "Мужской" : "Женский")
    }
    //const isEnableButton = firstName.length > 1 && lastName.length > 1 && errorText == "" && bday != undefined && bday.length == 10
    const isEnableButton = firstName.length > 1 && lastName.length > 1 && errorText == ""

    
    return (
        <div>
           <p className="profile_fill_start_title title_step">Представьтесь,<br/>пожалуйста</p>
           <p className="profile_fill_start_desc">Это общая информация, которая облегчит общение с собеседником</p>
           <p className="nessesary_fields">* — поля обязательные для заполнения</p>
           <ProfileInputRaw innerRef={refTextarea} title="Имя *" value={firstName} setValue={setFirstName}/>
           <ProfileInputRaw title="Фамилия *" value={lastName} setValue={setLastName}/>
           <div className='relative'>
                <ProfileInputRaw type={"country"} title="Страна" value={country} setValue={onCountryTextChange}/>
                {filteredCountries.length > 0 && <FilterDropdown isHideTooltipBg onCloseFilters={() => {}} style={{margin : '0px 0px 0px -2px'}} filters={filteredCountries} onFilterPicked={onCountryPicked}/>}
            </div>
            <div className='relative'>
                <ProfileInputRaw type={"city"} title="Город" value={city} setValue={setCity}/>
                {filteredCities.length > 0 && <FilterDropdown isHideTooltipBg onCloseFilters={() => {}} style={{margin : '0px 0px 0px -2px'}} filters={filteredCities} onFilterPicked={onCityPicked}/>}
            </div>
           <ProfileInputRaw placeholder="В формате 11.11.1991" title="Дата рождения" value={bday} setValue={bdayChanged}/>
           <CSSTransition in={errorText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <AuthErrorTooltip style={{margin : 0, width : '100%'}} text={errorText}/>
           </CSSTransition>
           <div className="relative input_raw_container">
                <div onClick={() => setShowSex(!isShowSex)} className="input_raw_container_p hover">
                    <p className="profile_input_title">Пол</p>
                    <div className="flex">
                        <div className="sex_text">{sex}</div>
                        <img alt='' className="arrow_sex" src={arrow_down}/>
                    </div>
                </div>
                <div className="fill_navbar_line"/>
                {isShowSex && <SexTooltip setShow={setShowSex} setSex={setPickedSex}/>}
            </div>
            
            <ProfileInputRaw type={"email"} title="Email" value={email} setValue={setEmail}/>

            <div className='profile_fill_email_desc'>На эту почту будут приходить важные уведомления и напоминания</div>
            <div style={{margin : '12px 0'}}>
                <a target='_blank' rel="noreferrer" href={Pages.privacy_policy} className='profile_fill_privacy hover'>Политика конфиденциальности</a>
            </div>
            <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep1(firstName, lastName, bday, sex == "Мужской" ? 2 : 1, email, city, country)} className="fill_button hover profile_bottom_button">Далее</button>
        </div>
    );
}

export default Step1;
