import { useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Pages, getRequestUrl } from '../../../utils/Utils';
import attach_plan from '../../../img/chat/attach_plan.svg'
import attach_doc from '../../../img/chat/attach_doc.svg'
import attach_image from '../../../img/chat/attach_image.svg'
import attach_desk from '../../../img/chat/attach_desk.svg'
import { getCookie } from '../../../utils/CookieHelper';
import { DialogsApi } from '../../../api/DialogsApi';
import moment from 'moment';
var img = new Image; 
var fr = new FileReader;
var pickedPhoto = {}

var frFile = new FileReader;
var pickedFile = {}
function AttachTooltip(props) {
    const imageUpload = useRef(null)
    const fileUpload = useRef(null)
    const to_user = props.to_user
    const history = useHistory()
    const setErrorSize = props.setErrorSize
    const fromCall = props.fromCall
    const videoStreams = props.videoStreams
    console.log("AttachTooltip")
    const planCall = () => {
        history.push(Pages.create_planner + "?id=" + props.to_user.vk_caller_id)
        props.setShow(false)
    }
    const onImage = () => {
        imageUpload.current.click()
    }
    const onDoc = () => {
        fileUpload.current.click()
    }
    const onDesk = () => {
        props.setShowAttachDesk(true)
        props.setShow(false)
    }

    const imageChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            preloadPhoto(event.target.files[0])
        }
    }
    const fileChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            preloadFile(event.target.files[0])
        }
    }
    const preloadFile = (file) => {
        pickedFile = file
        frFile.onload = uploadPickedFile
        frFile.readAsDataURL(file)
    }
    const showFileUploader = () => {
        if (props.setFileUploading){
            props.setFileUploading(true)
        }
    }
    const hideFileUploader = () => {
        if (props.setFileUploading){
            props.setFileUploading(false)
        }
    }
    const uploadPickedFile = () => {
        props.setShow(false)
        if (pickedFile.size > 10000000){
            setErrorSize("Размер файла не должен превышать 10Mb")
            return
        }
        setErrorSize("")
        showFileUploader()
        var headers = {
            access_token: getCookie("token")
        }        
        const params = {
            method: "POST",
            body: pickedFile,
            headers: headers,
        }

        fetch(getRequestUrl() + "uploadMedia?name=" + pickedFile.name, params).then((response) => response.json())
                    .then((responseData) => {
                        if (responseData.response > 0 && responseData.url){
                            const unixtime = moment().unix()
                            if (videoStreams && videoStreams.length > 0){
                                for (let index = 0; index < videoStreams.length; index++) {
                                    const id = videoStreams[index].user.id;
                                    DialogsApi.sendMessage("", id, undefined, (responseData) => {}, () => {}, responseData.url, unixtime)
                                }
                            
                            } else {
                                 sendMessage(responseData.url)
                            }
                        }
                        console.log("uploadPickedFile responseData = ", responseData)
                        hideFileUploader()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
    }
    const preloadPhoto = (file) => {
        //setLoading(true)
        pickedPhoto = file
        fr.onload = onLoadedPhotoReader
        fr.readAsDataURL(file)
    }
    const onLoadedPhotoReader = () => {
        console.log("onLoadedPhotoReader")
        img.onload = uploadPhoto
        img.src = fr.result
    }
   
    const uploadPhoto = () => {
        props.setShow(false)
        if (pickedPhoto.size > 10000000){
            setErrorSize("Размер файла не должен превышать 10Mb")
            return
        }
        setErrorSize("")
        const params = {
            method: "POST",
            body: pickedPhoto,
            headers: {
                access_token: getCookie("token"),
                width : img.width,
                height : img.height
                // name : pickedPhoto.name
            },
        }
        console.log("uploadPhoto params = ", params)
        fetch(getRequestUrl() + "uploadMedia?name=" + pickedPhoto.name, params).then((response) => response.json())
                    .then((responseData) => {
                        if (responseData.response > 0 && responseData.url){
                            if (videoStreams && videoStreams.length > 0){
                                const unixtime = moment().unix()
                                for (let index = 0; index < videoStreams.length; index++) {
                                    const id = videoStreams[index].user.id;
                                    DialogsApi.sendMessage("", id, undefined, (responseData) => {}, () => {}, responseData.url, unixtime)
                                }
                            
                            } else {
                                sendMessage(responseData.url)
                            }
                        }
                        console.log("uploadMedia responseData = ", responseData)
                    })
                    .catch((error) => {
                        console.error(error);
                });
    }
    const sendMessage = (url) => {
        //props.onMessageSentWithAttach(url)
        DialogsApi.sendMessage("", to_user.vk_caller_id, undefined, (responseData) => {
            
        }, () => {}, url)
    }
    
    return (
        <div className="more_tooltip_profile_bg">
            <input id="imageInput" className="custom-file-input hover" type="file" accept="image/jpg, image/jpeg, image/png" onChange={imageChangedHandler} style={{ display: "none" }} ref={imageUpload}/>
            <input id="fileUpload" className="custom-file-input hover" type="file" accept="file/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>

            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div style={props.isChatOpenedFromCalls ? {right : '120px', left : 'auto'} : fromCall ? {right : '150px', left : 'auto'} : {}} className="chat_attach_container">
                <div onClick={onImage}  className="flex hover">
                    <img alt="" src={attach_image}/>
                    <p className="more_tooltip_profile_text">Изображение</p>
                </div>
                <div onClick={onDoc}  className="flex hover">
                    <img alt='' src={attach_doc}/>
                    <p className="more_tooltip_profile_text">Файл</p>
                </div>
                {!fromCall && <div onClick={planCall}  className="flex hover">
                    <img alt='' src={attach_plan}/>
                    <p className="more_tooltip_profile_text">Запланировать созвон</p>
                </div>}
                <div onClick={onDesk}  className="flex hover">
                    <img alt='' src={attach_desk}/>
                    <p className="more_tooltip_profile_text">Интерактивную доску</p>
                </div>
            </div>

        </div>
    );
}

export default AttachTooltip;
