/* eslint-disable eqeqeq */
import React, { useState } from "react";
import calendar_left from '../../../img/planner/calendar_left.svg'
import calendar_right from '../../../img/planner/calendar_right.svg'
import calendar_today from '../../../img/planner/calendar_today.svg'
import moment from 'moment'

const CalendarPlanner = (props) => {
    const [date, setDate] = useState(new Date());
    const plannedEvents = [3, 7, 10]; // Пример запланированных дней
    const planners = props.planners
    const selectedDate = props.selectedDate
    const handlePrevMonth = () => {
        const prevMonth = new Date(date);
        prevMonth.setMonth(prevMonth.getMonth() - 1);
        setDate(prevMonth);
    };

    const handleNextMonth = () => {
        const nextMonth = new Date(date);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        setDate(nextMonth);
    };

    const monthOptions = {
        month: "long"
    };

    const today = new Date();
    const daysInMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
    ).getDate();
    const firstDayOfMonth = new Date(
        date.getFullYear(),
        date.getMonth(),
        0
    ).getDay();

    const days = [];
    for (let i = 1; i <= daysInMonth + firstDayOfMonth; i++) {
        if (i > firstDayOfMonth) {
            days.push(i - firstDayOfMonth);
        } else {
            days.push("");
        }
    }
    const goToToday = () => {
        setDate(new Date());
    };
    const isShowPlannedDay = (date, dateMonth) => {
        var isShow = false
        planners.forEach(element => {
            const plannedDate = moment(element.sozvon_date, "YYYY-MM-DD") 
            // console.log("plannedDate = ", plannedDate.month)
            // console.log("dateMonth = ", dateMonth.getMonth())
            // console.log("date = ", date)
            if (dateMonth.getMonth() === plannedDate.month() && plannedDate.date() == date){
                isShow = true
            }
        });
        return isShow
    }
    const setSelectedDate = (day) => {
        props.setSelectedDate(moment().set({'month': date.getMonth(), 'date' : day}).toDate())
    }
    console.log("days = ", days)
    return (
        <div className="calendar-container">
            <div className="calendar-container_header">
                <div className="calendar_month">{date.toLocaleDateString("ru-RU", monthOptions)}</div>
                <div className="flex">
                    <img alt='' onClick={handlePrevMonth} className="calendar_icons hover" src={calendar_left}/>
                    <img alt='' onClick={goToToday} className="calendar_icons hover" src={calendar_today}/>
                    <img alt='' onClick={handleNextMonth} className="calendar_icons hover" src={calendar_right}/>
                </div>
            </div>
            
            <div className="calendar">
                <div className="calendar-header">
                
                </div>
                <div className="calendar-grid">
                {["пн", "вт", "ср", "чт", "пт", "сб", "вс"].map((day) => (
                    <div key={day} className="calendar-day">
                    {day}
                    </div>
                ))}
                {days.map((day, index) => (
                    <div onClick={() => setSelectedDate(day)} key={index} style={(selectedDate != undefined && day === selectedDate.getDate() && date.getMonth() === selectedDate.getMonth()) ? {background : 'var(--emerald)', borderRadius : '4px', height : '24px', width : '24px', margin : '3px 4px 0 7px'} : {}} className={`calendar-cell ${day === "" ? "empty" : ""} 
                        ${
                            (day < today.getDate() && date.getMonth() === today.getMonth()) ? "calendar_past_day" :
                            day === today.getDate() && date.getMonth() === today.getMonth() ?
                            "calendar_today" : ""
                        }`}>

                        {day === today.getDate() && date.getMonth() === today.getMonth()
                            ? <div className="calendar_today_border"></div>
                            : ""}
                        {isShowPlannedDay(day, date) && <div className="calendar_planned_day"></div>}
       
                        {day}
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
};

export default CalendarPlanner;