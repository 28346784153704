/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState, useEffect } from 'react';
import { Pages, getIsDesktop, getUserAvatar, declension, getUrlParameter, getDomainUrl } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import close_chat from '../../img/chat/close_chat.svg'
import { useHistory } from "react-router-dom";
import { PlannerApi } from '../../api/PlannerApi';
import Loader from '../../custom_views/Loader';
import question from '../../img/planner/question.svg'
import planner_notif from '../../img/planner/planner_notif.svg'
import calendar_today from '../../img/planner/calendar_today.svg'
import moment from 'moment';
import ReadyTimer from './custom_views/ReadyTime';
import { getCookie, isUserAuthed } from '../../utils/CookieHelper';
import { secondsToTimeGroupCall } from './utils/PlannerUtils';
import planner_close from '../../img/planner/planner_close.svg'
import planner_info_group from '../../img/planner/planner_info_group.svg'
import planner_price from '../../img/planner/planner_price.svg'
import planner_info_copy from '../../img/planner/planner_info_copy.svg'
import planner_info_copy_start from '../../img/planner/planner_info_copy_start.svg'
import { copyText } from '../blog/utils/BlogUtils';
import Toast from '../profile/custom_views/Toast';
import { CSSTransition } from 'react-transition-group';
import CancelVideoCallDialog from './dialogs/CancelVideoCallDialog';
import AboutMoneyVideoCallDialog from './dialogs/AboutMoneyVideoCallDialog';
import NotAuthedDialog from '../profile/dialogs/NotAuthedDialog';
import PlannedGroupInfoDescription from './custom_views/PlannedGroupInfoDescription';
import GroupCallDescriptionDialog from './dialogs/GroupCallDescriptionDialog';
import AboutMaxVideoCallDialog from './dialogs/AboutMaxVideoCallDialog';
import GroupCalParticipantsDialog from './dialogs/GroupCallParticipantsDialog';
import views from '../../img/profile/views.svg'
import edit_planner from '../../img/planner/edit_planner.svg'


function GroupCallInfo(props) {
    const [isLoading, setLoading] = useState(true)
    const [isReadyLoading, setReadyLoading] = useState(false)
    const [planner, setPlanner] = useState({})
    const [isReady, setReady] = useState(false)
    const [isShowAboutMoney, setShowAboutMoney] = useState(false)
    const [isShowAboutMax, setShowAboutMax] = useState(false)
    const [secondsToStart, setSecondsToStart] = useState(9999)
    const [isShowCancel, setShowCancel] = useState(false)
    const [isCanceled, setCanceled] = useState(false)
    const [toastText, setToastText] = useState("")
    const [isShowNotAuthDialog, setShowNotAuthDialog] = useState(false)
    const [isShowDescDialog, setShowDescDialog] = useState(false)
    const [isShowParticipantsDialog, setShowParticipantsDialog] = useState(false)

    const getGroupCallInfo = () => {
        var id = ""
        const slashes = window.location.pathname.split("/")
        if (slashes.length > 1){
            id = slashes[2]
        }
        PlannerApi.getSozvonByLinkById({"_id" : id}, (responseData) => {
            setLoading(false)
            var plannerResponse = responseData.planner
            plannerResponse.accepted_users = responseData.accepted_users
            setPlanner(plannerResponse)
            setCanceled(plannerResponse.canceled)

            const writeReviewTime = getUrlParameter("is_write_review_time")
            if (writeReviewTime != null && writeReviewTime != undefined && writeReviewTime != ""){
                props.setWriteReview({isShow : true, price : plannerResponse.price, time : writeReviewTime , to_id : plannerResponse.creator_vk_caller_id})
            }
        }, (responseData) => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getGroupCallInfo()
      
    }, [])

    const history = useHistory()
    const isDesktop = getIsDesktop()
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.lk
        } else {
            history.goBack()
        }
    }
    const onEdit = () => {
        history.push(Pages.create_video_meeting + '?edit_id=' + planner._id)
    }
    const onCancel = () => {
        //setShowCancel(!isShowCancel)
        //showToast("Недостаточно денег")
        PlannerApi.cancelSozvonByLink(planner._id, "", (responseData) => {
            getGroupCallInfo()
        }, (responseData) => {
            showToast(responseData.message)
        })
        
    }
   
    const onTimer = (seconds) => {
        if (!isReady && seconds <= 300){
            setReady(true)
        }
        
        setSecondsToStart(seconds)
    }
   
    const openCall = () => {
        history.push(Pages.group_call + "?conv_token=" + planner.token_room + "&id=" + planner._id)
    }
    const onPlannerCanceled = () => {
        getGroupCallInfo()
    }
    var date = ""
    var datePlusHour = ""
    var time = ""
    var isHourFinished = secondsToStart < -3600 * 5
    if (planner.sozvon_date != undefined){
        date = moment(planner.sozvon_date).utc().local()
        datePlusHour = moment(planner.sozvon_date_end).utc().local()
        time = date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM, dd")
    }
   
    const accept = () => {
        if (props.user.count_rub < planner.price){
            window.open(Pages.pay + "?pay=1&balance=" + Math.ceil(planner.price - props.user.count_rub), '_blank')
            return
        }
        if (!isUserAuthed()){
            setShowNotAuthDialog(true)
            return
        }
        setReadyLoading(true)
        PlannerApi.acceptSozvonByLink({ _id : planner._id}, (responseData) => {
            setReadyLoading(false)
            var myPlanner = {...planner}
            var accepted = myPlanner.client_vk_caller_ids_accepted 
            accepted.push(getCookie("id"))
            myPlanner.client_vk_caller_ids_accepted = accepted
            setPlanner(myPlanner)
            // if (responseData.response < 1){
            //     showToast(responseData.message)
            // }
            props.getMyInfo()

        }, (responseData) => {
            setReadyLoading(false)
            showToast(responseData.message)
        })
        
    }
    // console.log("secondsToStart = ", secondsToStart)
    // const getEmptyHtml = () => {
    //     return(
    //         <div className="empty_profile_cont">
    //             <p className="empty_profile_title">Запланированная видеовстреча</p>
    //             <NotAuthed/>
    //         </div>
            
    //     )
    // }
  
    const getTimerHtml = () => {
        return(
            <div>
                <div className="planner_time_to_start_line"></div>
                <div className="planner_time_to_start_title">{'Время ' + (secondsToStart < 0 ? 'от' : 'до') + ' начала созвона'}</div>
                <div className="planner_time_to_start">{secondsToTimeGroupCall(secondsToStart)}</div>
            </div>
        )
    }
     const getDescriptionHTML = () => {
        if (planner.sozvon_description == undefined || planner.sozvon_description == ""){
            return null
        }
        return(
            <PlannedGroupInfoDescription onOpen={() => setShowDescDialog(true)} text={planner.sozvon_description}/>
        )
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const shareUrl = getDomainUrl() + Pages.group_call_info + "/" + planner._id

    const copyTextClick =() => {
        showToast("Ссылка скопирована")
        copyText(shareUrl)
    }
    const is_i_creator = planner.creator ? planner.creator.vk_caller_id == getCookie("id") : false
    const is_accepted = (!is_i_creator && planner.client_vk_caller_ids_accepted && planner.client_vk_caller_ids_accepted.includes(getCookie("id")))
    return (
        <div style={isDesktop ? getDesktopStyle() : {position : 'block', zIndex : '2', background : '#F1F5FD'}} className="selected_settings create_planner_bg">
            
            <div className="create_ads_step">
                <div className="create_planner_toolbar">
                        {isDesktop && <p className="theme_title planner_title">{isDesktop ? "Запланированная видеовстреча" : ""}</p>}
                        {!isDesktop ? 
                            <div style={{display : 'flex', width : '100%', justifyContent : (is_i_creator) ? 'space-between' : 'right'}}>
                                <div onClick={onBack} className="planner_close_cont hover">
                                    <img alt="" className="planner_close_img hover" src={close_chat}/>
                                </div>
                                {is_i_creator && <div onClick={onEdit} className="planner_close_cont hover">
                                    <img alt='edit' className='planner_close_img hover' src={edit_planner}/>
                                </div>}
                            </div>
                            : 
                            <img alt="" onClick={onBack}  className="planner_close_desktop hover" src={planner_close}/>
                        }

                </div>
                {/* <div className="planned_planner_header">
                    <div className="flex">
                        <div className="arrow_left_theme">
                            <div className="flex">
                                <div onClick={onBack} className="planner_close_cont hover">
                                    <img className="planner_close_img hover" src={close_chat}/>
                                </div>
                                <p className="theme_title"></p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {(isLoading) ? <Loader /> : 
                <div>
                    <div className="planned_picked_planner_cont">
                        <div className='justify-content'>
                            <div className="planned_info_title">{planner.sozvon_name}</div>
                            {(is_i_creator && isDesktop) && <div onClick={onEdit} style={{margin : '-4px 0px 0 0 '}} className="planner_close_cont hover">
                                <img alt='edit' className='planner_close_img hover' src={edit_planner}/>
                            </div>}
                        </div>
                        {getDescriptionHTML()}
                        <a rel="noreferrer" href={Pages.users + "/id" + planner.creator._id} target="_blank"><div className="flex hover">
                            <img alt="" src={getUserAvatar(planner.creator)} className="planned_item_avatar planned_picked_planner_avat"/>
                            <div>
                                <div className="planned_picked_planner_name">{planner.creator.name}</div>
                                <div className="planned_picked_planner_client">{"Эксперт"}</div>
                            </div>
                        </div></a>
                        <div onClick={copyTextClick} style={{width : '100%'}} className="planner_video_just_cont hover">
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img" src={planner_info_copy_start}/>
                                <div className="planned_picked_planner_time planner_text_overflow">{shareUrl}</div>
                            </div>
                            <img alt="" className="planner_info_copy_icon" src={planner_info_copy}/>
                        </div>
                        <div>
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img planner_opacity" src={calendar_today}/>
                                <div className="planned_picked_planner_time planner_opacity">{time}</div>
                            </div>
                            <div className='planner_video_just_cont'>
                                <div className="flex">
                                    <img alt="" className="planned_picked_planner_img" src={planner_price}/>
                                    <div className="planned_picked_planner_time planner_opacity">{planner.price}₽ с человека · {(planner.price ? planner.price : 0) * planner.client_vk_caller_ids_accepted.length}₽</div>
                                </div>
                                {is_i_creator && <img onClick={() => setShowAboutMoney(true)} alt="" className='planner_video_question hover' src={question}/>}
                            </div>
                            <div className='planner_video_just_cont'>
                                <div className="flex">
                                    <img alt="" className="planned_picked_planner_img" src={planner_info_group}/>
                                    <div className="planned_picked_planner_time"><span onClick={planner.client_vk_caller_ids_accepted.length > 0 ? () => setShowParticipantsDialog(true) : undefined} className={planner.client_vk_caller_ids_accepted.length > 0 ? 'hover planned_praticipants' : 'planner_opacity'}>{planner.client_vk_caller_ids_accepted.length + " " + declension(planner.client_vk_caller_ids_accepted.length, "человек", "человека", "человек")}</span><span className='planner_opacity'> · {planner.max_count_persons ? ("максимум " + planner.max_count_persons) : ""}</span></div>
                                </div>
                                <img onClick={() => setShowAboutMax(true)} alt="" className='planner_video_question hover' src={question}/>
                            </div>
                            {planner.reminder.length > 0 && planner.reminder.map((item, index) => (
                                <div className="flex planner_opacity" index={index} key={index}>
                                    <img alt="" className="planned_picked_planner_img" src={planner_notif}/>
                                    <div className="planned_picked_planner_time">{moment.unix(item/1000).local().format("HH:mm · DD MMMM, dd")}</div>
                                </div>
                            ))}
                            
                        </div>
                        
                        {(isHourFinished || !planner.is_open) ? 
                            <div className="planner_canceled">{!planner.is_open ? "Видеовстреча отменена" : "Видеовстреча завершена"}</div>
                        :
                        <div>
                            {secondsToStart <= 300 && getTimerHtml()}
                          
                            {(is_accepted || is_i_creator) && <div style={secondsToStart <= 300 ? {} : {opacity : 0.2, pointerEvents : 'none'}} onClick={openCall} className="chat_planner_accept chat_lowercased hover">
                                {is_i_creator ? "Начать" : "Подключиться"}
                            </div>}
                            {/* {(is_accepted || is_i_creator) && <div onClick={openCall} className="chat_planner_accept chat_lowercased hover">
                                {is_i_creator ? "Начать" : "Подключиться"}
                            </div>} */}
                            {(!is_i_creator && !is_accepted && planner.client_vk_caller_ids_accepted.length < planner.max_count_persons) && <div onClick={accept} className="chat_planner_accept chat_lowercased hover">
                                {isReadyLoading ? <Loader isWhite/> : planner.price == 0 ? "Принять участие" : "Оплатить участие"}
                            </div>}
                            {(planner.client_vk_caller_ids_accepted.length >= planner.max_count_persons && !is_accepted && !is_i_creator) && <div className='planner_group_info_full'>Все места на встречу заняты</div>}
                            {is_i_creator && <div onClick={copyTextClick} className="planner_share_video_btn hover">Поделиться ссылкой на видеовстречу</div>}
                            {(is_i_creator || is_accepted) && <div onClick={() => is_i_creator ? setShowCancel(true) : onCancel()} className="planned_picked_planner_cancel hover">{is_i_creator ? "Отменить видеовстречу" : "Отменить участие"}</div>}
                            {!is_i_creator && <div className="planner_video_confirm_desc">Для подтверждения участия в видеовстрече необходимо внести полную стоимость услуги. </div>}
                        </div>}
                        {(planner.views != undefined && planner.views > 0) && <div style={{margin : '16px auto 0 auto', justifyContent : 'center'}} className="flex relative">
                            <img alt='' className="profile_views_img" src={views}/>
                            <p className="profile_views_text">{planner.views}</p>
                        </div>}
                    </div>
                    <div onClick={() => window.open(Pages.planner_rules, '_blank')} style={{textAlign : 'center', marginTop : '24px', marginBottom : '64px'}} className="create_planner_how_it_works hover">Как это работает?</div>
                    <ReadyTimer isShowTimer startTime={moment(planner.sozvon_date).utc().local().unix()} onTimer={onTimer}/>
                    
                </div>}
                
            </div>
            <GroupCallDescriptionDialog text={planner.sozvon_description} name={planner.sozvon_name} setShow={setShowDescDialog} isShow={isShowDescDialog}/>
            <NotAuthedDialog setShow={setShowNotAuthDialog} isShow={isShowNotAuthDialog}/>
            <AboutMoneyVideoCallDialog setShow={setShowAboutMoney} isShow={isShowAboutMoney}/>
            <AboutMaxVideoCallDialog setShow={setShowAboutMax} isShow={isShowAboutMax}/>
            <CancelVideoCallDialog onPlannerCanceled={onPlannerCanceled} onSuccess={onPlannerCanceled} planner={planner} isShow={isShowCancel} setShow={setShowCancel}/>
            <GroupCalParticipantsDialog participants={planner.accepted_users} isShow={isShowParticipantsDialog} setShow={setShowParticipantsDialog}/>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
        
    );
}

export default GroupCallInfo;
