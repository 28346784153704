/* eslint-disable eqeqeq */
import '../../styles/auth.css';
import { useState, useEffect, useCallback } from 'react';
import { getRequestParams, setCookie, getCookie } from '../../utils/CookieHelper';
import { getUrlParameter, checkAfterAuth, isDateToday, Pages } from '../../utils/Utils';
import CodeView from './CodeView';
import PhoneView from './PhoneView';
import { useHistory, Route } from "react-router-dom";
import auth_bg_img from '../../img/auth_bg_img.svg'
import { UsersApi } from '../../api/UsersApi';
import AuthTypes from './AuthTypes';
import { AuthApi } from '../../api/AuthApi';


function Auth(props) {
    
    const [isLoading, setIsLoading] = useState(false);
    const [isShowCodeView, setShowCodeView] = useState(false);
    const [phoneNumber, setPhone] = useState("7");
    const [codeType, setCodeType] = useState("");
    const history = useHistory()
    const profile_id = getUrlParameter("profile_id")
    const toggleIsLoading = () => { setIsLoading(current => !current)}
    const toggleCodeView = () => { setShowCodeView(current => !current)}
    const openLink = useCallback((link) => {
        history.push(link)
    }, [history])

    useEffect(() => {
        const prev_url = getUrlParameter("prev_url")
        if (prev_url != null && prev_url != undefined && prev_url != ""){
            setCookie("prev_url", prev_url)
            if (window.location.href.includes("prev_url=/ads_board/shikari?id=")){
                setCookie("prev_url", window.location.href.split("prev_url=")[1])
            }
        }
    }, [])
    
    const getMyInfo = () => {
        UsersApi.getMyInfo((user) => {
            setCookie("vk_call_token", user.vk_call_token)
            setCookie("id", user.vk_caller_id)
            setCookie("was_authed", "was_authed")
            if ((user.is_repetitor == undefined || !user.is_repetitor) && !isDateToday(user.register_date)){
                window.location.href = (profile_id != undefined && profile_id != null && profile_id != "") ? ("/users/" + profile_id) : Pages.experts + (user.phone_number.includes("vk") ? "?is_vk=true" : "")
                return
            }
            if (user.is_repetitor == undefined || !user.is_repetitor){
                console.log("open profile_filling")
                window.location.href = (profile_id != undefined && profile_id != null && profile_id != "") ? ("/users/" + profile_id) : "/profile_filling/step_0" + (user.phone_number.includes("vk") ? "?is_vk=true" : "")
                return
            } 

            if (checkAfterAuth()){
                return
            }
            
            if (user.is_repetitor){
                window.location.href = Pages.experts
            }
        })
    }
    
    
    const registerUser = (promoCode) => {
        if (isLoading){
            return
        }
        
        setIsLoading(true)
        var params = getRequestParams()
        const savedPromoCode = getCookie("promocode")
        if (savedPromoCode != undefined && savedPromoCode != null && savedPromoCode != ""){
            params.promo_code = savedPromoCode
        } else if (promoCode != undefined && promoCode != ""){
            params.promo_code = promoCode
        }
        params.phone_number = phoneNumber.includes("+7") ? phoneNumber.replaceAll("+7", "7") : phoneNumber
        console.log("registerUser params =" + JSON.stringify(params))
        AuthApi.registerUser(params, (responseData) => {
            setShowCodeView(true)
            openLink("/auth/code")
            setCodeType(responseData.channelType)
            setIsLoading(false)
        }, () => {setIsLoading(false)})
        
    }
    return (
        
        <div className="auth auth_bg">
            <img alt="" className="auth_bg_img" src={auth_bg_img}/>
            <div className="auth_inner">
                <Route path={"/auth/phone"}>
                    <AuthTypes getMyInfo={getMyInfo}/>
                </Route>
                <Route path={"/auth/phone_type"}>
                    <PhoneView registerUser={registerUser} setCodeType={setCodeType} toggleCodeView={toggleCodeView} phoneNumber={phoneNumber} setPhone={setPhone} isLoading={isLoading} toggleIsLoading={toggleIsLoading}/>
                </Route>          
                <Route path={"/auth/code"}>
                    <CodeView registerUser={registerUser} setShowCodeView={setShowCodeView} codeType={codeType} getMyInfo={getMyInfo} isLoading={isLoading} toggleIsLoading={toggleIsLoading} phoneNumber={phoneNumber}/>
                </Route>
            </div>
            <img alt="" className="auth_bg_img" src={auth_bg_img}/>
        </div>
    );
}

export default Auth;
