import React from 'react';


function FooterRequesities() {
    
    return (
        <footer className="footer_requisites">
            <div>
                <div className='footer_requisites_line'/>
                <p>© 2024 sozvon.pro</p>
                <div className='footer_requisites_text'>
                    ИП Рабчанов В.Л., ОГРНИП<br/>317470400018418, ИНН<br/>471009214205
                </div>
            </div>
        </footer>
    )
}
export default FooterRequesities;
