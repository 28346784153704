import x_white from '../../../img/x_white.svg'
import money_transfer from '../../../img/pay/money_transfer.svg'
import { useHistory } from "react-router-dom";
import { getIsDesktop, parseBalance } from '../../../utils/Utils';

function PayHeader(props) {
    const user = props.user
    const history = useHistory()
    const onClose = () => {
        //window.location.href = '/'
        history.goBack()
    }
    const isDesktop = getIsDesktop()
    return (
        <div className="pay_header_desktop">
            <div className="pay_header">
                <div className="flex">
                    <div>
                        <p className="pay_balance_title">Ваш баланс</p>
                        <p className="pay_balance_count">{parseBalance(user)}₽</p>
                    </div>
                    {!isDesktop && <div onClick={onClose} className="review_close_cont hover">
                        <img alt='' className="review_close_icon" src={x_white}/>
                    </div>}
                </div>
                
            </div>
            {isDesktop ? 
            <div className="payout_plashka">
                <div onClick={() => props.setCurrentPage(2)} className="balance_btn_text up_balance_btn hover"><img alt='' className="pay_money_transfer" src={money_transfer}/> Пополнить баланс</div>
                <div style={{marginTop : '16px'}} onClick={() => props.setCurrentPage(1)} className="balance_btn_text hover">Вывести заработок</div>
            </div>
            :
            <div className="payout_plashka">
                <div onClick={() => props.setCurrentPage(1)} className="payout_plashka_text hover">Вывести<br/>заработок</div>
                <div onClick={() => props.setCurrentPage(2)} className="payout_plashka_text hover">Пополнить<br/>баланс</div>
            </div>}
        </div>
        
    );
}

export default PayHeader;
