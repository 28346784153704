/* eslint-disable eqeqeq */

import { useState } from 'react';
import { Link } from "react-router-dom";
import { Pages } from '../../../utils/Utils';

function SubjectsDesktopList(props) {
    const [isShowAllSpecs, setShowAllSpecs] = useState(false)
    const getOpenLink = (title, index, id) => {
        if (index == 9 && !isShowAllSpecs){
            return Pages.specializations + "?id=" + id
        } else {
            return Pages.search + "?text=" + title
        }
    }
    
    return (
        <div style={isShowAllSpecs ? {maxHeight : 'none'} : {}} className="subjects_desktop_container">
            <p className="subjects_title_item">Более 1 000 экспертов разных областей</p>
            <div className="subjects_desktop_flex">
                <div className="subjects_desktop_flex_item">
                    <p className="subjects_header_item">Репетиторы и преподаватели</p>
                    {props.specializations.length > 0 && props.specializations.map((tag, index) => (
                        (isShowAllSpecs || index <= 9) && 
                        <Link to={getOpenLink(tag, index, 0)} key={index}>
                            <div className="hover">
                                <p style={(index == 9 && !isShowAllSpecs) ? {color : "#31F8C9"} : {}} className="subject_landscape_list_item">{(index == 9 && !isShowAllSpecs) ? "И еще много других" : tag}</p>
                            </div>
                        </Link> 
                    ))}
                </div>

                <div className="subjects_desktop_flex_item">
                    <p className="subjects_header_item">Консультанты</p>
                    {props.consultants.length > 0 && props.consultants.map((tag, index) => (
                        (isShowAllSpecs || index <= 9) &&
                        <Link to={getOpenLink(tag, index, 1)} key={index}>
                            <div className="hover">
                                <p style={(index == 9 && !isShowAllSpecs) ? {color : "#31F8C9"} : {}} className="subject_landscape_list_item">{(index == 9 && !isShowAllSpecs) ? "И еще много других" : tag}</p>
                            </div>
                        </Link> 
                    ))}
                </div>

                {/* <div style={{marginLeft : '-32px'}} className="subjects_desktop_flex_item">
                    <p className="subjects_header_item">Знаменитости</p>
                    {selebs.length > 0 && selebs.map((tag, index) => (
                        <Link to={Pages.theme + "?title=Знаменитости"} key={index}>
                            <div className="hover" >
                                <p className="subject_landscape_list_item">{tag}</p>
                            </div>
                        </Link>
                    ))}
                </div> */}
            </div>
            
        </div>
    );
}

export default SubjectsDesktopList;

