/* eslint-disable eqeqeq */
import crown from '../../../img/chat/crown.svg'
import { useHistory } from "react-router-dom";
import React, {useRef} from 'react';
import { getIsDesktop, getUnreadCountMsgs, debounce, getUserAvatar } from '../../../utils/Utils';
import { getCookie } from '../../../utils/CookieHelper';
import Typing from '../custom_views/Typing';
function ChatItem(props) {
    const myId = getCookie("id")
    var countNoRead = getUnreadCountMsgs(props.chat)
    const isDesktop = getIsDesktop()
    const setServicesValueDebounced = useRef(debounce((id) => {
        props.removeIdsTyping(id)
    }, 3000))
    var text = props.chat.text
    if (props.chat.type == 1){
        text = props.chat.from_id == myId ? "Исходящий звонок" : "Входящий звонок"
    }
    const history = useHistory()
    const onChatClick = (item) => {
        props.setChat(item)
        const chat_id = item.from_id != getCookie("id") ? item.from_id : item.to_id
        if (!isDesktop){
            history.push("/chats/chat?id=" + chat_id)
        } else {
            window.history.replaceState(null, null, "/chats/chat?id=" + chat_id)
        }
        // console.log("chat item = ", item)
    }
    const id = props.chat.from_id == getCookie("id") ? props.chat.to_id : props.chat.from_id
    const isTyping = props.idsTyping.filter(item => item == id).length > 0
    if (isTyping){
        setServicesValueDebounced.current(id)
    }
    return (
        <div style={(props.isDesktop && props.currentChat !== undefined && props.currentChat._id === props.chat._id) ? {background: '#F1F5FD'} :{}} onClick={() => onChatClick(props.chat)} className="chat_item_container hover">
            <div className="flex chat_item_p">
                <div className="chat_item_avatar_img_cont">
                    <img src={getUserAvatar(props.chat.user)} className="chat_item_avatar" alt=""/>
                    {props.chat.user.online && <div className="round_msg_chat_item"></div>}
                    {props.chat.user.is_pro && <div className="chat_item_pro_icon"><img src={crown} alt=""/></div>}

                </div>
                
                <div className="chat_item_text">
                    <p className="chat_item_title">{props.chat.user.name} {props.chat.user.surname}</p>
                    {isTyping ? <div style={{margin : '4px 0 2px 0'}}><Typing /></div> : <p className="chat_item_description">{text}</p>}
                </div>
            </div>
            {(props.user !== undefined && countNoRead != null && countNoRead !== undefined && countNoRead !== 0) && <p style={{marginRight : '16px'}} className="chat_item_counter">{countNoRead}</p>}
        </div>
    );
}

export default ChatItem;
