import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyAJdDWQ4L6ZyqlnUl4dTehy2Uowqk7LaSA",
    authDomain: "vlearn-af7a8.firebaseapp.com",
    projectId: "vlearn-af7a8",
    storageBucket: "vlearn-af7a8.appspot.com",
    messagingSenderId: "42249686873",
    appId: "1:42249686873:web:a569191bb77f9714210648",
    measurementId: "G-WY2CJJG1ZZ"
}
//......
const app = initializeApp(firebaseConfig)
const messaging = getMessaging();
console.log("firebaseConfig initializeApp")
//......

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("foreground onMessageListener", payload)
//       resolve(payload);
//     });
//   });
