/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import contacts_vk from '../../../../img/create_ads/contacts_vk.svg'
import contacts_tg from '../../../../img/create_ads/contacts_tg.svg'
import contacts_phone from '../../../../img/create_ads/contacts_phone.svg'
import { AdsApi } from '../../../../api/AdsApi';


function ContactsDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const [isOpened, setOpened] = useState(false)
    const [vk, setVK] = useState({})
    const [tg, setTG] = useState({})
    const [phone, setPhone] = useState({})
    const isDesktop = getIsDesktop()
    const user = props.user
    const config = props.config
    const owner_user_id = props.owner_user_id
    const fromChat = props.fromChat
     useEffect(() => {
        if (props.isShow && !isOpened){
            AdsApi.checkContact(owner_user_id, (responseData) => {
             
                setTG({hasContact : responseData.tg != ""})
                setVK({hasContact : responseData.vk != ""})
                setPhone({hasContact : responseData.phone != ""})
              
            }, () => {})
        } else if (fromChat){
            setTG({hasContact : false})
            setVK({hasContact : false})
            setPhone({hasContact : false})
            setOpened(false)
        }
    }, [props.isShow])
  
    const isNotEnoughtMoney = (price) => {
        const countRub = user.count_rub == undefined ? 0 : Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
        const notEnoughtMoney = countRub < price
        return notEnoughtMoney
    }
    const cancel = () => {
        props.setShow(false)
    }
    const openContacts = () => {
        if (isLoading || isOpened){
            return false
        }
        if (isNotEnoughtMoney(config.PRICE_CONTACT)){
            setTimeout(function() {
                window.open(Pages.pay + '?pay=1&balance=100')
            }, 1500)
            
            props.showToast("Пополните баланс")
            cancel()
            return
        }
        setLoading(true)
        
        AdsApi.openContact(owner_user_id, (responseData) => {
            setLoading(false)
            props.getMyInfo()
            setOpened(true)
            if (responseData.tg != ""){
                setTG({hasContact : true, contact : responseData.tg})
            }
            if (responseData.vk != ""){
                setVK({hasContact : true, contact : responseData.vk})
            }
            if (responseData.phone != ""){
                setPhone({hasContact : true, contact : responseData.phone})
            }
            // if (responseData.telegram_username && responseData.telegram_username != ""){
            //     setTG({hasContact : true, contact : responseData.telegram_username})
            // }

        }, () => {setLoading(false)})
    }
   

    const getInnerHtml = () => {
        return(
            <div className="pro_dialog_container">
                <div style={{textAlign : 'left'}} className="ads_picked_shinkari_dialog_text">
                    <div className='ads_contacts_title'>Открыть контакт клиента</div>
                    <div className='ads_contacts_desc'>
                        <div>
                            С вашего баланса будет списано {config.PRICE_CONTACT}₽ за открытие данного контакта. 
                            <br/><br/>
                            Вы получите данные клиента:
                        </div>
                        <div  className='ads_contacts_info'>
                            {vk.hasContact && <div style={{marginBottom : '16px'}}>
                                <div className='ads_contacts_info_title'>Профиль ВК</div>
                                {vk.contact ? <a rel="noreferrer" target='_blank' href={'https://vk.com/id' + vk.contact}><div className='ads_contacts_info_contact hover'>{'vk.com/id' + vk.contact}</div></a> : <img alt='' src={contacts_vk}/>}
                            </div>}
                                
                            {tg.hasContact && <div style={{marginBottom : '16px'}}>
                                <div className='ads_contacts_info_title'>Профиль Telegram</div>
                                {tg.contact ? <a rel="noreferrer" target='_blank' href={'https://t.me/' + tg.contact}><div className='ads_contacts_info_contact hover'>{'t.me/' + tg.contact}</div></a> : <img alt='' src={contacts_tg}/>}
                            </div>}

                            {phone.hasContact &&<div>
                                <div className='ads_contacts_info_title'>Номер телефона</div>
                                {phone.contact ? <div onClick={() => window.open('tel:' + phone.contact, '_self')} className='ads_contacts_info_contact hover'>+{phone.contact}</div> : <img alt='' src={contacts_phone}/>}
                            </div>}
                        </div>
                        <div>
                            Вы можете использовать эти данные только для того, чтобы связаться с клиентом и предложить ему услуги по теме его объявления.
                        </div>
                    </div>
                    <div style={{marginTop : '36px'}} className="flex">
                        <div onClick={cancel} className="call_prices_edit hover">Отмена</div>
                        {!isOpened && <div onClick={openContacts} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : "Открыть"}</div>}
                    </div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return null
        }
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ContactsDialog;
