/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState, useRef, useEffect } from 'react';
import { Pages, getIsDesktop, getApiKey, getUrlParameter, getDomainUrl } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import close_chat from '../../img/chat/close_chat.svg'
import question from '../../img/planner/question.svg'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import PlannerSpecsPicked from './custom_views/PlannerSpecsPicker';
import PlannerNotifications from './custom_views/PlannerNotifications';
import { PlannerApi } from '../../api/PlannerApi';
import Loader from '../../custom_views/Loader';
import ErrorCreationDialog from './dialogs/ErrorCreationDialog';
import {DatePicker, CustomProvider} from 'rsuite'
import ruRu from 'rsuite/locales/ru_RU'
import isBefore from 'date-fns/isBefore';
import planner_close from '../../img/planner/planner_close.svg'
import * as SDK from '@vkontakte/calls-sdk'
import autosize from 'autosize';
import NotAuthedDialog from '../profile/dialogs/NotAuthedDialog';
import ShareGroupCallLinkDialog from './dialogs/ShareGroupCallLinkDialog';
import MaxClientsDialog from './dialogs/MaxClientsDialog';
import { disabledBtn } from '../blog/utils/BlogUtils';
import { isUserAuthed } from '../../utils/CookieHelper';

function CreateVideoMeeting(props) {
    const [pickedSpec, setPickedSpec] = useState("")
    const [description, setDescription] = useState("")
    const [price, setPrice] = useState(1000)
    const [maxClientsCount, setMaxClientsCount] = useState(99)
    const [isPlanOnFuture, setPlanOnFuture] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isAdsOnPlatform, setIsAdsOnPlatform] = useState(false)
    const [isSuccessCreated, setSuccessCreated] = useState(false)
    const [isShowMaxClientsDialog, setShowMaxClientsDialog] = useState(false)
    const [shareLink, setShareLink] = useState("")
    const [error, setError] = useState("")
    const [pickedNotifications, setPickedNotifications] = useState([86400000, 1800000])
    const [isShowNotAuthDialog, setShowNotAuthDialog] = useState(false)
    const [date, setDate] = useState({date : "", start_time : "", end_time : ""})
    const [plannerInfo, setPlannerInfo] = useState({})
    const history = useHistory()

    const isDesktop = getIsDesktop()
    const refTextarea = useRef(null)
    const edit_id = getUrlParameter("edit_id")
    useEffect(() => {
        autosize(refTextarea.current);
        if (!isUserAuthed()){
            setShowNotAuthDialog(true)
            return
        }
        getPlanner()
    }, [])
    const getPlanner = () => {
        PlannerApi.getSozvonByLinkById({"_id" : edit_id}, (responseData) => {
            setLoading(false)
            var plannerResponse = responseData.planner
            plannerResponse.accepted_users = responseData.accepted_users
            setPlannerInfo(plannerResponse)
            setPickedSpec(plannerResponse.sozvon_name)
            setDescription(plannerResponse.sozvon_description)
            setMaxClientsCount(plannerResponse.max_count_persons)
            setPlanOnFuture(true)
            setIsAdsOnPlatform(plannerResponse.ads_on_platform)
            
            var arr = []
            plannerResponse.reminder.forEach(element => {
                arr.push(moment(plannerResponse.sozvon_date).unix()*1000 - element)
            })
            setPickedNotifications(arr)
            setDate({date : moment(plannerResponse.sozvon_date).utc().local().toDate(), start_time : moment(plannerResponse.sozvon_date).utc().local().toDate(), end_time : moment(plannerResponse.sozvon_date_end).utc().local().toDate()})
            setPrice(plannerResponse.price)
        }, (responseData) => {
        })
    }
    const checkFilterDate = () => {
        const times = moment(date.start_time).format("HH:mm").split(":")
        const pickedDateMoment = moment(date.date, "YYYY-MM-DD").set("hour", times[0]).set("minute", times[1])
        return pickedDateMoment >= moment()
    }
    const isEnableButton = pickedSpec != "" && ((!isPlanOnFuture) || (date.date != undefined && date.date != "" && date.start_time != undefined && date.start_time != "" && date.end_time != undefined && date.end_time != "" && maxClientsCount > 0 && checkFilterDate()))
    const createPlanner = async () => {
       if (!isUserAuthed()){
            setShowNotAuthDialog(true)
            return
        }
        const currentDate = moment(new Date()).toDate()
        const startTime = isPlanOnFuture ? date.start_time : currentDate
        const endTime = isPlanOnFuture ? date.end_time : moment(currentDate).add(5,'hours').toDate()
        const dateDate = isPlanOnFuture ? date.date : currentDate

        const timesStart = startTime ? moment(startTime).format("HH:mm").split(":") : ""
        const timesEnd =  endTime ? moment(endTime).format("HH:mm").split(":") : ""
       
        const unixtimeStart = dateDate ? moment(dateDate, "YYYY-MM-DD").set("hour", timesStart[0]).set("minute", timesStart[1]).unix() * 1000 : ""
        const unixtimeEnd = dateDate ? moment(dateDate, "YYYY-MM-DD").set("hour", timesEnd[0]).set("minute", timesEnd[1]).unix() * 1000 : ""
       
        var params = {
            "sozvon_date": unixtimeStart,
            "sozvon_date_end": unixtimeEnd,
            "sozvon_name": pickedSpec,
            "token_room": "",
            "reminder": pickedNotifications.join(","),
            "price" : price
        }
        
        setLoading(true)
        const token_room = await getTokenRoom()
        if (!edit_id){
            params.token_room = token_room
        }
        // if (!isPlanOnFuture){
        //     params.sozvon_date = undefined
        //     params.sozvon_date_end = undefined
        //     params.reminder = undefined
        // }
        if (price == ""){
            params.price = 0
        }
        if (maxClientsCount != undefined && maxClientsCount != "" && maxClientsCount > 0 && isPlanOnFuture){
            params.max_count_persons = maxClientsCount
        } else {
            params.max_count_persons = 99
        }
        if (isAdsOnPlatform && isPlanOnFuture){
            params.ads_on_platform = isAdsOnPlatform
        }
        if (description != undefined && description != ""){
            params.sozvon_description = description
        }
        if (edit_id){
            params._id = edit_id
        }
        setError("")
        console.log("createPlanner params = ", params)
        PlannerApi.createOrEditSozvonByLink(params, edit_id, (responseData) => {
            setLoading(false)
            setSuccessCreated(true)
            if (responseData.response > 0){
                if (!isPlanOnFuture){
                    history.push(Pages.group_call + "?conv_token=" + token_room + "&id=" + responseData.sozvonByLink._id)
                } else {
                    setShareLink(getDomainUrl() + Pages.group_call_info + "/" + responseData.sozvonByLink._id)
                }
            }
         
        }, (responseData) => {
            const findMatch = (t) => {
                var match = t.match("\\d{13}");
                if (match != null && match.length > 0){
                    // console.log("match element = ", match)
                    const unixtime = match[0]
                    const date = moment.unix(unixtime/1000).local()
                    const datePlusHour = moment.unix(unixtime/1000).local().add(1, 'hours')
                    text = t.replaceAll(unixtime, date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM"))
                    findMatch(text)
                } else {
                    return text
                }        
            }
            setLoading(false)
            var text = responseData.message
            findMatch(text)
            setError(text)            
        })
    }
    
    async function getTokenRoom (){
        await SDK.init({
            apiKey: getApiKey(),
            authToken: props.user.vk_call_token
        })
        await SDK.authorize()
        const token = await SDK.startConversation()
        return token
    }
    const onFuture = (event) => {
        if (event){
            var value = event.target.checked
            setPlanOnFuture(value)
        }
    }
    const onAdOnPlatform = (event) => {
        if (event){
            var value = event.target.checked
            setIsAdsOnPlatform(value)
        }
    }
    const successCreated = () => {
        return(
            <div className="create_planner">
                <div className="create_planner_client_title">🥳<br/>Созвон успешно создан</div>
                <button onClick={() => history.goBack()} className="fill_button hover profile_bottom_button">ПОНЯТНО</button>

            </div>
        )
    }
    
    const descriptionHTML = () => {
        return(
            <div className="">
                <div className="create_planner_theme">Описание</div>
                <div className="flex">
                    <textarea ref={refTextarea} placeholder="Расскажите, о чём будет встреча" className="planner_group_desc"  value={description} onChange={(e) => setDescription(e.target.value)}/>
                </div>
            </div>
        )
    }
    
    const maxClientsHTML = () => {
        return(
            <div>
                <div className='planner_video_just_cont'>
                    <div style={{marginBottom : '16px'}} className="create_planner_theme">Максимальное количество клиентов</div>
                    <img onClick={() => setShowMaxClientsDialog(!isShowMaxClientsDialog)} alt="" className='hover planner_video_group_question' src={question}/>
                </div>

                <div className="relative">
                    <input className="create_planner_border" style={{padding : '12px 16px'}} type="number" value={maxClientsCount} onChange={(e) => setMaxClientsCount(e.target.value)}/>
                </div>
                <div className="create_planner_separator"/>
            </div>
        )
    }
    const isAdOnPlatformHTML = () => {
        return(
            <div>
                <div style={{marginTop : '16px'}} className="planner_video_just_cont">
                    <div className="planner_video_future_desc">Рекламировать встречу на платформе</div>
                    <Slider value={isAdsOnPlatform} setValue={onAdOnPlatform}/>
                </div>
                <div className='planned_is_ad_text'>Информация о предстоящей встрече будет размещена в вашем профиле и других разделах сервиса. К ней смогут присоединиться любые пользователи сервиса.</div>
            </div>
        )
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {position : 'block', zIndex : '2'}} className="selected_settings create_planner_bg">
            
            <div className="create_ads_step">
                <div className="create_planner_toolbar">
                        <p className="theme_title planner_title">Создать видеовстречу</p>
                        {!isDesktop ? 
                            <img alt="" onClick={() => history.goBack()}  className="planner_close hover" src={close_chat}/>
                            : 
                            <img alt="" onClick={() => history.goBack()}  className="planner_close_desktop hover" src={planner_close}/>
                        }

                </div>
                {/* {!user.is_repetitor ? getClientHtml()
                : */}
                {isSuccessCreated ? successCreated() :
                <div className="create_planner">
                    <PlannerSpecsPicked pickedSpec={pickedSpec} setPickedSpec={setPickedSpec}/>

                    <div className="create_planner_separator"/>
                    {descriptionHTML()}
                    <div className="create_planner_separator"/>

                    <div className="create_planner_theme">Стоимость для каждого участника</div>
                    <div className="relative">
                        <input disabled={edit_id} className="create_planner_border" style={edit_id ? {border: '1px solid #eaecf0', borderRadius: '8px', padding : '12px 16px', background : 'white'} : {padding : '12px 16px'}} type="number" value={price} onChange={(e) => setPrice(e.target.value)}/>
                        <div className="planner_video_rub">₽</div>
                    </div>
                    <div className="planner_video_rub_desc">За всё время созвона</div>
                    <div className="create_planner_separator"/>
                    
                    {/* <div className='planner_video_just_cont'>
                        <div className="create_planner_theme">Минимальное количество клиентов</div>
                        <img alt="" className='hover' src={question}/>
                    </div>

                    <div className="relative">
                        <input className="create_planner_border" style={{padding : '12px 16px'}} type="number" value={clientsCount} onChange={(e) => setClientsCount(e.target.value)}/>
                    </div>
                    <div className="create_planner_separator"/> */}

                    <div style={{marginTop : '16px'}} className="planner_video_just_cont">
                        <div className="planner_video_future_desc">Запланировать на будущее</div>
                        <Slider value={isPlanOnFuture} setValue={onFuture}/>
                    </div>
                    <div className="create_planner_separator"/>
                    {isPlanOnFuture && 
                    <div>
                        <PickedDate date={date} setDate={setDate} />
                        <div className="create_planner_separator"/>
                        <PlannerNotifications pickedNotifications={pickedNotifications} setPickedNotifications={setPickedNotifications}/>
                        {maxClientsHTML()}
                        {isAdOnPlatformHTML()}
                    </div>}
                    {edit_id ? 
                        <div style={{marginBottom : '132px'}} className='create_planner_separator'/>
                    :
                        <div onClick={() => window.open(Pages.planner_rules, '_blank')} style={{marginBottom : '66px'}} className="create_planner_how_it_works hover">Как это работает?</div>
                    }
                    <div className='planned_group_button_create'>
                        <button style={isEnableButton ? {maxHeight : '48px'} : disabledBtn()} onClick={createPlanner} className="fill_button hover relative">{isLoading ? <Loader isWhite/> : edit_id ? "Сохранить" : "Создать"}</button>
                        {edit_id && <button onClick={() => history.goBack()} className="fill_button planner_edit_cancel hover">Отмена</button>}
                    </div>
                </div>}
                
            </div>
            <ErrorCreationDialog isShow={error != ""} setShow={() => setError("")} error={error}/>
            <ShareGroupCallLinkDialog isPlanOnFuture={isPlanOnFuture} shareLink={shareLink} isShow={isSuccessCreated} setShow={() => setSuccessCreated(false)}/>
            <NotAuthedDialog setShow={setShowNotAuthDialog} isShow={isShowNotAuthDialog}/>

            <MaxClientsDialog isShow={isShowMaxClientsDialog} setShow={() => setShowMaxClientsDialog(false)}/>
        </div>
        
    );
}

export default CreateVideoMeeting;

const PickedDate = ({ date, setDate }) => {
    const isDesktop = getIsDesktop()
   
    return(
        <div className="create_planner_themes">
                <div className="create_planner_theme_item create_planner_theme_item_l">
                    <div className="create_planner_theme">Дата созвона</div>
                                
                    <CustomProvider locale={ruRu}>
                        <DatePicker value={date.date != "" ? date.date : undefined} isoWeek shouldDisableDate={date => isBefore(date, new Date(moment().subtract(1, "days")))} style={{padding : '4px'}} appearance="subtle" oneTap placeholder="Дата" onChange={(e) => setDate({date : e, start_time : date.start_time, end_time : date.end_time})} className="create_planner_border hover" format="dd.MM.yyyy" />
                    </CustomProvider>
                {/* <input onChange={(e) => setPickedDate(e.target.value)} className="create_planner_border hover" type="date" id="start" name="trip-start" value={pickedDate} min={moment().format("YYYY-DD-MM")}/> */}
                </div>
                <div className="create_planner_theme_item create_planner_theme_item_r">
                    <div className="create_planner_not_flex">
                        <div className="create_planner_theme">Время</div>
                    </div>
                    <div className="flex">
                        <CustomProvider locale={ruRu}>
                            <DatePicker hideMinutes={minute => minute % 5 !== 0} value={date.start_time != "" ? date.start_time : undefined} placement={isDesktop ? "auto" : "autoVerticalEnd"} style={{padding : '4px'}} appearance="subtle" placeholder="от" onChange={(e) => setDate({date : date.date, start_time : e != undefined ? e : "", end_time : e != undefined ? moment(e).add(1,'hours').toDate() : ""})} className="create_planner_border hover" format="HH:mm" />
                        </CustomProvider>
                        <div className="plan_tire">—</div>
                        
                        <CustomProvider locale={ruRu}>
                            <DatePicker hideMinutes={minute => minute % 5 !== 0} value={date.end_time != "" ? date.end_time : undefined} placement={isDesktop ? "auto" : "autoVerticalEnd"} style={{padding : '4px'}} appearance="subtle" placeholder="до" onChange={(e) => setDate({date : date.date, start_time : date.start_time != undefined ? date.start_time : "", end_time : e != undefined ? e : ""})} className="create_planner_border hover" format="HH:mm" />
                        </CustomProvider>
                    </div>
                   
                    
                </div>
        </div>
    )
}

const Slider = ({ value, setValue }) => {
    return(
        <label style={{marginTop : '4px'}} className="switch">
            <input type="checkbox" onChange={setValue} checked={value}/>
            <div className="slider round"></div>
        </label>
    )
}