import { useState } from 'react';
import { calculateAge, declension, getIsDesktop } from '../../../utils/Utils';

function ChatToast(props) {
    const isDesktop = getIsDesktop()
    if (props.text == ""){
        return (<div></div>)
    }
    return (
        <div style={props.style != undefined ? props.style : {}} className={isDesktop ? "profile_toast" : "chat_toast"}>
            {props.text}
        </div>
    );
}

export default ChatToast;
