import { useState, useEffect, useCallback } from 'react';
import close_editable_msg from '../../../../img/chat/close_editable_msg.svg'
import { useHistory } from "react-router-dom";
import CloseAdsDialog from '../../../ads_desk/CreateAds/dialogs/CloseAdsDialog';
import CloseExpressSearch from '../../dialogs/CloseExpressSearch';

function CreateExpressSearchHeader(props) {
    const history = useHistory()

    const [isShowRemoveDialog, setShowRemoveDialog] = useState(false);
    const onCloseClick = () => {
        setShowRemoveDialog(!isShowRemoveDialog)
    }
    return (
        <div className="relative">
            <p className="express_header_title">Экспресс-поиск</p>
            <img onClick={onCloseClick} className="express_ads_close hover" src={close_editable_msg}/>
            <CloseExpressSearch isShow={isShowRemoveDialog} setShow={setShowRemoveDialog}/>
        </div>
    );
}

export default CreateExpressSearchHeader;
