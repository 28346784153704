function ProfileBlogsTooltip(props) {
   
    return (
        <div style={props.style ? props.style : {}} className="profile_blogs_more">
            <p onClick={props.sharePost} className="more_tooltip_profile_text hover">Поделиться</p>
            <p onClick={props.editPost} className="more_tooltip_profile_text hover">Редактировать пост</p>
            <p onClick={props.removePost} style={{color : '#FF5353'}} className="more_tooltip_profile_text hover">Удалить пост</p>
        </div>
    );
}

export default ProfileBlogsTooltip;
