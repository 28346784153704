
export const getCreateAdsProgress = (step) => {
    return (step - 1) * 10
}
export const checkButtonDisabled = (isDisabled) => {
    return isDisabled ? {pointerEvents : 'none', opacity : 0.2} : {}
}
export const getEducationTargets = () => {
    return ["Сдать экзамен", "Для работы", "Подтянуть знания", "Для себя", "Другое"]
}
export const getMyLevels = () => {
    return ["Начальный", "Средний", "Выше среднего"]
}
export const getAges = () => {
    return ["Меньше 7 лет", "7-14 лет", "14-18 лет", "18-35 лет", "старше 35 лет"]
}
export const getAdsPrices = () => {
    return ["Не имеет значения", "Менее 900 ₽/час", "от 900 до 1400 ₽/час", "от 1400 до 2900 ₽/час", "более 2900 ₽/час"]
}
export const getAdsPricesFilter = () => {
    return ["от 0 ₽/час", "от 500 ₽/час", "от 900 ₽/час", "от 1400 ₽/час", "от 2900 ₽/час"]
}
export const getHowLong = () => {
    return ["1-2 занятия", "Дольше", "Решу после первого занятия"]
}
export const getSexes = () => {
    return ["Не важен", "Женщина", "Мужчина"]
}
export const convertParamsToIndexes = (info) => {
    var params = {...info}
    params.level_my = getMyLevels().indexOf(params.level_my)
    params.target_education = getEducationTargets().indexOf(params.target_education)
    params.age = getAges().indexOf(params.age)
    params.sex_repetitor = getSexes().indexOf(params.sex_repetitor)
    params.training_period = getHowLong().indexOf(params.training_period)
    params.level_repetitor = getAdsPrices().indexOf(params.level_repetitor)
    return params
}
export const getSavedAds = () => {
    const saved = localStorage.getItem('savedAds')
    if (saved == undefined || saved == "undefined" || saved == null || saved == ""){
        const info = {
            "desired_class_time": "",
            "specialization": "",
            "name": "",
            "target_education": "Сдать экзамен",
            "age": "18-35 лет",
            "sex_repetitor": "Не важен",
            "training_period": "1-2 занятия",
            "level_my": "Начальный",
            "level_repetitor": "Не имеет значения",
            "extra_data": ""
        }
        return info
    } else {
        return JSON.parse(saved)
    }
     
}
export const setSavedAds = (params) => {
    localStorage.setItem('savedAds', JSON.stringify(params))
}

// export const linkify = (inputText) => {
    
    
//     var replacedText, replacePattern1, replacePattern2;

//     //URLs starting with http://, https://, or ftp://
//     replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
//     //replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
//     var posStart = inputText.indexOf(replacePattern1) - 1
  
//     replacedText = (posStart < 0 || inputText.substring(posStart, posStart+1) != '"') ? inputText.replace(replacePattern1, '<a href=$1 target="_blank" class="hover img_click">$1</a>') : inputText

//     //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
//     replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
//     //replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
//     replacedText = replacedText.replace(replacePattern2, '$1<a href=$2 target="_blank" class="hover img_click">$2</a>');    
//     return replacedText;
// }

export const linkify = (inputText) => {
    var replacedText, replacePattern1, replacePattern2;
    replacePattern1 = /(^|[^\"])((https?|ftp):\/\/[А-Я-A-Z0-9+&@#\/%?=~_|!:,.;]*[А-Я-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '$1<a href=$2 target="_blank" class="hover img_click">$2</a>');
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href=$2 target="_blank" class="hover img_click">$2</a>');    
    return replacedText;
}
