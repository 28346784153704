/* eslint-disable eqeqeq */
import arrow_down from '../../../img/chat/arrow_down.svg'
function ScrollToBottomArrow(props) {
    const count = props.count
    const enabled = {opacity : 1, zIndex : 3}
    const disabled = {opacity : 0, pointerEvents : 'none', zIndex : 0}
    return (
        <div style={(props.length == 0 || props.itemIndex == 0 || props.isZIndex1) ? disabled : props.length - 5 >= props.itemIndex ? enabled : disabled} onClick={props.onScrollToBottomClick} className="scroll_to_bottom_container hover">
            {(count != undefined && count > 0) && <div className="scroll_to_bottom_text">{count > 99 ? 99 : count}</div>}
            <div className="scroll_to_bottom_circle">
                <img alt="" className="scroll_to_bottom_icon" src={arrow_down}/>
            </div>
        </div>
    );
}

export default ScrollToBottomArrow;
