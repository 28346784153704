import '../../styles/answers.css';
import '../../styles/express_search.css';
import { useState, useEffect, useCallback } from 'react';
import { getIsDesktop, getUrlParameter, Pages } from '../../utils/Utils';
import { Route, useHistory } from "react-router-dom";
import ExpressSearchStep1 from './create_search/ExpressSearchStep1';
import { getDesktopStyle } from '../../utils/Styles';
import ExpressSearchStep2 from './create_search/ExpressSearchStep2';
import { getExpressSearchTypes } from './helpers/ExpressSearchHelper';
import ExpressSearchStep3 from './create_search/ExpressSearchStep3';
import ExpressSearchStep4 from './create_search/ExpressSearchStep4';
import { ExpressSearchApi } from '../../api/ExpressSearchApi';
import ExpressSearchRequest from './ExpressSearchRequest';

function ExpressSearch(props) {
    const history = useHistory()
    const [specialization, setSpecialization] = useState("")
    const [description, setDescription] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [respondedUsers, setRespondedUsers] = useState([])
    const [type, setType] = useState("Чат")
    const specializations = props.specializations
    const user = props.user
    const isDesktop = getIsDesktop()
    const socketMessage = props.socketMessage
    const urlParams = {connection_type : getUrlParameter("connection_type"), specialization : getUrlParameter("specialization"), owner : getUrlParameter("owner"), create_time : getUrlParameter("create_time")}
    const isIncomingSearch = urlParams.specialization != null && urlParams.specialization != undefined && urlParams.specialization != ""
    useEffect(() => {
        if (socketMessage != undefined && socketMessage.event == "respondExpress"){
            var users = [...respondedUsers]
            const newUser = socketMessage.repetitor_info
            if (!users.includes(newUser)){
                users.push(newUser)
            }
            setRespondedUsers(users)
            setLoading(false)
        }
        
    }, [socketMessage])
    
    const expressSearchHref = "/express_search"
    const expressInfo = {
        specialization : specialization,
        connection_type : getExpressSearchTypes().indexOf(type),
        description : description
    }
    const createExpressSearch = () => {
        setLoading(true)
        ExpressSearchApi.createExpressSearch(expressInfo, (responseData) => {
            //setLoading(false)

        }, () => {setLoading(false)})
    }
    const startConnect = (type, user) => {
        setTimeout(function() {
            if (type == 0){
                history.push("/chats/chat?id=" + user.vk_caller_id)
            } else if (type == 1){
                props.setCallToUser(user)
                history.push(Pages.experts + "?is_video=true")
            } else {
                props.setCallToUser(user)
                history.push(Pages.experts)
            }
          }, 2000)        
    }
    const startConnectRepetitor = (type, user) => {
        setTimeout(function() {
            if (type == 0){
                history.push("/chats/chat?id=" + user.vk_caller_id)
            } 
          }, 2000)   
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            {/* <div className="answers_empty_cont center_horizontal">
                <div className="answers_empty_text">Скоро здесь будет функция, при помощи которой вы сможете быстрой найти нужно эксперта, чтобы созвониться прямо сейчас</div>
                <img className="aswers_empty_icon" src={answers_empty}/>
            </div> */}
            {isIncomingSearch ? 
            <ExpressSearchRequest myUser={user} config={props.config} startConnect={startConnectRepetitor} socketMessage={socketMessage} urlParams={urlParams}/>
            :
            <div className="create_ads_container">
                <Route path={expressSearchHref + "/step_1"}><ExpressSearchStep1 specializations={specializations} specialization={specialization} setSpecialization={setSpecialization}/></Route>
                <Route path={expressSearchHref + "/step_2"}><ExpressSearchStep2 bestExperts={props.bestExperts} specialization={specialization} value={type} setValue={setType}/></Route>
                <Route path={expressSearchHref + "/step_3"}><ExpressSearchStep3 createExpressSearch={createExpressSearch} specialization={specialization} value={description} setValue={setDescription}/></Route>
                <Route path={expressSearchHref + "/step_4"}><ExpressSearchStep4 setLoading={setLoading} startConnect={startConnect} createExpressSearch={createExpressSearch} type={expressInfo.connection_type} setRespondedUsers={setRespondedUsers} respondedUsers={respondedUsers} isLoading={isLoading}/></Route>
            </div>}
        </div>
    );
}

export default ExpressSearch;
