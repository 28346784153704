import { useState, useEffect, useCallback } from 'react';
import avatar from '../../../../img/avatar.svg'
import moment from 'moment'
import { getAges } from '../../CreateAds/helpers/CreateAdsHelper';
import { declension } from '../../../../utils/Utils';

function AdsItemProfile(props) {
    const item = props.item
    const hasComments = item.comments_count > 0
    return (
        <div className="ads_item_profile">
            <div style={{width : '100%'}} className="flex">
                <img className="create_ads_avatar" src={item.owner_user.avatar ? item.owner_user.avatar : avatar}/>
                <div style={{width : '100%'}}>
                    <div className="ads_item_profile_text">{item.owner_user.name}, клиенту {getAges()[item.age]}</div>
                    <div className="ads_without_comments_cont">
                        <div className="ads_item_profile_text">Опубликовано {moment.unix(moment(item.create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD MMMM")}</div>
                        <div style={hasComments ? {background : "#F1F5FD", width : 'auto'} : {}} className="ads_without_comments">{hasComments ? item.comments_count + " " + declension(item.comments_count, "отклик", "отклика", "откликов") : "Без откликов"}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdsItemProfile;
