/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import { Pages, declension, getDomainUrl, getIsDesktop, getUrlParameter } from '../../../utils/Utils';
import share_group_call from '../../../img/call/share_group_call.svg'
import group_mic_off from '../../../img/call/group_mic_off.svg'
import group_mic_on from '../../../img/call/group_mic_on.svg'
import { copyText } from '../../blog/utils/BlogUtils';
import * as SDK from '@vkontakte/calls-sdk'
import { useLongPress } from 'use-long-press';
import { getCookie } from '../../../utils/CookieHelper';
import disable_all_mic from '../../../img/call/disable_all_mic.svg'

var startedPlayVideo = false
var startedPlayScreenStream = false
const GroupVideoStreams = (props) => {
    const [swappedVideoRef, setSwappedVideoRef] = useState({ref : "", user : {}})
    const bigVideoRef = props.bigVideoRef
    const aspectRatio = props.aspectRatio
    const isShowParticipants = props.isShowParticipants
    const speakingUserId = props.speakingUserId
    const streams = props.streams.sort((a, b) => {
        const x = a.user.is_creator
        const y = b.user.is_creator 
        return (x === y) ? 0 : x? -1 : 1
    })
    console.log("sorted streams by creator = ", streams)
    const is_i_creator = props.is_i_creator
    const isDesktop = getIsDesktop()
    console.log("streams = ", streams)
   
    const copyUrl = () => {
        const link = getDomainUrl() + Pages.group_call_info + "/" + getUrlParameter("id")
        copyText(link)
        props.showToast("Ссылка на видеовстречу скопирована")
    }
    const creatorStreams = streams.filter(s => s.user.is_creator)
    const handsUpStreams = streams.filter(s => s.user.is_hand_up)
    var creatorStream = {}
    if (creatorStreams.length > 0){
        creatorStream = creatorStreams[0]
    }
    useEffect(() => {
        const currentRef = swappedVideoRef.ref == "" ? bigVideoRef : swappedVideoRef.ref
        console.log("currentRef changed = ", currentRef)
        console.log("swappedVideoRef changed = ", swappedVideoRef)
        if (creatorStream.user && !creatorStream.user.is_screen_sharing && !creatorStream.user.is_video_on){
            console.log("props.streams stop")
            startedPlayVideo = false
            startedPlayScreenStream = false
            props.stopVideo(currentRef)
            return
        }
        console.log("props.streams creatorStream.user = ", creatorStream.user)
        console.log("props.streams startedPlayVideo = ", startedPlayVideo)
        if (creatorStream.user && creatorStream.user.is_video_on && !creatorStream.user.is_screen_sharing && creatorStream.stream && !startedPlayVideo){
            props.playVideo(currentRef, creatorStream.stream)
            startedPlayVideo = true
            console.log("creatorStream startedPlayVideo 1 = ", startedPlayVideo)
        } else if (creatorStream.user && !creatorStream.user.is_video_on && startedPlayVideo){
            startedPlayVideo = false
            console.log("creatorStream startedPlayVideo 2 = ", startedPlayVideo)
        }

        if (creatorStream.user && creatorStream.user.is_screen_sharing && creatorStream.screen_stream && !startedPlayScreenStream){
            props.playVideo(currentRef, creatorStream.screen_stream)
            startedPlayScreenStream = true
        } else if (creatorStream.user && !creatorStream.user.is_screen_sharing && startedPlayScreenStream){
            startedPlayScreenStream = false
        }
        if (swappedVideoRef.ref != ""){
            props.playVideo(bigVideoRef, swappedVideoRef.stream)
        }
    }, [props.streams, swappedVideoRef])
    const onUserClick = (ref) => {
        console.log("onUserClick = ", ref)
        // if (is_i_creator){
        //     setSwappedVideoRef(ref)
         
        // }
    }
    const availableWidth = isShowParticipants ? '100% - ' + (isDesktop ? '220px' : '0') + '' : '100%'
    const landscapeStyle = {height : 'calc(' + availableWidth + ' * ' + aspectRatio + ')', width : 'calc(' + availableWidth + ')', margin : '0 auto auto auto'}
    const portraitStyle = {width : 'calc(100vh / ' + aspectRatio + ')', height : '100vh', margin : '0 auto auto auto'}
   
    const participants = streams.filter(s => !s.user.is_creator)
    
    return (
        <div className="group_call_streams">
            <video style={aspectRatio > 1 ? portraitStyle : landscapeStyle} id="big_video" playsInline className={(creatorStream != undefined && creatorStream.user != undefined && !creatorStream.user.is_screen_sharing && creatorStream.user.id == getCookie("id")) ? "group_call_big_video mirror_video" : "group_call_big_video"} ref={bigVideoRef} muted={true}></video>
            {(props.planner.creator != undefined && !is_i_creator && creatorStream.user == undefined) && 
            <div style={{width : 'calc(' + availableWidth + ')'}} className='video_call_initials_bg'>
                <div className='group_call_no_creator'>Создатель видеовстречи не подключен</div>
            </div>}
            {(creatorStream != undefined && creatorStream.user && creatorStream.user_info && !creatorStream.user.is_video_on && !creatorStream.user.is_screen_sharing) && <div style={{width : 'calc(' + availableWidth + ')'}} className='video_call_initials_bg'>
                <div className='video_call_initials'>
                    <div>
                        <p className='video_call_initials_text'>{(creatorStream.user_info.name && creatorStream.user_info.name.length > 0) ? creatorStream.user_info.name.substring(0, 1).toUpperCase() : ""}{(creatorStream.user_info.surname && creatorStream.user_info.surname.length > 0) ? creatorStream.user_info.surname.substring(0, 1).toUpperCase() : ""}</p>
                    </div>
                </div>
            </div>}
            {handsUpStreams.length > 0 && 
                <div style={{width : isDesktop ? 'calc(' + availableWidth + ')' : '100vw'}} className='call_repetitor_hand_up_c'>

                    <div className='call_repetitor_hand_up'>
                        <span>✋</span>
                        <div>{handsUpStreams[0].user_info.name + " " + handsUpStreams[0].user_info.surname}{handsUpStreams.length > 1 ? " +" + (handsUpStreams.length - 1) + " " + declension(handsUpStreams.length - 1, 'участник', 'участника', 'участников')  : ""} поднял руку</div>
                    </div>
                </div>
            }
            {(props.isHandUp && !isDesktop) && 
                <div style={{width : '100vw'}} className='call_repetitor_hand_up_c'>
                    <div onClick={() => props.toggleHangUp(!props.isHandUp)} className='call_repetitor_hand_up'>
                        <span>✋</span>
                        <div>Опустить руку</div>
                    </div>
                </div>
            }
            <div style={isShowParticipants ? {} : {display : 'none', visibility : 'collapse'}} className="group_video_streams">
                {isDesktop ? 
                    <div>
                        <div onClick={copyUrl} className='call_share_desktop_cont hover'>
                            <img className="call_share_desktop_img" src={share_group_call} alt=""/>
                            <div className="call_share_desktop">Пригласить</div>
                        </div>
                        {is_i_creator ? 
                            <div onClick={() => props.setShowDisableMic(true)} className='flex hover'>
                                <img className="call_share_desktop_img" src={disable_all_mic} alt=""/>
                                <div className="call_share_desktop">Выкл всем</div>
                            </div>
                        :
                            <div onClick={() => props.toggleHangUp(!props.isHandUp)} className='flex hover'>
                                <span className='call_share_desktop_img'>✋</span>
                                <div className="call_share_desktop">{props.isHandUp ? "Опустить руку" : "Поднять руку"}</div> 
                            </div>
                        }
                    </div>
                : 
                    <div onClick={copyUrl} className="group_video_call_item group_copy_url_item hover">
                        <img className="group_video_call_share_icon" src={share_group_call} alt=""/>
                        <div className="group_video_call_share_text">Пригласить</div>
                    </div>
                }
                {participants.length > 0 && <div className='group_mobile_flex'>
                    {participants.map((item, index) => (
                        <StreamItem item={item} is_i_creator={is_i_creator} index={index} key={index} speakingUserId={speakingUserId} onUserClick={onUserClick} swappedVideoRef={swappedVideoRef} onHandClicked={props.onHandClicked}/>
                    ))}
                    {/* {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))}
                    {streams.map((item, index) => (
                        <StreamItem item={item} index={index} key={index}/>
                    ))} */}
                </div>}
                
            </div>
        </div>
    )
}

export default GroupVideoStreams;

const StreamItem = ({ item, index, is_i_creator, speakingUserId, onUserClick, swappedVideoRef, onHandClicked }) => {
    const smallVideoRef = useRef(null)
    const name = item.user_info ? item.user_info.name : ""
    const surname = item.user_info ? item.user_info.surname : ""
    const is_mic_on = item.user.is_mic_on
    const is_video_on = item.user.is_video_on
    const is_creator = item.user.is_creator
    const is_hand_up = item.user.is_hand_up
    const isDesktop = getIsDesktop()
    const bind = useLongPress(() => {
        if (is_i_creator && item.user.is_video_on){
            try {
                SDK.muteParticipant(item.user.id, {"VIDEO" : SDK.MuteState.MUTE})
            } catch (error) {
                
            }
        }
    })
    // console.log("StreamItem asd item = ", item)
    useEffect(() => {
        let video = smallVideoRef.current
        if (video == undefined || video == null){
            return
        }
        if (is_video_on && item.stream){
            video.defaultMuted = true
            video.muted = true
            video.srcObject = item.stream
            video.play()   
        } else {
            if (video){
                video.pause()
            }
        }
        // console.log("StreamItem is_video_on = ", is_video_on)
        // console.log("StreamItem item = ", item)
        // console.log("StreamItem item.stream = ", item.stream)
    }, [is_video_on, item.stream])
    if (is_creator && swappedVideoRef.ref == ""){
        return null
    }
    // const turnVideo = async () => {
    //     if (is_i_creator && item.user.is_video_on){
    //         try {
    //             await SDK.muteParticipant(item.user.id, {"VIDEO" : SDK.MuteState.MUTE})
    //         } catch (error) {
                
    //         }
    //     }
    // }
    
    const turnMicrophone = async () => {
        if (is_i_creator && item.user.is_mic_on){
            try {
                console.log("turnMicrophone item.user.is_mic_on = ", item.user.is_mic_on)
                await SDK.muteParticipant(item.user.id, {"AUDIO" : SDK.MuteState.MUTE})
            } catch (error) {
                
            }
        }
    }
    const swapVideo = () => {
        onUserClick(is_creator ? {ref : "", user : {}}  : {ref : smallVideoRef, user : item})
    }
    return (
        <div onClick={swapVideo} style={speakingUserId == item.user.id ? {outline: '2px solid #31F8C9'} : {}} className="group_video_call_item">
                    {/* <div style={{zIndex : 2}} className="call_screen_mini_container call_screen_mini_1_container">
                        {!item.video_on && <div style={item.video_on ? {pointerEvents : 'none', opacity : 0} : {zIndex : 2}} className="call_screen_video_small call_screen_video_small_bg">
                            <p>{user.name}<br/>{user.surname}</p>
                        </div>}
                            
                    </div> */}
            <video {...bind()}style={is_video_on ? {} : {display : 'none'}} ref={smallVideoRef} id={"streams_" + index} playsInline className={is_i_creator ? "group_video_call_video hover" : item.user.id == getCookie("id") ? "group_video_call_video mirror_video" : "group_video_call_video"} muted={true}></video>
            <div style={!is_video_on ? {} : {display : 'none'}} className="group_video_call_name_bg">
                <div className="group_video_call_name">
                    <p>{(name && name.length > 0) ? name.substring(0, 1).toUpperCase() : ""}{(surname && surname.length > 0) ? surname.substring(0, 1).toUpperCase() : ""}</p>
                </div>
            </div>
            <div className="group_video_call_item_names">
                <div style={{marginLeft : '4px'}}>{name} {(surname && surname.length > 0 && isDesktop) ? surname.substring(0, 1).toUpperCase() + "." : ""}</div>
                <img onClick={turnMicrophone} className={is_i_creator ? 'hover' : ''} src={is_mic_on ? group_mic_on : group_mic_off} alt=""/>
            </div>
            {is_hand_up && <span onClick={() => onHandClicked(item)} className='call_stream_hand hover'>✋</span>}
        </div>)
}