import { Pages, declension, getRating } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import review from '../../../img/profile/review.svg'




function LKReviews(props) {
    
    const user = props.user
    const is_repetitor = props.user.is_repetitor == undefined ? false : props.user.is_repetitor
    const countReviews = user.count_1_grade + user.count_2_grade + user.count_3_grade + user.count_4_grade + user.count_5_grade
    const rating = getRating(user)
    return (
        <div className="lk_review">
            {is_repetitor ? <Link className="lk_review_cont hover" to={Pages.reviews + "?id=" + user.vk_caller_id + "&user_id=" + user._id}>
                <div>
                    <div className="rating_container">
                        <img className="profile_exp_icon" src={review}/>
                        <p className="profile_exp_review_title">{countReviews == 0 ? "" : rating}</p>
                    </div>
                    <p className="lk_review_desc lk_review_stars">{countReviews + " " + declension(countReviews, "отзыв", "отзыва", "отзывов")}</p>
                </div>
            </Link> : 
            <div className="lk_review_cont">
                <div className="rating_container">
                    <p className="profile_exp_review_title">{user.count_out_reviews}</p>
                </div>
                <p className="lk_review_desc">{declension(user.count_out_reviews, "отзыв", "отзыва", "отзывов")}<br/>{declension(user.count_out_reviews, "оставлен", "оставлено", "оставлено")}</p>
            </div>}
      
            <div style={is_repetitor ? {marginRight : '8px'} : {}} className="lk_review_cont lk_review_center">
                <div className="rating_container">
                    <p className="profile_exp_review_title">{user.count_calls_like_repetitor}</p>
                </div>
                <p className="lk_review_desc">{declension(user.count_calls_like_repetitor, "звонок", "звонка", "звонков")}<br/>{declension(user.count_calls_like_repetitor, "состоялся", "состоялось", "состоялось")}</p>
            </div>
            {is_repetitor && <div className="lk_review_cont">
                <div className="rating_container">
                    <p className="profile_exp_review_title">{user.views}</p>
                </div>
                <p className="lk_review_desc">{declension(user.views, "просмотр", "просмотра", "просмотров")}<br/>профиля</p>
            </div>}
        </div>)
}

export default LKReviews;