import { useState, useEffect, useCallback } from 'react';
import empty_ads from '../../../../img/create_ads/empty_ads.svg'
import moment from 'moment'
import { getAges } from '../../CreateAds/helpers/CreateAdsHelper';
import { declension } from '../../../../utils/Utils';

function AdsEmpty(props) {

    return (
        <div className="ads_empty_container">
           <img className="ads_empty_img" src={empty_ads}/>
           <div className="ads_empty_title" dangerouslySetInnerHTML={{__html: props.title}}/>
        </div>
    )
}

export default AdsEmpty;
