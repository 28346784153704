/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../../styles/ads_desk.css';
import { useState, useEffect, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Route } from "react-router-dom";
import AdsBoardHeader from './custom_views/AdsBoardHeader';
import { AdsApi } from '../../../api/AdsApi';
import Loader from '../../../custom_views/Loader';
import AdsItem from './list_items/AdsItem';
import PickedAds from './PickedAds';
import { getUrlParameter, getIsDesktop } from '../../../utils/Utils';
import AdsSearchBar from './custom_views/AdsSearchBar';
import AdsFilter from './custom_views/AdsFilter';
import MyAdsItem from './list_items/MyAdsItem';
import AdsArchive from './custom_views/AdsArchive';
import AdsEmpty from './custom_views/AdsEmpty';
import AdsDesktopHeader from './custom_views/AdsDesktopHeader';
import { isUserAuthed } from '../../../utils/CookieHelper';
import TgNotifDialog from '../../profile_filling/dialogs/TgNotifDialog';
import TgAuthInformation from '../../other/custom_views/TgAuthInformation';
import ShikariAdsItem from './list_items/ShikariAdsItem';
import PickedShikariAds from './PickedShikariAds';
import ShikariSendDialog from './dialogs/ShikariSendDialog';
import { useWindowResize } from '../../../utils/UserWindowResize';
import Toast from '../../profile/custom_views/Toast';
import ExpertsAdvatages from '../../experts/custom_views/ExpertsAdvantages';

function AdsBoard(props) {
    const user = props.user
    const isDesktop = getIsDesktop()
    const isRepetitor = isUserAuthed() ? user.is_repetitor : true
    const [selectedAdsType, setSelectedAdsType] = useState(-1)
    const [allAds, setAllAds] = useState([])
    const [shikariAds, setShikariAds] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isShikariLoading, setShikariLoading] = useState(true)
    const [pickedAds, setPickedAds] = useState(undefined)
    const [scrollTop, setScrollTop] = useState(0)
    const [isShowTgNotifDialog, setShowTgNotifDialog] = useState(false)
    const [isShowTgAuth, setShowTgAuth] = useState(false)
    const [isShowShareDialog, setShowShareDialog] = useState(false)
    const [withoutPro, setWithoutPro] = useState(0)
    const [toastText, setToastText] = useState("")

    const [text, setText] = useState("")
    const [selectedSpecs, setSelectedSpecs] = useState([])
    const [isAll, setAll] = useState(0)
    const [withoutRespond, setWithoutRespond] = useState(0)
    const [price, setPrice] = useState(0)
    // useEffect(() => {
    //     getFilteredAds()
    // }, [])
    useEffect(() => {
        const urlSearchText = getUrlParameter("filter")
        const hasQuery = urlSearchText != null && urlSearchText != undefined && urlSearchText != ""
        
        if (selectedSpecs.length == 0 && hasQuery){
            setSelectedSpecs(urlSearchText.includes(",") ? urlSearchText.split(",") : [urlSearchText])
        } else {
            getFilteredAds()
        }
        //getFilteredAds()

    }, [text, selectedSpecs, isAll, price, withoutRespond, withoutPro])
    useEffect(() => {
        if (props.user != undefined){
            const adsType = !isRepetitor ? 2 : 0
            setSelectedAdsType(adsType)
           
            const is_created = getUrlParameter("is_created")
            if (props.user.vk_caller_id != undefined && is_created != null && is_created != undefined && is_created != "" && (props.user.telegram_username == undefined || props.user.telegram_username == "")){
                setShowTgNotifDialog(true)
            } else {
                setShowTgNotifDialog(false)
            }
        }
        
    }, [props.user])
 
    useEffect(() => {
        if (selectedAdsType == -1){
            return
        }
        if (selectedAdsType == 0){
            getFilteredAds()
        } else if (selectedAdsType == 1){
            getRecommends()
        } else {
            getMyAds()
        }
    }, [selectedAdsType])
    useEffect(() => {
        const is_created = getUrlParameter("is_created")
        if (is_created != null && is_created != undefined && is_created != ""){
            showToast("Объявление опубликовано")
        }

    }, [])
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    const searchParams = {
        "text": text,
        "specializations": selectedSpecs,
        "is_all": isAll,
        "without_respond": withoutRespond,
        "without_pro": withoutPro,
        "price": price
    }
    // console.log("searchParams = ", searchParams)
    const getFilteredAds = () => {
        setIsLoading(true)
        if (user != undefined && !isRepetitor){
            getMyAds()
            console.log("getFilteredAds getMyAds")
            return
        }
        AdsApi.searchRequestsRepetitor(searchParams, (response) => {
            if (response.response > 0){
                // if (searchParams.without_pro){
                //     setAllAds(response.results)
                // } else {
                //     setAllAds(response.results.slice(0, 5))
                // }
               setAllAds(response.results)
            }
            if (searchParams.without_pro){
                setIsLoading(false)
            }
        }, () => {setIsLoading(false)})
        if (searchParams.without_pro){
            setShikariAds([])
            setShikariLoading(false)
        } else {
            getShikariAds(searchParams)
        }
    }
    const getRecommends = () => {
        setIsLoading(true)
        AdsApi.getRecRequestsRepetitor((response) => {
            if (response.response > 0){
                setAllAds(response.results)
            }
            setIsLoading(false)
        }, () => {setIsLoading(false)})
    }
    const getMyAds = () => {
        setIsLoading(true)
        AdsApi.getMyRequestsRepetitor((response) => {
            if (response.response > 0){
                const filtered = response.repetitorRequests.filter(item => item.status == 0)
                setAllAds(filtered)
            }
            
            setIsLoading(false)
        }, () => {setIsLoading(false)})
    }
    const getShikariAds = (searchParams) => {
        setShikariLoading(true)
        AdsApi.getSocialPosts(searchParams, (response) => {
            if (response.response > 0 && response.socialPosts){
                
                if (response.socialPosts.length > 100){
                    const slicedArr = response.socialPosts.slice(0, 100)
                    
                    setShikariAds(slicedArr)
                } else {
                    setShikariAds(response.socialPosts)
                }
                setIsLoading(false)
                setShikariLoading(false)
                console.log("falses loading")
            }
        }, () => {setIsLoading(false)})
    }
    const closeTgAuth = () => {
        setShowTgAuth(false)
    }
    const showTgAuth = () => {
        setShowTgAuth(true)
        setShowTgNotifDialog(false)
    }
    const listRef = useRef()
    const sizeMap = useRef({})
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size }
        listRef.current.resetAfterIndex(index)
    }, [])
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();
    const notRepetitor = user != undefined && !isRepetitor
    const handleScroll = event => {
        if (isDesktop){
            setScrollTop(event.currentTarget.scrollTop)
        }
    }
    return (
        <div onScroll={handleScroll} className="ads_board_cont_bg">
                        
            <div style={isDesktop ? (selectedAdsType == 1 || selectedAdsType == 2 || notRepetitor) ?  {maxWidth : '980px', marginRight : 0} : {maxWidth : '632px'} : {}} className="ads_board_container blogs_inner">
                <ExpertsAdvatages style={isDesktop ? {width : '950px', margin : '0 0 24px 16px', height : '64px'} : {margin : '0 0 8px 0', width : '100%', height : '64px', overflowX : 'scroll'}} is5/>
                <AdsBoardHeader isRepetitor={isRepetitor} user={user} selected={selectedAdsType} changeSelected={setSelectedAdsType}/>
                {selectedAdsType == 0 && <AdsSearchBar searchParams={searchParams} setPrice={setPrice} setWithoutRespond={setWithoutRespond} setAll={setAll} setSelectedSpecs={setSelectedSpecs} setText={setText}/>}
                {(isLoading || (isShikariLoading && isRepetitor)) ? <Loader style={{position : 'relative', marginTop : '-100px'}}/> : 
                <div className="flex">
                    {(allAds.length == 0 && (shikariAds.length == 0 || selectedAdsType == 2) && !isLoading) && <div style={isDesktop ? {marginTop : '16px'} : {}} className="ads_board_bg">
                        <AdsEmpty title={selectedAdsType == 0 ? "Здесь будут объявления клиентов, которые ищут специалистов определённых областей. Заглядывайте сюда, чтобы найти новых клиентов" : selectedAdsType == 1 ? "Здесь будут объявления, соответствующие вашей специализации и навыкам, а также похожие на те объявления, на которые вы откликались." : "Создайте объявление,<br/>опишите в нем, какой эксперт вам нужен. Специалисты откликнуться, а вам останется выбрать подходящего"}/>
                        {(selectedAdsType == 2 && !isDesktop) && <button onClick={() => window.location.href = '/create_ads/step_1'} style={{margin : '0 16px', width : 'calc(100% - 32px)'}} className="fill_button hover">Создать объявление</button>}
                    </div>}
                    
                    {(allAds.length > 0 || shikariAds.length > 0) && <div style={isRepetitor ? (selectedAdsType == 2 && allAds.length == 0) ? {display : 'none'} : {} : {marginTop : 0}} className="ads_list">
                        {selectedAdsType == 2 ? 
                        allAds.length > 0 && allAds.map((item, index) => (
                            <MyAdsItem setPickedAds={() => {}} item={item} index={index} key={index}/>
                        ))
                        :
                        <div>
                            {allAds.length > 0 && allAds.map((item, index) => (
                                <AdsItem setPickedAds={setPickedAds} item={item} index={index} key={index}/>
                            ))}
                            {shikariAds.length > 0 && shikariAds.map((item, index) => (
                                <ShikariAdsItem setPickedAds={setPickedAds} item={item} index={index} key={index}/>
                            ))}

                            {/* {shikariAds.length > 0 && 
                                <List className="List shikari_overflow"
                                    height={700}
                                    width='100%'
                                    itemCount={shikariAds.length}
                                    itemSize={getSize}
                                    ref={listRef}
                                    itemData={shikariAds}>
                                        {({ data, index, style }) => (
                                            <div style={style}>
                                                <ShikariAdsItem setPickedAds={setPickedAds} item={data[index]} index={index} key={index} setSize={setSize} windowWidth={windowWidth}/>
                                            </div>
                                        )}                        
                                    </List>
                            } */}
                        </div>
                        }
                    </div>}
                    {(isDesktop && selectedAdsType == 2) && <div style={allAds.length == 0 ? {marginTop : isRepetitor ? 0 : '16px'} : {}}><AdsDesktopHeader isRepetitor={isRepetitor} user={user}/></div>}
                </div>}
                

            </div>
            {(isDesktop && selectedAdsType == 0) && 
                <div className="lk_explain_relative">
                    <AdsFilter setWithoutPro={setWithoutPro} scrollTop={scrollTop} specializations={props.specializations} searchParams={searchParams} setPrice={setPrice} setWithoutRespond={setWithoutRespond} setAll={setAll} setSelectedSpecs={setSelectedSpecs}/>
                </div>
            }
            {/* {(isDesktop && selectedAdsType == 1) && <div style={{width : '350px'}}/>} */}
            <Route path={'/ads_board/ads_archive'}>
                <AdsArchive user={user} pickedAds={pickedAds} setPickedAds={setPickedAds}/>
            </Route>
            <Route path={'/ads_board/ads'}>
                <PickedAds getMyInfo={props.getMyInfo} config={props.config} socketMessage={props.socketMessage} user={user} pickedAds={pickedAds} setPickedAds={setPickedAds}/>
            </Route>
            <Route path={'/ads_board/shikari'}>
                <PickedShikariAds setShowShareDialog={setShowShareDialog} user={user} pickedAds={pickedAds} setPickedAds={setPickedAds}/>
            </Route>
            <Route path={'/ads_board/filter'}>
                <AdsFilter setWithoutPro={setWithoutPro} specializations={props.specializations} searchParams={searchParams} setPrice={setPrice} setWithoutRespond={setWithoutRespond} setAll={setAll} setSelectedSpecs={setSelectedSpecs}/>
            </Route>
            <ShikariSendDialog user={props.user} isShow={isShowShareDialog} setShow={setShowShareDialog}/>

            <TgNotifDialog showTgDialog={showTgAuth} isShow={isShowTgNotifDialog} setShow={setShowTgNotifDialog}/>
            {isShowTgAuth && <TgAuthInformation closeTgAuth={closeTgAuth} fromProfileFill user={user} getMyInfo={props.getMyInfo} />}

            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
            
        </div>
    );
}

export default AdsBoard;
