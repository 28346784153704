import { useState } from 'react';
import { getDesktopStyle } from '../../utils/Styles';
import { getIsDesktop, getUrlParameter } from '../../utils/Utils';
import sad from '../../img/pay/sad.svg'
import cool from '../../img/pay/cool.svg'
import { useHistory } from "react-router-dom";

function PayResult(props) {
    const [currentLink, setCurrentLink] = useState("")
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const continueBtn = () => {
        if (isSuccess){
            history.push("/experts")
        } else {
            history.push("/pay")
        }
        
    }
    
    const isSuccess = getUrlParameter("is_success") == "true"
    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="payment_container">
            <div className="payment_result">
                <div style={{top : 'calc(50% - 260px)'}} className="full_center">
                    <img className="pay_success_icon" src={isSuccess ? cool : sad}/>
                    {isSuccess ? <div className="pay_success_text">Баланс успешно<br/>пополнен!</div> : <div className="pay_success_text">Платёж не прошёл</div>}
                    <button style={{position : 'initial', maxWidth : '329px'}} onClick={continueBtn} className="fill_button hover profile_bottom_button">{isSuccess ? "Продолжить" : "Попробовать ещё раз"}</button>
                </div>
            </div>
        </div>
    );
}

export default PayResult;

