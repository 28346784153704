/* eslint-disable eqeqeq */
import vk_icon from '../../../../img/auth/vk_icon.svg';
import tg_icon from '../../../../img/auth/tg_icon.svg';
import Loader from '../../../../custom_views/Loader';
import { useState, useRef, useEffect } from 'react';
import PromoCodeDialog from '../../../auth/custom_views/PromoCodeDialog';
import { Define, Pages } from '../../../../utils/Utils';
import autosize from 'autosize';
import { IMaskInput } from 'react-imask';
import { getCookie, getRequestParams, setCookie } from '../../../../utils/CookieHelper';
import { AuthApi } from '../../../../api/AuthApi';
import { useHistory } from "react-router-dom";

function CreateAdsAuthNew(props) {
    const [isLoading, setLoading] = useState(false)
    const [isShowPromocode, setShowPromocode] = useState(false)
    const [isTGAuth, setTGAuth] = useState(true)
    const [phoneNumber, setPhone] = useState("7")
    const ref = useRef(null)
    const inputRef = useRef(null)
    const history = useHistory()
    
    const authVK = () => {
        window.location.href = "https://oauth.vk.com/authorize?client_id=51494524&display=popup&redirect_uri=" + window.location.origin + "/verification&scope=offline,groups&response_type=code&v=5.131"
    }
    const authTG = () => {
        // setTGAuth(true)
        // setShowPromocode(true)
        window.Telegram.Login.auth({ bot_id: Define.bot_id, request_access: true }, (user) => {
            console.log("LoginButton onAuthCallback = ", user)
            AuthApi.registerUserByTelegramNew(user, (responseData) => {
                setCookie("token", responseData.access_token)
                window.location.href = '/create_ads/step_11?publish=1'
            })  
        })
    }
    useEffect(() => {
        inputRef.current.focus();
        autosize(inputRef.current);
    }, []);

    var isPhoneEntered = phoneNumber.length == 11
    var isPhoneError = false
    const onPhoneChange = (phone) => {
        if (phone == undefined || phone == ""){
            setPhone("")
            setPhone("7")
        } else {
            if (phone.length < 3){
                setPhone("7")
            } else {
                setPhone(phone)
            }
        }
    }
    const onNext = () => {
        
        if (isLoading){
            return
        }
        setLoading(true)
        var params = getRequestParams()
        const savedPromoCode = getCookie("promocode")
        if (savedPromoCode != undefined && savedPromoCode != null && savedPromoCode != ""){
            params.promo_code = savedPromoCode
        }
        var mPhone = phoneNumber.includes("+7") ? phoneNumber.replaceAll("+7", "7") : phoneNumber
        params.phone_number = mPhone
        console.log("registerUser params =" + JSON.stringify(params))
        AuthApi.registerUser(params, (responseData) => {
            history.push("/create_ads/sms_code?phone=" + mPhone + "&code_type=" + responseData.channelType)
            setLoading(false)
        }, () => {setLoading(false)})
        // history.push("/create_ads/sms_code?phone=" + mPhone + "&code_type=" + responseData.channelType)
        // history.push("/create_ads/sms_code?phone=" + mPhone + "&code_type=sms")
        
    }
    return (
            <div>
              
                <div className="ads_auth_phone">
                    <p>Телефон</p>
                    <IMaskInput type="tel" className="auth_phone_input" mask={'+{7} (000) 000-00-00'} radix="." value={phoneNumber} unmask={true} // true|false|'typed'
                    ref={ref}
                    inputRef={inputRef}
                    onAccept={
                        (value, mask) => {
                            onPhoneChange(value)
                            console.log("value = " + value)
                        }
                    } placeholder='+7 (000) 000-00-00' />
                </div>
                <div style={isPhoneEntered ? {} : {opacity : 0.2, pointerEvents : 'none'}} onClick={onNext} className='ads_auth_btn hover'>{isLoading ? <Loader style={{minHeight : '56px', marginTop : '-16px', position : 'relative'}} isWhite/> : "Далее"}</div>
                <div className='ads_open_other_soc'>
                    Войти через соцсети:
                </div>
                <div className='ads_open_other_soc_btns'>
                    <div style={{marginRight : '8px'}} className='ads_open_other_soc_btns_d hover' onClick={authVK}>
                        <img className='ads_open_other_soc_img' src={vk_icon} alt=""/>          
                    </div>
                    {/* <a href={Pages.tg_bot} rel="noreferrer" target="_blank" > */}
                        <div onClick={authTG} className='ads_open_other_soc_btns_d hover' style={{marginLeft : '8px'}}>
                            <img className='ads_open_other_soc_img' src={tg_icon} alt=""/>          
                        </div>
                    {/* </a> */}
                    
                </div>
                
                <PromoCodeDialog getMyInfo={() => window.location.href = '/create_ads/step_11?publish=1'} isTGAuth={isTGAuth} setPromoCode={() => {}} isShow={isShowPromocode} setShow={setShowPromocode}/>

            </div>
    );
}

export default CreateAdsAuthNew;
