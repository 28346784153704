import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class CallApi {
    
    static refundMoneyFromExpert(params, onSuccess, onError) {
        fetch(getRequestUrl() + "refundMoneyFromExpert", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })

    }
    static declineCall(to_id, onSuccess, onError) {
        var params = {to_id : to_id}
        fetch(getRequestUrl() + "declineCall", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static sendSignalCall(to_id, onSuccess, onError) {
        var params = {to_id : to_id}
        fetch(getRequestUrl() + "sendSignalCall", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static changeDialogRepetitor(to_id, onSuccess, onError) {
        var params = {to_id : to_id}
        fetch(getRequestUrl() + "changeDialogRepetitor", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static pauseCall(to_id, is_pause_enable, call_id, onSuccess, onError) {
        var params = {to_id : to_id, is_pause_enable : is_pause_enable, call_id : call_id}
        fetch(getRequestUrl() + "pauseCall", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static acceptCall(call_id, onSuccess, onError) {
        var params = {call_id : call_id}
        fetch(getRequestUrl() + "acceptCall", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static newCallSozvonByLink(_id, onSuccess, onError) {
        var params = {_id : _id}
        fetch(getRequestUrl() + "newCallSozvonByLink", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static checkCall(call_id, onSuccess, onError) {
        var params = {call_id : call_id}
        fetch(getRequestUrl() + "checkCall", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static get_acting_planner_by_members(id_repetitor, id_student, onSuccess, onError) {
        var params = {id_repetitor : id_repetitor, id_student : id_student}
        fetch(getRequestUrl() + "get_acting_planner_by_members", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }

}