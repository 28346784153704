import React, { useState, useEffect, useRef } from 'react';
import autosize from 'autosize';
import { getIsDesktop } from '../../utils/Utils';
import arrow from '../../img/chat/arrow_down.svg'
function Step8(props) {
    const [bio, setBio] = useState(props.profileInfo.bio ? props.profileInfo.bio : "")
    const isEnableButton = bio.length > 2
    const refTextarea = useRef(null)
    const [currentItem, setCurrentItem] = useState(0)
    const items = [{title : 'О своём опыте', text : 'Где и сколько работали, учились, какие дипломы и сертификаты у вас есть.'}, 
                    {title : 'О себе', text : 'Навыки, умения и качества. Что отметили в вас клиенты?'},
                    {title : 'Про общение с вами', text : 'Как проходит ваше общение с Клиентом?'}]
    useEffect(() => {
        refTextarea.current.focus();
        autosize(refTextarea.current);
    }, [])
   
    const scrollRight = () => {
        document.getElementById('step8').scrollLeft += 336;
        if (currentItem < 2){
            const index = currentItem + 1
            setCurrentItem(index)
            console.log("index = " + index)
        }
        
        console.log("document.getElementById('step8') = " + document.getElementById('step8').scrollLeft )
    }
    const scrollLeft = () => {
        if (document.getElementById('step8').scrollLeft >= 0){
            document.getElementById('step8').scrollLeft -= 336
        }
        if (currentItem > 0){
            setCurrentItem(currentItem - 1)
        }
    }
    const isDesktop = getIsDesktop()
    return (
        <div className="step_fill_container step_8_container">
           <p className="profile_fill_start_title title_step">Расскажите о себе</p>
           <div style={{marginBottom : '32px'}} className="profile_fill_start_desc">Расскажите, чем можете быть полезны клиентам.<br/>О чем стоить рассказать</div>
           
           {isDesktop ? 
                <div className="desktop_step8_items">
                    <div style={currentItem == 0 ? {opacity : 0, pointerEvents : 'none'} : {}} onClick={scrollLeft} className="desktop_step8_items_arrow hover"><img src={arrow}/></div>
                    <div id="step8" className="profile_flex_tips">
                        {items.map((item, index) => (
                            <Item item={item} index={index} key={index}/>
                        ))}
                    </div>
                    <div style={currentItem == 2 ? {opacity : 0, pointerEvents : 'none', padding : '0 12px 0 16px'} : {padding : '0 12px 0 16px'}} onClick={scrollRight} className="desktop_step8_items_arrow hover"><img  style={{transform : 'rotate(180deg)'}} src={arrow}/></div>
                </div>
            :   <div className="profile_flex_tips">
                    {items.map((item, index) => (
                        <Item item={item} index={index} key={index}/>
                    ))}
                </div>
            }

           <div className="relative input_raw_container">
                <div className="input_raw_container_p">
                    <p className="profile_input_title">О себе</p>
                    <div className="flex">
                        <textarea ref={refTextarea} placeholder="Этот текст будет сохранён в профиле. Его всегда можно будет изменить" className="profile_input profile_textare_bio"  value={bio} onChange={(e) => setBio(e.target.value)}/>
                    </div>
                </div>
                <div className="fill_navbar_line"/>
            </div>
            <p className="profile_input_title profile_input_title_l">От 50 до 400 символов</p>
           <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep8(bio)} className="fill_button hover profil_fill_step8_button">Стать экспертом</button>
        </div>
    );
}
const Item = ({ item, index }) => {
    return (
        <div className="profile_item_step8">
            <p className="profile_item_step8_index">Идея {index + 1} из 3</p>
            <p className="profile_item_step8_title">{item.title}</p>
            <p className="profile_item_step8_desc">{item.text}</p>
        </div>
    )
}
export default Step8;
