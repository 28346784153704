import back_arrow from '../../../img/experts/back_arrow.svg'
import { useHistory } from "react-router-dom";
import { getIsDesktop } from '../../../utils/Utils';
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/other/other_back.svg'

function MyPrices(props) {
    const history = useHistory()

    const user = props.user
    const isDesktop = getIsDesktop()
    if (user == undefined){
        return(<div/>)
    }
    const onBack = () => {
        history.goBack()
    }
    const mobileToolbar = () => {
        return(
            <div className="arrow_left_theme">
                <div className="flex">
                    <img onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>
                    <p className="theme_title">Мои расценки</p>
                </div>
            </div>
        )
    }
    const desktopToolbar = () => {
        return(
            <div className="flex relative">
                <img onClick={onBack}  className="profile_ver_back hover" src={other_back}/>
                <p className="profile_ver_title">Мои расценки</p>
            </div>
        )
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="selected_settings">
            {isDesktop ? desktopToolbar() : mobileToolbar()}
            <div style={isDesktop ? {marginTop : '0'} : {}} className="desktop_referals">
                {/* <div className="arrow_left_theme">
                    <div className="flex">
                        <img onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>
                        <p className="theme_title">Мои расценки</p>
                    </div>
                </div> */}
                
                <div className="rules_container">
                    <div className="other_item_container">
                        <div className="justify-content">
                            <p className="other_item_title">Стоимость звонка</p>
                            <b className="other_item_title">{user.rub_in_hour}₽ / час</b>
                        </div>
                        <div className="justify-content">
                            <p className="other_item_title">Бесплатное время звонка</p>
                            <b className="other_item_title">{user.free_time} мин.</b>
                        </div>
                        <div className="justify-content">
                            <p className="other_item_title">Стоимость сообщения</p>
                            <b className="other_item_title">{user.rub_per_message > 0 ? user.rub_per_message+ "₽" : "бесплатно"}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyPrices;
