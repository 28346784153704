/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */




import * as SDK from '@vkontakte/calls-sdk'
import React, { useState, useEffect } from 'react';
import video_on from '../../../img/call/video_on.svg'
import mic_on from '../../../img/call/mic_on.svg'
import mic_off from '../../../img/call/mic_off.svg'
import video_off from '../../../img/call/video_off.svg'
import pause_img from '../../../img/call/pause.svg'
import play_img from '../../../img/call/play.svg'
import chat from '../../../img/call/chat.svg'
import more from '../../../img/call/more.svg'
import chat_opened from '../../../img/call/chat_opened.svg'
import screen_share from '../../../img/call/screen_share.svg'
import participants_active from '../../../img/call/participants_active.svg'
import participants from '../../../img/call/participants.svg'
import end_call from '../../../img/call/end_call.svg'
import board from '../../../img/call/board.svg'
import { getIsDesktop, Pages } from '../../../utils/Utils';
import { CallApi } from '../../../api/CallApi';
import CallMoreDialog from '../dialogs/CallMoreDialog';
import chat_opened_new from '../../../img/call/chat_opened_new.svg'
import chat_closed_new from '../../../img/call/chat_closed_new.svg'
import board_enabled from '../../../img/call/board_enabled.svg'
import { CSSTransition } from 'react-transition-group';

// import bg_mob_light from '../../../img/call/bg_mob_light.svg'
// import bg_mob_dark from '../../../img/call/bg_mob_dark.svg'
// import bg_desktop_light from '../../../img/call/bg_desktop_light.svg'
// import bg_desktop_dark from '../../../img/call/bg_desktop_dark.svg'
// import {
//     EffectBackground,
//     EffectBlur
// } from '@vkontakte/calls-video-effects';
import CallBGPickerDialog from '../dialogs/CallBGPickerDialog';
import MicSettingsDialog from '../dialogs/MicSettingsDialog';
function CallBottomBar(props) {
    
    const [isShowMoreDialog, setShowMoreDialog] = useState(false)
    const [isShowBgPicker, setShowBgPicker] = useState(false)
    const [isShowMicDialog, setShowMicDialog] = useState(false)
    const isMicOn = props.isMicOn
    const setMicOn = props.setMicOn
    const isGroupCall = props.isGroupCall
    const is_i_creator = props.is_i_creator
    const isBoardEnabled = props.isBoardEnabled
    // const setBoardEnabled = props.setBoardEnabled
    const setBoardEnabled = undefined
    const toggleMic = async (isMicOnTurned) => {
        console.log("toggleMic props.isMyMicPermanentMuted = ", props.isMyMicPermanentMuted)
        if (props.isMyMicPermanentMuted && props.showToast){
            props.showToast("Ваш микрофон выключен организатором")
            return
        }
        if (!isMicOnTurned && !SDK.browser.hasMicrophonePermission()){
            // props.setShowMicError(true)
            
            try {
                await SDK.browser.getUserAudio()
            } catch (error) {
                props.setShowMicError(true)
            }
            return
        }
        
        try{
            await SDK.toggleLocalAudio(!isMicOnTurned)
            setMicOn(!isMicOnTurned)
            console.log("toggleLocalAudio")
        } catch (e) {
            setMicOn(false)
            props.setShowMicError(true)
        }
       
    }
   
    const toggleVideo = async () => {
        
        if (!props.isVideoOn && !SDK.browser.hasCameraPermission()){
            // props.setShowCameraError(true)
            try {
                await SDK.browser.getUserVideo()
            } catch (error) {
                props.setShowCameraError(true)
            }
            return
        }
        props.setVideoOn(!props.isVideoOn)
        try {
            await SDK.toggleLocalVideo(!props.isVideoOn)
        } catch (error) {
            props.setShowCameraError(true)
            props.setVideoOn(false)
        }
       
    }
  
    useEffect(() => {
        if (!isGroupCall){
            //applySavedEffects()
        }
        
    }, [props.isVideoOn])
    const applySavedEffects = async () => {
        // if (props.isVideoOn){
        //     const savedItem = getCookie("call_bg_filter")
        //     console.log("savedItem = ", savedItem)
        //     if (savedItem == null || savedItem == undefined || savedItem == "" || savedItem == "no_filter"){
        //         try {
        //             await SDK.changeVideoEffect(undefined)
        //         } catch (error) {
        //             console.log(error)
        //         }
               
        //     } else if (savedItem == "blur"){
        //         const effectBlur = new EffectBlur();
        //         effectBlur.setEffects({blurStrength: 0.8});
        //         try {
        //             await SDK.changeVideoEffect(effectBlur)
        //         } catch (error) {
        //             console.log(error)
        //             await SDK.changeVideoEffect(undefined)
        //         }
              
        //     } else if (savedItem === "true" || savedItem === "false"){
        //         if (savedItem === "true"){
        //             setBGImage(isDesktop ? bg_desktop_dark : bg_mob_dark)
        //         } else if (savedItem === "false"){
        //             setBGImage(isDesktop ? bg_desktop_light : bg_mob_light)
        //         }
        //     } else {
        //         setBGImage(savedItem)
        //     }
        // }
    }
    const setBGImage = async (img) => {
        // const effectOverlay = new EffectBackground();
        // try {
        //     await effectOverlay.setBackgroundImage(img)
        //     await SDK.changeVideoEffect(effectOverlay)
        // } catch (error) {
        //     console.log(error)
        //     await SDK.changeVideoEffect(undefined)
        // }
        
    }
    const pause = () => {
        if (props.isIRepetitor){
            CallApi.pauseCall(props.callToUser.vk_caller_id, !props.isCallPaused, props.conversation.id, (responseData) => {
                props.setCallPaused(!props.isCallPaused)
            }, () => {props.setCallPaused(!props.isCallPaused)})
        } else {
            props.setShowCantPause(true)
        }
    }
    const changeCallBG = (isShow) => {
        setShowBgPicker(isShow)
    }
    console.log("isMicOn = ", isMicOn)
    const isDesktop = getIsDesktop()
    const hasUnreadedMsgs = props.hasUnreadedMsgs
    return (
        
        <div style={isDesktop ? (props.isShowCallChat ? {marginRight : props.isShowParticipants ? '580px' : '360px'} : {marginRight : props.isShowParticipants ? '220px' : '' }) : {}} className="call_bottom_bar">
            <CallBarItem onClick={toggleVideo} icon={!props.isVideoOn ? video_off : video_on} title="камера"/>
            <CallBarItem onClick={() => toggleMic(isMicOn)} icon={!isMicOn ? mic_off : mic_on} title="звук"/>
            {isGroupCall && <div className='call_bottom_bar_participants'>
                <div className='call_bottom_bar_participants_counter'>
                    <div>{props.videoStreams.filter(s => !s.user.is_creator).length}</div>
                </div>
                <CallBarItem onClick={() => props.setShowParticipants(!props.isShowParticipants)} icon={props.isShowParticipants ? participants_active : participants} title="участники"/>
            </div>}
                
            {isDesktop ? 
                // isGroupCall ?
                <div className='call_bottom_bar_participants'>
                    {hasUnreadedMsgs && <div className='chat_new_msgs_dot'></div>}
                    <CallBarItem onClick={props.openChat} icon={props.isShowCallChat ? (!hasUnreadedMsgs ? chat_opened : chat_opened_new) : (!hasUnreadedMsgs ? chat : chat_closed_new)} title="чат"/>
                </div>
                // :
                // <CallBarItem onClick={props.openChat} icon={props.isShowCallChat ? chat_opened : chat} title="чат"/>
            : 
            isGroupCall ? null : 
            <CallBarItem onClick={pause} icon={props.isCallPaused ? play_img : pause_img} title="пауза"/>}
            {/* {isDesktop && <CallBarItem onClick={() => {window.open(isGroupCall ? props.boardUrl : Pages.call_board + props.checkRepetitorResult.dialogModel.dialog_id, '_blank')}} icon={board} title="доска"/>} */}
            {isDesktop && <CallBarItem onClick={setBoardEnabled ? () => setBoardEnabled(!isBoardEnabled) : () => {window.open(isGroupCall ? props.boardUrl : Pages.call_board + props.checkRepetitorResult.dialogModel.dialog_id, '_blank')}} icon={isBoardEnabled ? board_enabled : board} title="доска"/>}
            {(isDesktop && !props.isScreenShareEnabled && is_i_creator && SDK.browser.isScreenCapturingSupported()) && <CallBarItem onClick={() => props.changeScreenShare(!props.isScreenShareEnabled)} icon={screen_share} title="экран"/>}
            <div className='call_bottom_bar_participants'>
                {(hasUnreadedMsgs && !isDesktop) && <div className='chat_new_msgs_dot'></div>}
                <CallBarItem onClick={() => setShowMoreDialog(!isShowMoreDialog)} icon={more} title="еще"/>
            </div>

            {(isDesktop && !isGroupCall) && <CallBarItem onClick={pause} icon={props.isCallPaused ? play_img : pause_img} title="пауза"/>}
            
            <CallBarItem onClick={() => props.setShowCloseCall(true)} icon={end_call} title="завершить"/>
            <CallMoreDialog isHandUp={props.isHandUp} toggleHangUp={props.toggleHangUp} isBoardEnabled={isBoardEnabled} setBoardEnabled={setBoardEnabled} disableAllMic={props.disableAllMic} hasUnreadedMsgs={hasUnreadedMsgs} boardUrl={props.boardUrl} is_i_creator={is_i_creator} isGroupCall={isGroupCall} setShowMicDialog={setShowMicDialog} changeCallBG={changeCallBG} isScreenShareEnabled={props.isScreenShareEnabled} changeScreenShare={props.changeScreenShare} checkRepetitorResult={props.checkRepetitorResult} isShow={isShowMoreDialog} setShow={setShowMoreDialog} openChat={props.openChat}/>
            <CallBGPickerDialog applySavedEffects={applySavedEffects} isShow={isShowBgPicker} setShow={setShowBgPicker}/>
            <MicSettingsDialog isShow={isShowMicDialog} setShow={setShowMicDialog}/>
            <CSSTransition in={props.toastText != undefined && props.toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <div className="planner_copy_share planner_copy_share_call">{props.toastText}</div>
            </CSSTransition>
        </div>
    );
}

export default CallBottomBar;

const CallBarItem = ({ onClick, icon, title }) => {
    return (
        <div onClick={onClick ? onClick : () => {}} className="hover call_bottom_bar_item">
            <img className="call_bottom_bar_icon" src={icon} alt=""/>
            <p className="call_bar_title">{title}</p>
        </div>)
}

// import * as SDK from '@vkontakte/calls-sdk'
// import React, { useState, useEffect } from 'react';
// import video_on from '../../../img/call/video_on.svg'
// import mic_on from '../../../img/call/mic_on.svg'
// import mic_off from '../../../img/call/mic_off.svg'
// import video_off from '../../../img/call/video_off.svg'
// import pause_img from '../../../img/call/pause.svg'
// import play_img from '../../../img/call/play.svg'
// import chat from '../../../img/call/chat.svg'
// import more from '../../../img/call/more.svg'
// import chat_opened from '../../../img/call/chat_opened.svg'
// import screen_share from '../../../img/call/screen_share.svg'
// import end_call from '../../../img/call/end_call.svg'
// import board from '../../../img/call/board.svg'
// import { getCookie } from '../../../utils/CookieHelper';
// import { getIsDesktop, Pages, getOS } from '../../../utils/Utils';
// import { CallApi } from '../../../api/CallApi';
// import CallMoreDialog from '../dialogs/CallMoreDialog';
// import bg_mob_light from '../../../img/call/bg_mob_light.svg'
// import bg_mob_dark from '../../../img/call/bg_mob_dark.svg'
// import bg_desktop_light from '../../../img/call/bg_desktop_light.svg'
// import bg_desktop_dark from '../../../img/call/bg_desktop_dark.svg'
// import {
//     EffectBackground,
//     EffectBlur
// } from '@vkontakte/calls-video-effects';
// import CallBGPickerDialog from '../dialogs/CallBGPickerDialog';
// import MicSettingsDialog from '../dialogs/MicSettingsDialog';
// function CallBottomBar(props) {
    
//     const [isShowMoreDialog, setShowMoreDialog] = useState(false)
//     const [isShowBgPicker, setShowBgPicker] = useState(false)
//     const [isShowMicDialog, setShowMicDialog] = useState(false)
//     const isMicOn = props.isMicOn
//     const setMicOn = props.setMicOn

//     const vkAuth = props.vkAuth

//     const toggleMic = (isMicOnTurned) => {
//         if (vkAuth == undefined){
//             return
//         }
//         setMicOn(!isMicOnTurned)
//         vkAuth.then(() => {
//             return SDK.toggleLocalAudio(!isMicOnTurned)
//         })
//         .catch(() => {
//             setMicOn(false)
//             if (getOS() == "macos"){
//                 props.setShowMicError(true)
//             }
//             console.error(arguments);
//         })
//         console.log("toggleMic = ", isMicOnTurned)
//     }
   
//     const toggleVideo = () => {
//         if (vkAuth == undefined){
//             return
//         }
//         props.setVideoOn(!props.isVideoOn)
//         vkAuth.then(() => {
//             return SDK.toggleLocalVideo(!props.isVideoOn)
//         })
//         .catch(() => {
            
//             if (getOS() == "macos"){
//                 props.setShowCameraError(true)
//             }
//             props.setVideoOn(false)
//             console.error(arguments);
//         })
//     }
  
//     useEffect(() => {
        
//         applySavedEffects()
        
//     }, [props.isVideoOn])
//     const applySavedEffects = () => {
//         if (props.isVideoOn && vkAuth != undefined){
//             const savedItem = getCookie("call_bg_filter")
//             console.log("savedItem = ", savedItem)
//             if (savedItem == null || savedItem == undefined || savedItem == "" || savedItem == "no_filter"){
//                 vkAuth.then(() => {
//                     return SDK.changeVideoEffect(undefined)
//                 }).catch(() => {
//                     console.error(arguments);
//                 })
//             } else if (savedItem == "blur"){
//                 const effectBlur = new EffectBlur();
//                 effectBlur.setEffects({blurStrength: 0.8});
//                 vkAuth.then(() => {
//                     return SDK.changeVideoEffect(effectBlur)
//                 }).catch(() => {
//                     console.error(arguments);
//                 })
//             } else if (savedItem === "true" || savedItem === "false"){
//                 if (savedItem === "true"){
//                     setBGImage(isDesktop ? bg_desktop_dark : bg_mob_dark)
//                 } else if (savedItem === "false"){
//                     setBGImage(isDesktop ? bg_desktop_light : bg_mob_light)
//                 }
//             } else {
//                 setBGImage(savedItem)
//             }
//         }
//     }
//     const setBGImage = (img) => {
//         const effectOverlay = new EffectBackground();
//         vkAuth.then(() => {
//             return effectOverlay.setBackgroundImage(img)
//         }).then(() => {
//             return SDK.changeVideoEffect(effectOverlay)
//         }).catch(() => {
//             console.error(arguments);
//         })
//     }
//     const pause = () => {
//         if (props.isIRepetitor){
//             CallApi.pauseCall(props.callToUser.vk_caller_id, !props.isCallPaused, props.conversation.id, (responseData) => {
//                 props.setCallPaused(!props.isCallPaused)
//             }, () => {props.setCallPaused(!props.isCallPaused)})
//         } else {
//             props.setShowCantPause(true)
//         }
//     }
//     const changeCallBG = (isShow) => {
//         setShowBgPicker(isShow)
//     }
//     const isDesktop = getIsDesktop()
//     return (
        
//         <div style={(isDesktop && props.isShowCallChat) ? {marginRight : '360px'} : {}} className="call_bottom_bar">
//             <CallBarItem onClick={toggleVideo} icon={!props.isVideoOn ? video_off : video_on} title="камера"/>
//             <CallBarItem onClick={() => toggleMic(isMicOn)} icon={!isMicOn ? mic_off : mic_on} title="звук"/>
            
//             {isDesktop ? 
//             <CallBarItem onClick={props.openChat} icon={props.isShowCallChat ? chat_opened : chat} title="чат"/>
//             :  
//             <CallBarItem onClick={pause} icon={props.isCallPaused ? play_img : pause_img} title="пауза"/>}
//             {isDesktop && <CallBarItem onClick={() => {window.open(Pages.call_board + props.checkRepetitorResult.dialogModel.dialog_id, '_blank')}} icon={board} title="доска"/>}
//             {(isDesktop && !props.isScreenShareEnabled ) && <CallBarItem onClick={() => props.changeScreenShare(!props.isScreenShareEnabled)} icon={screen_share} title="экран"/>}

//             <CallBarItem onClick={() => setShowMoreDialog(!isShowMoreDialog)} icon={more} title="еще"/>

//             {isDesktop && <CallBarItem onClick={pause} icon={props.isCallPaused ? play_img : pause_img} title="пауза"/>}
            
//             <CallBarItem onClick={() => props.setShowCloseCall(true)} icon={end_call} title="завершить"/>
//             <CallMoreDialog setShowMicDialog={setShowMicDialog} changeCallBG={changeCallBG} isScreenShareEnabled={props.isScreenShareEnabled} changeScreenShare={props.changeScreenShare} checkRepetitorResult={props.checkRepetitorResult} isShow={isShowMoreDialog} setShow={setShowMoreDialog} openChat={props.openChat}/>
//             <CallBGPickerDialog applySavedEffects={applySavedEffects} isShow={isShowBgPicker} setShow={setShowBgPicker}/>
//             <MicSettingsDialog vkAuth={vkAuth} isShow={isShowMicDialog} setShow={setShowMicDialog}/>
//         </div>
//     );
// }

// export default CallBottomBar;

// const CallBarItem = ({ onClick, icon, title }) => {
//     return (
//         <div onClick={onClick ? onClick : () => {}} className="hover call_bottom_bar_item">
//             <img className="call_bottom_bar_icon" src={icon}/>
//             <p className="call_bar_title">{title}</p>
//         </div>)
// }
