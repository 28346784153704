import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages, getUrlParameter } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import ref_copy from '../../../../img/other/ref_copy.svg'
import { AdsApi } from '../../../../api/AdsApi';


function ShikariSendDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const isDesktop = getIsDesktop()
    const user = props.user
  
    const writeToClient = () => {
        setLoading(true)
        AdsApi.getSocialPostLink(getUrlParameter("id"), (responseData) => {
            if (responseData.response > 0 && responseData.url){
                // window.open(responseData.url, "_blank")
                if (isDesktop){
                    window.open(responseData.url, "_blank")
                } else {
                    window.location.href = responseData.url
                }
                
            }
            setLoading(false)
            props.setShow(false)
        }, () => {})
    }
       
    const cancel = () => {
        props.setShow(false)
    }
    const copy = () => {
        var textField = document.createElement('textarea')
        textField.innerText = (Pages.promocode + user.vk_caller_id)
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    const getInnerHtml = () => {
        return(
            <div className="pro_dialog_container">
                <div className="ads_picked_shinkari_dialog_text">
                    Пригласите клиента в sozvon.pro —<br/>поделитесь этой ссылкой
                    <p onClick={copy} className="ref_promo_url hover">{Pages.promocode + props.user.vk_caller_id}</p>
                    <div onClick={copy} className="ads_picked_shinkari_dialog_copy hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M17.1667 7.5H9.66667C8.74619 7.5 8 8.24619 8 9.16667V16.6667C8 17.5871 8.74619 18.3333 9.66667 18.3333H17.1667C18.0871 18.3333 18.8333 17.5871 18.8333 16.6667V9.16667C18.8333 8.24619 18.0871 7.5 17.1667 7.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.66675 12.5001H3.83341C3.39139 12.5001 2.96746 12.3245 2.6549 12.0119C2.34234 11.6994 2.16675 11.2754 2.16675 10.8334V3.33341C2.16675 2.89139 2.34234 2.46746 2.6549 2.1549C2.96746 1.84234 3.39139 1.66675 3.83341 1.66675H11.3334C11.7754 1.66675 12.1994 1.84234 12.5119 2.1549C12.8245 2.46746 13.0001 2.89139 13.0001 3.33341V4.16675" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div>Скопировать ссылку</div>
                    </div>
                    <div>При регистрации в сервисе клиент сразу <b>получит на баланс 300₽</b>. Этими деньгами клиент сможет расплатиться за ваши услуги. Это выгодно выделит вас среди конкурентов.</div>
                    <div>
                        <div onClick={writeToClient} className="pro_dialog_btn hover">{isLoading ? <Loader isWhite/> : "Связаться с клиентом"}</div>
                        <div onClick={cancel} className="pro_dialog_btn_cancel hover">Отмена</div>
                    </div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return null
        }
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ShikariSendDialog;
