import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import { getIsDesktop } from '../../../../utils/Utils';
import { getAdsPricesFilter } from '../../CreateAds/helpers/CreateAdsHelper';
import check_black from '../../../../img/create_ads/check_black.svg'

function PricePickerDialog(props) {
    const isShow = props.isShow
    const isDesktop = getIsDesktop()
    const prices = getAdsPricesFilter()
    const onPriceClicked = (item, index) => {
        props.setPrice(index)
        onClose()
    }
    const getInnerHtml = () => {
        return(
            <div style={{paddingTop : '14px'}} className="rules_container">
                <div className="ads_price_picker_title">Стоимость услуг</div>
                {prices.length > 0 && prices.map((item, index) => (
                    <PriceItem item={item} isSelected={props.price == index} onPriceClicked={onPriceClicked} index={index} key={index}/>
                ))}
            </div>
        )
    }
    const onClose = () => {
        props.setShow(false)
    }
    if (isDesktop){
        if (!isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={isShow} setShow={onClose} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={onClose} open={isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default PricePickerDialog;
const PriceItem = ({ item, isSelected, onPriceClicked, index }) => {
    return(
        <div className="ads_spec_item">
            <div onClick={() => onPriceClicked(item, index)} className="ads_spec_item_flex hover">
                {item}
                <img style={isSelected ? {opacity : 1} : {opacity : 0}} className="ads_spec_item_icon" src={check_black}/>
            </div>
        </div>
    )
}