import { getIsDesktop } from "../../../utils/Utils";

function ProfilePublishedTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    const getTooltipClass = () => {
        switch (props.tooltipIndex) {
            case 0:
                return "green_profile_tooltip_c"
            case 1:
                return "green_profile_tooltip_c profile_tooltip_index_2"
            default:
                return "green_profile_tooltip_c"
        }
    }
    const getText = () => {
        switch (props.tooltipIndex) {
            case 0:
                return "Поделитесь ссылкой на профиль среди вашей аудитории в других соцсетях."
            case 1:
                return "Добавьте приветственное видео для привлечения клиентов"
            case 2:
                return "Добавьте больше информации в ваш профиль"
            default:
                return ""
        }
    }
    const getText2 = () => {
        if (props.tooltipIndex == 0){
            return "Когда вы приведёте своих клиентов, ваш рейтинг в сервисе вырастет, а это приведёт к приросту новых клиентов"
        } else {
            return undefined
        }
    }
    const getSquareStyle = () => {
        if (props.tooltipIndex == 1){
            return {left : '50%'}
        } else {
            return {left : isDesktop ? '80%' : '87%'}
        }
    }
     return (
        <div className="profile_published_tooltip">
            <div  onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div className={getTooltipClass()}>
                <div style={getSquareStyle()} className="green_rectangle"></div>
                <div className="green_profile_tooltip">{getText()}
                    {props.tooltipIndex == 0 && <div style={props.style ? props.style : {}}><br/>{getText2()}</div>}
                </div>
                
            </div>
        </div>
     );
 }
 
 export default ProfilePublishedTooltip;
 