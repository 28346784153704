/* eslint-disable eqeqeq */
import review from '../../../img/profile/review.svg'
import { declension, getRating, Pages } from '../../../utils/Utils';
import { Link } from "react-router-dom";

function HeaderReview(props) {
    const user = props.user
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade
    const rating = getRating(user)

    return (
        <div className="profile_expert_reviews_cont">
            <Link className="profile_expert_review_cont profile_expert_review_left hover" to={Pages.reviews + "?id=" + user.vk_caller_id + "&user_id=" + user._id}>
                <div>
                    <div className="rating_container">
                        <img alt='' className="profile_exp_icon" src={review}/>
                        <p className="profile_exp_review_title">{countReviews == 0 ? "" : rating}</p>
                    </div>
                    <p className="profile_exp_review_desc">{countReviews + " " + declension(countReviews, "отзыв", "отзыва", "отзывов")}</p>
                </div>
            </Link>
            {/* {(!isDesktop && isShowVideo) && <div onClick={openVideoLink} className="profile_expert_review_cont hover">
                <div className="rating_container">
                    <img className="profile_exp_icon" src={video_link}/>
                </div>
                <p className="profile_exp_review_desc">видеоролик</p>
            </div>} */}
            <div className="profile_expert_review_cont profile_expert_review_right">
                <div className="rating_container">
                    <p className="profile_exp_review_title">{user.count_calls_like_repetitor}</p>
                </div>
                <p className="profile_exp_review_desc">{declension(user.count_calls_like_repetitor, "звонок", "звонка", "звонков")}</p>
            </div>
        </div>
    )
}

export default HeaderReview;