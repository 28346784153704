/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import profile_more from '../../../img/profile/profile_more.svg'
import { getIsDesktop, Pages, getUserAvatar, convertUTC } from '../../../utils/Utils';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../api/UsersApi';
import { BlogsApi } from '../../../api/BlogsApi';
import { getCookie, getUserUrl } from '../../../utils/CookieHelper';
import ProfileBlogsTooltip from '../../profile/toolbars/ProfileBlogsTooltip';
import { CSSTransition } from 'react-transition-group';
import ProfileDeleteBlogDialog from '../../profile/dialogs/ProfileDeleteBlogDialog';
import { postShorter, copyText } from '../utils/BlogUtils';
import BlogsBookmarks from './BlogsBookmarks';
import BlogsLike from './BlogsLike';
import BlogsShare from './BlogsShare';
import BlogsViews from './BlogsViews';

function BlogItem(props) {
    const user = props.post.owner_user
    const post = props.post
    const blogItemRef = useRef(null)
    const isProfilePosts = props.isProfilePosts
    const fromBlogPost = props.fromBlogPost
    const history = useHistory()
    const [isInBookmarks, setInBookmarks] = useState(false)
    const [isShowMore, toggleMore] = useState(false)
    const [isLiked, setLiked] = useState(false)
    const [likesCount, setLikesCount] = useState(props.post.likes)
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false)
    const userLink = getUserUrl(user)
   
    useEffect(() => {
        if (props.myUser != undefined && props.myUser.favorite_users){
            getFavorites()
        }
    }, [props.myUser])
    
    useEffect(() => {
        if (post != undefined && post.like_vk_caller_ids){
            setLiked(post.like_vk_caller_ids.includes(getCookie("id")))
        }
        
    }, [post])
    useEffect(() => {
        postShorter(blogItemRef.current, fromBlogPost)
    }, [])
    const getFavorites = () => {
        const favorites = props.myUser.favorite_users
        if (favorites){
            favorites.forEach(profile => {
                if (user.vk_caller_id == profile){
                    setInBookmarks(true)
                }
            })
        }
    }
    const toggleBookmarks = () => {
        UsersApi.toggleBookmarks(user.vk_caller_id, isInBookmarks, (responseData) => {
        })
        setInBookmarks(!isInBookmarks)
    }
    const removePost = () => {
        setShowDeleteDialog(false)
        props.removePost(props.index)
    }
    const toggleLike = () => {
        BlogsApi.addLikeToPost(post._id, (responseData) => {
        })
        setLikesCount(isLiked ? (likesCount - 1) : (likesCount + 1))
        setLiked(!isLiked)

    }
    const sharePost = () => {
        copyText("https://sozvon.pro" + Pages.blogs_post + '?id=' + post._id)
        if (props.showToast){
            props.showToast("Ссылка скопирована")
        }
    }
    // const text = post.text.replaceAll("ql-editor", "")
    return (
        <div onClick={() => isShowMore ? toggleMore(false) : {}} className="blogs_item_c">
            <div style={(!getIsDesktop() && fromBlogPost) ? {marginTop : '80px'} : {}} className="blog_item_container">
                <div className="flex">
                    <a rel="noreferrer" style={fromBlogPost ? {pointerEvents : 'none'} : {}} href={userLink} target="_blank"><img alt='' className="blog_item_avatar hover" src={getUserAvatar(user)}/></a>
                    <div>
                        <a rel="noreferrer" style={fromBlogPost ? {pointerEvents : 'none'} : {}} href={userLink} target="_blank"><p className="blog_item_title hover">{user.name} {user.surname}</p></a>
                        <a rel="noreferrer" style={fromBlogPost ? {pointerEvents : 'none'} : {}} href={Pages.blogs_post + "?id=" + post._id} target="_blank"><div className="blog_item_date hover">{convertUTC(post.create_date, "DD.MM.YYYY")}</div></a>
                    </div>
                </div>
                {/* <div className="blog_item_text" dangerouslySetInnerHTML={{__html: post.text}}></div> */}
                <div ref={blogItemRef} className="ql-snow ql-container" style={{border: '0px none', margin : '16px 0'}} dangerouslySetInnerHTML={{__html: post.text}}></div>
                {/* <div ref={blogItemRef} className="ql-snow ql-container" style={{border: '0px none', margin : '16px 0'}} >
                    <ReadMoreText text={text}/>
                </div> */}
                {/* <ReadMoreText text={text}/> */}
                <div className="blog_flex_just">
                    <div></div>

                    <BlogsViews isShowTooltips views={post.views}/>
                </div>
                
            </div>
            
            <BlogsLike isShowTooltips toggleLike={toggleLike} isLiked={isLiked} likesCount={likesCount}/>
            <div className="flex">
            
                {fromBlogPost && <BlogsShare isShowTooltips sharePost={sharePost}/>}

                {user.vk_caller_id == getCookie("id") ? 
                    <img alt='' onClick={() => toggleMore(!isShowMore)} className="blog_item_bookmark blog_item_tooltip_cont hover" src={profile_more}/>
                    :
                    isProfilePosts ? <div/> 
                    :
                    <BlogsBookmarks isShowTooltips toggleBookmarks={toggleBookmarks} isInBookmarks={isInBookmarks}/>
                    // <img onClick={toggleBookmarks} className="blog_item_bookmark hover" src={isInBookmarks ? bookmark_selected : bookmark}/>
                }
            </div>
            <CSSTransition in={isShowMore} timeout={300} classNames="animation_in" unmountOnExit>
                <ProfileBlogsTooltip sharePost={sharePost} editPost={() => history.push(Pages.create_blog_post + "?id=" + post._id + '&is_edit=1')} setShowMoreTooltip={toggleMore} removePost={() => setShowDeleteDialog(true)}/>
            </CSSTransition>
            <ProfileDeleteBlogDialog post={post} removePost={removePost} isShow={isShowDeleteDialog} setShow={setShowDeleteDialog}/>

        </div>
        
    )
}

export default BlogItem;
