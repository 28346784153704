import { useState, useEffect, useCallback, useRef } from 'react';
import arrow_right from '../../../img/create_ads/arrow_right.svg'
import CreateAdsHeader from './custom_views/CreateAdsHeader';
import { useHistory } from "react-router-dom";
import { checkButtonDisabled, setSavedAds } from './helpers/CreateAdsHelper';
import autosize from 'autosize';
import CreateAdsButtons from './custom_views/CreateAdsButtons';
import { getUrlParameter } from '../../../utils/Utils';
import { AdsApi } from '../../../api/AdsApi';


function CreateAdsStep10(props) {
    const history = useHistory()
    const inputRef = useRef(null)
    const id = getUrlParameter("id")
    useEffect(() => {
        inputRef.current.focus()
        autosize(inputRef.current)

        const value = getUrlParameter("value")
        if (value != null && value != undefined && value != ""){
            const formattedValue = value == undefined ? "" : value.includes("<br/>") ? value.replaceAll("<br/>", "\n") : value
            props.setValue(formattedValue)
        }
        
    }, [])
    
    const onNext = () => {
        if (props.isEdit){
            AdsApi.editRequestRepetitor({repetitor_request_id : id, extra_data : props.value}, (response) => {
                window.location.href = '/ads_board/ads?id=' + id + "&is_details=true"
            })
        } else {
            setSavedAds(props.adsInfo)
            history.push("/create_ads/step_11")
        }
    }
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_light_title">{props.pickedSpecs}</div>
                    <div className="create_ads_step_title">Дополнительная информация</div>
                    <textarea ref={inputRef} style={{minHeight : '140px'}} placeholder="Что ещё вы бы хотели указать?" className="create_ads_input profile_textare_bio"  value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
                </div>
                <CreateAdsButtons isEdit={props.isEdit} onNext={onNext}/>
            </div>
        </div>
    );
}

export default CreateAdsStep10;
