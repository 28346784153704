/* eslint-disable eqeqeq */
import '../../styles/pay.css'
import { useState, useEffect } from 'react';
import { setDatesForTransactions, getIsDesktop, getUrlParameter, Pages } from '../../utils/Utils';
import { useHistory } from "react-router-dom";
import PayHeader from './custom_views/PayHeader';
import { getRequestParams, setCookie } from '../../utils/CookieHelper';
import Loader from '../../custom_views/Loader';
import x from '../../img/x.svg'
import chevron_down from '../../img/pay/chevron_down.svg'
import PayoutHeader from './custom_views/PayoutHeader';
import { IMaskInput } from 'react-imask';
import { CSSTransition } from 'react-transition-group';
import { getDesktopStyle } from '../../utils/Styles';
import { UsersApi } from '../../api/UsersApi';
import { PaymentApi } from '../../api/PaymentApi';
import PayTransaction from './list_items/PayTransaction';
import DetalizationDialog from './custom_views/DetalizationDialog';
import { getPayTypes, sortSbpBanks } from './utils/PayUtils';
import PayTypePickerDialog from './dialogs/PayTypePickerDialog';
import PayBankPickerDialog from './dialogs/PayBankPickerDialog';
import { ConfigApi } from '../../api/ConfigApi';


function Pay(props) {
    const [user, setUser] = useState({})
    const [transactions, setTransactions] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0)
    const [moneyInput, setMoneyInput] = useState(1000)
    const [isShowDetalization, setShowDetalization] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [banks, setBanks] = useState([])
    const [canShowTransactionDetailes, setCanShowTransactionDetailes] = useState(true)
    const history = useHistory()
    useEffect(() => {
        getMyInfo()
        getTransactions()
        getBanksList()
    }, [setUser])
    useEffect(() => {
        const isPay = getUrlParameter("pay") != null && getUrlParameter("pay") != undefined && getUrlParameter("pay") != ""
        const isPayout = getUrlParameter("payout") != null && getUrlParameter("payout") != undefined && getUrlParameter("payout") != ""
        const isBalance = getUrlParameter("balance") != null && getUrlParameter("balance") != undefined && getUrlParameter("balance") != ""
        if (isBalance){
            setMoneyInput(getUrlParameter("balance"))
        }
        
        if (isPay){
            setCurrentPage(2)
        } else if (isPayout){
            setCurrentPage(1)
        }
    }, [])
    const getMyInfo = () => {
        UsersApi.getMyInfo((mUser) => {
            setUser(mUser)
        }, (error) => {
            setCookie("token", null)
            setCookie("vk_call_token", null)
            window.location.href = '/auth'
        })
    }
    const getBanksList = () => {
        ConfigApi.getSbpBankList((responseData) => {
            if (responseData.results){
                setBanks(sortSbpBanks(responseData.results))
            }
            setLoading(false)
        })
    }
    const getTransactions = () => {
        PaymentApi.getTransactions((responseData) => {
            if (responseData.transactions){
                setTransactions(setDatesForTransactions(responseData.transactions))
            }
            setLoading(false)
        })
    }
    const isDesktop = getIsDesktop()
    const onClose = () => {
        //window.location.href = '/'
        history.goBack()
    }
    if (isLoading){
        return (<Loader />)
    } 
    var desktopPayStyle = getDesktopStyle()
    desktopPayStyle.minWidth = 'auto'
    desktopPayStyle.minHeight = '900px'
    if (currentPage == 1){
        return(
            <Payouts user={user} setCurrentPage={setCurrentPage} getMyInfo={getMyInfo} desktopPayStyle={desktopPayStyle} sbpBanks={banks}/>
        )
    }
    const onCloseDetailes = () => {
        setShowDetalization(false)
        setCanShowTransactionDetailes(false)
        setTimeout(function() {
            setCanShowTransactionDetailes(true)
        }, 500)
        
    }
    const onTransactionClicked = () => {
        if (canShowTransactionDetailes){
            setShowDetalization(true)
        }
    }
    
    if (currentPage == 2){
        const isEnableButton = moneyInput > 0
        return(
            // <div style={isDesktop ? {top : '16px'} : {}} className="payouts_desktop_container">
            //     <div style={isDesktop ? desktopPayStyle : {}} className="payment_container">
            <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings pay_desktop_bg">
                <div className='payout_fill_container'>
                    <div className="pay_fill_container">
                        <PayoutHeader pageTitle="Пополнить баланс" setCurrentPage={setCurrentPage}/>
                        {!isDesktop && <TransactionBalance user={user}/>}
                        <p style={{marginTop : '16px'}} className="pay_input_sign payout_input_title">На какую сумму хотите пополнить?</p>
                        <div className="input_pay_container">
                            <input className="pay_input" placeholder="" type="number" value={moneyInput} onChange={(e) => setMoneyInput(e.target.value)}/>
                            <p className="pay_input_sign pay_input_s">₽</p>
                        </div>
                        <button style={isEnableButton ? isDesktop ? {position : 'initial', width : 'calc(100% - 32px)'} : {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => window.location.href = "https://sozvon.pro/pay_mobile?sum=" + moneyInput + "&vk_caller_id=" + user.vk_caller_id} className="fill_button hover profile_bottom_button">Пополнить</button>
                        {/* <div className="payouts_description">
                        · Пластиковые карты (РФ) 4%<br/>
                        · YandexPay (РФ) 4%<br/>
                        · WebMoney 2%<br/>
                        · Qiwi 6%<br/>
                        · Пластиковые карты (не РФ) 7.5%<br/>
                        · YandexPay (не РФ) 7.5%
                        </div> */}
                    </div>
                    {isDesktop && <div onClick={() => setCurrentPage(0)} className="close_payment_desktop hover">
                        <img alt="" className="review_close_icon" src={x}/>
                    </div>}
                </div>
            </div>
        )
    }
   
    return (
        <div style={isDesktop ? desktopPayStyle : {}} className="payment_container">
            <p className="payment_title">Баланс</p>
            <div className="payment_inner_container">
                <PayHeader setCurrentPage={setCurrentPage} user={user}/>
                <div className="pay_history_container">
                    <div className="pay_history_title">История операций</div>
                    {transactions.map((transaction, index) => (
                        <div index={index} key={index}>
                            <PayTransaction setTransaction={setTransaction} isShowDetalization={isShowDetalization} setShowDetalization={onTransactionClicked} transaction={transaction}/> 
                        </div>
                    ))}
                </div>
                {/* <div className="pay_bottom_banks"></div>
                <img className="pay_bottom_banks_img" src={bottom_banks}/> */}
            </div>
            {isDesktop && <div onClick={onClose} className="close_payment_desktop hover">
                <img alt="" className="review_close_icon" src={x}/>
            </div>}
            <DetalizationDialog transaction={transaction} isShow={isShowDetalization} setShow={onCloseDetailes}/>

        </div>
    );
}

export default Pay;
const TransactionBalance = ({ user }) => {
    return(
        <div style={{marginTop : '-32px', marginBottom : '32px'}} className="balance_payout">
            <p className="balance_not_rep">{parseInt(user.count_rub)}₽</p>
            <p className="descr_not_rep">Ваш баланс</p>
        </div>
    )
}

const Payouts = ({ user, setCurrentPage, getMyInfo, desktopPayStyle, sbpBanks}) => {
    const isDesktop = getIsDesktop()
    return(
        // <div  style={isDesktop ? {top : '16px'} : {}} className="payouts_desktop_container">
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings pay_desktop_bg">
            {/* <div style={isDesktop ? desktopPayStyle : {}}  className="pay_fill_container"> */}
            <div className='payout_fill_container'>
                <div className="pay_fill_container">
                    <PayoutHeader pageTitle="Вывести заработок" setCurrentPage={setCurrentPage}/>
                    {!isDesktop && <TransactionBalance user={user}/>}
                    <div className='payout_mobile_title'>Вывести заработок</div>
                    <div className='payout_desc_new_title'>Если вы хотите вернуть внесенные средства, то это процедура возврата (комиссия 10%), оформляется через <span onClick={() => window.location.href = Pages.support_chat} className='hover' style={{color : '#ac92ed'}}>поддержку.</span></div>
                    {/* {pickedType == "" &&<div>
                        <p className="pick_payout_type">Выберите способ выплаты</p> 
                        <div onClick={() => setPickedType("card")} className="payout_type_cont hover">
                            <img className="payout_type_icon" src={card}/>
                            <p className="payout_type_text">На банковскую карту</p>
                        </div>
                    </div>}
                    {pickedType == "card" && <PayoutRequest user={user} getMyInfo={getMyInfo}/>} */}
                    <PayoutRequest user={user} getMyInfo={getMyInfo} sbpBanks={sbpBanks}/>
                    {/* <div className="payout_type_cont hover">
                        <img className="payout_type_icon" src={yoomoney}/>
                        <p className="payout_type_text">На ЮMoney</p>
                    </div> */}

                    {isDesktop && <div onClick={() => setCurrentPage(0)} className="close_payment_desktop hover">
                        <img alt="" className="review_close_icon" src={x}/>
                    </div>}
                </div>
            </div>
        </div>
    )
}
const PayoutRequest = ({ user, getMyInfo, sbpBanks }) => {

    const [types, setTypes] = useState(getPayTypes())
    const [banks, setBanks] = useState(sbpBanks)
    const [isShowTypesPicker, setShowTypesPicker] = useState(false)
    const [isShowBankPicker, setShowBankPicker] = useState(false)
    const [phone, setPhone] = useState("")
    const [wallet, setWallet] = useState("")
    const [payoutMoney, setPayoutMoney] = useState(user.count_rub ? parseInt(user.count_rub) : 0)
    const [isLoading, setLoading] = useState(false)
    
    useEffect(() => {
        if (sbpBanks && sbpBanks.length > 0){
            setBanks(sbpBanks)
        }
    }, [sbpBanks])
    useEffect(() => {
        if (user && user.count_rub != undefined){
            setPayoutMoney(parseInt(user.count_rub))
        }
    }, [user])
    const isMoneyErrorText = (payoutMoney > parseInt(user.count_rub)) ? "Недостаточно средств" : (payoutMoney < 5000) ? "Минимальная сумма вывода 5 000₽" : ""
    const isDesktop = getIsDesktop()
    const selectedType = types.filter(t => t.isSelected)[0]
    const selectedBanks = banks.filter(b => b.isSelected)
    const selectedBank = selectedBanks.length > 0 ? selectedBanks[0] : undefined
    const isSbpPay = selectedType.text == types[0].text

    const isEnableButton = isSbpPay ? (isMoneyErrorText == "" && phone.length == 11 && selectedBanks.length > 0) : (isMoneyErrorText == "" && wallet.length > 3)
    // const isEnableButton = true
    const addPayoutRequest = () => {
        var params = getRequestParams()
        params.amount = payoutMoney
        params.purse = isSbpPay ? phone : wallet
        params.memberId = isSbpPay ? selectedBank.member_id : "-1"
        console.log("addRequestMoney = ", params)
        setLoading(true)
        PaymentApi.addRequestMoney(params, (responseData) => {
            if (responseData.response > 0){
                getMyInfo()
            }
            console.log("addRequestMoney result = ", responseData)
            setLoading(false)
            window.location.href = Pages.pay
        })
    }
    return(
        <div>
            <div className='pay_payout_type_title'>Способ вывода</div>
            <div onClick={() => setShowTypesPicker(!isShowTypesPicker)} className='pay_payout_type hover'>
                <div className='pay_payout_type_flex'>
                    <img alt="" src={selectedType.img}/>
                    <div>{selectedType.text}</div>
                </div>
                <img style={isShowTypesPicker ? {rotate : '180deg'} : {}} className='pay_payout_type_arrow' alt="" src={chevron_down}/>
            </div>
            <p className="pay_input_sign payout_input_title">Сколько выводим (минимум 5 000₽):</p>
            <div className='payout_desc_new_desc'>Внесённые вами через пополнение баланса деньги в лимите не учитываются, вывести через эту форму можно только заработанные средства</div>

            <div className="input_pay_container">
                <input style={isMoneyErrorText != "" ? {border: '1px solid #FF5353', padding : '15px'} : {}} className="pay_input" placeholder="" type="number" value={payoutMoney} onChange={(e) => setPayoutMoney(e.target.value)}/>
                <p className="pay_input_sign pay_input_s">₽</p>
            </div>
            <CSSTransition in={isMoneyErrorText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <p className="payout_error_text">{isMoneyErrorText}</p>
            </CSSTransition>
            {isSbpPay ?
            <div>
                <div className="input_pay_container">
                    <IMaskInput type="tel" className="pay_input" mask={'+{7} (000) 000-00-00'} radix="." value={phone} unmask={true} // true|false|'typed'
                    onAccept={
                        (value, mask) => {
                            setPhone(value)
                            console.log("value = " + value)
                        }
                    } placeholder='Ваш номер телефона' />
                </div>

                <div onClick={() => setShowBankPicker(!isShowBankPicker)} style={{minHeight : '48px', marginBottom : '16px'}} className='pay_payout_type hover'>
                    <div className='pay_payout_type_flex'>
                        {(selectedBank && selectedBank.img) && <img alt="" src={selectedBank.img}/>}
                        <div>{selectedBank ? selectedBank.name : "Ваш банк"}</div>
                    </div>
                    <img style={isShowBankPicker ? {rotate : '180deg'} : {}} className='pay_payout_type_arrow' alt="" src={chevron_down}/>
                </div>
            </div>
            
            :
            <div>
                <div className='payout_trc_container'>
                    <div>Сеть: TRC-20</div>
                    <p>Ваш кошелёк обязательно должен быть в этой сети. В противном случае, при выводе средств на кошелёк другой сети ваши деньги потеряются. Администрация сервиса за это ответственность не несёт.</p>
                </div>
                <div className="input_pay_container">
                    <input type="tel" className="pay_input" value={wallet} placeholder='Ваш номер кошелька' onChange={(e) => setWallet(e.target.value)}/>
                </div>
            </div>
            }
            {/* <p style={{marginTop : '16px'}} className="pay_input_sign payout_input_title">Карта, на которую выводим:</p> */}

            {/* <div className="input_pay_container">
                <IMaskInput mask={'0000 0000 0000 0000'} radix="." value={cardNumber} unmask={true} // true|false|'typed'
                    inputRef={inputRef}
                    className="pay_input"
                    onAccept={
                        (value, mask) => {
                            setCardNumber(value)
                        }
                    } placeholder='0000 0000 0000 0000' />
            </div> */}

           
           
            <PayTypePickerDialog types={types} setTypes={setTypes} isShow={isShowTypesPicker} setShow={setShowTypesPicker}/>
            <PayBankPickerDialog banks={banks} setBanks={setBanks} isShow={isShowBankPicker} setShow={setShowBankPicker}/>
            
            <div className='pay_comission_calc'>{payoutMoney ? "Ваша комиссия: " + parseFloat(payoutMoney * (isSbpPay ? 0.035 : 0.02)).toFixed(1) + "₽" : ""}</div>
            <div className='payout_comissions'>
                {!isSbpPay && <div>Сумма будет пересчитана в USDT по курсу актуальному на момент фактического перевода средств (запрос может обрабатываться в течение 3 дней).<br/><br/></div>}
                • Комиссия: {isSbpPay ? 3.5 : 2}%{isSbpPay ? "" : ", также вы платите сетевую плату (она устанавливается сетью)"}<br/>
                • Максимальная сумма операции: 75,000<br/>
                • Максимальная сумма выплат на одни реквизиты за месяц: 400,000<br/>
                • Ваш запрос будет обработан в течение 3 дней
            </div>
            <br/>
            <a href={Pages.rules} className='payout_rules_link hover'>Правила сервиса</a>
            {/* <div className='pay_days_work'>Ваш запрос будет обработан в течение 3 дней</div> */}
            <button style={isEnableButton ? isDesktop ? {position : 'initial', width : 'calc(100% - 32px)'} : {position : isDesktop ? 'initial' : 'fixed'} : {pointerEvents : 'none', opacity : '0.2', position : isDesktop ? 'initial' : 'fixed', width : isDesktop ? 'calc(100% - 32px)' : ''}} onClick={addPayoutRequest} className="fill_button hover profile_bottom_button payout_btn_z">{isLoading ? <Loader /> : "Заказать выплату"}</button>
            <div className='payout_mobile_gradients'>
                <div className='payout_mobile_gradient'></div>
                <div className='payout_mobile_gradient_2'></div>
            </div>
        </div>
    )
}