import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class PaymentApi {
    
    static getTransactions(onSuccess, onError) {
        fetch(getRequestUrl() + "getTransactions", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addRequestMoney(params, onSuccess, onError) {
        fetch(getRequestUrl() + "addRequestMoney", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
   
}