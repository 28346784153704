/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../styles/profile.css'
import { useState, useEffect } from 'react';
import ClientProfileNavbar from './toolbars/ClientProfileNavbar';
import ProfileInfo from './custom_views/ProfileInfo';
import moment from 'moment'
import { getUnixDateFromString, getIsDesktop, getUrlParameter, isDateToday } from '../../utils/Utils';
import { CSSTransition } from 'react-transition-group';
import Loader from '../../custom_views/Loader';
import CallPricesBottomSheet from '../call/dialogs/CallPricesBottomSheet';
import Toast from './custom_views/Toast';
import { Route, Link, useHistory } from "react-router-dom";
import { UsersApi } from '../../api/UsersApi';
import DesktopNavbar from './toolbars/DesktopNavbar';
import HeaderReview from './custom_views/HeaderReview';
import UploadVideo from './custom_views/UploadVideo';
import { getCookie, setCookie, isUserAuthed, getUserUrl } from '../../utils/CookieHelper';
import MyPrices from './custom_views/MyPrices';
import NotAuthed from './custom_views/NotAuthed';
import ProfilePublishedDialog from './dialogs/ProfilePublishedDialog';
import ProfilePublishedTooltip from './tooltips/ProfilePublishedTooltip';
import NotAuthedDialog from './dialogs/NotAuthedDialog';
import BestExperts from '../experts/custom_views/BestExperts';
import ProfileBlogs from './custom_views/ProfileBlogs';
import { ConfigApi } from '../../api/ConfigApi';
import UploadYoutubeVideoDialog from './dialogs/UploadYoutubeVideoDialog';
import AddMyReviewDialog from './dialogs/AddMyReviewDialog';
import AddClientReviewDialog from './dialogs/AddClientReviewDialog';
import FreeLessonDialog from './dialogs/FreeLessonDialog';
import ProfileReviewsList from './custom_views/ProfileReviewsList';
import { createAggregateRating, setLdJson } from '../experts/utils/ExpertsUtils';
var tryCount = 0
function Profile(props) {
    
    const [isShowHeaderAvatar, setShowHeaderAvatar] = useState(undefined)
    const [scrollTop, setScrollTop] = useState(-1)
    const [isShowChat, setShowChat] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isInBookmarks, setInBookmarks] = useState(false)
    const [isBlocked, setBlocked] = useState(false)
    const [isFullScreen, setFullScreen] = useState(false)
    const [isShowPublished, setShowPublished] = useState(false)
    const [isShowAddReview, setShowAddReview] = useState(false)
    const [canWriteReview, setCanWriteReview] = useState(false)
    const [addClientReviewDialog, setShowAddClientReviewDialog] = useState("")

    // const [isShowPublished, setShowPublished] = useState(getUrlParameter("show_tips") != null && getUrlParameter("show_tips") != "")
    //const [isShowPublished, setShowPublished] = useState(true)
    const [isShowNotAuthedDialog, setShowNotAuthedDialog] = useState(false)

    const [uploadVideoChanged, setUploadVideoChanged] = useState(0)
    const [tooltipIndex, setTooltipIndex] = useState(-1)
    const [isShowUploadYoutube, setShowUploadYoutube] = useState(false)
    const [isShowCallBottomSheet, setShowCallBottomSheet] = useState(false)
    const [isShowNotRegisteredDialog, setShowNotRegisteredDialog] = useState(false)
    const [recommendedExperts, setRecommendedExperts] = useState([])
    const [isFromSearch, setFromSearch] = useState(false)
    const [isShowFreeLesson, setShowFreeLesson] = useState(false)
    const [toastText, setToastText] = useState("")
    const config = props.config
    const user = props.user
    const myUser = props.myUser
    const history = useHistory()
    const isAuthed = isUserAuthed()
    useEffect(() => {
        props.getMyInfo()
        if (user != undefined && user != "" && user.vk_caller_id != undefined){
            getFavorites()
            
        }
       
    }, [])
    useEffect(() => {
        const countOpen = getCookie("open_count")
        if (myUser != undefined && myUser.vk_caller_id != undefined && !myUser.is_repetitor && countOpen < 3){
            if (countOpen == null || countOpen == undefined || countOpen == ""){
                setCookie("open_count", 1)
            } else {
                setCookie("open_count", (parseInt(countOpen) + 1))
            }
            if (countOpen == "3" || countOpen == 3){
                setShowFreeLesson(true)
            }
        }
        
    }, [myUser])
    const checkAvailableFreeLesson = () => {
        
        const countOpen = getCookie("open_count")
        
        if (!isAuthed && (countOpen == undefined || countOpen == null || countOpen == "" || countOpen < 3)){
             console.log("checkAvailableFreeLesson 2")
            setTimeout(function() {
                setShowFreeLesson(true)
                setCookie("open_count", 3)
                console.log("free lesson show")
            }, 15000)
        }
    }
    
    // useEffect(() => {
    //     if (props.user != undefined && props.user != ""){
    //        setUser(props.user)
    //     }
    // }, [props.user])
    const getByID = () => {
        var id = window.location.pathname.split("/users/id")[1]
        if (id.includes("?")){
            id = id.split("?")[0]
        }
        setFullScreen(true)
        getUser(id)
    }
    const getByDomain = () => {
        var domain_name = window.location.pathname.split("/users/")[1]
        if (domain_name.includes("?")){
            domain_name = domain_name.split("?")[0]
        }
        setFullScreen(true)
        console.log("domain_name = ", domain_name)
        getUser(domain_name)
    }
    const loadUserInfo = () => {
        
         if (window.location.pathname.includes("/users/id")){
            //get by _id
            getByID()
        } else if (window.location.pathname.includes("/users/")){
            //get by domain_name
            getByDomain()
        }
    }
    useEffect(() => {
        loadUserInfo()
        window.scrollTo(0, 0)
     
        // const from_search = getUrlParameter("from_search")
        
        // if (from_search != null && from_search != undefined && from_search != "" && !isAuthed){
        //     setShowNotRegisteredDialog(true)
        // }
        if (user != undefined && user.vk_caller_id != undefined){
            loadRecommendedExperts()
        }
           
        
    }, [])
    
    const loadRecommendedExperts = () => {
            const from_search = getUrlParameter("from_search")
            if (from_search != null && from_search != undefined && from_search != ""){
                setFromSearch(true)
                getRecommendedExperts()
            } else {
                setFromSearch(false)
                setRecommendedExperts([])
            }
    }
    useEffect(() => {
         if (props.user != undefined && props.myUser != undefined && props.myUser.name != undefined && props.myUser.block_users != undefined){
            checkBlocked(props.user)
            
            if (props.user.is_repetitor && getCookie("id") != null && getCookie("id") != undefined && getCookie("id") != "" && user.vk_caller_id == getCookie("id") && getCookie("shown_tips") != getCookie("id") && isDateToday(props.user.register_date)){
                setCookie("shown_tips", getCookie("id"))
                setShowPublished(true)
            }
            
            
            
        }
        console.log("user loadRecommendedExperts = ", user)
        if (user != undefined && user.vk_caller_id != undefined){
            loadRecommendedExperts()
        }
    }, [props.myUser, props.user])
    const checkBlocked = (pickedUser) => {
        const isMyProfile = pickedUser.vk_caller_id == getCookie("id") || window.location.pathname.includes("/profile")
        const isInBlocked = isMyProfile ? false : props.myUser.block_users == undefined ? false : props.myUser.block_users.includes(String(pickedUser.vk_caller_id))
        setBlocked(isInBlocked)
        setTimeout(getFavorites(), 500)
    }
    const getRecommendedExperts = () => {
        ConfigApi.getRecExperts(user._id, (responseData) => {
            setRecommendedExperts(responseData.users)
        }, () => {})
    }
    const getFavorites = () => {
        const favorites = props.myUser.favorite_users
        if (favorites){
            favorites.forEach(profile => {
                if (user != undefined && user.vk_caller_id == profile){
                    setInBookmarks(true)
                }
            })
        }
    }
    const getUser = (id) => {
        UsersApi.getUsersByIDs(id, (mUser) => {
            props.getMyInfo()
            props.setSelectedProfile(mUser)
            checkBlocked(mUser)
            setLoading(false)
            checkCanReview(mUser.vk_caller_id)
            checkAvailableFreeLesson()
            // window.document.querySelector('meta[property="og:title"]').setAttribute("content", mUser.name + " " + mUser.surname);
            // window.document.querySelector('meta[property="og:description"]').setAttribute("content", mUser.bio);
            // window.document.querySelector('meta[property="og:image"]').setAttribute("content", mUser.avatar);
            // window.document.querySelector('meta[property="og:url"]').setAttribute("content", getUserUrl(mUser));
            if (mUser.is_repetitor){
                setLdJson(mUser.name + " " + mUser.surname, (mUser.rub_in_hour > 0 ? "Цена " + mUser.rub_in_hour + ' ₽/час' : 'Цена договорная'), createAggregateRating(mUser))
            }
        }, () => {
           
            tryCount += 1
            if (tryCount >= 2){
                setLoading(false)
            } else {
                getByDomain()
            }
        }, 1)
       

    }
    const checkCanReview = (id) => {
        UsersApi.checkWriteReviewAvailable(id, (responseData) => {
            if (responseData.response > 0){
                setCanWriteReview(responseData.writeReviewAvailable)
            }
        })
    }
    if (user == undefined){
        return(<Loader />)
    }

    const changeCurrentChat = (chat) => {
        if (chat == undefined){
            setShowChat(false)
        } else {
            props.setCurrentChat(chat)
            setShowChat(true)
        }
    }
    const closePublished = () => {
        setShowPublished(false)
        setTooltipIndex(0)
    }

    const isMyProfile = user.vk_caller_id == props.myUser.vk_caller_id
    
    const write = () => {
        // setLoading(true)
        // DialogsApi.getDialogInfo(user.vk_caller_id, (responseData) => {
        //     var chat = {user : user}
        //     chat.dialog = responseData.dialogInfo
        //     changeCurrentChat(chat)
        //     if (getIsDesktop()){
        //         history.push("/chats")
        //         window.history.replaceState(null, null, "/chats/chat?id=" + user.vk_caller_id)
        //     } else {
        //         history.push("/chats/chat?id=" + user.vk_caller_id)
        //     }
        //     setLoading(false)
        // }, () => {setLoading(false)})
        history.push("/chats/chat?id=" + user.vk_caller_id)
    }
    const call = () => {
        setShowCallBottomSheet(false)
        props.setCallToUser(user)        
    }
    const onWrite = () => {
        if (isAuthed){
            write()
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const onCall = () => {
        if (isAuthed){
            setShowCallBottomSheet(true)
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const onReviewLoaded = (link) => {
        getUser(user.vk_caller_id)
        showToast("Отзыв опубликован" + (link ? link : ""))
    }
    const isDesktop = getIsDesktop()
    const handleScroll = event => {
        const scrollPadding = isDesktop ? 250 : (window.screen.availWidth - 40)
        if (event.currentTarget.scrollTop >= scrollPadding && !isShowHeaderAvatar){
            setShowHeaderAvatar(true)
        } else if (event.currentTarget.scrollTop < scrollPadding && isShowHeaderAvatar){
            setShowHeaderAvatar(false)
        }
        if (isDesktop){
            setScrollTop(event.currentTarget.scrollTop)
        }
        // console.log("handleScroll e = ", event)
    }
    
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    const toggleShowCall = () => {
        if (isBlocked){
            showToast("Звонок невозможен. Вы заблокировали пользователя.")
        } else if (props.user.block_users.includes(getCookie("id"))){
            showToast("Звонок невозможен. Пользователь вас заблокировал.")
        } else {
            setShowCallBottomSheet(true)
        }
    }
    
    const isScrolledToTop =  isDesktop && (scrollTop >= 12)
    const token = getCookie("token")
    const isTokenEmpty = token == null || token == undefined || token == ""
    // const isShowReviewUpload = isMyProfile || canWriteReview
    const isShowReviewUpload = false
    if (config == undefined || (props.myUser.count_rub == undefined && !isTokenEmpty)){
        return(<Loader />)
    }
    const getEmptyHtml = () => {
        return(
            <div className="empty_profile_cont">
                <p className="empty_profile_title">Профиль</p>
                <NotAuthed/>
            </div>
            
        )
    }
    
    const getNotRepetitorInfo = () => {
        return(
            <div className="profile_desktop_info_client">
                <div>Состоявшиеся консультации {user.count_calls_like_student}</div>
                <Link className="hover" to={"/reviews?id=" + user.vk_caller_id + "_1" + "&user_id=" + user._id}>
                    <div>Отзывы от пользователя {user.count_out_reviews}</div>
                </Link>
            </div>
        )
    }
    const getDesktopHtml = () => {
        return(
            <div className="profile_desktop_max_width">
                <DesktopNavbar config={config} isScrolledToTop={isScrolledToTop} updateUserInfo={props.updateUserInfo} isProfileFromID={isTokenEmpty} isBlocked={isBlocked} setBlocked={setBlocked} showToast={showToast} setUploadVideoChanged={setUploadVideoChanged} setShowCallBottomSheet={toggleShowCall} isMyProfile={isMyProfile} isLoading={isLoading} write={write} getMyInfo={props.getMyInfo} isInBookmarks={isInBookmarks} setInBookmarks={setInBookmarks} isShowHeaderAvatar={isShowHeaderAvatar} user={user} setSelectedProfile={props.setSelectedProfile} isSelectedProfile={props.isSelectedProfile}/>
                <div style={user.is_repetitor ? {} : {justifyContent : 'center'}} className="profile_desktop_info_cont">
                    
                    {user.is_repetitor && <ProfileInfo canWriteReview={canWriteReview} setShowAddClientReviewDialog={setShowAddClientReviewDialog} setShowAddReview={setShowAddReview} isScrolledToTop={isScrolledToTop} isMyProfile={isMyProfile} myUser={myUser} config={config} isDesktop={isDesktop} user={user}/>}

                    {user.is_repetitor && 
                    <div>
                       
                        <div style={isDesktop ? {position : 'fixed', top : isScrolledToTop ? (isShowReviewUpload ? '361px' : '148px') : (isShowReviewUpload ? '428px' : '216px'), zIndex : 1} : {}} className="profile_desktop_info_right transition_header_top">
                            <UploadVideo config={config} setShowUploadYoutube={setShowUploadYoutube} onWrite={onWrite} onCall={onCall} isMyProfile={isMyProfile} uploadVideoChanged={uploadVideoChanged} user={props.user} updateUserInfo={props.updateUserInfo}/>
                            <HeaderReview user={user} isMyProfile={props.isMyProfile}/>
                        </div>
                        {isDesktop && <div style={{minWidth : '369px'}}/>}
                    </div>}

                    {!user.is_repetitor && getNotRepetitorInfo()}

                </div>

                {isFromSearch && 
                <div className="relative" style={{marginLeft : '16px'}}>
                    <BestExperts isRecommended setSelectedProfile={props.setSelectedProfile} bestExperts={recommendedExperts} />
                </div>}
                {user.is_repetitor && <ProfileReviewsList user={user}/>}
                {user.is_repetitor && <ProfileBlogs user={user}/>}

                <p style={isMyProfile ? {paddingBottom : '20px'} : {}} className="profile_info_title register_date_profile">В сервисе с {moment.unix(getUnixDateFromString(user.register_date)).format("DD MMMM YYYY")}</p>

            </div>
        )
    }
    const countRub = Number.isInteger(props.myUser.count_rub) ? props.myUser.count_rub : parseFloat(props.myUser.count_rub).toFixed(1)
    const getMobileHtml = () => {
        return(
            <div style={isMyProfile && !props.isBottomMenu ? {paddingBottom : '24px'} : {}} className="profile_bg">
                <ClientProfileNavbar isProfileFromID={isTokenEmpty} isBlocked={isBlocked} setBlocked={setBlocked} showToast={showToast} isMyProfile={isMyProfile} setShowCallBottomSheet={toggleShowCall} write={write} isLoading={isLoading} setUploadVideoChanged={setUploadVideoChanged} getMyInfo={props.getMyInfo} isInBookmarks={isInBookmarks} setInBookmarks={setInBookmarks} isShowHeaderAvatar={isShowHeaderAvatar} user={user} setSelectedProfile={props.setSelectedProfile} isSelectedProfile={props.isSelectedProfile} />
                <UploadVideo config={config} setShowUploadYoutube={setShowUploadYoutube} onWrite={onWrite} onCall={onCall} isMyProfile={isMyProfile} uploadVideoChanged={uploadVideoChanged} user={props.user} updateUserInfo={props.updateUserInfo}/>
                {user.is_repetitor && <HeaderReview user={user} isMyProfile={props.isMyProfile}/>}
                <ProfileInfo canWriteReview={canWriteReview} setShowAddClientReviewDialog={setShowAddClientReviewDialog} setShowAddReview={setShowAddReview} isMyProfile={isMyProfile} myUser={myUser} config={config} isDesktop={isDesktop} user={user}/>
                {user.is_repetitor && <ProfileReviewsList user={user}/>}

                {isFromSearch && <BestExperts isRecommended setSelectedProfile={props.setSelectedProfile} bestExperts={recommendedExperts} />}
                {user.is_repetitor && <ProfileBlogs user={user}/>}
                {!user.is_repetitor && getNotRepetitorInfo()}
                <p style={isMyProfile ? {paddingBottom : '44px'} : {}} className="profile_info_title register_date_profile">В сервисе с {moment.unix(getUnixDateFromString(user.register_date)).format("DD MMMM YYYY")}</p>
                
                {(isMyProfile && props.myUser.count_rub != undefined) &&  <div style={isFullScreen ? {position : 'fixed', bottom : '16px'} : {position : 'fixed', bottom : '64px'}} onClick={() => window.location.href = '/pay'} className="chat_message_price_cont center_horizontal">
                    <p className="chat_message_price_balance">Баланс {countRub}₽</p>
                </div>}
            </div>
        )
    }
    
    return (
        <div onScroll={handleScroll} className={isDesktop ? "selected_profile_container_desktop" : "selected_profile_container"}>  
            {(isTokenEmpty && isMyProfile) ? getEmptyHtml() : <div>
                {isDesktop ?
                    getDesktopHtml()
                : 
                    getMobileHtml()
                }
            </div>}
            <Route path={'/profile/prices'}>
                <MyPrices user={user}/>
            </Route>
            <CallPricesBottomSheet config={config} getMyInfo={props.getMyInfo} setShow={setShowCallBottomSheet} user={props.myUser} callToUser={user} isShow={isShowCallBottomSheet} call={call}/>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
            <ProfilePublishedDialog isShow={isShowPublished} setShow={closePublished}/>
            {(tooltipIndex >= 0 && tooltipIndex < 3) && <ProfilePublishedTooltip tooltipIndex={tooltipIndex} setShow={() => setTooltipIndex(tooltipIndex + 1)}/>}
            <NotAuthedDialog isShow={isShowNotAuthedDialog} setShow={setShowNotAuthedDialog}/>
            <FreeLessonDialog isShow={isShowFreeLesson} setShow={setShowFreeLesson}/>
            <NotAuthedDialog isRegistration isShow={isShowNotRegisteredDialog} setShow={setShowNotRegisteredDialog}/>
            <UploadYoutubeVideoDialog getMyInfo={loadUserInfo} user={props.user} updateUserInfo={props.updateUserInfo} isShow={isShowUploadYoutube} setShow={setShowUploadYoutube}/>
            <AddMyReviewDialog user={user} onLoaded={onReviewLoaded} isShow={isShowAddReview} setShow={setShowAddReview}/>
            <AddClientReviewDialog user={user} onLoaded={onReviewLoaded} to_id={addClientReviewDialog} isShow={addClientReviewDialog != ""} setShow={() => setShowAddClientReviewDialog("")}/>

        </div>
    );
}

export default Profile;
