/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useRef, useEffect } from 'react';
import { getRequestParams, setCookie } from "../../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../../utils/Utils";
import AuthErrorTooltip from './AuthErrorTooltip';
import logo from '../../img/logo.svg';
import back_white from '../../img/back_white.svg';
import CodeTimer from './custom_views/CodeTimer';
import Loader from '../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import autosize from 'autosize';
import { AuthApi } from '../../api/AuthApi';
var canGoNext = false
function CodeView(props) {
    const [code, setCode] = useState("");
    const [code_1, setCode_1] = useState("");
    const [code_2, setCode_2] = useState("");
    const [code_3, setCode_3] = useState("");
    const [code_4, setCode_4] = useState("");
    const [isCodeError, setCodeError] = useState("");
    const [timerFinished, setTimerFinished] = useState(false);
    const inputRef = useRef(null);
    const history = useHistory()
    const toggleCodeError = () => { setCodeError(current => !current)}
    const inputSelectedStyle = { border: '2px solid #31F8C9' }
    useEffect(() => {
        inputRef.current.focus();
        autosize(inputRef.current);
    }, [])
    useEffect(() => {
        if (code.length >= 4 && canGoNext){
            confirmPhoneNumber()
            canGoNext = false
        } else {
            canGoNext = true
        }
    }, [code])
    const codeEntered = (text) => { 
        const enteredCode = text
        if (enteredCode.length < 5){
            setCode(enteredCode)
            switch (enteredCode.length) {
                case 0:
                    setCode_1("")
                    break;
                    case 1:
                        setCode_1(enteredCode[0])
                        setCode_2("")
                        break;
                    case 2:
                        setCode_2(enteredCode[1])
                        setCode_3("")
                        break;
                    case 3:
                        setCode_3(enteredCode[2])
                        setCode_4("")
                        break;
                    case 4:
                        setCode_4(enteredCode[3])
                        break;
                default:
                    break;
            }
        }
    }
    const confirmPhoneNumber = () => {
        if (props.isLoading){
            return
        }
        if (isCodeError){
            toggleCodeError()
        }
        props.toggleIsLoading()
        var params = getRequestParams()
        params.phone_number = props.phoneNumber.includes("+7") ? props.phoneNumber.replaceAll("+7", "7") : props.phoneNumber
        params.code = code
        console.log("confirmPhoneNumber params = " + JSON.stringify(params))
        AuthApi.confirmPhoneNumber(params, (responseData) => {
            setCookie("token", responseData.access_token)
            props.getMyInfo()
            props.toggleIsLoading()
        }, () => {
            toggleCodeError()
            props.toggleIsLoading()
        })
    }
   
    const callAgain = () => {
        props.registerUser()
        setTimerFinished(false)
    }
    const back = useCallback(() => {
        props.setShowCodeView(false)
        history.goBack()
    }, [history])
    const onKeyDownPressed = (e) => {
        
        if (e.key === 'Enter' && code.length == 4) {
            e.preventDefault()
            confirmPhoneNumber()
        }
    }
    var isCodeEntered = code.length == 4
    return (
        <div>
            <div onClick={back} className="back_code_container hover"><img alt='' className="back_white_code" src={back_white}/></div>
            <img alt='' className="auth_logo" src={logo}/>
            <p className="auth_title">Подтверждение телефона</p>
            {/* {props.codeType == "VOICECODE" ?  */}
                <p className="auth_description">введите 4 цифры, которые<br/>пришли по СМС<br/>на номер +<b>{props.phoneNumber}</b></p> 
                {/* : <p className="auth_description">введите последние 4 цифры номера,<br/>с которого вам поступил звонок<br/>на номер +<b>{props.phoneNumber}</b></p>} */}
            <div onKeyDown={onKeyDownPressed} className="code_input_container">
                <div className="code_inputs">
                    <input style={code_1 == "" ? inputSelectedStyle : {}} className="code_input" value={code_1} onChange={(e) => setCode_1(e.target.value)}/>
                    <input style={(code_1 != "" && code_2 == "") ? inputSelectedStyle : {}} className="code_input"  value={code_2} onChange={(e) => setCode_2(e.target.value)}/>
                    <input style={(code_2 != "" && code_3 == "") ? inputSelectedStyle : {}} className="code_input" value={code_3} onChange={(e) => setCode_3(e.target.value)}/>
                    <input style={(code_3 != "" && code_4 == "") ? inputSelectedStyle : {}} className="code_input" value={code_4} onChange={(e) => setCode_4(e.target.value)}/>
                </div>
                <input ref={inputRef} className="hidden_code_input" type="tel" value={code} onChange={(e) => codeEntered(e.target.value)}/>
            </div>
            {timerFinished ? <p onClick={callAgain} className="call_again_btn hover">Выслать код снова</p> :
            <CodeTimer setTimerFinished={setTimerFinished}/>}
            
            {isCodeError && <AuthErrorTooltip text="Неверный код"/>}

            <button style={!isCodeEntered ? {pointerEvents : 'none', opacity : 0.2} : {}} onClick={confirmPhoneNumber} className="button bottom_button hover">{props.isLoading ? <Loader isWhite={true}/> : "Далее"}</button>

        </div>
    );
}

export default CodeView;
