import { Pages } from '../../../utils/Utils';
import article from '../../../img/blogs/article.svg'
function BlogsExplain(props) {

    return (
        <div style={props.scrollTop >= 88 ? {marginTop : 0} : {marginTop : '68px'}} className="lk_explain transition_header">
            <div className="lk_explain_icon_cont">
                <img alt="" src={article}/>
            </div>
            <div>Как начать вести блог и зачем это нужно</div>
            <a rel="noreferrer" href={Pages.blogs_faq} target="_blank"><div className="lk_explain_btn hover">Читать в статье</div></a>
        </div>)
}

export default BlogsExplain;