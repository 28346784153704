/* eslint-disable eqeqeq */
import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { log, onResponse, setApiMetadata } from "./ApiUtils";
export class UsersApi {
    
    static getMyInfo(onSuccess, onError) {
        
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyInfo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyInfo = ", responseData)
            if (responseData.response > 0 && responseData.user){
                onSuccess(responseData.user)
            } else {
                if (onError){
                    onError()
                }
            }
        })
    }
    static setMyInfo(params, onSuccess, onError) {
        log("setMyInfo params = ", params)
        fetch(getRequestUrl() + "setMyInfo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("setMyInfo responseData = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    // static getUsersByIDs(id, onSuccess, onError) {
        
    // }
    static getUsersByIDs(id, onSuccess, onError, view) {
        var params = getRequestParams()
        params.ids = id
        if (view == 1){
            params.view = 1
        }
        fetch(getRequestUrl() + "getUsersByIDs", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getUsersByIDs = ", responseData)
            if (responseData.response === 1 && responseData.users.length > 0){
                var user = responseData.users[0]
                
                if (responseData.sozvon_by_links.length > 0){
                    user.sozvon_by_links = responseData.sozvon_by_links
                } else {
                    user.sozvon_by_links = []
                }
                onSuccess(user)
                setApiMetadata(responseData.meta_data)
            } else {
                if (onError){
                    onError()
                }
            }
        })
    }
    static getUserByVkCallerID(id, onSuccess, onError) {
        var params = getRequestParams()
        params.vk_caller_id = id
        fetch(getRequestUrl() + "getUserByVkCallerID", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getUserByVkCallerID = ", responseData)
            if (responseData.response === 1 && responseData.user != undefined){
                onSuccess(responseData.user)
            } else {
                if (onError){
                    onError()
                }
            }
        })
    }
    static getUserByDomain(id, onSuccess, onError, view) {
        var params = getRequestParams()
        params.domain_name = id
        if (view == 1){
            params.view = 1
        }
        fetch(getRequestUrl() + "getUserByDomain", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getUserByDomain = ", responseData)
            if (responseData.response === 1 && responseData.user != undefined){
                onSuccess(responseData.user)
            } else {
                if (onError){
                    onError()
                }
            }
        })
    }

    static toggleBookmarks(vk_caller_id, isInBookmarks, onSuccess, onError) {
        var params = getRequestParams()
        var method = !isInBookmarks ? "addFavorites" : "removeFavorite"
        params.favorite_id = vk_caller_id
        fetch(getRequestUrl() + method, getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            onResponse(responseData, onSuccess, onError)          
        })
    }
    
    static toggleBlock(vk_caller_id, isBlocked, onSuccess, onError) {
        var params = getRequestParams()
        var method = !isBlocked ? "blockUser" : "unBlockUser"
        params.block_id = vk_caller_id
        fetch(getRequestUrl() + method, getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static removePushToken(token, onSuccess, onError) {
        var params = getRequestParams()
        params.push_token = token
        fetch(getRequestUrl() + "removePushToken", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static getReviews(to_id, onSuccess, onError) {
        var params = getRequestParams()
        params.to_id = to_id
        fetch(getRequestUrl() + "getReviews", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            log("getReviews = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static getMainScreenReviews( onSuccess, onError) {
        fetch(getRequestUrl() + "getMainScreenReviews", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {  
            log('getMainScreenReviews = ', responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static clearTelegramNotify(onSuccess, onError) {
        var params = getRequestParams()
        console.log("clearTelegramNotify")
        fetch(getRequestUrl() + "clearTelegramNotify", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            console.log("clearTelegramNotify responseData = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static activatePromocode(promo_code, onSuccess, onError) {
        var params = getRequestParams()
        params.promo_code = promo_code
        fetch(getRequestUrl() + "activatePromocode", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            log("activatePromocode = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    
    static promotionBuyYandex(onSuccess, onError) {
        fetch(getRequestUrl() + "promotionBuyYandex", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {  
            log("promotionBuyYandex = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static promotionBuyVkPosts(onSuccess, onError) {
        fetch(getRequestUrl() + "promotionBuyVkPosts", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {  
            log("promotionBuyVkPosts = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static promotionRequestDemoVideo(onSuccess, onError) {
        fetch(getRequestUrl() + "promotionRequestDemoVideo", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {  
            log("promotionRequestDemoVideo = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static getReview(to_id, onSuccess, onError) {
        fetch(getRequestUrl() + "getReview", getHttpParams({to_id : to_id})).then((response) => response.json())
        .then((responseData) => {  
            log("getReview = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static buyPro(is_pro_type, onSuccess, onError) {
        fetch(getRequestUrl() + "buyPro", getHttpParams({is_pro_type : is_pro_type})).then((response) => response.json())
        .then((responseData) => {  
            log("buyPro = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static cancelPro(onSuccess, onError) {
        fetch(getRequestUrl() + "cancelPro", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {  
            log("cancelPro = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static writeReviewForYourself(params, onSuccess, onError) {
        fetch(getRequestUrl() + "writeReviewForYourself", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            log("writeReviewForYourself = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static writeReview(params, onSuccess, onError) {
        fetch(getRequestUrl() + "writeReview", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            log("writeReview = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static removeReviewForYourself(review_id, onSuccess, onError) {
        fetch(getRequestUrl() + "removeReviewForYourself", getHttpParams({review_id : review_id})).then((response) => response.json())
        .then((responseData) => {  
            log("removeReviewForYourself = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static checkWriteReviewAvailable(to_id, onSuccess, onError) {
        fetch(getRequestUrl() + "checkWriteReviewAvailable", getHttpParams({to_id : to_id})).then((response) => response.json())
        .then((responseData) => {  
            log("checkWriteReviewAvailable = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    static fastCreateRequestRepetitor(user_query, phone_number, onSuccess, onError) {
        const params = {user_query : user_query, phone_number : phone_number.slice(1), gift : 1}
        log("fastCreateRequestRepetitor params = ", params)
        fetch(getRequestUrl() + "fastCreateRequestRepetitor", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {  
            log("fastCreateRequestRepetitor = ", responseData)
            onResponse(responseData, onSuccess, onError)          
        })
    }
    

}