import React, { useState, useEffect } from 'react';
import { Pages, getIsDesktop } from '../../../utils/Utils';
import Draggable from 'react-draggable';
import video_logo from '../../../img/call/video_logo.svg'
import Loader from '../../../custom_views/Loader';

const VideoStreams = (props) => {
    const [isBoardLoading, setBoardLoading] = useState(false)
    const [isVideoDragging, setVideoDragging] = useState(false)
    const [isVideoSwapped, setVideoSwapped] = useState(false)
    const smallVideoRef = props.smallVideoRef
    const bigVideoRef = props.bigVideoRef
    const smallVideoOpponent = props.smallVideoOpponent
    const aspectRatio = props.aspectRatio
    const isIncomingCall = props.isIncomingCall
    const isCallAccepted = props.isCallAccepted
    const isVideoOn = props.isVideoOn
    const isInterview = props.isInterview
    const user = props.user
    const callToUser = props.callToUser ? props.callToUser : {name : '', surname : ''}
    const largeVideoStyle = {left : 0, right : 'calc(100vw / 2)', top : 0, width : 'calc(100vw / 2)', bottom : 0, objectFit : 'cover', height : '100vh', position : 'fixed', zIndex : '9999', minHeight : '100vh', transition : 'none', opacity : 1, borderRadius : 0, filter : 'none', cursor : 'auto'}
    const smallVideoContainerStyle = {left : 'calc(100vw / 2)', right : 0, top : 0, width : 'calc(100vw / 2)', bottom : 0, objectFit : 'cover', height : '100vh', position : 'fixed', zIndex : '9999', minHeight : '100vh', transition : 'none', opacity : 1, borderRadius : 0, filter : 'none', cursor : 'auto'}
    const isScreenShareEnabled = props.isScreenShareEnabled
    const isBoardEnabled = props.isBoardEnabled
    const smallVideoStyle = {
        width: '100%',
        objectFit: 'cover',
        height: '100vh',
        zIndex: '9999',
        position: 'absolute',
        left: '0px',
        bottom: 0,
        minHeight: '100vh',
        borderRadius : 0,
        pointerEvents : 'none'
    }
    // useEffect(() => {
    //     if (isBoardEnabled){
    //         console.log('frame isBoardEnabled = ', isBoardEnabled)
    //         const frame = document.getElementById("frame_board")
    //         const startPolling = () => {
    //             console.log('frame startPolling frame = ', frame)
    //             if (frame.querySelector(".modal")) {
    //                 console.log('frame react is ready')
    //                 return 
    //             }
    //             setTimeout(startPolling, 1000)
    //         }
    //         frame.onload = () => {
    //             console.log('frame onload')
    //             startPolling()
    //         }
    //     }
    // }, [isBoardEnabled])
    useEffect(() => {
        if (isBoardEnabled){
            setBoardLoading(true)
            const frame = document.getElementById("frame_board")
            
            frame.onload = () => {
                console.log('frame onload')
                setBoardLoading(false)
            }
        } else {
            setBoardLoading(false)
        }
    }, [isBoardEnabled])
    
    const swapVideo = () => {
        const smallRefSrc = smallVideoRef.current.srcObject
        const bigVideoRefSrc = bigVideoRef.current.srcObject
        props.playVideo(smallVideoRef, bigVideoRefSrc)
        props.playVideo(bigVideoRef, smallRefSrc)
        setVideoSwapped(!isVideoSwapped)
    }
    const onClick = (e) => {
        if (e.detail == 2){
            console.log("on double click")
        }
    }
    const turnOffIntreview = () => {
        if (isInterview && isDesktop){
            props.setInterview(false)
        }
    }
    console.log("props.isBoardEnabled = ", props.isBoardEnabled)
    const boardUrl = (props.checkRepetitorResult && props.checkRepetitorResult.dialogModel) ? Pages.call_board + props.checkRepetitorResult.dialogModel.dialog_id + "?Iframe=1" : ""
    const isDesktop = getIsDesktop()
    return (
        <div onClick={turnOffIntreview} style={isIncomingCall ? {display : 'none'} : {}}>
            <div className='relative'>
                <video style={isInterview ? largeVideoStyle : (isDesktop && isCallAccepted) ? aspectRatio > 1 ? {height : '100vh', width : 'calc(100vh / ' + aspectRatio + ')'} : {width : '100vw', height : 'calc(100vw * ' + aspectRatio + ')'} : {}} id="big_video" playsInline className={(isDesktop && isCallAccepted) ? (isVideoSwapped && !isScreenShareEnabled) ? "call_screen_desktop_video mirror_video" : "call_screen_desktop_video" : "call_screen_video"} ref={bigVideoRef} muted={true}></video>
                {(!isInterview && isBoardEnabled) && <iframe id="frame_board" title='board' style={(isDesktop && isCallAccepted) ? aspectRatio > 1 ? {height : '100vh', width : 'calc(100vh / ' + aspectRatio + ')'} : {width : '100vw', height : 'calc(100vw * ' + aspectRatio + ')'} : {}} className={(isDesktop && isCallAccepted) ? "call_screen_desktop_video call_board_frame" : "call_screen_video call_board_frame"} src={boardUrl}></iframe>}
                {isBoardLoading && <div className='call_board_loader'>
                    <Loader style={{minHeight : '32px', marginTop : '12px', marginBottom : '0', position : 'relative'}}/>
                    <div style={{marginTop : '16px'}}>Открывается<br/>интерактивная<br/>доска</div>
                </div>}
            </div>
           
            <Draggable disabled={isInterview} onClick={onClick} onDrag={() => setVideoDragging(true)} onStop={() => {
                    if (!isVideoDragging) {
                        swapVideo()
                    }
                    setVideoDragging(false)
                }}>
                <div style={isInterview ? smallVideoContainerStyle : {zIndex : 2}} className="call_screen_mini_container call_screen_mini_1_container">
                    {!isVideoOn && <div style={isVideoOn ? {pointerEvents : 'none', opacity : 0} : isInterview ? smallVideoStyle : {zIndex : 2}} className="call_screen_video_small call_screen_video_small_bg">
                        <p>{user.name}<br/>{user.surname}</p>
                    </div>}
                    
                    <video style={!isVideoOn ? {pointerEvents : 'none', opacity : 0, display : 'none', visibility : 'collapse'} : isInterview ? smallVideoStyle : {zIndex : 2}} playsInline className={(!isVideoSwapped && !isScreenShareEnabled) ? "call_screen_video_small mirror_video hover" : "call_screen_video_small hover"} ref={smallVideoRef} muted={true}></video>
                </div>
                
                {/* <video style={!isVideoOn ? {pointerEvents : 'none', opacity : 0} : isInterview ? smallVideoStyle : {zIndex : 2}} playsInline className="call_screen_video_small hover" ref={smallVideoRef} muted={true}></video> */}
            </Draggable>

            <Draggable>
                <div style={{zIndex : 2}} className="call_screen_mini_container call_screen_mini_2_container">
                    <video playsInline className="call_screen_video_small call_screen_video_small_opponent hover" ref={smallVideoOpponent} muted={true}></video>
                    <div style={{pointerEvents : 'none', opacity : 0, zIndex : 2, display : 'none'}} className="call_screen_video_small call_screen_video_small_bg call_screen_video_small_bg_2">
                        <p>{callToUser.name}<br/>{callToUser.surname}</p>
                    </div>
                </div>
            </Draggable> 
            {isInterview && <img alt='' className="video_logo_img" src={video_logo}/>}
        </div>
    )
}

export default VideoStreams;