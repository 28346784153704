import stats from '../../../img/profile/ads/stats.svg'
import { Pages, getTargetStatsUrl } from '../../../utils/Utils';

function ProfileAdsStats(props) {
    const user = props.user
   
    return (
        <div className="profile_ads_item">
            <div className="flex">
                <div style={{width : '100%'}}>
                    <div className="profile_ads_item_title">Детальная статистика</div>
                    <div>Показывает переходы с рекламы в ваш профиль по дням.</div>
                    <a href={getTargetStatsUrl(user)} target="_blank"><div className="profile_ads_item_btn hover">Посмотреть статистику</div></a>

                </div>
                <img className="profile_ads_item_stats" src={stats}/>
            </div>
        </div>
    );
}

export default ProfileAdsStats;
