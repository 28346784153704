/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import radio_on from '../../../img/create_ads/radio_on.svg'
import radio_off from '../../../img/create_ads/radio_off.svg'
import bg_selected from '../../../img/call/bg_selected.svg'
import bg_desktop_light from '../../../img/call/bg_desktop_light.svg'
import bg_desktop_dark from '../../../img/call/bg_desktop_dark.svg'
import { setCookie, getCookie } from '../../../utils/CookieHelper';
import { UploadApi } from '../../../api/UploadApi';
import Loader from '../../../custom_views/Loader';
// import {
//     isSupported
// } from '@vkontakte/calls-video-effects';
const FilterTypes = {
    NO_FILTER: 0,
    BLUR: 1,
    UPLOADED_IMG: 2,
    DEFAULT_IMG: 3,
}
const cookieFilter = "call_bg_filter"
function CallBGPickerDialog(props) {
    const [filters, setFilters] = useState([{id : FilterTypes.NO_FILTER, title : "Без фильтров"}, {id : FilterTypes.BLUR, title : "Размыть фон"}, {id : FilterTypes.UPLOADED_IMG, title : "Моя картинка"}, {id : FilterTypes.DEFAULT_IMG, title : "Выбрать из предложенных", isDark : true}])
   
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [pickedItem, setPickedItem] = useState({id : FilterTypes.NO_FILTER, title : "Без фильтров"})
    const isDesktop = getIsDesktop()
   
    
    useEffect(() => {
        const savedItem = getCookie(cookieFilter)
        console.log("savedItem = ", savedItem)
        if (savedItem == null || savedItem == undefined || savedItem == "" || savedItem == "no_filter"){
            setPickedItem(filters[0])
            setSelectedIndex(0)
            
        } else if (savedItem == "blur"){
            setPickedItem(filters[1])
            setSelectedIndex(1)
        } else if (savedItem == "true" || savedItem == "false"){
            var mItem = {...filters[3]}
            mItem.isDark = savedItem === "true"
            setPickedItem(mItem)
            setSelectedIndex(3)
            var mFilters = [...filters]
            mFilters[3] = mItem
            setFilters(mFilters)
            console.log("mFilters = ", mFilters)
        } else {
            var mItem = {...filters[2]}
            mItem.img = savedItem
            setPickedItem(mItem)
            setSelectedIndex(2)
            var mFilters = [...filters]
            mFilters[2] = mItem
            setFilters(mFilters)
        }
    }, [])
    const onPicked = (item) => {
        setPickedItem(item)
        if (item.id == FilterTypes.UPLOADED_IMG){
            var mFilters = [...filters]
            mFilters[2] = item
            setFilters(mFilters)
        }
    }
    const onUse = () => {
        if (pickedItem.id == FilterTypes.NO_FILTER){
            setCookie(cookieFilter, "no_filter")
        } else if (pickedItem.id == FilterTypes.BLUR){
            setCookie(cookieFilter, "blur")
        } else if (pickedItem.id == FilterTypes.UPLOADED_IMG){
            setCookie(cookieFilter, pickedItem.img)
        } else if (pickedItem.id == FilterTypes.DEFAULT_IMG){
            setCookie(cookieFilter, pickedItem.isDark + "")
        }
        console.log("save Item pickedItem = ", pickedItem)
        props.setShow(false)
        props.applySavedEffects()
    }
    const isBtnDisabled = pickedItem.id == FilterTypes.UPLOADED_IMG && pickedItem.img == undefined
    const getInnerHtml = () => {
        return(
            <div className="call_picker_container">
                {/* <div className="flex">
                    <img onClick={props.onBack}  className="call_bg_picker_back hover" src={back_arrow}/>
                    <div className="call_bg_picker_title">Изменить фон видео</div>
                    
                </div> */}
                <div className="call_bg_picker_title">Изменить фон видео</div>
                <RadioItem pickedItem={pickedItem} item={filters[0]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} onPicked={onPicked}/>
                <RadioItem pickedItem={pickedItem} item={filters[1]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} onPicked={onPicked}/>
                {/* {isSupported && <RadioItem pickedItem={pickedItem} item={filters[2]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} onPicked={onPicked}/>}
                {isSupported && <RadioItem pickedItem={pickedItem} item={filters[3]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} onPicked={onPicked}/>} */}
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={isBtnDisabled ? {margin : '0 24px', opacity : 0.2, pointerEvents : 'none'} : {margin : '0 24px'}} onClick={onUse} className="call_price_continue hover">Применить</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CallBGPickerDialog;
var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
const RadioItem = ({ item, selectedIndex, setSelectedIndex, onPicked }) => {
    const [isDarkImg, setIsDarkImg] = useState(true)
    const [fileError, setFileError] = useState("")
    const [isLoading, setLoading] = useState(false)
    const fileUpload = useRef(null)
    useEffect(() => {
        console.log("item.isDark = ", item.isDark)
        setIsDarkImg(item.isDark)
    }, [item])
    const onImgClicked = (isDark) => {
        setIsDarkImg(isDark)
        if (selectedIndex == 3){
            var mItem = {...item}
            mItem.isDark = isDark
            onPicked(mItem)
        }
    }
    const onRadioClicked = () => {
        setSelectedIndex(item.id)
        onPicked(item)
    }
    const fileChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            uploadFile(event.target.files[0])
        }
       
    }
    const uploadFile = (file) => {
        setLoading(true)
        setFileError("")
        pickedFile = file
        fr.onload = onLoadedFileReader
        fr.readAsDataURL(file);
    }
    const onImgLoaded = () => {
        if (img.width < 800 || img.width < 600){
            setLoading(false)
            setFileError("Размеры файла меньше допустимого")
        } else if (img.width > 3200 || img.width > 2400){
            setLoading(false)
            setFileError("Размеры файла больше допустимого")
        } else {
            uploadToServer(pickedFile)
        }
        // if (img.width > 1280){
        //     this.setState({isShowWidthError : true, isImageLoading : false})
        // } else {
        //     var scale = img.height / img.width 
        //     var height = 120 * scale
        //     uploadToServer(height, pickedFile)
        // }
        
    }
    const onLoadedFileReader = () => {
        img.onload = onImgLoaded
        img.src = fr.result;
    }
    const uploadToServer = (file) => {
        // var data = new FormData()
        // data.append('file1', file)
        console.log("uploadToServer file = ", file)
        UploadApi.uploadMedia(file, (responseData) => {
            console.log("uploadMedia response = ", responseData)
            //props.setAvatarUrl(responseData.url)
            var mItem = {...item}
            mItem.img = responseData.url
            onPicked(mItem)
            setSelectedIndex(2)
            setLoading(false)
        }, 
        () => {setLoading(false)})
    }
    const isDesktop = getIsDesktop()
    return (
        <div className="call_bg_picker_just">
            <div style={{width : '100%'}} className="create_ads_radio_container">
                <img onClick={onRadioClicked} style={{marginRight : '0'}} className="create_ads_radio_icon hover" src={selectedIndex == item.id ? radio_on : radio_off}/>
                <div style={{width : '100%'}}>
                    <div onClick={onRadioClicked} className="call_bg_picker_radio_t hover">{item.title}</div>
                    {item.id == 2 && <div className="call_bg_picker_uploaded_cont">
                        {isLoading ? <div className="call_bg_picker_loader"><Loader style={{minHeight : '40px', margin : '16px 0 0 16px'}}/></div> : item.img != undefined ?
                        <img className="call_bg_picker_loaded_img" src={item.img}/> :
                        <div >
                            <div className="call_bg_picker_pick_desc">Требования к картинке: *.jpg<br/>Размер: от 600х800 до 2400х3200 px</div>
                            {fileError != "" && <div className="call_bg_picker_pick_error">{fileError}</div>}
                        </div>}
                    </div>}
                    {item.id == 3 && <div className="call_bg_picker_images">
                        <div onClick={() => onImgClicked(true)} style={{marginRight : '8px', width : '100%'}} className="relative hover">
                            <img className="call_bg_picker_img" src={bg_desktop_dark}/>
                            {isDarkImg && <img className="call_bg_picker_img_selected" src={bg_selected}/>}
                        </div>
                        <div onClick={() => onImgClicked(false)} style={{marginLeft : '8px', width : '100%'}} className="relative hover">
                            <img className="call_bg_picker_img" src={bg_desktop_light}/>
                            {!isDarkImg && <img className="call_bg_picker_img_selected" src={bg_selected}/>}
                        </div>
                    </div>}
                </div>
                
            </div>
            {item.id == 2 && <div>
                <div onClick={() => fileUpload.current.click()} className="call_bg_picker_pick hover">Выбрать</div>
                <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>

            </div>}
        </div>
    );
}

