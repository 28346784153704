
function AuthErrorTooltip(props) {
   if (props.text == ""){
       return(<div/>)
   }
    return (

        <div style={props.styleMain ? props.styleMain : {}} className="relative auth_error_container">
            <div className="red_rectangle"></div>
            <div style={props.style ? props.style : {}} className="auth_error_bg">{props.text}</div>
        </div>
    );
}

export default AuthErrorTooltip;
