import { Pages, getIsDesktop } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import phone_24 from '../../../img/lk/phone_24.svg'
function LKCreateVideoCall(props) {
    const btnHTML = () => {
        return(
            <Link to={Pages.create_video_meeting}>
                <div className='lk_create_video_call_btn hover'>
                    <img alt='' src={phone_24}/>
                    <p>Создать видеовстречу</p>
                </div>
            </Link>
        )
    }
    const isDesktop = getIsDesktop()
    if (!isDesktop){
        return(
            btnHTML()
        )
    }
    return (
        <div className="lk_create_video_call">
            {btnHTML()}
            <div className='lk_create_video_call_desc'>Вы создаёте встречу — ваши клиенты подключаются по ссылке. Удобно для индивидуальных и групповых встреч. </div>

        </div>)
}

export default LKCreateVideoCall;