import React from 'react';

class FilterDropdown extends React.Component {
    constructor(props){
        super(props)
    }
    onFilterPicked = (filter, index) => {
        this.props.onFilterPicked(filter, index)
    }
    onCloseFilters = () => {
        if (this.props.onCloseFilters){
            this.props.onCloseFilters()
        }
    }
    render(){
      
            return (
            
                    <div style={this.props.style} className="apps_dropdown filter_dropdown">
                        {!this.props.isHideTooltipBg && <div onClick={this.onCloseFilters} className="tooltip_bg"></div>}
                        {this.props.filters.map((filter, index) => (
                            <div onClick={() => this.onFilterPicked(filter, index)} className="flex apps_line" filter={filter} key={index}>
                                <p className="apps_drop_text">{filter}</p>
                            </div>   
                        ))}
                        
                    </div>
             
            ); 
        
        
    }
}
export default FilterDropdown;
