import search_icon from '../../../img/experts/search.svg'
import { useState, useRef, useEffect,useCallback } from 'react';
import { DebounceInput } from 'react-debounce-input';
import FilterDropdown from '../../../custom_views/FilterDropdown';
import { useHistory } from "react-router-dom";
import { Pages } from '../../../utils/Utils';

function SearchExpertsDesktop(props) {
    const inputRef = useRef(null)
    const [isFocused, setFocused] = useState(false)
    const [filteredSpecs, setFilteredSpecs] = useState([])
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
       if (inputRef != undefined && inputRef.current != undefined && props.isFocused){
            inputRef.current.focus()
       }
       
    }, [])
    useEffect(() => {
        if (props.specializations != undefined && props.specializations.length != 0){
            setFilteredSpecs(props.specializations)
        } 
     }, [props.specializations])
   
    const onInputFocus = () => {
        setFocused(true)
    }
    const onInputBlur = () => {
        setTimeout(function() {
            setFocused(false)
        }, 300)
       
    }
    const onInputChanged = (e) => {
        const text = e.target.value
        props.setText(text)
        const filtered = props.specializations.filter(spec => spec.toLowerCase().includes(text.toLowerCase()))
        setFilteredSpecs(filtered)
    }
    const onFilterPicked = (filter, index) => {
        props.setText(filter)
        loadUsers(filter)
    }
   
    const handleOnEnter = (e) => {
        if (e.key === 'Enter' && props.text != undefined && props.text != "") {
            loadUsers(props.text)
        }
    }
    const loadUsers = (text) => {
        history.push(Pages.search + "?text=" + text)
    }
    return (
        <div className="relative">
            <div className="flex">
                <div onKeyDown={handleOnEnter} onBlur={onInputBlur} onFocus={onInputFocus} style={isFocused ? {border: '1px solid #AC92ED', padding : '0 23px'} : {}} className="experts_search_container experts_search_input_br">
                    <img className="search_experts_icon" src={search_icon}/>
                    <input style={isFocused ? {padding : '27px 0'} : props.isDisabled ? {pointerEvents : 'none'} : {}} ref={inputRef} placeholder="Поиск эксперта" className="experts_search_input " type="text" value={props.text} onChange={(e) => onInputChanged(e)}/>
                </div>   
            </div>
            <div className="experts_seach_line"/>
            {/* specializations={props.specializations} */}
            {(filteredSpecs.length > 0 && isFocused) && <FilterDropdown style={{left : '64px', top : '42px', width : 'calc(100% - 58px)'}} filters={filteredSpecs} onFilterPicked={onFilterPicked}/>}

        </div>
        
    );
}

export default SearchExpertsDesktop;
