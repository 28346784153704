/* eslint-disable eqeqeq */

import avatar from '../../../img/avatar.svg'
import { getIsDesktop, getUrlParameter, Pages } from '../../../utils/Utils';
import React, { useEffect, useState} from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import { getUserUrl } from '../../../utils/CookieHelper';
import { UsersApi } from '../../../api/UsersApi';

function ExpertsReviews(props) {
    const [reviews, setReviews] = useState([])
    const isDesktop = getIsDesktop()

    useEffect(() => {
        getReviews()
    }, [])
    const swapElements = (array, index1, index2) => {
        let temp = array[index1];
        array[index1] = array[index2];
        array[index2] = temp
        return array
    }
    const getReviews = () => {
        UsersApi.getMainScreenReviews((responseData) => {
            var mReviews = responseData.reviews
            if (!isDesktop){
                const foundIndex = mReviews.findIndex((obj => obj.title == 'Репетитор по английскому'))
                if (foundIndex != -1){
                    mReviews = swapElements(mReviews, 0, foundIndex)
                }
            } 
            setReviews(mReviews)
        }, () => {})
    }
    // if (reviews.length == 0){
    //     return null
    // }
    return (
        <div style={reviews.length == 0 ? {} : {opacity : 1}}  className="best_exp_container opacity_list_messages">
            <p style={isDesktop ? {paddingTop : '0'} : {marginTop : '12px'}} className="experts_title_section experts_online">Отзывы об экспертах</p>
       
            <div className="no_horizontal_scroll">
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {reviews.length > 0 && reviews.map((item, index) => (
                        <Card item={item} itemId={item._id} key={index} index={index}/>
                    ))}
                </ScrollMenu>
                {/* {isDesktop && <div className="profile_best_exp_gradient"></div>} */}
            </div> 
            
        </div>
    );
}

function Card({ item, itemId, index }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isDesktop = getIsDesktop()
    const from_search = getUrlParameter("from_search")
    var url = getUserUrl(item)
    if (from_search != null && from_search != undefined && from_search != ""){
        url += "?from_search=1"
    }
    return (
            <div index={index} key={index} style={index == 0 ? {marginLeft : isDesktop ? '52px' : '16px'} : {}} className={'experts_review_item'}>
                <div className='experts_review_title'>{item.title}</div>
                <a rel="noreferrer" href={item.target_url} target="_blank"><div className='experts_rep_name hover'>{item.repetitor_name}</div></a>
                <div style={{marginBottom : '12px'}}>{item.price}</div>
                <div style={{marginBottom : '12px'}}>{item.text}</div>
                <div className='flex'>
                    <img alt='' src={item.from_avatar ? item.from_avatar : avatar}/>
                    <div className='experts_from_name'>{item.from_name}</div>
                </div>
                
                
            </div> 
    );
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img alt='' style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_review_arrow hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img alt='' style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_review_arrow experts_arrow_right hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}

export default ExpertsReviews;
