import React, { useState, useEffect, useCallback, useRef } from 'react';

function Typing(props) {
    const [currentDot, setCurrentDot] = useState(0)
    const currentDotStyle = {height : '5px', width : '5px', margin : '7px 0.5px 0 0.5px'}
    useEffect(() => {
        // let interval = null;
        // interval = setInterval(() => {
        //     var dot = currentDot + 1
        //     if (dot == 3){
        //         dot = 0
        //     }
        //     setCurrentDot(dot)
        // }, 400)
    }, [currentDot])
    return (
        // <div className="flex">
        //     <div class="typing_dot_container">
        //         <div style={currentDot == 0 ? currentDotStyle : {}} className="typing_dot"/>
        //         <div style={currentDot == 1 ? currentDotStyle : {}} className="typing_dot"/>
        //         <div style={currentDot == 2 ? currentDotStyle : {}} className="typing_dot"/>
        //     </div>
        //     <p style={{color : "#AC92ED"}} className="msgs_profile_desc">печатает</p>
        // </div>
        <div className="flex">
            {/* <div className="dot-pulse"></div> */}
            <div className="typing_dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <p style={{color : "#AC92ED", marginLeft : '22px'}} className="msgs_profile_desc">печатает</p>
        </div>
    );
}

export default Typing;
