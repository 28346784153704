import '../../styles/menu.css'
import { useState } from 'react';
import avatar from '../../img/avatar.svg'
import left_bookmarks from '../../img/menu/left_bookmarks.svg'
import left_chats from '../../img/menu/left_chats.svg'
import left_experts from '../../img/menu/left_experts.svg'
import menu_arrow from '../../img/menu/menu_arrow.svg'
import logo_left_menu from '../../img/menu/logo_left_menu.svg'
import left_ads from '../../img/menu/left_asd.svg'
import left_blogs from '../../img/menu/left_blogs.svg'
import lk_desktop from '../../img/menu/lk_desktop.svg'
import badge from '../../img/profile/badge.svg'

import { Link } from "react-router-dom";
import WriteToDevelopersDialog from './dialogs/WriteToDevelopersDialog';
import { Pages } from '../../utils/Utils';
import { getCookie, getUserUrl } from '../../utils/CookieHelper';

function LeftMenu(props) {
    const [currentLink, setCurrentLink] = useState("/123")
    const [isMenuOpened, setMenuOpened] = useState(false)
    const [isWriteToDevelopersOpened, setWriteToDevelopersOpened] = useState(false)
    const user = props.user
    const isRepetitor = user.is_repetitor
    const onLinkClicked = (link) => {
        setCurrentLink(link)
        setMenuOpened(false)
    }
    const writeToDevelopers = () => {
        setWriteToDevelopersOpened(!isWriteToDevelopersOpened)
        setMenuOpened(false)
    }
    return (
        <div style={isMenuOpened ? {minWidth : '267px'} : {}} className="left_menu_container">
            {isMenuOpened && 
            <div>
                <img alt="" className="logo_left_menu" src={logo_left_menu}/>
                <div className="logo_line"/>
            </div>}
            <a onClick={() => onLinkClicked(Pages.profile)} className="hover" rel="noreferrer" target='_blank' href={getUserUrl(user)}>
                <div className="relative hover">
                    <img alt="" className={isMenuOpened ? "left_menu_avatar_opened animate_all" : "left_menu_avatar animate_all"} src={user.avatar ? user.avatar : avatar}/>
                    {(isRepetitor) && <img alt="" style={isMenuOpened ? {opacity : 1} : {transition : 'none'}} className="menu_badge" src={badge}/>}
                </div>
            </a>
            <p style={isMenuOpened ? {opacity : 1} : {transition : 'none', visibility : "collapse", height : 0}} className="left_menu_name">{user.name} {user.surname}</p>
            <div className="left_menu_items">
                <LeftMenuItem link={Pages.experts} img={left_experts} title={"Главная"} onLinkClicked={onLinkClicked}/>
                <LeftMenuItem link={Pages.chats} img={left_chats} title={"Сообщения"} onLinkClicked={onLinkClicked} unreadedMsgs={props.unreadedMsgs}/>
                <LeftMenuItem link={Pages.ads_board} img={left_ads} title={"Объявления"} onLinkClicked={onLinkClicked}/>
                <LeftMenuItem link={Pages.blogs} img={left_blogs} title={"Блоги "} onLinkClicked={onLinkClicked}/>
                <LeftMenuItem link={Pages.bookmarks} img={left_bookmarks} title={"Избранное"} onLinkClicked={onLinkClicked}/>
                <LeftMenuItem link={Pages.lk} img={lk_desktop} title={"Кабинет"} onLinkClicked={onLinkClicked}/>
                {/* <LeftMenuItem link={Pages.answers} img={left_answers} title={"Ответы"} onLinkClicked={onLinkClicked}/> */}
            </div>
            <div onClick={writeToDevelopers} style={isMenuOpened ? {opacity : 1} : {transition: 'opacity 0ms', pointerEvents : 'none'}} className="write_to_dev_menu">Написать разработчикам</div>
            <div onClick={() => setMenuOpened(!isMenuOpened)} className="menu_arrow_container_h hover">
                <div className="menu_arrow_container">
                    <img alt="" style={isMenuOpened ? {rotate : '180deg'} : {}} className="menu_arrow" src={menu_arrow}/>
                </div>
            </div>
            <WriteToDevelopersDialog isShow={isWriteToDevelopersOpened} setShow={setWriteToDevelopersOpened}/>

        </div>
    );
}

export default LeftMenu;

const LeftMenuItem = ({ link, img, title, onLinkClicked, unreadedMsgs }) => {
    const isSelected = window.location.pathname.includes(link)

    return (
        <Link onClick={() => onLinkClicked(link)} className="" to={link}>
            <div className="hover">
                <div className="relative left_item_icon">
                    <div style={{width : '24px', height : '24px'}} className="relative">
                        <img style={isSelected ? {} : {opacity : 0.2}} className="left_menu_item_cont" src={img}/>
                        {(unreadedMsgs != undefined && unreadedMsgs > 0) && <div className="round_msg_red"></div>}
                    </div>
                    
                </div>
               
                <p className="left_item_title">{title}</p>
            </div>
        </Link>
    )
}