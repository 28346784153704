/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../styles/messages.css'
import { useState, useEffect } from 'react';
import search_chat from '../../img/chat/search_chat.svg'
import crown from '../../img/chat/crown.svg'
import { getIsDesktop, getUrlParameter, getUnreadCountMsgs, Pages } from '../../utils/Utils';
import ChatItem from './list_items/ChatItem';
import Chat from './Chat';
import Loader from '../../custom_views/Loader';
import EmptyView from '../../custom_views/EmptyView';
import { Route } from "react-router-dom";
import { UsersApi } from '../../api/UsersApi';
import { getCookie, isUserAuthed, setCookie } from '../../utils/CookieHelper';
import NotAuthed from '../profile/custom_views/NotAuthed';
import { Link } from 'react-router-dom/cjs/react-router-dom';

var savedIdsTyping = []
function Chats(props) {
    const [currentChat, setCurrentChat] = useState(undefined)
    const [idsTyping, setIdsTyping] = useState([])
    const dialogs = props.dialogs
    const isLoading = props.isLoading
    // const isLoading = true
    const isDesktop = getIsDesktop()
    const chatID = getUrlParameter("id")
    const config = props.config
    const emptyMobileStyle = {top: 0, width: '100vw', position: 'absolute', minHeight: 'calc(100vh - 60px)'}
    const isAuthed = isUserAuthed()
    const user = props.user
    useEffect(() => {
        const msg = props.socketMessage
        if (msg != undefined && msg.event === "message"){
            const isMessageReceived = msg.to_id == getCookie("id")
            messageReceived(msg, isMessageReceived)
            // if (msg.balanceHasBeenUpdated && currentChat != undefined && props.user != undefined){
            //     const msgPrice = parseInt(currentChat.dialog.rub_per_message)
            //     const moneyToUpdate = currentChat.dialog.id_repetitor == getCookie("id") ? parseFloat(msgPrice * ( 1 - config.comission)).toFixed(1) : -msgPrice
            //     props.updateBalance(moneyToUpdate)
            // } 
            
        }
        //обработка надписи печатает, чтобы не дублировалось в отрытом чате в десктопе
        if (props.socketMessage != undefined && props.socketMessage.event === "typingEvent"){
            if (!isDesktop){
                addTyping(msg)
            } else if (currentChat != undefined){
                const id = currentChat.from_id == getCookie("id") ? currentChat.to_id : currentChat.from_id
                if (msg.from_id != id){
                    addTyping(msg)
                }
            }
        }
    }, [props.socketMessage])
    const addTyping = (msg) => {
        var ids = [...idsTyping]
        if (ids.filter(i => i == msg.from_id).length == 0){
            ids.push(msg.from_id)
            setIdsTyping(ids)
            savedIdsTyping = ids
        }
    }
    const removeIdsTyping = (from_id) => {
        var ids = [...savedIdsTyping]
        var filtered = ids.filter(item => item != from_id)
        setIdsTyping(filtered)
        savedIdsTyping = filtered
    }
    // console.log("useEffect history = ", history)
    
    // if (history.action == "PUSH" && history.location.pathname == "/chats" && currentChat != undefined && !isDesktop){
    //     setCurrentChat(undefined)
    //     console.log("useEffect setCurrentChat undefined")
    // }
    
    useEffect(() => {
        if (!isDesktop){
            window.addEventListener('popstate', function(event) {
                setCurrentChat(undefined)
            }, false);
        } else {
            console.log("props.getDialogs")
            props.getDialogs()
        }
       
    }, [])
    useEffect(() => {
       if (props.dialogs != undefined && props.dialogs.length > 0 && chatID != undefined && chatID != null && chatID != "" && currentChat == undefined){
            var isFound = false
            props.dialogs.forEach(element => {
                const opponent_id = element.from_id != getCookie("id") ? element.from_id : element.to_id
                if (opponent_id == chatID){
                    setCurrentChatWithCookie(element)
                    isFound = true
                } 
            })
            if (!isFound){
                getUser(chatID)
            }
            //testing
            // messageReceived(testMsg, true)
        } else if (props.dialogs != undefined && props.dialogs.length > 0 && isDesktop && currentChat == undefined){
            setCurrentChatWithCookie(props.dialogs[0])
        } else if (props.dialogs != undefined && props.dialogs.length == 0 && isDesktop && currentChat == undefined && chatID != undefined && chatID != null && chatID != ""){
            getUser(chatID)
        }
    }, [props.dialogs])

    const getUser = (id) => {
        UsersApi.getUserByVkCallerID(id, (mUser) => {
            var chat = {user : mUser}
            chat.dialog = {dialog_id : props.user.vk_caller_id + "_" + mUser.vk_caller_id}
            var myDialogs = [...props.dialogs]
            myDialogs.splice(0, 0, chat)
            
            setCurrentChatWithCookie(chat)
            setTimeout(function() {
                props.setDialogs(myDialogs)
            }, 500)
            
        })
    }
    const setCurrentChatWithCookie = (chat) => {
        // console.log("setCurrentChatWithCookie chat = ", chat.to_user.vk_caller_id)
        setCurrentChat(chat)
        setCookie("selected_to_user", (chat.to_id == getCookie("id") ? chat.from_id : chat.to_id))
    }
    const messageReceived = (msg, isMessageReceived) => {
        var myDialogs = [...dialogs]
        var swapIndex = -1
        for (let i = 0; i < myDialogs.length; i++) {
            var dialog = myDialogs[i];
            if (dialog.dialog.dialog_id == msg.dialog_id){
                swapIndex = i
                const isThisDialogOpened = currentChat == undefined ? false : currentChat.dialog.dialog_id === dialog.dialog.dialog_id
                if (isMessageReceived && !isThisDialogOpened){
                    dialog.dialog.count_no_read_messages += 1
                    dialog.dialog.count_no_read_messages2 += 1
                    dialog.is_read = msg.is_read
                }
                // if (isMessageReceived && isThisDialogOpened){
                //     dialog.is_read = true
                // }
                dialog.type = msg.type
                dialog.text = msg.text
                break
            }
        }
        if (swapIndex != -1){
            const swapDialog = myDialogs[swapIndex]
            myDialogs.splice(swapIndex, 1)
            myDialogs.splice(0,0, swapDialog)
            props.setDialogs(myDialogs)
        } else {
            props.getDialogs()
        }
       
    } 
    const setChat = (chat) => {
        
        clearBadge(chat)
        setCurrentChat(chat)
        setCookie("selected_to_user", chat.to_user.vk_caller_id)
        console.log("setCurrentChat chat ", chat)
    }
    const clearBadge = (chat) => {
        if (props.removeUnreadedMsgs != undefined){
            var countRemoveMsgs = getUnreadCountMsgs(chat)
            props.removeUnreadedMsgs(countRemoveMsgs)
        }
        var myDialogs = [...props.dialogs]
        myDialogs.forEach(dialog => {
            if (dialog.dialog != undefined && dialog.dialog.dialog_id == chat.dialog.dialog_id){
                dialog.dialog.count_no_read_messages = 0
                dialog.dialog.count_no_read_messages2 = 0
            }
        });
       
        props.setDialogs(myDialogs)
    }
    const loadedDialogs = props.dialogs == undefined ? [] : props.dialogs
    const getNotAuthedHtml = () => {
        return(
            <div className="empty_profile_cont">
                <p className="empty_profile_title">Сообщения</p>
                <NotAuthed/>
            </div>
        )
    }
    return (
        <div className={!isAuthed ? isDesktop ? "selected_profile_container_desk" : "selected_profile_container" :  ""}>
            {!isAuthed ?
                getNotAuthedHtml()
            :
            <div className="flex">
                <div style={(!isDesktop && currentChat != undefined) ? {display : 'none'} : {}} className="chat_container">
                    <div className="search_chat_navbar">
                        <p className="messages_title">Сообщения</p>
                        <div style={{opacity : 0}} className="search_chat_cont hover">
                            <img alt="" className="search_chat_icon" src={search_chat}/>
                        </div>
                    </div>
                            
                    <div className="chat_navbar_line"/>
                    {isLoading && <Loader />}
                    {(loadedDialogs.length > 0 && user.is_repetitor && !user.is_pro) && <Link to={Pages.pro}><div className="chat_pro_version hover"><div>Больше возможностей со статусом PRO</div><img src={crown}/></div></Link>}
                    <div style={isLoading ? {} : {opacity : 1}} className="opacity_list_messages chats_p_b">
                    {loadedDialogs.map((item, index) => (
                        <ChatItem setCurrentChat={setCurrentChatWithCookie} removeIdsTyping={removeIdsTyping} idsTyping={idsTyping} currentChat={currentChat} isDesktop={isDesktop} user={props.user} setChat={setChat} index={index} chat={item} key={index}/>
                    ))}</div>
                    {(!isLoading && loadedDialogs.length == 0) && <EmptyView imageStyle={isDesktop ? {marginLeft : '100px'} : {marginLeft : 'calc(100vw/2 - 86px)'}} bgStyle={isDesktop ? {minHeight : '100vh'} : emptyMobileStyle} text="Диалоги отсутствуют"/>}
                </div>
                {isDesktop ? 
                    <Chat isChatsLoading={isLoading} isRestoredRecconnect={props.isRestoredRecconnect} dialogs={loadedDialogs} updateBalance={props.updateBalance} config={config} callToUser={props.callToUser} setCallToUser={props.setCallToUser} updateUserInfo={props.updateUserInfo} getMyInfo={props.getMyInfo} setBalance={props.setBalance} clearBadge={clearBadge} socketMessage={props.socketMessage} user={props.user} setCurrentChat={setCurrentChat} currentChat={currentChat}/>
                    : 
                    <Route path={'/*/chat'}>
                        <Chat isRestoredRecconnect={props.isRestoredRecconnect} dialogs={loadedDialogs} updateBalance={props.updateBalance} config={config} callToUser={props.callToUser} setCallToUser={props.setCallToUser} updateUserInfo={props.updateUserInfo} getMyInfo={props.getMyInfo} setBalance={props.setBalance} clearBadge={clearBadge} socketMessage={props.socketMessage} user={props.user} setCurrentChat={setCurrentChat} currentChat={currentChat}/>
                    </Route>
                }
            </div>}
        </div>
    );
}

export default Chats;
