import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, setModelParam } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function PopularDetalizationDialog(props) {
    const info = props.info
    const close = () => {
        setModelParam(props.info, "isShow", false, props.setDialogInfo)
    }
   
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}} className="call_info_container">
                <div style={{background : props.info.bgColor}} className="popular_logo_bg">
                    <img className="popular_logo_img" src={info.img}/>
                </div>
                <div className="popular_dialog_title">{info.title}</div>
                <p className="popular_dialog_text">{info.text}</p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div onClick={close} className="call_price_continue hover">Понятно</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={info.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={info.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default PopularDetalizationDialog;
