/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../styles/main.css'
import BottomMenu from './menu/BottomMenu';
import { useState, useEffect } from 'react';
import Experts from './experts/Experts';
import Profile from './profile/Profile';
import Other from './other/Other';
import { setCookie, getCookie, deleteCookie, isUserAuthed } from '../utils/CookieHelper';
import { getIsDesktop, declension, getUnreadCountMsgs, getUrlParameter, Pages } from '../utils/Utils';
import Chats from './chat/Chats';
import { w3cwebsocket as W3CWebSocket } from "websocket"
import moment from 'moment'
import 'moment/locale/ru' 
import Call from './call/Call';
import Chat from './chat/Chat';
import WriteReview from './call/custom_views/WriteReview';
import { Route, useLocation } from "react-router-dom";
import PickedTheme from './experts/custom_views/PickedTheme';
import FindExperts from './experts/custom_views/FindExperts';
import BookMarks from './experts/custom_views/BookMarks';
import DesktopNavbar from './menu/DesktopNavbar';
import LeftMenu from './menu/LeftMenu';
import { UsersApi } from '../api/UsersApi';
import { ConfigApi } from '../api/ConfigApi';
import { DialogsApi } from '../api/DialogsApi';
import YouEarnedBottomSheet from './call/dialogs/YouEarnedBottomSheet';
import Specializations from './experts/custom_views/Specializations';
import Pay from './pay/Pay';
import msg_sound from '../sound/msg_sound.mp3'
import Reviews from './profile/custom_views/Reviews';
import DeclinedCallDialog from './call/dialogs/DeclinedCallDialog';
import PayResult from './pay/PayResult';
import Verification from './profile/custom_views/Verification';
import EditProfile from './edit_profile/EditProfile';
import { CallApi } from '../api/CallApi';
import CreateAds from './ads_desk/CreateAds/CreateAds';
import AdsBoard from './ads_desk/AdsBoard/AdsBoard';
import Answers from './answers/Answers';
import ExpressSearch from './express_search/ExpressSearch';
import CreatePlanner from './planner/CreatePlanner';
import PlannedPlanner from './planner/PlannedPlanner';
import PlannerInfo from './planner/PlannerInfo';
import UserLK from './lk/UserLK';
import Blogs from './blog/Blogs';
import CreateBlogPost from './blog/CreateBlogPost';
import BlogsPost from './blog/BlogsPost';
import WorkBoards from './work_boards/WorkBoards';
import ProfileAds from './profile/profile_ads/ProfileAds';
import Pro from './other/custom_views/Pro';
import CreateVideoMeeting from './planner/CreateVideoMeeting';
import GroupCall from './call/GroupCall';
import GroupCallInfo from './planner/GroupCallInfo';
import Course from './planner/Course';
import { isCatalogsPage, setConfigMetaTags } from './experts/utils/ExpertsUtils';
var hadNewMsgs = false
moment.locale('ru')
function Main(props) {
    const [user, setUser] = useState({})
    const [socket, setSocket] = useState(null);
    const [socketMessage, setSocketMessage] = useState(undefined);
    const [bestExperts, setBestExperts] = useState([]);
    const [specializations, setSpecializations] = useState([])
    const [consultants, setConsultants] = useState([])
    const [dialogs, setDialogs] = useState(undefined)
    const [isLoading, setLoading] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState(undefined)

    const [callToUser, setCallToUser] = useState(undefined)
    const [callerUser, setCallerUser] = useState({})
    const [currentChat, setCurrentChat] = useState(undefined)
    const [isShowChat, setShowChat] = useState(false)
    const [isShowCallChat, setShowCallChat] = useState(false)
    const [unreadedMsgs, setUnreadedMsgs] = useState(0)
    const [checkRepetitorResult, setCheckRepetitor] = useState({})
    const [isRestoredRecconnect, setRestoredRecconnect] = useState(true)
    const [isShowDeclinedCall, setShowDeclinedCall] = useState(false)
    const [prepay, setPrepay] = useState(0)
    const [writeReview, setWriteReview] = useState({isShow : false, isShowToRepetitor : false, price : 0, time : 0})
    const config = props.config
    const token = getCookie("token")
    const isTokenEmpty = token == null || token == undefined || token == ""
    const location = useLocation()

    useEffect(() => {
        console.log('Location changed config = ', config)
        if (config.seo_config){
            setConfigMetaTags(config)
            console.log('Location changed setSeoMetaTags')
        }
        
    }, [location])
    const changeCurrentChat = (chat) => {
        if (chat == undefined){
            setShowChat(false)
        } else {
            //window.history.replaceState("", "", "/chat/" + chat.user.vk_caller_id)
            setCurrentChat(chat)
            setShowChat(true)
        }
    }
    
    const getMyInfo = () => {
        UsersApi.getMyInfo((mUser) => {
            setUser(mUser)
            props.setUser(mUser)

            if (mUser.vk_call_token != getCookie("vk_call_token")){
                deleteCookie("vk_call_token")
                setCookie("vk_call_token", mUser.vk_call_token)
            }

            if (mUser.vk_caller_id != getCookie("id")){
                deleteCookie("id")
                setCookie("id", mUser.vk_caller_id)
            }
            setCookie("_id", mUser._id)

        }, (error) => {
            setCookie("token", null)
            setCookie("vk_call_token", null)
        })
    }
    
    const getBestExperts = () => {
        ConfigApi.getBestExperts((responseData) => {
            setBestExperts(responseData.users)
        })
    }
    const updateBalance = (balance) => {
        if (user.count_rub != undefined){
            var myUser = { ...user }
            myUser.count_rub = parseFloat(myUser.count_rub) + parseFloat(balance)
            setUser(myUser)
        }
    }
    const setBalance = (balance) => {
        if (user.count_rub != undefined){
            var myUser = { ...user }
            myUser.count_rub = balance
            setUser(myUser)
        }
    }
    const updateUserInfo = (user) => {
        setUser(user)
        props.setUser(user)
    }
    const getDialogs = () => {
        setLoading(true)
        var countUnread = unreadedMsgs
        DialogsApi.getDialogs((responseData) => {
            // console.log("getDialogs = ", responseData.dialogs)
            const loadedDialogs = responseData.dialogs.filter((d) => d.dialog != undefined)
            loadedDialogs.forEach(dialog => {
                countUnread += getUnreadCountMsgs(dialog)
            });
            if (countUnread > 0){
                setUnreadedMsgs(countUnread)
            }
            setDialogs(loadedDialogs)
            setLoading(false)
        }, () => {setLoading(false)})
    }
    useEffect(() => {
        connectSoccet()
        setInterval(() => {
           if (!navigator.onLine){
                setRestoredRecconnect(false)
                connectSoccet(true)
           }
        }, 2000)
    }, [setSocket]);
    useEffect(() => {
        if (!isUserAuthed()){
            return
        }
        let interval = null;
        const standartTitle = "Общайтесь с экспертами и знаменитостями в Репетиторс.рф"
        document.title = standartTitle
        if (unreadedMsgs > 0) {
            interval = setInterval(() => {
                if (document.title == standartTitle){
                    document.title = unreadedMsgs + declension(unreadedMsgs, " новое сообщение", " новых сообщения", " новых сообщений")
                } else {
                    document.title = standartTitle
                }
            }, 1000)
            hadNewMsgs = true
        } else {
            clearInterval(interval)
            setTimeout(function() {
                if (hadNewMsgs){
                    document.title = standartTitle
                }
                
                hadNewMsgs = false
            }, 1500)
           
        }
        return () => clearInterval(interval);
    }, [unreadedMsgs])
    const connectSoccet = (isRecconectingFromTimer) => {
        const client = new W3CWebSocket('wss://sozvon.pro:9000/');
        setSocket(client)

        client.onclose = function(e) {
            if (!isRecconectingFromTimer){
                console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
                setTimeout(function() {
                    connectSoccet()
                }, 1000);
            }
        }
 
        client.onopen = () => {
            console.log('WebSocket Client Connected');
            client.send(JSON.stringify({
                event : "auth",
                access_token : getCookie("token")
            }))
            
        };
        client.onmessage = (message) => {
            console.log("message = " + message.data)
            const msg = JSON.parse(message.data)
            setSocketMessage(msg)

            setRestoredRecconnect(true)
        }
        // setTimeout(function() {
        //     if (navigator.onLine){
                
        //         console.log("setRestoredRecconnect(true)")
        //     }
        // }, 1000)
       
        
    }
    
    useEffect(() => {
        if (!isTokenEmpty){
            getMyInfo()
            getDialogs()
            checkCall()
        }
        getBestExperts()
        getSpecializations() 
        //getPushToken(user)       
    }, [setUser])

    useEffect(() => {
        if (socketMessage != undefined && socketMessage.event == "incomingCall" && callToUser == undefined){
            getCallerInfo(socketMessage)
        }
        if (socketMessage != undefined && socketMessage.event == "changeBalance"){
            setUser(socketMessage.my_info)
            props.setUser(socketMessage.my_info)
        }
        if (socketMessage != undefined && socketMessage.event == "buyPro"){
            setUser(socketMessage.my_info)
            props.setUser(socketMessage.my_info)
        }
        if (socketMessage != undefined && socketMessage.event == "endCallEvent"){
            setCallToUser(undefined)
            setCallerUser(undefined)
        }
        if (socketMessage != undefined && socketMessage.event == "message"){
            if (socketMessage.to_id == getCookie("id")){
                //if (socketMessage.from_id)
                var msgsCount = unreadedMsgs + 1
                setUnreadedMsgs(msgsCount)
                var audio = document.getElementById("audio");
                audio.volume = 0.2
                audio.play()
            }
            var hasChatInList = false
            const chatID = socketMessage.dialog_id
            if (dialogs){
                dialogs.forEach(element => {
                    if (element.dialog.dialog_id == chatID){
                        hasChatInList = true
                    } 
                })
            }
            
            if (!hasChatInList){
                getDialogs()
            }
        }
    }, [socketMessage])
    const checkCall = () => {
        const call_id = getUrlParameter("call_id")
        if (call_id != undefined && call_id != null && call_id != ""){        
            CallApi.checkCall(call_id, (responseData) => {
                if (!responseData.closed){
                    const sockedMsgInfo = {"method":"sendCallEvent","event":"incomingCall","from_id":getUrlParameter("id_from"),"to_id":getUrlParameter("id_to"), "call_id":call_id}
                    getCallerInfo(sockedMsgInfo)
                }
            })
        }
    }
    const removeUnreadedMsgs = (removeCount) => {
        var msgsCount = unreadedMsgs - removeCount
        if (msgsCount < 0){
            msgsCount = 0
        }
        setUnreadedMsgs(msgsCount)
    }
    const getCallerInfo = (socketMessage) => {
        DialogsApi.checkRepetitor(socketMessage.from_id, (responseData) => {
            if (responseData.id_student == user.vk_caller_id){
                var mUser = responseData.userRepetitor
                mUser.is_i_repetitor = false
                setCallerUser(mUser)
            } else {
                var mUser = responseData.userStudent
                mUser.is_i_repetitor = true
                setCallerUser(mUser)
            }
            setCallToUser({id : "-1", socketMessage : socketMessage})
            setCheckRepetitor(responseData)
            getActingPlanner(responseData.id_repetitor, responseData.id_student)
        })
    }
    const getActingPlanner = (id_repetitor, id_student) => {
        CallApi.get_acting_planner_by_members(id_repetitor, id_student, (responseData) => {
            if (responseData.planner && responseData.planner.prepay){
                setPrepay(responseData.planner.prepay)
            }
        })
    }
    const getSpecializations = () => {
        ConfigApi.getSpecializations((responseData) => {
            setSpecializations(responseData.specializations)
            setConsultants(responseData.specializationsConsultants)
        })
    }  
    const isDesktop = getIsDesktop()
     
    
    return (
        <div className="flex">
            
            <LeftMenu unreadedMsgs={unreadedMsgs} isTokenEmpty={isTokenEmpty} user={user}/>
            <div className="main_container">
                <div>
                    <DesktopNavbar user={user}/>
                    <div >
                        <Route path={Pages.experts}>
                            <Experts consultants={consultants} user={user} setSelectedProfile={setSelectedProfile} specializations={specializations} bestExperts={bestExperts}/>
                        </Route>
                       
                        <Route path={Pages.chats}>
                            <Chats isRestoredRecconnect={isRestoredRecconnect} removeUnreadedMsgs={removeUnreadedMsgs} config={config} callToUser={callToUser} setCallToUser={setCallToUser} getMyInfo={getMyInfo} getDialogs={getDialogs} setBalance={setBalance} isLoading={isLoading} setDialogs={setDialogs} dialogs={dialogs} updateBalance={updateBalance} socketMessage={socketMessage} user={user}/>
                        </Route>
                        <Route path={Pages.lk}>
                            <UserLK config={config} updateUserInfo={updateUserInfo} user={user} getMyInfo={getMyInfo}/>
                        </Route>
                        <Route path={Pages.blogs}>
                            <Blogs setSelectedProfile={setSelectedProfile} specializations={specializations} config={config} user={user} getMyInfo={getMyInfo}/>
                        </Route>
                        <Route path={Pages.profile}>
                            <Profile bestExperts={bestExperts} updateUserInfo={updateUserInfo} config={config} currentChat={currentChat} setCurrentChat={changeCurrentChat} callToUser={callToUser} setCallToUser={setCallToUser} isBottomMenu={true} myUser={user} user={user} getMyInfo={getMyInfo}/>
                        </Route>
                        <Route path={Pages.other}>
                            <Other getMyInfo={getMyInfo} user={user}/>
                        </Route>
                        {/* <Route path='/*'>
                            <Experts consultants={consultants} user={user} setSelectedProfile={setSelectedProfile} specializations={specializations} bestExperts={bestExperts}/>
                        </Route> */}
                        <BottomMenu user={user} unreadedMsgs={unreadedMsgs}/>
                    </div>                 
                </div>
                <Route path={Pages.theme}>
                    <PickedTheme user={user} setSelectedProfile={setSelectedProfile}/>
                </Route>
                <Route path={Pages.search}>
                    <FindExperts user={user} specializations={specializations} setSelectedProfile={setSelectedProfile}/>
                </Route>
                <Route path={Pages.list}>
                    <FindExperts isSeo user={user} specializations={specializations} setSelectedProfile={setSelectedProfile}/>
                </Route>
                <Route path={Pages.bookmarks}>
                    <BookMarks user={user} setSelectedProfile={setSelectedProfile} pickedTheme="Избранное"/>
                </Route>

                <Route path={Pages.users}>
                    <Profile bestExperts={bestExperts} updateUserInfo={updateUserInfo} config={config} getMyInfo={getMyInfo} currentChat={currentChat} setCurrentChat={changeCurrentChat} callToUser={callToUser} setCallToUser={setCallToUser} setBalance={setBalance} myUser={user} socketMessage={socketMessage} isSelectedProfile={true} setSelectedProfile={setSelectedProfile} user={selectedProfile}/>
                </Route>
                <Route path={Pages.specializations}>
                    <Specializations user={user} consultants={consultants} specializations={specializations} setSelectedProfile={setSelectedProfile}/>
                </Route>
              
                {!isDesktop && <Route path={Pages.any_chat}>
                    <Chat isRestoredRecconnect={isRestoredRecconnect} dialogs={dialogs} removeUnreadedMsgs={removeUnreadedMsgs} updateBalance={updateBalance} config={config} callToUser={callToUser} setCallToUser={setCallToUser} getMyInfo={getMyInfo} setBalance={setBalance} clearBadge={() => {}} socketMessage={socketMessage} user={user} setCurrentChat={changeCurrentChat} currentChat={currentChat}/>
                </Route>}
                {(callToUser != undefined && user != undefined) && 
                    <div className="call_screen_container flex">
                        <Call prepay={prepay} setShowDeclinedCall={setShowDeclinedCall} isShowCallChat={isShowCallChat} setShowCallChat={setShowCallChat} setWriteReview={setWriteReview} config={config} socketMessage={socketMessage} checkRepetitorResult={checkRepetitorResult} updateUserInfo={updateUserInfo} setCurrentChat={changeCurrentChat} currentChat={currentChat} callerUser={callerUser} setCallerUser={setCallerUser} callToUser={callToUser} setCallToUser={setCallToUser} user={user} getMyInfo={getMyInfo}/>
                        {(isDesktop && isShowCallChat && (callToUser != undefined || callerUser != undefined)) && <Chat fromCall isRestoredRecconnect={isRestoredRecconnect} dialogs={dialogs} removeUnreadedMsgs={removeUnreadedMsgs} updateBalance={updateBalance} config={config} setShowCallChat={setShowCallChat} isShowCallChat={isShowCallChat} callToUser={callToUser} setCallToUser={setCallToUser} getMyInfo={getMyInfo} setBalance={setBalance} clearBadge={() => {}} socketMessage={socketMessage} user={user} setCurrentChat={changeCurrentChat} currentChat={currentChat}/>}
                    </div>}
                {!isDesktop && <Route path={Pages.call_chat}>
                    <Chat isRestoredRecconnect={isRestoredRecconnect} dialogs={dialogs} removeUnreadedMsgs={removeUnreadedMsgs} updateBalance={updateBalance} config={config} callToUser={callToUser} setCallToUser={setCallToUser} getMyInfo={getMyInfo} setBalance={setBalance} clearBadge={() => {}} socketMessage={socketMessage} user={user} setCurrentChat={changeCurrentChat} currentChat={currentChat}/>
                </Route>}
                <Route path={Pages.pay}>
                    <Pay config={config} setUser={setUser}/>
                </Route>

                <Route path={Pages.reviews}>
                    <Reviews user={selectedProfile}/>
                </Route>
                <Route path={Pages.pay_result}>
                    <PayResult user={user}/>
                </Route>
                <Route path={Pages.verification}>
                    <Verification user={user} config={config}/>
                </Route>
                <Route path={Pages.edit_profile}>
                    <EditProfile getMyInfo={getMyInfo} user={user} config={config}/>
                </Route>
                <Route path={Pages.create_ads_main}>
                    <CreateAds specializations={specializations} getMyInfo={getMyInfo} user={user} config={config}/>
                </Route>
                <Route path={Pages.ads_board}>
                    <AdsBoard socketMessage={socketMessage} specializations={specializations} getMyInfo={getMyInfo} user={user} config={config}/>
                </Route>
                <Route path={Pages.answers}>
                    <Answers specializations={specializations} getMyInfo={getMyInfo} user={user} config={config}/>
                </Route>
                <Route path={Pages.express_search_main}>
                    <ExpressSearch config={config} setCallToUser={setCallToUser} socketMessage={socketMessage} bestExperts={bestExperts} specializations={specializations} getMyInfo={getMyInfo} user={user}/>
                </Route>
                <Route path={Pages.planned_planner}>
                    <PlannedPlanner config={config} getMyInfo={getMyInfo} user={user}/>
                </Route>
                <Route path={Pages.planner_info}>
                    <PlannerInfo setCallToUser={setCallToUser} config={config} getMyInfo={getMyInfo} user={user}/>
                </Route>
                <Route path={Pages.create_planner}>
                    <CreatePlanner config={config} user={user}/>
                </Route>
                <Route path={Pages.create_blog_post}>
                    <CreateBlogPost specializations={specializations} config={config} user={user}/>
                </Route>
                <Route path={Pages.blogs_post}>
                    <BlogsPost config={config} user={user}/>
                </Route>
                <Route path={Pages.work_boards}>
                    <WorkBoards config={config} user={user}/>
                </Route>
                <Route path={Pages.profile_ads}>
                    <ProfileAds getMyInfo={getMyInfo} config={config} user={user}/>
                </Route>
                <Route path={Pages.pro}>
                    <Pro getMyInfo={getMyInfo} config={config} user={user}/>
                </Route>
                <Route path={Pages.create_video_meeting}>
                    <CreateVideoMeeting config={config} user={user}/>
                </Route>
                <Route path={Pages.group_call}>
                    <GroupCall prepay={prepay} setShowDeclinedCall={setShowDeclinedCall} isShowCallChat={isShowCallChat} setShowCallChat={setShowCallChat} setWriteReview={setWriteReview} config={config} socketMessage={socketMessage} checkRepetitorResult={checkRepetitorResult} updateUserInfo={updateUserInfo} setCurrentChat={changeCurrentChat} currentChat={currentChat} callerUser={callerUser} setCallerUser={setCallerUser} callToUser={callToUser} setCallToUser={setCallToUser} user={user} getMyInfo={getMyInfo}/>
                </Route>
                <Route path={Pages.group_call_info}>
                    <GroupCallInfo setWriteReview={setWriteReview} config={config} getMyInfo={getMyInfo} user={user}/>
                </Route>
                <Route path={Pages.course}>
                    <Course config={config} getMyInfo={getMyInfo} user={user}/>
                </Route>
                {/* <CSSTransition in={writeReview.isShow} timeout={300} classNames="animation_in" unmountOnExit>
                    <WriteReview writeReview={writeReview} setWriteReview={setWriteReview}/>
                </CSSTransition> */}
                {writeReview.isShow && <WriteReview writeReview={writeReview} setWriteReview={setWriteReview}/>}
                <YouEarnedBottomSheet writeReview={writeReview} setWriteReview={setWriteReview}/>
                <DeclinedCallDialog isShow={isShowDeclinedCall} setShow={setShowDeclinedCall}/>
                
                
            </div>
            <audio style={{display : 'none'}} autoPlay={false} id="audio" src={msg_sound}></audio>

        </div>
    );
}

export default Main;
