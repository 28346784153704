import { useHistory } from "react-router-dom";
import { getMyLevels, getAges, getAdsPrices } from './helpers/CreateAdsHelper';
import CreateAdsRadioStep from './custom_views/CreateAdsRadioStep';
import { getUrlParameter } from "../../../utils/Utils";
import { useEffect } from 'react';
import { AdsApi } from "../../../api/AdsApi";

function CreateAdsStep6(props) {
    const history = useHistory()
    const id = getUrlParameter("id")
    const info = {
        progress : props.progress,
        pickedSpecs : props.pickedSpecs,
        header : "Стоимость занятий, которая вам комфортна",
        value : props.value,
        radioItems : getAdsPrices()
    }
    useEffect(() => {
        const value = getUrlParameter("value")
        if (value != null && value != undefined && value != ""){
            props.setValue(getAdsPrices()[value])
        }
    }, [])
    const onNext = () => {
        if (props.isEdit){
            AdsApi.editRequestRepetitor({repetitor_request_id : id, level_repetitor : getAdsPrices().indexOf(props.value)}, (response) => {
                window.location.href = '/ads_board/ads?id=' + id + "&is_details=true"
            })
        } else {
            history.push("/create_ads/step_7")
        }
    }
    return (
        <CreateAdsRadioStep isEdit={props.isEdit} info={info} onNext={onNext} setValue={props.setValue}/>
    );
}

export default CreateAdsStep6;
