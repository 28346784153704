/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import Loader from "../../../custom_views/Loader";
import back_arrow from '../../../img/experts/back_arrow.svg'
import badge from '../../../img/profile/badge.svg'
import delete_review from '../../../img/profile/delete_review.svg'
import expert_review_desc from '../../../img/profile/expert_review_desc.png'
import { useState, useEffect,useCallback } from 'react';
import { getCookie, getRequestParams } from "../../../utils/CookieHelper";
import { getRequestUrl, getHttpParams, getUrlParameter, getIsDesktop, declension, getRating, Pages, getReviewsCount, MyImage } from "../../../utils/Utils";
import avatar from '../../../img/avatar.svg'
import star_profile from '../../../img/profile/star.svg'
import star_selected from '../../../img/profile/star_selected.svg'
import moment from 'moment'
import { useHistory } from "react-router-dom";
import { UsersApi } from "../../../api/UsersApi";
import message from '../../../img/experts/message.svg'
import star from '../../../img/experts/star.svg'
import empty_reviews from '../../../img/profile/empty_reviews.png'
import AddMyReviewDialog from "../dialogs/AddMyReviewDialog";
import DeleteReviewDialog from "../dialogs/DeleteReviewDialog";
import AddClientReviewDialog from "../dialogs/AddClientReviewDialog";
import OnlineInfo from "../../profile_filling/custom_views/OnlineInfo";
function Reviews(props) {
    const [reviews, setReviews] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [user, setUser] = useState({})
    const [reviewsFromUser, setReviewsFromUser] = useState(false)
    const [isShowAddReview, setShowAddReview] = useState(false)
    const [isShowAddClientReview, setShowAddClientReview] = useState(false)
    const [isShowAddClientReviewDialog, setShowAddClientReviewDialog] = useState("")
    const [deleteReviewInfo, setDeleteReviewInfo] = useState({_id : "", isShow : false})
    const history = useHistory()
    const loadReviews = () => {
        var to_id = props.to_id
        if (window.location.href.includes(Pages.reviews + "?id=")){
            to_id = getUrlParameter("id")
        }
        console.log("to_id = " + to_id)
        setLoading(true)
        var params = getRequestParams()
        var methodName = "getReviews"
        if (to_id != undefined && String(to_id).includes("_1")){
            params.from_id = to_id.split("_1")[0]
            methodName = "getReviewsFrom"
            setReviewsFromUser(true)
        } else {
            params.to_id = to_id
        }
        getUser(getUrlParameter("user_id"))
        console.log("getReviews params = " + JSON.stringify(params))
        fetch(getRequestUrl() + methodName, getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("getReviews = ", responseData)
            if (responseData.response === 1){
                setReviews(responseData.reviews)
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        if (props.to_id != "" || window.location.pathname.includes(Pages.reviews + "?id=")){
            loadReviews()
        }
    }, [setReviews])
    
    const onBack = useCallback(() => {
        history.goBack()
    }, [history])
    const getUser = (id) => {
        UsersApi.getUsersByIDs(id, (mUser) => {
            setUser(mUser)
            if (mUser.vk_caller_id != getCookie("id")){
                checkCanReview(mUser.vk_caller_id)
            }
        })
    }
    const checkCanReview = (id) => {
        UsersApi.checkWriteReviewAvailable(id, (responseData) => {
            if (responseData.response > 0){
                setShowAddClientReview(responseData.writeReviewAvailable)
            }
        })
    }
    const onClientReviewAdded = () => {
        loadReviews()
        setShowAddClientReview(false)
    }
    const addReviewHTML = () => {
        return(
            <div className="profile_add_my_review">
                <h5>Загрузите отзывы ваших клиентов из других сервисов</h5>
                <p>По статистике нашего сервиса к экспертам с отзывами клиенты обращаются чаще</p>
                <div onClick={() => setShowAddReview(true)} className="hover">Загрузить</div>
            </div>
        )
    }
    const addReviewFromClientHTML = () => {
        return(
            <div className="profile_add_my_review">
                <h5>Пожалуйста, оставьте честный отзыв об эксперте. Это поможет потенциальным клиентом в выборе подходящего специалиста.</h5>
                <div style={{marginTop : '16px'}} onClick={() => setShowAddClientReviewDialog(user.vk_caller_id)} className="hover">Оставить отзыв</div>
            </div>
        )
    }
    const isDesktop = getIsDesktop()
    var to_id = props.to_id
    if (window.location.href.includes(Pages.reviews + "?id=")){
        to_id = getUrlParameter("id")
    }
    const isFromUserReview = to_id != undefined && String(to_id).includes("_1")
    const isMyProfile = getCookie("id") == user.vk_caller_id

    return (
        <div style={isDesktop ? {zIndex : 999 , background : '#F1F5FD', top : '40px', left : '64px', right : '0', width : '100%', maxWidth: 'calc(100vw - 64px)'} : {zIndex : 2, background : 'F1F5FD'}} className="selected_theme">
            <div className="reviews_desktop_container">
                <div className="review_top_bar">
                    <div className="review_arrow_container">
                        <div className="flex">
                            <img alt='' onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>
                            <p style={{fontSize : '18px'}} className="theme_title review_title_text">{!reviewsFromUser ? "Отзывы" : "Отзывы от пользователя"}</p>
                        </div>
                    </div>
                    {user.name == undefined ? <div/> :
                    <a rel="noreferrer" href={Pages.users + "/id" + user._id} target="_blank">
                        <div className="reviews_desktop_profile_info hover">
                            <img alt='' className="review_avatar_cont" src={user.avatar ? user.avatar : avatar}/>
                            <div>
                                {(user.is_repetitor && user.specializations && user.specializations.length > 0) && <div className="profile_expert_by profile_expert_margins">Эксперт - {user.specializations[0].toLowerCase()}</div>}

                                <div className="flex profile_name_margins">
                                    <p className="desktop_profile_name">{user.name} {user.surname}</p>
                                    {user.is_repetitor && <img alt='' className="desktop_profile_badge" src={badge}/>}
                                </div>
                                <div style={{height : '12px'}} className="flex">
                                    <p className="desktop_profile_desk" style={{marginRight : '3px'}}>{user.rub_in_hour ? user.rub_in_hour + " ₽/час " : "цена договорная "}·</p>
                                    <OnlineInfo disableViews user={user}/>
                                    <div style={{margin: '-8px 0 0 0'}} className="flex">
                                        <img alt='' className="review_info_icon" src={star}/>
                                        <p className="review_info_text">{getRating(user)}</p>

                                        <img alt='' className="review_info_icon" src={message}/>
                                        <p className="review_info_text">{getReviewsCount(user)}</p>
                                    </div>
                                </div>
                                
                            </div>  
                                
                        </div>
                    </a>}
                </div>
                {(!isDesktop && isMyProfile) && addReviewHTML()}
                {(!isDesktop && isShowAddClientReview) && addReviewFromClientHTML()}
                {isLoading ? <Loader /> : 
                <div className="flex">
                    <div style={{width : '100%', maxWidth : isDesktop ? 'calc(100vw - 64px)' : '100%', paddingTop : 0, background : reviews.length == 0 ? 'white' : isDesktop ? 'none' : "#F1F5FD", marginBottom : isDesktop ? '64px' : 0}} className={isDesktop ? reviews.length == 0 ? "exprets_in_theme_desc profile_review_c experts_rounded_corner review_empty_margin" : "exprets_in_theme_desc profile_review_c" : "exprets_in_theme profile_review_c"}>
                        {reviews.length > 0 && reviews.map((review, index) => (
                            <ReviewItem review={review} isFromUserReview={isFromUserReview} setDeleteReviewInfo={setDeleteReviewInfo} isMyProfile={isMyProfile} index={index} key={index}/>
                        ))} 
                        {reviews.length == 0 && <MyImage className='profile_empty_reviews_img' src={empty_reviews}/>}
                    </div>
                    {!isDesktop ? null : 
                    <div>
                        {isMyProfile ?
                        <div>
                            {addReviewHTML()}
                            <img alt='' className="expert_review_desc" src={expert_review_desc}/>
                        </div>
                        :
                        (isDesktop && isShowAddClientReview) ? addReviewFromClientHTML() : null
                        }
                    </div>}
                </div>
                }
            </div>
            <AddClientReviewDialog onLoaded={onClientReviewAdded} to_id={isShowAddClientReviewDialog} isShow={isShowAddClientReviewDialog != ""} setShow={() => setShowAddClientReviewDialog("")}/>
            <AddMyReviewDialog onLoaded={loadReviews} isShow={isShowAddReview} setShow={setShowAddReview}/>
            <DeleteReviewDialog onLoaded={loadReviews} deleteReviewInfo={deleteReviewInfo} isShow={deleteReviewInfo.isShow} setShow={() => setDeleteReviewInfo({_id : "", isShow : false})}/>
        </div>
    );
}

export default Reviews;

const ReviewItem = ({ review, isFromUserReview, isMyProfile, setDeleteReviewInfo}) => {
    const isDesktop = getIsDesktop()
    
    const getStars = () => {
        var stars = []
        for (let index = 1; index < 6; index++) {
            if (review.grade >= index){
                stars.push(star_selected)
            } else {
                stars.push(star_profile)
            }
        }
        return stars
    }
    const stars = getStars()
    var text = review.text.includes("\n") ? ("<div>" +  review.text.replaceAll("\n", "<br/>") + "</div>") : review.text
    // var text = review.text.includes("\n") ? ("<div>" +  review.text.replaceAll("\n", "<br/>") + "</div>") : "asdfkasgkdaklsdgklasdgklkl aslk klasdkl klasdk sadk<br/>dfkasgkdaklsdgklasdgklkl aslk klasdkl klasdk sadk"
    // if (text == ""){
    //     text = "Обучение длилось " + review.count_full_time + " мин."
    // }
    const avatarImg = isFromUserReview ? review.to_avatar : review.from_avatar
    var name = isFromUserReview ? review.to_name : review.from_user ? review.from_user.name : review.from_name
    var hasUrl = review.link_to_proof != undefined && review.link_to_proof != ""
    var surname = isFromUserReview ? "" : review.from_user ? review.from_user.surname : ""
    // if (name.includes(" ")){
    //     const arr = name.split(" ")
    //     name = arr[0]
    //     surname = arr[1]
    // }
    // console.log("review = ", review)
    return (
        <div className="review_item_container">
            {!isDesktop && <div className="review_item_top">
                <p className="review_item_date">{moment.unix(moment(review.create_date, "YYYY-MM-DD").unix()).format("DD MMM YYYY")}</p>
                <div className="flex">
                    {stars.length > 0 && stars.map((img, index) => (
                        <img alt='' className="review_item_star" key={index} src={img}/>
                    ))}
                    {(isMyProfile && hasUrl) && <img alt="" onClick={() => setDeleteReviewInfo({_id : review._id, isShow : true})} className="delete_review_icon hover" src={delete_review}/>}

                </div>
            </div>}
            <div className="review_item_just">
                <div className="flex">
                    {avatarImg ? <img alt='' style={{margin : '0 16px 0 0'}} className="review_avatar_img" src={avatarImg ? avatarImg : avatar}/>
                    :
                    <div className="profile_review_avatar_bg">
                        <div className="profile_review_avatar_bg_col">
                            <div>{(name && name.length > 0) ? name.substring(0, 1).toUpperCase() : ""}{(surname && surname.length > 0) ? surname.substring(0, 1).toUpperCase() : ""}</div>
                        </div>
                    </div>}
                    
                    <div>
                        <p className="review_item_surname">{name.replaceAll("ㅤ", "")} {(surname && surname != "") ? surname : ""}</p>
                        {hasUrl ?
                        <div className="review_item_from_url">
                            <div>загружен из другой сети</div>
                            <a className="hover" href={review.link_to_proof} target="_blank" rel="noreferrer">оригинал отзыва</a>
                        </div>
                        // : <p className="review_item_text_time">{(review.dialog && review.dialog.count_calls) ? (review.dialog.count_calls + " " + declension(parseInt(review.dialog.count_calls), 'созвон', 'созвона', 'созвонов') + ", " + review.dialog.count_full_time + " мин.") : ("Обучение длилось " + (review.dialog ? review.dialog.count_full_time : review.count_full_time) + " мин.")}</p>}
                        : <p className="review_item_text_time">{(review.dialog && review.dialog.count_calls) ? (review.dialog.count_calls + " " + declension(parseInt(review.dialog.count_calls), 'созвон', 'созвона', 'созвонов')) : ("")}</p>}
                    </div>
                </div>

                {isDesktop && <div className="review_item_top">
                    <p className="review_item_date">{moment.unix(moment(review.create_date, "YYYY-MM-DD").unix()).format("DD MMM YYYY")}</p>
                    <div className="flex">
                        {stars.length > 0 && stars.map((img, index) => (
                            <img alt='' className="review_item_star" key={index} src={img}/>
                        ))}
                    </div>
                    {(isMyProfile && hasUrl) && <img alt="" onClick={() => setDeleteReviewInfo({_id : review._id, isShow : true})} className="delete_review_icon hover" src={delete_review}/>}
                </div>}
            </div>
            
            {text != "" && <p className="review_item_text" dangerouslySetInnerHTML={{__html:text}}></p>}
        </div>
    )
}
