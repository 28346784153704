import { useState } from 'react';
import { getIsDesktop, Pages } from '../../../utils/Utils';
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/other/other_back.svg'
import back_arrow from '../../../img/experts/back_arrow.svg'
import { useHistory } from "react-router-dom";
import BeforeProfilePromotion from './BeforeProfilePromotion';
import ProfileAdsStats from './ProfileAdsStats';
import ProfileAdsTarget from './ProfileAdsTarget';
import ProfileAdsVK from './ProfileAdsVk';
import ProfileAdsDemoVideo from './ProfileAdsDemoVideo';
import ProfileAdsQuestions from './ProfileAdsQuestions';
import ProfileAdsDialog from '../dialogs/ProfileAdsDialog';
import { UsersApi } from '../../../api/UsersApi';
import { CSSTransition } from 'react-transition-group';
import Toast from '../custom_views/Toast';

function ProfileAds(props) {
    const [nextDialogInfo, setNextDialogInfo] = useState({isShow : false, price : 0, type : 0})
    const [toastText, setToastText] = useState("")

    const isDesktop = getIsDesktop()
    const history = useHistory()
    const user = props.user
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.lk
        } else {
            history.goBack()
        }
    }
    const getToolbar = () => {
        return(
            <div style={isDesktop ? {} : {top : 0}} className="picked_ads_top_header">
                <div className="flex">
                    {isDesktop ? <img onClick={onBack} className="ads_back_desktop blog_post_back hover" src={other_back}/> 
                    :
                    <img onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                    <div className="blog_toolbar_title">Продвижение профиля</div>
                </div>
            </div>
        )
    }
    const isNotEnoughtMoney = (price) => {
        const countRub = user.count_rub == undefined ? 0 : Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
        const notEnoughtMoney = countRub < price
        return notEnoughtMoney
    }
    const buyTargetAds = () => {
        if (!isNotEnoughtMoney(2499)){
            UsersApi.promotionBuyYandex((responseData) => {
                props.getMyInfo()
            }, () => {})
        }
        setNextDialogInfo({isShow : true, price : 2499, type : 0})
    }
    const buyVKAds = () => {
        if (!isNotEnoughtMoney(1499)){
            UsersApi.promotionBuyVkPosts((responseData) => {
                showToast("Запрос на публикацию рекламы отправлен")
            }, () => {})
        }
        setNextDialogInfo({isShow : true, price : 1499, type : 1})
    }
    const buyDemoAds = () => {
        UsersApi.promotionRequestDemoVideo((responseData) => {
            showToast("Запрос на демо видео отправлен")
        }, () => {})
        setNextDialogInfo({isShow : true, price : 1500, type : 2})
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="lk_container_inner">
                <div className="profile_ads_inner">
                    {getToolbar()}
                    {isDesktop ? 
                        <div className="flex">
                            <div>
                                <ProfileAdsStats user={user}/>
                                <ProfileAdsTarget user={user} buyTargetAds={buyTargetAds}/>
                                <ProfileAdsVK user={user} buyVKAds={buyVKAds} />
                                <ProfileAdsDemoVideo user={user} buyDemoAds={buyDemoAds} />
                            </div>
                            <div style={{marginLeft : '16px'}}>
                                <BeforeProfilePromotion />
                                <ProfileAdsQuestions />
                            </div>
                        </div>
                    : 
                        <div>
                            <BeforeProfilePromotion />
                            <ProfileAdsStats user={user}/>
                            <ProfileAdsTarget user={user} buyTargetAds={buyTargetAds}/>
                            <ProfileAdsVK user={user} buyVKAds={buyVKAds} />
                            <ProfileAdsDemoVideo user={user} buyDemoAds={buyDemoAds} />
                            <ProfileAdsQuestions />
                        </div>
                    }
                </div>
            </div>
            <ProfileAdsDialog user={user} nextDialogInfo={nextDialogInfo} isShow={nextDialogInfo.isShow} setNextDialogInfo={setNextDialogInfo}/>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
    );
}

export default ProfileAds;
