/* eslint-disable eqeqeq */
import "rsuite/dist/rsuite.css"
import './styles/App.css';
import Auth from './pages/auth/Auth';
import ProfileFilling from './pages/profile_filling/ProfileFilling';
import Main from './pages/Main';
import { setCookie } from './utils/CookieHelper';
import { useState, useEffect } from 'react';
import { getUrlParameter, Pages } from './utils/Utils';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { ConfigApi } from './api/ConfigApi';
import './firebase.js'


function App() {
  const [user, setUser] = useState({})
  const [config, setConfig] = useState({})

  const getConfig = () => {
    ConfigApi.getConfig((responseData) => {
      setConfig(responseData.config)
    })
  }
  useEffect(() => {
      getConfig()
      if (window.location.pathname == Pages.auth_main){
          window.location.href = Pages.auth
      }
  }, [])
  if (getUrlParameter("token") != undefined && getUrlParameter("token") != null && getUrlParameter("token") != ""){
      setCookie('token', getUrlParameter("token"))
  }
  
  const web_view = getUrlParameter("web_view")
  if (web_view != undefined && web_view != null && web_view != ""){
      setCookie('web_view', web_view)
  }
  // console.log("vk caller id = ", getCookie("id"))
    
  return (
    <Router>
      <div className="App">
        <Switch>      
          <Route path={Pages.auth_main + "/*"}>
            <Auth/>
          </Route>
          
          <Route path={Pages.profile_filling}>
            <ProfileFilling config={config} user={user}/>
          </Route>
          <Route path="/*">
            <Main config={config} setUser={setUser}/>
          </Route>
          
        </Switch>
      </div>
    </Router>
  )
}

export default App;

