import React, { useState, useEffect } from 'react';
import { getDesktopStyle } from '../../utils/Styles';
import not_selected_type from '../../img/edit_profile/type.svg'
import selected_type from '../../img/edit_profile/selected_type.svg'
import UploadAvatar from '../profile_filling/custom_views/UploadAvatar';
import { setModelParam } from '../../utils/Utils';
import ProfileInputRaw from '../profile_filling/custom_views/ProfileInputRaw';
import AuthErrorTooltip from '../auth/AuthErrorTooltip';
import moment from 'moment'
import SexTooltip from '../profile_filling/custom_views/SexTooltip';
import { CSSTransition } from 'react-transition-group';
import arrow_down from '../../img/chat/arrow_down.svg'
import { UsersApi } from '../../api/UsersApi';
import Loader from '../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import NotSavedDialog from './dialogs/NotSavedDialog';
function EditMainInfo(props) {
    const [user, setUser] = useState(props.user)
    const [errorText, setErrorText] = useState("")
    const [isShowSex, setShowSex] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
    }, [props.user])
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
    }, [])
    const setUserInfo = (user) => {
        var mUser = {}
        if (user.bdate){
            const date = moment.unix(moment(user.bdate, "YYYY-MM-DD").unix()).format("DD.MM.YYYY")
            mUser.bday = date
            mUser.start_bday = date
        }
        mUser.is_repetitor = user.is_repetitor
        mUser.surname = user.surname
        mUser.name = user.name
        mUser.sex = user.sex ? user.sex : 2
        mUser.email = user.email
        mUser.avatar = user.avatar
        setUser(mUser)
    }
    const setRepetitor = (is_repetitor) => {
        setModelParam(user, "is_repetitor", is_repetitor, setUser)
    }
    const setLastName = (surname) => {
        setModelParam(user, "surname", surname, setUser)
    }
    const setFirstName = (name) => {
        setModelParam(user, "name", name, setUser)
    }
 
    const setBday = (text) => {
        setModelParam(user, "bday", text, setUser)
    }
    const setSex = (sex) => {
        setModelParam(user, "sex", sex, setUser)
    }
    const setEmail = (email) => {
        setModelParam(user, "email", email, setUser)
    }
    const setAvatarUrl = (url) => {
        setModelParam(user, "avatar", url, setUser)
    }

    const bdayChanged = (text) => {
        if (text.length == 2 || text.length == 5){
            if (user.bday.length > text.length){
                setBday(text)
            } else {
                setBday(text + ".")
            }
            
            setErrorText("")
        } else if (text.length > 10){
            const date = text.slice(0, 10)
            setBday(date)
            checkDate(date)
        } else {
            setErrorText("")
            setBday(text)
            if (text.length == 10){
                checkDate(text)
            }
        } 
    }
    const checkDate = (dateText) => {
        var ageDifMs = Date.now() - moment(dateText, "DD.MM.YYYY").unix()
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        const years = Math.abs(ageDate.getUTCFullYear() - new Date(moment(dateText, "DD.MM.YYYY")).getUTCFullYear())
        if (years > 99){
            setErrorText("Вам больше 100 лет?")
        } else if (years < 18){
            setErrorText("Пользоваться сервисом можно только совершеннолетним")
        } else {
            setErrorText("")
        }
    }
    const setPickedSex = (type) => {
        setShowSex(false)
        setSex(type == 0 ? 2 : 1)
    }
    const save = () => {
        setLoading(true)
        var mUser = user
        if (mUser.bday != undefined && mUser.bday.length == 10){
            mUser.bdate = moment(mUser.bday + ".24:00", "DD.MM.YYYY.HH:mm").unix() * 1000
        }
        console.log("setMyInfo = ", mUser)
        UsersApi.setMyInfo(mUser, (responseData) => {
            setLoading(false)
            props.getMyInfo()
        })
    }
    const isCanSave = props.user.name != user.name || props.user.surname != user.surname || props.user.sex != user.sex || props.user.email != user.email || user.bday != user.start_bday || user.is_repetitor != props.user.is_repetitor || user.avatar != props.user.avatar
    const isEnableButton = user.name.length > 1 && user.surname.length > 1 && errorText == "" && !isLoading
    props.setNotSaved(isCanSave ? 0 : -1)
    return (
        <div className="profile_edit_container desktop_profile_main_info">
            <div className="edit_type_account_title">Тип аккаунта</div>
            <div className="edit_type_profile">
                <div onClick={() => setRepetitor(false)} className="edit_type_item hover">
                    <img className="edit_type_item_img" src={!user.is_repetitor ? selected_type : not_selected_type}/>
                    <div className="edit_type_item_title">Клиент</div>
                </div>
                <div onClick={() => setRepetitor(true)} className="edit_type_item hover">
                    <img className="edit_type_item_img" src={user.is_repetitor ? selected_type : not_selected_type}/>
                    <div className="edit_type_item_title">Эксперт</div>
                </div>
            </div>
            <div className="edit_type_line"/>

            <div className="edit_avatar_margin">
                <UploadAvatar avatarUrl={user.avatar} setAvatarUrl={setAvatarUrl}/>
            </div>
            <div style={{margin : '0 20px'}}>
                <ProfileInputRaw title="Имя" value={user.name} setValue={setFirstName}/>
                <ProfileInputRaw title="Фамилия" value={user.surname} setValue={setLastName}/>
                <ProfileInputRaw placeholder="В формате 11.11.1991" title="Дата рождения" value={user.bday != undefined ? user.bday : ""} setValue={bdayChanged}/>
                <CSSTransition in={errorText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                        <AuthErrorTooltip style={{margin : 0, width : '100%'}} text={errorText}/>
                </CSSTransition>
                <div className="relative input_raw_container">
                    <div onClick={() => setShowSex(!isShowSex)} className="input_raw_container_p hover">
                        <p className="profile_input_title">Пол</p>
                        <div className="flex">
                            <div className="sex_text">{user.sex == 2 ? "Мужской" : "Женский"}</div>
                            <img className="arrow_sex" src={arrow_down}/>
                        </div>
                    </div>
                    <div className="fill_navbar_line"/>
                    {isShowSex && <SexTooltip setShow={setShowSex} setSex={setPickedSex}/>}
                </div>
                <ProfileInputRaw type={"email"} title="Email" value={user.email} setValue={setEmail}/>
            </div>

            <div onClick={() => window.open('https://sozvon.pro/docs/privace_policy.pdf', '_blank')} className="edit_profile_privacy hover">Политика конфиденциальности</div>
            <div className="edit_profile_buttons">
                <div onClick={() => history.goBack()} className="call_prices_edit hover">Отмена</div>
                <div onClick={save} style={isCanSave && isEnableButton ? {} : {pointerEvents : 'none', opacity : 0.2}} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : 'Сохранить'}</div>
            </div>

            <NotSavedDialog save={save} isShow={props.showNotSaved != -1} closeDialog={props.closeDialog}/>
        </div>
    );
}

export default EditMainInfo;
