
import React from 'react'
import lama from '../img/lama.svg'
class EmptyBookmarks extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
        return (
            <div className="profile_fill_start_section">
                <div className="center_vertical center_fill_cont">
                    <img style={{marginLeft : '-32px'}} className="profile_fill_start_img" src={lama}/>
                    <div style={{fontWeight : '700'}} className="profile_fill_start_desc">В избранном<br/>пока пусто</div>
                </div> 
            </div>
        )   
    }
}
export default EmptyBookmarks