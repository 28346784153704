/* eslint-disable eqeqeq */
import { getCookie } from "../../../utils/CookieHelper"
import * as SDK from '@vkontakte/calls-sdk'

export class GroupCallController {
   
    static onLocalStatus(status) {
        console.log("onLocalStatus status = ", status)
    }
    static onLocalStream(stream, mediaSettings, plannerInfo, streams, setVideoStreams, addStream, isVideoOn, onFinish) {
        console.log("onLocalStream mediaSettings = ", mediaSettings)
        console.log("onLocalStream stream = ", stream)
        const is_i_creator = this.isICreator(plannerInfo)
        if (is_i_creator && plannerInfo.creator){
            addStream({id : plannerInfo.creator.vk_caller_id, is_video_on : mediaSettings.isVideoEnabled}, stream, mediaSettings.isVideoEnabled)
        } else {
            const foundIndex = streams.findIndex((obj => obj.user.id == getCookie("id")))
            if (foundIndex != -1){
                streams[foundIndex].stream = stream
                streams[foundIndex].user.is_video_on = isVideoOn
                streams[foundIndex].user.is_mic_on = mediaSettings.isAudioEnabled
                setVideoStreams([...streams])
            }
        }
        onFinish(stream)
    }
    static onScreenStream (stream, mediaSettings, plannerInfo, streams, setVideoStreams, setScreenShareEnabled) {
        const is_i_creator = this.isICreator(plannerInfo)
        if (is_i_creator){
            const foundIndex = streams.findIndex((obj => obj.user.id == getCookie("id")))
            streams[foundIndex].user.is_video_on = mediaSettings.isVideoEnabled
            streams[foundIndex].user.is_screen_sharing = mediaSettings.isScreenSharingEnabled
            streams[foundIndex].screen_stream = stream
            setVideoStreams([...streams])
        }
        console.log('onScreenStream mediaSettings = ', mediaSettings);
        setScreenShareEnabled(mediaSettings.isScreenSharingEnabled)
    }
    static onDeviceSwitched (mediaOptions, enabled, streams, setMicOn, setVideoOn, setVideoStreams){
        console.log('onLocalMediaSettings enabled = ', enabled);
        console.log('onLocalMediaSettings mediaOptions = ', mediaOptions);
        const foundIndex = this.getMyIndexInStreams(streams)
        if (foundIndex != -1){
            if (mediaOptions == SDK.MediaOption.AUDIO){
                streams[foundIndex].user.is_mic_on = enabled
                setMicOn(enabled)
            } else if (mediaOptions == SDK.MediaOption.VIDEO){
                setVideoOn(enabled)
                streams[foundIndex].user.is_video_on = enabled
            }
            setVideoStreams([...streams])
        } 
    }
    static onRemoteMediaSettings(userId, mediaSettings, streams, plannerInfo, setVideoStreams){
        console.log('onRemoteMediaSettings status = ', mediaSettings);
        console.log('onRemoteMediaSettings userId = ', userId);
        const foundIndex = streams.findIndex((obj => obj.user.id == userId.id))
        if (foundIndex != -1){
                const isUserCreator = (plannerInfo.creator.vk_caller_id == streams[foundIndex].user.id)
                //проверять видео на наличие стрима до запускаf
                streams[foundIndex].user.is_video_on = mediaSettings.isVideoEnabled
                streams[foundIndex].user.is_mic_on = mediaSettings.isAudioEnabled
                streams[foundIndex].user.is_screen_sharing = mediaSettings.isScreenSharingEnabled
                streams[foundIndex].user.is_creator = isUserCreator

                setVideoStreams([...streams])
        } 
        if (mediaSettings.isVideoEnabled || mediaSettings.isScreenSharingEnabled) {
            // Запрашиваем видео от участника, когда он включает камеру или трансляцию экрана
            SDK.updateDisplayLayout([{
                    uid: userId,
                    mediaType: mediaSettings.isScreenSharingEnabled ? SDK.MediaType.SCREEN : SDK.MediaType.CAMERA,
                        
            }]);
        }
    }
    static getMyIndexInStreams (streams) {
        return streams.findIndex((obj => obj.user.id == getCookie("id")))
    }
    static isICreator (plannerInfo) {
        return plannerInfo.creator ? plannerInfo.creator.vk_caller_id == getCookie("id") : false
    } 
}