/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useRef, useEffect } from 'react';
import bighead from '../../img/auth/auth_bighead.svg';
import auth_bighead_desktop from '../../img/auth/auth_bighead_desktop.svg';
import vk_icon from '../../img/auth/vk_icon.svg';
import tg_icon from '../../img/auth/tg_icon.svg';
import auth_logo from '../../img/auth/auth_logo.svg';
import { Link } from "react-router-dom";
import { AuthApi } from '../../api/AuthApi';
import PromoCodeDialog from './custom_views/PromoCodeDialog';
import { getUrlParameter, getIsDesktop, Pages, checkAfterAuth, Define } from '../../utils/Utils';
import Loader from '../../custom_views/Loader';
import { setCookie, getCookie } from '../../utils/CookieHelper';

function AuthTypes(props) {
    const [isShowPromocode, setShowPromocode] = useState(false)
    const [isTGAuth, setTGAuth] = useState(false)
    const [isVKAuth, setVKAuth] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const btnRef = useRef(null)

    const isDesktop = getIsDesktop()
    const authVK = () => {
        setVKAuth(true)
        const prev_url = getUrlParameter("prev_url")
        if (prev_url != null && prev_url != undefined && prev_url != ""){
            setCookie("prev_url", prev_url)
        }
        window.location.href = "https://oauth.vk.com/authorize?client_id=51494524&display=popup&redirect_uri=" + window.location.origin + "/verification&scope=offline,groups&response_type=code&v=5.131"
    }

    useEffect(() => {
        const code = getUrlParameter("code")
        if (code != undefined && code != ""){
            // if (getCookie("was_authed") == "was_authed"){
            //     verify(code, "")
            // } else {
            //     setTGAuth(false)
            //     setShowPromocode(true)
            // }
            verify(code, "")
        }
        //test
        // setTGAuth(true)
        // setShowPromocode(true)
    }, [])
    // useEffect(() => {
    //    const authParam = getUrlParameter("auth")
    //    if (authParam != null && authParam != undefined && authParam === 'tg'){
    //         setTimeout(function() {
    //             btnRef.current.click()
    //         }, 1000);
            
    //    }
    // }, [])
    const savedPromoCode = getCookie("promocode")
    const hasPromo = (savedPromoCode != undefined && savedPromoCode != null && savedPromoCode != "")
    const verify = (code, promo_code) => {
        setLoading(true)
       
        AuthApi.registerUserByVK(code, (hasPromo ? savedPromoCode : promo_code), (response) => {
            setLoading(false)
            setCookie("token", response.access_token)
            props.getMyInfo()
        }, () => {setLoading(false)})
    }
    const promoCodeChanged = (promo_code) => {
        const code = getUrlParameter("code")
        if (code != undefined && code != ""){
            verify(code, promo_code)
        }
    }
    //доделать лоадер
    const token = getCookie("token")
    if (token != null && token != undefined && token != ""){
        if (checkAfterAuth()){
            return
        }
        window.location.href = "/experts"
        return(<div></div>)
    }

    const authTG = () => {
        // setTGAuth(true)
        // setShowPromocode(true)
       
        window.Telegram.Login.auth({ bot_id: Define.bot_id, request_access: true }, (user) => {
            AuthApi.registerUserByTelegramNew(user, (responseData) => {
                setCookie("token", responseData.access_token)
                props.getMyInfo()
            })  
        })
        
    }
    return (
        <div className="auth_types_container">
            <div className="center_types_auth">
                <img alt="" className="auth_types_logo" src={auth_logo}/>
                <div className="auth_buttons_center">
                    {hasPromo && <div className="auth_bonus" src={auth_logo}>
                        <div style={{margin : 'auto 8px auto 0', fontSize : '16px'}}>🎁</div>
                        <div>При регистрации вы получаете 300₽ на баланс сервиса и можете потратить их на онлайн-консультацию с экспертом</div>
                    </div>}
                    <button onClick={authVK} className="auth_vk_btn hover">
                        {isLoading ? <Loader /> : <div className="flex">
                            <img alt="" className="vk_icon" src={vk_icon}/>
                            <p className="vk_auth_text">Войти через ВКонтакте</p>
                        </div>}
                    </button>
                    {/* <a rel="noreferrer" href={Pages.tg_bot} target="_blank"> */}
                        <button style={{marginTop : '16px'}} onClick={authTG} ref={btnRef} className="auth_vk_btn hover">
                            {isLoading ? <Loader /> : <div className="flex">
                                <img alt="" className="vk_icon" src={tg_icon}/>
                                <p className="vk_auth_text">Войти через Telegram</p>
                            </div>}
                        </button>
                    {/* </a> */}
                    {/* {getUrlParameter("asdf") != null && 
                    <div style={{marginTop : '16px'}}><LoginButton
                        botUsername="notification_sozvon_bot"
                        buttonSize="large" // "large" | "medium" | "small"
                        cornerRadius={16} // 0 - 20
                        showAvatar={false} // true | false
                        onAuthCallback={(user) => {
                            console.log("LoginButton onAuthCallback = ", user);
                            fetch("https://sozvon.pro/api/registerUserByTelegramNew", {
                                method: "POST",
                                headers: {'Content-Type': 'application/json'}, 
                                body: JSON.stringify({telegram_data:JSON.stringify(user)})
                            })
                            .then((response) => response.json())
                            .then(responseData => {
                               
                                console.log("Request complete! response: ", responseData);
                                setCookie("token", responseData.access_token)
                                props.getMyInfo()
                            })
                        }}
                        lang="ru"
                    /></div>} */}
                
                    <Link className="hover" to={Pages.auth_phone}>
                        <div className="auth_no_vk_and_tg hover">У меня нет Вк и Телеграм</div>
                    </Link>
                    <Link className="hover" to={Pages.experts}>
                        <div className="use_phone_btn hover">Продолжить без авторизации</div>
                    </Link>
                </div>
            </div>
            <div className="auth_policy_text auth_agreement_main">
                <div className="auth_policy_bg">Нажимая кнопку входа, я подтверждаю, что мне есть 18 лет, и я соглашаюсь с 
                <a rel="noreferrer" style={{color : 'white'}} href={Pages.agreement} target="_blank"><b className="hover"> Условиями использования </b></a>
                 и <a rel="noreferrer" style={{color : 'white'}} href={Pages.privacy_policy} target="_blank"><b className="hover"> Политикой конфиденциальности </b></a>
                 </div>
            </div>
            <img alt="" style={isDesktop ? {right : '-40px'} : {}} className="auth_types_bighead" src={isDesktop ? auth_bighead_desktop : bighead}/>
            <PromoCodeDialog getMyInfo={props.getMyInfo} isTGAuth={isTGAuth} setPromoCode={promoCodeChanged} isShow={isShowPromocode} setShow={setShowPromocode}/>
        </div>
    );
}

export default AuthTypes;
