import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, declension } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import FilterDropdown from '../../../custom_views/FilterDropdown';

function AddNotifDialog(props) {
    const isDesktop = getIsDesktop()
    const [count, setCount] = useState(5)
    //0 min, 1 hour, 3 day
    const [type, setType] = useState(0)
    const [isShowTypeDropdown, setShowTypeDropdown] = useState(false)
    const isError = count <= 0
    const onFilterPicked = (item, index) => {
        setShowTypeDropdown(false)
        setType(index)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="call_edit_title">Напоминание за</p>

                <div className="create_planner_themes create_planner_notif_m">
                    <div className="create_planner_theme_item create_planner_theme_item_l">
                        <input style={{padding : '12px 16px'}} onChange={(e) => setCount(e.target.value)} className="create_planner_border" type="number" value={count}/>
                    </div>
                    <div className="create_planner_theme_item create_planner_theme_item_r relative">
                        <div style={{padding : '12px 16px'}} onClick={() => setShowTypeDropdown(!isShowTypeDropdown)} className="create_planner_border create_planner_not_flex hover">
                            {type == 0 ? 'мин.' : type == 1 ? declension(parseInt(count), "час", "часа", "часов") : declension(parseInt(count), "сутки", "суток", "суток")}
                            <div style={{transform : isShowTypeDropdown ? 'rotate(0deg)' : 'rotate(180deg)'}} className="create_planner_notif_arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                                    <path d="M11.5456 8.30895C11.9371 8.61512 12.5028 8.5459 12.8089 8.15434C13.1151 7.76277 13.0459 7.19714 12.6543 6.89097L8.5577 3.6877C8.23209 3.4331 7.77493 3.43301 7.44922 3.68748L3.34926 6.89075C2.95757 7.19677 2.88813 7.76237 3.19415 8.15406C3.50017 8.54574 4.06577 8.61519 4.45745 8.30917L8.00309 5.53899L11.5456 8.30895Z" fill="black"/>
                                </svg>
                            </div>
                        </div>
                        {isShowTypeDropdown && <FilterDropdown onCloseFilters={() => setShowTypeDropdown(false)} style={{margin : '0', width: '-webkit-fill-available', top : 0}} filters={["мин.", declension(parseInt(count), "час", "часа", "часов"), declension(parseInt(count), "сутки", "суток", "суток")]} onFilterPicked={onFilterPicked}/>}

                    </div>

                </div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={isError ? {margin : '0 24px 0 24px', pointerEvents : 'none', opacity : '0.3'} : {margin : '0 24px 0 24px'}} onClick={() => props.addNotif(type == 0 ? (count * 60000) : type == 1 ? (count * 3600000) : (count * 86400000))} className="call_price_continue hover">Добавить</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default AddNotifDialog;
