import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, getAuthUrlWithPrev } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useHistory } from "react-router-dom";


function NotAuthedDialog(props) {
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const cancel = () => {
        props.setShow(false)
    }
    const openAuth = () => {
        props.setShow(false)
        var url = getAuthUrlWithPrev()
        url += "&profile_id=" + window.location.pathname.split("/")[2]
        history.push(url)
    }
    const isRegistration = props.isRegistration
    const getInnerHtml = () => {
        return(
            <div style={{padding : '32px 40px'}} className="call_info_container">
                <p className="call_edit_title">{isRegistration ? "Вы не авторизованы 👋" : "Необходимо авторизоваться 👋"}</p>
                <p style={{fontSize : '15px', maxWidth : '400px', color : "#A4A5B4"}} className="call_edit_desc">{isRegistration ? <div>Предлагаем вам пройти авторизацию/регистрацию. Так вы сможете сразу связаться с экспертом и пользоваться сервисом <b>без ограничений</b>.</div> : "Данная фукнция доступна только авторизованным пользователям"}</p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{background : 'none'}} onClick={cancel} className="call_prices_edit hover">Отмена</div>
                    <div style={{padding : '13px 40px', borderRadius : '30px'}} onClick={openAuth} className="call_price_continue hover">Авторизоваться</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog setShow={cancel} isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet onDismiss={cancel} style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default NotAuthedDialog;
