/* eslint-disable eqeqeq */
import waiting from '../../../img/pay/waiting.svg'
import { getPaymentDetalization } from '../utils/DetalizationHelper';
import { useState } from 'react';
import WaitingTooltip from '../tooltips/WaitingTooltip';

function PayTransaction(props) {
    const [isShowWaitingTooltip, setShowWaitingTooltip] = useState(false)
    const transaction = props.transaction
    
    const info = getPaymentDetalization(transaction, false)
    
    const openDetalization = () => {
        props.setTransaction(transaction)
        props.setShowDetalization(true)
    }
    return (
        <div className="relative">
            {transaction.dateTitle && <p className="pay_transaction_title">{transaction.dateTitle}</p>}
            <div className="flex pay_j_content">
                <div onClick={openDetalization} className="flex">
                    <img alt="" className="pay_transaction_avatar" src={info.img}/>
                    <p className="pay_transaction_text">{info.text}</p>
                </div>
                <div className="flex relative">
                    {transaction.status == 0 && <img alt="" className="pay_wainting_img" src={waiting}/>}
                    {info.isShowWaiting && <img alt="" onClick={() => setShowWaitingTooltip(!isShowWaitingTooltip)} className="pay_wainting_img" src={waiting}/>}
                    <p style={transaction.amount > 0 ? {color: '#6FCF97'} :{}} className="pay_transaction_count">{transaction.amount > 0 ? "+" : ""}{Math.round(transaction.amount)}₽</p>
                    {isShowWaitingTooltip && <WaitingTooltip isShow={isShowWaitingTooltip} setShow={setShowWaitingTooltip}/>}

                </div>
            </div>
        </div>
    )
}

export default PayTransaction;
