import React, { useState, useEffect, useRef } from 'react';
import autosize from 'autosize';
import { UsersApi } from '../../api/UsersApi';
import Loader from '../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import NotSavedDialog from './dialogs/NotSavedDialog';
import { setModelParam } from '../../utils/Utils';
import ProfileSkills from '../profile_filling/custom_views/ProfileSkills';
import ProfileSpecs from '../profile_filling/custom_views/ProfileSpecs';

function EditSkills(props) {
    const [user, setUser] = useState(props.user)
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const refTextarea = useRef(null)
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
    }, [props.user])
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
        autosize(refTextarea.current);
    }, [])

    const removeTag = (tag, index) => {
        var mUser = {...user}
        mUser.skills.splice(index, 1)
        setUser(mUser)
    }
    const addTag = (tag) => {
        var mUser = user
        var array = [...mUser.skills]
        if (!array.includes(tag)){
            array.push(tag)
            setModelParam(user, "skills", array, setUser)
        }
    }
    const addSpec = (tag) => {
        var mUser = {...user}
        var array = [...mUser.specializations]
        if (!array.includes(tag)){
            array.push(tag)
            setModelParam(user, "specializations", array, setUser)
        }
    }
    const removeSpec = (tag, index) => {
        var mUser = {...user}
        mUser.specializations.splice(index, 1)
        setUser(mUser)
    }
    const setUserInfo = (user) => {
        var mUser = {}
        mUser.skills = [...user.skills]
        mUser.specializations = [...user.specializations]
        mUser.bio = user.bio
        setUser(mUser)
    }
    const setBio = (bio) => {
        setModelParam(user, "bio", bio, setUser)
    }
   
    const save = () => {
        setLoading(true)
        var mUser = {...user}
        if (mUser.skills != undefined && mUser.skills.length > 0){
            mUser.skills = user.skills.join(',')
        }
        if (mUser.specializations != undefined && mUser.specializations.length > 0){
            mUser.specializations = user.specializations.join(',')
        }
        UsersApi.setMyInfo(mUser, (responseData) => {
            setLoading(false)
            props.getMyInfo()
        })
    }
    const isEnableButton = user.skills.length > 0
    const isCanSave = JSON.stringify(props.user.skills) != JSON.stringify(user.skills) || props.user.bio != user.bio || JSON.stringify(props.user.specializations) != JSON.stringify(user.specializations)

    props.setNotSaved(isCanSave ? 0 : -1)
    return (
        <div>
            <div className="profile_edit_container profile_edit_container_auto">
                <div style={{margin : '0 20px'}}>
                    <ProfileSpecs disabledAutoFocus specializations={user.specializations} removeTag={removeSpec} addTag={addSpec}/>
                </div>
            </div>
            <div className="profile_edit_container profile_edit_price profile_edit_container_auto">
                <div style={{margin : '0 20px'}}>
                    <ProfileSkills disabledAutoFocus addTag={addTag} removeTag={removeTag} skills={user.skills}/>
                </div>
            </div>
            <div className="profile_edit_container profile_edit_price desktop_profile_prices_info">
                <div style={{margin : '0 20px'}}>
                <div className="relative input_raw_container">
                    <div className="input_raw_container_p">
                        <p className="profile_input_title">О себе</p>
                        <div className="flex">
                            <textarea style={{maxHeight : 'none'}} ref={refTextarea} placeholder="Этот текст будет сохранён в профиле. Его всегда можно будет изменить" className="profile_input profile_textare_bio"  value={user.bio} onChange={(e) => setBio(e.target.value)}/>
                        </div>
                    </div>
                    <div className="fill_navbar_line"/>
                </div>
                </div>
                <div onClick={() => window.open('https://sozvon.pro/docs/privace_policy.pdf', '_blank')} className="edit_profile_privacy hover">Политика конфиденциальности</div>
                <div className="edit_profile_buttons">
                    <div onClick={() => history.goBack()} className="call_prices_edit hover">Отмена</div>
                    <div onClick={save} style={isCanSave && isEnableButton ? {} : {pointerEvents : 'none', opacity : 0.2}} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : 'Сохранить'}</div>
                </div>

            </div>
            <NotSavedDialog save={save} isShow={props.showNotSaved != -1} closeDialog={props.closeDialog}/>

        </div>
    );
}

export default EditSkills;
