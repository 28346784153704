import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class PlannerApi {
    
    static createPlanner(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createPlanner", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createPlanner response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static acceptPlanner(params, onSuccess, onError) {
        fetch(getRequestUrl() + "acceptPlanner", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("acceptPlanner response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static cancelPlanner(params, onSuccess, onError) {
        fetch(getRequestUrl() + "cancelPlanner", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("cancelPlanner response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getPlannerById(params, onSuccess, onError) {
        fetch(getRequestUrl() + "getPlannerById", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getPlannerById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyPlanner(onSuccess, onError) {
        fetch(getRequestUrl() + "getMyPlanner", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getMyPlanner response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static repetitorReady(params, onSuccess, onError) {
        fetch(getRequestUrl() + "repetitorReady", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("repetitorReady response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static createSozvonByLink(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createSozvonByLink", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createSozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static createOrEditSozvonByLink(params, isEdit, onSuccess, onError) {
        if (!isEdit){
            this.createSozvonByLink(params, onSuccess, onError)
        } else {
            this.editSozvonByLink(params, onSuccess, onError)
        }
    }
    static getSozvonByLinkById(params, onSuccess, onError) {
        fetch(getRequestUrl() + "getSozvonByLinkById", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getSozvonByLinkById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static acceptSozvonByLink(params, onSuccess, onError) {
        fetch(getRequestUrl() + "acceptSozvonByLink", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("acceptSozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMySozvonByLink(onSuccess, onError) {
        fetch(getRequestUrl() + "getMySozvonByLink", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getMySozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static cancelSozvonByLink(id, reason, onSuccess, onError) {
        fetch(getRequestUrl() + "cancelSozvonByLink", getHttpParams({_id : id, reason_for_cancellation : reason})).then((response) => response.json())
        .then((responseData) => {
            log("cancelSozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static repetitorReadyToSozvonByLink(id, onSuccess, onError) {
        fetch(getRequestUrl() + "repetitorReadyToSozvonByLink", getHttpParams({_id : id})).then((response) => response.json())
        .then((responseData) => {
            log("repetitorReadyToSozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getSozvonByLinkForSearch(query, onSuccess, onError) {
        fetch(getRequestUrl() + "getSozvonByLinkForSearch", getHttpParams({query : query})).then((response) => response.json())
        .then((responseData) => {
            log("getSozvonByLinkForSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getCourseByLinkById(_id, onSuccess, onError) {
        fetch(getRequestUrl() + "getCourseByLinkById", getHttpParams({_id : _id})).then((response) => response.json())
        .then((responseData) => {
            log("getCourseByLinkById response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static acceptCourseByLink(_id, onSuccess, onError) {
        fetch(getRequestUrl() + "acceptCourseByLink", getHttpParams({_id : _id})).then((response) => response.json())
        .then((responseData) => {
            log("acceptCourseByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static editSozvonByLink(params, onSuccess, onError) {
        fetch(getRequestUrl() + "editSozvonByLink", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("editSozvonByLink response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
}