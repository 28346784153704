import { useHistory } from "react-router-dom";
import { getHowLong } from './helpers/CreateAdsHelper';
import CreateAdsRadioStep from './custom_views/CreateAdsRadioStep';
import { getUrlParameter } from "../../../utils/Utils";
import { useEffect } from 'react';
import { AdsApi } from "../../../api/AdsApi";

function CreateAdsStep7(props) {
    const history = useHistory()
    const id = getUrlParameter("id")
    const info = {
        progress : props.progress,
        pickedSpecs : props.pickedSpecs,
        header : "Как долго планируете заниматься",
        value : props.value,
        radioItems : getHowLong()
    }
    useEffect(() => {
        const value = getUrlParameter("value")
        if (value != null && value != undefined && value != ""){
            props.setValue(getHowLong()[value])
        }
    }, [])
    const onNext = () => {
        if (props.isEdit){
            AdsApi.editRequestRepetitor({repetitor_request_id : id, training_period : getHowLong().indexOf(props.value)}, (response) => {
                window.location.href = '/ads_board/ads?id=' + id + "&is_details=true"
            })
        } else {
            history.push(props.value == "Дольше" ? "/create_ads/step_8" : "/create_ads/step_9")
        }
    }
    return (
        <CreateAdsRadioStep isEdit={props.isEdit} info={info} onNext={onNext} setValue={props.setValue}/>
    )
}

export default CreateAdsStep7;
