import avatar from '../../../img/profile/ads/avatar.svg'
import play from '../../../img/profile/ads/play.svg'
import text from '../../../img/profile/ads/text.svg'
import { Pages } from '../../../utils/Utils';

function BeforeProfilePromotion(props) {
  
    return (
        <div className="profile_ads_item profile_ads_item_mt">
            <div className="profile_ads_item_title">Перед продвижением профиля</div>
            <div className="profile_ads_item_row">
                <img src={avatar}/>
                <div>Загрузите аватарку</div>
            </div>
            <div className="profile_ads_item_row">
                <img src={text}/>
                <div>Заполните свой профиль, подробно опишите свой опыт и навыки.</div>
            </div>
            <div className="profile_ads_item_row">
                <img src={play}/>
                <div>Загрузите своё демо-видео</div>
            </div>
            <div>Мы подготовили для вас <a href={Pages.profile_ads_advices} target="_blank"><span className="profile_ads_underline hover">советы по заполнению профиля.</span></a>
            <br/><br/>
            Эти пункты очень важны для того, чтобы дальнейшее меры по продвижению были эффективны. </div>
        </div>
    );
}

export default BeforeProfilePromotion;
