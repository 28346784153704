import { UsersApi } from '../../../api/UsersApi';
import { getUserUrl } from '../../../utils/CookieHelper';
import { Pages } from '../../../utils/Utils';

function ChatToolbarTooltip(props) {
    const user = props.user

    const blockUser = () => {
        props.setShow(false)
        UsersApi.toggleBlock(user.vk_caller_id, props.isInMyBlock, (responseData) => {
            props.getMyInfo()
            props.setInMyBlock(!props.isInMyBlock)
        })
        
    }

    const changeRole = () => {
        props.setShow(false)
        props.setShowChangeRole(true)
    }
    return (
        <div className="more_tooltip_profile_bg">
            
            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div onClick={() => props.setShow(false)} style={props.style ? props.style : {}} className="chate_tooltip_profile">
                {(user.city && user.country) && <div className='chat_profile_tooltip_city'>{user.city}, {user.country}</div>}
                <a rel="noreferrer" href={getUserUrl(user)} target="_blank"><p className="more_tooltip_profile_text hover">Посмотреть профиль</p></a>
                {(props.canWriteReview && user.is_repetitor) && <a rel="noreferrer" href={Pages.reviews + "?id=" + user.vk_caller_id + "&user_id=" + user._id} target="_blank"><p className="more_tooltip_profile_text hover">Написать отзыв</p></a>}
                {(props.isIRepetitor && user.is_repetitor) && <p onClick={changeRole} className="more_tooltip_profile_text hover">Передать роль эксперта</p>}
                <p onClick={blockUser} className={props.isInMyBlock ? "more_tooltip_profile_text hover" : "more_tooltip_profile_text more_tooltip_profile_text_r hover"}>{props.isInMyBlock ? "Разблокировать пользователя" : "Заблокировать пользователя"}</p>
            </div>
        </div>
    );
}

export default ChatToolbarTooltip;
