import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { UsersApi } from '../../api/UsersApi';
import Loader from '../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import NotSavedDialog from './dialogs/NotSavedDialog';
import { setModelParam } from '../../utils/Utils';
import ProfileInputRaw from '../profile_filling/custom_views/ProfileInputRaw';
function EditPrices(props) {
    const [user, setUser] = useState(props.user)
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
    }, [props.user])
    useEffect(() => {
        if (props.user != undefined){
            setUserInfo(props.user)
        }
    }, [])
    const setUserInfo = (user) => {
        var mUser = {}
        mUser.rub_in_hour = user.rub_in_hour
        mUser.rub_per_message = user.rub_per_message
        mUser.free_time = user.free_time
        setUser(mUser)
    }
    const setPriceHour = (rub_in_hour) => {
        setModelParam(user, "rub_in_hour", rub_in_hour, setUser)
    }
    const setMessagePrice = (rub_per_message) => {
        setModelParam(user, "rub_per_message", rub_per_message, setUser)
    }
    const setFreeTime = (free_time) => {
        setModelParam(user, "free_time", free_time, setUser)
    }
    const save = () => {
        setLoading(true)
        UsersApi.setMyInfo(user, (responseData) => {
            setLoading(false)
            props.getMyInfo()
        })
    }
    const isEnableButton = user.rub_in_hour >= props.config.minimum_call_price && user.free_time >= 5
    const isPriceError = user.rub_in_hour == "" ? false : user.rub_in_hour < props.config.minimum_call_price
    const isTimeError = user.free_time == "" ? false : user.free_time < 5
    const isCanSave = props.user.rub_in_hour != user.rub_in_hour || props.user.free_time != user.free_time || user.rub_per_message != props.user.rub_per_message
    props.setNotSaved(isCanSave ? 0 : -1)
    return (
        <div>
            <div className="profile_edit_container profile_edit_container_auto">
                <div style={{margin : '0 20px'}}>
                    <p className="profile_edit_price_title">Стоимость аудио/видеозвонка</p>
                    <ProfileInputRaw inputDesc="₽" title="Стоимость одного часа" value={user.rub_in_hour} setValue={setPriceHour}/>
                    <p style={isPriceError ? {color : 'var(--red)', opacity : 1} : {}} className="profile_input_title profile_input_title_l">{isPriceError ? "Минимальная цена - " + props.config.minimum_call_price : "За вычетом комиссии сервиса получится " + parseFloat(user.rub_in_hour*(1-props.config.comission)).toFixed(2) + "₽"}</p>
                    <ProfileInputRaw inputDesc="минут" title="Бесплатное время" value={user.free_time} setValue={setFreeTime}/>
                    <p style={{}} className="profile_input_title profile_input_title_l">Это то количество времени, которое вы готовы консультировать бесплатно. Оно может быть вашим конкурентным преимуществом. Если ваш первый созвон с клиентом был меньше указанного тут значения, то остаток бесплатных минут перенесётся на последующие созвоны.</p>

                    {isTimeError && <p style={{color : 'var(--red)', opacity : 1}} className="profile_input_title profile_input_title_l">Минимальное бесплатное время - 5 минут</p>}
                </div>
            </div>
            <div className="profile_edit_container profile_edit_price desktop_profile_prices_info">
                <div style={{margin : '0 20px'}}>
                    <p className="profile_edit_price_title">Стоимость сообщения</p>
                    <ProfileInputRaw inputDesc="₽" title="Стоимость одного сообщения" value={user.rub_per_message} setValue={setMessagePrice}/>
                    <p className="profile_input_title profile_input_title_l">За вычетом комиссии сервиса получится {parseFloat(user.rub_per_message*(1-props.config.comission)).toFixed(2)}₽</p>
                </div>
                <div onClick={() => window.open('https://sozvon.pro/docs/privace_policy.pdf', '_blank')} className="edit_profile_privacy hover">Политика конфиденциальности</div>
                <div className="edit_profile_buttons">
                    <div onClick={() => history.goBack()} className="call_prices_edit hover">Отмена</div>
                    <div onClick={save} style={isCanSave && isEnableButton ? {} : {pointerEvents : 'none', opacity : 0.2}} className="call_price_continue hover">{isLoading ? <Loader isWhite/> : 'Сохранить'}</div>
                </div>

            </div>
            <NotSavedDialog save={save} isShow={props.showNotSaved != -1} closeDialog={props.closeDialog}/>

        </div>
    );
}

export default EditPrices;
