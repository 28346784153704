import React, { useState, useEffect } from 'react';
import back_arrow from '../../img/experts/back_arrow.svg'
import { getIsDesktop } from '../../utils/Utils';
import other_back from '../../img/other/other_back.svg'
import { useHistory } from "react-router-dom";
import { getDesktopStyle } from '../../utils/Styles';
import '../../styles/edit.css'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import EditMainInfo from './EditMainInfo';
import Loader from '../../custom_views/Loader';
import EditPrices from './EditPrices';
import EditSkills from './EditSkills';


function EditProfile(props) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showNotSaved, setShowNotSaved] = useState(-1)
    
    const history = useHistory()
    const config = props.config
    const user = props.user
    const pages = [{title : "Основное", id : 0}, {title : "Стоимость", id : 1}, {title : "Навыки", id : 2}]
    
    const isDesktop = getIsDesktop()
    const handleOnClick = (index) => {
        if (notSavedIndex == -1){
            setCurrentIndex(index)
        } else {
            setShowNotSaved(index)
        }
        
    }
    var notSavedIndex = -1
    const setNotSaved = (index) => {
        notSavedIndex = index
        console.log("notSavedIndex = ", notSavedIndex)
    }
    const closeDialog = () => {
        notSavedIndex = -1
        setCurrentIndex(showNotSaved)
        setShowNotSaved(-1)
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="desktop_edit">
                {isDesktop && <img onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}

                <div className="profile_edit_toolbar">
                    <div style={{width : '100%'}} className="flex">
                        {!isDesktop && <img onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>}
                        <p className="edit_profile_title">Редактировать профиль</p>
                    </div>
                </div>
                <div className="desktop_edit_toolbar">
                    <ScrollMenu LeftArrow={Arrow} RightArrow={Arrow}>
                        {pages.map((item, index) => (
                            <Page item={item} itemId={item.id} handleOnClick={handleOnClick} key={index} index={index} currentIndex={currentIndex}/>
                        ))}
                    </ScrollMenu>
                </div>
                 
                {user.name == undefined ? <Loader /> :
                    <div>
                        {currentIndex == 0 && <EditMainInfo getMyInfo={props.getMyInfo} showNotSaved={showNotSaved} closeDialog={closeDialog} setNotSaved={setNotSaved} user={user}/>}
                        {currentIndex == 1 && <EditPrices config={config} getMyInfo={props.getMyInfo} showNotSaved={showNotSaved} closeDialog={closeDialog} setNotSaved={setNotSaved} user={user}/>}
                        {currentIndex == 2 && <EditSkills config={config} getMyInfo={props.getMyInfo} showNotSaved={showNotSaved} closeDialog={closeDialog} setNotSaved={setNotSaved} user={user}/>}
                    </div>
                }
            </div>
            
        </div>
    );
}
function Page({ item, itemId, handleOnClick, index, currentIndex }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const style = currentIndex == index ? {marginLeft : index == 0 ? "16px" : "0", background: '#FFFFFF', borderRadius: '40px', color : 'black'} : {marginLeft : index == 0 ? "16px" : "0", opacity : 0.5}
    return (
        <div style={style} onClick={() => handleOnClick(index)} className="edit_profile_page hover">
           {item.title}
        </div> 
    );
}
function Arrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <div/>
    ); 
}
export default EditProfile;
