import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import avatar from '../../../img/avatar.svg'
import badge from '../../../img/profile/badge.svg'
import express_call from '../../../img/express_search/express_call.svg'
import express_chat from '../../../img/express_search/express_chat.svg'
import express_video from '../../../img/express_search/express_video.svg'
import badge_seleb from '../../../img/profile/badge_seleb.svg'
import { calculateAge, declension, getRating, Pages } from '../../../utils/Utils';
import review from '../../../img/profile/review.svg'
import help_circle from '../../../img/profile/help_circle.svg'
import { linkify } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';
import ProfileInfoFreeTimeDialog from '../../profile/dialogs/ProfileInfoFreeTimeDialog';

function ExpressProfile(props) {
    const history = useHistory()
    const [isShowFreeTime, setShowFreeTime] = useState(false)
    const user = props.user
    const age = calculateAge(user.bdate)
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade
    const rating = getRating(user)
    const bio = user.bio == undefined ? "" : user.bio.includes("\n") ? ("<div>" +  user.bio.replaceAll("\n", "<br/>") + "</div>") : user.bio
    const connectionType = props.type
    const isIncoming = props.isIncoming != undefined ? props.isIncoming : false
    const openProfile = () => {
        window.open(Pages.users + "/id" + user._id)
    }
    const getFreeTimeInfo = () => {
        return(
            <div className="express_profile_question">
                {(user.free_time + " " + declension(user.free_time, "минута", "минуты", "минут") + " бесплатно")}
                <div style={{paddingTop : 0, marginTop : '-4px'}} onClick={() => setShowFreeTime(true)} className="help_circle_profile_cont hover">
                    <img style={{marginLeft : '9px'}} className="help_circle_profile" src={help_circle}/>
                </div>
            </div>
        )
    }
    const getMsgPrice = () => {
        return(
            <div className="express_profile_question">
                <div style={{paddingTop : 0, marginTop : '-4px'}} onClick={() => setShowFreeTime(true)} className="help_circle_profile_cont hover">
                    <img style={{marginLeft : '9px'}} className="help_circle_profile" src={help_circle}/>
                </div>
            </div>
        )
    }
    const getLeftInfo = () => {
        return(
            <div>
                {isIncoming ?
                <div>
                    <div className="rating_container">
                        <img style={{marginRight : '8px'}} className="profile_exp_icon" src={connectionType == 0 ? express_chat : connectionType == 1 ? express_video : express_call}/>
                        <p className="profile_exp_review_title">{connectionType == 0 ? "Чат" : connectionType == 1 ? "Видео" : "Аудио"}</p>
                    </div>
                    <p style={{marginTop : '4px'}} className="profile_exp_review_desc">Способ связи</p>
                </div> 
                :
                <div>
                    <div className="rating_container">
                        <img className="profile_exp_icon" src={review}/>
                        <p className="profile_exp_review_title">{countReviews == 0 ? "" : rating}</p>
                    </div>
                    <p style={{marginTop : '4px'}} className="profile_exp_review_desc">{countReviews + " " + declension(countReviews, "отзыв", "отзыва", "отзывов")}</p>
                </div>}
            </div>
        )
    }
    const getInfo = () => {
        return(
            <div>
                {isIncoming ? 
                <div>
                    <div className="express_incoming_title">{props.urlParams.specialization}</div>
                </div> 
                :
                <div>
                    <div className="express_profile_about_title">О себе</div>
                    <div className="express_profile_about" dangerouslySetInnerHTML={{__html: linkify(bio)}}></div>
                    <div onClick={openProfile} className="express_profile_open hover">Смотреть профиль</div>
                </div>}
            </div>
        )
    }
    const rub_per_message = props.prices.rub_per_message
    const free_time = props.prices.free_time
    const rub_in_hour = props.prices.rub_in_hour
    const userName = (user.name ? user.name : "") + " " + (user.surname ? user.surname : "")
    return (
        <div>
            <a href={Pages.users + "/id" + user._id} target="_blank">
                <div className='hover'>
                    <img className="express_profile_avatar" src={user.avatar ? user.avatar : avatar}/>
                    <div className="express_profile_name_cont">
                        <div className="express_profile_name">{userName.trim() == "" ? "Имя неизвестно" : userName}</div>
                        <img className="express_profile_badge" src={user.verified ? badge_seleb : badge}/>
                    </div>
                </div>
            </a>
            {age != "" && <div className="express_profile_age">{age + " " + declension(age, "год", "года", "лет")}</div>}
            <div style={{margin : '16px 0 0 0'}} className="express_line"/>
            <div className="express_profile_info_cont">
                <div className="express_profile_info">
                    {getLeftInfo()}
                </div>
                <div className="express_profile_info_line"/>
                <div className="express_profile_info">
                    <div className="express_profile_price">{connectionType == 0 ? (rub_per_message > 0 ? rub_per_message + "₽/сообщение" : "бесплатно") : Math.round(rub_in_hour) + "₽/час"}</div>
                    {(free_time > 0 && connectionType != 0) && <span className="express_profile_free_time">{getFreeTimeInfo()}</span>}
                    {connectionType == 0 && getMsgPrice()}
                </div>
            </div>
            <div style={{margin : '0'}} className="express_line"/>
            {getInfo()}
            
            <ProfileInfoFreeTimeDialog isExpressSearch={true} user={user} isShow={isShowFreeTime} setShow={setShowFreeTime}/>

        </div>
    );
}

export default ExpressProfile;
