import { useState, useEffect, useCallback } from 'react';
import avatar from '../../../../img/avatar.svg'
import moment from 'moment'
import { getAges } from '../../CreateAds/helpers/CreateAdsHelper';
import { declension, getIsDesktop } from '../../../../utils/Utils';
import MyAdsItem from '../list_items/MyAdsItem';
import Loader from '../../../../custom_views/Loader';
import { AdsApi } from '../../../../api/AdsApi';
import not_found_experts from '../../../../img/experts/not_found_experts.svg'
import back_arrow from '../../../../img/experts/back_arrow.svg'
import { Route, useHistory } from "react-router-dom";
import AdsEmpty from './AdsEmpty';
import { getDesktopStyle } from '../../../../utils/Styles';
import other_back from '../../../../img/blogs/blogs_back.svg'


function AdsArchive(props) {
    const user = props.user
    const history = useHistory()

    const [allAds, setAllAds] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const isDesktop = getIsDesktop()
    useEffect(() => {
        getMyAds()
    }, [])
    const onBack = () => {
        history.goBack()
    }
    const getMyAds = () => {
        setIsLoading(true)
        AdsApi.getMyRequestsRepetitor((response) => {
            if (response.response > 0){
                const filtered = response.repetitorRequests.filter(item => item.status == 1)
                setAllAds(filtered)
            }
            setIsLoading(false)
        }, () => {setIsLoading(false)})
    }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header">
                <div className="flex">
                    {isDesktop ? <img onClick={onBack} style={{marginLeft : '16px'}} className="ads_back_desktop hover" src={other_back}/> 
                    : 
                    <img onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                    
                    <p className="ads_top_title">Архив объявлений</p>
                </div>
            </div>
        )
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="selected_settings">
            <div className="ads_archive_desktop">
                {getToolbar()}
                {isLoading ? <Loader/> : <div style={allAds.length == 0 ? {display: 'flex'} : {}} className="ads_archive_pad">
                    {(allAds.length == 0) && <div className="ads_mypicked_bg"><AdsEmpty title="Здесь будут объявления, добавленные в архив."/></div>}
                    {allAds.length > 0 && allAds.map((item, index) => (
                            <MyAdsItem setPickedAds={props.setPickedAds} item={item} index={index} key={index}/>
                        ))}
                </div>}
            </div>
        </div>
    )
}

export default AdsArchive;
