import { Pages } from '../../../utils/Utils';
import { useHistory, Link } from "react-router-dom";

function LKAbout(props) {

    const history = useHistory()

    const getItem = (title, onClick) => {
        return(
            <div onClick={onClick} className="lk_section_item hover">
                <div>{title}</div>
            </div>
        )
    }
    return (
        <div style={{marginBottom : '16px'}} className="lk_other lk_other_bottom">
            <div className="lk_other_title">О сервисе</div>
            {/* {getItem("Экспертам", () => {
                window.open(Pages.landing_for_experts, '_blank')
            })} */}
            <a href={Pages.landing_for_experts} target="_blank">
                <div className="lk_section_item hover">
                    <div>Экспертам</div>
                </div>
            </a>
            <a href={Pages.landing_for_clients} target="_blank">
                <div className="lk_section_item hover">
                    <div>Клиентам</div>
                </div>
            </a>
            {/* {getItem("Клиентам", () => {
                window.open(Pages.landing_for_clients, '_blank')
            })} */}
            <Link to={Pages.referals}>
                <div className="lk_section_item hover">
                    <div>Реферальная система</div>
                </div>
            </Link>
            {/* {getItem("Реферальная система", () => {
                history.push(Pages.referals)
            })} */}
            
        </div>)
}

export default LKAbout;