
import profile_more from '../../../img/profile/profile_more.svg'
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import BoardTooltip from '../tooltips/BoardTooltip';
import { Pages } from '../../../utils/Utils';
import moment from 'moment'

function BoardsItem(props) {
    const [isShowMore, toggleMore] = useState(false)

    const board = props.board
    console.log("board = ", board)
    const onBoard = () => {
        if (props.onBoard){
            props.onBoard(board)
        } else {
            window.open(Pages.call_board + board._id, '_blank')
        }
    }
    return (
        <div onClick={() => isShowMore ? toggleMore(false) : {}} className="relative">
            <div onClick={onBoard} className="boards_item">
                <div>
                    <div>
                        <div className="boards_item_title">{board.name}</div>
                        <div className="boards_item_desc">{board.theme}</div>
                        
                        <div className='flex'>
                            <div className='boards_item_created'>Создана {moment.parseZone(board.create_date).local().format("DD.MM.YYYY")}</div>
                            {(props.isPublic && board.owner_user._id) && <div className='flex'><div className='boards_item_created boards_item_author'>Автор</div></div>}
                        </div>
                    </div>
                </div>

                
            </div>
            {(props.isPublic && board.owner_user._id) && <a rel="noreferrer" target='_blank' href={Pages.users + '/id' +  board.owner_user._id} className='boards_item_author_name hover'>{board.owner_user.name}</a>}
            {(!props.isFromChat && !props.isPublic) && <img alt='' onClick={() => toggleMore(!isShowMore)} className="blog_item_bookmark boards_item_more hover" src={profile_more}/>}

            {isShowMore && <div onClick={() => toggleMore(false)} className="more_tooltip_profile_bg"></div>}

            <CSSTransition in={isShowMore} timeout={300} classNames="animation_in" unmountOnExit>
                <BoardTooltip setWorkDialogData={props.setWorkDialogData} getMyBoards={props.getMyBoards} showToast={props.showToast} board={board}/>
            </CSSTransition>

        </div>
        
    );
}

export default BoardsItem;
