/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { getIsDesktop } from '../../../utils/Utils';
import group_mic_off from '../../../img/call/group_mic_off.svg'
import group_mic_on from '../../../img/call/group_mic_on.svg'

const GroupCallTimer = (props) => {
    const [seconds, setSeconds] = useState(0)
    const [isActive, setIsActive] = useState(true)
    const plannerInfo = props.planner
    const streams = props.streams
    const startTime = moment(plannerInfo.sozvon_date).utc().local().toDate()/1000
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
            }
        }, [])


    useEffect(() => {
        let interval = null;
        if (isActive) {
        interval = setInterval(() => {
            //const s = seconds + 1
            const s = Math.round(new Date()/1000 - startTime)
            setSeconds(seconds => s );
         
        }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    const secondsToTime = (seconds) => {
        var time = "00:00"
        if (seconds < 3600){
            time = new Date(seconds * 1000).toISOString().substring(14, 19)
        } else {
            time = new Date(seconds * 1000).toISOString().substring(11, 16)
        }
        return time
    }
    const creatorStreams = streams.filter(s => s.user.is_creator)
    var creatorStream = {}
    if (creatorStreams.length > 0){
        creatorStream = creatorStreams[0]
    }
    const isDesktop = getIsDesktop()
    return (
        <div style={(isDesktop && props.isShowParticipants) ? {marginLeft : '-110px'} : (!props.isShowParticipants && !isDesktop) ? {bottom : '96px', marginBottom : '16px'} : {}} className="price_call_item_group center_horizontal group_item_timer">
            <div style={props.speakingUserId == plannerInfo.creator.vk_caller_id ? {outline: '2px solid #31F8C9'} : {}} className="chat_message_price_msg group_call_timer">
                <div>
                    {plannerInfo.creator.name + " " + (plannerInfo.creator.surname ? plannerInfo.creator.surname : "")}
                </div>
                {creatorStream.user && <img className='group_call_timer_mic' src={creatorStream.user.is_mic_on ? group_mic_on : group_mic_off} alt=""/>}
            </div>
            <p className="chat_message_price_msg group_call_timer">{secondsToTime(seconds)}</p>
        </div>
    )
}

export default GroupCallTimer;