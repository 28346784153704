import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useHistory } from "react-router-dom";
function RulesBottomSheet(props) {
    const history = useHistory()
    const isIRepetitor = props.isIRepetitor
    const config = props.config

    if (config == undefined || props.repetitor == undefined){
        return(<div/>)
    }
    const price = props.repetitor.rub_per_message ? isIRepetitor ? parseFloat(props.repetitor.rub_per_message*(1-config.comission)).toFixed(1) : props.repetitor.rub_per_message : 0
    const onDecline = () => {
        props.setOpenRules(false)
    }
    const isDesktop = getIsDesktop()
    const isFromCallScreen = props.isShowCallChat != undefined && props.isShowCallChat
    const isEnoughtMoney = (props.checkRepetitorResult == undefined || props.user.count_rub >= props.checkRepetitorResult.rub_per_message || props.isIRepetitor)
    const getInnerHtml = () => {
        return(
            <div className="rules_container">
                <p className="rules_title">Условия переписки</p>
                {props.isIRepetitor ? <p className="rules_text">Вы получаете <b>{price ? price : 0}₽ за каждое ответное сообщение.</b><br/><br/>Деньги начисляются только за парные сообщения.<br/><br/>Одно сообщение клиента подразумевает один вопрос. Если в сообщении клиента несколько вопросов, то вы можете их проигнорировать.</p> : 
                <p className="rules_text">По умолчанию чат с экспертом является бесплатным, чтобы потенциальный клиент мог задать уточняющие вопросы эксперту до начала платной консультации.<br/><br/>Когда переписка перерастёт в консультацию, эксперт может включить платный чат, и это отобразится у клиента заранее. Платная переписка с данным экспертом стоит <b>{price ? price : 0}₽ за каждое ответное сообщение.</b><br/><br/>Деньги списываются только за парные сообщения.<br/><br/>Один ответ эксперта подразумевает ответ на один ваш вопрос. Если у вас в одном сообщении несколько вопросов, то эксперт может их проигнорировать.</p>} 
                {isEnoughtMoney ? 
                <div className="rules_btns">
                    {/* <div onClick={onDecline} className="rules_left_btn hover">Отмена</div> */}
                    <div onClick={() => props.setOpenRules(false)} className="rules_left_btn rules_right_btn hover">Понятно</div>
                </div> : 
                <div style={{justifyContent : 'space-around'}} className="rules_btns">
                    <div style={{paddingTop : '14px', maxHeight : '48px'}} onClick={onDecline} className="rules_left_btn hover">Отмена</div>
                    <div style={{paddingTop : '4px', maxHeight : '48px'}} onClick={() => history.push("/pay")} className="rules_left_btn rules_right_btn hover">Пополнить<br/>баланс</div>
                </div>
                }
            </div>
        )
    }
    if (isDesktop){
        if (!props.isOpenRules){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isFromCallScreen={isFromCallScreen} isShow={props.isOpenRules} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isOpenRules}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default RulesBottomSheet;
