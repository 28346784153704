/* eslint-disable eqeqeq */
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import mic_check_on from '../../../img/call/mic_check_on.svg'
import mic_check_off from '../../../img/call/mic_check_off.svg'
function DisableMicDialog(props) {
    const [isPermanentEnabled, setPermanentEnabled] = useState(true)

    const cancel = () => {
        setShow()
    }
    const setShow = () => {
        props.setShow(false)
    }
    const disableAllMic = () => {
        props.disableAllMic(isPermanentEnabled)
        setShow()
    }
   
    const isDesktop = getIsDesktop()
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {textAlign : 'left', maxWidth : '400px'} : {textAlign : 'left'}} className="rules_container">
                <div className="chat_decline_planner_title">Отключить микрофоны для всех текущих и новых участников</div>
                <div className='flex call_permanent_mic'>
                    <img onClick={() => setPermanentEnabled(!isPermanentEnabled)} alt='checkbox' className='call_permanent_mic_img hover' src={isPermanentEnabled ? mic_check_off : mic_check_on}/>
                    <div className='call_permanent_mic_off'>Разрешить участникам включать свой звук</div>
                </div>
                <div style={{marginTop : '32px'}} className="rules_btns">
                    <div style={{maxHeight : '48px', width : '132px', marginRight : '8px', textTransform : 'none'}} onClick={cancel} className="rules_left_btn hover">Отмена</div>
                    <div onClick={disableAllMic} style={{width : '100%', textTransform : 'none'}} className="rules_left_btn rules_right_btn hover relative">Выключить звук для всех</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default DisableMicDialog
