import '../../styles/menu.css'
import BottomMenuItem from './BottomMenuItem';
import experts from '../../img/menu/experts.svg'
import chats from '../../img/menu/chats.svg'
import profile from '../../img/menu/user.svg'
import other from '../../img/menu/other.svg'
import ads from '../../img/menu/asd.svg'
import answers from '../../img/menu/answers.svg'
import blogs_menu from '../../img/menu/blogs_menu.svg'
import lk from '../../img/menu/lk.svg'
import { useState, useEffect } from 'react';
import { Pages, getUrlParameter } from '../../utils/Utils';
import { getCookie } from '../../utils/CookieHelper';

function BottomMenu(props) {
    const [currentLink, setCurrentLink] = useState(Pages.experts)
    useEffect(() => {
        setCurrentLink(Pages.experts)
    }, [])
    const user = props.user
    const web_view = getUrlParameter("web_view")
    if ((web_view != undefined && web_view != null && web_view != "") || (getCookie("web_view") != undefined && getCookie("web_view") != null && getCookie("web_view") != "")){
        return null
    }
    return (
        <div className="bottom_menu_container">
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.experts} title="Главная" icon={experts}/>
            <BottomMenuItem unreadedMsgs={props.unreadedMsgs} setCurrentLink={setCurrentLink} link={Pages.chats} title="Сообщения" icon={chats}/>
            {/* <BottomMenuItem setCurrentLink={setCurrentLink} link="/profile" title="Профиль" icon={profile}/>
            <BottomMenuItem setCurrentLink={setCurrentLink} link="/other" title="Другое" icon={other}/> */}
            {/* <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.answers} title="Ответы" icon={answers}/> */}
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.blogs} title="Блоги" icon={blogs_menu}/>
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.ads_board} title="Объявления" icon={ads}/>
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.lk} title="Кабинет" icon={lk}/>
        </div>
    );
}

export default BottomMenu;

