import { useState, useEffect, useCallback } from 'react';
import new_icon from '../../../../img/create_ads/new.svg'
import { useHistory } from "react-router-dom";
import { getAdsPrices, getSexes, getHowLong, linkify, getAges, getEducationTargets } from '../../CreateAds/helpers/CreateAdsHelper';
import moment from 'moment'
import views from '../../../../img/profile/views.svg'

function MyAdsItem(props) {
    const history = useHistory()
    const item = props.item
    const onClick = () => {
        props.setPickedAds(item)
        history.push("/ads_board/ads?id=" + item._id)
    }
    return (
        <div onClick={onClick} className="ads_item_container hover">
            <div className="ads_item_spec">{item.specialization}</div>
            <div className="ads_item_header">
                <div className="my_ads_item_title">{item.name}</div>
                {item.comments_count_new > 0 ? <div className="my_ads_counter">{item.comments_count_new}</div>
                : item.comments.length > 0 ? <div className="my_ads_counter_viewed">{item.comments.length}</div> : <div/>}
            </div>
            <div className="my_ads_item_date_row">
                <div className="ads_item_profile_text">Создано {moment.unix(moment(item.create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD MMMM")}</div>
                <div style={{marginTop : '4px'}} className="flex">
                    <img className="profile_views_img" src={views}/>
                    <p className="profile_views_text">{item.views}</p>
                </div>
            </div>
            <div className="ads_item_header_title ads_item_price_mb">{item.level_repetitor == 0 ? "Цена не имеет значения" : getAdsPrices()[item.level_repetitor]}</div>

        </div>
    )
}

export default MyAdsItem;
