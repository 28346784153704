import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { log, onResponse } from "./ApiUtils";
// "dependencies": {
//     "@vkontakte/calls-sdk": "^2.8.1",
//     "@vkontakte/calls-video-effects": "^2.2.2",
//     "autosize": "^5.0.1",
//     "buffer": "^6.0.3",
//     "firebase": "^9.17.1",
//     "highlight.js": "^11.9.0",
//     "imask": "^6.4.3",
//     "moment": "^2.29.4",
//     "pure-react-carousel": "^1.27.1",
//     "querystring": "^0.2.1",
//     "quill": "^1.3.7",
//     "quill-image-compress": "^1.2.30",
//     "react": "^18.2.0",
//     "react-circular-progressbar": "^2.1.0",
//     "react-debounce-input": "^3.3.0",
//     "react-dom": "^18.2.0",
//     "react-draggable": "^4.4.5",
//     "react-horizontal-scrolling-menu": "^3.2.5",
//     "react-image-crop": "^10.0.9",
//     "react-imask": "^6.4.3",
//     "react-phone-number-input": "^3.2.12",
//     "react-quilljs": "^1.3.3",
//     "react-resizable": "^3.0.4",
//     "react-router-dom": "^5.2.0",
//     "react-scripts": "^5.0.1",
//     "react-spring-bottom-sheet": "^3.4.1",
//     "react-transition-group": "4.4.5",
//     "react-virtualized-auto-sizer": "^1.0.7",
//     "react-window": "^1.8.7",
//     "react-youtube": "^10.1.0",
//     "rsuite": "^5.40.0",
//     "use-long-press": "^3.2.0",
//     "web-vitals": "^2.1.4",
//     "websocket": "^1.0.34"
//   }
export class DialogsApi {
    
    static getDialogs(onSuccess, onError) {
        var params = getRequestParams()
        fetch(getRequestUrl() + "getDialogs", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            //log("getDialogs responseData = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }

    static getDialogInfo(to_id, onSuccess, onError) {
        var params = getRequestParams()
        params.to_id = to_id
        fetch(getRequestUrl() + "getDialogInfo", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }

    static getDialog(to_id, onSuccess, onError) {
        var params = getRequestParams()
        params.to_id = to_id
        params.limit = 1000
        params.offset = 0
        //log("getDialog params = ", params)
        fetch(getRequestUrl() + "getDialog", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    
    static sendMessage(text, to_id, reply_message_id, onSuccess, onError, attachments_urls, message_id_for_group_chat) {
        var params = getRequestParams()
        params.to_id = to_id
        params.text = text
        if (message_id_for_group_chat){
            params.message_id_for_group_chat = message_id_for_group_chat
        }
        if (attachments_urls){
            params.attachments_urls = attachments_urls
        }
        if (reply_message_id){
            params.reply_message_id = reply_message_id
        }
        console.log("sendMessage p = ", params)
        log("sendMessage params = ", params)
        fetch(getRequestUrl() + "sendMessage", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("sendMessage responseData = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static setReadDialog(dialog_id, onSuccess, onError) {
        var params = getRequestParams()
        params.dialog_id = dialog_id
        fetch(getRequestUrl() + "setReadDialog", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }

    static checkRepetitor(to_id, onSuccess, onError) {
        fetch(getRequestUrl() + "checkRepetitor", getHttpParams({to_id : to_id})).then((response) => response.json())
        .then((responseData) => {
            log("checkRepetitor = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static sendTypingEvent(to_id, onSuccess, onError) {
        fetch(getRequestUrl() + "sendTypingEvent", getHttpParams({to_id : to_id})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static editMessage(_id, text, onSuccess, onError) {
        fetch(getRequestUrl() + "editMessage", getHttpParams({_id : _id, text : text})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static setPaidDialog(to_id, isPaidChat, onSuccess, onError) {
        fetch(getRequestUrl() + "setPaidDialog", getHttpParams({to_id : to_id, paid_chat : isPaidChat ? "1" : "0"})).then((response) => response.json())
        .then((responseData) => {
            log("setPaidDialog responseData = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    
  
}