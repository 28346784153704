import { setConfigMetaTags } from "../pages/experts/utils/ExpertsUtils";
import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { log, onResponse, setApiMetadata } from "./ApiUtils";
export class ConfigApi {
    
    static getConfig(onSuccess, onError) {
        fetch(getRequestUrl() + "getConfig", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("getConfig response = ", responseData)
            setConfigMetaTags(responseData.config)
            onResponse(responseData, onSuccess, onError)
        })
    }

    static getBestExperts(onSuccess, onError) {
        fetch(getRequestUrl() + "getBestExperts", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            //log("getBestExperts = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getRecExperts(current_user_id, onSuccess, onError) {
        fetch(getRequestUrl() + "getRecExperts", getHttpParams({current_user_id : current_user_id})).then((response) => response.json())
        .then((responseData) => {
            //log("getBestExperts = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    
    static getKeyWords(text, onSuccess, onError) {
        var params = {key_word : text}
        fetch(getRequestUrl() + "getKeyWords", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getSpecializations(onSuccess, onError) {
        fetch(getRequestUrl() + "getSpecializations", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }  
    static searchExpertsWithStar(onSuccess, onError) {
        fetch(getRequestUrl() + "searchExpertsWithStar", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }  
    static searchExpertsBySpecialization(specialization, onSuccess, onError) {
        var params = getRequestParams()
        params.specialization = specialization
        fetch(getRequestUrl() + "searchExpertsBySpecialization", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
            
        })
    }  
    static searchExperts(text, onSuccess, onError) {
        var params = getRequestParams()
        params.text = text
        fetch(getRequestUrl() + "searchExperts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
            setApiMetadata(responseData.meta_data)
        })
    }  
    static getSbpBankList(onSuccess, onError) {
        fetch(getRequestUrl() + "getSbpBankList", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }  
    static getAllCountries(onSuccess, onError) {
        fetch(getRequestUrl() + "getAllCountries", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }  
    static getAllRussianCities(onSuccess, onError) {
        fetch(getRequestUrl() + "getAllRussianCities", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }  
}