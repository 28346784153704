import notification_sheet from '../../../img/profile/notification_sheet.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, MyImage } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { getPushToken } from '../../../utils/PushToken';
import Loader from '../../../custom_views/Loader';
import { useState } from 'react';

function TgNotifDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const showTgDialog = () => {
       props.showTgDialog()
       props.setShow(false, undefined)
    }
    const close = () => {
        props.setShow(false, undefined)
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div className="call_info_container">
                <div className="notification_sheet_flex">
                    <MyImage className="notification_sheet_img" src={notification_sheet}/>
                </div>
                <div className="notification_sheet_text">Необходимо привязать Telegram, чтобы<br/>вы знали, когда вам звонят и пишут.</div>
                <button style={isDesktop ? {position : 'relative', left : 0, bottom : 0, maxHeight : '48px', width : '100%'} : {position : 'relative', bottom : 0, maxHeight : '48px'}} onClick={showTgDialog} disabled={isLoading} className="fill_button profile_bottom_button relative">{isLoading ? <Loader isWhite style={{minHeight : 'auto', marginTop : '24px'}}/> : "привязать Telegram"}</button>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={props.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet onDismiss={close} style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default TgNotifDialog;
