import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import { ExpressSearchApi } from '../../../api/ExpressSearchApi';
import { Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function CloseExpressSearch(props) {
    const isDesktop = getIsDesktop()
    const closeSearch = () => {
        ExpressSearchApi.declineAllExpressSearch((responseData) => {

        })
        window.location.href = Pages.experts
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="create_ads_close_title">Закрыть поиск?</p>
                <div className="create_ads_close_desc">Восстановить его будет<br/>невозможно</div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px', color : 'white', background : '#FF5353'}} onClick={closeSearch} className="call_price_continue hover">Закрыть</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CloseExpressSearch;
