import back_arrow from '../../../img/experts/back_arrow.svg'
import other_back from '../../../img/other/other_back.svg'
import { useHistory } from "react-router-dom";
import { getIsDesktop } from '../../../utils/Utils';
import { getDesktopStyle } from '../../../utils/Styles';

function Rules(props) {
    const history = useHistory()

    const openPolicy = () => {
        window.open('https://sozvon.pro/docs/privace_policy.pdf', '_blank')
    }
    const openAgreement = () => {
        window.open('https://sozvon.pro/docs/conditions.pdf', '_blank')
    }
    const openReferalsRules = () => {
        window.open('https://sozvon.pro/docs/referral_system.pdf', '_blank')
    }
    const isDesktop = getIsDesktop()
    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="selected_settings">
            <div className="desktop_referals">
                {isDesktop && <img alt='' onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}

                <div className="arrow_left_theme">
                    <div style={{width : '100%'}} className="flex">
                        {!isDesktop && <img alt='' onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>}
                        <p className="theme_title">Правила сервиса</p>
                    </div>
                </div>
                <div style={isDesktop ? {maxWidth: '360px', margin : '0 auto'} : {}} className="other_rules_container">
                    <div className="other_item_container">
                        <p onClick={openPolicy} className="other_item_title hover">Политика обработки персональных данных</p>
                        <p onClick={openAgreement} className="other_item_title hover">Условия использования sozvon.pro</p>
                        <p onClick={openReferalsRules} className="other_item_title hover">Условия реферальной системы sozvon.pro</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rules;
