/* eslint-disable eqeqeq */
import add_ads from '../../../../img/create_ads/add_ads.svg'
import archive from '../../../../img/create_ads/archive.svg'
import { getIsDesktop } from '../../../../utils/Utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function AdsBoardHeader(props) {
    const isDesktop = getIsDesktop()
    const user = props.user
    const selected = props.selected
    const changeSelected = props.changeSelected
   
    return (
        <div className="ads_board_header_container">
            <div className="ads_board_header">
                <div className="ads_board_title">{props.isRepetitor ? "Объявления" : "Мои объявления"}</div>
                {!isDesktop && <div className="flex">
                    <Link to={"/ads_board/ads_archive"}><img alt="" className="ads_board_header_icon hover" src={archive}/></Link>
                    <Link to={"/create_ads/step_1"}><img alt="" style={{marginRight : '-8px'}} className="ads_board_header_icon hover" src={add_ads}/></Link>
                </div>}
            </div>
            {user.is_repetitor && <div className="ads_horizontal_scroll">
                <SelectedItem title="Все" index={0} selected={selected} setSelected={changeSelected}/>
                <SelectedItem title="Рекомендованные" index={1} selected={selected} setSelected={changeSelected}/>
                <SelectedItem title="Созданные вами" index={2} selected={selected} setSelected={changeSelected}/>
            </div>}
        </div>
    )
}

export default AdsBoardHeader;
const SelectedItem = ({ title, index, selected, setSelected }) => {
    return (
        <div onClick={() => setSelected(index)} style={index == selected ? {background : 'black', color : 'white'} : {}} className="ads_board_header_title hover">
            {title}
        </div>
    )
}