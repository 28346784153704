function AdsAnswerTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }    
     return (
        <div className="profile_published_tooltip">
            <div className="ads_answer_tooltip">
                <div style={{bottom : '6px', position : 'absolute', left : '32px'}} className="green_rectangle"></div>
                <div style={{width : '268px'}} className="green_profile_tooltip">При этом вы обязуетесь оказать услугу через платформу sozvon.pro. Переводить общение с клиентом в другие сервисы нельзя.</div>
            </div>
        </div>
     );
 }
 
 export default AdsAnswerTooltip;
 