/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import '../../styles/blogs.css'
import { useState, useEffect } from 'react';
import { getIsDesktop, Pages} from '../../utils/Utils';
import { isUserAuthed, getCookie, setCookie } from '../../utils/CookieHelper';
import BlogsHeader from './custom_views/BlogsHeader';
import AdsFilter from '../ads_desk/AdsBoard/custom_views/AdsFilter';
import { Route, useHistory } from "react-router-dom";
import { BlogsApi } from '../../api/BlogsApi';
import BlogItem from './custom_views/BlogItem';
import empty from '../../img/blogs/empty.svg'
import { Loader } from 'rsuite';
import BlogsTooltips from './tooltips/BlogsTooltips';
import { CSSTransition } from 'react-transition-group';
import Toast from '../profile/custom_views/Toast';
import { getDesktopStyle } from '../../utils/Styles';
import BlogsExplain from './custom_views/BlogsExplain';

function Blogs(props) {
    
    const [selectedSpecs, setSelectedSpecs] = useState([])
    const [isAll, setAll] = useState(0)
    const [posts, setPosts] = useState([])
    const [interestingPosts, setInterestingPosts] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [tooltipIndex, setTooltipIndex] = useState(-1)
    const [toastText, setToastText] = useState("")
    const [scrollTop, setScrollTop] = useState(0)

    const history = useHistory()
    const user = props.user
    const isDesktop = getIsDesktop()
    const isRepetitor = props.user.is_repetitor
    // useEffect(() => {
    //     getLastPosts()
    // }, [])
    useEffect(() => {
        
         getFilteredPosts()
    }, [selectedSpecs, isAll])
    useEffect(() => {
        if (props.user != undefined && props.user.vk_caller_id != undefined && getCookie("shown_blogs_tips") != getCookie("id")){
            setCookie("shown_blogs_tips", getCookie("id"))
            setTooltipIndex(isRepetitor ? 0 : 1)
        }
        // setTooltipIndex(isRepetitor ? 0 : 1)
   }, [props.myUser || props.user])
    const searchParams = {
        "specializations": selectedSpecs,
        "is_all": isAll,
    }
    const getFilteredPosts = () => {
        getLastPosts()
    }
    const getLastPosts = () => {
        BlogsApi.getLastPosts(50, 0, searchParams.is_all ? "" : selectedSpecs.join(","), (responseData) => {
            const posts = responseData.results
            setPosts(posts)
            addView(posts, 0)
            setLoading(false)
            if (responseData.results.length == 0){
                getInterestingPosts()
            }
        }, () => {
            setLoading(false)
        })
    }
    const getInterestingPosts = () => {
        BlogsApi.getLastPosts(100, 0, "", (responseData) => {
            if (responseData.results && responseData.results.length > 0){
                setInterestingPosts(responseData.results.sort((a, b) => b.likes - a.likes))
                
            }
        }, () => { setLoading(false) })
    }
    const removePost = (index) => {
        var myPosts = [...posts]
        myPosts.splice(index, 1)
        setPosts(myPosts)
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const addView = (posts, i) => {
        if (i < posts.length){
            const post = posts[i]
            if (!post.is_viewed){
                BlogsApi.addViewToPost(post._id, (responseData) => {
                    addView(posts, i + 1)
                }, () => {})
            }
        }
    }
    const handleScroll = event => {
        if (isDesktop){
            setScrollTop(event.currentTarget.scrollTop)
        }
    }
    const getEmptyBlogs = () => {
        return(
            <div>
                <div className="blogs_empty">
                    <img alt="" className="blogs_empty_img" src={empty}/>
                    По текущим условиям показа<br/>ничего не найдено
                </div>
                {interestingPosts.length > 0 &&<div>
                    <div className="blogs_interesting_title">Может быть интересно</div>
                    <div className="blogs_list">
                        {interestingPosts.length > 0 && interestingPosts.map((post, index) => (
                            <BlogItem myUser={user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} post={post}/>
                        ))}
                    </div> 
                </div>}
            </div>
        )
    }
    return (
        
        <div onScroll={handleScroll} style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings"> 
            <div className="lk_container_inner">
                <div className="blogs_inner">
                    <BlogsHeader/>
                    {isRepetitor && <div className="flex blogs_create_bg">
                        <div className="blogs_create_text">Поделитесь знаниями из вашей области — это продемонстрирует вашу экспертность и привлечёт новых клиентов</div>
                        <div onClick={() => history.push(Pages.create_blog_post)} className="blog_write_btn hover">Написать пост</div>
                    </div>}
                    { isLoading ? 
                        <Loader style={{marginTop : '56px'}}/>
                    :
                        posts.length > 0 ? <div className="blogs_list">
                            {posts.length > 0 && posts.map((post, index) => (
                                <BlogItem showToast={showToast} removePost={removePost} myUser={user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} post={post}/>
                            ))}
                        </div> 
                    :
                        getEmptyBlogs()
                    } 
                    <Route path={Pages.blogs_filter}>
                        <AdsFilter scrollTop={scrollTop} isBlogFilter specializations={props.specializations} searchParams={searchParams} setPrice={() => {}} setWithoutRespond={() => {}} setAll={setAll} setSelectedSpecs={setSelectedSpecs}/>
                    </Route>
                </div>
                {isDesktop && <div className="lk_explain_relative">
                    <BlogsExplain scrollTop={scrollTop}/>
                    <AdsFilter scrollTop={scrollTop} isBlogFilter specializations={props.specializations} searchParams={searchParams} setPrice={() => {}} setWithoutRespond={() => {}} setAll={setAll} setSelectedSpecs={setSelectedSpecs}/>
                </div>}
            </div>
            {(tooltipIndex >= 0 && tooltipIndex < 3) && <BlogsTooltips isRepetitor={isRepetitor} tooltipIndex={tooltipIndex} setShow={() => setTooltipIndex(tooltipIndex + 1)}/>}
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
    );
}

export default Blogs;

