/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

const ReadyTimer = (props) => {
    const [seconds, setSeconds] = useState(0)
    const [isActive, setIsActive] = useState(true)
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
            }
        }, [])
    // function toggle() {
    //     setIsActive(!isActive);
    // }

  
    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                const currentTime = moment(new Date()).unix()
                const s = Math.round(props.startTime - currentTime)
            
                setSeconds(seconds => s);
                props.onTimer(s)
                if (s <= -1800 && !props.isShowTimer){
                    setIsActive(false)
                    clearInterval(interval)
                }
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);
    
    // const secondsToTime = () => {
    //     var time = "00:00"
    //     const sec = 120 - seconds
    //     if (seconds < 3600){
    //         time = new Date(sec * 1000).toISOString().substring(14, 19)
    //     } else {
    //         time = new Date(sec * 1000).toISOString().substring(11, 16)
    //     }
    //     console.log("timerTime = " + sec)
    //     return time
    // }
   

    return (
        <div className="code_timer">
        </div>
    )
}

export default ReadyTimer;