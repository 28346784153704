import search_icon from '../../../img/experts/search.svg'
import { DebounceInput } from 'react-debounce-input';


function BoardsSearch(props) {
  

    const onTextChanged = (text) => {
        var q = text == undefined ? "" : text
        props.setSearchText(q)
    }
    return (
        <div>
            <div className="flex">
                <div className="boards_search_cont">
                    <img className="search_experts_icon" src={search_icon}/>
                    <DebounceInput debounceTimeout={700} placeholder="Поиск" className="boards_search_input" type="text" value={props.text} onChange={(e) => onTextChanged(e.target.value)}/>
                </div>   
            </div>
        </div>
        
    );
}

export default BoardsSearch;
