import { useState, useEffect, useCallback } from 'react';
import add_ads from '../../../../img/create_ads/add_ads.svg'
import archive from '../../../../img/create_ads/archive.svg'
import { useHistory } from "react-router-dom";

function AdsDesktopHeader(props) {
    const history = useHistory()
    const user = props.user
    const selected = props.selected
    const changeSelected = props.changeSelected
    const onArchive = () => {
        history.push("/ads_board/ads_archive")
    }
    const onAdd = () => {
        history.push("/create_ads/step_1")
    }
    return (
        <div style={{marginTop : props.isRepetitor ? '16px' : 0}} className="ads_desktop_header">
            <button onClick={onAdd} style={{margin : '16px', width : 'calc(100% - 32px)'}} className="fill_button hover">Создать объявление</button>
            <div onClick={onArchive} className="ads_archive_btn hover">
                <img className="ads_board_header_icon hover" src={archive}/>
                <div style={{margin : '8px 0 0 0'}}>Неактивные объявления</div>
            </div>
        </div>
    )
}
export default AdsDesktopHeader;