/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { PlannerApi } from '../../../api/PlannerApi';
import Loader from '../../../custom_views/Loader';
import autosize from 'autosize';

function CancelVideoCallDialog(props) {
    const [isDeclineLoading, setDeclineLoading] = useState(false)
    const [error, setError] = useState("")
    const [text, setText] = useState("")
    
    const refTextarea = useRef(null)
    useEffect(() => {
        if (props.isShow){
            //refTextarea.current.focus();
            autosize(refTextarea.current);
        }
    }, [props.isShow])
    
    const planner = props.planner
    const cancel = () => {
        setShow()
    }
    const decline = () => {
        setError("")
        setDeclineLoading(true)
       
        PlannerApi.cancelSozvonByLink(planner._id, text, (responseData) => {
            setDeclineLoading(false)
            setShow()
            if (props.onPlannerCanceled != undefined){
                props.onPlannerCanceled()
            }
            
            if (props.plannerMsgChanged != undefined){
                props.plannerMsgChanged(planner, false)
            }  
        }, (responseData) => {
            setError(responseData.message)
            setDeclineLoading(false)
        })
        
    }
    const setShow = () => {
        props.setShow(false)
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () => {
        return(
            <div style={{textAlign : 'left'}} className="rules_container">
                <div className="chat_decline_planner_title">Вы собираетесь отменить запланированный созвон</div>
                <div className="chat_decline_planner_desc">Причина отмены (это поле обязательное)</div>
                <textarea ref={refTextarea} style={{minHeight : '24px'}} placeholder="Например, не могу в это время" className="profile_input profile_textare_bio"  value={text} onChange={(e) => setText(e.target.value)}/>

                {/* <input onChange={(e) => setText(e.target.value)} className="chat_decline_planner_input" placeholder="Например, не могу в это время" type="text" value={text}/> */}
                <div className="chat_decline_planner_line"/>
                {/* <div className="chat_decline_planner_desc">100/400 символов</div> */}
                {error != "" && <div className="planner_cancel_error">{error}</div>}
                <div style={{justifyContent : 'space-between', marginTop : '68px'}} className="rules_btns">
                    <div style={{maxHeight : '48px'}} onClick={cancel} className="rules_left_btn hover">Отмена</div>
                    <div style={text.length > 0 ? {maxHeight : '48px'} : {pointerEvents : 'none', opacity : '0.3', maxHeight : '48px'}} onClick={decline} className="rules_left_btn rules_right_btn hover relative">{isDeclineLoading ? <Loader/> : "Подтвердить"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default CancelVideoCallDialog;
