import CreateAdsButtons from "./custom_views/CreateAdsButtons";
import CreateAdsHeader from "./custom_views/CreateAdsHeader";

import { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import CreateAdsDaysPicker from "./custom_views/CreateAdsDaysPicker";
import { getUrlParameter } from "../../../utils/Utils";
import { AdsApi } from "../../../api/AdsApi";

function CreateAdsStep8(props) {
     
    const history = useHistory() 
    const id = getUrlParameter("id")
    useEffect(() => {
        const value = getUrlParameter("value")
        if (value != null && value != undefined && value != ""){
            props.setTime(value.split(","))
        }
    }, [])
    const toggleItem = (item) => {
        var times = [...props.time]
        if (times.includes(item)){
            const index = times.indexOf(item);
            if (index > -1) { 
                times.splice(index, 1)
            }
        } else {
            times.push(item)
        }
        props.setTime(times)
        console.log("times = ", times)
        console.log("times item  = ", item)
    }
    
    const onNext = () => {
        if (props.isEdit){
            AdsApi.editRequestRepetitor({repetitor_request_id : id, desired_class_time : props.time.join(",")}, (response) => {
                window.location.href = '/ads_board/ads?id=' + id + "&is_details=true"
            })
        } else {
            history.push("/create_ads/step_9")
        }
    }
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_light_title">{props.pickedSpecs}</div>
                    <div className="create_ads_radio_header" dangerouslySetInnerHTML={{__html: "Когда вам удобно заниматься?"}}/>
                    <CreateAdsDaysPicker toggleItem={toggleItem} time={props.time}/>
                </div>
                <CreateAdsButtons isEdit={props.isEdit} onNext={onNext}/>
            </div>
        </div>
    );
}

export default CreateAdsStep8;
