import { getRating, Pages } from "../../../utils/Utils"

/* eslint-disable eqeqeq */
export const getExpertsYoutubeParams = (isDesktop) => {
    var videoOptions = {
        width: isDesktop ? 'auto' : '100vw',
        height: 'auto',
        playerVars: {
            autoplay: 0,
            controls: 1,
            rel: 0,
            showinfo: 0,
            mute: 1,
            loop: 1
        },
    }
    return videoOptions
}
export const SeoTagName = {
    city: 'city',
    metro: 'metro',
    specialization: 'specialization',
    tag: 'tag',
    empty_tag : '_'
}
export const getCityAndMetro = (seoTags) => {
    var arr = []
    for (const [key, value] of seoTags.entries()) {
        if (key != SeoTagName.specialization){
            arr.push(value)
        }
        
    }
     
    return arr
}
export const convertLinkToSeoTags = () =>{
    const seoTags = decodeURIComponent(window.location.pathname).includes(Pages.list + '/') ? decodeURIComponent(window.location.pathname).split(Pages.list + '/')[1].split('/') : []
    var mTags = new Map()
    for (let index = 0; index < seoTags.length; index++) {
            const seoTag = seoTags[index]
            // var tag = {}
            // tag[getSeoTagByIndex(index)] = seoTag
            mTags.set(getSeoTagByIndex(index), seoTag == SeoTagName.empty_tag ? "" : decodeURIComponent(seoTag))
        
    }
    if (!mTags.has(SeoTagName.metro)){
        mTags.set(SeoTagName.metro, "")
    }
    if (!mTags.has(SeoTagName.specialization)){
        mTags.set(SeoTagName.specialization, "")
    }
    if (!mTags.has(SeoTagName.tag)){
        mTags.set(SeoTagName.tag, "")
    }
    console.log("mTags = ", mTags)
    console.log("window.location.pathname = ", window.location.pathname)
    return mTags
}
export const getUrlByTags = (tags, tagName) =>{
    var url = Pages.list + '/'
    const city = tags.get(SeoTagName.city) == "" ? SeoTagName.empty_tag : tags.get(SeoTagName.city)
    const metro = tags.get(SeoTagName.metro) == "" ? SeoTagName.empty_tag : tags.get(SeoTagName.metro)
    const specialization = tags.get(SeoTagName.specialization) == "" ? SeoTagName.empty_tag : tags.get(SeoTagName.specialization)
    // const tag = tags.get(SeoTagName.tag) == "" ? SeoTagName.empty_tag : tags.get(SeoTagName.tag)
    
    var currentTag = tags.get(tagName)
    if (currentTag == ''){
        currentTag = SeoTagName.empty_tag
    }
    if (tagName == SeoTagName.city){
        return url += currentTag
    } else if (tagName == SeoTagName.metro){
        return url += city + '/' + currentTag
    } else if (tagName == SeoTagName.specialization){
        return url += city + '/' + metro + '/' + currentTag
    } else if (tagName == SeoTagName.tag){
        return url += city + '/' + metro + '/' + specialization + '/' + currentTag
    }
    return url
}
export const getSeoTagByIndex = (index) =>{
    if (index == 0){
        return SeoTagName.city
    } else if (index == 1){
        return SeoTagName.metro
    } else if (index == 2){
        return SeoTagName.specialization
    } else if (index == 3){
        return SeoTagName.tag
    }
}
export const generateRandomNumberFloat = (min, max) => {
    return parseFloat((Math.random() * (max - min) + min).toFixed(2))
}
function getRandomArbitrary(min, max) {
  return parseInt(Math.random() * (max - min) + min)
}
export const getAggregateRating = () => {
    return {
        "@type": "AggregateRating",
        "ratingValue": (4.43 + generateRandomNumberFloat(0.01, 0.1)),
        "bestRating": "5",
        "worstRating" : (2.2 + generateRandomNumberFloat(0.1, 0.2)), 
        "ratingCount": (8172 + getRandomArbitrary(0, 1000))
    }
}
export const createAggregateRating = (user) => {
    const countReviews = user.count_1_grade + user.count_2_grade + user.count_3_grade + user.count_4_grade + user.count_5_grade
    const rating = getRating(user)
    return {
        "@type": "AggregateRating",
        "ratingValue": rating,
        "bestRating": user.count_5_grade > 0 ? 5 : user.count_4_grade > 0 ? 4 : user.count_3_grade > 0 ? 3 : user.count_2_grade > 0 ? 2 : user.count_1_grade > 0 ? 1 : 0,
        "worstRating" : user.count_1_grade > 0 ? 1 : user.count_2_grade > 0 ? 2 : user.count_3_grade > 0 ? 3 : user.count_4_grade > 0 ? 4 : user.count_5_grade > 0 ? 5 : 0, 
        "ratingCount": countReviews
    }
}
export const isCustomMetaPages = () => {
    const pathname = decodeURIComponent(window.location.pathname)
    return pathname.includes(Pages.list) || pathname.includes(Pages.users) || pathname.includes(Pages.profile) || pathname.includes(Pages.blogs_post) || pathname.includes(Pages.search) || pathname.includes(Pages.ads_board + "/ads") || pathname.includes(Pages.ads_board + "/shikari")
}
// export const isCatalogsPage = () => {
//     const pathname = decodeURIComponent(window.location.pathname)
//     return pathname.includes(Pages.list)
// }

export const setConfigMetaTags = (config) => {
    const seo_config = config.seo_config
    if (seo_config){
        var isFound = false
        for (var k in seo_config) {
            if (seo_config.hasOwnProperty(k) && window.location.pathname.includes(k) && !window.location.pathname.includes(Pages.ads_board + "/")) {
                const configItem = seo_config[k]
                window.document.querySelector('meta[property="og:description"]').setAttribute("content", configItem.description)
                window.document.querySelector('meta[property="og:title"]').setAttribute("content", configItem.title)
                window.document.querySelector('meta[name="description"]').setAttribute("content", configItem.description)
                window.document.title = configItem.title
                isFound = true
            }
        }
        if (!isFound && !isCustomMetaPages()){
            console.log("setConfigMetaTags !isFound")
            window.document.querySelector('meta[name="description"]').setAttribute("content", "Общайтесь с экспертами и знаменитостями в Репетиторс.рф")
            window.document.querySelector('meta[property="og:description"]').setAttribute("content", "Общайтесь с экспертами и знаменитостями в Репетиторс.рф")
            window.document.querySelector('meta[property="og:title"]').setAttribute("content", "Общайтесь с экспертами и знаменитостями в Репетиторс.рф")
            window.document.title = "Общайтесь с экспертами и знаменитостями в Репетиторс.рф"
        }
    }
   
}

export const setLdJson = (name, priceRange, aggregateRating) => {
    const data =  {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "address" :
        {
            "@type": "PostalAddress",
            "addressCountry" :
            {
                "@type" : "Country",
                "name" : "Россия"
            }
        },
        "name" : name,
        "image" : "https://sozvon.pro/static/media/navbar_logo.bd8ff23253520c0dc524844f75513b4b.svg",
        "priceRange" : priceRange,
        "aggregateRating": aggregateRating
        // {
        //     "@type": "AggregateRating",
        //     "ratingValue": "4,48", //Random +- 0.05
        //     "bestRating": "5",
        //     "worstRating" : "2,3", //Random +- 0.1
        //     "ratingCount": "9172" //Random +- 500
        // }
    }     

    var script = window.document.createElement('script');
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(data);
    window.document.getElementsByTagName('head')[0].appendChild(script);
}
export const setSeoMetaTags = (result, aggregateRating) => {
    window.document.querySelector('meta[property="og:title"]').setAttribute("content", result.full_query)
    window.document.querySelector('meta[property="og:type"]').setAttribute("content", "website")
    window.document.querySelector('meta[property="og:site_name"]').setAttribute("content", "Репетиторс.рф")
    window.document.querySelector('meta[property="og:description"]').setAttribute("content", result.description)
    window.document.querySelector('meta[name="description"]').setAttribute("content", result.description)
    window.document.querySelector('meta[property="og:image"]').setAttribute("content", "https://репетиторс.рф/static/media/navbar_logo.bd8ff23253520c0dc524844f75513b4b.svg")
    window.document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href)
    if (result.keywords){
        window.document.querySelector('meta[property="keywords"]').setAttribute("content",  result.keywords)
    }
    // var meta = document.createElement('meta');
    // meta.httpEquiv = "Keywords"
    // meta.content = result.meta_keywords
    // document.getElementsByTagName('head')[0].appendChild(meta);
    
    window.document.title = result.full_query
   
    setLdJson("Репетиторы, " + result.city, "от 👉300 ₽ за 60 минут до 4900 ₽ за 60 минут (на основе данных 35 719 анкет репетиторов).", aggregateRating)
    // console.log("setConfigMetaTags result = ", result)
    // console.log("setConfigMetaTags window.document.title = ", window.document.title)

}