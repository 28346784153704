import '../../styles/answers.css';
import '../../styles/express_search.css';
import { useState, useEffect, useCallback } from 'react';
import { Route, useHistory } from "react-router-dom";
import { UsersApi } from '../../api/UsersApi';
import ExpressProfile from './custom_views/ExpressProfile';
import Loader from '../../custom_views/Loader';
import decline from '../../img/express_search/decline.png';
import AcceptExpressBtn from './custom_views/AcceptExpressBtn';
import { ExpressSearchApi } from '../../api/ExpressSearchApi';
import { Pages } from '../../utils/Utils';
import { CSSTransition } from 'react-transition-group';
import Toast from '../profile/custom_views/Toast';
import { linkify } from '../ads_desk/CreateAds/helpers/CreateAdsHelper';

function ExpressSearchRequest(props) {
    const history = useHistory()
    const [isLoading, setLoading] = useState(true)
    const [isAccepted, setAccepted] = useState(false)
    const [placeInQueue, setPlaceInQueue] = useState(1)
    const [isClientAccepted, setClientAccepted] = useState(false)
    const [clientDeclinedMsg, setClientDeclinedMsg] = useState("")
    const [descriptionMsg, setDescriptionMsg] = useState("")
    const [error, showError] = useState("")
    const [user, setUser] = useState({})
    const urlParams = props.urlParams
    const socketMessage = props.socketMessage
    const config = props.config
    const myUser = props.myUser
    useEffect(() => {
        getUser(urlParams.owner)
        getExpressSearch(urlParams.owner)
   }, [])
   useEffect(() => {
        if (socketMessage != undefined && socketMessage.event == "minusQueueExpress"){
            setPlaceInQueue(placeInQueue - 1)
        }
        if (socketMessage != undefined && socketMessage.event == "acceptExpress"){
            setClientAccepted(true)
            props.startConnect(urlParams.connection_type, user)            
        }
        if (socketMessage != undefined && socketMessage.event == "declineExpress"){
            setClientDeclinedMsg(socketMessage.reject)
        }
    }, [socketMessage])
   
    const getUser = (id) => {
        UsersApi.getUserByVkCallerID(id, (mUser) => {
            setUser(mUser)
            setLoading(false)
        }, () => {setLoading(false)})
    }
    const getExpressSearch = (id) => {
        ExpressSearchApi.getExpressSearch(id, (responseData) => {
            setDescriptionMsg(responseData.result.description)
        })
    }
    const getLoader = () => {
        return(
            <div className="relative full_center">
                <div className="express_loader_cont">
                    <Loader style={{marginTop : '124px', minHeight : 'auto', position : 'relative'}}/>
                </div>
            </div>
        )
    }
    const declineCall = () => {
        window.location.href = Pages.experts
    }
    const acceptCall = () => {
        ExpressSearchApi.respondExpressSearch(urlParams.owner, (responseData) => {
            setAccepted(true)
            setPlaceInQueue(responseData.place_in_queue)
        }, (responseData) => {
            showError(responseData.desc)
        })
    }
    const getTitle = () =>{
        return(
            <p className="express_header_title express_request_title">Запрос<br/>на экспресс-консультацию</p>
        )
    }
    if (clientDeclinedMsg != ""){
        return(
            <div className="create_ads_container">
                {getTitle()}
                <div className="create_ads_step create_ads_background express_ads_background">
                    <div className="express_client_picked express_expert_connection">
                        Клиент выбрал другого эксперта 🤓
                        <div className="express_inc_connecting">Рекомендуем отредактировать ваш профиль так, чтобы он стал более привлекательным для клиентов. </div>
                    </div>
                </div>
            </div>
        )
    }
    if (isClientAccepted){
        return(
            <div className="create_ads_container">
                {getTitle()}
                <div className="create_ads_step create_ads_background express_ads_background">
                    <div className="express_client_picked express_expert_connection">
                        🥳<br/>Клиент выбрал вас
                        <div className="express_inc_connecting">Сейчас соединим вас</div>
                    </div>
                </div>
            </div>
        )
    }
    const priceWithComission = Math.round(myUser.rub_in_hour * (1 - config.comission))
    const userPrices = {rub_per_message : parseFloat(myUser.rub_per_message * (1 - config.comission)).toFixed(1), free_time : myUser.free_time, rub_in_hour : priceWithComission}
    const text = descriptionMsg == "" ? "" : descriptionMsg.includes("\n") ? ("<div>" +  descriptionMsg.replaceAll("\n", "<br/>") + "</div>") : descriptionMsg

    return (
        <div className="create_ads_container">
            {getTitle()}
            <div className="create_ads_step">
                <div style={!isLoading ? {minHeight : 'auto'} : {}} className="create_ads_background express_ads_background">
                    {(isLoading || user.vk_caller_id == undefined) ? getLoader() : <ExpressProfile prices={userPrices} urlParams={urlParams} isIncoming={true} type={urlParams.connection_type} user={user}/>}
                    {descriptionMsg != "" && <div className="express_description_cont">
                        <div className="express_description_title">Тема консультации</div>
                        <div className="express_description_text" dangerouslySetInnerHTML={{__html: linkify(text)}}></div>
                    </div>}
                </div>
            </div>
            {isAccepted ? 
                <div className="relative">
                    <Loader style={{marginTop : '24px', minHeight : '24px', position : 'relative'}}/>
                    <div className="express_inc_wait_title">Подождите, клиент<br/>выбирает из нескольких экспертов</div>
                    <div className="express_inc_wait_position">Ваша позиция в очереди {placeInQueue}</div>
                </div>
                :
                <div className="express_search_buttons_inc">
                <div onClick={declineCall} style={{marginRight : '40px'}} className="hover">
                    <img className="express_search_button" src={decline}/>
                    <div style={{color : '#FF5353', marginTop : '14px'}} className="express_search_btn_text">Отказаться</div>
                </div>
                <AcceptExpressBtn urlParams={urlParams} error={error} onAccept={acceptCall}/>
            </div>}
           
        </div>
    );
}

export default ExpressSearchRequest;
