import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { CallApi } from '../../../api/CallApi';

function ChangeRole(props) {
    const isDesktop = getIsDesktop()
    const changeRole = () => {
        CallApi.changeDialogRepetitor(props.to_id, (response) => {
            //props.checkRepetitor()
            if (props.checkRepetitor){
                props.checkRepetitor()
            }
            if (props.declineCall){
                props.declineCall()
            }
            
        })
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <div className="call_edit_desc">Передать роль эксперта<br/>собеседнику?</div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px'}} onClick={changeRole} className="call_price_continue hover">Передать</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ChangeRole;
