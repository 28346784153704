/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState, useEffect } from 'react';
import { getIsDesktop, setPlannerDateTitles } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { PlannerApi } from '../../api/PlannerApi';
import CalendarPlanner from './custom_views/CalendarPlanner';
import back_arrow from '../../img/experts/back_arrow.svg'
import other_back from '../../img/other/other_back.svg'
import PlannedPlannerItem from './list_items/PlannedPlannerItem';

function PlannedPlanner(props) {
    const [isLoading, setLoading] = useState(true)
    const [planners, setPlanners] = useState([])
    const [selectedDate, setSelectedDate] = useState(undefined)
    useEffect(() => {
        PlannerApi.getMyPlanner((responseData) => {
            setLoading(false)
            getGroupCalls(responseData.result)
            
        }, (responseData) => {
            setLoading(false)
        })
    }, [])

   
    const getGroupCalls = (planners) => {
        PlannerApi.getMySozvonByLink((responseData) => {
            var result = [...planners]
            result.push(...responseData.result)
            result.sort(function(a,b){
                return moment(a.sozvon_date).utc().local().toDate() - moment(b.sozvon_date).utc().local().toDate();
            })
            console.log("getGroupCalls result = ", result)
            // setPlanners(result)
            setPlanners(setPlannerDateTitles(result))
        }, (responseData) => {

        })
        
    } 
    const history = useHistory()

    const isDesktop = getIsDesktop()
   
    const onBack = () => {
        history.goBack()
    }
    const filteredPlanner = planners.filter((p) => {
        if (selectedDate == undefined){
            return true
        }
        const pDate = moment(p.sozvon_date, "YYYY-MM-DD").set("hour", 0).set("minute", 0).set("second", 0)
        const sDate = moment(selectedDate).set("hour", 0).set("minute", 0).set("second", 0)
        return pDate.dayOfYear() === sDate.dayOfYear() && pDate.year() === sDate.year()
    })
    const getListOfPlanners = () => {
        return(
            <div className="planned_planners_list">
                {filteredPlanner.length > 0 && filteredPlanner.map((planner, index) => (
                    <PlannerItem item={planner} index={index} key={index}/>
                ))}
            </div>
        )
    }

    return (
        <div style={isDesktop ? getDesktopStyle() : {}} className="selected_settings planned_planner_bg">
            <div>
                <div className="planned_planner_header">
                    {isDesktop && <img alt='' style={isDesktop ? {top : '24px'} : {}} onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}
                    <div style={isDesktop ? {justifyContent : 'center', width : '100%'} : {}} className="flex">
                        {!isDesktop &&
                        <div className="arrow_left_theme">
                            <div className="flex">
                                <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>
                                <p className="theme_title"></p>
                            </div>
                        </div>}
                        <p className="planned_planner_title">Запланированное</p>
                    </div>

                    
                    {/* <div className="flex">
                        <img onClick={onSearch} className="planned_planner_header_icon hover" src={search_in_calendar}/>
                        <img onClick={onAdd} className="planned_planner_header_icon hover" src={add_in_calendar}/>
                    </div> */}
                </div>
                <div className="planned_planner_display">
                    {isDesktop && getListOfPlanners()}
                    <div className="planned_planner_calendar">
                        <CalendarPlanner selectedDate={selectedDate} setSelectedDate={setSelectedDate} planners={planners}/>
                    </div>
                    {!isDesktop && getListOfPlanners()}
                </div>
                
                
            </div>
        </div>
        
    );
}

export default PlannedPlanner;

const PlannerItem = ({ item }) => {
   
    return (
        <div>
            {item.dateTitle != undefined && <div className="planned_item_title">{item.dateTitle}</div>}
            <PlannedPlannerItem item={item}/>
        </div>)
}