/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState, useEffect } from 'react';
import { Pages, getIsDesktop, getUserAvatar, declension, getUrlParameter } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import close_chat from '../../img/chat/close_chat.svg'
import { useHistory } from "react-router-dom";
import Loader from '../../custom_views/Loader';
import question from '../../img/planner/question.svg'
import course_videos_count from '../../img/planner/course_videos_count.svg'
import calendar_today from '../../img/planner/calendar_today.svg'
import moment from 'moment';
import { getCookie, isUserAuthed } from '../../utils/CookieHelper';
import planner_close from '../../img/planner/planner_close.svg'
import planner_info_group from '../../img/planner/planner_info_group.svg'
import planner_price from '../../img/planner/planner_price.svg'
import planner_info_copy from '../../img/planner/planner_info_copy.svg'
import planner_info_copy_start from '../../img/planner/planner_info_copy_start.svg'
import { copyText } from '../blog/utils/BlogUtils';
import Toast from '../profile/custom_views/Toast';
import { CSSTransition } from 'react-transition-group';
import AboutMoneyVideoCallDialog from './dialogs/AboutMoneyVideoCallDialog';
import NotAuthedDialog from '../profile/dialogs/NotAuthedDialog';
import PlannedGroupInfoDescription from './custom_views/PlannedGroupInfoDescription';
import GroupCallDescriptionDialog from './dialogs/GroupCallDescriptionDialog';
import AboutMaxVideoCallDialog from './dialogs/AboutMaxVideoCallDialog';
import GroupCalParticipantsDialog from './dialogs/GroupCallParticipantsDialog';
import views from '../../img/profile/views.svg'
import CourseCallItem from './list_items/CourseCallItem';
import CourseCallsDialogs from './dialogs/CourseCallsDialog';
import { PlannerApi } from '../../api/PlannerApi';


function Course(props) {
    const [isLoading, setLoading] = useState(true)
    const [isReadyLoading, setReadyLoading] = useState(false)
    const [course, setCourse] = useState({})
    const [isShowAboutMoney, setShowAboutMoney] = useState(false)
    const [isShowAboutMax, setShowAboutMax] = useState(false)
    const [isShowCourseCalls, setShowCourseCalls] = useState(false)
 
    const [toastText, setToastText] = useState("")
    const [isShowNotAuthDialog, setShowNotAuthDialog] = useState(false)
    const [isShowDescDialog, setShowDescDialog] = useState(false)
    const [isShowParticipantsDialog, setShowParticipantsDialog] = useState(false)
    const id = getUrlParameter('id')
    const getGroupCallInfo = () => {
        PlannerApi.getCourseByLinkById(id, (responseData) => {
            var mCourse = responseData.course
            mCourse.accepted_users = responseData.accepted_users
            setCourse(mCourse)
            setLoading(false)
        }, () => {setLoading(false)})
        
    }
    useEffect(() => {
        getGroupCallInfo()
    }, [])

    const history = useHistory()
    const isDesktop = getIsDesktop()
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.lk
        } else {
            history.goBack()
        }
    }

    var startTime = ""
    var endTime= ""
    var videoCalls = ""
    if (course.sozvon_by_links){
        videoCalls = course.sozvon_by_links.sort((a, b) => moment(a.sozvon_date) - moment(b.sozvon_date))
    }

    if (videoCalls.length > 1){
        startTime = moment(videoCalls[0].sozvon_date).utc().local().format("DD MMM")
        endTime = moment(videoCalls[videoCalls.length - 1].sozvon_date).utc().local().format("DD MMM")
        
    }
   
    const accept = () => {
        if (props.user.count_rub < course.price){
            window.open(Pages.pay + "?pay=1&balance=" + Math.ceil(course.price - props.user.count_rub), '_blank')
            return
        }
        if (!isUserAuthed()){
            setShowNotAuthDialog(true)
            return
        }
        setReadyLoading(true)
        PlannerApi.acceptCourseByLink(id, (responseData) => {
            setReadyLoading(false)
            var myPlanner = {...course}
            var accepted = myPlanner.client_vk_caller_ids_accepted 
            accepted.push(getCookie("id"))
            myPlanner.client_vk_caller_ids_accepted = accepted
            setCourse(myPlanner)
            props.getMyInfo()
            showToast("Курс успешно оплачен")
        }, (responseData) => {
            setReadyLoading(false)
            showToast(responseData.message)
        })
    }

  
     const getDescriptionHTML = () => {
        if (course.course_description == undefined || course.course_description == ""){
            return null
        }
        return(
            <PlannedGroupInfoDescription onOpen={() => setShowDescDialog(true)} text={course.course_description}/>
        )
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const copyTextClick =() => {
        const link = window.location.origin + Pages.course + "?id=" + course._id
        showToast("Ссылка скопирована")
        copyText(link)
    }
  
    const is_i_creator = course.creator ? course.creator.vk_caller_id == getCookie("id") : false
    const is_accepted = (!is_i_creator && course.client_vk_caller_ids_accepted && course.client_vk_caller_ids_accepted.includes(getCookie("id")))
    return (
        <div style={isDesktop ? getDesktopStyle() : {position : 'block', zIndex : '2', background : '#F1F5FD'}} className="selected_settings create_planner_bg">
            
            <div className="create_ads_step">
                <div className="create_planner_toolbar">
                        <p className="theme_title planner_title">{isDesktop ? course.course_name : ""}</p>
                        {!isDesktop ? 
                            <div onClick={onBack} className="planner_close_cont hover">
                                <img alt="" className="planner_close_img hover" src={close_chat}/>
                            </div>
                            : 
                            <img alt="" onClick={onBack}  className="planner_close_desktop hover" src={planner_close}/>
                        }

                </div>
           
                {(isLoading || course._id == undefined) ? <Loader /> : 
                <div>
                    <div className="planned_picked_planner_cont">
                        {!isDesktop && <div className="planned_info_title">{course.course_name}</div>}
                        {getDescriptionHTML()}
                        <a rel="noreferrer" href={Pages.users + "/id" + course.creator._id} target="_blank"><div className="flex hover">
                            <img alt="" src={getUserAvatar(course.creator)} className="planned_item_avatar planned_picked_planner_avat"/>
                            <div>
                                <div className="planned_picked_planner_name">{course.creator.name}</div>
                                <div className="planned_picked_planner_client">{"Эксперт"}</div>
                            </div>
                        </div></a>
                        <div onClick={copyTextClick} style={{width : '100%'}} className="planner_video_just_cont hover">
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img" src={planner_info_copy_start}/>
                                <div className="planned_picked_planner_time planner_text_overflow">{window.location.origin + Pages.course + "?id=" + course._id}</div>
                            </div>
                            <img alt="" className="planner_info_copy_icon" src={planner_info_copy}/>
                        </div>
                        <div>
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img" src={course_videos_count}/>
                                <div className="planned_picked_planner_time planner_opacity">Видеовстречи: {course.sozvon_by_links.length}</div>
                            </div>
                            <div className='planner_course_calls'>
                                {course.sozvon_by_links.length > 0 && course.sozvon_by_links.map((item, index) => (
                                    <CourseCallItem item={item} index={index} key={index}/>
                                ))}
                                {course.sozvon_by_links.length > 4 && <div onClick={() => setShowCourseCalls(true)} className='planner_course_show_all hover'>Показать все</div>}
                            </div>
                            <div className="flex">
                                <img alt="" className="planned_picked_planner_img planner_opacity" src={calendar_today}/>
                                <div className="planned_picked_planner_time planner_opacity">{startTime} — {endTime}</div>
                            </div>
                            <div className='planner_video_just_cont'>
                                <div className="flex">
                                    <img alt="" className="planned_picked_planner_img" src={planner_price}/>
                                    <div className="planned_picked_planner_time"><span className='planner_course_price'>{course.price}₽/чел</span><span style={{opacity: 0.5, marginLeft : '8px'}}>{(course.price ? course.price : 0) * course.client_vk_caller_ids_accepted.length}₽</span></div>
                                </div>
                                {is_i_creator && <img onClick={() => setShowAboutMoney(true)} alt="" className='planner_video_question hover' src={question}/>}
                            </div>
                            {course.client_vk_caller_ids_accepted.length > 0 && <div className='planner_video_just_cont'>
                                <div className="flex">
                                    <img alt="" className="planned_picked_planner_img" src={planner_info_group}/>
                                    <div className="planned_picked_planner_time"><span onClick={course.client_vk_caller_ids_accepted.length > 0 ? () => setShowParticipantsDialog(true) : undefined} className={course.client_vk_caller_ids_accepted.length > 0 ? 'hover planned_praticipants' : 'planner_opacity'}>{course.client_vk_caller_ids_accepted.length + " " + declension(course.client_vk_caller_ids_accepted.length, "человек", "человека", "человек")}</span></div>
                                </div>
                                {/* <img onClick={() => setShowAboutMax(true)} alt="" className='planner_video_question hover' src={question}/> */}
                            </div>}
                           
                            
                        </div>
                     
                        <div>
                          
                            {(!is_i_creator && !is_accepted) && <div onClick={accept} className="chat_planner_accept chat_lowercased hover">
                                {isReadyLoading ? <Loader isWhite/> : course.price == 0 ? "Принять участие" : "Оплатить участие"}
                            </div>}
                            {/* {(course.client_vk_caller_ids_accepted.length >= course.max_count_persons && !is_accepted && !is_i_creator) && <div className='planner_group_info_full'>Все места на встречу заняты</div>} */}
                            <div onClick={copyTextClick} className="planner_share_video_btn hover">Поделиться ссылкой на пакет встреч</div>
                            {/* {(is_i_creator || is_accepted) && <div onClick={() => is_i_creator ? setShowCancel(true) : onCancel()} className="planned_picked_planner_cancel hover">{is_i_creator ? "Отменить видеовстречу" : "Отменить участие"}</div>} */}
                            {!is_i_creator && <div className="planner_video_confirm_desc">Для подтверждения участия в пакете видеовстреч необходимо внести полную стоимость услуги.</div>}
                        </div>
                        <div style={{margin : '16px auto 0 auto', justifyContent : 'center'}} className="flex relative">
                            <img alt='' className="profile_views_img" src={views}/>
                            <p className="profile_views_text">{course.views}</p>
                        </div>
                    </div>
                    <div onClick={() => window.open(Pages.planner_rules, '_blank')} style={{textAlign : 'center', marginTop : '24px', marginBottom : '64px'}} className="create_planner_how_it_works hover">Как это работает?</div>                    
                </div>}
                
            </div>
            <GroupCallDescriptionDialog text={course.course_description} name={course.course_name} setShow={setShowDescDialog} isShow={isShowDescDialog}/>
            <CourseCallsDialogs course={course} setShow={setShowCourseCalls} isShow={isShowCourseCalls}/>
            <NotAuthedDialog setShow={setShowNotAuthDialog} isShow={isShowNotAuthDialog}/>
            <AboutMoneyVideoCallDialog setShow={setShowAboutMoney} isShow={isShowAboutMoney}/>
            <AboutMaxVideoCallDialog setShow={setShowAboutMax} isShow={isShowAboutMax}/>
            {/* <CancelVideoCallDialog onPlannerCanceled={onPlannerCanceled} onSuccess={onPlannerCanceled} planner={course} isShow={isShowCancel} setShow={setShowCancel}/> */}
            <GroupCalParticipantsDialog participants={course.accepted_users} isShow={isShowParticipantsDialog} setShow={setShowParticipantsDialog}/>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
        
    );
}

export default Course;
