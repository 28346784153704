import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class ExpressSearchApi {
    
    static createExpressSearch(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createExpressSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static declineExpressSearch(decline_vk_caller_id, onSuccess, onError) {
        var params = {decline_vk_caller_id : decline_vk_caller_id}
        fetch(getRequestUrl() + "declineExpressSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("declineExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static acceptExpressSearch(expert_vk_caller_id, onSuccess, onError) {
        var params = {expert_vk_caller_id : expert_vk_caller_id}
        fetch(getRequestUrl() + "acceptExpressSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("acceptExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static respondExpressSearch(owner_vk_caller_id, onSuccess, onError) {
        var params = {owner_vk_caller_id : owner_vk_caller_id}
        fetch(getRequestUrl() + "respondExpressSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("respondExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getExpressSearch(vk_caller_id, onSuccess, onError) {
        var params = {vk_caller_id : vk_caller_id}
        fetch(getRequestUrl() + "getExpressSearch", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static declineAllExpressSearch(onSuccess, onError) {
        fetch(getRequestUrl() + "declineAllExpressSearch", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            log("declineAllExpressSearch response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
   
}