import { useState } from 'react';
import ProfileInputRaw from './custom_views/ProfileInputRaw';

function Step4(props) {
    const [priceHour, setPriceHour] = useState(props.profileInfo.rub_in_hour ? props.profileInfo.rub_in_hour : 500)
    const [freeTime, setFreeTime] = useState(props.profileInfo.free_time ? props.profileInfo.free_time : 5)
    const isEnableButton = priceHour >= props.config.minimum_call_price && freeTime >= 5
    const isPriceError = priceHour == "" ? false : priceHour < props.config.minimum_call_price
    const isTimeError = freeTime == "" ? false : freeTime < 5
    return (
        <div>
           <p className="profile_fill_start_title title_step">Стоимость аудио/<br/>видеозвонка</p>
           <p className="profile_fill_start_desc text_with_bottom_input">Звонки в нашем сервисе автоматически тарифицируются. Т.е. вы получаете деньги за каждую минуту разговора</p>
           <ProfileInputRaw inputDesc="₽" title="Стоимость одного часа" value={priceHour} setValue={setPriceHour}/>
           <p style={isPriceError ? {color : 'var(--red)', opacity : 1} : {}} className="profile_input_title profile_input_title_l">{isPriceError ? "Минимальная цена - " + props.config.minimum_call_price : "За вычетом комиссии сервиса получится " + parseFloat(priceHour*(1-props.config.comission)).toFixed(2) + "₽"}</p>
           <ProfileInputRaw inputDesc="минут" title="Бесплатное время" value={freeTime} setValue={setFreeTime}/>
           <p className="profile_input_title profile_input_title_l">Это то количество времени, которое вы готовы консультировать бесплатно. Оно может быть вашим конкурентным преимуществом. Если ваш первый созвон с клиентом был меньше указанного тут значения, то остаток бесплатных минут перенесётся на последующие созвоны.</p>

           {isTimeError && <p style={{color : 'var(--red)', opacity : 1}} className="profile_input_title profile_input_title_l">Минимальное бесплатное время - 5 минут</p>}
           <button style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={() => props.saveStep4(priceHour, freeTime)} className="fill_button hover profile_bottom_button">Далее</button>
        </div>
    );
}

export default Step4;
