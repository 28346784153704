import experts_info_1 from '../../../img/experts/experts_info_1.png'
import experts_info_2 from '../../../img/experts/experts_info_2.png'
import experts_info_3 from '../../../img/experts/experts_info_3.png'
import experts_info_4 from '../../../img/experts/experts_info_4.png'
import { getIsDesktop } from '../../../utils/Utils';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import React, {useCallback} from 'react';

function ExpertsInfo(props) {
    const items = [{id : 0, title : "Помощь эксперта начинающему специалисту", text : "Новички сталкиваются со сложностями на каждом шагу. Вместе с экспертом решить задачу получится в разы быстрее.", img : experts_info_1},
    {id : 1,title : "Подготовка к экзаменам", text : "Найди репетитора по нужному предмету и грызи гранит науки с удовольствием!", img : experts_info_2},
    {id : 2,title : "Платные совещания", text : "Уходит много времени на общение с клиентами? Чтобы монетизировать это время, звонки и переписку перенеси в сервис sozvon.pro. Установи стоимость переписки и звонков. Время — деньги.", img : experts_info_3},
    {id : 3,title : "Поговори со звездой", text : "Хочешь пообщаться вживую с блогером, которого давно смотришь? Найди его через поиск и позвони по видеосвязи. Сделай скриншот и хвастайся в соцсетях, что поговорил со знаменитостью!", img : experts_info_4}]
    return (
        <div className="popular_horizontal_cont">
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {items.map((item, index) => (
                    <ExpertsInfoItem item={item} index={index} setDialogInfo={props.setDialogInfo} key={index} itemId={item.id}/>
                ))}
            </ScrollMenu>
        </div>
    );
}

export default ExpertsInfo;

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_scroll_popular hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_scroll_popular experts_arrow_right hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}
const ExpertsInfoItem = ({ item , index, setDialogInfo, itemId}) => {
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);

    const isDesktop = getIsDesktop()
    const getBackground = (index) => {
        switch (index) {
            case 0:
                return {background : "#FFDF7F", marginLeft : isDesktop ? '52px' : '16px'}
                case 1:
                return {background : "#C5AEFF"}
                case 2:
                return {background : "#56CCF2"}
                case 3:
                return {background : "#77EBCF"}
            
            default:
                return
        }
    }
    const bgColor = getBackground(index)
    const openInfo = () => {
        if (!isDesktop){
            var info = item
            info.bgColor = bgColor.background
            info.isShow = true
            setDialogInfo(info)
        }
    }
    return (
        <div onClick={openInfo} style={bgColor} className="popular_experts_item">
            <p className="experts_info_title">{item.title}</p>
            <div className="flex">
                <p className="experts_popular_info_text">{item.text}</p>
                <img className="experts_popular_info_icon" src={item.img}/>
            </div>
        </div>
    )
}
{/* <div>
            <p className="experts_title_section">Популярное</p>
            <div className="experts_horizontal_contaner">
                <div className="popular_experts_item hover">
                    <p className="popular_experts_title">Поговори<br/>со звездой</p>
                    <img className="popular_experts_icon" src={popular_experts_1}/>
                </div>
                <div className="popular_experts_item hover">
                    <p className="popular_experts_title">Подготовка<br/>к экзаменам</p>
                    <img className="popular_experts_icon" src={popular_experts_1}/>
                </div>
                
            </div>
            
        </div> */}