/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import back_arrow from '../../../img/experts/back_arrow.svg'
import not_found_experts from '../../../img/experts/not_found_experts.svg'
import { useState, useEffect } from 'react';
import SearchExperts from './SearchExperts';
import Loader from '../../../custom_views/Loader';
import { getRequestUrl, getHttpParams, getUrlParameter, getIsDesktop, Pages } from '../../../utils/Utils';
import { getRequestParams } from '../../../utils/CookieHelper';
import ExpertItem from '../list_items/ExpertItem';
import { useHistory, Link } from "react-router-dom";
import other_back from '../../../img/blogs/blogs_back.svg'
import express_search from '../../../img/experts/express_search.png'
import create_ads from '../../../img/experts/create_asd.png'
import { PlannerApi } from '../../../api/PlannerApi';
import ProfileGroupCalls from '../../profile/custom_views/ProfileGroupCalls';
import ExpertsAdvatages from './ExpertsAdvantages';
import { convertLinkToSeoTags, getAggregateRating, getCityAndMetro, SeoTagName, setSeoMetaTags } from '../utils/ExpertsUtils';
import { SeoApi } from '../../../api/SeoApi';
import SeoRatings from './SeoRatings';
import SeoHeader from './SeoHeader';
import { ConfigApi } from '../../../api/ConfigApi';
var isSeoLoading = false
var status_scroll_event = 0;
function FindExperts(props) {
    const [searchText, setSearchText] = useState("")
    const [searchTextH1, setSearchTextH1] = useState("")
    const [experts, setExperts] = useState([])
    const [groupCalls, setGroupCalls] = useState([])
    const [seoTags, setSeoTags] = useState([])
    const [footerKeywords, setFooterKeywords] = useState([])
    const [aggregateRating, setAggregateRating] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [scrollTop, setScrollTop] = useState(0)
    const [currentSeoTagSelected, setCurrentSeoTagSelected] = useState("a")
    const isSeo = props.isSeo
    useEffect(() => {
        const text = getUrlParameter("text")
        if (text != null && text != undefined && text != ""){
            setSearchText(text)
            searchExpert(text)
            searchGroupCall(text)
        }
    }, [searchText])
    useEffect(() => {
        loadByUrlTags()
    }, [])
    useEffect(() => {
        if (!isSeoLoading){
            loadByUrlTags()
        }
    }, [currentSeoTagSelected])
    const loadByUrlTags = () => {
        console.log("loadByUrlTags 1")
        if (isSeo){
            isSeoLoading = true
            const tags = convertLinkToSeoTags()
            const spec = tags.get(SeoTagName.specialization)
            setSeoTags(tags)
            searchExpert(spec == "" ? "Математика" : spec, true)
            getSeoKeywordsByFeatures(tags)
        }
    }
    const searchGroupCall = (text) => {
        PlannerApi.getSozvonByLinkForSearch(text, (responseData) => {
            if (responseData.response > 0){
                setGroupCalls(responseData.result)
            }
        }, () => {})
    }
    const getSeoKeywordsByFeatures = (tags) => {
        SeoApi.getSeoKeywordsByFeatures(tags, (responseData) => {
            if (responseData.result){
                const ratings = getAggregateRating()
                setSearchTextH1(responseData.result.full_query)
                setSeoMetaTags(responseData.result, ratings)
                setAggregateRating(ratings)
            }
        })
        getRandomSeoKeywords(tags)
    }
    const getRandomSeoKeywords = (tags) => {
        SeoApi.getRandomSeoKeywords(tags, (responseData) => {
            if (responseData.result){
                setFooterKeywords(responseData.result)
            }
        })
    }
    const searchExpert = (text, isDisabledH1) => {
        if (!isDisabledH1){
            setSearchText(text)
        }
       
        if (text == ""){
            setExperts([])
            return
        }
        setLoading(true)
        ConfigApi.searchExperts(text, (responseData) => {
            if (!isDisabledH1){
                setSearchTextH1(text)
            }
            
            if (responseData.response === 1){
                setExperts(responseData.results)
                setTimeout(function() {
                    const search_scroll_top = localStorage.getItem("search_scroll_top")
                    if (search_scroll_top && !isDesktop){
                        console.log("search_scroll_top = ", search_scroll_top)
                        document.getElementsByClassName("selected_theme")[0].scrollTo(0, search_scroll_top)
                        localStorage.setItem("search_scroll_top", "")
                    }
                }, 200)
               
            }
            setLoading(false)
        }, () => {setLoading(false)})
        // var params = getRequestParams()
        // params.text = text
        // fetch(getRequestUrl() + "searchExperts", getHttpParams(params)).then((response) => response.json())
        // .then((responseData) => {
        //     console.log("searchExperts = ", responseData)
        //     if (!isDisabledH1){
        //         setSearchTextH1(text)
        //     }
            
        //     if (responseData.response === 1){
        //         setExperts(responseData.results)
        //         setTimeout(function() {
        //             const search_scroll_top = localStorage.getItem("search_scroll_top")
        //             if (search_scroll_top && !isDesktop){
        //                 console.log("search_scroll_top = ", search_scroll_top)
        //                 document.getElementsByClassName("selected_theme")[0].scrollTo(0, search_scroll_top)
        //                 localStorage.setItem("search_scroll_top", "")
        //             }
        //         }, 200)
               
        //     }
        //     setLoading(false)
        // })
    }
    
    const history = useHistory()
    const onBack = () => {
        history.push(Pages.experts)
    }
    const getToolbar = () => {
        return(
            <div className="flex">
                <div className="arrow_left_theme">
                    {isDesktop ? <img alt="" style={{marginBottom : '16px', marginLeft : '0px'}} onClick={onBack} className="ads_back_desktop hover" src={other_back}/>  
                    :
                    <img alt="" onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>}
                </div>
                <h1 className="ads_top_title">{searchTextH1 == "" ? "Поиск экспертов" : searchTextH1}</h1>
                {/* {isDesktop && <p className="expeerts_search_title">Эксперты</p>} */}
            </div>
        )
    }
    const onSpecClicked = (spec) => {
        // window.history.replaceState(null, null, Pages.search + '?text=' + spec)
        searchExpert(spec)
    }
    const handleScroll = event => {
        const scrollY = event.currentTarget.scrollTop
        if (!isDesktop){
            setScrollTop(scrollY)
        }
        
        if (window.location.hostname != 'localhost' && window.ym){
            if (scrollY > 500 && status_scroll_event < 1) {
                window.ym(94527020, 'reachGoal', 'scroll500')
                status_scroll_event = 1
                console.log("ym search reachGoal 1")
            }
            if (scrollY > 1000 && status_scroll_event < 2) {
                window.ym(94527020, 'reachGoal', 'scroll1000')
                status_scroll_event = 2
                console.log("ym search reachGoal 2")
            }
            if (scrollY > 2000 && status_scroll_event < 3) {
                window.ym(94527020, 'reachGoal', 'scroll2000')
                status_scroll_event = 3
                console.log("ym search reachGoal 3")
            }
        }
    }
    const isDesktop = getIsDesktop()
    const expertsSeoTags = isSeo ? getCityAndMetro(seoTags) : []
    return (
        <div onScroll={handleScroll} className={isDesktop ? "selected_profile_container_desktop" : "selected_theme"}>
            {getToolbar()}
         
            {isLoading ? <Loader /> : 
                <div style={(experts.length == 0 || !isDesktop) ? {display : 'block'} : {}} className="flex">
                    <div className="exprets_in_theme">
                        <SearchExperts isDisabledOpen style={isDesktop ? {margin : '0 12px 12px 16px'} : {}} setShowBookmarks={props.setShowBookmarks} isFocused={true} text={searchText} setText={searchExpert}/>
                        <SeoHeader isSeo={isSeo} seoTags={seoTags} setCurrentSeoTagSelected={setCurrentSeoTagSelected}/>
                        <ExpertsAdvatages style={isDesktop ? {width : 'calc(100% - 28px)', margin :'0 12px 16px 16px', height : '56px'} : {margin : '0 0 8px 0', width : '100%', height : '64px', overflowX : 'scroll'}} />
                        {groupCalls.length > 0 && <div>
                            <ProfileGroupCalls fromSearch planners={groupCalls}/>
                        </div>}
                        {(experts.length > 0) && experts.map((expert, index) => (
                            <ExpertItem seoTags={expertsSeoTags} scrollTop={scrollTop} myUser={props.user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} user={expert}/>
                        ))}
                        <SeoRatings isSeo={isSeo} experts={experts} aggregateRating={aggregateRating}/>
                        {(experts.length == 0 && searchText != "") && 
                            <div className="not_found_experts_cont">
                                <img alt="" className="not_found_experts" src={not_found_experts}/>
                                <div onClick={() => history.push(Pages.create_ads)} style={{width : 'fit-content', margin : '30px auto', padding : '14px 44px'}}  className="call_price_continue hover">Создать объявление</div>
                            </div>
                        }
                            
                        {searchText == "" && 
                            <div className="specs_search">
                                {props.specializations.map((spec, index) => (
                                    <Link action="replace" to={Pages.search + '?text=' + spec} key={index}>
                                        <div onClick={() => onSpecClicked(spec)} className="spec_search_text hover">
                                            <p className="subject_list_item">{spec}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        }                        
                    </div>
                    {(isDesktop && experts.length != 0) && 
                    <div className="experts_create_ads_cont_desc">
                        <div style={{marginBottom : '5px'}} className="experts_create_ads">
                            <Link to={Pages.create_ads}><img alt="" className="experts_create_ads hover" src={create_ads}/></Link>
                        </div>
                        <div className="experts_create_ads">
                            <Link to={Pages.express_search}><img alt="" className="experts_create_ads hover" src={express_search}/></Link>
                        </div>
                    </div>}
                </div>
            }
            {footerKeywords.length > 0 && 
                <div className='experts_seo_tags'>
                    {footerKeywords.map((item, index) => (
                        <div className='flex' index={index} key={index}>
                            <a href={item.url} target='_blank' rel="noreferrer" className='hover'><div>{item.full_query}</div></a>
                            <div className='experts_seo_tags_line'>{"|"}</div>
                        </div>
                    ))}
                    
                </div>
            }
        </div>
    )
}

export default FindExperts;
