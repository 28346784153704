import { useHistory } from "react-router-dom";
import { getMyLevels, getAges } from './helpers/CreateAdsHelper';
import CreateAdsRadioStep from './custom_views/CreateAdsRadioStep';
import { getUrlParameter } from "../../../utils/Utils";
import { useEffect } from 'react';
import { AdsApi } from "../../../api/AdsApi";

function CreateAdsStep5(props) {
    const history = useHistory()
    const id = getUrlParameter("id")
    const info = {
        progress : props.progress,
        pickedSpecs : props.pickedSpecs,
        header : "Возраст клиента",
        value : props.value,
        radioItems : getAges()
    }
    useEffect(() => {
        const value = getUrlParameter("value")
        if (value != null && value != undefined && value != ""){
            props.setValue(getAges()[value])
        }
    }, [])
    const onNext = () => {
        if (props.isEdit){
            AdsApi.editRequestRepetitor({repetitor_request_id : id, age : getAges().indexOf(props.value)}, (response) => {
                window.location.href = '/ads_board/ads?id=' + id + "&is_details=true"
            })
           
        } else {
            history.push("/create_ads/step_6")
        }
    }
    return (
        <CreateAdsRadioStep isEdit={props.isEdit} info={info} onNext={onNext} setValue={props.setValue}/>
    );
}

export default CreateAdsStep5;
