import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useHistory } from "react-router-dom";
import { getCookie } from '../../../utils/CookieHelper';


function ProfileInfoFreeTimeDialog(props) {
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const user = props.user
    const isMyProfile = user.vk_caller_id == getCookie("id")
    const isExpressSearch = props.isExpressSearch != undefined && props.isExpressSearch
    const cancel = () => {
        props.setShow(false)
    }
    const openChat = () => {
        props.setShow(false)
        if (!isMyProfile){
            history.push("/chats/chat?id=" + user.vk_caller_id)
        }
    }

    const getInnerHtml = () => {
        return(
            <div style={ isDesktop ? {maxWidth : '460px'} : {}} className="call_info_container">
                <p style={{color : 'black'}} className="rules_title">Бесплатное время</p>
                <p className="rules_text">Бесплатное время относится только к первому звонку. Если за первый звонок время не было исчерпано, то оставшиеся минуты переходят на следующие звонки.</p> 
                {isExpressSearch ? 
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{marginLeft : 0}} onClick={() => props.setShow(false)} className="call_price_continue hover">{"Понятно"}</div>
                </div>
                :
                <div style={{marginTop : '36px'}} className="flex">
                    {!isMyProfile && <div onClick={cancel} className="call_prices_edit hover">Отмена</div>}
                    <div style={isMyProfile ? {} : {marginLeft : 0}} onClick={openChat} className="call_price_continue hover">{isMyProfile ? "Понятно" : "Написать"}</div>
                </div>}
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow}  getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ProfileInfoFreeTimeDialog;
