/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import '../../styles/messages.css'
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { VariableSizeList as List  } from "react-window";
import ChatNavbar from './custom_views/ChatNavbar';
import WriteBar from './custom_views/WriteBar';
import { setDateTitles, getIsDesktop, getUnreadCountMsgs, debounce, filterByDate, filterDuplicatesMsgs } from '../../utils/Utils';
import { useWindowResize } from '../../utils/UserWindowResize';
import RowMessageItem from './list_items/RowMessageItem';
import Loader from '../../custom_views/Loader';
import RulesBottomSheet from './dialogs/RulesBottomSheet';
import CallPricesBottomSheet from '../call/dialogs/CallPricesBottomSheet';
import { DialogsApi } from '../../api/DialogsApi';
import { getCookie, setCookie } from '../../utils/CookieHelper';
import { CSSTransition } from 'react-transition-group';
import ChatToolbarTooltip from './custom_views/ChatToolbarTooltip';
import ChangeRole from '../call/dialogs/ChangeRole';
import MsgContextMenu from './dialogs/MsgContextMenu';
import { setAllReadedMsgs, changeMsgText, updateMsg_Id, getReplyMsgIndex, is_web_view, useWindowSize, getGroupCallChatStyle, hasIncomingMessages } from './custom_views/ChatUtils';
import ChatToast from './custom_views/ChatToast';
import ScrollToBottomArrow from './custom_views/ScrollToBottomArrow';
import PaidChatDialog from './dialogs/PaidChatDialog';
import DeclinePlannerDialog from './dialogs/DeclinePlannerDialog';
import ErrorCreationDialog from '../planner/dialogs/ErrorCreationDialog';
import TgNotifDialog from '../profile_filling/dialogs/TgNotifDialog';
import TgAuthInformation from '../other/custom_views/TgAuthInformation';
import AttachDeskDialog from './dialogs/AttachDeskDialog';
import { createNewMessage } from './utils/FilesUploader';
import ContactsDialog from '../ads_desk/AdsBoard/dialogs/ContactsDialog';
var groupCallMsgs = [{id : -100}]
var groupCallChats = [{id : -100}]
var groupCallMsgsIds = []
function Chat(props) {
    const [data, setMsgs] = useState([])
    const [isListScrolled, setListScrolled] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isOpenRules, setOpenRules] = useState(false)
    const [windowHeight, setWindowHeight] = useState(0)
    const [isShowCallBottomSheet, setShowCallBottomSheet] = useState(false)
    const [canWriteReview, setCanWriteReview] = useState(false)
    const [isIRepetitor, setIRepetitor] = useState(false)
    const [checkRepetitorResult, setCheckRepetitor] = useState(undefined)
    const [checkRepetitorInfo, setCheckRepetitorInfo] = useState({})
    const [isIBlocked, setIBlocked] = useState(false)
    const [isInMyBlock, setInMyBlock] = useState(false)
    const [isShowToolbarTooltip, setShowToolbarTooltip] = useState(false)
    const [isShowPaidChatDialog, setShowPaidChatDialog] = useState(false)
    const [isShowChangeRole, setShowChangeRole] = useState(false)
    const [toastText, setToastText] = useState("")
    const [contextMenuMsg, setContextMenuMsg] = useState({})
    const [editableMsg, setEditableMsg] = useState({})
    const [replyMsg, setReplyMsg] = useState({})
    const [isFirstLoad, setFirstLoad] = useState(true)
    const [currentListIndex, setCurrentListIndex] = useState(0)
    const [declinePlanner, setDeclinePlanner] = useState({isShow : false, msg : {}})
    const [errorAcceptPlanner, setErrorAcceptPlanner] = useState("")
    const [isShowAttachDesk, setShowAttachDesk] = useState(false)

    const [isFileUploading, setFileUploading] = useState(false)

    const [isShowTgNotifDialog, setShowTgNotifDialog] = useState(false)
    const [isShowOpenContacts, setShowOpenContacts] = useState(false)

    const [isShowTgAuth, setShowTgAuth] = useState(false)
    const [isWebView, setWebView] = useState(is_web_view())
    const isChatsLoading = props.isChatsLoading
    const is_i_creator = props.is_i_creator
    const [width, height] = useWindowSize()
    const setServicesValueDebounced = useRef(debounce((index, data) => {
        setCurrentListIndex(index)
    }, 200))
  
    const scrollToWindowBottom = useRef(debounce(() => {
        const elememts = window.document.getElementsByClassName("chat_cont_scroll")
        if (elememts.length > 0){
            const elem = elememts[0]
            elem.scrollTo(0, elem.scrollHeight)
        }
    }, 200))
    var itemIndex = 0
    const config = props.config
    const user = props.user
    const fromCall = props.fromCall
    const isGroupCall = props.isGroupCall
    const plannerInfo = props.planner
    const videoStreams = props.videoStreams
    const isDesktop = getIsDesktop()
    const currentChat = props.currentChat
    const socketMsg = props.socketMessage
    const setMessages = (array, from) => {
        setMsgs(array)
    }
    const changeMsg = (newMsg) => {
        const myMsgs = [...data]
        for (let index = 0; index < myMsgs.length; index++) {
            const element = myMsgs[index]
            if (element._id == newMsg._id){
                myMsgs[index] = newMsg
            }
        }
        setMsgs(myMsgs)
    }
    const getGroupCallDialogs = (index, msgs) => {
       
       
        if (videoStreams.length > 0 && videoStreams.length > index && videoStreams[index] && videoStreams[index].user){
            setLoading(true)
            const user_id = videoStreams[index].user.id
            console.log("getGroupCallDialogs videoStreams[index].user.id = ", user_id)
            DialogsApi.getDialog(user_id, (responseData) => {
                
                groupCallChats.push({dialog : responseData, to_id : user_id})

                console.log("getGroupCallDialogs grpCallChats = ", groupCallChats)
                var myMsgs = [...msgs]
                console.log("getGroupCallDialogs getDialog 1 = ", responseData)
                myMsgs.push(...filterByDate(responseData.messages.filter(m => m.sozvon_by_link == undefined), plannerInfo.sozvon_date))
                console.log("getGroupCallDialogs msgs 1 = ", myMsgs)
                var i = index + 1
                if (i < videoStreams.length){
                    getGroupCallDialogs(i, myMsgs)
                } else {
                    var responseMessages = myMsgs.sort((a,b) => a.unixtime - b.unixtime)
                    responseMessages = setDateTitles(myMsgs)
                    //need test
                    responseMessages = filterDuplicatesMsgs(responseMessages)
                    
                    //
                    
                    responseMessages.push({id : -999})
                    
                    setMessages(responseMessages, 1)
                    setLoading(false)
                }
                setReadGroupDialog(responseData.messages)
            })
        }
    }
    const getDialog = () => {
        setLoading(true)
        DialogsApi.getDialog(currentChat.user.vk_caller_id, (responseData) => {
            console.log("getDialog responseMessages = ", responseData.messages)
            var responseMessages = setDateTitles(responseData.messages)
            if (isGroupCall){
                responseMessages = filterByDate(responseMessages, plannerInfo.sozvon_date)
            } else {
                responseMessages.splice(0, 0, {id : -99})
            }
            if (user.is_repetitor && !hasIncomingMessages(responseMessages)){
                responseMessages.push({id : -888})
            }
            
            responseMessages.push({id : -999})
            setMessages(responseMessages, 1)
            props.setBalance(responseData.count_rub)
            
        })
        setReadDialog()        
    }
    const checkRepetitor = () => {
        if (currentChat == undefined){
            return
        }
        DialogsApi.checkRepetitor(currentChat.user.vk_caller_id, (responseData) => {
            const is_i_repetitor = responseData.id_student == currentChat.user.vk_caller_id
            setIRepetitor(is_i_repetitor)
            const myUser = is_i_repetitor ? responseData.userRepetitor : responseData.userStudent
            setIBlocked(currentChat.user.block_users && currentChat.user.block_users.includes(getCookie("id")))
            setInMyBlock(myUser.block_users.includes(String(currentChat.user.vk_caller_id)))
            setCheckRepetitor(responseData.userRepetitor)
            setCheckRepetitorInfo(responseData)
            setWindowHeight(window.innerHeight - (props.fromCall ? (isDesktop ? (isGroupCall ? 106 : 126) : (isGroupCall ? 132 : 122)) : is_i_repetitor ? (isDesktop ? 196 : 162) : (isDesktop ? 156 : 122)))
        })
    }

    const setReadGroupDialog = (messages) => {
        if (messages.length > 0){
            DialogsApi.setReadDialog(messages[0].dialog_id, (responseData) => {})
        }
    }
    const setReadDialog = () => {
        var chats = props.dialogs.filter(d => d.dialog_id == currentChat.dialog_id)
        if (getUnreadCountMsgs(chats[0]) > 0){
            if (props.clearBadge != undefined && chats != undefined && chats.length > 0){            
                props.clearBadge(generateUnreadedMsgs(chats[0]))
            } else if (props.removeUnreadedMsgs && chats != undefined && chats.length > 0){
                var countRemoveMsgs = getUnreadCountMsgs(generateUnreadedMsgs(chats[0]))
                props.removeUnreadedMsgs(countRemoveMsgs)
            }
        }
        console.log("setReadDialog")
        DialogsApi.setReadDialog(currentChat.dialog_id, () => {
            console.log("setReadDialog response")
            if (chats != undefined && chats.length > 0){
                // setTimeout(function() {
                //     props.clearBadge(generateUnreadedMsgs(chats[0]))
                // }, 1000)
                props.clearBadge(generateUnreadedMsgs(chats[0]))
            }
        })
        
    }

    
    const generateUnreadedMsgs = (chat) => {
        var c = chat
        if (c.dialog.count_no_read_messages == 0){
            c.dialog.count_no_read_messages = 1
        } 
        if (c.dialog.count_no_read_messages2 == 0){
            c.dialog.count_no_read_messages2 = 1
        }
        return c
    }
    const chatChangeHook = (isGroupCall ? props.isShowCallChat : currentChat)
    useEffect(() => {
        if (user != undefined && currentChat != undefined){
            setFirstLoad(true)
            setListScrolled(false)
            setMsgs([])
            loadDialogs()
            checkRepetitor()
        } 
    }, [chatChangeHook])
   
    useEffect(() => {
       if (currentListIndex == (data.length - 1) && data.filter(d => d.from_id != undefined && d.from_id != getCookie("id") && !d.is_read).length > 0){
            setMessages(setAllReadedMsgs(data))
       }
    }, [currentListIndex])
    

    useEffect(() => {
        if (windowHeight == 0){
            setWindowHeight(window.innerHeight - 188)
        }
    }, [windowHeight])

    useEffect(() => {
        if (props.isRestoredRecconnect && currentChat != undefined){
            setFirstLoad(true)
            setListScrolled(false)
            setMsgs([])
            loadDialogs()
            
            console.log("props.isRestoredRecconnect = ", props.isRestoredRecconnect)
        }
    }, [props.isRestoredRecconnect])
    const loadDialogs = () => {
        if (isGroupCall){
            getGroupCallDialogs(0, groupCallMsgs)
        } else {
            getDialog()
        }
    }
    useEffect(() => {
        scrollToWindowBottom.current()
    }, [height])
    
    useEffect(() => {
        const isOpenedChatMessage = currentChat != undefined && ((currentChat.dialog.dialog_id == socketMsg.dialog_id) || groupCallChats.filter(d => d.to_id + "_" + getCookie("id") == socketMsg.dialog_id || getCookie("id") + "_" + d.to_id == socketMsg.dialog_id).length > 0)
        if (socketMsg != undefined && socketMsg.event === "message" && isOpenedChatMessage){
            var last10msgs = data
            if (data.length > 10){
                last10msgs = data.slice(data.length - 10)
            }
            const filteredData = last10msgs.filter(msg => msg.text == socketMsg.text)
            if (socketMsg.from_id != getCookie("id") || filteredData.length == 0 || (socketMsg.attachments_urls && socketMsg.attachments_urls.length > 0)){
                console.log("socket msg groupCallMsgsIds 1 = ", groupCallMsgsIds)
                if (isGroupCall && socketMsg.from_id == getCookie("id")){
                    console.log("socket msg groupCallMsgsIds 2 = ", groupCallMsgsIds)
                    if (groupCallMsgsIds.indexOf(socketMsg.message_id_for_group_chat) == -1){
                        groupCallMsgsIds.push(socketMsg.message_id_for_group_chat)
                        messageSent(socketMsg)
                    }   
                    
                } else {
                    messageSent(socketMsg)
                    if (socketMsg.dialog_id === currentChat.dialog_id){
                        setTimeout(function() {
                            setReadDialog()
                        }, 100)
                    }
                }
                if (isGroupCall && (socketMsg.from_id == getCookie("id") || socketMsg.to_id == getCookie("id"))){
                    setTimeout(function() {
                        setReadGroupDialog([socketMsg])
                    }, 100)
                    
                }
                
            } else if (socketMsg.from_id == getCookie("id")){
                var updatedIds = updateMsg_Id(data, socketMsg)
                setMessages(updatedIds, 2)
            }
         
            
            console.log("socketMsg.balanceHasBeenUpdated = ", socketMsg.balanceHasBeenUpdated)
            if (socketMsg.balanceHasBeenUpdated && currentChat != undefined && props.user != undefined && checkRepetitorResult != undefined && checkRepetitorResult.rub_per_message != undefined){
                const msgPrice = parseInt(checkRepetitorResult.rub_per_message)
                const moneyToUpdate = isIRepetitor ? parseFloat(msgPrice * ( 1 - config.comission)).toFixed(1) : -msgPrice
                props.updateBalance(moneyToUpdate)
            } 
         
            
        }
        if (socketMsg != undefined && (socketMsg.event === "changeDialogRepetitor" || socketMsg.event === "changePaidChatEvent")){
            console.log("changeDialogRepetitor")
            checkRepetitor()
        }

        if (socketMsg != undefined && socketMsg.event === "readMessages" && data.length > 0 && isOpenedChatMessage){
            setMessages(setAllReadedMsgs(data), 3)
        }
        if (socketMsg != undefined && socketMsg.event === "editMessage" && isOpenedChatMessage){
            setMessages(changeMsgText(data, socketMsg), 4)
        }
        if (socketMsg != undefined && socketMsg.event === "acceptPlanner" && currentChat != undefined && (currentChat.dialog.dialog_id == socketMsg.from_id + "_" + socketMsg.to_id || currentChat.dialog.dialog_id == socketMsg.to_id + "_" + socketMsg.from_id)){
            plannerMsgChanged(socketMsg.planner_id, true)
        }
        if (socketMsg != undefined && socketMsg.event === "cancelPlanner" && currentChat != undefined && (currentChat.dialog.dialog_id == socketMsg.from_id + "_" + socketMsg.to_id || currentChat.dialog.dialog_id == socketMsg.to_id + "_" + socketMsg.from_id)){
            plannerMsgChanged(socketMsg.planner_id, false)
        }
    }, [socketMsg])

    const listRef = useRef()
    const scrollableContainerRef = useRef()
    const sizeMap = useRef({})
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size }
        listRef.current.resetAfterIndex(index)
    }, [])
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();
    useEffect(() => {
        if (data.length == 0){
            return
        }
        if (isFirstLoad){
            scrollToBottom()
            //scrollBottomDebounced.current()
            setFirstLoad(false)
        } else if (currentListIndex == 0 || currentListIndex >= data.length - 5){
            listRef.current.scrollToItem(data.length)
        }
        
    }, [data])
    

    const delayedScrollToBottom = () => {
        setTimeout(function() {
            listRef.current.scrollToItem(data.length)
        }, 500)
    }
    const scrollToBottom = () => {
        console.log("scrollToBottom")
        if (listRef.current != undefined && listRef.current != null){
            listRef.current.scrollToItem(data.length)
            setTimeout(function() {
                listRef.current.scrollToItem(data.length)
                setLoading(false)
            }, 40)
            setTimeout(function() {
                setListScrolled(true)
            }, 100)
        }
        // setTimeout(function() {
        //     listRef.current.scrollToItem(data.length)
        //     setListScrolled(true)
        //     setLoading(false)
        // }, 1000)
       
    }
    const scrollToReplyMsg = (replyMsg) => {
        var scrollIndex = getReplyMsgIndex(data, replyMsg._id)
        if (scrollIndex > 0){
            scrollIndex -= 1
        }
        listRef.current.scrollToItem(scrollIndex)
        showReplyMsgAnimation(replyMsg)
    }
    const onScrollToBottomClick = () => {
        //listRef.current.scrollToItem(data.length)
        // setMessages(setAllReadedMsgs(data))
        listRef.current.scrollTo(99999999)
    }
    
    const showReplyMsgAnimation = (replyMsg) => {
        setTimeout(function() {
            const item = window.document.getElementById(replyMsg._id)
            if (item != undefined && item != null){
                const prevBG = item.style.background
                item.style.background = "#8F6FF7"
                item.style.color = "rgba(255, 255, 255, 0.5)"
                setTimeout(function() {
                    item.style.background = prevBG
                    item.style.color = "black"
                }, 300)
            } else {
                showReplyMsgAnimation(replyMsg)
            }
        }, 200)
    }
    const messageSent = (message, isMyMsg) => {
        console.log("messageSent message = ", message)
        if (data.length === 0){
            return
        }
        var array = JSON.parse(JSON.stringify(data))
        array.splice(array.length - 1,0, message)
        var responseMessages = setDateTitles(array)
        setMessages(responseMessages, 5)
        
        if (isMyMsg || currentListIndex >= data.length - 5){
            listRef.current.scrollToItem(data.length)
            //scrollToBottom()
            //scrollBottomDebounced.current()
        }        
    }

    // const call = (user) => {
    //     setCallToUser(user)
    // }
    const call = (checkRepetitorResult) => {
        setShowCallBottomSheet(false)
        props.setCallToUser(currentChat.user)
        props.setCurrentChat(undefined)
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
   
    if (user == undefined || currentChat == undefined){
        return (<div />)
    }
    // const zindex = getUrlParameter("zindex")
    // const isDisableNavigation = (zindex != undefined && zindex != null && zindex != "")
    var listWidth = isDesktop ? window.innerWidth - 360 - 64 : window.innerWidth - 16
    const isDesktopCallChat = isDesktop && props.isShowCallChat
    if (isDesktopCallChat){
        listWidth = 360
    }
    console.log("itemIndex = " + itemIndex)
    const onItemsRendered = (result) => {
        itemIndex = result.visibleStopIndex
        setServicesValueDebounced.current(result.visibleStopIndex, data)
    }
    const plannerMsgChanged = (planner_id, isAccepted) => {
        const msgs = [...data]
        for (let index = 0; index < msgs.length; index++) {
            if (msgs[index].planner != undefined && msgs[index].planner._id == planner_id){
                console.log("plannerMsgChanged set =", isAccepted)
                if (isAccepted){
                    msgs[index].planner.accepted = true
                } else {
                    msgs[index].planner.canceled = true
                }
            }
        }
        setMsgs(msgs)
    }
    const onBoardPicked = (url) => {
        messageSent(createNewMessage(currentChat.user.vk_caller_id, url), true)
        messageSentCallback()
        DialogsApi.sendMessage(url, currentChat.user.vk_caller_id, undefined, (responseData) => {
            //messageSentCallback()
        }, () => {})

    }
    const closeTgAuth = () => {
        setShowTgAuth(false)
    }
    const showTgAuth = () => {
        setShowTgAuth(true) 
        setShowTgNotifDialog(false)
    }
    const messageSentCallback = () => {
        if ((props.user.telegram_username == undefined || props.user.telegram_username == "") && getCookie("shown_chat_tg_notif") != getCookie("id")){
            setShowTgNotifDialog(true)
            setCookie("shown_chat_tg_notif", getCookie("id"))
        }
    }
    const isPaidChat = checkRepetitorInfo.dialogModel != undefined ? checkRepetitorInfo.dialogModel.paid_chat : false
    let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1
    return (
        <div style={(isDesktop && isGroupCall) ? getGroupCallChatStyle() : isDesktopCallChat ? {width : listWidth, maxWidth : listWidth, position : 'fixed', right : 0, height : '100%'} : props.isCallChat ? {} : isDesktop ? {width : listWidth + 32} : {}} className={isDesktop ? "selected_chat_container_desktop" : "selected_chat_container" + (isWebView ? "chat_cont_scroll" : "")}>
            
            <ChatNavbar setCanWriteReview={setCanWriteReview} fromCall={fromCall} isGroupCall={isGroupCall} setShowCallChat={props.setShowCallChat} setShowPaidChatDialog={setShowPaidChatDialog} checkRepetitor={checkRepetitor} checkRepetitorInfo={checkRepetitorInfo} isIRepetitor={isIRepetitor} socketMessage={socketMsg} isShowToolbarTooltip={isShowToolbarTooltip} setShowToolbarTooltip={setShowToolbarTooltip} showToast={showToast} isIBlocked={isIBlocked} isDesktopCallChat={isDesktopCallChat} callToUser={props.callToUser} setShowCallBottomSheet={setShowCallBottomSheet} call={call} setCurrentChat={props.setCurrentChat} currentChat={currentChat} isLoading={isLoading}/>            
            {/* <Loader /> */}
            {(!isChatsLoading && isLoading) && <Loader/>}
            
            <div className="relative">
           
                <div style={{display : 'none'}} className="chat_on_drag_border">
                    <div className="chat_on_drag_border_div"></div>
                    <div className="chat_on_drag_border_text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
                            <path d="M30.7984 4.48131C31.5547 4.65562 32.2352 4.93749 32.8932 5.34901C33.5245 5.74383 34.0215 6.16023 34.9894 7.11897L44.9319 17.0606C46.0342 18.1628 46.4799 18.6834 46.901 19.3568C47.3125 20.0148 47.5944 20.6953 47.7687 21.4516C47.9471 22.2256 48 22.9088 48 24.4676V38.2125C48 42.0966 47.6441 43.9394 46.6206 45.8532C45.6964 47.5813 44.3313 48.9464 42.6032 49.8706C40.6894 50.8941 38.8466 51.25 34.9625 51.25H22.0375C18.1534 51.25 16.3106 50.8941 14.3968 49.8706C12.6687 48.9464 11.3036 47.5813 10.3794 45.8532C9.35585 43.9394 9 42.0966 9 38.2125V15.8819C9 13.3905 9.44541 11.3932 10.3794 9.6468C11.3036 7.91873 12.6687 6.55356 14.3968 5.62938C16.1432 4.69541 18.1405 4.25 20.6319 4.25H27.7824C29.3412 4.25 30.0244 4.30293 30.7984 4.48131ZM28.0258 7.25045L20.6319 7.25C18.6103 7.25 17.0967 7.58753 15.8116 8.27482C14.6063 8.9194 13.6694 9.85633 13.0248 11.0616C12.3375 12.3467 12 13.8603 12 15.8819V38.2125C12 41.6437 12.2655 43.0186 13.0248 44.4384C13.6694 45.6437 14.6063 46.5806 15.8116 47.2252C17.2314 47.9845 18.6063 48.25 22.0375 48.25H34.9625C38.3937 48.25 39.7686 47.9845 41.1884 47.2252C42.3937 46.5806 43.3306 45.6437 43.9752 44.4384C44.7345 43.0186 45 41.6437 45 38.2125V24.4676C45 23.9066 44.9936 23.4955 44.9777 23.1701C44.8277 23.2227 44.667 23.25 44.5 23.25H33.5C31.0147 23.25 29 21.2353 29 18.75V7.75C29 7.58298 29.0273 7.42233 29.0777 7.27227C28.8035 7.25882 28.4653 7.25215 28.0258 7.25045ZM31.9999 8.41747L32 18.75C32 19.5784 32.6716 20.25 33.5 20.25L43.8334 20.2511C43.6012 19.9855 43.2849 19.6563 42.8106 19.1819L33.0681 9.43942C32.5943 8.96564 32.2653 8.64955 31.9999 8.41747Z" fill="#AC92ED"/>
                        </svg>
                        <div style={{marginTop : '10px'}}> Перетащите сюда файлы,<br/>чтобы отправить их</div>
                    </div>
                </div>
                {data.length > 0 && 
                    <List style={isListScrolled ? {opacity : 1, scrollBehavior : (safariAgent ? 'auto' : 'smooth')} : {}} className="List opacity_list_messages"
                        height={windowHeight}
                        width={listWidth - (isDesktop ? 16 : 0)}
                        ref={listRef}
                        itemCount={data.length}
                        itemSize={getSize}
                            //itemKey={(index) => itemIndex = index}
                        outerRef={scrollableContainerRef}
                        onItemsRendered={onItemsRendered}
                        // scrollToItem={replyScrollIndex}
                        // onAnimationComplete={() => {console.log("onAnimationComplete")}}
                        itemData={data}>
                            {({ data, index, style }) => (
                                <div style={style}>
                                    <RowMessageItem setReplyMsg={setReplyMsg} videoStreams={videoStreams} fromCall={fromCall} data={data} index={index} setSize={setSize} windowWidth={windowWidth} user={currentChat.user} isIRepetitor={isIRepetitor} setContextMenuMsg={setContextMenuMsg} scrollToReplyMsg={scrollToReplyMsg} setDeclinePlanner={setDeclinePlanner} isPaidChat={isPaidChat} checkRepetitorResult={checkRepetitorResult} changeMsg={changeMsg} setErrorAcceptPlanner={setErrorAcceptPlanner} isDesktop={isDesktop} setShowCallBottomSheet={setShowCallBottomSheet} setShowOpenContacts={setShowOpenContacts}/>
                                </div>
                            )}                        
                        </List>
                }
                {isFileUploading && 
                <div className='chat_file_uploader_bg'>
                    <div className='chat_file_uploader'>
                        <Loader isBlack style={{minHeight : '32px', marginTop : '12px', marginBottom : '0', position : 'relative', marginLeft : '-10px'}}/>
                        <div style={{marginTop : '36px', width : '200px', marginLeft : '-70px'}}>Файл загружается</div>
                    </div>
                </div>}
            </div>
           
            <WriteBar setFileUploading={setFileUploading} chatChangeHook={chatChangeHook} setShowAttachDesk={setShowAttachDesk} is_i_creator={is_i_creator} videoStreams={videoStreams} fromCall={fromCall} isGroupCall={isGroupCall} delayedScrollToBottom={delayedScrollToBottom} setErrorSize={setErrorAcceptPlanner} messageSentCallback={messageSentCallback} isPaidChat={isPaidChat} replyMsg={replyMsg} setReplyMsg={setReplyMsg} editableMsg={editableMsg} setEditableMsg={setEditableMsg} isLoading={isLoading} config={config} setOpenRules={setOpenRules} isInMyBlock={isInMyBlock} isIBlocked={isIBlocked} style={isDesktopCallChat ? {position : 'absolute', left : 0} : undefined} checkRepetitorResult={checkRepetitorResult} isIRepetitor={isIRepetitor} setBalance={props.setBalance} messages={data} user={props.user} currentChat={currentChat} messageSent={messageSent}/>
            <ScrollToBottomArrow isZIndex1={replyMsg.text != undefined || editableMsg.text != undefined} length={data.length} itemIndex={currentListIndex} onScrollToBottomClick={onScrollToBottomClick} count={data.filter(d => d.from_id != undefined && d.from_id != getCookie("id") && !d.is_read).length}/>
            <RulesBottomSheet isShowCallChat={props.isShowCallChat} config={config} user={user} checkRepetitorResult={checkRepetitorResult} isIRepetitor={isIRepetitor} repetitor={checkRepetitorResult} setCurrentChat={props.setCurrentChat} currentChat={currentChat} isOpenRules={isOpenRules} setOpenRules={setOpenRules}/>
            <CallPricesBottomSheet config={config} getMyInfo={props.getMyInfo} setShow={setShowCallBottomSheet} user={props.user} callToUser={currentChat.user} isShow={isShowCallBottomSheet} call={call}/>
            <ChangeRole isShow={isShowChangeRole} setShow={setShowChangeRole} checkRepetitor={checkRepetitor} to_id={currentChat.user.vk_caller_id}/>
            <MsgContextMenu isShowCallChat={props.isShowCallChat} setReplyMsg={setReplyMsg} setEditableMsg={setEditableMsg} showToast={showToast} contextMenuMsg={contextMenuMsg} setContextMenuMsg={setContextMenuMsg}/>
            <PaidChatDialog isShowCallChat={props.isShowCallChat} isShow={isShowPaidChatDialog} setShow={setShowPaidChatDialog}/>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <ChatToast text={toastText}/>
            </CSSTransition>

            <CSSTransition in={isShowToolbarTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                <ChatToolbarTooltip canWriteReview={canWriteReview} setCanWriteReview={setCanWriteReview} setShowChangeRole={setShowChangeRole} isIRepetitor={isIRepetitor} user={currentChat.user} getMyInfo={props.getMyInfo} setInMyBlock={setInMyBlock} isInMyBlock={isInMyBlock} showToast={props.showToast} isMyProfile={props.isMyProfile} setShow={setShowToolbarTooltip}/>
            </CSSTransition>

            
            <DeclinePlannerDialog plannerMsgChanged={plannerMsgChanged} msg={declinePlanner.msg} isShow={declinePlanner.isShow} setDeclinePlanner={setDeclinePlanner} changeMsg={changeMsg}/>
            <ErrorCreationDialog isShow={errorAcceptPlanner != ""} setShow={() => setErrorAcceptPlanner("")} error={errorAcceptPlanner}/>
            <AttachDeskDialog onBoardPicked={onBoardPicked} isShow={isShowAttachDesk} setShow={setShowAttachDesk}/>
            <ContactsDialog fromChat getMyInfo={props.getMyInfo} showToast={showToast} owner_user_id={currentChat.user._id} config={props.config} user={user} isShow={isShowOpenContacts} setShow={setShowOpenContacts}/>

            <TgNotifDialog showTgDialog={showTgAuth} isShow={isShowTgNotifDialog} setShow={setShowTgNotifDialog}/>
            {isShowTgAuth && <TgAuthInformation closeTgAuth={closeTgAuth} fromProfileFill user={user} getMyInfo={props.getMyInfo} />}
        </div>
    );
}

export default Chat;

