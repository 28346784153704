/* eslint-disable eqeqeq */
import '../../styles/blogs.css'
import { useState, useEffect } from 'react';
import { getIsDesktop, getUrlParameter, Pages} from '../../utils/Utils';
import { CSSTransition } from 'react-transition-group';
import Toast from '../profile/custom_views/Toast';
import { useHistory } from "react-router-dom";
import { BlogsApi } from '../../api/BlogsApi';
import other_back from '../../img/blogs/blogs_back.svg'
import back_arrow from '../../img/experts/back_arrow.svg'
import { getDesktopStyle } from '../../utils/Styles';
import BlogItem from './custom_views/BlogItem';

function BlogsPost(props) {
    const [savedPost, setSavedPost] = useState(undefined)
    const [toastText, setToastText] = useState("")
    const [lastPosts, setLastPosts] = useState([])
    const [userPosts, setUserPosts] = useState([])

    const history = useHistory()
    const user = props.user
    const isDesktop = getIsDesktop()
  
    useEffect(() => {
        getSavedPost(getUrlParameter("id"))
        addView(getUrlParameter("id"))
        getLastPosts()
        
    }, [])
    const getLastPosts = () => {
        BlogsApi.getLastPosts(3, 0, "", (responseData) => {
            if (responseData.results && responseData.results.length > 0){
                var posts = responseData.results
                posts.forEach(element => {
                    element.extracted_content = extractContent(element.text)
                });
                setLastPosts(posts)
            }
        }, () => { })
    }
    const getPostsByUserID = (user_id) => {
        BlogsApi.getPostsByUserID(30, 0, user_id, (responseData) => {
            var posts = responseData.results
            posts.forEach(element => {
                element.extracted_content = extractContent(element.text)
            });
            setUserPosts(posts)
        }, () => { })
    }
    const getSavedPost = (id) => {
        BlogsApi.getPostByID(id, (responseData) => {
            const item = responseData.item
            if (item){
                setSavedPost(item)
                getPostsByUserID(item.owner_user._id)
            }
        }, () => {})
    }
    const addView = (id) => {
        BlogsApi.addViewToPost(id, (responseData) => {
           
        }, () => {})
    }
    const removePost = () => {
        window.location.href = Pages.blogs
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const onBack = () => {
        if (history.action == "POP"){
            window.location.href = Pages.blogs
        } else {
            history.goBack()
        }
    }
    // function createElementFromHTML(htmlString) {
    //     var div = document.createElement('div');
    //     div.innerHTML = htmlString.trim();
      
    //     // Change this to div.childNodes to support multiple top-level nodes.
    //     return div.getElementsByTagName('h1')[0].innerText
    // }
    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        var pTags = span.getElementsByTagName('p')
        var h1Tags = span.getElementsByTagName('h1')
        if (h1Tags.length > 0){
            var tag = ""
            for (let index = 0; index < h1Tags.length; index++) {
                const h1Tag = h1Tags[index];
                if (h1Tag.innerText != ""){
                    tag = h1Tag.innerText
                    break
                } 
            }
          
            return tag
           
        } 
        if (pTags.length > 0){
            var tag = ""
            for (let index = 0; index < pTags.length; index++) {
                const pTag = pTags[index];
                if (pTag.innerText != ""){
                    tag = pTag.innerText
                    break
                }
            }
           
            return tag
            //return pTags[0].innerText
        } else {
            return ""
        }
        
    }
    // const getNewPosts = () => {
    //     return(

    //     )
    // }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header blog_post_margin">
                <div className="flex">
                    {isDesktop ? <img alt='' onClick={onBack} className="ads_back_desktop blog_post_back hover" src={other_back}/> 
                    :
                    <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}
                    {savedPost != undefined && <div className="h1tagcont" style={{display : 'none'}} dangerouslySetInnerHTML={{__html: savedPost.text}}></div>}

                    {savedPost != undefined && !savedPost.text.includes("<h1>") ? <h1 className="blog_toolbar_title h1_title">{savedPost.owner_user.specializations.length > 0 ? savedPost.owner_user.specializations[0] : "Статья в блогах"}</h1> : <div className="blog_toolbar_title">Статья в блогах</div>}
                </div>
            </div>
        )
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className={isDesktop ? "blogs_post_desktop" : "selected_settings"}>
                {getToolbar()}
                {savedPost != undefined && 
                    <div className="blog_post_mt blogs_post_desktop_flex">
                        <BlogItem fromBlogPost showToast={(text) => showToast(text, setToastText)} removePost={removePost} myUser={user} setSelectedProfile={() => {}} index={0} post={savedPost}/>
                        <div className="blogs_post_desktop_new">
                            <div className="blogs_post_desktop_new_cont">
                                <b>Новые посты</b>
                                {lastPosts.length > 0 && lastPosts.map((post, index) => (
                                    <a rel="noreferrer" style={post.extracted_content == "" ? {display : 'none'} : {}} href={Pages.blogs_post + "?id=" + post._id} target="_blank" className="hover" index={index} key={index}>
                                        <div className="blogs_clamp hover">{post.extracted_content}</div>
                                    </a>
                                ))}
                            </div>
                            {userPosts.length > 0 && <div className="blogs_post_desktop_new_cont">
                                <b>Посты этого автора</b>
                                {userPosts.length > 0 && userPosts.map((post, index) => (
                                    <a rel="noreferrer" style={post.extracted_content == "" ? {display : 'none'} : {}} href={Pages.blogs_post + "?id=" + post._id} target="_blank" className="hover" index={index} key={index}>
                                        <div className="blogs_clamp hover">{post.extracted_content}</div>
                                    </a>
                                ))}
                            </div>}
                        </div>
                    </div>
                }
            </div>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
    )
}

export default BlogsPost;

