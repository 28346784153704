import back_fill from '../../../img/back_fill.svg'
function ProfileFillNavbar(props) {
    const getStepString = () => {
        var step = props.step
        if (window.location.pathname.includes("/profile_fillind/step_")){
            step = window.location.pathname.split("/profile_fillind/step_")[1]
        }
        if (step > 3){
            return (step - 3) + " из 5"
        } else {
            return step + " из 3"
        }
    }
    return (
        <div className="relative">
            <div className="flex profil_fill_navbar_cont">
                {props.step != 0 && <div onClick={() => props.onBack(props.step == 1 ? 0 : props.step - 1)} className="back_icon_container hover">
                    <img className="back_icon_img" src={back_fill}/>
                </div>}
                <div>
                    <p style={props.step == 0 ? {marginLeft : '16px'} : {marginTop : '8px'}} className="fill_navbar_title">Заполнить профиль</p>
                    {props.step != 0 && <p className="fill_navbar_title_desc">{getStepString()}</p>}

                </div>
                
            </div>
            <div className="fill_navbar_line"/>
        </div>
    );
}

export default ProfileFillNavbar;
