import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
export async function registerWorker() {
  console.log('Registering service worker');
  const registration = await navigator.serviceWorker.
  register('/firebase-messaging-sw.js', {scope: '/'});
  console.log('Registered service worker');
  const subscription = await registration.pushManager.
  subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BCu0TCVsV1FDXLexT4Ufy1YTJjrlp7u5mio7-Yn9231DrAFZGzjg-HauCPsdYeeY2C2r6cyxpiHRbJyFc0V9qIU'
  });
}
if ('serviceWorker' in navigator) {
  console.log('Registering service worker'); 
  registerWorker().catch(error => console.error(error));
}
