import React, { useState, useRef } from 'react';
import 'react-image-crop/dist/ReactCrop.css'
import { UploadApi } from '../../../api/UploadApi';
import ImageCropper from './ImageCropper';
import Loader from '../../../custom_views/Loader';
import avatar from '../../../img/avatar.svg'

var img = new Image; 
var fr = new FileReader;
var pickedFile = {}
function UploadAvatar(props) {
    const [isLoading, setLoading] = useState(false)
    const [isShowCropper, setShowCropper] = useState(false)
    const [croppedImg, setCroppedImg] = useState(undefined)
    const avatarUrl = props.avatarUrl
    const fileUpload = useRef(null)
    
    const fileChangedHandler = (event) => {
        if(event && event.target.files && event.target.files.length > 0){
            uploadFile(event.target.files[0])
        }
    }
    const uploadFile = (file) => {
        setLoading(true)
        pickedFile = file
        fr.onload = onLoadedFileReader
        fr.readAsDataURL(file)
    }
    const onLoadedFileReader = () => {
        img.onload = onPhotoReady
        img.src = fr.result
    }
    const onPhotoReady = () => {
        setShowCropper(true)
    }
    const uploadPhoto = (img) => {
        UploadApi.uploadAvatar(img, (responseData) => {
            props.setAvatarUrl(responseData.url)
            setLoading(false)
        }, 
        () => {setLoading(false)})
    }
    const onCropped = (img) => {
        setLoading(true)
        setShowCropper(false)
        setCroppedImg(img)
        uploadPhoto(img)
    }
 
    const cancelCrop = () => {
        setShowCropper(false)
        setLoading(false)
    }
    if (isShowCropper){
        return(<ImageCropper file={pickedFile} onCancel={cancelCrop} onCropped={onCropped} img={img}/>)
    }
    return (
        <div>
            {isLoading ? 
                <Loader/> : <div>
                <div onClick={() => fileUpload.current.click()} className="hover">
                    <img className="profile_avatar_filling" src={croppedImg != undefined ? URL.createObjectURL(croppedImg) : avatarUrl ? avatarUrl : avatar}/>
                    <p className="upload_photo_btn">Загрузить фотографию</p>
                </div>
                <input id="fileInput" className="custom-file-input hover" type="file" accept="image/*" onChange={fileChangedHandler} style={{ display: "none" }} ref={fileUpload}/>
            </div>}
        </div>
    );
}

export default UploadAvatar;
