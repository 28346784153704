import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import profile_published from '../../../img/profile/profile_published.svg'
function ProfilePublishedDialog(props) {
    const close = () => {
        props.setShow(false)
    }
    const isDesktop = getIsDesktop()
    const getInnerHtml = () =>{
        return(
            <div className="call_info_container profile_published_cont">
                <div className="profile_published_img_container">
                    <im alt='' className="profile_published_img" src={profile_published}/>
                </div>
                <div className="profile_published_dialog_title">Ваш профиль успешно<br/>опубликован</div>
                <div className="profile_published_text">Теперь наши пользователи смогут найти вас в базе экспертов и взять у вас консультацию. <b>Уводить клиентов с платформы запрещено, такие ситуации будут отлавливаться.</b> Мы ценим честные партнёрские отношения и всячески содействуем нашим экспертам.</div>
                                    
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px 0 24px'}} onClick={() => props.setShow(false)} className="call_price_continue hover">Понятно</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={close} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default ProfilePublishedDialog;

