import { getIsDesktop } from "../../../utils/Utils";

function WaitingTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    const isDesktop = getIsDesktop()
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="pay_waiting_tooltip">
                <div style={{left : '80%'}} className="green_rectangle"></div>
                <div className="green_profile_tooltip">Деньги заморожены на счету клиента и зачислятся на ваш баланс после начала запланированного созвона</div>
                
            </div>
        </div>
     );
 }
 
 export default WaitingTooltip;
 