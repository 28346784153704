import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Loader from '../../../custom_views/Loader';
import { getRequestParams } from '../../../utils/CookieHelper';
import { getRequestUrl, getHttpParams } from '../../../utils/Utils';
import autosize from 'autosize';
import { AuthApi } from '../../../api/AuthApi';

function PromocodeView(props) {
    const [isShowPromo, setShowPromo] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [promoCode, setPromoCode] = useState("")
    const [isPromoCodeCorrect, setPromoCodeCorrect] = useState(0)
    const inputRef = useRef(null);
    useEffect(() => {
        if (isShowPromo){
            inputRef.current.focus();
            autosize(inputRef.current);
        }
        
    }, [isShowPromo]);

    const checkPromocode = () => {
        if (promoCode == undefined || promoCode == ""){
            return
        }
        setLoading(true)
        AuthApi.checkPromocode(promoCode, (responseData) => {
            setPromoCodeCorrect(1)
            props.setPromoCode(promoCode)
            setLoading(false)
        }, () => {
            setLoading(false)
            setPromoCodeCorrect(-1)
        })
    }
    const onPromoChanged = (value) => {
        setPromoCode(value)
        if (isPromoCodeCorrect != 0){
            setPromoCodeCorrect(0)
        }
    }
    const isPromoBtnDisabled = promoCode == undefined || promoCode == "" || promoCode.length < 3
    return (
        <div className="auth_promo_bg">
            {!isShowPromo && <p onClick={() => setShowPromo(true)} className="auth_promo_text hover">У меня есть промокод</p>}
            <CSSTransition in={isShowPromo} timeout={300} classNames="animation_in" unmountOnExit>
                <div>
                    <div style={{marginTop : '24px'}} className="auth_phone_bg">
                        <p className="auth_phone_title">Промокод</p>
                        <input ref={inputRef} className="auth_phone_input" type="number" value={promoCode} onChange={(e) => onPromoChanged(e.target.value)}/>
                    </div>
                    {isLoading ? <div style={{position : 'relative', height : '48px'}}><Loader style={{marginTop : "8px"}}/></div> : 
                    isPromoCodeCorrect == 0 ? <div onClick={checkPromocode} style={isPromoBtnDisabled ? {pointerEvents : 'none', opacity : '0.2'} : {}} className="auth_promo_text hover">ПРИМЕНИТЬ</div> : isPromoCodeCorrect == -1 ? 
                    <div className="promo_result_text wrong_promo">😞   Такого промокода не существует</div> :
                    <div className="promo_result_text correct_promo">👍  Промокод успешно применён</div>}
                </div>
            </CSSTransition>
        </div>
    );
}

export default PromocodeView;
