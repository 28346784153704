/* eslint-disable eqeqeq */
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../../utils/Utils';
import BaseDesktopDialog from '../../../../custom_views/BaseDesktopDialog';
import Loader from '../../../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import { UsersApi } from '../../../../api/UsersApi';


function ProActivateDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const user = props.user
    const activateDialogInfo = props.activateDialogInfo
    const isNotEnoughtMoney = (price) => {
        const countRub = user.count_rub == undefined ? 0 : Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
        const notEnoughtMoney = countRub < price
        return notEnoughtMoney
    }
    const activate = () => {
        if (hasNoMoney){
            history.push(Pages.pay + '?balance=' + activateDialogInfo.price +'&pay=1')
        } else {
            setLoading(true)
            UsersApi.buyPro(activateDialogInfo.type, (responseData) => {
                props.getMyInfo()
                //setLoading(false)
            }, () => {props.getMyInfo()})
            
        }
    }
    const cancel = () => {
        props.setShow(false)
    }
    const hasNoMoney = isNotEnoughtMoney(activateDialogInfo.price)
    const isPro = user.is_pro
    const getInnerHtml = () => {
        return(
            <div className="pro_dialog_container">
                {isPro ?
                     <div style={{textAlign : isDesktop ? 'center' : 'left'}}>
                        <div style={{textAlign : isDesktop ? 'center' : 'left'}} className="pro_dialog_title">Ура! Теперь вы PRO-эксперт 👑</div>
                        <div style={{textAlign : isDesktop ? 'center' : 'left'}} className="pro_dialog_text">Желаем вам успешной работы на платформе, интересных консультаций, благодарных клиентов и роста вашего дохода!</div>
                        <div style={{marginLeft : isDesktop ? 'auto' : '0'}} onClick={() => props.setShow(false)} className="pro_dialog_btn hover">Хорошо</div>
                    </div>
                :
                    <div>
                        <div className="pro_dialog_title">{hasNoMoney ? 'На вашем балансе недостаточно средств' : 'Оплата статуса PRO'}</div>
                        <div className="pro_dialog_text">{hasNoMoney ? 'Пополните ваш баланс на ' + activateDialogInfo.price + '₽. Оплата статуса произойдёт с внутреннего баланса, после этого вам станут доступны все преимущества статуса.' : 'С вашего баланса будет списано ' + activateDialogInfo.price + '₽. После этого вам станут доступны все преимущества статуса.'}
                            <br/><br/>
                            Действует автоматическое продление статуса. Следующий автоплатёж с вашего внутреннего баланса будет осуществлён через неделю.
                            <br/><br/>
                            Нажимая кнопку “Оплатить”, вы соглашаетесь с <a rel="noreferrer" target="_blank" href={Pages.terms} className="pro_dialog_text hover">Условиями и правилами</a> предоставления опции.</div>
                        <div>
                            <div onClick={activate} className="pro_dialog_btn hover">{isLoading ? <Loader isWhite/> : hasNoMoney ? "Пополнить баланс" : "Оплатить " + activateDialogInfo.price + "₽"}</div>

                            <div onClick={cancel} className="pro_dialog_btn_cancel hover">Отмена</div>
                        </div>
                    </div>
                }
                
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return null
        }
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ProActivateDialog;
