function CustomTooltip(props) {
    if (props.text == ""){
        return(<div/>)
    }
    
     return (
        <div className="profile_published_tooltip">
            <div onClick={() => props.setShow(false)} className="more_tooltip_profile_bg"></div>
            <div onClick={() => props.setShow(false)} className={props.tooltipClass}>
                <div style={props.tooltipArrowStyle} className="green_rectangle"></div>
                <div className="green_profile_tooltip">{props.text}</div>
            </div>
        </div>
     );
 }
 
 export default CustomTooltip;
 