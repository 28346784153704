import React from 'react';
import step_1 from '../../../img/experts/step_1.svg'
import step_2 from '../../../img/experts/step_2.svg'
import step_3 from '../../../img/experts/step_3.svg'
import step_4 from '../../../img/experts/step_4.svg'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import { getIsDesktop } from '../../../utils/Utils';

function HowItWorks(props) {
        
    const slides = [
        {id : 0, text : "Найдите нужного эксперта в каталоге. Видеопрезентация и отзывы клиентов помогут сделать правильный выбор.", img : step_1},
        {id : 1, text : "Пополните баланс удобным способом.", img : step_2},
        {id : 2, text : "Спишитесь или созвонитесь с выбранным экспертом.", img : step_3},
        {id : 3, text : "Оплата спишется автоматически", img : step_4}]
  
    const getInnerCorousel = () => {
        return(
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {slides.map((item, index) => (
                    <Card slide={item} itemId={item.id} index={index} key={index}/>
                ))}
            </ScrollMenu>
        )
    }
    const isDesktop = getIsDesktop()
    return (
        <div className="how_it_works_container">

            <div className="how_it_works_title">
                Как это работает
                {/* <div className="how_it_works_circles">
                    {items.map((_item, index) => (
                        <div style={currentItem == index ? {opacity : 1} : {}} className="how_works_circle" index={index} key={index}></div>
                    ))}
                </div> */}
            </div>
            {isDesktop && <p style={{paddingTop : '56px'}} className="experts_title_section">О сервисе</p>}

            <div className="carousel_mobile">
                {/* <CarouselProvider naturalSlideWidth={256} naturalSlideHeight={216} playDirection={'forward'} interval={delay} step={1} infinite={true} visibleSlides={rate} totalSlides={slides.length}>
                    <WrappedListener updatePlace={changeIndex}/>
                        <Slider>
                            {slides.map((slide, index) => (
                                <Slide index={index} key={index}>
                                    <div className="how_it_works_slide">
                                        <img className="how_it_works_slide_img" src={slide.img}/>
                                        <p className="how_it_works_slide_text">{slide.text}</p>
                                    </div>
                                </Slide>
                            ))}
                        </Slider>
                </CarouselProvider> */}
                {getInnerCorousel()}
            </div>
            <div className="carousel_desktop">
                {getInnerCorousel()}
            </div>
          
        </div>
    )
}
function Card({ slide, itemId, index }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isDesktop = getIsDesktop()
    return (
        <div style={index == 0 ? {marginLeft : '16px'} : (index == 3 && isDesktop) ? {} : {}} className="how_it_works_slide">
            <img className="how_it_works_slide_img" src={slide.img} alt=""/>
            <p className="how_it_works_slide_text">{slide.text}</p>
        </div>
    );
  }
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img alt="" style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img alt="" style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_right hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}
export default HowItWorks;


