/* eslint-disable eqeqeq */
import { useState } from 'react';
import back_arrow from '../../../img/experts/back_arrow.svg'
import { useHistory } from "react-router-dom";
import { getIsDesktop, Pages } from '../../../utils/Utils';
import other_back from '../../../img/other/other_back.svg'
import { getDesktopStyle } from '../../../utils/Styles';
import { Route } from "react-router-dom";
import TgAuthInformation from './TgAuthInformation';
import { isUserAuthed } from '../../../utils/CookieHelper';
import NotAuthed from '../../profile/custom_views/NotAuthed';
import TgNotificationsDialog from './dialogs/TgNotificationsDialog';
import ChangeUserUrlDialog from './dialogs/ChangeUserUrlDialog';
import RemoveTgDialog from './dialogs/RemoveTgDialog';

function Settings(props) {
    const [isShowTgDialog, setTgShowDialog] = useState(false)
    const [isShowChangeUrl, setShowChangeUrl] = useState(false)
    const [isShowRemoveTG, setShowRemoveTG] = useState(false)

    const history = useHistory()
    const isDesktop = getIsDesktop()
    const user = props.user
    const isTgNotficitaionsAdded = user.telegram_username
    var desktopStyle = getDesktopStyle()
    desktopStyle.zIndex = 3
    const openTG = () => {
        history.push(Pages.settings + Pages.tg_auth_inform)
        // if (!isTgNotficitaionsAdded){
        //     window.open('https://t.me/notification_sozvon_bot', '_blank')
        //     setTgShowDialog(true)
        // }
    }
    const onFinishTg = () => {
        setTgShowDialog(false)
    }
   
    return (
        <div style={isDesktop ? desktopStyle : {zIndex : '2'}} className="selected_settings">
            <div className="desktop_referals">
                {isDesktop && <img alt="" onClick={() => history.goBack()} className="other_back_desktop hover" src={other_back}/>}

                <div className="arrow_left_theme">
                    <div style={{width : '100%'}} className="flex">
                        {!isDesktop && <img alt="" onClick={() => history.goBack()}  className="back_arrow_theme hover" src={back_arrow}/>}
                        <p className="theme_title">Настройки</p>
                    </div>
                </div>
                {isUserAuthed() ?
                    <div style={isDesktop ? {maxWidth: '360px', margin : '0 auto'} : {}} className="other_rules_container">
                        <div style={{padding : '24px'}} className="other_item_container">
                            <div className="other_settings_title_tg">Уведомления в Telegram</div>
                            <div className="other_settings_desc_tg">Чтобы всегда оставаться на связи и не пропускать сообщения в сервисе Sozvon, подключите Telegram-бота к вашему аккаунту.</div>
                            {isTgNotficitaionsAdded ? 
                            <div>
                                <div className="other_settings_border">Привязан аккаунт @{user.telegram_username}</div> 
                                <div onClick={() => setShowRemoveTG(!isShowRemoveTG)} className='other_settings_remove_tg hover'>Отвязать</div>
                            </div> 
                            : 
                            <div className='flex'>
                                <div onClick={openTG} className="other_add_notif_btn hover">Подключить</div>
                                <div onClick={() => setTgShowDialog(true)} className="other_add_notif_btn hover">Вставить код</div>
                            </div>
                            }
                        </div>

                        <div style={{padding : '24px', marginTop : '16px'}} className="other_item_container">
                            <div className="other_settings_title_tg">Адрес вашей страницы</div>
                            <div className="other_settings_desc_tg">Выберите короткий адрес страницы, чтобы ваша ссылка смотрелась более выигрышно</div>
                            <div>
                                <div className="other_settings_border">
                                    <div className='other_settings_link'>https://sozvon.pro/users/</div>
                                    {user.domain_name ?  <div>{user.domain_name}</div> : <div>id{user._id}</div>}
                                </div> 
                                <div onClick={() => setShowChangeUrl(!isShowChangeUrl)} className='other_settings_remove_tg hover'>Изменить</div>
                            </div> 
                        </div>
                    </div>
                :
                   <NotAuthed btnStyle={isDesktop ? {left: 'calc((100vw)/2 - 210px)'} : {}}/>
                }
            </div>
            <TgNotificationsDialog onFinish={onFinishTg} getMyInfo={props.getMyInfo} isShow={isShowTgDialog} setShow={setTgShowDialog}/>
            <ChangeUserUrlDialog user={user} onFinish={() => setShowChangeUrl(false)} getMyInfo={props.getMyInfo} isShow={isShowChangeUrl} setShow={setShowChangeUrl}/>
            <RemoveTgDialog onFinish={() => setShowRemoveTG(false)} getMyInfo={props.getMyInfo} isShow={isShowRemoveTG} setShow={setShowRemoveTG}/>
            <Route path={Pages.settings + Pages.tg_auth_inform}>
                <TgAuthInformation user={user} getMyInfo={props.getMyInfo} />
            </Route>
        </div>
    );
}

export default Settings;
