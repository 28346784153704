import { Pages, getIsDesktop } from '../../../utils/Utils';
import { useHistory } from "react-router-dom";
import filter from '../../../img/blogs/filter.svg';
import question from '../../../img/blogs/question.svg';



function BlogsHeader(props) {
    const isDesktop = getIsDesktop()
    const history = useHistory()
    return (
        <div className="blog_header relative">
            <div className="blog_header_title">Блоги</div>
            {!isDesktop && <div className='flex'>
                <div onClick={() => history.push(Pages.blogs_filter)} className="hover">
                    <img alt='' src={filter}/>
                </div>
                <div onClick={() =>  window.open(Pages.blogs_faq, '_blank')} className="hover">
                    <img alt='' src={question}/>
                </div>
            </div>}
        </div>)
}

export default BlogsHeader;