import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import * as SDK from '@vkontakte/calls-sdk'
import check_black from '../../../img/create_ads/check_black.svg'

function MicSettingsDialog(props) {
    // const [microphones, setMicrophones] = useState([])
    const microphones = SDK.browser.getMicrophones()
    const isDesktop = getIsDesktop()
    // console.log("SDK.browser.getMicrophones() = ", SDK.browser.getMicrophones())
    const setMic = async (item) => {
        try {
            await SDK.changeDevice(item.kind, item.deviceId)
        } catch (error) {
            console.error(error)
        }
       
        props.setShow(false)
    }
    // useEffect(() => {
    //     if (props.isShow){
    //         var mics = SDK.browser.getMicrophones()
    //         const savedMic = SDK.browser.getSavedMicrophone()
    //         if (savedMic != null){
    //             mics.forEach(element => {
    //                 element.isSelected = element.deviceId == savedMic.deviceId
                    
    //             });
    //         }
    //         setMicrophones(mics)
    //         console.log("mic setted = ", savedMic)
    //     }
    // }, [props.isShow])
    const grantAccessMic = () => {
        
        props.setShow(false)

    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                {SDK.browser.hasMicrophonePermission() ?
                <div>
                    <p style={{marginBottom : '24px'}} className="call_edit_title">Выберите микрофон</p>
                    {microphones.length > 0 && microphones.map((item, index) => (
                        <div onClick={() => setMic(item)} style={{justifyContent : 'space-between', marginBottom : '8px'}} className="flex hover" index={index} key={index}>
                            <div className="flex">
                                <div className="call_pick_mic_bg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M12.0002 2.48438C9.79105 2.48438 8.00019 4.27524 8.00019 6.48437V11.4844C8.00019 13.6935 9.79105 15.4844 12.0002 15.4844C14.2093 15.4844 16.0002 13.6935 16.0002 11.4844V6.48438C16.0002 4.27524 14.2093 2.48438 12.0002 2.48438Z" fill="#AC92ED"/>
                                        <path d="M5.1003 12.4844C4.54896 12.4844 4.11844 12.97 4.2572 13.5036C5.07879 16.6629 7.78287 19.0629 11.1002 19.4343V21.5844C11.1002 22.0814 11.5031 22.4844 12.0002 22.4844C12.4972 22.4844 12.9002 22.0814 12.9002 21.5844V19.4343C16.2175 19.0629 18.9216 16.6629 19.7432 13.5036C19.8819 12.97 19.4514 12.4844 18.9001 12.4844C18.4504 12.4844 18.0742 12.8134 17.9467 13.2447C17.1881 15.8114 14.8129 17.6844 12.0002 17.6844C9.1875 17.6844 6.81226 15.8114 6.05363 13.2447C5.92617 12.8134 5.54999 12.4844 5.1003 12.4844Z" fill="#AC92ED"/>
                                    </svg>
                                </div>
                                <p className="call_mic_picker" >{item.label}</p>
                            </div>
                            {(SDK.browser.getSavedMicrophone() != null && SDK.browser.getSavedMicrophone().deviceId == item.deviceId) && <img style={{opacity : 1, marginTop : '10px'}} className="ads_spec_item_icon" src={check_black}/>}
                        </div>
                    ))}
                    
                    <div style={{marginTop : '36px'}} className="flex">
                        <div style={{margin : '0 24px 0 24px'}} onClick={() => props.setShow(false)} className="call_price_continue hover">Отмена</div>
                    </div>
                </div> : 
                <div>
                    <p style={{marginBottom : '24px'}} className="call_edit_title">Доступ к микрофону запрещен</p>
                    <p className="call_edit_desc">Вы запретили браузеру использовать микрофон</p>
                    <div style={{marginTop : '36px'}} className="flex">
                        <div style={{margin : '0 24px 0 24px'}} onClick={grantAccessMic} className="call_price_continue hover">Понятно</div>
                    </div>
                </div>}
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default MicSettingsDialog;
