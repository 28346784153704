import React from 'react'
import profile_fill_1 from '../img/profile_fill_1.png'
import lama from '../img/lama.svg'
class EmptyView extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
        return (
            <div style={this.props.bgStyle ? this.props.bgStyle : {}} className="profile_fill_start_section">
                <div className="center_vertical center_fill_cont">
                    <img style={this.props.imageStyle ? this.props.imageStyle : {}} className="profile_fill_start_img" src={lama}/>
                    <p className="profile_fill_start_desc">{this.props.text}</p>
                </div> 
            </div>
        )   
    }
}
export default EmptyView
