/* eslint-disable eqeqeq */
import '../../styles/lk.css'
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import LKHeader from './custom_views/LKHeader';
import LKReviews from './custom_views/LKReviews';
import LKBalance from './custom_views/LKBalance';
import PlannersList from '../experts/custom_views/PlannersList';
import LKSections from './custom_views/LKSections';
import Toast from '../profile/custom_views/Toast';
import { isUserAuthed } from '../../utils/CookieHelper';
import CreateAdsAuthButtons from '../ads_desk/CreateAds/custom_views/CreateAdsAuthButtons';
import LKOther from './custom_views/LKOther';
import LKAbout from './custom_views/LKAbout';
import { Pages, getIsDesktop } from '../../utils/Utils';
import ProAvailable from '../ads_desk/AdsBoard/custom_views/ProAvailable';
import LKProActive from './custom_views/LKProActive';
import LKCreateVideoCall from './custom_views/LKCreateVideoCall';
import UploadYoutubeVideoDialog from '../profile/dialogs/UploadYoutubeVideoDialog';



function UserLK(props) {
    const [toastText, setToastText] = useState("")
    const [isShowUploadYoutube, setShowUploadYoutube] = useState(false)

    const user = props.user
    const isAuthed = isUserAuthed()
    const isDesktop = getIsDesktop()
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const getAdvices = () => {
        return(
            <a rel="noreferrer" href={Pages.advices_profile} target="_blank">
                <div style={isDesktop ? {margin : '0 0 12px 0'} : {}} className="lk_advices_profile hover">
                    <div>Советы по заполнению профиля 🤓</div>
                    <p>Читать в статье</p>
                </div>
            </a>
        )
    }
    const getNotAuthedHtml = () => {
        return(
            <div className="lk_not_authed">
                <div className="lk_not_authed_title">Приветствуем вас 👋</div>
                <CreateAdsAuthButtons getMyInfo={props.getMyInfo}/>
            </div>
        )
    }
    const isPro = user.is_pro
    return (
        <div className="selected_profile_container lk_container">  
            <div className="lk_container_inner">
                <div className="lk_info_desktop">
                    {isAuthed ? <div>
                        <LKHeader setShowUploadYoutube={setShowUploadYoutube} setToastText={showToast} user={user}/>
                        <LKCreateVideoCall />
                        <PlannersList />
                        
                        {(!isPro && !isDesktop && user.is_repetitor) && <div style={{marginTop : '12px'}}><ProAvailable config={props.config}/></div>}
                        {(!isDesktop && isPro && user.is_repetitor) && <div style={{marginTop : '12px'}}><LKProActive /></div>}
                        
                        <div className="lk_balance_mobile">
                            <LKBalance getMyInfo={props.getMyInfo} user={user}/>
                        </div>
                        <LKReviews user={user}/>
                        {!isDesktop && getAdvices()}
                    </div> : getNotAuthedHtml()}
                    {isAuthed ? <LKSections user={user}/> : <LKAbout />}
                    <LKOther />
                </div>
                {isAuthed && <div className="lk_balance_desktop">
                    
                    {(isDesktop && !isPro && user.is_repetitor) && <div style={{marginBottom : '12px'}}><ProAvailable /></div>}
                    {(isDesktop && isPro && user.is_repetitor) && <div style={{margin : '-12px 0 12px 0'}}><LKProActive /></div>}
                    {isDesktop && getAdvices()}
                    <LKBalance getMyInfo={props.getMyInfo} user={user}/>
                </div>}
            </div>
            <UploadYoutubeVideoDialog getMyInfo={props.getMyInfo} user={props.user} updateUserInfo={props.updateUserInfo} isShow={isShowUploadYoutube} setShow={setShowUploadYoutube}/>

            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
        </div>
    );
}

export default UserLK;

