import back_arrow from '../../../img/experts/back_arrow.svg'
import not_found_experts from '../../../img/experts/not_found_experts.svg'
import { useState, useEffect } from 'react';
import SearchExperts from './SearchExperts';
import Loader from '../../../custom_views/Loader';
import { getRequestUrl, getHttpParams, getUrlParameter, getIsDesktop, Pages } from '../../../utils/Utils';
import { getRequestParams } from '../../../utils/CookieHelper';
import ExpertItem from '../list_items/ExpertItem';
import { useHistory } from "react-router-dom";

function Specializations(props) {
    const getSpecs = () => {
        return getUrlParameter("id") == "0" ? props.specializations : props.consultants
    }
    const [searchText, setSearchText] = useState("")
    const [experts, setExperts] = useState(getSpecs())
    const [specs, setSpecs] = useState()
    const [page, setPage] = useState(getUrlParameter("id"))
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        const text = getUrlParameter("text")
        if (text != null && text != undefined && text != ""){
            setSearchText(text)
            searchExpert(text)
        }
     }, [setSearchText])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (props.specializations && props.specializations.length > 0 && (specs == undefined || specs.length == 0)){
            setSpecs(getSpecs())
        }
    }, [props.specializations])
    const history = useHistory()
    const onSpecClick = (text) => {
        history.push(Pages.specializations + "?id=" + getUrlParameter("id") + "&text=" + text)
        searchExpert(text)
    }
    const searchExpert = (text) => {
        setSearchText(text)
        if (text == ""){
            setExperts([])
            return
        }
        setLoading(true)
        var params = getRequestParams()
        params.text = text
        console.log("searchExperts text = " + text)
        fetch(getRequestUrl() + "searchExperts", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("searchExperts = " + JSON.stringify(responseData))
            if (responseData.response === 1){
                setExperts(responseData.results)
            }
            setLoading(false)
        })
    }
    const changeCategory = (id) => {
        setPage(id)
        setSpecs(id == 0 ? props.specializations : props.consultants)
    }
    
    const onBack = () => {
        history.goBack()
    }
    
    const isDesktop = getIsDesktop()
    return (
        <div className={isDesktop ? "search_experts_desktop" : "selected_theme"}>
            <div className="flex">
                <div className="arrow_left_theme">
                    <div className="flex">
                        <img onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>
                        <p className="theme_title"></p>
                    </div>
                </div>
                <div className="flex">
                    <p style={page == 0 ? {color : 'black'} : {}} onClick={() => changeCategory(0)} className="find_spec_title experts_spec_title hover">Репетиторы и преподаватели</p>
                    <p style={page == 1 ? {color : 'black'} : {}} onClick={() => changeCategory(1)} className="find_spec_title experts_spec_title hover">Консультанты</p>
                    {/* <p style={getUrlParameter("id") == "2" ? {color : 'black'} : {}} onClick={() => changeCategory(2)} className="find_spec_title hover">Знаменитости</p> */}
                </div>
                
            </div>
            <SearchExperts isDisabledOpen={true} style={isDesktop ? {margin : '0 16px 29px 16px'} : {}} setShowBookmarks={props.setShowBookmarks} isFocused={true} text={searchText} setText={searchExpert}/>
            {isLoading ? <Loader /> : 
            <div className="exprets_in_theme">
                {(searchText != "" && experts.length > 0) && experts.map((expert, index) => (
                    <ExpertItem myUser={props.user} setSelectedProfile={props.setSelectedProfile} index={index} key={index} user={expert}/>
                ))}
                {(experts.length == 0 && searchText != "") && 
                <div className="not_found_experts_cont"><img className="not_found_experts" src={not_found_experts}/></div>}
                {searchText == "" && 
                    <div className="specs_search">
                        {specs != undefined && specs.map((spec, index) => (
                            <div onClick={() => onSpecClick(spec)} className="spec_search_text hover" key={index}>
                                <p className="subject_list_item">{spec}</p>
                            </div>
                        ))}
                    </div>
                }
            </div>}
        </div>
    )
}

export default Specializations;
