/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';

const CallTimer = (props) => {
    const [seconds, setSeconds] = useState(0)
    const [isActive, setIsActive] = useState(false)
    const [spentMoney, setSpentMoney] = useState(0)
    const [startTime, setStartTime] = useState(new Date()/1000)
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
                props.setStartCallTime(new Date())
                setStartTime(new Date()/1000)
            }
        }, [])


    useEffect(() => {
        let interval = null;
        if (isActive) {
        interval = setInterval(() => {
            //const s = seconds + 1
            const s = Math.round(new Date()/1000 - startTime)
            setSeconds(seconds => s );
            callPrice(s)
            if (props.isCallPaused){
                props.setPauseSec(sec => sec + 1)
            }
        }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);
    const checkRepetitorResult = props.checkRepetitorResult
    const secondsToTime = (seconds) => {
        var time = "00:00"
        if (seconds < 3600){
            time = new Date(seconds * 1000).toISOString().substring(14, 19)
        } else {
            time = new Date(seconds * 1000).toISOString().substring(11, 16)
        }
        return time
    }
    const callPrice = (sec) => {
        // const userToCalcPrice = props.userToCalcPrice
        // if (!props.isCallPaused && sec > checkRepetitorResult.count_free_time*60 && sec > props.prepay){
        //     const moneyTime = sec - (checkRepetitorResult.count_free_time*60) - props.pauseSec - 10
        //     var price = parseInt((moneyTime >= 0 ? moneyTime : 0) * userToCalcPrice.rub_in_hour / 3600)
        //     setSpentMoney(price)
        //     if (!props.isIRepetitor && (props.user.count_rub - spentMoney) <= 0){
        //         props.onNotEnougthMoney()
        //     }
        // }
        const standart_free_time = checkRepetitorResult.count_free_time*60 + 10
        const userToCalcPrice = props.userToCalcPrice
        const free_time_seconds = standart_free_time + props.pauseSec
        const priceWithComission = Math.round(userToCalcPrice.rub_in_hour * (1 - props.config.comission))
        const rub_in_seconds = props.isIRepetitor ? priceWithComission / 3600 : userToCalcPrice.rub_in_hour / 3600
        const prepay = props.prepay
        var earnRub = 0
        if (!props.isCallPaused){
            if(props.isIRepetitor){
                earnRub = 0 - Math.round(free_time_seconds * rub_in_seconds) + Math.round(rub_in_seconds * sec);
            } else{
                earnRub = 0 - Math.round(free_time_seconds * rub_in_seconds) - parseInt(prepay) + Math.round(rub_in_seconds * sec);
            }
            if (earnRub < 0){
                earnRub = 0
            }
            setSpentMoney(earnRub)
            
            if (!props.isIRepetitor && (props.user.count_rub - spentMoney) <= 0 && sec >= free_time_seconds && prepay <= (Math.round(rub_in_seconds * sec))){
                props.onNotEnougthMoney()
            }
        }
    }
    if ((checkRepetitorResult.count_free_time*60 + 10 + props.pauseSec) > seconds){
        return(
            <div style={props.isInterview ? {zIndex : '99999999', bottom : '42px', background : '#31F8C9'} : {background : '#31F8C9'}} className="price_call_item center_horizontal">
                <p style={{color : 'black'}} className="chat_message_price_msg">{secondsToTime((checkRepetitorResult.count_free_time*60 + 10 + props.pauseSec) - seconds)}</p>
                <p style={{color : 'black'}} className="chat_message_price_balance">Бесплатное время</p>
            </div>
        )
    }
    return (
        <div style={props.isInterview ? {zIndex : '99999999', bottom : '42px'} : {}} className="price_call_item center_horizontal">
            <p className="chat_message_price_msg">{secondsToTime(seconds)}</p>
            <p className="chat_message_price_balance">Баланс {parseInt(props.isIRepetitor ? (props.user.count_rub + spentMoney) : (props.user.count_rub - spentMoney))}₽</p>
        </div>
    )
}

export default CallTimer;