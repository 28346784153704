import { SeoTagName } from "../pages/experts/utils/ExpertsUtils";
import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";
export class SeoApi {
    
    static getSeoKeywordsByFeatures(tags, onSuccess, onError) {
        var params = {}
        params[SeoTagName.city] = tags.get(SeoTagName.city) 
        params[SeoTagName.metro] = tags.get(SeoTagName.metro) 
        params[SeoTagName.specialization] = tags.get(SeoTagName.specialization) 
        params[SeoTagName.tag] = tags.get(SeoTagName.tag) 
        fetch(getRequestUrl() + "getSeoKeywordsByFeatures", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getSeoKeywordsByFeatures response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getRandomSeoKeywords(tags, onSuccess, onError) {
        var params = {}
        params[SeoTagName.city] = tags.get(SeoTagName.city) 
       
        fetch(getRequestUrl() + "getRandomSeoKeywords", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getRandomSeoKeywords response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    
}