import { useState, useEffect } from 'react';
import radio_on from '../../../../img/create_ads/radio_on.svg'
import radio_off from '../../../../img/create_ads/radio_off.svg'
function CreateAdsRadioGroup(props) {
    const [selectedIndex, setSelectedIndex] = useState(0)
   
    useEffect(() => {
        for (let index = 0; index < props.items.length; index++) {
            const element = props.items[index]
            if (element == props.selectedItem){
                setSelectedIndex(index)
                break
            }
        }
    }, [props.selectedItem])
    const itemSelected = (item, index) => {
        props.setSelectedItem(item, index)
        // setTimeout(function() {
        //     props.onNext()
        // }, 1000)
        
    }
    return (
        <div className="relative">
            {props.items.length > 0 && props.items.map((item, index) => (
                <div onClick={() => itemSelected(item, index)} className="hover create_ads_radio_container" key={index}>
                    <img className="create_ads_radio_icon" src={selectedIndex == index ? radio_on : radio_off}/>
                    <div className="create_ads_radio_title">{item}</div>
                </div>
            ))}
        </div>
    );
}

export default CreateAdsRadioGroup;
