import { Pages } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import WriteToDevelopersDialog from '../dialogs/WriteToDevelopersDialog';
import { useState } from 'react';

function LKOther(props) {
    const [isShowWriteToDev, setShowWriteToDev] = useState(false)


    const getItem = (title, onClick) => {
        return(
            <div onClick={onClick} className="lk_section_item hover">
                <div>{title}</div>
            </div>
        )
    }
    return (
        <div className="lk_other lk_other_bottom">
            <div className="lk_other_title">Другое</div>
            <a href={Pages.support} target="_blank">
                <div className="lk_section_item hover">
                    <div>Часто задаваемые вопросы</div>
                </div>
            </a>
            <a href={Pages.vk_group} target="_blank">
                <div className="lk_section_item hover">
                    <div>Техподдержка</div>
                </div>
            </a>
            <Link to={Pages.rules}>
                <div className="lk_section_item hover">
                    <div>Правила сервиса</div>
                </div>
            </Link>
            <Link to={Pages.settings}>
                <div className="lk_section_item hover">
                    <div>Настройки</div>
                </div>
            </Link>
            {/* {getItem("Часто задаваемые вопросы", () => {
                window.open(Pages.support, '_blank')
            })}
            {getItem("Техподдержка", () => {
                window.open(Pages.vk_group, '_blank')
            })}
            {getItem("Правила сервиса", () => {
                history.push(Pages.rules)
            })}
            {getItem("Настройки", () => {
                history.push(Pages.settings)
            })} */}
            {getItem("Написать разработчикам", () => {
                setShowWriteToDev(true)
            })}
            <WriteToDevelopersDialog isShow={isShowWriteToDev} setShow={setShowWriteToDev}/>
        </div>)
}

export default LKOther;