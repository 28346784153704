import { useHistory, Link } from "react-router-dom";
import React, {useCallback} from 'react';
function BottomMenuItem(props) {
    const isSelected = window.location.pathname.includes(props.link)
    const history = useHistory()
    const handleOnClick = () => {
        props.setCurrentLink(props.link)
        //history.push(props.link)
    }
    const unreadedMsgs = props.unreadedMsgs
    return (
        <Link to={props.link}>
            <div onClick={handleOnClick} className="bottom_menu_item hover">
                <div className="left_item_icon">
                    <div style={{height : '24px', width : '24px'}} className="relative">
                        <img style={isSelected ? {} : {opacity : 0.2}} className={props.isProfile ? "bottom_menu_profile" : "bottom_menu_icon"} src={props.icon}/>
                        {(unreadedMsgs != undefined && unreadedMsgs > 0) && <div className="round_msg_red"></div>}
                    </div>
                </div>
                
            
                <p className="bottom_menu_title">{props.title}</p>
            </div>
        </Link>
    );
}

export default BottomMenuItem;

