/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */


import * as SDK from '@vkontakte/calls-sdk'
import React, { useState, useEffect, useRef } from 'react';
import { getApiKey, declension, getIsDesktop, getUrlParameter, getOS } from '../../utils/Utils';
import '../../styles/call.css'
import CallNavbar from './custom_views/CallNavbar';
import CallBottomBar from './custom_views/CallBottomBar';
import avatar from '../../img/avatar.svg'
import stop_screen_share from '../../img/call/stop_screen_share.svg'
import accept_call from '../../img/call/accept_call.svg'
import decline_call from '../../img/call/decline_call.svg'
import CallTimer from './custom_views/CallTimer';
import CallInfoBottomSheet from './dialogs/CallInfoBottomSheet';
import MoneyFinishedBottomSheet from './dialogs/MoneyFinishedBottomSheet';
import { useHistory } from "react-router-dom";
import { DialogsApi } from '../../api/DialogsApi';
import { CallApi } from '../../api/CallApi';
import SafariScreenShareScreen from './dialogs/SafariScreenShareSheet';
import CloseCallBottomSheet from './dialogs/CloseCallBottomSheet';
import ChangeRole from './dialogs/ChangeRole';
import call_sound from '../../sound/call_sound.mp3'
import out_call_sound from '../../sound/out_call_sound.mp3'
import SafariIncomingScreenShare from './dialogs/SafariIncomingScreenShare';
import VideoStreams from './custom_views/VideoStreams';
import CantPauseDialog from './dialogs/CantPauseDialog';
// import { VideoEffects } from '@vkontakte/calls-video-effects';
import ScreenShareErrorDialog from './dialogs/ScreenShareErrorDialog';
import { callRemoteChange } from './utils/CallUtils';
import CallCameraErrorDialog from './dialogs/CallCameraErrorDialog';
import CallMicErrorDialog from './dialogs/CallMicErrorDialog';
import { getCookie } from '../../utils/CookieHelper';

var declineCallPressed = 0
var reconnectTry = 0
var isOutSound = false
 var localStream = undefined
    var remoteStream = undefined
    var screenStream = undefined
    var remoteScreenStream = undefined
    var isBoardEnabledField = false
function Call(props) {
    const urlParamIsVideo = getUrlParameter("is_video")
    const isVideoOnFromUrl = urlParamIsVideo != null && urlParamIsVideo != undefined && urlParamIsVideo != ""
    const [vkAuth, setVkAuth] = useState(undefined)
    const [dialogInfo, setDialogInfo] = useState(undefined)
    const [isIRepetitor, setIRepetitor] = useState(props.callerUser != undefined ? props.callerUser.is_i_repetitor : false)
    const bigVideoRef = useRef(null);
    const smallVideoRef = useRef(null);
    const smallVideoOpponent = useRef(null);
    const [isCallAccepted, setCallAccepted] = useState(false)
    const [isCallPaused, setCallPaused] = useState(false)
    const [isBoardEnabled, setBoardEnabled] = useState(false)
    const [isMuted, setMuted] = useState(false)

    const [isShowCallInfo, setShowCallInfo] = useState(false)
    const [isScreenShareEnabled, setScreenShareEnabled] = useState(false)
    const [isShowMoneyFinished, setShowMoneyFinished] = useState(false)
    const [cameras, setCameras] = useState([])
    const [startCallTime, setStartCallTime] = useState(0)
    const [pauseSec, setPauseSec] = useState(0)
    const [isVideoOn, setVideoOn] = useState(isVideoOnFromUrl)
    const [isShowSafariScreenShare, setShowSafariScreenShare] = useState(false)
    const [isShowCloseCall, setShowCloseCall] = useState(false)
    const [isShowChangeRole, setShowChangeRole] = useState(false)
    const [isShowSafariIncomingScreenShare, setShowSafariIncomingScreenShare] = useState(false)
    const [acceptCallPressed, setAcceptCallPressed] = useState(false)
    const [hasUnreadedMsgs, setHasUnreadedMsgs] = useState(false)

    const [isInterview, setInterview] = useState(false)
    const [aspectRatio, setAspectRatio] = useState(1)
    const [prepay, setPrepay] = useState(0)
    const [isShowCantPause, setShowCantPause] = useState(false)
    const [conversation, setConversation] = useState({})

    const [isShowScreenShareError, setShowScreenShareError] = useState(false)
    const [isShowMicError, setShowMicError] = useState(false)
    const [isShowCameraError, setShowCameraError] = useState(false)
    const [isMicOn, setMicOn] = useState(true)
    const [checkRepetitorResult, setCheckRepetitor] = useState(props.checkRepetitorResult ? props.checkRepetitorResult : {})
   
    const callOptions = [SDK.MediaOption.AUDIO, SDK.MediaOption.VIDEO, SDK.MediaOption.SCREEN_SHARING]
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const config = props.config
    const socketMsg = props.socketMsg
    
    useEffect(() => {
        if (vkAuth == undefined){
            // if (isSupported()) {
               
            // }

            // SDK.setVideoEffects(new VideoEffects());
            const auth = getStandartVkInit()
            setVkAuth(auth)
            console.log("vkAuth init = ", vkAuth)
            if (props.callToUser != undefined && props.callToUser.id != "-1" && auth != undefined){
                startCall()
                checkRepetitor()
            }
     
        }
        
    }, [vkAuth])
   
    useEffect(() => {
        if (dialogInfo == undefined){
            getDialogInfo()
        }
    }, [setDialogInfo])
    useEffect(() => {
        props.getMyInfo()
    }, [])
    useEffect(() => {
        if (props.prepay != 0){
            setPrepay(props.prepay)
        }
    }, [props.prepay])
    // useEffect(() => {
    //     const isOpenedChatMessage = currentChat != undefined && (streams.filter(s => s.user.id + "_" + getCookie("id") == socketMsg.dialog_id || getCookie("id") + "_" + s.user.id == socketMsg.dialog_id).length > 0)
    //     if (socketMsg != undefined && socketMsg.event === "message" && isOpenedChatMessage){
    //         if (socketMsg.to_id == getCookie("id") && !isShowCallChat){
    //             setHasUnreadedMsgs(true)
    //         }
         
            
    //     }
      
    // }, [socketMsg])
    useEffect(() => {
        if (props.callerUser != undefined){
            CallApi.sendSignalCall(props.callerUser.vk_caller_id, (response) => {
                console.log("sendSignalCall = ", JSON.stringify(response))
            })
        }
    }, [props.callerUser])
    const openChat = () => {
        if (dialogInfo != undefined){
            // console.log("dialogInfo openChat = " + JSON.stringify(dialogInfo))
            props.setCurrentChat(dialogInfo)
            // history.push("/call/chat?id=" + dialogInfo.dialog._id)
            if (isDesktop){
                props.setShowCallChat(!props.isShowCallChat)
            } else {
                history.push("/call/chat?id=" + dialogInfo.dialog._id)
            }
        }
    }
  
    useEffect(() => {
        const socketMessage = props.socketMessage
        if (socketMessage != undefined && socketMessage.event == "endCallEvent"){
            console.log("call screen endCallEvent")
            checkNeedWriteReview(socketMessage, props.callToUser.vk_caller_id)
            declineCall()
            closeCall()
        }
        if (socketMessage != undefined && socketMessage.event == "pauseCallEvent"){
            setCallPaused(socketMessage.is_pause_enable)
        }

        if (socketMessage != undefined && socketMessage.event == "changeDialogRepetitor"){
            checkRepetitor()
        }
        if (socketMessage != undefined && socketMessage.event == "declineCall"){
            if (props.callerUser != undefined){
                props.setShowDeclinedCall(true)
            }
            declineCall()
            props.setCallToUser(undefined)
            props.setCallerUser(undefined)
        }     
        if (socketMessage != undefined && socketMessage.event == "acceptCall"){
            if (!acceptCallPressed){
                props.setCallToUser(undefined)
                props.setCallerUser(undefined)
            }
        }   
    }, [props.socketMessage])
    const closeCall = () => {
        if (props.setCallerUser){
            props.setCallerUser(undefined)
        }
        // if (isIRepetitor && pauseSec > 0){
        //     refundMoneyFromExpert()
        // }
        props.setCallToUser(undefined)
        props.getMyInfo()
    }
    if (props.callToUser == undefined){
        return(<div></div>)
    }

    const checkRepetitor = () => {
        DialogsApi.checkRepetitor(props.callToUser.vk_caller_id, (responseData) => {
            const is_i_repetitor = responseData.id_student == props.callToUser.vk_caller_id
            setIRepetitor(is_i_repetitor)
            setCheckRepetitor(responseData)
            getActingPlanner(responseData.id_repetitor, responseData.id_student)
        })
    }
    const getActingPlanner = (id_repetitor, id_student) => {
        CallApi.get_acting_planner_by_members(id_repetitor, id_student, (responseData) => {
            if (responseData.planner && responseData.planner.prepay){
                setPrepay(responseData.planner.prepay)
            }
        })
    }
   
    const getDialogInfo = () => {
        const isIncomingCall = props.callToUser.id == "-1"
        DialogsApi.getDialogInfo((isIncomingCall ? props.callerUser.vk_caller_id : props.callToUser.vk_caller_id), (responseData) => {
            var chat = {user : isIncomingCall ? props.callerUser : props.callToUser}
            chat.dialog = responseData.dialogInfo
            setDialogInfo(chat)
        })
    }
    // const getStandartVkInitasd = async () => {
    //     console.log("getStandartVkInit")
    //     await SDK.init({

    //         // Ключ приложения, полученный при регистрации
    //         apiKey: getApiKey(),
        
    //         // Пользовательский токен авторизации
    //         authToken: props.user.vk_call_token,
        
    //         // Получен локальный стрим с камеры
    //         onLocalStream: (stream, mediaSettings) => {
    //             playVideo(bigVideoRef, stream)
    //             localStream = stream
    //         }, 
    //         onScreenStream : (stream, mediaSettings) => {
    //             screenStream = stream
    //             if (screenStream != undefined){
    //                 playVideo(smallVideoRef, screenStream)
    //             } else {
    //                 playVideo(smallVideoRef, localStream)
    //                 playVideo(bigVideoRef, remoteStream)
    //             }
    //             setScreenShareEnabled(mediaSettings.isScreenSharingEnabled)
    //         },
    //         onLocalStatus(status){
    //             console.log('onLocalStatus status = ', status);
    //         },
    //         onRemoteScreenStream: (useriId, stream) => {
    //             remoteScreenStream = stream
    //             if (remoteScreenStream != undefined){
    //                 playVideo(bigVideoRef, remoteScreenStream)
    //                 //setShowSafariIncomingScreenShare(true)
    //                 //проверить
    //             } else {
    //                 playVideo(bigVideoRef, remoteStream)
    //             }
    //         },
    //         onRemoteStream: (useriId, stream) => {                
    //             remoteStream = stream
    //             if (screenStream == undefined){
    //                 playVideo(smallVideoRef, localStream)
    //             }
    //             playVideo(bigVideoRef, stream)
    //             setCallAccepted(true)
    //         },
    //         onStatistics: (stats) => {
    //             console.log('onStatistics', stats);
    //         },
    //         onRemoteStatus(userIds, status, data) {
    //             console.log('onRemoteStatus status = ', status);
    //         },
           
    //     });
        
    //     // Авторизуемся в API
    //     await SDK.authorize();
    //     await SDK.callTo("1685183100270");
    //     console.log("startCall props.callerUser.vk_caller_id = ", props.callerUser.vk_caller_id)
    // }
    const playVideo = (ref, stream) => {
        let video = ref.current
        if (video == undefined){
            return
        }
        if (ref == smallVideoRef){
            if (video != undefined){
                video.defaultMuted = true
                video.muted = true
            }
        } else if (ref == bigVideoRef) {
            video.addEventListener("resize", function () {
                const height = this.videoHeight
                const width = this.videoWidth
                setAspectRatio(height/width)
                console.log("setAspectRatio height/width = ", (height/width))
            }, false)
          
        }
        if (stream != undefined && stream != null){
            console.log("playVideo stream = ", stream)
            try {
                video.srcObject = stream
                video.play()                    
            } catch {
                console.log("play video error")
            }
              
        }  
    }
    const getStandartVkInit = () => {
        console.log("SDK version = ", SDK.version())
        return SDK.init({
            apiKey: getApiKey(),
            authToken: props.user.vk_call_token,
            onLocalStream: (stream, mediaSettings) => {
                playVideo(bigVideoRef, stream)
                localStream = stream
            }, 
            onScreenStream : (stream, mediaSettings) => {
                console.log('onScreenStream mediaSettings = ', mediaSettings);
                
                screenStream = stream
                // if (screenStream != undefined){
                //     //playVideo(smallVideoRef, screenStream)
                // } else {
                //     playVideo(smallVideoRef, localStream)
                //     playVideo(bigVideoRef, remoteStream)
                // }
                if (mediaSettings.isScreenSharingEnabled){
                    playVideo(smallVideoRef, screenStream)        
                } else {
                    playVideo(smallVideoRef, localStream)
                    playVideo(bigVideoRef, remoteStream)
                }
                // if (mediaSettings.isScreenSharingEnabled){
                //     playVideo(smallVideoRef, stream)
                // } else {
                //     playVideo(smallVideoRef, localStream)
                //     playVideo(bigVideoRef, remoteStream)
                // }
                setScreenShareEnabled(mediaSettings.isScreenSharingEnabled)
            },
            onLocalStatus(status){
                console.log('onLocalStatus status = ', status);
            },
            onRemoteMediaSettings: (userId, mediaSettings) => {
                console.log('onRemoteMediaSettings status = ', mediaSettings);
                console.log('onRemoteMediaSettings userId = ', userId);
                //  if (mediaSettings.isVideoEnabled || mediaSettings.isScreenSharingEnabled) {
                //     SDK.updateDisplayLayout([{
                //         uid: userId.id,
                //         mediaType: mediaSettings.isScreenSharingEnabled ? SDK.MediaType.SCREEN : SDK.MediaType.CAMERA,
                       
                //     }]);
                // }
                
                callRemoteChange(mediaSettings, isBoardEnabledField)
            },
            onRemoteScreenStream: (useriId, stream) => {
                console.log('onRemoteScreenStream useriId = ', useriId);
                remoteScreenStream = stream
                if (remoteScreenStream != undefined){
                    playVideo(bigVideoRef, remoteScreenStream)
                   
                    console.log("onRemoteScreenStream remoteStream = ", remoteStream)
                    if (remoteStream != undefined){
                        playVideo(smallVideoOpponent, remoteStream)
                    } 
                    //setShowSafariIncomingScreenShare(true)
                    //проверить
                } else {
                    playVideo(bigVideoRef, remoteStream)

                }
            },
            onRemoteStream: (useriId, stream) => {   
                console.log('onRemoteStream useriId = ', useriId);             
                remoteStream = stream
                if (screenStream == undefined){
                    playVideo(smallVideoRef, localStream)
                } 
                if (stream){
                    playVideo(bigVideoRef, stream)
                }
                
                setCallAccepted(true)
            },
            onStatistics: (stats) => {
                console.log('onStatistics', stats);
            },
            onCallState: (isCallActive, canAddParticipants, conversation) => {
                console.log('onCallState conversation = ', conversation);
                console.log('onCallState isCallActive = ', isCallActive);
                setConversation(conversation)
                setCallAccepted(isCallActive)
                if (isCallActive){
                    stopOutSound()
                }
                
            },
            // onConversation: (userId, mediaModifiers, muteStates, participants) => {
            //     console.log('onConversation userId = ', userId);
            //     console.log('participants = ', participants);
            //     const layouts = participants
            //         .filter(({mediaSettings}) =>
            //             mediaSettings.isVideoEnabled || mediaSettings.isScreenSharingEnabled)
            //         .map(({uid, mediaSettings}) => ({
            //             uid,
            //             mediaType: mediaSettings.isScreenSharingEnabled ? SDK.MediaType.SCREEN : SDK.MediaType.CAMERA
            //         }));

            //     SDK.updateDisplayLayout(layouts);
            // }
          
        })
        .then(() => {
            return SDK.authorize();
        })
        // .catch(() => {
        //     console.error(arguments);
        // })
        
        
    }
   
    const startCall = async () => {
        console.log("call props.callToUser.vk_caller_id = ", props.callToUser.vk_caller_id)
        // vkAuth.then(() => {
        //     return SDK.callTo(props.callerUser.vk_caller_id, callOptions)
        // })
        // .then(() => {
        //     return SDK.toggleLocalVideo(isVideoOn)
        // })
        // .then(result => {
        //     setCameras(SDK.browser.getCameras())
        // })
        // .catch(() => {
        //     console.log("startCall error = ", arguments);
        //     closeCall()
        // })
        playOutSound()
        try {
            await SDK.callTo(props.callToUser.vk_caller_id, callOptions)
            await SDK.toggleLocalVideo(isVideoOn)
            setCameras(SDK.browser.getCameras())
        
        } catch (error) {
            reconnectTry += 1
            if (reconnectTry > 4){
                closeCall()
            } else {
                startCall()
            }
            
        }
        setSavedMic()   
    }
    const setSavedMic = async () => {
       
        const mic = SDK.browser.getSavedMicrophone() != null ? SDK.browser.getSavedMicrophone() : SDK.browser.getMicrophones().length > 0 ? SDK.browser.getMicrophones()[0] : null
        if (mic != null){
            try {
                await SDK.changeDevice(mic.kind, mic.deviceId)
            } catch (error) {
                console.log(error)
            }
           
            setMicOn(true)
            try {
                await SDK.toggleLocalAudio(true)
            } catch (error) {
                setMicOn(false)
                if (getOS() == "macos"){
                    setShowMicError(true)
                }
            }
           
            console.log("setSavedMic = ", mic)
        }
    }
    const acceptCall = () => {
        props.setCallToUser(props.callerUser)
        stopOutSound()
        vkAuth.then(() => {
            return SDK.callTo(props.callerUser.vk_caller_id, callOptions)
        })
        .then(result => {
            setCameras(SDK.browser.getCameras())
        })
        .then(() => {
            return SDK.toggleLocalVideo(isVideoOn)
        })
        .catch(() => {
            console.error(arguments);
        })
        setSavedMic(vkAuth)
        setAcceptCallPressed(true)
        console.log("props.callToUser.socketMessage.call_id = ", props.callToUser.socketMessage.call_id)
        CallApi.acceptCall(props.callToUser.socketMessage.call_id, (response) => {

        })
    }
    const declineCall = () => {
        if (props.isShowCallChat){
            props.setShowCallChat(!props.isShowCallChat)
        }
        
        vkAuth.then(() => {
            return SDK.hangup()
        })
        .catch(() => {
            console.error(arguments);
        })
        if (declineCallPressed == '1'){
            closeCall()
        }
        declineCallPressed += 1
    }
    const stopIncommingCall = () => {
        CallApi.declineCall(callerUser.vk_caller_id, (responseData) => {
            props.setCallToUser(undefined)
            props.setCallerUser(undefined)
        })
        vkAuth.then(() => {
            return SDK.declineCall()
        })
        .catch(() => {
            console.error(arguments);
        })
    }
    const changeScreenShare = async (isEnabled) => {
        // if (window.safari !== undefined){
        //     setShowSafariScreenShare(true)
        //     return
        // }
        console.log("captureScreen = " + isEnabled)
        try {
            await SDK.captureScreen(isEnabled)
            if (!isEnabled){
                playVideo(smallVideoRef, localStream)
                playVideo(bigVideoRef, remoteStream)
            }
        } catch (error) {
            console.log("error = ", error)
            setScreenShareEnabled(false)
            if (getOS() == "macos"){
                setShowScreenShareError(true)
            }
        }
        
        
    }
    const toggleVolume = (isEnabled) => {
        let video = bigVideoRef.current
        if (isEnabled){
            video.volume = 1
        } else {
            video.volume = 0
        }
    }
    const onNotEnougthMoney = () => {
        console.log("onNotEnougthMoney")
        setShowMoneyFinished(true)
        // closeMoneyFinished()
        
        
    }
    const stopOutSound = () => {
        var audio = document.getElementById("out_audio");
        audio.volume = 0
        audio.pause()
        isOutSound = false
    }
    const playOutSound = () => {
        var audio = document.getElementById("out_audio");
        audio.volume = 0.2
        audio.play()
        isOutSound = true
    }
    const toggleBoard = (isEnabled) => {
        isBoardEnabledField = isEnabled
        setBoardEnabled(isEnabled)
        if (!isEnabled){
            window.document.getElementsByClassName("call_screen_mini_2_container")[0].setAttribute('style', 'display : none; visibility : collapse')
        } else {
            const rect = window.document.getElementsByClassName("call_screen_mini_1_container")[0].getBoundingClientRect()
            window.document.getElementsByClassName("call_screen_mini_2_container")[0].setAttribute('style', 'display : block; top : ' + (rect.bottom + 8) + 'px;')
            playVideo(smallVideoOpponent, remoteStream)
        }
    }
    const closeMoneyFinished = () => {
        setShowMoneyFinished(false)
        props.setCallerUser(undefined)
        props.setCallToUser(undefined)
        props.getMyInfo()
        vkAuth.then(() => {
            return SDK.hangup()
        })
        .catch(() => {
            console.error(arguments);
        })
    }
    const checkNeedWriteReview = (socketMessage, id) => {
        const isReadyToShow = (startCallTime/1000 + props.config.min_time_for_review * 60) <= (new Date() / 1000)
        console.log("socketMessage.spent_time = ", socketMessage.spent_time)
        if (socketMessage.spent_time < 1){
            return
        }
        if (isReadyToShow){
            if (!isIRepetitor){
                props.setWriteReview({isShow : true, price : socketMessage.spent_money, time : socketMessage.spent_time, to_id : id})
            } else {
                props.setWriteReview({isShow : false, isShowToRepetitor : true , price : socketMessage.spent_money, time : socketMessage.spent_time, to_id : id})
            }
        }
    }
    
    
    const isIncomingCall = props.callToUser.id == "-1"
    const callerUser = props.callerUser
    const isDesktopChat = isDesktop && props.isShowCallChat

    const incomingCallScreen = () => {
        return(
            <div className="incoming_call_bg">
                <img className="caller_avatar" src={callerUser.avatar ? callerUser.avatar : avatar} alt=""/>
                <p className="caller_price_title">Стоимость</p>
                <p className="caller_price_text">{callerUser.is_i_repetitor ? "+ " + Math.round(props.user.rub_in_hour*(1-config.comission)) + "₽ в час" : "- " + callerUser.rub_in_hour + "₽ в час"}</p>
                {(!callerUser.is_i_repetitor && callerUser.count_free_time > 0) && <div>
                    <p style={{marginTop : '18px'}} className="caller_price_title">Бесплатное время</p>
                    <p className="caller_price_text">{callerUser.count_free_time <= 0 ? "закончилось" : callerUser.count_free_time + " " + declension(callerUser.count_free_time, "минута", "минуты", "минут")}</p>
                </div>}
                <div className="call_bottom_bar incoming_call_bottom">
                    <img onClick={acceptCall} style={{marginRight : '60px'}} className="incomin_call_bottom_icon hover" src={accept_call} alt=""/>
                    <img onClick={stopIncommingCall} className="incomin_call_bottom_icon hover" src={decline_call} alt=""/>
                </div>
                <audio volume={0.2} style={{display : 'none'}} loop autoPlay={true} id="audio" src={call_sound}></audio>
            </div>
        )
    }
    return (
        <div style={isCallAccepted ? {background : '#2D2535'} : {background: '#2C1444'}} className={isDesktopChat ? "call_screen_container_with_chat" : "call_screen_container"}>
            {(callerUser != undefined || props.callToUser != undefined) && <CallNavbar is_i_creator setShowCloseCall={setShowCloseCall} isCallAccepted={isCallAccepted} declineCall={declineCall} isShowCallInfo={isShowCallInfo} toggleVolume={toggleVolume} vkAuth={vkAuth} cameras={cameras} setShowCallInfo={setShowCallInfo} setCallerUser={props.setCallerUser} setCallToUser={props.setCallToUser} callToUser={!isIncomingCall ? props.callToUser : callerUser}/>}
            
            {(isIncomingCall && callerUser != undefined) && incomingCallScreen()}
            
            <VideoStreams checkRepetitorResult={checkRepetitorResult} isBoardEnabled={isBoardEnabled} isMuted={isMuted} callToUser={!isIncomingCall ? props.callToUser : callerUser} smallVideoOpponent={smallVideoOpponent} isScreenShareEnabled={isScreenShareEnabled} user={props.user} isInterview={isInterview} setInterview={setInterview} isVideoOn={isVideoOn} isCallAccepted={isCallAccepted} isIncomingCall={isIncomingCall} playVideo={playVideo} smallVideoRef={smallVideoRef} bigVideoRef={bigVideoRef} aspectRatio={aspectRatio}/>

            {(props.callToUser != undefined && props.callToUser.id != "-1" && isCallAccepted) && <CallTimer prepay={prepay} isInterview={isInterview} setPauseSec={setPauseSec} pauseSec={pauseSec} setStartCallTime={setStartCallTime} checkRepetitorResult={checkRepetitorResult} onNotEnougthMoney={onNotEnougthMoney} isIRepetitor={isIRepetitor} user={props.user} userToCalcPrice={isIRepetitor ? props.user : props.callToUser} isCallPaused={isCallPaused} callToUser={props.callToUser} config={props.config}/>}
            {!isIncomingCall && <CallBottomBar isBoardEnabled={isBoardEnabled} setBoardEnabled={toggleBoard} is_i_creator isMicOn={isMicOn} setMicOn={setMicOn} setShowMicError={setShowMicError} setShowCameraError={setShowCameraError} conversation={conversation} checkRepetitorResult={checkRepetitorResult} setShowCantPause={setShowCantPause} setShowCloseCall={setShowCloseCall} isVideoOn={isVideoOn} setVideoOn={setVideoOn} isScreenShareEnabled={isScreenShareEnabled} changeScreenShare={changeScreenShare} isShowCallChat={props.isShowCallChat} isCallPaused={isCallPaused} setCallPaused={setCallPaused} isIRepetitor={isIRepetitor} openChat={openChat} vkAuth={vkAuth} declineCall={declineCall} isIncomingCall={isIncomingCall} callToUser={props.callToUser}/>}
            <CallInfoBottomSheet setInterview={setInterview}  setShowChangeRole={setShowChangeRole} count_free_time={checkRepetitorResult.count_free_time} config={config} isScreenShareEnabled={isScreenShareEnabled} changeScreenShare={changeScreenShare} pricesUserInfo={isIRepetitor ? props.user : props.callToUser} isIRepetitor={isIRepetitor} isShowCallInfo={isShowCallInfo} setShowCallInfo={setShowCallInfo}/>
            <MoneyFinishedBottomSheet isShow={isShowMoneyFinished} setShow={closeMoneyFinished}/>
            <SafariScreenShareScreen isShow={isShowSafariScreenShare} setShow={setShowSafariScreenShare}/>
            <SafariIncomingScreenShare isShow={isShowSafariIncomingScreenShare} setShow={setShowSafariIncomingScreenShare}/>
            <CloseCallBottomSheet isShow={isShowCloseCall} setShow={setShowCloseCall} declineCall={declineCall} />
            <CantPauseDialog isShow={isShowCantPause} setShow={setShowCantPause}/>
            <ChangeRole declineCall={declineCall} isShow={isShowChangeRole} setShow={setShowChangeRole} checkRepetitor={checkRepetitor} to_id={props.callToUser.vk_caller_id}/>
            <ScreenShareErrorDialog isShow={isShowScreenShareError} setShow={setShowScreenShareError}/>
            <CallCameraErrorDialog isShow={isShowCameraError} setShow={setShowCameraError}/>
            <CallMicErrorDialog isShow={isShowMicError} setShow={setShowMicError}/>
            {(isDesktop && isScreenShareEnabled) && <div onClick={() => changeScreenShare(false)} className="stop_screen_share hover"><p className="stop_screen_share_text">Идёт демонстрация экрана</p><img className="stop_screen_share_icon" src={stop_screen_share} alt=""/></div>}
            {/* {getCookie("id") == "1685183100270" && <div style={{position : 'fixed', left : '16px', top : '200px', maxWidth : 'calc(100vw - 32px)', wordWrap : 'break-word', zIndex : '999999999', color : 'white'}}>{JSON.stringify(SDK.browser.getCameras())}</div>} */}
            
            <audio style={{display : 'none'}} loop autoPlay={false} id="out_audio" src={out_call_sound}></audio>
        </div>
    );
}

export default Call;
