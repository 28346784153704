/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import back_arrow from '../../../img/experts/back_arrow.svg'
import { useState, useEffect } from 'react';
import Loader from '../../../custom_views/Loader';
import { getRequestUrl, getHttpParams, getUrlParameter, getIsDesktop, calculateAge, declension } from '../../../utils/Utils';
import { getRequestParams, getCookie } from '../../../utils/CookieHelper';
import avatar from '../../../img/avatar.svg'
import other_back from '../../../img/other/other_back.svg'
import verified from '../../../img/profile/verified.svg'
import vk from '../../../img/profile/vk.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useHistory } from "react-router-dom";
import badge_seleb from '../../../img/profile/badge_seleb.svg'
import badge from '../../../img/profile/badge.svg'
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function Verification(props) {
    //const [user, setUser] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [verificationResult, setVerificationResult] = useState({})
    const history = useHistory()
    const token = getCookie("token")
    const isTokenEmpty = token == null || token == undefined || token == ""
    const user = props.user
    const auth = () => {
        window.location.href = "https://oauth.vk.com/authorize?client_id=51494524&display=popup&redirect_uri=https://sozvon.pro/verification&scope=offline&response_type=code&v=5.131"
    }

    const verify = (code) => {
        setLoading(true)
        var params = getRequestParams()
        params.code = code
        console.log("verifiedStarVk params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "verifiedStarVk", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("verifiedStarVk = " + JSON.stringify(responseData))
            setVerificationResult({isSuccess : responseData.response > 0, isShow : true})
            setLoading(false)
        })
    }

    useEffect(() => {
        const code = getUrlParameter("code")
        if (isTokenEmpty){
            window.location.href = "/auth/phone?code=" + code
        } else {
            //getMyInfo()
            if (code != undefined && code != ""){
                verify(code)
            }
        }
    }, [])

    // const getMyInfo = () => {
    //     var params = getRequestParams()
    //     fetch(getRequestUrl() + "getMyInfo", getHttpParams(params)).then((response) => response.json())
    //     .then((responseData) => {
    //         console.log("getMyInfo" + JSON.stringify(responseData))
    //         if (responseData.user){
    //             setUser(responseData.user)
    //             props.setUser(responseData.user)
    //         } 
    //         setLoading(false)
    //     })
    // }
    
    const onBack = () => {
        history.goBack()
    }
    const isDesktop = getIsDesktop()
    if (isTokenEmpty){
        return(<div/>)
    }
    const age = calculateAge(user.bdate)
    const mobileToolbar = () => {
        return(
            <div className="arrow_left_theme">
                <div className="flex">
                    <img onClick={onBack}  className="back_arrow_theme hover" src={back_arrow}/>
                    <p className="theme_title">Верификация</p>
                </div>
            </div>
        )
    }
    const desktopToolbar = () => {
        return(
            <div className="flex relative">
                <img onClick={onBack}  className="profile_ver_back hover" src={other_back}/>
                <p className="profile_ver_title">Верификация</p>
            </div>
        )
    }
    return (
        <div className={isDesktop ? "selected_profile_container_desk" : "selected_profile_container"}>  
            {isDesktop ? desktopToolbar() : mobileToolbar()}
            
            <div className="verification_container">
                {/* <div className="flex">
                    <img style={{margin : '0 16px 0 0'}} className="expert_avatar_img" src={user.avatar ? user.avatar : avatar}/>
                    <p className="review_item_name">{user.name} {user.surname}</p>
                </div> */}
                <div className="flex">
                    <img className="profile_ver_avatar" src={user.avatar ? user.avatar : avatar}/>
                    <div>
                        <div className="flex">
                            <p className="profile_mobile_header_title">{user.name} {user.surname}</p>
                            {(user.is_repetitor || user.verified) && <img style={{marginTop : '11px'}} className="desktop_profile_badge"  src={user.verified ? badge_seleb : badge}/>}
                        </div>
                        <div className="profile_ages">
                            <p className="desktop_profile_desk">{age + " " + declension(age, "год", "года", "лет")}{!user.online && ", сейчас не в сети"}</p>
                            {user.online ? <div className="green_profile_circle"/> :  <div />}
                            {user.online && <p className="green_profile_online">сейчас в сети</p>}
                        </div>
                    </div>
                </div>
                <div className="verification_line"/>
                <div className="profile_ver_center">
                    <img className="verfified_icon" src={verified}/>
                    <p className="verified_title">Привязка профиля Вконтакте</p>
                    <p className="verified_text">Если у вашего профиля Вконтакте есть “галочка”, то после привязки ваш профиль в sozvon.pro автоматически будет верифицирован как профиль знаменитости, и около вашей аватарки появится иконка “звезда”. При этом фамилия и имя в вашем профиле скопируются из Вконтакте и изменить их будет невозможно.</p>
                    <button disabled={isLoading} onClick={auth} className="verify_btn relative hover">
                        {isLoading ? <Loader isWhite/> : <div className="flex">
                            <img className="verify_vk_icon" src={vk}/>
                            <p className="verify_vk_text">привязать</p>
                        </div>}
                    </button>
                </div>
            </div>
            <VerificationResult isSuccess={verificationResult.isSuccess} isShow={verificationResult.isShow}/>
           
        </div>
    )
}

export default Verification;
const VerificationResult = ({ isSuccess, isShow }) => {
    const isDesktop = getIsDesktop()
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                {!isSuccess && <div className="verification_result_title">Не удалось выполнить<br/>верификацию 😔</div>}
                {isSuccess ? <div className="verification_result_text">Ваш профиль успешно верифицирован</div>
                    : <div className="verification_result_text">Ваш профиль ВКонтакте не является верифицированным (у него нет галочки).<br/><br/>Если вы считаете, что тут где-то закралась ошибка, напишите в нашу поддержку.</div>}   
                <div style={{marginTop : '36px'}} className="flex">
                    <div onClick={() => window.location.href = "/experts"} className="call_price_continue hover">Понятно</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}