import { useState, useEffect, useRef } from 'react';
import autosize from 'autosize';
import ProfileInputRaw from './ProfileInputRaw';
import Loader from '../../../custom_views/Loader';
import FilterDropdown from '../../../custom_views/FilterDropdown';
import { ConfigApi } from '../../../api/ConfigApi';

function ProfileSkills(props) {
    const [text, setText] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [serverSpecs, setServerSpecs] = useState([])
    const specializations = props.skills
    const refTextarea = useRef(null)
    useEffect(() => {
        if (props.disabledAutoFocus){

        } else {
            refTextarea.current.focus()
        }
        autosize(refTextarea.current)
    }, [])
    const getSpecializations = (text) => {
        setLoading(true)
        ConfigApi.getKeyWords(text, (responseData) => {
            const specs = []
            responseData.keyWords.forEach(element => {
                if (element.key_word != undefined){
                    specs.push(element.key_word)
                } else if (element){
                    specs.push(element)
                }
                
            });
            setServerSpecs(specs)
            setLoading(false)
        }, () => {setLoading(false)})
    }
    const changeText = (text) => { 
        setText(text)
        if (text.includes(",")){
            const tags = text.split(",")
            tags.forEach(tag => {
                if (tag != "" && tag != " "){
                    props.addTag(tag)
                }
            })
            setTimeout(function() {
                setText("")
                setServerSpecs([])
            }, 300)
        } else if (text.length > 1){
            getSpecializations(text)
        } else if (text.length == 0){
            setServerSpecs([])
        }
        console.log("changeText text = " + text)
    }
    const onFilterPicked = (tag) => {
        setServerSpecs([])
        setText("")
        props.addTag(tag)
    }

    const handleOnEnter = (e) => {
        if (e.key === 'Enter') {
            props.addTag(e.target.value)
            setTimeout(function() {
                setText("")
                setServerSpecs([])
            }, 300)
            
        }
    }
    
    const onCloseFilters = () => {
        setText("")
        setServerSpecs([])
    }
   

    return (
        <div>
           <div onKeyDown={handleOnEnter} className="spec_top step_7_profile_input">
                <ProfileInputRaw innerRef={refTextarea} debounceTimeout={500} placeholder="Ваши навыки через запятую" value={text} setValue={changeText}/>
                {isLoading && <Loader style={{top : '12px', position : 'absolute', right : '32px', minHeight : 'auto', margin : 0}}/>}
           </div>
           {serverSpecs.length > 0 && <FilterDropdown isHideTooltipBg onCloseFilters={text.length == 0 ? onCloseFilters : () => {}} style={{margin : '0px 0px 0px -2px'}} filters={serverSpecs} onFilterPicked={onFilterPicked}/>}

           <div className="profile_tags_contaner">
                {specializations.length > 0 && specializations.map((tag, index) => (
                    <div style={tag == "" || tag == " " ? {display : 'none'} : {}} key={index}>
                        <p onClick={() => props.removeTag(tag, index)} className="profile_tag hover">{tag}</p>
                    </div>
                ))}
           </div>
        </div>
    );
}

export default ProfileSkills;
