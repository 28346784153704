/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import { useState, useEffect, useRef } from 'react';
import send from '../../../img/chat/send.svg'
import answer_msg from '../../../img/chat/answer_msg.svg'
import editable_msg_icon from '../../../img/chat/editable_msg_icon.svg'
import close_editable_msg from '../../../img/chat/close_editable_msg.svg'
import attach from '../../../img/chat/attach.svg'
import { DialogsApi } from '../../../api/DialogsApi';
import { getIsDesktop, debounce, MyImage } from '../../../utils/Utils';
import { getCookie, isUserAuthed, setCookie } from '../../../utils/CookieHelper';
import autosize from 'autosize';
import PriceItem from '../list_items/PriceItem';
import { CSSTransition } from 'react-transition-group';
import AttachTooltip from '../tooltips/AttachTooltip';
import ChatBalanceTooltip from '../tooltips/ChatBalanaceTooltip';
import CustomTooltip from '../../../utils/CustomTooltip';
import { createNewMessage, uploadChatFiles } from '../utils/FilesUploader';
import moment from 'moment';
import { clearSavedMsgByDialogId, getSavedMsgByDialogId, saveMsgByDialogId } from './ChatUtils';
var videoStreamsSaved = []
var to_user = {}
function WriteBar(props) {
    const [message, setMessage] = useState("")
    const [wasOpenedRules, setWasOpenedRules] = useState(false)
    const [isAttachOpened, setAttachOpened] = useState(false)
    const [isShowBalanceTooltip, setShowBalanceTooltip] = useState(false)
    const [isShowCreatePlannerTooltip, setShowCreatePlannerTooltip] = useState(false)

    const refTextarea = useRef(null)
    const isDesktop = getIsDesktop()
    const fromCall = props.fromCall
    const isGroupCall = props.isGroupCall
    const videoStreams = props.videoStreams
    const is_i_creator = props.is_i_creator
    const setFileUploading = props.setFileUploading
    to_user = props.currentChat.user
    // console.log("to_user = ", to_user)
    const setServicesValueDebounced = useRef(debounce(() => {
        DialogsApi.sendTypingEvent(to_user.vk_caller_id, (response) => {
        })
    }, 300))
    var lastMsg = ""
    const editableMsg = props.editableMsg
    const replyMsg = props.replyMsg
    const dragLeaveService = useRef(debounce((chat_on_drag_border) => {
        if (chat_on_drag_border.style.display == 'block'){
            chat_on_drag_border.setAttribute('style', 'display : none')
        }
    }, 5))
    const dragEnterService = useRef(debounce((chat_on_drag_border) => {
        if (chat_on_drag_border.style.display == 'none'){
            chat_on_drag_border.setAttribute('style', 'display : block')
        }
    }, 5))
  
    useEffect(() => {
        videoStreamsSaved = videoStreams
    }, [videoStreams])
    useEffect(() => {
        to_user = props.currentChat.user
    }, [props.currentChat.user])
    useEffect(() => {
        // clearMsg()
        const savedMsg = getSavedMsgByDialogId(props.currentChat.dialog.dialog_id)
        console.log("props.chatChangeHook savedMsg = ", savedMsg)
        setMessage(savedMsg)
        if (savedMsg == "" && document.getElementById("textarea_id")){
            document.getElementById("textarea_id").style.height = '65px'
        }
    }, [props.chatChangeHook])
    const clearMsg = () => {
        setMessage("")
        clearSavedMsgByDialogId(props.currentChat.dialog.dialog_id)
    }
    useEffect(() => {
        if (refTextarea != undefined && refTextarea.current != undefined){
            //refTextarea.current.focus();
            autosize(refTextarea.current);

            document.onpaste = function(event){
                var items = (event.clipboardData || event.originalEvent.clipboardData).items;
                if (items.length > 0){
                    for (let index = 0; index < items.length; index++) {
                        var item = items[index]
                        if (item.kind === 'file') {
                            var blob = item.getAsFile()
                            var reader = new FileReader()
                            reader.onload = function(event){
                                uploadChatFiles(blob, event.target.result, to_user.vk_caller_id, props.setErrorSize, onMessageSentWithAttach, videoStreamsSaved, setFileUploading)
                            };
                            reader.readAsDataURL(blob)
                        }
                    }
                    // var item = items[0];
                    // if (item.kind === 'file') {
                    //     var blob = item.getAsFile()
                    //     var reader = new FileReader()
                    //     reader.onload = function(event){
                    //         uploadChatFiles(blob, event.target.result, to_user.vk_caller_id, props.setErrorSize, onMessageSentWithAttach, videoStreamsSaved, setFileUploading)
                    //     };
                    //     reader.readAsDataURL(blob)
                    // }
                }
            }
            const dropContainers = window.document.getElementsByClassName("selected_chat_container_desktop")
            if (dropContainers.length > 0){
                const dropContainer = dropContainers[0]
                const chat_on_drag_border = window.document.getElementsByClassName("chat_on_drag_border")[0]
               
                dropContainer.ondragover = dropContainer.ondragenter = function(event) {
                    event.preventDefault();
                    dragEnterService.current(chat_on_drag_border)
                };
             
                dropContainer.addEventListener("dragleave", (event) => {
                    event.preventDefault()
                    dragLeaveService.current(chat_on_drag_border)
                })

                dropContainer.ondrop = function(evt) {
                    evt.preventDefault();
                    const files = evt.dataTransfer.files
                    for (let index = 0; index < files.length; index++) {
                        const blob = files[index];
                        var reader = new FileReader();
                        reader.onload = function(event){
                            uploadChatFiles(blob, event.target.result, to_user.vk_caller_id, props.setErrorSize, onMessageSentWithAttach, videoStreamsSaved, setFileUploading)
                        }
                        reader.readAsDataURL(blob)
                    }
                                
                    dragLeaveService.current(chat_on_drag_border)
                }
            }
        }
        if (getCookie("shown_chat_balance_tooltip") != getCookie("id") && isUserAuthed() && !fromCall){
            setCookie("shown_chat_balance_tooltip", getCookie("id"))
            setShowBalanceTooltip(true)
        }
    }, [])
    // useEffect(() => {
    //     if (getCookie("shown_createplanner_tooltip") == null && isUserAuthed()){
    //         setCookie("shown_createplanner_tooltip", getCookie("id"))
    //         setShowCreatePlannerTooltip(true)
    //     }
    // }, [])
    useEffect(() => {
        if (editableMsg.text != undefined){
            setMessage(editableMsg.text)
        }
    }, [editableMsg.text])
    const sendMessage = (to_user_id, message_id_for_group_chat) => {
        if (message == undefined || message == ""){
            return
        }
        lastMsg = message
        if (editableMsg.text != undefined){
            console.log("editableMsg._id = ", editableMsg._id)
            DialogsApi.editMessage(editableMsg._id, lastMsg, () => {
                // props.messageSentCallback()
            })
            closeEditable()
        } else {
            props.messageSent(createNewMessage(to_user_id, message), true)
            DialogsApi.sendMessage(lastMsg, to_user_id, replyMsg._id, (responseData) => {
                props.messageSentCallback()
            }, () => {}, undefined, message_id_for_group_chat)
            if (replyMsg._id != undefined){
                closeReply()
            }
        }
        clearMsg()  
        refTextarea.current.focus()
        setTimeout(function() {
            setMessage("")
            document.getElementById("textarea_id").style.height = '65px'
            refTextarea.current.focus()
        }, 50)
            
    }
   
    const onMessageSentWithAttach = (url) => {
        console.log("onMessageSentWithAttach url = ", url)
        var msg = createNewMessage(to_user.vk_caller_id, message)
        msg.attachments_urls = [url]
        props.messageSent(msg, true)
        props.messageSentCallback()
        props.delayedScrollToBottom()
    }
    // console.log("msg replyMsg = ", replyMsg)
    const onTextTouched = () => {
        // const chat = props.currentChat
        // if (props.messages.length == 2 && !wasOpenedRules && chat.user.rub_per_message > 0 && (chat.dialog == undefined || chat.dialog.id_repetitor != props.user.vk_caller_id)){
        //     props.setOpenRules(true)
        //     setWasOpenedRules(true)
        // }
    }
    const onSendMsgPressed = () => {
        // if (isGroupCall){
        //     for (let index = 0; index < videoStreams.length; index++) {
        //         const id = videoStreams[index].user.id;
        //         console.log("onSendMsgPressed id = ", id)
        //         sendMessage(id)
        //     }
        // } else {
        //     sendMessage(to_user.vk_caller_id)
        // }
        if (isGroupCall && is_i_creator){
            const unixtime = moment().unix()
            if (replyMsg.text != undefined){
                const to_id = replyMsg.to_id == getCookie("id") ? replyMsg.from_id : replyMsg.to_id
                const foundIndex = videoStreams.findIndex((obj => obj.user.id == to_id))
                if (foundIndex != -1){
                    sendMessage(videoStreams[foundIndex].user.id, unixtime)       
                }
                
            } else {
                for (let index = 0; index < videoStreams.length; index++) {
                    const id = videoStreams[index].user.id;
                    console.log("onSendMsgPressed id = ", id)
                    sendMessage(id, unixtime)
                }
            }
        } else {
            sendMessage(to_user.vk_caller_id)
        }
        
    }
    const handleOnEnter = (e) => {
        if ((e.metaKey || e.ctrlKey) && e.keyCode == 13){
            const txtArea = window.document.getElementById("textarea_id")
            const selectionStart = txtArea.selectionStart
            var msg = message.substr(0, selectionStart) + '\n' + message.substr(txtArea.selectionEnd)
            setMessage(msg)
           
            console.log("txtArea setSelectionRange = ", selectionStart)
            autosize.update(refTextarea.current)
            setTimeout(function() {
                txtArea.setSelectionRange(selectionStart + 1, selectionStart + 1)
            }, 20)
        } else if (e.key === 'Enter') {
            if (isDesktop){
                onSendMsgPressed()

            } 
            //else {
            //     setMessage(message + '\n')
            // }
        } else if (e.keyCode == 38){
            const txtArea = window.document.getElementById("textarea_id")
            if (document.activeElement === txtArea && message == "") {
                const myMessages = props.messages.filter(m => m.from_id == getCookie("id") && m.type == 0)
                if (myMessages.length > 0){
                    const msg = myMessages[myMessages.length - 1]
                    const msgIsPlanner = msg.planner != undefined && msg.planner._id != undefined
                    if (!msgIsPlanner){
                        props.setEditableMsg(msg)
                    }
                }
            } 
        }
    }
    const onMessageChanged = (msg) => {
        if (msg == "\r" || msg == "\n"){
            clearMsg()
        } else {
            setMessage(msg)
            saveMsgByDialogId(props.currentChat.dialog.dialog_id, msg)
        }
         //timer search breaker
        if (msg != "" && msg != "\r" && msg != "\n"){
            setServicesValueDebounced.current()
        }
        
    }
 
 
    
    const upBalance = () => {
        window.location.href = "/pay"
    }
    const closeEditable = () => {
        props.setEditableMsg({})
        clearMsg()
    }
    const closeReply = () => {
        props.setReplyMsg({})
    }
    const closeBalanceTooltip = () => {
        setShowBalanceTooltip(false)
        setShowCreatePlannerTooltip(true)
    }
    const toggleAttach = () => {
        setAttachOpened(!isAttachOpened)
    }
    
    const isBlockedSomeone = props.isInMyBlock || props.isIBlocked
    const editableMsgText = editableMsg.text == undefined ? "" : editableMsg.text.includes("\n") ? ("<div>" +  editableMsg.text.replaceAll("\n", "<br/>") + "</div>") : editableMsg.text
    const replyMsgText = replyMsg.text == undefined ? "" : replyMsg.text.includes("\n") ? replyMsg.text.split("\n")[0] + "..." : replyMsg.text
    if (editableMsgText != "" || replyMsgText != ""){
        refTextarea.current.focus()
    }
    const getEditableMsgItem = () => {
        return(
            <div style={{background : 'white', padding : '8px 16px'}}>
                <div className="editable_msg_container">
                    <div className="flex">
                        <MyImage className="editable_msg_icon" src={editable_msg_icon}/>
                        <p className="editable_msg_text" dangerouslySetInnerHTML={{__html:editableMsgText}}></p>
                    </div>
                    <img alt="" onClick={closeEditable} className="editable_msg_icon hover" src={close_editable_msg}/>
                </div>
            </div>
        )
    }
    const getReplyMsgItem = () => {
        return(
            <div style={{background : 'white', padding : '12px 16px'}}>
                <div className="reply_msg_container">
                    <div className="flex">
                        <MyImage className="reply_msg_icon" src={answer_msg}/>
                        <div className="reply_msg_line"/>
                        <div>
                            <p className="reply_msg_name">{to_user.name + " " + (to_user.surname ? to_user.surname : "")}</p>
                            <p className="editable_msg_text reply_msg_in_bubble" dangerouslySetInnerHTML={{__html:replyMsgText}}></p>
                        </div>
                    </div>
                    <img alt="" onClick={closeReply} className="editable_msg_icon hover" src={close_editable_msg}/>
                </div>
            </div>
        )
    }
    const isChatOpenedFromCalls = ((fromCall || isGroupCall) && isDesktop)
    const inputArea = () => {
        return(
            <div className="write_bar_flex relative">
                <div className="flex">
                    <div style={isDesktop ? {margin: '0 12px 0 0'} : {margin : 0}} onClick={toggleAttach} className="back_icon_container hover chat_back_icon">
                        <img alt="" className="back_icon_img" src={attach}/>
                    </div>
                    <div style={{paddingLeft : '0'}} onClick={onTextTouched} className="flex">
                        <textarea id="textarea_id" ref={refTextarea} style={props.style ? {width : 'calc(360px - 72px)'} : {}} onKeyDown={handleOnEnter} autoFocus={false} placeholder="Сообщение" className="write_bar_input"  value={message} onChange={(e) => onMessageChanged(e.target.value)}/>
                    </div>
                </div>
                <div style={((fromCall || isGroupCall) && isDesktop) ? {margin: '0 4px 0 -36px'} : {margin: '0 4px 0 -24px'}} onClick={onSendMsgPressed} className="back_icon_container hover chat_back_icon">
                    <img alt="" className="back_icon_img" src={send}/>
                </div>

                <CSSTransition in={isAttachOpened} timeout={300} classNames="animation_in" unmountOnExit>
                    <AttachTooltip isChatOpenedFromCalls={isChatOpenedFromCalls} setFileUploading={setFileUploading} setShowAttachDesk={props.setShowAttachDesk} videoStreams={videoStreams} fromCall={fromCall} setErrorSize={props.setErrorSize} onMessageSentWithAttach={onMessageSentWithAttach} to_user={to_user} isShow={isAttachOpened} setShow={toggleAttach}/>
                </CSSTransition>
            </div>
        )
    }
    const isZero = !props.isPaidChat || props.checkRepetitorResult.rub_per_message == undefined || props.checkRepetitorResult.rub_per_message == 0

    return (
        <div style={props.style ? props.style : {}} className="write_bar_container">
            {isGroupCall && 
                <div className="chat_group_call_description hover">
                    <div>{is_i_creator ? "Ваше сообщение получат все участники" : "Приватно — " + to_user.name}</div>
                </div>}
            {(props.checkRepetitorResult && !props.isLoading && !props.isGroupCall && props.isPaidChat) && <PriceItem isPaidChat={props.isPaidChat} setOpenRules={props.setOpenRules} config={props.config} isIRepetitor={props.isIRepetitor} isDesktop={isDesktop} repetitor={props.checkRepetitorResult} user={props.user}/>}
            <div style={{margin : 0}} className="chat_navbar_line"/>
                {editableMsg.text != undefined && getEditableMsgItem()}
                {replyMsg.text != undefined && getReplyMsgItem()}
                {isBlockedSomeone ? 
                    <div className="write_bar_flex block_bar relative">
                        <div style={(!getIsDesktop() && props.isIBlocked) ? {marginTop : "18px"} : {}} className="blocked_bar_text">{props.isInMyBlock ? "Вы заблокировали пользователя. Чтобы написать ему сообщение, разблокируйте его 🤐" : "Пользователь заблокировал вас 🤐"}</div>
                    </div>
                : 
                (isZero || props.checkRepetitorResult == undefined || props.user.count_rub >= (props.checkRepetitorResult.rub_per_message != undefined ? props.checkRepetitorResult.rub_per_message : 0) || props.isIRepetitor) ? 
                    inputArea()
                : 
                <div className="write_bar_flex block_bar">
                    <div onClick={upBalance} className="up_balance_btn_dialog hover">пополнить баланс</div>
                </div>}
                
                {isShowBalanceTooltip && <ChatBalanceTooltip setShow={closeBalanceTooltip}/>}
                {isShowCreatePlannerTooltip && <CustomTooltip text="Здесь вы можете создать запланированный созвон" tooltipClass="chat_create_plan_tooltip" tooltipArrowStyle={{bottom : '6px', position : 'absolute', left : '24px'}} setShow={setShowCreatePlannerTooltip}/>}

        </div>
    );
}

export default WriteBar;
