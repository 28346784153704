/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import left_arrow from '../../../img/experts/left_arrow.svg'
import PlannedPlannerItem from '../../planner/list_items/PlannedPlannerItem';
import { declension, getIsDesktop, getRating, Pages } from '../../../utils/Utils';
import AdsGroupCall from '../../planner/list_items/AdsGroupCall';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { UsersApi } from '../../../api/UsersApi';
import moment from 'moment'
import star_profile from '../../../img/profile/star.svg'
import star_selected from '../../../img/profile/star_selected.svg'
import avatar from '../../../img/avatar.svg'

function ProfileReviewsList({ user, style }) {
    const [reviews, setReviews] = useState([])
    useEffect(() => {
        getReviews()
    }, [])
    const getReviews = () => {
        UsersApi.getReviews(user.vk_caller_id, (responseData) => {
            setReviews(responseData.reviews)
        }, () => {})
    }
    const getInnerCorousel = () => {
        return(
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {reviews.map((item, index) => (
                    <Card review={item} itemId={item.id} user={user} index={index} key={index}/>
                ))}
            </ScrollMenu>
        )
    }
    if (reviews.length == 0 || user.count_1_grade == undefined || !user.is_repetitor){
        return null
    }
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade
    const rating = getRating(user)
    return (
            <div style={style ? style : {}} className={'profile_review_cont_bg'}>
                <div className='justify-content'>
                    <div className='profile_review_list_title'>
                        Отзывы
                    </div>
                    <div className='flex'>
                        <div className='profile_review_list_text'>{reviews.length + " " + declension(reviews.length, 'отзыв', 'отзыва', 'отзывов')}</div>
                        <div style={{margin : '2px 0 0 0 '}}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.65461 4.32651C7.20487 3.21176 8.79448 3.21176 9.34474 4.32651L9.71101 5.06853C9.92931 5.5108 10.3511 5.81749 10.8391 5.88882L11.6607 6.0089C12.8905 6.18867 13.3807 7.70043 12.4903 8.56767L11.8982 9.14441C11.5444 9.48902 11.3829 9.98572 11.4664 10.4725L11.6058 11.2855C11.816 12.511 10.5297 13.4454 9.42923 12.8667L8.69785 12.4821C8.26078 12.2522 7.73857 12.2522 7.3015 12.4821L6.57012 12.8667C5.46968 13.4454 4.18335 12.511 4.39353 11.2855L4.53297 10.4725C4.61646 9.98572 4.45497 9.48902 4.10116 9.14441L3.50903 8.56767C2.61863 7.70043 3.10881 6.18867 4.33868 6.0089L5.16022 5.88882C5.64825 5.81749 6.07003 5.5108 6.28834 5.06853L6.65461 4.32651Z" fill="#FFEA79" />
                            </svg>
                        </div>
                        <div className='profile_review_list_count'>{rating}</div>
                    </div>
                </div>
                
                {reviews.length > 0 && getInnerCorousel()}
            </div>
    );
    
}
function Card({ review, itemId, index, user, isProfile }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isDesktop = getIsDesktop()
    const link = Pages.reviews + "?id=" + user.vk_caller_id + "&user_id=" + user._id
    const getStars = () => {
        var stars = []
        for (let index = 1; index < 6; index++) {
            if (review.grade >= index){
                stars.push(star_selected)
            } else {
                stars.push(star_profile)
            }
        }
        return stars
    }
    const stars = getStars()
    const isFromUserReview = false
    const avatarImg = isFromUserReview ? review.to_avatar : review.from_avatar
    var name = isFromUserReview ? review.to_name : review.from_user ? review.from_user.name : review.from_name
    var hasUrl = review.link_to_proof != undefined && review.link_to_proof != ""
    var surname = isFromUserReview ? "" : review.from_user ? review.from_user.surname : ""
    var text = review.text.includes("\n") ? ("<div>" +  review.text.replaceAll("\n", "<br/>") + "</div>") : review.text

    return (
        <div style={index == 0 ? {marginLeft : isDesktop ? '1px' : '1px'} : (index == 3 && isDesktop) ? {} : {}}>
          <a rel="noreferrer" href={link} target="_blank">
            <div className={'profile_review_list_item hover'}>
                <div className="review_item_top">
                    <p className="review_item_date">{moment.unix(moment(review.create_date, "YYYY-MM-DD").unix()).format("DD MMM YYYY")}</p>
                    <div className="flex">
                        {stars.length > 0 && stars.map((img, index) => (
                            <img alt='' className="review_item_star" key={index} src={img}/>
                        ))}
                    </div>
                     
                </div>
                <div className="flex">
                    {avatarImg ? <img alt='' className='review_item_img' src={avatarImg ? avatarImg : avatar}/>
                    : 
                    <div className="profile_review_avatar_bg">
                        <div className="profile_review_avatar_bg_col">
                            <div>{(name && name.length > 0) ? name.substring(0, 1).toUpperCase() : ""}{(surname && surname.length > 0) ? surname.substring(0, 1).toUpperCase() : ""}</div>
                        </div>
                    </div>}
                    <div className=''>
                        <p className="review_item_surname profile_rev_item_overflow">{name.replaceAll("ㅤ", "")} {(surname && surname != "") ? surname : ""}</p>
                        <p className="review_item_text_time">{(review.dialog && review.dialog.count_calls) ? (review.dialog.count_calls + " " + declension(parseInt(review.dialog.count_calls), 'созвон', 'созвона', 'созвонов')) : ("1 созвон")}</p>

                    </div>
                </div>
                {text != "" && <p className="review_item_text profile_rev_item_clamp" dangerouslySetInnerHTML={{__html:text}}></p>}
            </div>
        </a>
        </div>
    );
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <img alt="" style={isFirstItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll planner_arrow_scroll_left hover" src={left_arrow} disabled={isFirstItemVisible} onClick={() => scrollPrev()}></img>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
        <img alt="" style={isLastItemVisible ? {opacity : 0, pointerEvents : 'none'} : {}} className="experts_arrow_scroll experts_arrow_right planner_arrow_scroll hover" src={left_arrow} disabled={isLastItemVisible} onClick={() => scrollNext()}></img>
    );
}
export default ProfileReviewsList;
