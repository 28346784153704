import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { CSSTransition } from 'react-transition-group';
import ProfileInputRaw from '../../profile_filling/custom_views/ProfileInputRaw';
import { getRequestUrl, getHttpParams, getIsDesktop, getTimePaid, Pages } from '../../../utils/Utils';
import Loader from '../../../custom_views/Loader';
import no_money from '../../../img/call/no_money.png'
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { DialogsApi } from '../../../api/DialogsApi';
import { UsersApi } from '../../../api/UsersApi';

function CallPricesBottomSheet(props) {
    const [isEdit, setEdit] = useState(false)
    const [priceHour, setPriceHour] = useState(props.user.rub_in_hour ? props.user.rub_in_hour : 500)
    const [freeTime, setFreeTime] = useState(props.user.free_time)
    const [isLoading, setLoading] = useState(false)
    const [isIRepetitor, setIRepetitor] = useState(false)
    const [isNotENoughtMoney, setNotENoughtMoney] = useState(false)
    const [isCheckLoaded, setCheckLoaded] = useState(false)
    const [checkRepetitorResult, setCheckRepetitorResult] = useState({})
    const config = props.config
    useEffect(() => {
        if (props.user.vk_caller_id != user.vk_caller_id && props.isShow){
            console.log("checkRepetitor isShow")
            checkRepetitor()
        }
    }, [props.isShow])
    useEffect(() => {
        if (props.user != undefined && props.user.vk_caller_id != undefined){
            setPriceHour(props.user.rub_in_hour)
            setFreeTime(props.user.free_time)
        }
    }, [props.user])
    const user = props.callToUser

    const edit = () => {
        setEdit(true)
    }
    const save = () => {
        setMyInfo()
    }
    
    const setMyInfo = () => {
        var params = {}
        params.rub_in_hour = priceHour
        params.free_time = freeTime
        UsersApi.setMyInfo(params, (responseData) => {
            if (responseData.response == 1){
                setEdit(false)
                props.getMyInfo()
            }
            setLoading(false)
        })
        setLoading(true)
    }
    const checkRepetitor = () => {
        DialogsApi.checkRepetitor(user.vk_caller_id, (responseData) => {
            if (responseData.id_student == props.user.vk_caller_id){
                setIRepetitor(false)
            } else {
                setIRepetitor(true)
            }
            if (responseData.too_little_money_to_call){
                setNotENoughtMoney(true)
            }
            setCheckLoaded(true)
            setCheckRepetitorResult(responseData)
        })
    }
    const isEnableButton = priceHour >= config.minimum_call_price && freeTime >= 5
    const isPriceError = priceHour == "" ? false : priceHour < config.minimum_call_price
    const isTimeError = freeTime == "" ? false : freeTime < 5
    const isDesktop = getIsDesktop()
    const rub_in_hour = isIRepetitor ? props.user.rub_in_hour : user.rub_in_hour
    const free_time = parseInt(checkRepetitorResult.count_free_time)
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '360px'} : {}}>
                <CSSTransition in={!isEdit && !isNotENoughtMoney} timeout={0} classNames="animation_in" unmountOnExit>
                    <div className="call_info_container">
                        <div className="call_expert_price_title_dialog"><div style={{opacity : '0.5'}}>Ваша роль в звонке</div><span>{isIRepetitor ? "Эксперт" : "Клиент"}</span></div>

                        <div className="call_expert_price_dialog">
                            <div className="call_price_half">
                                <p className="call_price_title">Стоимость звонка</p>
                                <p className="call_price_text">{user.rub_in_hour <= 0 ? "" : isIRepetitor ? "+" : "-"} {isIRepetitor ? Math.round(rub_in_hour*(1-config.comission)) : rub_in_hour}₽/час</p>
                                {user.rub_in_hour > 0 && <p style={{opacity : '0.5'}} className="call_price_desc">{isIRepetitor ? Math.round(rub_in_hour/60*(1-config.comission)) : parseInt(rub_in_hour/60)}₽/мин</p>}
                            </div>
                            {free_time > 0 && <div className="call_price_half">
                                <p className="call_price_title">Бесплатное время</p>
                                <p className="call_price_text">{free_time} мин.</p>
                            </div>}
                        </div>
                        {(!isIRepetitor && user.rub_in_hour > 0) && <div style={{margin : '-16px 0 32px 0', maxWidth : isDesktop ? '320px' : 'auto', textAlign : 'left'}} className="profile_up_balance">
                        🤓 Средств на вашем балансе хватит на <b>{getTimePaid(props.user, user.rub_in_hour)}</b> разговора с экспертом. Рекомендуем сначала согласовать время созвона с экспертом в чате.
                            <br/><br/>
                            <b onClick={() => window.open(Pages.pay + "?pay=1", "_blank")} className="hover">{"Пополнить баланс".toLocaleUpperCase()}</b>
                        </div>}
                        <div className="flex">
                            {isIRepetitor && <div onClick={edit} className="call_prices_edit hover">Редактировать</div>}
                            <div style={!isIRepetitor ? {margin : '0 24px 0 24px'} : {}} onClick={() => props.call(checkRepetitorResult)} className="call_price_continue hover">Продолжить</div>
                        </div>
                    </div>
                </CSSTransition>

                <CSSTransition in={isEdit && !isNotENoughtMoney} timeout={0} classNames="animation_in" unmountOnExit>
                    <div className="call_info_container">
                        <p className="call_edit_title">Условия звонка</p>
                        <p className="call_edit_desc">Изменения применятся ко всем будущим звонкам и отобразятся в вашем профиле</p>
                        {/* <p className="call_edit_price_title">Стоимость аудио/видеозвонка</p> */}
                        
                        <ProfileInputRaw titleStyle={{color : 'black', opacity : '0.5'}} inputDesc="₽" title="Стоимость одного часа звонка" value={priceHour} setValue={setPriceHour}/>
                        <p style={isPriceError ? {color : 'var(--red)', opacity : 1} : {color : 'black', opacity : '0.5'}} className="profile_input_title profile_input_title_l">{isPriceError ? "Минимальная цена - " + config.minimum_call_price : "За вычетом комиссии сервиса получится " + parseFloat(priceHour*(1-config.comission)).toFixed(2) + "₽"}</p>
                        <ProfileInputRaw titleStyle={{color : 'black', opacity : '0.5'}} inputDesc="минут" title="Бесплатное время (только первый звонок)" value={freeTime} setValue={setFreeTime}/>
                        <p style={{color : 'black', opacity : '0.5'}} className="profile_input_title profile_input_title_l">Это то количество времени, которое вы готовы консультировать бесплатно.</p>
                        {isTimeError && <p style={{color : 'var(--red)', opacity : 1, marginTop : '-32px'}} className="profile_input_title profile_input_title_l">Минимальное бесплатное время - 5 минут</p>}
                        
                        {/* <div className="call_edit_free_time_title">Бесплатное время {free_time} мин.
                            <p>Не редактируется для клиента, с которым вы уже начали взаимодействие. Изменение бесплатного времени для новых клиентов происходит через редактирование профиля.</p>
                        </div> */}
                        <div style={{marginTop : '36px'}} className="flex">
                            <div onClick={() => setEdit(false)} className="call_prices_edit hover">Отмена</div>
                            <div style={isEnableButton ? {} : {pointerEvents : 'none', opacity : '0.2'}} onClick={save} className="call_price_continue hover">
                                {isLoading ? <Loader/> : "Сохранить"}
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                <CSSTransition in={isNotENoughtMoney} timeout={0} classNames="animation_in" unmountOnExit>
                    <div className="call_info_container">
                        <img className="call_no_money_img" src={no_money}/>

                        <p className="call_no_money_title">{isIRepetitor ? "У клиента не хватает средств на балансе" : "У вас не хватает средств на балансе"}</p>
                        <div className="call_no_money_desc">Для успешного звонка на балансе<br/>должна быть сумма не менее {parseInt(rub_in_hour/4)}₽</div>
                                    
                    
                        <div style={{marginTop : '36px'}} className="flex">
                            <div onClick={() => props.setShow(false)} className="call_prices_edit hover">Закрыть</div>
                            {!isIRepetitor && <div onClick={() => window.location.href = "/pay"} className="call_price_continue hover">Пополнить</div>}
                        </div>
                    </div>
                </CSSTransition>

            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(
            <BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>
        )
    } else {
        return (
            <BottomSheet onDismiss={() => props.setShow(false)} style={{zIndex : 9999}} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default CallPricesBottomSheet;
