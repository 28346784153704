import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop, Pages } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function WriteToDevelopersDialog(props) {
    const isDesktop = getIsDesktop()
    const write = () => {
        //window.open(Pages.vk_group, '_blank')
        window.open('mailto:sozvon-pro@yandex.ru')
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <div className="call_edit_title">Написать разработчикам</div>
                <p className="call_edit_desc">По возникающим вопросам и<br/>предложениям сотрудничества<br/>пишите, пожалуйста, на адрес:<br/><span className="lk_other_write_email">sozvon-pro@yandex.ru</span></p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px'}} onClick={write} className="call_price_continue hover">Написать разработчикам</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default WriteToDevelopersDialog;
