import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import check_black from '../../../img/create_ads/check_black.svg'
import search_icon from '../../../img/experts/search.svg'

function BlogThemePickerDialog(props) {
    const [selectedSpecs, setSelectedSpecs] = useState(props.blogThemes)
    const [filterSearchText, setFilterSearchText] = useState("")
    const filteredSpecs = props.specializations.filter((spec) => filterSearchText == "" ? true : spec.toLowerCase().includes(filterSearchText.toLowerCase()))

    const isDesktop = getIsDesktop()
    useEffect(() => {
        setSelectedSpecs(props.blogThemes)
    }, [props.blogThemes])
    const setThemes = () => {
        props.setShow(false)
        props.setBlogThemes(selectedSpecs)
    }
    const onSpecClicked = (item) => {
        const specs = [...selectedSpecs]
        if (specs.includes(item)){
            specs.splice(specs.indexOf(item), 1)
        } else {
            specs.push(item)
        }
        setSelectedSpecs(specs)
    }
    const blogFilterItems = () => {
        return(
            <div>
                <div className="blogs_filter_title blog_filter_theme">Выбрано тем {selectedSpecs.length > 0 && <span className="blogs_filter_count">{selectedSpecs.length}</span>}</div>
                <div>
                    <div className="flex">
                        <div className="blog_filter_search">
                            <img className="search_experts_icon" src={search_icon}/>
                            <input placeholder="Поиск по темам" className="experts_search_input blogs_search_input" type="text" value={filterSearchText} onChange={(e) => setFilterSearchText(e.target.value)}/>
                        </div>   
                    </div>
                </div>
            </div>
        )
    }
    const getInnerHtml = () => {
        return(
            <div>            
                <div className="call_info_container blog_theme_picker">
                    <p style={{color : 'black'}} className="create_ads_close_title">Тема поста</p>
                    {blogFilterItems()}
                    {filteredSpecs.length > 0 && filteredSpecs.map((item, index) => (
                        <SpecializationItem item={item} isSelected={selectedSpecs.includes(item)} onSpecClicked={onSpecClicked} index={index} key={index}/>
                    ))}
                    {filteredSpecs.length == 0 && <div className="blog_Filer_no_theme">Тема не найдена</div>}
                </div>
                <div style={{margin : '16px 0 16px 0'}} className="flex">
                    <div style={selectedSpecs.length == 0 ? {margin : '0 24px', pointerEvents : 'none', opacity : '0.2'} : {margin : '0 24px'}} onClick={setThemes} className="call_price_continue hover">Применить</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default BlogThemePickerDialog;
const SpecializationItem = ({ item, isSelected, onSpecClicked }) => {
    return(
        <div className="ads_spec_item">
            <div onClick={() => onSpecClicked(item)} className="ads_spec_item_flex hover">
                {item}
                <img style={isSelected ? {opacity : 1} : {opacity : 0}} className="ads_spec_item_icon" src={check_black}/>
            </div>
            <div style={{marginTop : '12px'}} className="ads_filter_line"/>
        </div>
    )
}