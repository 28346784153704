/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useRef } from 'react';
import moment from 'moment'
import chat_video_icon from '../../../img/chat/chat_video_icon.svg'
import document_icon from '../../../img/chat/document.svg'
import readed_msg from '../../../img/chat/readed_msg.svg'
import not_readed_msg from '../../../img/chat/not_readed_msg.svg'
import ads_message from '../../../img/create_ads/ads_message.svg'
import reply_group_call from '../../../img/call/reply_group_call.svg'
import { MyImage, Pages, isUrlWithImage, getIsDesktop, getUserAvatar } from '../../../utils/Utils';
import { linkify } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';
import { getCookie } from '../../../utils/CookieHelper';
import Loader from '../../../custom_views/Loader';
import { PlannerApi } from '../../../api/PlannerApi';
import { useLongPress } from 'use-long-press';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import { getAllUrlParams, formatBytes, get_url_extension, downloadFileFromUrl, getFilenameFromUrl, getGroupCallText } from '../custom_views/ChatUtils';
const groupCallText = "Всю переписку видит\nтолько организатор видеовстречи.\nОстальные участники видят\nсвои сообщения и сообщения организатора, адресованные лично им или всем участникам."
const RowMessageItem = ({ data, index, setSize, windowWidth, user, isIRepetitor, setContextMenuMsg, scrollToReplyMsg, setDeclinePlanner, isPaidChat, checkRepetitorResult, changeMsg, setErrorAcceptPlanner, setShowCallBottomSheet, fromCall, videoStreams, setReplyMsg, setShowOpenContacts }) => {
    const rowRef = useRef();
    const message = data[index]
    const isDocument = message.attachments_urls && message.attachments_urls.length > 0 && !isUrlWithImage(message.attachments_urls[0])
    //  console.log("message = ", message)
    React.useEffect(() => {
      setSize(index, rowRef.current.getBoundingClientRect().height + (message.dateTitle ? 16 : 4));
    }, [setSize, index, windowWidth]);
    return (
        <div ref={rowRef} style={{...styles.row}}>
            {   (message.id == -888 && isIRepetitor) ? <ShowContactsMessageItem setShowOpenContacts={setShowOpenContacts}/> :
                (message.id === -999 || message.id === -100) ? <div style={{height : message.id === -100 ? 'auto' : '36px', minHeight : message.id === -100 ? 'auto' : '36px', opacity : message.id === -100 ? 1 : 0}} className='chat_group_call_msg'>{message.id === -100 ? <div>😎<br/>{groupCallText}</div> : "asd"}</div> : message.id === -99 ? 
                <MessagePriceItem isIRepetitor={isIRepetitor} user={user} isPaidChat={isPaidChat} repetitor={checkRepetitorResult}/> :
                message.type == 1 ? 
                <ChatCallItem data={data} user={user} setShowCallBottomSheet={setShowCallBottomSheet} index={index}/> : 
                isDocument ? 
                <DocumentItem setContextMenuMsg={setContextMenuMsg} data={data} user={user} index={index} fromCall={fromCall} videoStreams={videoStreams} setReplyMsg={setReplyMsg}/> :
                <ChatMessageItem data={data} user={user} index={index} fromCall={fromCall} setContextMenuMsg={setContextMenuMsg} scrollToReplyMsg={scrollToReplyMsg} isIRepetitor={isIRepetitor} setDeclinePlanner={setDeclinePlanner} changeMsg={changeMsg} setErrorAcceptPlanner={setErrorAcceptPlanner} isDesktop={getIsDesktop()} videoStreams={videoStreams} setReplyMsg={setReplyMsg}/>
            }
        </div>
    )
}
export default RowMessageItem;
const ChatMessageItem = ({ data, user, index, fromCall, setContextMenuMsg, scrollToReplyMsg, isIRepetitor, setDeclinePlanner, changeMsg, setErrorAcceptPlanner, isDesktop, videoStreams, setReplyMsg }) => {
    const msg = data[index]
    const isMyMessage = msg.from_id == getCookie("id")
    const isEdited = msg.is_edited != undefined && msg.is_edited && msg.edit_date != undefined
    var text = msg.text == undefined ? "" : msg.text.includes("\n") ? ("<div>" +  msg.text.replaceAll("\n", "<br/>") + "</div>") : msg.text
    // console.log("msg = ", msg)
    const onReplyMsgClick = () => {
        scrollToReplyMsg(msg.reply_message)
    }
    // var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13
    const reply_message = msg.reply_message
    var replyUser = reply_message ? (reply_message.to_user == getCookie("id") ? reply_message.to_user : reply_message.from_user) : user
    var replyMsgText = (reply_message == undefined || reply_message.text == undefined) ? "" : reply_message.text.includes("\n") ? reply_message.text.split("\n")[0] + "..." : reply_message.text
    if (reply_message && reply_message.attachments_urls &&  reply_message.attachments_urls.length > 0){
        console.log("reply_message = ", msg)
        const attachmentUrl = reply_message.attachments_urls[0]
        const urlParams = getAllUrlParams(attachmentUrl)
        replyMsgText = urlParams.name != undefined ? urlParams.name : "Документ"
    }
    const isPlanner = msg.planner != undefined && msg.planner._id != undefined
    const findMatch = (t) => {
        var match = t.match("\\d{13}");
        if (match != null && match.length > 0){
            // console.log("match element = ", match)
            const unixtime = match[0]
            const date = moment.unix(unixtime/1000).local()
            const datePlusHour = moment.unix(unixtime/1000).local().add(1, 'hours')
            text = t.replaceAll(unixtime, date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM, dd"))
            findMatch(text)
        } else {
            return text
        }        
    }
    
    if (isPlanner){
        findMatch(text)
    }
    
    const isGroupCall = msg.sozvon_by_link != undefined && msg.sozvon_by_link._id != undefined
    const isFromGroupCall = videoStreams != undefined && videoStreams.length > 0
    var incomingUserInfo = undefined
    if (isFromGroupCall){
        const users = videoStreams.filter(u => u != undefined && u.user != undefined && u.user.id == msg.from_id && u.user.id != getCookie("id"))
        incomingUserInfo = users.length > 0 ? users[0] : undefined
    }
    if (isGroupCall){
        text = getGroupCallText(msg.sozvon_by_link)
    }

    const bind = useLongPress(() => {
        if (!isDesktop){
            showContextMenu()
        }   
    });
    const isGigaBot = msg.from_id == "1701381280570" || msg.to_id == "1701381280570"
    var elem = document.createElement('div')
    elem.innerHTML = linkify(isGigaBot ? msg.text : text)
    if (isGigaBot){
        if (elem.getElementsByTagName("code").length > 0){
            Array.from(elem.getElementsByTagName("code")).forEach(item => {
                hljs.highlightElement(item) 
            })
        }
    }
    const showContextMenu = (event) => {
        if (!isGigaBot){
            if (event != undefined){
                event.preventDefault()
            }
            setContextMenuMsg(msg)
        }
    }
    
    const hasAttachmetsImg = msg.attachments_urls && msg.attachments_urls.length > 0 && isUrlWithImage(msg.attachments_urls[0])
    const attachmentUrl = hasAttachmetsImg ? msg.attachments_urls[0] : ""
    var attachImgRatio = 99
    if (attachmentUrl != ""){
        const urlParams = getAllUrlParams(attachmentUrl)
        if (urlParams.width && urlParams.height){
            attachImgRatio = urlParams.width/urlParams.height
        }
    }
    // if (msg.repetitor_request){
    //     console.log("msg.repetitor_request = ", msg)
    // }
    if (msg.unixtime == undefined){
        return null
    }
    return (
        <div>
            
            {msg.dateTitle && <p className="chat_message_descr">{msg.dateTitle}</p>}
            <div className='flex'>
                {incomingUserInfo != undefined && <img alt="" className='chat_msg_group_avatar' src={getUserAvatar(incomingUserInfo.user_info)}/>}
                <div id={msg._id} {...!isDesktop ? bind() : undefined} onContextMenu={isGigaBot ? undefined : showContextMenu} className={isMyMessage ? "chat_opponent_msg chat_my_msg" + (fromCall ? " chat_from_call_width" : "") : "chat_opponent_msg" + (fromCall ? " chat_from_call_width" : "")} style={hasAttachmetsImg ? {border: '1px solid #EDEBF2'} : {}}>
                    {msg.repetitor_request && 
                    <a rel="noreferrer" href={Pages.ads_board + "/ads?id=" + msg.repetitor_request} target="_blank">
                        <div className="flex hover">
                            <div style={{margin : '4px 7px 4px 0'}} className="reply_msg_line"/>
                            <div>
                                <p className="editable_msg_text reply_msg_in_bubble">Отклик на объявление</p>
                                <div className="flex">
                                    <img className="ads_message_icon" src={ads_message} alt=""/>
                                    <p className="reply_msg_name" dangerouslySetInnerHTML={{__html: msg.repetitor_request_name}}></p>
                                    
                                </div>
                            </div>
                        </div>
                    </a>}
                    {(incomingUserInfo != undefined && incomingUserInfo.user_info) && <div className='chat_msg_incoming_name'>
                        <div>
                            {incomingUserInfo.user_info.name + " " + (incomingUserInfo.user_info.surname ? incomingUserInfo.user_info.surname : "")}
                        </div>
                        <img alt="" className='hover' onClick={() => setReplyMsg(msg)} src={reply_group_call}/>
                    </div>}
                    {reply_message && <div onClick={onReplyMsgClick} className="flex hover">
                            <div style={{margin : '4px 7px 4px 0'}} className="reply_msg_line"/>
                            <div>
                                <p className="reply_msg_name">{replyUser.name + " " + (replyUser.surname ? replyUser.surname : "")}</p>
                                <p className="editable_msg_text reply_msg_in_bubble" dangerouslySetInnerHTML={{__html:replyMsgText}}></p>
                            </div>
                    </div>}
                    {/* <div onClick={iOS ? showContextMenu : undefined} > */}
                    <div >
                        
                        <a rel="noreferrer" style={hasAttachmetsImg ? attachImgRatio == 99 ? {} : {display : 'block', minHeight : (!isDesktop || fromCall) ? ('calc((' + (fromCall ? incomingUserInfo != undefined ? '282px' : '328px' : '100vw') + ' - 56px)/' + attachImgRatio + ')') : 'calc(520px / ' + attachImgRatio + ')'} : {display : 'none'}} href={attachmentUrl} target="_blank"><MyImage className={fromCall ? incomingUserInfo != undefined ? "chat_msg_attachmets_image_mob_incoming hover" : "chat_msg_attachmets_image_mob hover" : "chat_msg_attachmets_image hover"} src={attachmentUrl}/></a>
                        <p style={{margin : 0, overflowWrap : 'break-word'}} dangerouslySetInnerHTML={{__html: elem.innerHTML}}></p>
                     
                        {isPlanner && <PlannerItem msg={msg} isMyMessage={isMyMessage} setDeclinePlanner={setDeclinePlanner} changeMsg={changeMsg} setErrorAcceptPlanner={setErrorAcceptPlanner}/>}
                        {isGroupCall && <GroupCallItem msg={msg} setDeclinePlanner={setDeclinePlanner} user={user} setErrorAcceptPlanner={setErrorAcceptPlanner} changeMsg={changeMsg}/>}
                        <div className={isMyMessage ? "msg_time_read_cont chat_msg_time_my" : "msg_time_read_cont chat_msg_time_opponent"}>
                            <p className="chat_msg_time">{isEdited ? "изм. " +  moment.parseZone(msg.edit_date).local().format(moment(moment.parseZone(msg.edit_date).local()).isSame(moment.unix(msg.unixtime/1000), 'day') ? "HH:mm" : "DD MMM HH:mm") : moment.unix(msg.unixtime/1000).format("HH:mm")}</p>
                            {isMyMessage && <MyImage className="readed_chat_img" src={msg.is_read ? readed_msg : not_readed_msg}/>}
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}
const DocumentItem = ({ data, user, index, fromCall, videoStreams, setReplyMsg, setContextMenuMsg }) => {
    const msg = data[index]
    const isMyMessage = msg.from_id == getCookie("id")
    const attachmentUrl = msg.attachments_urls[0]
    const urlParams = getAllUrlParams(attachmentUrl)
    const fileExtension = get_url_extension(attachmentUrl)
    const isDesktop = getIsDesktop()
    const downloadFileName = urlParams.name != undefined ? urlParams.name : getFilenameFromUrl(attachmentUrl)
    // console.log("DocumentItem msg = ", msg)
    // console.log("DocumentItem urlParams = ", urlParams)
    const isFromGroupCall = videoStreams != undefined && videoStreams.length > 0
    var incomingUserInfo = undefined
    if (isFromGroupCall){
        const users = videoStreams.filter(u => u != undefined && u.user != undefined && u.user.id == msg.from_id)
        incomingUserInfo = users.length > 0 ? users[0] : undefined
    }
    const bind = useLongPress(() => {
        if (!isDesktop){
            showContextMenu()
        }   
    })
     const showContextMenu = (event) => {
       if (event != undefined){
            event.preventDefault()
        }
        var mMsg = {...msg}
        mMsg.text = urlParams.name != undefined ? urlParams.name : "Документ"
        setContextMenuMsg(mMsg)
    }
    const hasIncomingsUserInfo = incomingUserInfo != undefined && incomingUserInfo.user_info
    return(
        <div {...!isDesktop ? bind() : undefined} onContextMenu={showContextMenu}>
            {data[index].dateTitle && <p className="chat_message_descr">{data[index].dateTitle}</p>}
            <div className='flex'>
                {hasIncomingsUserInfo && <img alt="" className='chat_msg_group_avatar' src={getUserAvatar(incomingUserInfo.user_info)}/>}
                <div style={fromCall ? {maxWidth : hasIncomingsUserInfo ? '286px' : '330px'} : {}} onClick={() => downloadFileFromUrl(attachmentUrl, downloadFileName)} className={isMyMessage ? "chat_opponent_msg chat_my_msg hover" : "chat_opponent_msg hover"}>
                    {(hasIncomingsUserInfo && incomingUserInfo.user_info) && <div style={{marginBottom : '8px'}} className='chat_msg_incoming_name'>
                        <div>
                            {incomingUserInfo.user_info.name + " " + (incomingUserInfo.user_info.surname ? incomingUserInfo.user_info.surname : "")}
                        </div>
                        {/* <img alt="" className='hover' onClick={() => setReplyMsg(msg)} src={reply_group_call}/> */}
                    </div>}
                    <div className="flex">
                        <div className="chat_document_icon">
                            <MyImage className="chat_document_download_icon" src={document_icon}/>
                        </div>
                        
                        <div>
                            <p style={fromCall ? {maxWidth: 'calc(330px - ' + (hasIncomingsUserInfo ? '102px' : '62px') + ')'} : {}} className="chat_video_title chat_doc_name_ellipse">{urlParams.name != undefined ? urlParams.name : "Документ"} {moment.unix(msg.unixtime/1000).format("DD-MM-YYYY")}</p>
                            <p style={fromCall ? {maxWidth: 'calc(330px - ' + (hasIncomingsUserInfo ? '102px' : '62px') + ')'} : {}} className="chat_video_descr chat_doc_name_ellipse">{fileExtension.toUpperCase()} {urlParams.filesize != undefined ? formatBytes(urlParams.filesize) : ""}</p>
                        </div>
                    </div>
                    <div className={isMyMessage ? "msg_time_read_cont chat_msg_time_my" : "msg_time_read_cont chat_msg_time_opponent"}>
                        <p className="chat_msg_time">{moment.unix(data[index].unixtime/1000).format("HH:mm")}</p>
                        {isMyMessage && <MyImage className="readed_chat_img" src={data[index].is_read ? readed_msg : not_readed_msg}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}
const ChatCallItem = ({ data, user, setShowCallBottomSheet, index }) => {
    const isMyMessage = data[index].from_id != user.vk_caller_id
    const text = data[index].text
    const getTimeText = () => {
        if (text.includes("_")){
            const splitArr = text.split("_")
            var time = "00:00"
            const seconds = splitArr[0]
            if (seconds < 3600){
                time = new Date(seconds * 1000).toISOString().substring(14, 19)
            } else {
                time = new Date(seconds * 1000).toISOString().substring(11, 16)
            }
            return time + " " + splitArr[1] + "₽"
        } else {
            return "Пропущен"
        }
    }
   
    return(
        <div onClick={setShowCallBottomSheet} className="hover">
            {data[index].dateTitle && <p className="chat_message_descr">{data[index].dateTitle}</p>}
            <div className={isMyMessage ? "chat_opponent_msg chat_my_msg" : "chat_opponent_msg"}>
                <div className="flex">
                    <MyImage className="chat_video_icon" src={chat_video_icon}/>
                    <div>
                        <p className="chat_video_title">{!isMyMessage ? "Входящий звонок" : "Исходящий звонок"}</p>
                        <p className="chat_video_descr">{text == "0_0" ? "Пропущен" : getTimeText()}</p>
                    </div>
                </div>
                <div className={isMyMessage ? "msg_time_read_cont chat_msg_time_my" : "msg_time_read_cont chat_msg_time_opponent"}>
                    <p className="chat_msg_time">{moment.unix(data[index].unixtime/1000).format("HH:mm")}</p>
                    {isMyMessage && <MyImage className="readed_chat_img" src={data[index].is_read ? readed_msg : not_readed_msg}/>}
                </div>
                {/* <p className={isMyMessage ? "chat_msg_time chat_msg_time_my" : "chat_msg_time chat_msg_time_opponent"}>{moment.unix(data[index].unixtime/1000).format("HH:mm")}</p> */}
            </div>
        </div>
    )
}

const PlannerItem = ({ msg, isMyMessage, setDeclinePlanner, changeMsg, setErrorAcceptPlanner }) => {
    const planner = msg.planner
    const iICreator = planner.creator_vk_caller_id == getCookie("id")
    const isIRepetitor = planner.repetitor_vk_caller_id == getCookie("id")
    const [isAcceptLoading, setAcceptLoading] = useState(false)
    // console.log("PlannerItem msg = ", msg)
    const accept = () => {
        setAcceptLoading(true)
        setErrorAcceptPlanner("")
        const params = {
            "planner_ids": msg.planner_ids,
            "other_vk_caller_id": planner.client_vk_caller_id == getCookie("id") ? planner.repetitor_vk_caller_id : planner.client_vk_caller_id
        }
        PlannerApi.acceptPlanner(params, (responseData) => {
            setAcceptLoading(false)
            var newMsg = {...msg}
            newMsg.planner.accepted = true
            changeMsg(newMsg)
        }, (responseData) => {
            setAcceptLoading(false)
            setErrorAcceptPlanner(responseData.message)
        })
    }
    const cancel = () => {
        setDeclinePlanner({isShow : true, msg : msg})
    }
    const clientText = isIRepetitor ? " клиентом" : " экспертом"
    return (
        <div>
            {iICreator ? 
                <div style={planner.accepted ? {color : '#0DCDA0'} : planner.canceled ? {color : 'var(--red)'} : {}} className="chat_planner_btn">{(!planner.accepted && !planner.canceled) ? "Ожидает подтверждения" + clientText : planner.accepted ? "Подтверждено" + clientText : "Отклонено" + clientText}</div> 
            : 
            (!planner.accepted && !planner.canceled) ? 
                <div>
                    <div onClick={accept} className="chat_planner_accept hover">
                        {isAcceptLoading ? <Loader isWhite/> : "Подтвердить"}
                    </div>
                    <div onClick={cancel} className="chat_planner_decline hover relative">
                        Отклонить
                    </div>
                </div>
            :
            <div style={planner.accepted ? {color : '#0DCDA0'} : planner.canceled ? {color : 'var(--red)'} : {}} className="chat_planner_btn">{planner.accepted ? "Подтверждено клиентом" : "Отклонено клиентом"}</div>
            }
            
            <div onClick={() => window.open(Pages.planner_info + '?id=' + planner._id, '_blank')} className="msg_planner_check_details hover">Смотреть детали</div>
        </div>)
}
const GroupCallItem = ({ msg, setDeclinePlanner, user, setErrorAcceptPlanner, changeMsg }) => {
    const planner = msg.sozvon_by_link
    const isOpen = planner.is_open == undefined || planner.is_open 
    const isICreator = planner.creator_vk_caller_id == getCookie("id")
    const isEmptyAccepted = isOpen && planner.client_vk_caller_ids_accepted.length == 0
    const isAccepted = isOpen && planner.client_vk_caller_ids_accepted.length > 0 && (planner.client_vk_caller_ids_accepted.includes(getCookie("id")) || planner.client_vk_caller_ids_accepted.includes(user.vk_caller_id + ""))
    const isFinished = new Date() > moment(planner.sozvon_date_end).utc().local().toDate()
    const [isAcceptLoading, setAcceptLoading] = useState(false)
    const [isCancelLoading, setCancelLoading] = useState(false)
    // console.log("GroupCallItem msg = ", msg)
    
   
    const accept = () => {
        setAcceptLoading(true)
        setErrorAcceptPlanner("")
        const params = {
            "_id": planner._id,
        }
        PlannerApi.acceptSozvonByLink(params, (responseData) => {
            setAcceptLoading(false)
            var newMsg = {...msg}
            newMsg.sozvon_by_link.client_vk_caller_ids_accepted.push(getCookie("id"))
            changeMsg(newMsg)
        }, (responseData) => {
            setAcceptLoading(false)
            setErrorAcceptPlanner(responseData.message)
        })
    }
    const cancel = () => {
        if (isICreator){
            window.open(Pages.group_call_info + '/' + planner._id, '_blank')
        } else {
            setCancelLoading(true)
            setErrorAcceptPlanner("")
            
            PlannerApi.cancelSozvonByLink(planner._id, "", (responseData) => {
                setCancelLoading(false)
                var newMsg = {...msg}
                if (isICreator){
                    newMsg.sozvon_by_link.is_open = false
                } else {
                    const acceptedList = newMsg.sozvon_by_link.client_vk_caller_ids_accepted
                    const foundIndex = acceptedList.findIndex((obj => obj == getCookie("id")))
                    if (foundIndex != -1){
                        newMsg.sozvon_by_link.client_vk_caller_ids_accepted.splice(foundIndex, 1)
                    }
                }
                
                changeMsg(newMsg)
            }, (responseData) => {
                setCancelLoading(false)
                setErrorAcceptPlanner(responseData.message)
            })
        }
    }
    return (
        <div>
            {(isICreator && isEmptyAccepted) && <div className="chat_planner_btn">Участие не оплачено</div>}
            {(!isICreator && isEmptyAccepted) && <div onClick={accept} style={{maxHeight : '42px', position : 'relative'}} className="msg_planner_check_details hover">{isAcceptLoading ? <Loader/> : "Оплатить участие"}</div>}
            {isAccepted && <div style={{color : '#0DCDA0'}} className="chat_planner_btn">Оплачено клиентом</div>}
            {/* {iICreator ? 
                <div style={isAccepted ? {color : '#0DCDA0'} : {}} className="chat_planner_btn">{isAccepted ? "Подтверждено клиентом" : "Отменено клиентом"}</div> 
            : 
            (isAccepted) ? 
                <div>
                    <div onClick={accept} className="chat_planner_accept hover">
                        {isAcceptLoading ? <Loader isWhite/> : "Подтвердить"}
                    </div>
                    <div onClick={cancel} className="chat_planner_decline hover relative">
                        Отклонить
                    </div>
                </div>
            :
            <div style={planner.accepted ? {color : '#0DCDA0'} : {}} className="chat_planner_btn">{isAccepted ? "Подтверждено клиентом" : "Отклонено клиентом"}</div>
            }
             */}
            {!isOpen && <div style={{color : '#FF5353'}} className="chat_planner_btn">Отменено организатором</div>}
            <div onClick={() => window.open(Pages.group_call_info + '/' + planner._id, '_blank')} className="msg_planner_check_details hover">Смотреть детали</div>
            {(!isFinished && isOpen && (isAccepted || isICreator)) && <div onClick={cancel} style={{maxHeight : '42px', position : 'relative'}} className="msg_planner_check_details hover">{isCancelLoading ? <Loader/> :isICreator ? "Отменить созвон" : "Отменить участие"}</div>}
        </div>)
}
const MessagePriceItem = ({ user, isIRepetitor, isPaidChat, repetitor }) => {
    const isZero = !isPaidChat || repetitor.rub_per_message == undefined || repetitor.rub_per_message == 0

    if (isIRepetitor || !isPaidChat){
        return null
    }
    return (
        <div>
            <div className="chat_first_message_cont">
                <p className="chat_first_message_text">Переписка с данным экспертом стоит <b>{(!isZero && repetitor.rub_per_message != undefined) ? repetitor.rub_per_message : 0}₽  за каждое ответное сообщение.</b><br/><br/>Деньги списываются только за парные сообщения.<br/><br/>Один ответ эксперта подразумевает ответ на один ваш вопрос. Если у вас в одном сообщении несколько вопросов, то эксперт может их проигнорировать.</p>
            </div>
            <p className="chat_message_descr">Сообщения в чате автоматически<br/>удаляются через месяц</p>
            <p className="chat_empty_item">asd</p>
        </div>)
}
const ShowContactsMessageItem = ({setShowOpenContacts}) => {
    
    return (
        <div>
            <div className="chat_first_message_cont">
                <p className="chat_first_message_text">Некоторые объявления создаются клиентами через сторонние сервисы или быстрые формы сбора информации, поэтому клиент может не увидеть ваше сообщение.<br/><br/>Если клиент не отвечает, свяжитесь с ним другим способом, открыв его контакты.</p>
                <div onClick={() => setShowOpenContacts(true)} className='chat_show_contacts_btn hover'>Открыть контакт</div>
            </div>
            <div style={{opacity : 0, height : '16px'}}>asd</div>
        </div>
    )
}
const styles = {
    row: {
      
    }
};
