import robot_tg from '../../../img/experts/robot_tg.png'
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getIsDesktop, Pages } from '../../../utils/Utils';
import { getCookie, setCookie } from '../../../utils/CookieHelper';

function TgNotificationsBanner(props) {
    const history = useHistory()
    const [isClosed, setClosed] = useState(getCookie("is_closed") == getCookie("id"))
    // const [isClosed, setClosed] = useState(false)

    const isDesktop = getIsDesktop()
    const addTg = () => {
        history.push(Pages.settings)
        history.push(Pages.settings + Pages.tg_auth_inform)
    }
    const onClose = () => {
        setCookie("is_closed", getCookie("id"))
        setClosed(true)
    }
    if (isClosed){
        return(<div/>)
    }

    return (
        <div className="relative">
            <div onClick={addTg} className="experts_tg_banner hover">
                <img className="experts_tg_banner_icon" src={robot_tg}/>
                <div>
                    <div className="experts_tg_banner_title">Подключите Telegram-бота</div>
                    <div className="experts_tg_banner_desc">И получайте важные уведомления</div>
                </div>
                
            </div>
            <div onClick={onClose} className="experts_tg_banner_close hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M10.4286 3.57129L3.57147 10.4284" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.57147 3.57129L10.4286 10.4284" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
    
}

export default TgNotificationsBanner;
