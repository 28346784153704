/* eslint-disable eqeqeq */
import '../../../styles/ads_desk.css';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { getIsDesktop, getUrlParameter, declension, Pages } from '../../../utils/Utils';
import back_arrow from '../../../img/experts/back_arrow.svg'
import share_ads from '../../../img/create_ads/share_ads.svg'
import delete_ads from '../../../img/create_ads/delete_ads.svg'
import arrow_right_dark from '../../../img/create_ads/arrow_right_dark.svg'
import { getEducationTargets, getHowLong, getAges, getSexes, getMyLevels, getAdsPrices } from '../CreateAds/helpers/CreateAdsHelper';
import moment from 'moment'
import views from '../../../img/profile/views.svg'
import { AdsApi } from '../../../api/AdsApi';
import { CSSTransition } from 'react-transition-group';
import Toast from '../../profile/custom_views/Toast';
import DeleteAdsDialog from './dialogs/DeleteAdsDialog';
import CreateAdsDaysPicker from '../CreateAds/custom_views/CreateAdsDaysPicker';
import AdsExpertItem from './list_items/AdsExpertItem';
import { getDesktopStyle } from '../../../utils/Styles';
import other_back from '../../../img/blogs/blogs_back.svg'
import AdsEmpty from './custom_views/AdsEmpty';

function MyPickedAds(props) {
    const history = useHistory()
    const isDesktop = getIsDesktop()
    const isDetails = getUrlParameter("is_details")
    const ads = props.ads
    const [isShowDeleteDialog, setShowDeleteDialog] = useState(false)
    const [toastText, setToastText] = useState("")
    const [isActive, setIsActive] = useState(ads.status == 0)
    const [selected, changeSelected] = useState((isDetails != undefined && isDetails != null && isDetails != "") ? 1 : 0)
    //const [comments, setComments] = useState([])
    // useEffect(() => {
    //     const id = ads == undefined ? getUrlParameter("id") : ads._id
    //     console.log("getRequestRepetitorById start")
    //     AdsApi.getRequestRepetitorById("", id, (response) => {
    //         if (response.response > 0){
    //             console.log("getRequestRepetitorById result = ", response)
    //             setComments(response.result.comments)
    //             props.setPickedAds(response.result)
    //         }
    //     })
    // }, [])
    const onBack = () => {
        if (props.isFromLink){
            window.location.href = Pages.ads_board
        } else {
            history.goBack()
        }
        //props.setPickedAds(undefined)
    }
    const toggleActive = () => {
        AdsApi.editRequestRepetitor({repetitor_request_id : ads._id, status : isActive ? 1 : 0}, (response) => {})
        props.setFromLink(true)
        setIsActive(!isActive)
    }
    

    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const deleteAds = () => {
        setShowDeleteDialog(true)
    }
    const share = () => {
        var textField = document.createElement('textarea')
        textField.innerHTML = "Здесь ищут эксперта, похожего на вас:\n" + window.location.href
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        showToast("Ссылка на объявление скопирована")
    }
    const getToolbar = () => {
        return(
            <div className="picked_ads_top_header">
                {isDesktop ? <img alt='' onClick={onBack} style={{marginLeft : '16px'}} className="ads_back_desktop hover" src={other_back}/> 
                :
                <img alt='' onClick={onBack} className="back_arrow_theme hover" src={back_arrow}/>}

                {!isDesktop ? <div className="flex">
                    <img alt='' onClick={share} className="ads_share_img hover" src={share_ads}/>
                    <img alt='' onClick={deleteAds} className="ads_share_img hover" src={delete_ads}/>
                </div> 
                : 
                <DesktopTypePicker selected={selected} changeSelected={changeSelected} count={props.comments.length}/>
                }
            </div>
        )
    }
   
    console.log("my ads = ", ads)
    return (
        <div style={isDesktop ? getDesktopStyle() : {zIndex : 2}} className="selected_settings">
            <div className="ads_archive_desktop">
                <div className="picked_ads_top_container">
                        {getToolbar()}
                        {!isDesktop && <div className="ads_picked_info_container">
                            <MyAdsHeader item={ads}/>
                            <GetCommentsSwitch ads={ads} isActive={isActive} toggleActive={toggleActive}/>
                        </div>}
                    </div>
                    {!isDesktop && <TypePicker selected={selected} changeSelected={changeSelected} count={props.comments.length}/>}
                    <div className="flex">
                        {selected == 0 ? <Comments ads={ads} history={history} comments={props.comments} user={props.user}/> : <OrderDetails ads={ads} history={history}/>}

                        {isDesktop && 
                        <div>
                            <div className="ads_picked_info_container">
                                    <MyAdsHeader item={ads}/>
                                    <GetCommentsSwitch ads={ads} isActive={isActive} toggleActive={toggleActive}/>
                            </div>
                            <div className="ads_desktop_share_cont">
                                <div onClick={share} className="flex hover">
                                    <img alt='' className="ads_share_img hover" src={share_ads}/>
                                    <div className="ads_desktop_share_text">Поделиться</div>
                                </div>
                                <div onClick={deleteAds} className="flex hover">
                                    <img alt='' className="ads_share_img hover" src={delete_ads}/>
                                    <div className="ads_desktop_share_text">Удалить</div>
                                </div>
                            </div>
                        </div>}
                    </div>
            </div>
            <CSSTransition in={toastText != ""} timeout={300} classNames="animation_in" unmountOnExit>
                <Toast text={toastText}/>
            </CSSTransition>
            <DeleteAdsDialog ads={ads} isShow={isShowDeleteDialog} setShow={setShowDeleteDialog}/>
        </div>
    )
}

export default MyPickedAds;
const Comments = ({ ads, history, comments, user }) => {
    const setSelectedProfile = () => {

    }
    return(
        <div style={{width : '100%'}} className="flex">
            {comments.length == 0 ? 
                //<div className="ads_mypicker_comments_empty">Здесь будут отображаться отклики экспертов</div>
                <div className="ads_mypicked_bg"><AdsEmpty title="Здесь будут отображаться отклики экспертов"/></div>
                : 
                <div className="ads_mypicker_info_container"> 
                {comments.length > 0 && comments.map((item, index) => (
                    <AdsExpertItem count={comments.length} comment={item} myUser={user} setSelectedProfile={setSelectedProfile} index={index} key={index}/>
                ))}
            </div>}
        </div>
        
    )
}
const OrderDetails = ({ ads, history }) => {
    const getUrl = (step, value) => {
        return "/create_ads/step_" + step + "?is_edit=true&id=" + ads._id + "&value=" + value
    }
    const extraData = ads.extra_data == undefined ? "" : ads.extra_data.includes("\n") ? ("<div>" +  ads.extra_data.replaceAll("\n", "<br/>") + "</div>") : ads.extra_data
    const formatDataForUrl = ads.extra_data == undefined ? "" : ads.extra_data.includes("\n") ? ads.extra_data.replaceAll("\n", "<br/>") : ads.extra_data
    return(
        <div className="ads_mypicker_info_container">
            <div className="ads_item_spec">{ads.specialization}</div>
            <div className="ads_mypicker_title">{ads.name}</div>
            <div className="ads_mypicker_line"/>
            <InfoRow title="Цель:" desc={getEducationTargets()[ads.target_education].toLocaleLowerCase()} onClick={() => {history.push(getUrl(3, ads.target_education))}}/>
            <InfoRow title="Как долго планируете заниматься:" desc={getHowLong()[ads.training_period].toLocaleLowerCase()} onClick={() => {history.push(getUrl(7, ads.training_period))}}/>
            <InfoRow title="Возраст клиента:" desc={getAges()[ads.age].toLocaleLowerCase()} onClick={() => {history.push(getUrl(5, ads.age))}}/>
            <InfoRow title="Желаемый пол эксперта:" desc={getSexes()[ads.sex_repetitor].toLocaleLowerCase()} onClick={() => {history.push(getUrl(9, ads.sex_repetitor))}}/>
            {(ads.desired_class_time.length > 0 && ads.desired_class_time[0] != '') && <div onClick={() => {history.push(getUrl(8, ads.desired_class_time))}} className="hover">
                <p className="ads_mypicker_days_title">Желаемые дни и время занятий:</p>
                <CreateAdsDaysPicker time={ads.desired_class_time}/>
                <div style={{marginTop : '16px'}} className="ads_mypicker_line"/>
            </div>}
            <InfoRow title="Уровень знаний клиента в данной области:" desc={getMyLevels()[ads.level_my].toLocaleLowerCase()} onClick={() => {history.push(getUrl(4, ads.level_my))}}/>
            <InfoRow title="Пожелания к заявке:" desc={extraData} onClick={() => {history.push(getUrl(10, formatDataForUrl))}} isExtraData/>
            <InfoRow title="Стоимость:" desc={ads.level_repetitor == 0 ? "Цена не имеет значения" : getAdsPrices()[ads.level_repetitor]} onClick={() => {history.push(getUrl(6, ads.level_repetitor))}} isPrice/>
        </div>
    )
}
const InfoRow = ({ title, desc, onClick, isExtraData, isPrice }) => {
    return(
        <div>
            <div onClick={onClick} className="ads_mypicker_row hover">
                <div className="ads_picked_info_line_title ads_mypicker_row_margin">
                    <span className="ads_opacity_05">{title} </span>
                    {/* <div dangerouslySetInnerHTML={{__html: desc}}/> */}
                    {isExtraData ? <div style={{marginTop : '4px'}} dangerouslySetInnerHTML={{__html: desc}}/> : isPrice ? <div className="ads_mypicker_price">{desc}</div> : desc}
                </div>
                <img alt='' className="ads_mypicker_arrow" src={arrow_right_dark}/>
            </div>
            {!isPrice && <div className="ads_mypicker_line"/>}
        </div>
        
    )
}
const GetCommentsSwitch = ({ ads, isActive, toggleActive }) => {
    return(
        <div className="ads_get_comments_container">
            <div>
                <p className="ads_get_comments_title">Получать отклики</p>
                <p className="ads_get_comments_desc">{ads.views + " " + declension(ads.views, "эксперт", "эксперта", "экспертов")} увидели вашу заявку</p>
            </div>
            <Slider value={isActive} setValue={toggleActive}/>
        </div>
    )
}
const Slider = ({ value, setValue }) => {
    return(
        <label style={{marginTop : '4px'}} className="switch">
            <input type="checkbox" onChange={setValue} checked={value}/>
            <div className="slider round"></div>
        </label>
    )
}
const TypePicker = ({ selected, changeSelected, count }) => {
    return(
        <div className="ads_mypicker_types">
            <SelectedItem title={count == 0 ? "Отклики" : "Отклики " + count} index={0} selected={selected} setSelected={changeSelected}/>
            <SelectedItem title="Детали объявления" index={1} selected={selected} setSelected={changeSelected}/>
        </div>
    )
}
const DesktopTypePicker = ({ selected, changeSelected, count }) => {
    return(
        <div>
            <div className="ads_desktop_type">
                <div onClick={() => changeSelected(0)} style={selected == 0 ? {} : {color : '#818C99'}} className="ads_desktop_type_cont hover">{"Отклики " + count}</div>
                <div onClick={() => changeSelected(1)} style={selected == 1 ? {} : {color : '#818C99'}} className="ads_desktop_type_cont hover">Детали объявления</div>
            </div>
            <div style={selected == 0 ? {} : {marginLeft : '167px', width : '196px'}} className="ads_desktop_type_line"/>
        </div>
    )
}
const SelectedItem = ({ title, index, selected, setSelected }) => {
    return (
        <div onClick={() => setSelected(index)} style={index == selected ? {background : 'var(--dark_purple)', color : 'white'} : {}} className="subjects_nav_title hover">
            {title}
        </div>
    )
}
const MyAdsHeader = ({ item }) => {
    return(
        <div>
            <div className="my_ads_item_title">{item.specialization == "" ? item.user_query : item.specialization}</div>

            <div style={item.comments.length > 0 ? {marginTop : '4px'} : {marginTop : '4px'}} className="my_ads_item_date_row">
                <div className="ads_item_profile_text">Создано {moment.unix(moment(item.create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD MMMM")}</div>
                <div style={{marginTop : '4px'}} className="flex">
                    <img alt='' className="profile_views_img" src={views}/>
                    <p className="profile_views_text">{item.views}</p>
                </div>
            </div>
            <div className="ads_item_header_title ads_item_price_mb">{getAdsPrices()[item.level_repetitor]}</div>

        </div>
    )
}