/* eslint-disable eqeqeq */
import moment from 'moment';
import { Pages, getUserAvatar } from '../../../utils/Utils';
import { getCookie } from '../../../utils/CookieHelper';
import group_call_avatar from '../../../img/planner/group_call_avatar.svg'

function PlannedPlannerItem(props) {
    const item = props.item
    const is_group_call = item.creator
    const userItem = is_group_call ? item.creator : item.client_vk_caller_id == getCookie("id") ? item.repetitor : item.client
    const link = is_group_call ? Pages.group_call_info + "/" + item._id : Pages.planner_info + "?id=" + item._id
    const isFromMainScreen = props.isFromMainScreen
    const secondsToStart = Math.round(moment(item.sozvon_date).utc().local().unix() - new Date()/1000)
    const isCanceled = is_group_call ? !item.is_open : item.canceled
    const isLess5min = isCanceled || (isFromMainScreen && secondsToStart < 300)
    //const isLess5min = true

    const mainScreenStyle = {margin : '8px 16px 2px 0', minWidth : '242px', border : isLess5min ? isCanceled ? '3px solid rgba(255, 83, 83, 0.30)' : '3px solid rgba(49, 248, 201, 0.50)' : ''}
    const headerStyle = {borderRadius: '16px 16px 0px 0px', background: isCanceled ? '#FF5353' : '#31F8C9', color : 'black', opacity : '1', margin: '-16px -19px 0 -19px', padding: '6px 8px 6px 8px'}
    const minutesToStart = Math.round(secondsToStart / 60)
    const date = isLess5min ? moment(item.sozvon_date).utc().local().format("HH:mm · ") + (minutesToStart <= 0 ? ((is_group_call && (item.creator.vk_caller_id + "") != getCookie("id")) ? "уже идет" : "пора начинать") : "через " + minutesToStart + ' мин.') : moment(item.sozvon_date).utc().local().format("HH:mm · DD MMMM, dd")
    const isAdsInProfile = props.isAdsInProfile
    const isNotPaid = !isAdsInProfile && is_group_call && item.creator_vk_caller_id != getCookie("id") && !item.client_vk_caller_ids_accepted.includes(getCookie("id"))
    return(
        <a rel="noreferrer" href={link} target="_blank">
            <div style={isFromMainScreen ? mainScreenStyle : item.dateTitle == undefined ? {marginTop : '8px'} : {}} className={isAdsInProfile ? "planned_item hover planned_ads_profile_item" : "planned_item hover"}>
                <div style={isLess5min ? headerStyle : {}}  className="planned_item_just">
                    <div className="planned_item_date">{isCanceled ? moment(item.sozvon_date).utc().local().format("HH:mm · DD MMM" + " · отменено") : date}</div>
                    {is_group_call && <div>
                        {!item.is_open ?
                        <div></div> 
                            :
                        isNotPaid ? 
                        <div className="planner_item_not_paid">не оплачено</div> 
                        :
                        <div className="planned_item_date">{item.price ? item.price : "0"}₽</div>}
                        
                    </div>}
                </div>
                <div className="planned_item_just">
                    <div style={{minHeight : '57px'}}>
                        <div className="planned_item_name">
                           <div className='planned_item_name_span'>{is_group_call ? item.sozvon_name : item.sozvon_name}</div>
                        </div>
                        <div className="planned_item_name_desc">{is_group_call ? "Группа · до " + (item.max_count_persons != undefined ? item.max_count_persons : 99) + " чел."  : userItem.name}</div>
                    </div>
                    <img alt="" src={is_group_call ? group_call_avatar : getUserAvatar(userItem)} className="planned_item_avatar"/>
                </div>
            </div>
        </a>
    )
}
export default PlannedPlannerItem;
