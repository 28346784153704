/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useCallback, useRef, useEffect } from 'react';
import CreateAdsHeader from './custom_views/CreateAdsHeader';
import CreateAdsButtons from './custom_views/CreateAdsButtons';
import Loader from '../../../custom_views/Loader';
import { useHistory } from "react-router-dom";
import autosize from 'autosize';
import { getCookie, getRequestParams, setCookie } from '../../../utils/CookieHelper';
import { AuthApi } from '../../../api/AuthApi';
import { getUrlParameter } from '../../../utils/Utils';
import AuthErrorTooltip from '../../auth/AuthErrorTooltip';
import CodeTimer from '../../auth/custom_views/CodeTimer';

function CreateAdsSmsCode(props) {
    const [isLoading, setLoading] = useState(false)
   const [code, setCode] = useState("");
    const [code_1, setCode_1] = useState("");
    const [code_2, setCode_2] = useState("");
    const [code_3, setCode_3] = useState("");
    const [code_4, setCode_4] = useState("");
    const [isCodeError, setCodeError] = useState("");
    const [timerFinished, setTimerFinished] = useState(false);
    const inputRef = useRef(null);
    const history = useHistory()
    const toggleCodeError = () => { setCodeError(current => !current)}
    const inputSelectedStyle = { border: '2px solid #322267' }
    useEffect(() => {
        inputRef.current.focus();
        autosize(inputRef.current);
    }, [])
    
    const codeEntered = (text) => { 
        const enteredCode = text
        if (isCodeError){
            setCodeError("")
        }
        if (enteredCode.length < 5){
            setCode(enteredCode)
            switch (enteredCode.length) {
                case 0:
                    setCode_1("")
                    break;
                    case 1:
                        setCode_1(enteredCode[0])
                        setCode_2("")
                        break;
                    case 2:
                        setCode_2(enteredCode[1])
                        setCode_3("")
                        break;
                    case 3:
                        setCode_3(enteredCode[2])
                        setCode_4("")
                        break;
                    case 4:
                        setCode_4(enteredCode[3])
                        break;
                default:
                    break;
            }
        }
    }
    const confirmPhoneNumber = () => {
        if (isLoading){
            return
        }
        if (isCodeError){
            toggleCodeError()
        }
        setLoading(true)
        var params = getRequestParams()
        params.phone_number = getUrlParameter("phone")
        params.code = code
        console.log("confirmPhoneNumber params = " + JSON.stringify(params))
        AuthApi.confirmPhoneNumber(params, (responseData) => {
            setCookie("token", responseData.access_token)
            window.location.href = '/create_ads/step_11?publish=1'
            setLoading(false)
        }, (responseData) => {
            // toggleCodeError()
            setCodeError(responseData.message)
            setLoading(false)
        })
    }
   
    const callAgain = () => {
        var params = getRequestParams()
        const savedPromoCode = getCookie("promocode")
        if (savedPromoCode != undefined && savedPromoCode != null && savedPromoCode != ""){
            params.promo_code = savedPromoCode
        }
        var mPhone = getUrlParameter("phone")
        params.phone_number = mPhone
        console.log("registerUser params =" + JSON.stringify(params))
        AuthApi.registerUser(params, (responseData) => {
          
        }, () => {})
        setTimerFinished(false)
    }
  
    const onKeyDownPressed = (e) => {
        
        if (e.key === 'Enter' && code.length == 4) {
            e.preventDefault()
            confirmPhoneNumber()
        }
    }
    var isCodeEntered = code.length == 4
    
    
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className='create_ads_confirm_phone'>Подтвердите номер телефона</div>
                    <div className='create_ads_confirm_desc'>Мы отправили код<br/>на номер +{getUrlParameter("phone")}</div>
                <div className='relative'>
                    <div onKeyDown={onKeyDownPressed} className="code_input_container">
                        <div className="code_inputs">
                            <input style={code_1 == "" ? inputSelectedStyle : {}} className="ads_code_input" value={code_1} onChange={(e) => setCode_1(e.target.value)}/>
                            <input style={(code_1 != "" && code_2 == "") ? inputSelectedStyle : {}} className="ads_code_input"  value={code_2} onChange={(e) => setCode_2(e.target.value)}/>
                            <input style={(code_2 != "" && code_3 == "") ? inputSelectedStyle : {}} className="ads_code_input" value={code_3} onChange={(e) => setCode_3(e.target.value)}/>
                            <input style={(code_3 != "" && code_4 == "") ? inputSelectedStyle : {}} className="ads_code_input" value={code_4} onChange={(e) => setCode_4(e.target.value)}/>
                        </div>
                        <input ref={inputRef} className="hidden_code_input" type="tel" value={code} onChange={(e) => codeEntered(e.target.value)}/>
                    </div>
                    {isCodeError && <AuthErrorTooltip styleMain={{position : 'absolute', zIndex : '2',  left: '50%', transform:' translate(-50%, 0)', marginTop : '8px'}} text={isCodeError}/>}
                </div>
                
                {timerFinished ? <p onClick={callAgain} style={{color : '#322267'}} className="call_again_btn hover">Выслать код снова</p> 
                :
                <div style={{marginBottom : '16px'}}>
                    <CodeTimer setTimerFinished={setTimerFinished}/>
                </div>}
                
                
                <div style={isCodeEntered ? {} : {opacity : 0.2, pointerEvents : 'none'}} onClick={confirmPhoneNumber} className='ads_auth_btn hover'>{isLoading ? <Loader style={{minHeight : '56px', marginTop : '-16px', position : 'relative'}} isWhite/> : "Далее"}</div>

                </div>
                <CreateAdsButtons isEdit={props.isEdit}/>
            </div>
        </div>
    );
}

export default CreateAdsSmsCode;
