/* eslint-disable eqeqeq */
import qs from 'querystring'
import { getCookie, setCookie, deleteCookie } from './CookieHelper';
import moment from 'moment'
import punycode from 'punycode'
import React from 'react';
import { UsersApi } from '../api/UsersApi';
import avatar from '../img/avatar.svg'
import badge from '../img/profile/badge.svg'
import badge_seleb from '../img/profile/badge_seleb.svg'
export const getHttpParams = (params) => {
    var httpParams = {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', "access_token" : getCookie("token")},
        body: qs.stringify(params)
    }
    return httpParams;
};

export const getRequestUrl = () => {
    return "https://sozvon.pro/api/";
};
export const getDomainUrl = () => {
    // return punycode.toUnicode(window.location.origin)
    return 'https://репетиторс.рф'
}
export const getVkSdkUrl = () => {
    return "https://api.ok.ru";
};
export const getApiKey = () => {
    return "CDIKHMKGDIHBABABA";
};
export function isValidUrl (urlString) {
	  	var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	  return !!urlPattern.test(urlString);
	}
export function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? undefined : decodeURIComponent(sParameterName[1]);
        }
    }
};
export function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : "";
}
export const declension = (count, one, two, five) => {
    var realCount = count;
  
    if (count > 100)
        count %= 100;
  
    if (count > 20)
        count %= 10;
  
    switch (count) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return two;
        default:
            return five;
    }
}
export const filterByDate = (responseMessages, date) => {
    const nowDate = Date()
    const filterDate = moment(date).utc().local().toDate()
    console.log("filtedByDate = ", filterDate)
    return responseMessages.filter(m => moment.unix(m.unixtime/1000).utc().local().toDate() >= filterDate)
}
export const filterDuplicatesMsgs = (responseMessages) => {
    return responseMessages.filter((e, i, a) => {
                        if (i == 0){
                            return true
                        } else if (i > 0 && e.message_id_for_group_chat && a[i - 1].message_id_for_group_chat){
                            return e.message_id_for_group_chat !== a[i - 1].message_id_for_group_chat
                        } else {
                            return true
                        }
                    })
}
export const setDateTitles = (responseMessages) => {
    var prevDate = ""
    responseMessages.forEach(element => {
        var date = moment.unix(element.unixtime/1000).format("DD MMM")
        var create_date = moment.unix(element.unixtime/1000)
        // if (create_date.date() == moment().date()){
        //     date = "Сегодня"
        // }
        // if (create_date.date() == moment().add(1,'days').date()){
        //     date = "Завтра"
        // }
        // if (create_date.date() == moment().add(-1,'days').date()){
        //     date = "Вчера"
        // }
        if (create_date.dayOfYear() ===  moment().dayOfYear() && create_date.year() == moment().year()){
            date = "Сегодня"
        }
        const tomorrow = moment().add(1,'days')
        if (create_date.dayOfYear() ===  tomorrow.dayOfYear() && create_date.year() == tomorrow.year()){
            date = "Завтра"
        }
        const yesterday = moment().add(-1,'days')
        if (create_date.dayOfYear() ===  yesterday.dayOfYear() && create_date.year() == yesterday.year()){
            date = "Вчера"
        }
        if (prevDate != date){
            prevDate = date
            element.dateTitle = date
        }
    })
    return responseMessages
}

export const setDatesForTransactions = (responseMessages) => {
    var prevDate = ""
    responseMessages.forEach(element => {
        const create_date = moment(element.create_date).utc().local()
        var date = create_date.format("DD MMMM")
        
        if (create_date.dayOfYear() ===  moment().dayOfYear() && create_date.year() == moment().year()){
            date = "Сегодня"
        }
        const tomorrow = moment().add(1,'days')
        if (create_date.dayOfYear() ===  tomorrow.dayOfYear() && create_date.year() == tomorrow.year()){
            date = "Завтра"
        }
        const yesterday = moment().add(-1,'days')
        if (create_date.dayOfYear() ===  yesterday.dayOfYear() && create_date.year() == yesterday.year()){
            date = "Вчера"
        }

        if (prevDate != date){
            prevDate = date
            element.dateTitle = date
        }
    })
    return responseMessages
}
export const calculateAge = (birthday) => { // birthday is a date
    if (birthday == undefined){
        return ""
    }
    // var ageDifMs = Date.now() - moment(birthday, "YYYY-MM-DD").unix()
    // var ageDate = new Date(ageDifMs); // miliseconds from epoch
    // return Math.abs(ageDate.getUTCFullYear() - new Date(moment(birthday, "YYYY-MM-DD")).getUTCFullYear());
    return moment().diff(birthday, 'years')
}
export const backViewStyle = () => {
    return {overflow : 'hidden', height : '100%', width : '100%', marginTop : 0, position : 'fixed'}
}
export const getHiddenViewStyle = () => {
    return {pointerEvents : 'none', overflow : 'hidden', zIndex : '-1', position : 'fixed', marginTop : '0', width : '100%'}
}
export const hiddenExpertsStyle = () => {
    return {margin: 0, height: '100%', overflowY: 'hidden', position : 'absolute'}
}
export const getReviewsCount = (user) => { 
    if (user == undefined || user.count_1_grade == undefined){
        return 0
    }
    return user.count_1_grade + user.count_2_grade + user.count_3_grade + user.count_4_grade + user.count_5_grade
}
export const getRating = (user) => { 
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade
    const rating = countReviews == 0 ? 0 : (user.count_1_grade + (user.count_2_grade*2) + (user.count_3_grade*3) + (user.count_4_grade*4) + (user.count_5_grade*5)) / countReviews
    return parseFloat(rating).toFixed(1)
}
export const getUnixDateFromString = (date) => { // birthday is a date
    var parsedDate = moment(date, "YYYY-MM-DD").unix()
    return parsedDate
}
export const getIsDesktop = () => {
    return window.innerWidth >= 960
}
export const getUnreadCountMsgs = (chat) => {
    var countNoRead = 0
    if (chat == undefined || chat.dialog == undefined){
        return 0
    }
    const dialog_id = chat.dialog.dialog_id
    const dialog_ids = dialog_id.split("_")
    if (dialog_ids[0] == getCookie("id")){
        countNoRead = chat.dialog.count_no_read_messages
    } else if (dialog_ids[1] == getCookie("id")) {
        countNoRead = chat.dialog.count_no_read_messages2
    }
    return countNoRead
}
export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    }
}

export const setModelParam = (model, key, value, method) => {
    const m = {...model}
    m[key] = value
    method(m)
}
export const getAuthUrlWithPrev = () => {
    var authUrl = Pages.auth + "?prev_url=" + window.location.pathname
    // if (window.location.pathname.includes("users")){
    //     authUrl += "?prev_url=" + window.location.pathname
    // }
    return authUrl
}
export const MyImage = React.memo(function Image({ src, className }) {
    return <img alt="" src={src} className={className} />;
});
export const exitProfile = () => {
    const pushToken = getCookie("push_token")
    if (pushToken != undefined && pushToken != null && pushToken != ""){
        UsersApi.removePushToken(pushToken, () => {})
    }
    deleteCookie("token")
    deleteCookie("id")
    deleteCookie("push_token")
    // setCookie("token", null)
    window.location.href = '/auth/phone'
}
export const checkAfterAuth = () => {
    const prev_url = getCookie("prev_url")
    if (prev_url != null && prev_url != undefined && prev_url != ""){
        setCookie("prev_url", "")
        window.location.href = prev_url
        return true
    }
    const saved = localStorage.getItem('savedAds')
    if (saved != undefined && saved != "undefined" && saved != null && saved != ""){
        const ads = JSON.parse(saved)
        if (ads.name != undefined && ads.name != ""){
            window.location.href = "/create_ads/step_11"
            return true
        }
    }
    return false
}
export const Define = {
    bot_id: '6057707032',
}

export const getDomainNameWithoutHttps = () => {
  var domain_name = window.location.origin
  if (domain_name.includes("https://")){
      domain_name = domain_name.split("https://")[1]
  }
  return domain_name
}
export const Pages = {
    //auth
    auth_main: "/auth",
    auth: "/auth/phone",
    auth_phone:"/auth/phone_type",
    tg_bot:"https://t.me/notification_sozvon_bot",
    //experts
	experts: "/experts",
	theme: "/theme",
    specializations: "/specializations",
    search: "/search",
    list: "/каталог",
	bookmarks: "/bookmarks",
    //profile
	profile: "/profile",
	profile_ads: "/profile_ads",
	profile_ads_advices: window.location.origin + "/advices_profile",
	lk: "/lk",
	chats: "/chats",
	reviews: "/reviews",
	verification: "/verification",
	edit_profile: "/edit_profile",
	
	express_search_main: "/express_search",
	express_search: "/express_search/step_1",
	//profile_filling
    profile_filling : "/profile_filling",
    //pay
    pay : "/pay",
    pay_result : "/pay_result",
    //ads
    ads_board: "/ads_board",
    create_ads_main: "/create_ads",
	create_ads: "/create_ads/step_1",
    //answers
	answers: "/answers",
    //other
    other: "/other",
	referals: "/other/referals",
	rules: "/other/rules",
	settings: "/other/settings",
	tg_auth_inform: "/tg_auth_inform",
	promocode: window.location.origin + "/?promocode=",
    pro: "/pro",
    //landing
	support: window.location.origin + "/support",
	landing_for_experts: window.location.origin + "/about_experts",
	landing_for_clients: window.location.origin,
	vk_group: "https://vk.com/sozvon_pro",
	terms: window.location.origin + "/terms",
	advices_profile: window.location.origin + "/advices_profile",
	
    users: "/users",
    //blogs
    blogs_post: "/blogs_post",
    blogs: "/blogs",
	create_blog_post: "/create_blog_post",
	blogs_filter: "/blogs/filter",
	blogs_faq: window.location.origin + "/blogs_faq",
    //boards
    work_boards: "/work_boards",
    work_boards_new: "/work_boards",
    boads_faq: window.location.origin + "/desk_faq",
    
    promotion: "/promotion",
    //planner
    create_planner: "/create_planner",
    planned_planner: "/planned_planner",
    course: "/course",
    planner_info: "/planner_info",
    //chat
    call_chat: "/call/chat",
    any_chat: "/*/chat",
    
    
    support_chat : "/chats/chat?id=1677790397579",
    call_board : "https://desk." + getDomainNameWithoutHttps() + "/boards/",
    planner_rules : "https://drive.google.com/file/d/1I2ineTyLvTOQoiQr7Wh7Q2uF_i6uoLtD/view",
    //policies
    privacy_policy: window.location.origin + "/docs/privace_policy.pdf",
    agreement: window.location.origin + "/docs/conditions.pdf",
    regina_help: window.location.origin + "/chats/chat?id=1677790397579",
    pro_policy: "https://drive.google.com/file/d/1x7-YjhN8r-2Zg8GEKqAhtoOMLcUzih1l/view?usp=drive_link",
    //group call
    group_call : "/group_call",
    group_call_info : "/c",
    create_video_meeting : "/create_video_meeting",

}

export const getNotificationFormat = (millisecs) => {
    const sec_num = parseInt(millisecs / 1000, 10)
    const days   = Math.floor(sec_num / 86400)
    const hours   = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const result = days > 0 ? ("за " + days + declension(days, " сутки", " суток", " суток")) : hours > 0 ? ("за " + hours + declension(hours, " час", " часа", " часов")) : ("за " + minutes + " мин.")
    // console.log("getNotificationFormat millisecs = ", millisecs)
    // console.log("getNotificationFormat result = ", result)
    return result
}
export const isDateToday = (date) => {
    return moment.parseZone(date).local().isSame(new Date(), "day")
}
export const getTimePaid = (user, priceHour) => {
    var totalSeconds = (user.count_rub / priceHour) * 3600
    var hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    var minutes = Math.floor(totalSeconds / 60);
    const minutesText = minutes + " " + declension(minutes, "минута", "минуты", "минут")
    return hours > 0 ? (hours + " ч. " + minutesText) : minutesText
}
export const convertUTC = (date, mask) => {
    return moment.parseZone(date).local().format(mask)
}

export const isUrlWithImage = (url) => {
    // return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    return url.includes(".jpeg") || url.includes(".jpg") || url.includes(".png")
}
export const CompOS = {
    macos: "macos",
    ios: "ios",
    windows: "windows",
    android: "android",
    linux: "linux",
}
export const VideoType = {
    none: "none",
    youtube: "youtube",
    rutube: "rutube",
    vk: "vk"
}
export const CallHandType = {
    up: "up",
    down: "down",

}
export const changeVkVideoUrl = (youtubeVideoUrl) => {
    // const searchParams = new URLSearchParams(youtubeVideoUrl)
    var videoParams = ''
    if (youtubeVideoUrl.includes("z=video")){
        videoParams = youtubeVideoUrl.substring(youtubeVideoUrl.indexOf("z=video") + 7, youtubeVideoUrl.length)
    } else if (youtubeVideoUrl.includes("/video")){
        videoParams = youtubeVideoUrl.substring(youtubeVideoUrl.indexOf("/video") + 6, youtubeVideoUrl.length)
    }
    // console.log("changeVkVideoUrl youtubeVideoUrl = ", youtubeVideoUrl)
    // console.log("changeVkVideoUrl videoParams = ", videoParams)
    if (videoParams.includes("_")){
        const arr = videoParams.split("_")
        const url = 'https://vk.com/video_ext.php?oid=' + arr[0] + '&id=' + arr[1]
        // console.log("changeVkVideoUrl = ", url)
        return url
    } else {
        return ''
    }
}
export const getVideoType = (user) => {
    const youtubeVideoUrl = user.video_url_youtube
    const hasVideo = !(youtubeVideoUrl == undefined || youtubeVideoUrl == "")
    if (!hasVideo){
        return VideoType.none
    }
    return youtubeVideoUrl.includes("vk.com/") ? VideoType.vk : VideoType.youtube
}
export const getOS = () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;
  
    if (macosPlatforms.test(userAgent)) {
      os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }
  
    return os;
}
export const parseBalance = (user) => {
    return user.count_rub == undefined ? "" : Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
}
export const getUserAvatar = (user) => {
    return user == undefined ? avatar : user.avatar ? user.avatar : avatar
}
export const getUserBadge = (user) => {
    return user.verified ? badge_seleb : badge
}

export const showToast = (text, setToastText) => {
    setToastText(text)
    setTimeout(function() {
        setToastText("")
    }, 2000)
}
export const getTargetStatsUrl = (user) => {
    var url = ""
    if (user.short_url_vk != undefined && user.short_url_vk.includes("https://vk.cc/")){
        url = "https://vk.com/cc?act=stats&key=" + user.short_url_vk.split("https://vk.cc/")[1]
    }
    return url
}
export const setPlannerDateTitles = (responseMessages) => {
    var prevDate = ""
    responseMessages.forEach(element => {
        const sozvon_date = moment(element.sozvon_date).utc().local()
        var date = sozvon_date.format("DD MMM")
        if (sozvon_date.dayOfYear() ===  moment().dayOfYear() && sozvon_date.year() == moment().year()){
            date = "Сегодня"
        }
        const tomorrow = moment().add(1,'days')
        if (sozvon_date.dayOfYear() ===  tomorrow.dayOfYear() && sozvon_date.year() == tomorrow.year()){
            date = "Завтра"
        }
        if (prevDate != date){
            prevDate = date
            element.dateTitle = date
        }
    })
    return responseMessages
}