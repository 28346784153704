import chat_white from '../../../img/profile/chat_white.svg'
import call_white from '../../../img/profile/call_white.svg'
import Loader from '../../../custom_views/Loader';
function CallWriteButtons(props) {
    
    return (
        <div className="flex">
            <div onClick={props.onWrite} style={{marginRight : '12px'}} className="call_write_btn hover">
                <div className="flex">
                    <img alt='' src={chat_white}/>
                    <p className='profile_header_btn_text'>Написать</p>
                </div>
            </div>
            <div onClick={props.onCall} style={{marginLeft : '12px'}} className="call_write_btn hover">
                {props.isLoading ? <Loader isWhite/> : <div className="flex">
                    <img alt='' src={call_white}/>
                    <p className='profile_header_btn_text'>Позвонить</p>
                </div>}
            </div>
        </div>
    );
}

export default CallWriteButtons;
