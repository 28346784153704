
/* eslint-disable eqeqeq */
function Toast(props) {
    var text = props.text
    var clickText = ""
    if (text.includes("&&")){
        const arr = text.split("&&")
        text = arr[0]
        clickText = arr[1]
    }
    if (text == ""){
        return (<div></div>)
    }
    return (
        <div style={props.style != undefined ? props.style : {}} className="profile_toast flex">
            {text}
            {clickText != "" && <a rel="noreferrer" className="profile_click_text_toast hover" target="_blank" href={clickText}>Смотреть</a>}
        </div>
    );
}

export default Toast;
