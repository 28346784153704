import { getCookie } from "../../../utils/CookieHelper";
import { getRequestUrl } from "../../../utils/Utils";
import { DialogsApi } from "../../../api/DialogsApi";
import moment from "moment";

export const uploadChatFiles = (file, result, to_user, setErrorSize, onMessageSentWithAttach, videoStreams, setFileUploading) => {
    var img = new Image; 
    var pickedPhoto = {}
    var pickedFile = {}
    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    const showFileUploader = () => {
        if (setFileUploading){
            setFileUploading(true)
        }
    }
    const hideFileUploader = () => {
        if (setFileUploading){
            setFileUploading(false)
        }
    }
    function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            //etc
            return true;
        }
        return false;
    }
    const sendMessage = (url, to_user) => {
        //onMessageSentWithAttach(url)
        console.log("uploadChatFile videoStreams = ", videoStreams)
        console.log("uploadChatFile to_user = ", to_user)
        if (videoStreams && videoStreams.length > 0){
            const unixtime = moment().unix()
            for (let index = 0; index < videoStreams.length; index++) {
                const id = videoStreams[index].user.id;
                DialogsApi.sendMessage("", id, undefined, (responseData) => {}, () => {}, url, unixtime)
            }
           
        } else {
            DialogsApi.sendMessage("", to_user, undefined, (responseData) => {}, () => {}, url)
        }
        
    }
    const uploadPickedFile = () => {
        if (pickedFile.size > 10000000){
            setErrorSize("Размер файла не должен превышать 10Mb")
            return
        }
        showFileUploader()
        setErrorSize("")

        var headers = {
            access_token: getCookie("token")
        }        
        const params = {
            method: "POST",
            body: pickedFile,
            headers: headers,
        }

        fetch(getRequestUrl() + "uploadMedia?name=" + pickedFile.name, params).then((response) => response.json())
                    .then((responseData) => {
                        console.log("uploadPickedFile responseData = ", responseData)
                        if (responseData.response > 0 && responseData.url){
                            console.log("sendMessage responseData.url = ", responseData.url)
                            sendMessage(responseData.url, to_user)
                        }
                            hideFileUploader()
                    })
                    .catch((error) => {
                        console.error(error);
                    });
    }
    const uploadPhoto = () => {
        console.log("uploadPhoto 123")
        if (pickedPhoto.size > 10000000){
            setErrorSize("Размер файла не должен превышать 10Mb")
            return
        }
        setErrorSize("")
        const params = {
            method: "POST",
            body: pickedPhoto,
            headers: {
                access_token: getCookie("token"),
                width : img.width,
                height : img.height
                // name : pickedPhoto.name
            },
        }
        console.log("uploadPhoto params = ", params)
        fetch(getRequestUrl() + "uploadMedia?name=" + pickedPhoto.name, params).then((response) => response.json())
                    .then((responseData) => {
                        if (responseData.response > 0 && responseData.url){
                            sendMessage(responseData.url, to_user)
                        }
                        console.log("uploadMedia responseData = ", responseData)
                    })
                    .catch((error) => {
                        console.error(error);
                });
    }
  
    if (isImage(file.name)){
        pickedPhoto = file 
        img.onload = uploadPhoto
        img.src = result
    } else {
        pickedFile = file
        uploadPickedFile()
    }


}
 export const createNewMessage = (to_user_id, message) => {
        var listMsg = {}
        listMsg.text = message
        listMsg.type = 0
        listMsg.from_id = getCookie("id")
        listMsg.to_id = to_user_id
        listMsg.dialog_id = getCookie("id") + "_" + to_user_id
        listMsg.unixtime = Math.floor(Date.now())
        listMsg.is_read = false
        return listMsg
}
