import ReactCrop, {
    centerCrop,
    makeAspectCrop
  } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useState, useEffect,useRef } from 'react';
import { Buffer } from 'buffer'
import Loader from '../../../custom_views/Loader';
import { getIsDesktop } from '../../../utils/Utils';
import '../../../styles/profile_filling.css'
function ImageCropper(props) {
    const [crop, setCrop] = useState()
    const [aspect, setAspect] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [image, setImage] = useState(undefined)
    const [completedCrop, setCompletedCrop] = useState()
    const imgRef = useRef(null)
    const imgEl = useRef(null)
    useEffect(() => {
        
        // setTimeout(function() {
        //     const squares = window.document.getElementsByClassName("ReactCrop__drag-handle::after")
        //     squares.forEach(element => {
        //         element.width = "32px"
        //         element.height = "32px"
        //     });
        //   }, 1000)
        
    }, [])
    const onImgLoad = () => {
        const resizedDataUri = resizeImage(imgEl.current, 1080);
        setImage(resizedDataUri)
    }
    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
      ) {
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
    }
    function onImageLoad(e) {
        if (aspect) {
          const { width, height } = e.currentTarget
          setCrop(centerAspectCrop(width, height, aspect))
        }
    }
    
    function resizeImage (imgEl, wantedWidth) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
      
        const aspect = imgEl.width / imgEl.height;
      
        canvas.width = wantedWidth;
        canvas.height = wantedWidth / aspect;
      
        ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();
    }

    
    function onNext() {
        if (completedCrop) {
            setLoading(true)
          // create a canvas element to draw the cropped image
          const canvas = document.createElement("canvas");
    
          // get the image element
          const image = imgRef.current;
    
          // draw the image on the canvas
          if (image) {
            const crop = completedCrop;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext("2d");
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio * scaleX;
            canvas.height = crop.height * pixelRatio * scaleY;
    
            if (ctx) {
              ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
              ctx.imageSmoothingQuality = "high";
    
              ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
              );
            }
    
            const base64Image = canvas.toDataURL("image/jpeg"); // can be changed to jpeg/jpg etc
    
            if (base64Image) {
              // @ts-ignore
              const fileType = base64Image.split(";")[0].split(":")[1];
    
              const buffer = Buffer.from(
                base64Image.replace(/^data:image\/\w+;base64,/, ""),
                "base64"
              );
              const file = new File([buffer], "avatar", { type: fileType });
              setLoading(false)
              props.onCropped(file); // function passed as a prop
              
            }
          }
        }
    }
    const onCancel = () => {
        props.onCancel()
    }
    const aspectRatio = props.img.width / props.img.height
    const width = aspectRatio > 1 ? 'calc(100vw - 500px)' : 'auto'
    const height = aspectRatio <= 1 ? 'calc(100vh - 52px - 112px)' : 'auto'
    const isDesktop = getIsDesktop()
    if (isLoading){
        return(<Loader />)
    }
    return (
        <div style={props.style ? props.style : {}} className="image_cropper_bg">
            <div className="image_cropper_cropper"></div>
            {image != undefined ? <ReactCrop className="imp_crop_cont" keepSelection={true} onComplete={(c) => setCompletedCrop(c)} minHeight={150} minWidth={150} aspect={aspect} crop={crop} onChange={c => setCrop(c)}>
                <img style={isDesktop ? {width : width, height : height, maxHeight : 'calc(100vh - 52px - 112px)'} : {}} ref={imgRef} onLoad={onImageLoad} className="image_cropper_img" src={image}/>
            </ReactCrop> : <Loader/>}
            <img style={{display : 'none'}} src={props.img.src} onLoad={onImgLoad} ref={imgEl}/>
            <div className="image_cropper_controls">
                <div onClick={onCancel} className="image_cropper_cancel hover">Отмена</div>
                <div onClick={onNext} className="image_cropper_next hover">Далее</div>
            </div>
            
        </div>
    );
}

export default ImageCropper;
