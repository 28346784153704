
import { useState, useEffect, useCallback } from 'react';
import check from '../../../../img/create_ads/check.svg'

function CreateAdsDaysPicker(props) {
    const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
    const [selectedMornings, setSelectedMornings] = useState([])
    const [selectedDays, setSelectedDays] = useState([])
    const [selectedEvenings, setSelectedEvenings] = useState([]) 
    useEffect(() => {
        const s1 = []
        const s2 = []
        const s3 = []
        props.time.forEach(element => {
            let items = element.split(":")
            if (items[1] == "0"){
                console.log("props.time 1 = ", element)
                s1.push(element)
            } else if (items[1] == "1"){
                s2.push(element)
            } else if (items[1] == "2"){
                s3.push(element)
            }
        })
        setSelectedMornings(s1)
        setSelectedDays(s2)
        setSelectedEvenings(s3)
        console.log("props.time = ", props.time)
        console.log("props.time s1 = ", s1)
        console.log("props.time s2 = ", s2)
        console.log("props.time s3 = ", s3)
    }, [props.time])
    const getRowHTML = (title, array, dayTime) => {
        return(
            <div className="week_picker_times">
                <div className="create_ads_day_time">{title}</div>
                {weekDays.map((item, index) => (
                    <ItemRow array={array} index={index} toggleItem={props.toggleItem} dayTime={dayTime} key={index}/>
                ))}
            </div>
        )
    }
    return (
        <div style={props.toggleItem == undefined ? {marginTop : '19px'} : {}} className="week_picker_container">
            <div className="week_picker_days">
                {weekDays.map((day, index) => (
                    <div className="week_picker_day" key={index}>
                        {day}
                    </div>
                ))}
            </div>
            {getRowHTML("Утро", selectedMornings, "0")}
            {getRowHTML("День", selectedDays, "1")}
            {getRowHTML("Вечер", selectedEvenings, "2")}
        </div>
    )
}
const ItemRow = ({ array, index, toggleItem, dayTime }) => {
    const getIsSelected = (array, index) => {
        for (let i = 0; i < array.length; i++) {
           if (array[i].split(":")[0] == index){
               return true
           } 
        }
        return false
    }
    const isSelected = getIsSelected(array, index)
    return(
        <div onClick={() => toggleItem != undefined ? toggleItem(index + ":" + dayTime) : {}} style={isSelected ? {background : 'var(--dark_purple)'} : {}} className={toggleItem != undefined ? "week_day_picker_item hover" : "week_day_picker_item"} key={index}>
            <img style={isSelected ? {opacity : 1} : {opacity : 0}} className="day_picker_check" src={check}/>
        </div>
    )
}
export default CreateAdsDaysPicker;
