import { useState} from 'react';
import bookmark_selected from '../../../img/experts/bookmark_selected.svg'
import bookmark from '../../../img/blogs/bookmark.svg'
import { getIsDesktop } from '../../../utils/Utils';

function BlogsBookmarks(props) {
    const [isShowTooltip, setShowTooltip] = useState(false)
    const isShowTooltips = props.isShowTooltips
    const isDesktop = getIsDesktop()
    const showTooltip = () => {
        if (isShowTooltips && isDesktop){
            setShowTooltip(true)
        }
    }
    return (
        <div className="blog_item_tooltip_cont">
            <img onClick={props.toggleBookmarks} onMouseEnter={showTooltip} onMouseLeave={() => setShowTooltip(false)} className="blog_item_bookmark hover" src={props.isInBookmarks ? bookmark_selected : bookmark}/>
            <div style={isShowTooltip ? {opacity : 1, pointerEvents : 'all'} : {}} onMouseLeave={() => setShowTooltip(false)} className="blog_tooltip_bookmark">
                <p className="views_tooltip_text">{props.isInBookmarks ? "Убрать эксперта из избранного" : "Добавить эксперта в избранное"}</p>
            </div>
        </div>)
}

export default BlogsBookmarks;