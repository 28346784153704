import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
function PaidChatDialog(props) {
    const onDecline = () => {
        props.setShow(false)
    }
    const isDesktop = getIsDesktop()
    const isFromCallScreen = props.isShowCallChat != undefined && props.isShowCallChat
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '400px'} : {}} className="rules_container">
                <p className="rules_title">Платный чат</p>
                <p className="rules_text">Эта настройка работает для того чата, в котором вы находитесь.<br/><br/>По умолчанию чат с любым пользователем является бесплатным, чтобы потенциальный клиент мог задать вам уточняющие вопросы до платной консультации.<br/><br/>Когда переписка перерастёт в консультацию, вы можете включить платный чат. Но сервис вас к этому не обязывает.</p>
                <div className="rules_btns">
                    <div onClick={onDecline} className="rules_left_btn rules_right_btn hover">Понятно</div>
                </div> 
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isFromCallScreen={isFromCallScreen} isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default PaidChatDialog;
