import CreateAdsHeader from "./CreateAdsHeader";
import CreateAdsRadioGroup from "./CreateAdsRadioGroup";
import CreateAdsButtons from "./CreateAdsButtons";
import { useEffect } from 'react';

function CreateAdsRadioStep(props) {
    const info = props.info
    // useEffect(() => {
    //     props.onNext()
    // }, [props.setValue])
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={info.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_light_title">{info.pickedSpecs}</div>
                    <div className="create_ads_radio_header" dangerouslySetInnerHTML={{__html: info.header}}/>
                    <CreateAdsRadioGroup onNext={props.onNext} selectedItem={info.value} setSelectedItem={props.setValue} items={info.radioItems}/>
                </div>
                <CreateAdsButtons isEdit={props.isEdit} onNext={props.onNext}/>
            </div>
        </div>
    );
}

export default CreateAdsRadioStep;
