/* eslint-disable eqeqeq */
import moment from 'moment'
import { getCookie } from '../../../utils/CookieHelper';
import { useLayoutEffect, useState } from 'react';
import { Pages } from '../../../utils/Utils';

export const setAllReadedMsgs = (data) => {
    var array = data
    for (var i = array.length - 1; i >= 0; i--) {
        var element = array[i]
        if (!element.is_read){
            element.is_read = true
        }
    }
    return array
}
export const changeMsgText = (data, socketMessage) => {
    var array = [...data]
    for (var i = array.length - 1; i >= 0; i--) {
        var element = array[i]
        if (element._id == socketMessage._id){
          
            array[i].text = socketMessage.text
            array[i].unixtimeEdit = socketMessage.unixtimeEdit
            array[i].is_edited = socketMessage.is_edited
            if (socketMessage.edit_date){
                array[i].edit_date = socketMessage.edit_date
            }
            break
        }
    }
    return array
}
export const hasIncomingMessages = (responseMessages) => {
    var hasMassages = false
    for (let index = 0; index < responseMessages.length; index++) {
        const element = responseMessages[index];
        if (element.to_id == getCookie("id")){
            hasMassages = true
            break
        }
    }
    return hasMassages
}
export const getSavedMsgByDialogId = (dialog_id) => {
    const svdMsgsKey = "saved_msgs"
    var saved_msgs = localStorage.getItem(svdMsgsKey)
  
    if (saved_msgs != null && saved_msgs != "null"){
        var svdJson = JSON.parse(saved_msgs)
        if (svdJson[dialog_id] != undefined){
            return svdJson[dialog_id]
        }
    }
    return ""
}
export const saveMsgByDialogId = (dialog_id, text) => {
  // localStorage.setItem("saved_msgs", null)
    const svdMsgsKey = "saved_msgs"
    var saved_msgs = localStorage.getItem(svdMsgsKey)
    if (saved_msgs != null && saved_msgs != "null"){
        saved_msgs = JSON.parse(saved_msgs)
        saved_msgs[dialog_id] = text
    } else {
        saved_msgs = {}
        saved_msgs[dialog_id] = text
    }
    localStorage.setItem(svdMsgsKey, JSON.stringify(saved_msgs))    
}
export const clearSavedMsgByDialogId = (dialog_id ) => {
    const svdMsgsKey = "saved_msgs"
    var saved_msgs = JSON.parse(localStorage.getItem(svdMsgsKey))
    if (saved_msgs != null && saved_msgs[dialog_id] != undefined){
        saved_msgs[dialog_id] = undefined
    }
    localStorage.setItem(svdMsgsKey, JSON.stringify(saved_msgs))
}
export const updateMsg_Id = (data, socketMessage) => {
    var array = data
    for (var i = array.length - 1; i >= 0; i--) {
        var element = array[i]
        if (element.text == socketMessage.text){
            element._id = socketMessage._id
            element.reply_message = socketMessage.reply_message
            break
        }
    }
    return array
}
export const getReplyMsgIndex = (data, id) => {
    var foundIndex = -1
    for (let index = 0; index < data.length; index++) {
        if (data[index]._id == id){
            foundIndex = index
            break
        }
    }
    return foundIndex
}
export const get_url_extension = ( url ) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}
export const is_web_view = ( ) => {
    const web_view = getCookie("web_view")
    
    return (web_view != undefined && web_view != null && web_view != "");
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
export const getChatDateTitle = ( date_text ) => {
        const parsedDate =  moment.parseZone(date_text).local()
        var date = parsedDate.format("DD MMM в HH:mm")
        var dateTime = parsedDate.format("в HH:mm")
       
        if (parsedDate.dayOfYear() ===  moment().dayOfYear() && parsedDate.year() == moment().year()){
            date = "сегодня " + dateTime
        }
        const tomorrow = moment().add(1,'days')
        if (parsedDate.dayOfYear() ===  tomorrow.dayOfYear() && parsedDate.year() == tomorrow.year()){
            date = "завтра " + dateTime
        }
        const yesterday = moment().add(-1,'days')
        if (parsedDate.dayOfYear() ===  yesterday.dayOfYear() && parsedDate.year() == yesterday.year()){
            date = "вчера " + dateTime
        }
    return date;
}
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0B'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['b', 'Kb', 'Mb', 'Gb', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}
export const downloadFileFromUrl = (url, filename) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
}
export const getGroupCallText = (planner) => {
    var date = ""
    var datePlusHour = ""
    var time = ""
    if (planner.sozvon_date != undefined){
        date = moment(planner.sozvon_date).utc().local()
        datePlusHour = moment(planner.sozvon_date_end).utc().local()
        time = date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM, dd")
    }
    const text = "Ссылка для подключения к видеовстрече: " + window.location.origin + Pages.group_call_info + "/" + planner._id + 
      "<br/><br/>🤓 Тема: " + planner.sozvon_name + 
      "<br/>⏰ Когда: " + time + 
      "<br/>👉 Стоимость: " + (planner.price > 0 ? (planner.price + " ₽ с каждого участника") : "бесплатно")
       // + planner.price > 0 ? "/nПредоплата: полная стоимость, взимается с" : ""

    return text
}
export const getGroupCallChatStyle = () => {
    return {
      width: '360px',
      position: 'fixed',
      right: 0,
      left: 'calc(100vw - 360px)',
      zIndex: '9999',
      background: 'white',
      bottom: 0,
      top: 0,
  }
}
export const getFilenameFromUrl = (url) => {
    // console.log("getFilenameFromUrl url = ", url)
    try {
        const pathname = new URL(url).pathname;
        const index = pathname.lastIndexOf('/');
        return pathname.substring(index + 1)
    } catch (error) {
        return ""
    }
    // if index === -1 then index+1 will be 0
  }
export const getAllUrlParams = (url) => {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
  
    // we'll store the parameters here
    var obj = {};
  
    // if query string exists
    if (queryString) {
  
      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];
  
      // split our query string into its component parts
      var arr = queryString.split('&');
  
      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');
  
        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
  
        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
  
        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {
  
          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];
  
          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }
  
    return obj;
  }