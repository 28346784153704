/* eslint-disable eqeqeq */
import { useState } from 'react';
import avatar from '../../../img/avatar.svg'
import { CSSTransition } from 'react-transition-group';
import { Pages, getRating, getReviewsCount } from '../../../utils/Utils';
import { useHistory } from "react-router-dom";
import ProfileTooltip from '../tooltips/ProfileTooltip';
import { UsersApi } from '../../../api/UsersApi';
import badge from '../../../img/profile/badge.svg'
import badge_seleb from '../../../img/profile/badge_seleb.svg'
import profile_more from '../../../img/profile/profile_more.svg'
import profile_bookmark from '../../../img/profile/profile_bookmark.svg'
import bookmark_selected from '../../../img/profile/bookmark_selected.svg'
import AvatarTooltip from '../tooltips/AvatarTooltip';
import { getCookie, isUserAuthed } from '../../../utils/CookieHelper';
import NotAuthedDialog from '../dialogs/NotAuthedDialog';
import OnlineInfo from '../../profile_filling/custom_views/OnlineInfo';
import CallWriteButtons from '../custom_views/CallWriteButtons';
import ProIcon from '../../ads_desk/AdsBoard/custom_views/ProIcon';
import message from '../../../img/experts/message.svg'
import star from '../../../img/experts/star.svg'

function DesktopNavbar(props) {
    const user = props.user
    const [isShowMoreTooltip, setShowMoreTooltip] = useState(false)
    const [isShowAvatarTooltip, setShowAvatarTooltip] = useState(false)
    const [isShowNotAuthedDialog, setShowNotAuthedDialog] = useState(false)
    const [tooltipText, setTooltipText] = useState({isShow : false, text : ""})

    const history = useHistory()
    const isRepetitor = user.is_repetitor
    const isAuthed = isUserAuthed()
    const toggleBookmarks = () => {
        if (isAuthed){
            UsersApi.toggleBookmarks(user.vk_caller_id, props.isInBookmarks, (responseData) => {
                props.getMyInfo()
            })
            props.setInBookmarks(!props.isInBookmarks)
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const toggleBlocked = () => {
        UsersApi.toggleBlock(user.vk_caller_id, props.isBlocked, (responseData) => {
            props.getMyInfo()
        })
        props.setBlocked(!props.isBlocked)
    }
    // const back = () => {
    //     if (history.action == "POP"){
    //         window.location.href = Pages.experts
    //     } else {
    //         props.setSelectedProfile(undefined)
    //         history.goBack()
    //     }
    // }
    const openUserAvatar = () => {
        const avatar = props.user.avatar
        if (avatar && avatar != ""){
            window.open(props.user.avatar, '_blank')
        }
    }
    const onWrite = () => {
        if (isAuthed){
            props.write()
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const onCall = () => {
        if (isAuthed){
            props.setShowCallBottomSheet(true)
        } else {
            setShowNotAuthedDialog(true)
        }
    }
    const isShowBack = props.isProfileFromID || props.isSelectedProfile
    const disableTooltip = () => {
        const tooltip = {...tooltipText}
        tooltip.isShow = false
        setTooltipText(tooltip)
    }
    const isScrolledToTop = props.isScrolledToTop
    return (
        <div >
            <div className="flex">
                {/* <img onClick={back} className="round_back_desktop hover" src={round_back}/> */}
                <div className={isScrolledToTop ? "profile_desktop_fixed profile_desktop_header_container " : "profile_desktop_header_container"}>
                   
                    <div className="desktop_profile_info">
                        <div className="flex">
                            {/* <img onClick={back} className="profile_desktop_back_48 hover" src={back_48}/> */}
                            <div className="profile_mobile_pro_cont">
                                <img alt="" style={isScrolledToTop ? {cursor : 'pointer', height : '56px', width : '56px'} : {cursor : 'pointer'}} onClick={props.isMyProfile ? () => setShowAvatarTooltip(true) : openUserAvatar} className="desktop_profile_avatar transition_header" src={props.user.avatar ? props.user.avatar : avatar}/>
                                <div style={isScrolledToTop ? {left : '10px'} : {}} className="profile_mobile_pro transition_header"><ProIcon isShow={user.is_pro}/> </div>
                            </div>

                            <div className="relative">
                                {(user.is_repetitor && user.specializations && user.specializations.length > 0) && <h1 className="profile_expert_by h1_title">Эксперт - {user.specializations[0].toLowerCase()}</h1>}

                                <div className="flex">
                                    <p style={isScrolledToTop ? {marginBottom : '4px', marginTop : '-4px', fontSize : '16px', lineHeight : '18px'} : {}}  className="desktop_profile_name">{user.name} {user.surname}</p>
                                    {(isRepetitor || user.verified) && <img style={isScrolledToTop ? {marginTop : '-4px'} : {}} alt="" onMouseEnter={() => setTooltipText({isShow : true, text : user.verified ? "Профиль верифицированной звезды" : "Профиль эксперта"})} onMouseLeave={disableTooltip} className="desktop_profile_badge" src={user.verified ? badge_seleb : badge}/>}
                                </div>
                                {isRepetitor && 
                                <div className="flex">
                                    <p className="desktop_profile_desk" style={{marginRight : '3px'}}>{user.vk_caller_id == getCookie("id") ?  user.rub_in_hour == undefined ? 0 : Math.round(user.rub_in_hour * (1 - props.config.comission)) : user.rub_in_hour} ₽/час ·</p>
                                    <OnlineInfo user={user}/>
                                    <div style={{margin: '-8px 0 0 0'}} className="flex">
                                        <img alt='' className="review_info_icon" src={star}/>
                                        <p className="review_info_text">{getRating(user)}</p>

                                        <img alt='' className="review_info_icon" src={message}/>
                                        <p className="review_info_text">{getReviewsCount(user)}</p>
                                    </div>
                                </div>}
                                <div style={tooltipText.isShow ? {opacity : 1, pointerEvents : 'all'} : {}} className="views_tooltip">
                                    <p className="views_tooltip_text badge_tooltip_text">{tooltipText.text}</p>
                                </div>
                            </div>
                        </div>
                        <div style={props.isMyProfile ? {margin : 0} : {margin : isScrolledToTop ? "auto 0" : "auto 0 0 0"}} className="desktop_profile_icons">
                            {!props.isMyProfile ?
                                <div className="flex">
                                    <div className="call_write_buttons_desktop">
                                        <CallWriteButtons onCall={onCall} onWrite={onWrite} isLoading={props.isLoading}/>
                                    </div>
                                    <img alt="" onClick={toggleBookmarks} style={{marginLeft : '16px'}} className="desktop_profile_icon hover" src={props.isInBookmarks ? bookmark_selected : profile_bookmark}/>
                                   
                                </div> : <div/>}
                            
                            <div className="relative">
                                <img alt="" onClick={() => setShowMoreTooltip(!isShowMoreTooltip)} className="desktop_profile_icon hover" src={profile_more}/>

                                <CSSTransition in={isShowMoreTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                                    <ProfileTooltip getMyInfo={props.getMyInfo} toggleBlocked={toggleBlocked} isBlocked={props.isBlocked} showToast={props.showToast} isMyProfile={props.isMyProfile} setUploadVideoChanged={props.setUploadVideoChanged} style={{top : isScrolledToTop ? '100px' : props.isMyProfile ? '100px' : '180px', right : window.innerWidth >= 1280 ? 'calc((100vw - 960px) / 2)' : '40px'}} user={user} setShowMoreTooltip={setShowMoreTooltip}/>
                                </CSSTransition>
                                <CSSTransition in={isShowAvatarTooltip} timeout={300} classNames="animation_in" unmountOnExit>
                                    <AvatarTooltip style={ window.innerWidth >= 1280 ? {left : 'calc((100vw - 960px) / 2)'}: {}} updateUserInfo={props.updateUserInfo} user={user} setShow={setShowAvatarTooltip}/>
                                </CSSTransition>
                            </div>
                        </div>
                        
                    </div>
                    {/* {!props.isMyProfile && <div className="call_write_buttons_less_1160">
                        <CallWriteButtons onCall={onCall} onWrite={onWrite} isLoading={props.isLoading}/>
                    </div>} */}
                </div>
            </div>
            <NotAuthedDialog isShow={isShowNotAuthedDialog} setShow={setShowNotAuthedDialog}/>
        </div>
    );
}

export default DesktopNavbar;
