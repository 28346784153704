/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { Pages, getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import BoardsSearch from '../../work_boards/custom_views/BoardsSearch';
import { BoardsApi } from '../../../api/BoardsApi';
import empty from '../../../img/blogs/empty.svg'
import Loader from '../../../custom_views/Loader';
import BoardsItem from '../../work_boards/custom_views/BoardsItem';
import globe from '../../../img/work_boards/globe.svg'
import x from '../../../img/x.svg'

function AttachDeskDialog(props) {
    const [searchText, setSearchText] = useState("")
    const [boards, setBoards] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isPublic, setPublic] = useState(false)
    
    useEffect(() => {
        if (props.isShow){
            setPublic(false)
            getMyBoards()
        }
    }, [props.isShow])
    useEffect(() => {
        if (isPublic){
            BoardsApi.searchPublicBoards(searchText, (responseData) => {
                setLoading(false)
                setBoards(responseData.items)
            }, () => {
                setLoading(false)
            })
        } else {
            getMyBoards()
        }
    }, [searchText, isPublic])
    const getMyBoards = () => {
        BoardsApi.getMyBoards((responseData) => {
            setLoading(false)
            setBoards(responseData.items)
        }, () => {
            setLoading(false)
        })
    }
    const getEmptyBoards = () => {
        return(
            <div className="boards_empty">
                <img alt='' className="blogs_empty_img" src={empty}/>
                Ничего не найдено
            </div>
        )
    }
    const onBoard = (board) => {
        props.onBoardPicked(board.name + '\n' + Pages.call_board + board._id)
        props.setShow(false)
    }
    const isDesktop = getIsDesktop()
    const filteredBoards = isPublic ? boards : boards.filter((board) => searchText == "" ? true : (board.name.toLowerCase().includes(searchText.toLowerCase()) || board.theme.toLowerCase().includes(searchText.toLowerCase())))

    const getInnerHtml = () => {
        return(
            <div className="boards_container boards_container_messages">
                <div className='boards_container_messages_flex'>
                    <div>Отправить доску</div>
                    <img onClick={() => props.setShow(false)} alt='' className='hover' src={x}/>
                </div>
                <BoardsSearch text={searchText} setSearchText={setSearchText}/>
                {isLoading ? 
                    <Loader style={{marginTop : '56px'}}/>
                :
                    filteredBoards.length > 0 ? <div style={{maxHeight : 'calc(100vh - 420px)'}} className="boards_list">
                        {filteredBoards.length > 0 && filteredBoards.map((board, index) => (
                            <BoardsItem onBoard={onBoard} isFromChat isPublic={isPublic} getMyBoards={getMyBoards} board={board} index={index} key={index} />
                        ))}
                    </div> 
                :
                    getEmptyBoards()
                } 
                <div onClick={() => setPublic(!isPublic)} className="boards_open_all">
                    <img alt='' src={globe}/>
                    <div>Перейти в {isPublic ? "мои доски" : "публичные доски"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} open={props.isShow}>
               {getInnerHtml()}
            </BottomSheet>
        )
    }
}

export default AttachDeskDialog;
