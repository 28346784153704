import { getRequestParams } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse, setApiMetadata } from "./ApiUtils";
export class BlogsApi {
    
    static createPost(params, onSuccess, onError) {
        fetch(getRequestUrl() + "createPost", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("createPost response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
   
    static getLastPosts(limit, skip, category, onSuccess, onError) {
        fetch(getRequestUrl() + "getLastPosts", getHttpParams({limit : limit, skip : skip, category : category})).then((response) => response.json())
        .then((responseData) => {
            log("getLastPosts response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addViewToPost(post_id, onSuccess, onError) {
        fetch(getRequestUrl() + "addViewToPost", getHttpParams({post_id : post_id})).then((response) => response.json())
        .then((responseData) => {
            log("addViewToPost response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static removePostByID(post_id, onSuccess, onError) {
        fetch(getRequestUrl() + "removePostByID", getHttpParams({post_id : post_id})).then((response) => response.json())
        .then((responseData) => {
            log("removePostByID response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getPostByID(post_id, onSuccess, onError) {
        fetch(getRequestUrl() + "getPostByID", getHttpParams({post_id : post_id})).then((response) => response.json())
        .then((responseData) => {
            log("getPostByID response = ", responseData)
            onResponse(responseData, onSuccess, onError)
            setApiMetadata(responseData.meta_data)
        })
    }
    static editPost(post_id, text, onSuccess, onError) {
        fetch(getRequestUrl() + "editPost", getHttpParams({post_id : post_id, text : text})).then((response) => response.json())
        .then((responseData) => {
            log("editPost response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getPostsByUserID(limit, skip, _id, onSuccess, onError) {
        fetch(getRequestUrl() + "getPostsByUserID", getHttpParams({limit : limit, skip : skip, _id : _id})).then((response) => response.json())
        .then((responseData) => {
            log("getPostsByUserID    response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addLikeToPost(post_id, onSuccess, onError) {
        fetch(getRequestUrl() + "addLikeToPost", getHttpParams({post_id : post_id})).then((response) => response.json())
        .then((responseData) => {
            log("addLikeToPost response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static addViewToPost(post_id, onSuccess, onError) {
        fetch(getRequestUrl() + "addViewToPost", getHttpParams({post_id : post_id})).then((response) => response.json())
        .then((responseData) => {
            log("addViewToPost response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getGigaChatAnswer(params, onSuccess, onError) {
        fetch(getRequestUrl() + "getAnswer", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getAnswer response = ", responseData)
            onSuccess(responseData)
        })
    }
    static genGigaImage(params, onSuccess, onError) {
        fetch(getRequestUrl() + "genImage", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("genImage response = ", responseData)
            onSuccess(responseData)
        })
    }
    // static getGigaChatAnswer(params, onSuccess, onError) {
    //     fetch("http://77.244.221.121:8080/api/getAnswer", {
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/json'},
    //         body: JSON.stringify(params)
    //     }).then((response) => response.json())
    //     .then((responseData) => {
    //         log("getGigaChatAnswer response = ", responseData)
    //         onSuccess(responseData)
    //     })
    // }
   
}