/* eslint-disable eqeqeq */
function PriceItem(props) {
    const isIRepetitor = props.isIRepetitor 
    const config = props.config
    const user = props.user
    const countRub = Number.isInteger(user.count_rub) ? user.count_rub : parseFloat(user.count_rub).toFixed(1)
    const onPriceClick = () => {
        if (props.setOpenRules){
            props.setOpenRules(true)
        }
    }
    const isZero = !props.isPaidChat || props.repetitor.rub_per_message == undefined || props.repetitor.rub_per_message == 0
    return (
        <div onClick={onPriceClick} className="chat_message_price_cont hover">
            {!isZero ? <p className="chat_message_price_msg">{isIRepetitor ? "+" : "-"}{props.repetitor.rub_per_message ? isIRepetitor ? parseFloat(props.repetitor.rub_per_message*(1-config.comission)).toFixed(1) : props.repetitor.rub_per_message : 0}₽ сообщение</p> :
            <p className="chat_message_price_msg">0₽ сообщение</p>}
            {!props.isDesktop && <p className="chat_message_price_balance">Баланс {countRub}₽</p>}
        </div>)
}
export default PriceItem;