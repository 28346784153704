import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { useHistory } from "react-router-dom";
import { getCookie } from '../../../utils/CookieHelper';


function ProfileInfoMsgPriceDialog(props) {
    const isDesktop = getIsDesktop()
    const history = useHistory()
    const user = props.user
    const isMyProfile = user.vk_caller_id == getCookie("id")
    const cancel = () => {
        props.setShow(false)
    }
    const openChat = () => {
        if (!isMyProfile){
            history.push("/chats/chat?id=" + user.vk_caller_id)
        } else {
            props.setShow(false)
        }
    }
    const price = user.rub_per_message ? user.rub_per_message : 0

    const getInnerHtml = () => {
        return(
            <div style={ isDesktop ? {maxWidth : '460px'} : {}} className="call_info_container">
                <p style={{color : 'black'}}  className="rules_title">Условия переписки</p>
                <p className="rules_text">Переписка с данным экспертом стоит <b>{price ? price : 0}₽ за каждое ответное сообщение.</b><br/><br/>Деньги списываются только за парные сообщения.<br/><br/>Один ответ эксперта подразумевает ответ на один ваш вопрос. Если у вас в одном сообщении несколько вопросов, то эксперт может их проигнорировать.</p> 
                <div style={{marginTop : '36px'}} className="flex">
                    {!isMyProfile && <div onClick={cancel} className="call_prices_edit hover">Отмена</div>}
                    <div style={isMyProfile ? {} : {marginLeft : 0}} onClick={openChat} className="call_price_continue hover">{isMyProfile ? "Понятно" : "Написать"}</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default ProfileInfoMsgPriceDialog;
