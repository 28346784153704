/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { linkify } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';

function GroupCallDescriptionDialog(props) {
    const isDesktop = getIsDesktop()
    const name = props.name ? props.name : ""
    const text = props.text ? linkify(props.text) : ""
    const formattedText = text == undefined ? "" : text.includes("\n") ? ("<div>" +  text.replaceAll("\n", "<br/>") + "</div>") : text

    const close = () => {
        props.setShow(false)
    }
   
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '400px'} : {}} className="call_info_container relative">
                <p className="call_edit_title">{name}</p>
                <div className="planner_share_text" dangerouslySetInnerHTML={{__html: formattedText}}></div>
                
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0'}} onClick={close} className="call_price_continue hover">Закрыть</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default GroupCallDescriptionDialog;
