/* eslint-disable eqeqeq */
import '../../styles/planner.css'
import { useState } from 'react';
import { Pages, getIsDesktop, getUrlParameter } from '../../utils/Utils';
import { getDesktopStyle } from '../../utils/Styles';
import close_chat from '../../img/chat/close_chat.svg'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import PlannerSpecsPicked from './custom_views/PlannerSpecsPicker';
import PlannerNotifications from './custom_views/PlannerNotifications';
import { PlannerApi } from '../../api/PlannerApi';
import Loader from '../../custom_views/Loader';
import ErrorCreationDialog from './dialogs/ErrorCreationDialog';
import {DatePicker, CustomProvider} from 'rsuite'
import "../../styles/App.css"
import ruRu from 'rsuite/locales/ru_RU'
import isBefore from 'date-fns/isBefore';
import plus from '../../img/planner/plus.svg'
import planner_close from '../../img/planner/planner_close.svg'
import remove_icon from '../../img/x.svg'

function CreatePlanner(props) {
    const [pickedSpec, setPickedSpec] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [isSuccessCreated, setSuccessCreated] = useState(false)
    const [error, setError] = useState("")
    const user = props.user
    const [pickedNotifications, setPickedNotifications] = useState([86400000, 1800000])
    const [pickedDates, setPickedDates] = useState([{date : "", time : ""}])
    const history = useHistory()

    const isDesktop = getIsDesktop()
    const checkFilterDate = (d) => {
        const times =  moment(d.time).format("HH:mm").split(":")
        const pickedDateMoment = moment(d.date, "YYYY-MM-DD").set("hour", times[0]).set("minute", times[1])
        return d.date == "" || d.time == "" || d.date == undefined || d.time == undefined || pickedDateMoment < moment()
    }
    const isEnableButton = pickedDates.filter((d    ) => checkFilterDate(d)).length == 0 && pickedSpec != ""
    
    const createPlanner = () => {
        // console.log("pickedTime = ", pickedTime)
        var timesParams = ""
        pickedDates.forEach(element => {
            const times =  moment(element.time).format("HH:mm").split(":")
            const unixtime = moment(element.date, "YYYY-MM-DD").set("hour", times[0]).set("minute", times[1]).unix() * 1000
            if (timesParams == ""){
                timesParams = unixtime
            } else {
                timesParams = timesParams + "," + unixtime
            }
        });
        
        
        const params = {
            "other_vk_caller_id": getUrlParameter("id"),
            "sozvon_date": timesParams,
            "sozvon_name": pickedSpec,
            "reminder": pickedNotifications.join(",")
        }
        console.log("createPlanner params = ", params)
        setLoading(true)
        setError("")
        PlannerApi.createPlanner(params, (responseData) => {
            setLoading(false)
            setSuccessCreated(true)
         
        }, (responseData) => {
            const findMatch = (t) => {
                var match = t.match("\\d{13}");
                if (match != null && match.length > 0){
                    // console.log("match element = ", match)
                    const unixtime = match[0]
                    const date = moment.unix(unixtime/1000).local()
                    const datePlusHour = moment.unix(unixtime/1000).local().add(1, 'hours')
                    text = t.replaceAll(unixtime, date.format("HH:mm") + datePlusHour.format(" - HH:mm") + date.format(" · DD MMMM"))
                    findMatch(text)
                } else {
                    return text
                }        
            }
            setLoading(false)
            var text = responseData.message
            findMatch(text)
            setError(text)

            
        })
    }
    
    const addDate = () => {
        var dates = [...pickedDates]
        dates.push({date : "", time : ""})
        setPickedDates(dates)
    }
    const removeDate = (index) => {
        var dates = [...pickedDates]
        dates.splice(index, 1)
        setPickedDates(dates)
    }
    const changeDate = (index, newDate) => {
        var dates = [...pickedDates]
        dates[index] = newDate
        setPickedDates(dates)
    }
    
    const getClientHtml = () => {
        return(
            <div className="create_planner">
                <div className="create_planner_client_title">Создать запланированный созвон может только эксперт</div>
                <div className="create_planner_client_desc">Обсудите удобное для всех время. После этого эксперт вышлет вам приглашение на запланированный созвон, а вам останется лишь подтвердить участие.</div>
                <button onClick={() => history.goBack()} className="fill_button hover profile_bottom_button">ПОНЯТНО</button>

            </div>
        )
    }
    const successCreated = () => {
        return(
            <div className="create_planner">
                <div className="create_planner_client_title">🥳<br/>Приглашение успешно создано</div>
                <div className="create_planner_client_desc">Ждём подтверждения клиента</div>
                <button onClick={() => history.goBack()} className="fill_button hover profile_bottom_button">ПОНЯТНО</button>

            </div>
        )
    }
    return (
        <div style={isDesktop ? getDesktopStyle() : {position : 'block', zIndex : '2'}} className="selected_settings create_planner_bg">
            
            <div className="create_ads_step">
                <div className="create_planner_toolbar">
                        <p className="theme_title planner_title">Запланировать созвон</p>
                        {!isDesktop ? 
                            <img alt='' onClick={() => history.goBack()}  className="planner_close hover" src={close_chat}/>
                            : 
                            <img alt='' onClick={() => history.goBack()}  className="planner_close_desktop hover" src={planner_close}/>
                        }

                </div>
                {/* {!user.is_repetitor ? getClientHtml()
                : */}
                {isSuccessCreated ? successCreated() :
                <div className="create_planner">
                    <PlannerSpecsPicked pickedSpec={pickedSpec} setPickedSpec={setPickedSpec}/>
                    <div className="create_planner_separator"/>
                    {pickedDates.length > 0 && pickedDates.map((date, index) => (
                        <PickedDate date={date} index={index} addDate={addDate} removeDate={removeDate} changeDate={changeDate} count={pickedDates.length} key={index}/>
                    ))}
                    
                    <div className="create_planner_desc">По умолчанию предполагается длительность созвона — 1 час.</div>
                    <div className="create_planner_separator"/>
                    <PlannerNotifications pickedNotifications={pickedNotifications} setPickedNotifications={setPickedNotifications}/>
                    <div onClick={() => window.open(Pages.planner_rules, '_blank')} style={{marginBottom : '66px'}} className="create_planner_how_it_works hover">Как это работает?</div>
                    <button style={isEnableButton ? {left : isDesktop ? 'calc(50vw - 166px)' : ''} : {pointerEvents : 'none', opacity : '0.2', left : isDesktop ? 'calc(50vw - 166px)' : ''}} onClick={createPlanner} className="fill_button hover profile_bottom_button">{isLoading ? <Loader isWhite/> : "Создать"}</button>

                </div>}
                
            </div>
            <ErrorCreationDialog isShow={error != ""} setShow={() => setError("")} error={error}/>
        </div>
        
    );
}

export default CreatePlanner;

const PickedDate = ({ date, index, addDate, removeDate, changeDate, count }) => {
    const isDesktop = getIsDesktop()
   
    return(
        <div style={index != 0 ? {marginTop : '8px'} : {}} className="create_planner_themes">
                <div className="create_planner_theme_item create_planner_theme_item_l">
                    {index == 0 && <div className="create_planner_theme">Дата созвона</div>}
                                
                    <CustomProvider locale={ruRu}>
                        <DatePicker isoWeek disabledDate={date => isBefore(date, new Date(moment().subtract(1, "days")))} style={{padding : '4px'}} appearance="subtle" oneTap placeholder="Дата" onChange={(e) => changeDate(index, {date : e, time : date.time})} className="create_planner_border hover" format="dd.MM.yyyy" />
                    </CustomProvider>
                {/* <input onChange={(e) => setPickedDate(e.target.value)} className="create_planner_border hover" type="date" id="start" name="trip-start" value={pickedDate} min={moment().format("YYYY-DD-MM")}/> */}
                </div>
                <div className="create_planner_theme_item create_planner_theme_item_r">
                    {index == 0 && <div className="create_planner_not_flex">
                        <div className="create_planner_theme">Время</div>
                        <img alt='' onClick={addDate} className="create_planner_not_icon hover" src={plus}/>
                    </div>}
                    <div className="flex">
                        <CustomProvider locale={ruRu}>
                            <DatePicker hideMinutes={minute => minute % 5 !== 0}  placement={isDesktop ? "auto" : "autoVerticalEnd"} style={{padding : '4px'}} appearance="subtle" placeholder="Время" onChange={(e) => changeDate(index, {date : date.date, time : e})} className="create_planner_border hover" format="HH:mm" />
                        </CustomProvider>
                        {index != 0 && <img alt='' onClick={() => removeDate(index)} className="planner_remove_date_icon hover" src={remove_icon}/>}
                    </div>
                </div>
        </div>
    )
}