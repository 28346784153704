import { useState, useEffect, useCallback, useRef } from 'react';
import arrow_right from '../../../img/create_ads/arrow_right.svg'
import { useHistory } from "react-router-dom";
import CreateExpressSearchHeader from './header/CreateExpressSearchHeader';
import { checkButtonDisabled } from '../../ads_desk/CreateAds/helpers/CreateAdsHelper';
import FilterDropdown from '../../../custom_views/FilterDropdown';
import search_icon from '../../../img/experts/search.svg'

function ExpressSearchStep1(props) {
    const history = useHistory()
    const inputRef = useRef(null)
    const specializations = props.specializations
    const [isShowSpecs, setShowSpecs] = useState(false)
    const text = props.specialization
    useEffect(() => {
        inputRef.current.focus();
    }, [])

    const onNext = () => {
        history.push("/express_search/step_2")
    }
    var filteredServerSpecs = specializations.filter( tag => text.length > 0 ? (tag.toLowerCase().includes(text.toLowerCase()) && tag.toLowerCase() != text.toLowerCase()) : isShowSpecs)

    const onFilterPicked = (tag) => {
        filteredServerSpecs = []
        setShowSpecs(false)
        props.setSpecialization(tag)
    }
    return (
        <div>
            <CreateExpressSearchHeader />
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_step_title">Найдём эксперта, готового провести консультацию в течение двух минут ⏰</div>
                    <div className="express_under_title">Эксперта какой области вы ищете?</div>
                    <div onClick={() => setShowSpecs(!isShowSpecs)} className="relative">
                        <input style={{paddingLeft : '36px'}} ref={inputRef} className="create_ads_input" placeholder="Поиск" type="text" value={text} onChange={(e) => props.setSpecialization(e.target.value)}/>
                        <img className="create_ads_search_icon" src={search_icon}/>
                    </div>
                    {(filteredServerSpecs.length > 0 || (isShowSpecs && filteredServerSpecs.length > 0)) && <FilterDropdown onCloseFilters={() => setShowSpecs(false)} style={{margin : '-16px 0 0 0', maxWidth : '536px'}} filters={filteredServerSpecs} onFilterPicked={onFilterPicked}/>}
                    {/* <input ref={inputRef} className="create_ads_input" placeholder="Поиск" type="text" value={props.specialization} onChange={(e) => props.setSpecialization(e.target.value)}/> */}
                    <p className="create_ads_step_desc">Укажите специальность эксперта. Выберите из выпадающей подсказки или введите иное значение.</p>
                </div>
                <div className="create_ads_buttons">
                    <div style={checkButtonDisabled(text.length == 0)} onClick={onNext} className="create_ads_btn hover">Далее <img className="create_ads_arrow_right" src={arrow_right}/></div>
                </div>
            </div>
        </div>
    );
}

export default ExpressSearchStep1;
