export const secondsToTime = (seconds) => {
    var time = "00:00"
    var s = seconds + 3600
    // if (s < 0){
    //     s = s * -1
    // }
    const sec = 120 - s
    if (s < 3600){
        time = new Date(sec * 1000).toISOString().substring(14, 19)
    } else {
        time = new Date(sec * 1000).toISOString().substring(11, 16)
    }
    // console.log("timerTime = " + sec)
    // console.log("timerTime s = " + s)
    return time
}
export const secondsToTimeGroupCall = (seconds) => {
    var time = "00:00"
    var sec = seconds > 0 ? seconds : seconds*-1
    // if (s < 0){
    //     s = s * -1
    // }
    
    if (sec < 3600){
        time = new Date(sec * 1000).toISOString().substring(14, 19)
    } else {
        time = new Date(sec * 1000).toISOString().substring(11, 19)
    }
    // console.log("secondsToTimeGroupCall = ", sec)
    // console.log("time = ", time)
    // console.log("time 2 = ", new Date(sec * 1000).toISOString())
    return time
}
export const getTestCourseJSON = () => {
    return {
        "response": 1,
        "status": "OK",
        "course": {
            "_id": "66d74173565079cbc167d227",
            "creator_vk_caller_id": "1667485202263",
            "client_vk_caller_ids_accepted": [],
            "creator": {
            "_id": "6363ce124ce56781dfcb6294",
            "vk_caller_id": 1667485202263,
            "specializations": [
                "Программирование",
                "Разработка ПО"
            ],
            "bdate": "1991-08-14T21:00:00.000Z",
            "name": "Владимир",
            "avatar": "https://728823.selcdn.ru/vlearn-container/6363ce124ce56781dfcb6294.jpg?fileSize=391239",
            "push_token_list": [
                "fgtNT1idCEiJvxTmuoZu-M:APA91bEm2rM4We7nByIXeESpEoO0vzxMr05zQewuHlNEJGO3rG2qKHjywuBIgJkSR4uvlaZXx7xWGp9R8NsAY60X6R1ClAp_jcmVUbQpGgWwPadycFxPeQljiqRRRzMc9By5rCPRRwMU",
                "dyjdAwtMQUKyG4Y5Dm3C57:APA91bFDbsPBnNsfIfl_qSRfmOSwGhOvNnnEhARlbcT_SUGP0SC2axiZeF1_gqhD4BFJMGVsQ_UBScuL0wpTg5wnGuqSx8wTNAy3Z56fVdIlkd3WFngsAhFN5TK8lXxXevED5FMb6Nr1"
            ],
            "telegram_chat_id": "32951559"
            },
            "sozvon_by_links": [
            {
                "_id": "66d7407a565079cbc167d119",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "VhLxa3zPcgk8ofiUkR5hDAMGI_eTh7MVmmiUjtIPquE",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 300,
                "reminder": [
                1725469206000,
                1725553806000
                ],
                "sozvon_name": "урок для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Описание урока 1 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T16:59:38.109Z",
                "sozvon_date": "2024-09-05T17:00:06.000Z",
                "sozvon_date_end": "2024-09-05T18:00:06.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            },
            {
                "_id": "66d740ac76682156b4e80870",
                "creator_vk_caller_id": "1677790397579",
                "client_vk_caller_ids": [],
                "client_vk_caller_ids_accepted": [],
                "client_vk_caller_ids_canceled": [],
                "client_vk_caller_ids_from_course": [],
                "creator": "64010cbdc25dca81ca8d6d0a",
                "token_room": "4Gtvh9FoZDwVYAV1mFX-Ut2qJqzqYK_F0sZIHNmYUDA",
                "view_user_ids": [
                "64010cbdc25dca81ca8d6d0a"
                ],
                "price": 700,
                "reminder": [
                1725555619000,
                1725640219000
                ],
                "sozvon_name": "Урок 2 для курса 1",
                "ads_on_platform": false,
                "sozvon_description": "Урок описание урока 2 для курса 1",
                "creator_was_on_time": false,
                "is_open": true,
                "max_count_persons": 99,
                "specializations": [
                "Маркетинг и продвижение",
                "Другое"
                ],
                "create_date": "2024-09-03T17:00:28.254Z",
                "sozvon_date": "2024-09-06T17:00:19.000Z",
                "sozvon_date_end": "2024-09-06T18:00:19.000Z",
                "__v": 0,
                "views": 1
            }
            ],
            "view_user_ids": [
            "6363ce124ce56781dfcb6294"
            ],
            "price": 999,
            "course_name": "Тестовый курс по математике",
            "course_description": "Существуют две основные трактовки понятия «текст»: имманентная (расширенная, философски нагруженная) и репрезентативная (более частная). Имманентный подход подразумевает отношение к тексту как к автономной реальности, нацеленность на выявление его внутренней структуры. Репрезентативный — рассмотрение текста как особой формы представления информации о внешней тексту действительности.",
            "create_date": "2024-09-03T17:03:47.336Z",
            "__v": 0,
            "views": 1
        },
        "accepted_users": []
    }
}