/* eslint-disable eqeqeq */
import React from 'react';
import { CSSTransition } from 'react-transition-group';


function BaseDesktopDialog(props) {
    const isFromCallScreen = props.isFromCallScreen
    return(
        <CSSTransition in={props.isShow != undefined ? props.isShow : false} timeout={50} classNames="animation_opacity" unmountOnExit>
            <div className={isFromCallScreen ? "" : "relative"}>
                <div style={isFromCallScreen ? {left : 'calc(100vw - 360px)'} : {}} onClick={() => props.setShow != undefined ? props.setShow(false) : {}} className="desktop_dialog_bg"></div>
                <div style={isFromCallScreen ? {position : 'absolute', maxWidth : '328px', margin : '0 16px 0 0', minWidth : '328px'} : {}} className="desktop_dialog_inner">
                    {props.getInnerHtml()}
                </div>
            </div>
        </CSSTransition>
    )
}
export default BaseDesktopDialog;