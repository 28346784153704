import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { UsersApi } from '../../../api/UsersApi';

function DeleteReviewDialog(props) {
    const isDesktop = getIsDesktop()
    const info = props.deleteReviewInfo
    const isShow = info.isShow
    const removeReview = () => {
        console.log("removeReview params = ", info._id)
        UsersApi.removeReviewForYourself(info._id, (responseData) => {
            
            props.onLoaded()
            props.setShow(false)
        }, () => {})
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="create_ads_close_title">Вы собираетесь удалить отзыв</p>
                <div className="create_ads_close_desc">Отменить это действие<br/>будет невозможно</div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px', color : 'white', background : '#FF5353'}} onClick={removeReview} className="call_price_continue hover">Удалить</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        if (!isShow){
            return(<div/>)
        }
        return(<BaseDesktopDialog isShow={isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default DeleteReviewDialog;
