import { useState, useEffect } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';

function CloseCallBottomSheet(props) {
    const isDesktop = getIsDesktop()
    const stopCall = () => {
        props.declineCall()
        props.setShow(false)
    }
    const getInnerHtml = () => {
        return(
            <div className="call_info_container">
                <p className="call_edit_desc">Разговор будет завершён</p>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0 24px', color : 'white', background : '#FF5353'}} onClick={stopCall} className="call_price_continue hover">Завершить</div>
                </div>
                <div style={{marginTop : '0', justifyContent : 'center'}} className="flex">
                    <div style={{margin : '16px 24px 0 24px', textAlign : 'center'}} onClick={() => props.setShow(false)} className="cancel_call_info hover">Отмена</div>
                </div>
            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CloseCallBottomSheet;
