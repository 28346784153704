/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import CreateAdsHeader from './custom_views/CreateAdsHeader';
import CreateAdsButtons from './custom_views/CreateAdsButtons';
import Loader from '../../../custom_views/Loader';
import { isUserAuthed } from '../../../utils/CookieHelper';
import avatar from '../../../img/avatar.svg'
import arrow_right from '../../../img/create_ads/arrow_right.svg'
import { exitProfile, getUrlParameter, Pages } from '../../../utils/Utils';
import { AdsApi } from '../../../api/AdsApi';
import { setSavedAds, convertParamsToIndexes } from './helpers/CreateAdsHelper';
import CreateAdsAuthNew from './custom_views/CreateAdsAuthNew';

function CreateAdsStep11(props) {
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        const publish = getUrlParameter("publish")
        if (publish != null && publish != undefined && publish != ""){
            createAds()
        }
    }, [])
    
    const user = props.user
    
    const createAds = () => {
        const params = convertParamsToIndexes(props.adsInfo)
        setSavedAds(undefined)
       
        setLoading(true)
        AdsApi.createRequestRepetitor(params, (response) => {
            window.location.href = Pages.ads_board + '?is_created=1'
            setLoading(false)
        }, () => {setLoading(false)})
    }
   
    const getAuthHTML = () => {
        return(
            // <CreateAdsAuthButtons getMyInfo={props.getMyInfo}/>
            <CreateAdsAuthNew getMyInfo={props.getMyInfo}/>
        )
    }
    const getPublishHTML = () => {
        return(
            <div>
                <div className="create_ads_profile">
                    <img alt='' className="create_ads_avatar" src={user.avatar ? user.avatar : avatar}/>
                    <div>
                        <div className="create_ads_profile_name">{user.name} {user.surname}</div>
                        <div onClick={exitProfile} className="create_ads_exit hover">Сменить аккаунт</div>
                    </div>
                </div>
                <div onClick={createAds} className="create_ads_btn create_ads_btn_create hover">{isLoading ? <Loader isWhite/> : <div>Опубликовать заявку<img alt='' className="create_ads_arrow_right" src={arrow_right}/></div>}</div>
            </div>
        )
    }
    
    return (
        <div>
            <CreateAdsHeader isEdit={props.isEdit} progress={props.progress}/>
            <div className="create_ads_step">
                <div className="create_ads_background">
                    {/* <div className="create_ads_light_title">{props.pickedSpecs}</div>
                    <div className="create_ads_step_title">Объявление почти создано 🥳</div>
                    {!isUserAuthed() && <div className="create_ads_auth_desc">Чтобы эксперт мог с вами связаться, выполните вход в систему</div>} */}
                    <div style={{paddingTop : '44px', marginBottom : 0}} className="create_ads_light_title ">{props.pickedSpecs}</div>
                    <div style={{paddingTop : '8px'}} className="create_ads_step_title">Объявление почти создано 🥳</div>
                    {!isUserAuthed() && <div style={{opacity : 0.5}} className="create_ads_auth_desc">Чтобы эксперт мог с вами связаться,<br/>выполните вход в систему</div>}
                    {isUserAuthed() ? getPublishHTML() : getAuthHTML()}
                </div>
                <CreateAdsButtons isEdit={props.isEdit}/>
            </div>
        </div>
    );
}

export default CreateAdsStep11;
