import views from '../../../../img/profile/views.svg'
import avatar from '../../../../img/avatar.svg'
import moment from 'moment'
import { Pages } from '../../../../utils/Utils';

const AdsProfileCreator = ({ ads, setShowOpenContacts, setShowNeedPro, user }) => {
    const is_pro = user.is_pro
    const avatarImg = () => {
        return(
            <img onClick={!is_pro ? () => setShowNeedPro(true) : undefined}  alt='' className="create_ads_avatar hover" src={ads.owner_user.avatar ? ads.owner_user.avatar : avatar}/>
        )
    }
    const nameDiv = () => {
        return(
            <div onClick={!is_pro ? () => setShowNeedPro(true) : undefined} className="ads_picked_profile_name hover">{ads.owner_user.name}</div>
        )
    }
    return(
       
            <div style={{padding : '1px 16px 16px 16px'}} className="ads_picked_info_container ads_picked_info_container_p">
                <div className="ads_item_profile">
                    
                    <div style={{width : '100%'}} className="flex">
                        {is_pro ? <a rel="noreferrer"  href={Pages.users + "/id" + ads.owner_user._id} target='_blank'>
                            {avatarImg()}
                        </a>
                        :
                        avatarImg()}
                        <div style={{width : '100%'}}>
                            <div className="ads_picked_views">
                                <div>
                                    {is_pro ? <a rel="noreferrer"  href={Pages.users + "/id" + ads.owner_user._id} target='_blank'>
                                        {nameDiv()}
                                    </a>
                                    :
                                    nameDiv()}

                                    <div onClick={() => setShowOpenContacts(true)} className='ads_open_contacts hover'>Открыть контакт</div>
                                </div>
                                <div>
                                    <div className="ads_item_profile_text">от {moment.unix(moment(ads.create_date, "YYYY-MM-DD'T'HH:mm").unix()).format("DD.MM.YYYY")}</div>
                                    <div style={{marginTop : '9px'}} className="flex">
                                        <img alt='' style={{marginLeft : 'auto'}} className="profile_views_img" src={views}/>
                                        <p className="profile_views_text">{ads.views}</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        
    )
}
export default AdsProfileCreator;