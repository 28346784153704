import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import CreateExpressSearchHeader from './header/CreateExpressSearchHeader';
import CreateAdsButtons from '../../ads_desk/CreateAds/custom_views/CreateAdsButtons';
import autosize from 'autosize';

function ExpressSearchStep3(props) {
    const history = useHistory()
    const inputRef = useRef(null)

    useEffect(() => {
        inputRef.current.focus()
        autosize(inputRef.current)
    }, [])

    const onNext = () => {
        history.push("/express_search/step_4")
        props.createExpressSearch()
    }
  
    return (
        <div>
            <CreateExpressSearchHeader />
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="create_ads_light_title">{props.specialization}</div>
                    <div className="create_ads_step_title">Опишите кратко тему консультации — чтобы эксперт был готов к вашему запросу 🤓</div>
                    <textarea ref={inputRef} style={{minHeight : '140px'}} placeholder="Например, решение задачи по алгебре" className="create_ads_input profile_textare_bio"  value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
                </div>
                <CreateAdsButtons onNext={onNext}/>
            </div>
        </div>
    );
}

export default ExpressSearchStep3;
