/* eslint-disable eqeqeq */
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { getIsDesktop } from '../../../utils/Utils';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import CourseCallItem from '../list_items/CourseCallItem';

function CourseCallsDialogs(props) {
    const isDesktop = getIsDesktop()
    const course = props.course ? props.course : {}
   
    const close = () => {
        props.setShow(false)
    }
   
    const getInnerHtml = () => {
        return(
            <div style={isDesktop ? {maxWidth : '400px'} : {}} className="call_info_container relative">
                <p className="call_edit_title">Видеовстречи : {course.sozvon_by_links.length}</p>
                <div style={{padding : 0, boxShadow : 'none', margin : '16px 0 0 0'}} className='planner_course_calls planner_course_calls_dialog'>
                    {course.sozvon_by_links.length > 0 && course.sozvon_by_links.map((item, index) => (
                        <CourseCallItem isShowAll item={item} index={index} key={index}/>
                    ))}
                </div>
                <div style={{marginTop : '36px'}} className="flex">
                    <div style={{margin : '0'}} onClick={close} className="call_price_continue hover">Закрыть</div>
                </div>
            </div>
        )
    }
    if (course.sozvon_by_links == undefined){
        return null 
    }
    if (isDesktop){
        if (!props.isShow){
            return(<div/>)
        } 
        return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    } else {
        return (
            <BottomSheet style={{zIndex : 9999}} onDismiss={() => props.setShow(false)} open={props.isShow}>
                {getInnerHtml()}
            </BottomSheet>
        )
    }
    
}

export default CourseCallsDialogs;
