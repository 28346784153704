import { Pages } from '../../../utils/Utils';
import { Link } from "react-router-dom";
import crown from '../../../img/chat/crown.svg'

function LKProActive(props) {

    return (
        <Link to={Pages.pro}>
            <div className="lk_pro_active hover">
                <div>У вас активирован статус PRO</div>
                <img src={crown}/>
            </div>
        </Link>)
}

export default LKProActive;