import { getRequestParams, getCookie } from "../utils/CookieHelper";
import { getRequestUrl, getHttpParams } from "../utils/Utils";
import { error, log, onResponse } from "./ApiUtils";

export class UploadApi {
    
    static uploadAvatar(pickedFile, onSuccess, onError) {
        fetch(getRequestUrl() + "uploadAvatar", {
            method: 'POST',
            headers: {"access_token" : getCookie("token")},
            body: pickedFile
        }).then((response) => response.json())
        .then((responseData) => {
            log("uploadAvatar response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    
    static uploadVideo(pickedFile, onSuccess, onError) {
        fetch(getRequestUrl() + "uploadVideo", {
            method: 'POST',
            headers: {"access_token" : getCookie("token")},
            body: pickedFile
        }).then((response) => response.json())
        .then((responseData) => {
            log("uploadVideo response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }

    static removeVideo(onSuccess, onError) {
        fetch(getRequestUrl() + "removeVideo", getHttpParams({})).then((response) => response.json())
        .then((responseData) => {
            onResponse(responseData, onSuccess, onError)
        })
    }

    static uploadMedia(pickedFile, onSuccess, onError) {
        fetch(getRequestUrl() + "uploadMedia", {
            method: 'POST',
            headers: {"access_token" : getCookie("token")},
            body: pickedFile
        }).then((response) => response.json())
        .then((responseData) => {
            log("uploadMedia response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
}