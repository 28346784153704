import { Pages } from '../../../utils/Utils';

function ProfileAdsQuestions(props) {
  
    return (
        <div style={{marginBottom : '8px'}} className="profile_ads_item">
            <div className="flex">
                <div style={{width : '100%'}}>
                    <div className="profile_ads_item_title">Детальная статистика</div>
                    <div>Показывает переходы с рекламы в ваш профиль по дням.</div>
                    <a href={Pages.regina_help} target="_blank"><div className="profile_ads_item_btn hover">Чат с поддержкой</div></a>

                </div>
            </div>
        </div>
    );
}

export default ProfileAdsQuestions;
