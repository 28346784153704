import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from "react-router-dom";
import CreateExpressSearchHeader from './header/CreateExpressSearchHeader';
import avatar from '../../../img/avatar.svg'
import CreateAdsRadioGroup from '../../ads_desk/CreateAds/custom_views/CreateAdsRadioGroup';
import CreateAdsButtons from '../../ads_desk/CreateAds/custom_views/CreateAdsButtons';
import { getExpressSearchTypes, getRandomArbitrary, shuffle } from '../helpers/ExpressSearchHelper';
import { declension } from '../../../utils/Utils';

function ExpressSearchStep2(props) {
    const history = useHistory()
    const [randomFound, setRandomFound] = useState(getRandomArbitrary(20, 40))
    const [bestExperts, setBestExperts] =  useState([{avatar : avatar}, {avatar : avatar}, {avatar : avatar}])
    useEffect(() => {
        if (props.bestExperts == undefined || props.bestExperts.length == 0){
            setBestExperts([{avatar : avatar}, {avatar : avatar}, {avatar : avatar}])
        } else {
            setBestExperts(shuffle([...props.bestExperts]))
        }
    }, [props.bestExperts])
    const onNext = () => {
        history.push("/express_search/step_3")
    }
    const info = {
        progress : 0,
        pickedSpecs : props.specialization,
        header : "Способ связи",
        value : props.value,
        radioItems : getExpressSearchTypes()
    }
    
    return (
        <div>
            <CreateExpressSearchHeader />
            <div className="create_ads_step">
                <div className="create_ads_background">
                    <div className="express_avatars">
                        <img className="express_avatar" src={bestExperts[0].avatar}/>
                        <img style={{marginLeft : '-8px'}} className="express_avatar" src={bestExperts[1].avatar}/>
                        <div style={{marginLeft : '-8px'}} className="express_avatar_cont">
                            <img className="express_avatar" src={bestExperts[2].avatar}/>
                            <div className="express_avatar_bg">
                                <div className="express_avatar_dot"></div>
                                <div className="express_avatar_dot"></div>
                                <div className="express_avatar_dot"></div>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="express_found_count">{randomFound + " " + declension(randomFound, "эксперт", "эксперта", "экспертов") + " выбранной области"}</div>
                    <div className="express_line"></div>
                    <div className="create_ads_light_title">{info.pickedSpecs}</div>
                        <div className="create_ads_radio_header" dangerouslySetInnerHTML={{__html: info.header}}/>
                        <CreateAdsRadioGroup selectedItem={info.value} setSelectedItem={props.setValue} items={info.radioItems}/>
                    </div>
                <CreateAdsButtons onNext={onNext}/>

            </div>
        </div>
    );
}

export default ExpressSearchStep2;
