/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState, useEffect, useRef } from 'react';
import close from '../../../img/x.svg'
import star_selected from '../../../img/profile/star_selected.svg'
import star from '../../../img/profile/star.svg'
import { setModelParam, getRequestUrl, getHttpParams, getIsDesktop } from '../../../utils/Utils';
import autosize from 'autosize';
import { getRequestParams } from '../../../utils/CookieHelper';
import BaseDesktopDialog from '../../../custom_views/BaseDesktopDialog';
import { UsersApi } from '../../../api/UsersApi';
import { disabledBtn } from '../../blog/utils/BlogUtils';

function WriteReview(props) {
    const [stars, setStars] = useState([{img : star},{img : star},{img : star},{img : star},{img : star}])
    const [grade, setGrade] = useState(5)
    const [review, setReview] = useState("")
    const refTextarea = useRef(null)
    useEffect(() => {
        refTextarea.current.focus();
        autosize(refTextarea.current);
    }, []);
    useEffect(() => {
        if (props.writeReview.isShow){
            UsersApi.getReview(props.writeReview.to_id, (responseData) => {
                if (responseData.response > 0 && responseData.review && responseData.review.text && responseData.review.text != ""){
                    setReview(responseData.review.text)
                }
            }, {})
        } else {
            setReview("")
        }
    }, [props.writeReview.isShow])
    const onClose = () => {
        setModelParam(props.writeReview, "isShow", false, props.setWriteReview)
    }
    const sendReview = () => {
        const params = getRequestParams()
        params.to_id = props.writeReview.to_id
        params.text = review
        params.grade = grade
        
        console.log("writeReview params = " + JSON.stringify(params))

        fetch(getRequestUrl() + "writeReview", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("writeReview = " + JSON.stringify(responseData))
        })
        onClose()
        
    }
    const isDesktop = getIsDesktop()
    const innerHtml = () => {
        return(
            <div>
                <div onClick={onClose} className="review_close_cont hover">
                    <img alt="" className="review_close_icon" src={close}/>
                </div>
                <p className="write_review_title">Стоимость прошедшего занятия</p>
                <p className="write_review_price">{props.writeReview.price}₽</p>
                <p className="write_review_title write_review_title_t">{props.writeReview.time ? Math.round(props.writeReview.time) : 0} мин.</p>
                <div className="write_review_line"/>
                <p className="write_review_rate">Оцените качество</p>
                <p className="write_review_title write_review_expert">Оцените работу эксперта</p>
                <div className="write_review_stars">
                    {stars.map((review, index) => (
                        <img alt="" onClick={() => setGrade(index + 1)} className="write_review_star hover" index={index} key={index} src={grade - 1 >= index ? star_selected : star}/>
                    ))}
                </div>
                <p className="write_review_title write_comment_review">Прокомментируйте свою оценку</p>
                <div className="flex write_review_pad">
                    <textarea ref={refTextarea} placeholder="Ваш отзыв поможет эксперту и его потенциальным клиентам" className="profile_input profile_textare_bio write_review_input"  value={review} onChange={(e) => setReview(e.target.value)}/>
                </div>
                <button style={(review && review != "") ? {} : disabledBtn()} onClick={() => sendReview()} className="fill_button hover profile_bottom_button review_ready_btn">Готово</button>

            </div>
        )
    }
    if (isDesktop){
        return(<BaseDesktopDialog isShow={props.writeReview.isShow} setShow={onClose} getInnerHtml={innerHtml}/>)
    } else {
        return (
            <div style={{background : 'white'}} className="selected_theme">
                {innerHtml()}
            </div>
        )
    }
}

export default WriteReview;
