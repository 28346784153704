import badge from '../../../../img/profile/badge.svg'
import badge_seleb from '../../../../img/profile/badge_seleb.svg'
import avatar from '../../../../img/avatar.svg'
import { getRating } from '../../../../utils/Utils';
import message from '../../../../img/experts/message.svg'
import star from '../../../../img/experts/star.svg'

const MyAdsProfile = ({ user }) => {
    const countReviews = user.count_1_grade + user.count_2_grade +user.count_3_grade +user.count_4_grade +user.count_5_grade

    return(
        <div className="">
            <div className="ads_item_profile">
                <div style={{width : '100%'}} className="flex">
                    <div className="relative">
                        <img className="create_ads_avatar" src={user.avatar ? user.avatar : avatar}/>
                        <img className="ads_bagde" src={user.verified ? badge_seleb : badge}/>
                    </div>
                    
                    <div style={{width : '100%'}}>
                        <div className="ads_picked_profile_name">{user.name} {user.surname}</div>
                        <div style={{marginTop : '-4px'}} className="flex">
                            <img className="experts_info_icon" src={star}/>
                            <p className="experts_info_text">{getRating(user)}</p>

                            <img style={{marginLeft : '24px'}} className="experts_info_icon" src={message}/>
                            <p className="experts_info_text">{countReviews}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyAdsProfile;